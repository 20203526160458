<template>
  <utd-modal-dialog ref="utdLexiContentModal"
                    :modal-classes="`lexi_content_modal ds1-utd-font ${lexiModalType}`"
                    :close-on-overlay-click="false"
                    :autofocus="enableAutoFocus">
    <template #header>{{ getHeaderTitle(lexiModalTitle) }}</template>
    <div class="lexi__overlay-container">
      <div class="lexi__overlay-content ds1-pa-2">
        <div v-if="!hasError"
             class="lexi-viewer-content lexi__height-extents"
             v-html="lexiModalContent && lexiModalContent.outerHTML" />
        <utd-notification v-if="hasError || hasWarning"
                          class="lexi-viewer-error ds1-mh-4"
                          :notification-mode="hasError ? 'error' : 'warn'">
          <template v-slot:title>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-if="hasError" v-html="errorMessage" />
            <span v-if="hasWarning">{{ $t('ERRORS.INLINE_REF_WARNING') }}</span>
          </template>
        </utd-notification>
      </div>
    </div>
  </utd-modal-dialog>
</template>
<script>

import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import { getWindow, checkAndSetEventBinding, decodeHtml } from '_acaSrc/utility/DOM';
import { C_KEYCODES, C_EVENTS } from '_acaSrc/utility/constants';
import { setQueryParamValue, getUrlWithoutHash } from '_acaSrc/utility/http';
import { mapGetters } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';

export default {
    components: {
        UtdModalDialog,
        UtdNotification
    },
    props: {
        qapTopicId: {
            type: String
        }
    },
    data() {
        return {
            hasError: false,
            errorMessage: '',
            hasWarning: false,
            lexiModalContent: '',
            lexiModalTitle: '',
            lexiModalType: '',
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        ...mapGetters('app', [ 'isTabletDevice' ]),
        ...mapGetters('topic', [ 'findInTopic', 'topicId', 'isTopicView' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        toggleFindInTopicActive() {
            return this.findInTopic.active;
        },
        currentUrl() {
            return getUrlWithoutHash();
        },
        enableAutoFocus() {
            return this.isDesktopView && !this.isTabletDevice;
        }
    },
    watch: {
        toggleFindInTopicActive() {
            this.bindClickHandler();
        }
    },
    mounted() {
        this.bindClickHandler();
    },
    beforeUnmount() {
        clearTimeout(this.domElementsTimeout);
    },
    methods: {
        open(event) {
            if (event && event.preventDefault && event.currentTarget) {
                event.preventDefault();
                this.hasError = false;
                this.hasWarning = false;

                if (event.currentTarget.className === 'lexi-table-link') {
                    this.setTableDialogContent(event);
                }
                else {
                    this.setReferenceDialogContent(event);
                }

                this.$refs.utdLexiContentModal.open();
            }
        },
        setTableDialogContent(event) {
            this.lexiModalType = 'lexiTable';
            const lexiTableId = event.currentTarget.getAttribute('data-table-id');
            this.lexiModalContent = lexiTableId && document.querySelector(`#${lexiTableId}`);
            this.lexiModalTitle = lexiTableId && document.querySelector(`#${lexiTableId} caption`);

            if (!lexiTableId || !this.lexiModalContent) {
                this.hasError = true;
                this.errorMessage = this.$t('ERRORS.CONTACT_ON_ERROR');
            }
            this.logClickEvent('lexiTableModal',
                this.lexiModalTitle && this.lexiModalTitle.innerText, lexiTableId);
            this.lexiModalTitle = this.lexiModalTitle && this.lexiModalTitle.outerHTML;
        },
        setReferenceDialogContent(event) {
            this.lexiModalType = 'lexiRef';
            let erroredRef = [];
            const contentRefId = event.currentTarget.getAttribute('lexi-content-ref-id');
            const htmlEle = document.createElement('div');

            // Replace square brackets and single quotes
            contentRefId && contentRefId.replace(/[\[\]']+/g, '').split(',').forEach(ref => {
                const refData = ref && document.getElementById(`${ref}`);

                // Add reference in erroredRef array if not able to find any data associated with it
                if (ref && !document.getElementById(`${ref}`)) {
                    erroredRef.push(ref);
                }
                if (!refData && contentRefId) {
                    this.hasWarning = true;
                    return;
                }
                htmlEle.insertAdjacentHTML('beforeend', refData && refData.outerHTML);
            });

            if (!htmlEle.innerText) {
                this.hasError = true;
                this.errorMessage = this.$t('ERRORS.INLINE_REF_ERROR');
                this.hasWarning = false;
                erroredRef = contentRefId;
            }
            this.lexiModalContent = htmlEle;
            this.lexiModalTitle = 'References';
            this.logClickEvent('inlineRefModal', this.lexiModalTitle, erroredRef);
        },
        logClickEvent(modalType, modalTitle, id) {
            let targetUrl = '';
            if (!this.isTopicView) {
                targetUrl = setQueryParamValue(getWindow().location.href,
                    'source',
                    'panel_search_result');
            }

            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                targetUrl,
                uiElementName: modalType,
                optData: modalTitle && modalTitle.trim()
            });

            if (this.hasError || this.hasWarning) {
                Logger.warn(`Error fetching content in ${modalType} ${id} ${modalTitle || ''} for topicID ${this.topicId || this.qapTopicId}`);
            }
        },
        bindClickHandler() {
            this.$nextTick(() => {
                Array.from(document.querySelectorAll('.lexi-table-link, .ref-callout-list-link'))
                    .forEach(el => {
                        checkAndSetEventBinding(
                            el,
                            'lexi-content-click-handler',
                            'click',
                            this.open
                        );
                    });
            });
        },
        getHeaderTitle(title) {
            return decodeHtml(title);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.lexi_content_modal .utd-modal-content {
  max-width: calc(100vw - (@ds1-spacing-multiplier * 4));
  position: relative;
  overflow: auto;
  width: auto;

  a {
    text-decoration: underline;
  }

  .reference {
    font-style: normal;
    word-break: break-word;
    .ds1-utd-size-3();

    &:not(:last-child) {
      .ds1-pb-2();
    }
  }

  .lexi-modal-content {
    .ds1-mr-2();
    .ds1-lexi-content-table-styles();

    th p,
    td p {
      text-align: left !important; //Important is used to override inline style
    }

    caption {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop,
  .isTablet {
    .lexi_content_modal {
      .utd-modal-content {
        display: inline-block;
      }

      &.lexiRef .utd-modal-content {
        max-width: 600px;
      }

      &.lexiTable .lexi-viewer-error {
        .ds1-mr-2();
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

:deep(.utd-notification) {
  .ds1-ma-0();

  &.utd-notification-warn {
    .ds1-mt-2();
  }

  .utd-notification-title {
    .ds1-utd-size-3();
    .ds1-utd-weight-normal();
  }

  .utd-notification-icon {
    top: 20px;
  }
}

.lexi__overlay-container {
  display: inline-block;
  max-width: 100%;
}

.lexi__overlay-content {
  overflow: auto;

  .lexiTable & {
    padding-bottom: 10px;
    .ds1-pr-0();
  }
}

.lexi-viewer-content {
  display: inline-block;
}

</style>
