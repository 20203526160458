import { getQueryParamValues, setQueryParamValue } from '_acaSrc/utility/http';
import { capitalizeFirstLetter } from '_acaSrc/utility/String';

export default class InlineGraphicTemplate {
    constructor(anchor, graphicsImageKeyMap) {
        this.templateBase = document.createElement('template');
        this.parentParagraph = anchor && anchor.closest('p');
        this.graphicAnchor = anchor;
        this.graphicData = this.populateGraphicData(graphicsImageKeyMap);
        this.inlineGraphicClass = '';
        this.curParagraphIndex = undefined;
    }

    /** If graphicData piece is missing, or no parent p is found, no template is added to DOM */
    confirmDataAvailable() {
        return this.graphicData && this.parentParagraph;
    }

    /**
     * Attempts to populate the necessary graphic data. Returns null if piece is missing,
     * to prevent template from being created (and thus prevent broken inline graphic).
     */
    populateGraphicData(graphicsImageKeyMap) {
        try {
            const url = setQueryParamValue(this.graphicAnchor.href, 'source', 'inline_graphic');
            const imageKey = getQueryParamValues(url, 'imageKey');
            const title = graphicsImageKeyMap[imageKey].title;
            const label = capitalizeFirstLetter(this.graphicAnchor.textContent);
            return url && imageKey && title && label && { url, imageKey, title, label };
        }
        catch (e) {
            /* eslint-disable no-console */
            console.log(`Issue with graphic data in populateGraphicData: ${e}`);
            return;
        }
    }

    /** Checks if graphic is part of new paragraph, in order to update current paragraph index */
    checkIfFirstOnParagraph(curIndex) {
        if (!this.parentParagraph) {
            return;
        }

        let index = curIndex;
        if (!this.parentParagraph.classList.contains('inlineGraphicParagraph')) {
            this.parentParagraph.className
                = `inlineGraphicParagraph ${this.parentParagraph.className}`;
            index++;
        }
        this.curParagraphIndex = index;
        return index;
    }

    /** Sets graphicData properties as attributes on the template, to facilitate later injection */
    setAttributes() {
        if (this.confirmDataAvailable()) {
            Object.keys(this.graphicData).forEach(attribute => {
                this.templateBase.setAttribute(attribute, this.graphicData[attribute]);
            });
        }
        return this;
    }

    /** Checks if the anchor is child to a header (H1), to determine where to place the template */
    checkIfOnHeader() {
        const anchorOnH1Parent = this.confirmDataAvailable()
                              && this.parentParagraph.firstElementChild.classList.contains('h1');

        if (anchorOnH1Parent) {
            const newParentParagraph = this.parentParagraph.querySelector('.headingEndMark');
            /* If we can find the headingEndMark, we want to place the template after it
               (places template in paragraph rather than above it, for long header support) */
            if (newParentParagraph && newParentParagraph.nextSibling) {
                this.parentParagraph = newParentParagraph.nextSibling;
                this.templateBase.className = 'onHeader';
            }
            else {
                // Failsafe for when headingEndMark can't be found
                this.templateBase.className = 'onHeaderFailsafe';
            }
        }
        return this;
    }

    /** Sets class used to facilitate synced-hover capability */
    setLabelIdentifier() {
        if (this.confirmDataAvailable()) {
            // Adds class to distinguish label
            this.inlineGraphicClass = this.graphicData.label.replace(' ', '-').toLowerCase();
            this.inlineGraphicClass && this.graphicAnchor.classList.add(this.inlineGraphicClass);
        }
        return this;
    }

    /** Sets class used to help identify graphics in the same paragraph */
    setParagraphIdentifier() {
        if (this.confirmDataAvailable()) {
            const paragraphIdentifier = `paragraph-${this.curParagraphIndex}`;
            this.templateBase.className = paragraphIdentifier.concat(
                ' ',
                'inlineGraphicPlaceholder ',
                this.templateBase.className
            );
        }
        return this;
    }

    /** Inserts using IE11 supported syntax */
    insertTemplateBeforeParentParagraph() {
        if (this.confirmDataAvailable()) {
            this.parentParagraph.parentNode.insertBefore(this.templateBase, this.parentParagraph);
        }
    }
}