<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="utd-error"
       class="content-error"
       tabindex="0">
    <div v-if="hasUrl" v-html="errorUrl" />
    <div v-if="hasMessage" :aria-label="$t(messageKey)" v-html="$t(messageKey)" />
  </div>
</template>

<script>

export default {
    props: {
        messageKey: {
            type: String,
            default: ''
        },
        errorUrl: {
            type: String,
            default: ''
        }
    },
    computed: {
        hasUrl() {
            return this.errorUrl;
        },
        hasMessage() {
            return this.messageKey;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles.less";

#utd-error {
  padding: 10px 15px;
  .border-radius(6px);
  color: black;
  font-size: 0.9em;
  background: white;
  border: 2px solid #ef3841;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  h1 {
    font-size: 1.5em;
    margin: 0;
  }
}

</style>