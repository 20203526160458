<template>
  <div v-if="result"
       class="graphical-answers">
    <div id="gaHeader" class="ga-header">
      <div class="ga-title"> {{ graphicalAnswersTitle }} </div>
      <div class="ga-description">{{ result.imageTitle }}</div>
    </div>
    <div class="ga-body">
      <img class="utd-image"
           aria-labelledby="gaHeader"
           :src="imageSrc"
           :alt="desc"
           :tabIndex="tabIndex"
           @keypress="$event.which === ENTER_KEY && onClick($event, result.firstGraphicalAnswer)"
           @click="onClick($event, result.firstGraphicalAnswer)">
    </div>
    <utd-thumbnail class="mobile-web-graphical-answers"
                   :graphic="createGraphicObject"
                   has-border
                   horizontal
                   large />
    <utd-pendo-feedback class="graphical-answers-feedback"
                        :feedback-data="feedbackData" />
  </div>
</template>

<script>
import { C_KEYCODES } from '_acaSrc/utility/constants';
import { mapGetters, mapActions } from 'vuex';
import { makeGraphicUrl } from '_acaSrc/utility/contents/graphic/graphic';
import { setSearchUrlParamsHelper, makeRelativeUrl } from '_acaSrc/utility/http';
import GraphicalAnswerResult from '_acaSrc/utility/data-adapters/graphical-answers-adapter';
import UtdPendoFeedback from '_acaSrc/components/shared/utd/UtdPendoFeedback.vue';
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';

export default {
    components: {
        UtdPendoFeedback,
        UtdThumbnail
    },
    props: {
        result: GraphicalAnswerResult,
        desc: {
            type: String,
            default: 'Image'
        },
        tabIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('search', [ 'searchParamsSearchText' ]),
        ...mapGetters('device', [ 'isDesktopView', 'isMobileOnDesktop' ]),
        feedbackData() {
            return {
                featureType: 'Graphical Answers',
                featureId: this.result.id,
                featureTitle: this.result.title
            };
        },
        imageSrc() {
            return this.isDesktopView
                ? this.result.imageSrcDesktop
                : this.result.imageSrcMobile;
        },
        createGraphicObject() {
            const { imageSrcMobile, type, title } = this.result.firstGraphicalAnswer;
            const returnObj = {
                thumbnailUrl: makeRelativeUrl(imageSrcMobile),
                url: makeGraphicUrl(this.result.firstGraphicalAnswer),
                type,
                title
            };
            return returnObj;
        },
        graphicalAnswersTitle() {
            return this.isDesktopView && !this.isMobileOnDesktop
                ? 'Graphical Answers'
                : 'Graphical Answers:';
        }
    },
    methods: {
        ...mapActions('graphic', [ 'launchGraphicViewerModal' ]),
        async onClick(event, graphic) {
            const { rankIndex, searchRank, searchTerm } = graphic;
            let graphicUrl = makeGraphicUrl(graphic);
            graphicUrl = setSearchUrlParamsHelper(graphicUrl, {
                rankIndex, searchRank, searchTerm
            });
            event.preventDefault();

            if (!this.isDesktopView) {
                this.router.url(graphicUrl);
                return;
            }

            await this.launchGraphicViewerModal({
                imageKey: graphic.imageKey,
                imageUrl: graphicUrl
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles.less";

.graphical-answers {
  background-color: rgba(246, 246, 246, 0.5);
  border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;

  .utd-image {
    max-height: 145px;
    max-width: 225px;
    display: block;
    margin: auto;
  }

  .ga-header {
    .ds1-pa-2();
    .ds1-pb-1();
    background-color: @DS1-UTD-GRAY-BG-COLOR;

    .ga-title {
      font-size: 12px;
      line-height: 16px;
      color: @DS1-UTD-GRAY-TEXT-COLOR;
    }

    .ga-description {
      display: none;
    }
  }

  .mobile-web-graphical-answers {
    .ds1-mh-2();
    .ds1-mb-1();
    width: auto;
  }

  .ga-body {
    display: none;
  }

  .graphical-answers-feedback {
    display: block;
    text-align: right;
    z-index: @ZINDEX-FEATURED-RESULT-FEEDBACK;
  }
}

@media only screen and (min-width: 768px) {
  .graphical-answers .mobile-web-graphical-answers :deep(.utd-thumbnail__image-container) {
    width: 225px;
    height: 225px;
    min-width: auto;

    .utd-thumbnail__image-anchor {
      width: 225px;
      height: 225px;
    }
  }

  .isDesktop {
    .graphical-answers {
      background-color: transparent;
      width: 450px;
      border: @DS1-UTD-GRAY-SINGLE-BORDER;
      position: relative;

      .ga-header {
        .ds1-pa-2();
        background-color: @DS1-UTD-PRIMARY-BG-COLOR;
        border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;

        .ga-title {
          .ds1-mb-compact();
          .ds1-utd-weight-bold();
          line-height: unset;
          font-size: unset;
          color: unset;
        }

        .ga-description {
          display: block;
        }
      }

      .ga-body {
        // nonstandard h-pad due to ux wanting image
        // to have a very specific container size.
        padding: 16px 36px;
        background-color: @DS1-UTD-PRIMARY-BG-COLOR;
        display: block;
      }

      .mobile-web-graphical-answers {
        display: none;
      }

      .utd-image {
        max-height: 100%;
        max-width: 100%;
        cursor: pointer;
      }

      .graphical-answers-feedback {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>