import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { C_TOC } from '_acaSrc/utility/constants';
import tocRoot from '_acaSrc/components/contents/toc/TocRoot';
import { decodeHtml } from '_acaSrc/utility/DOM';
import Logger from '_acaSrc/utility/Logger';
import { setBrowserTabTitle } from '_acaSrc/utility/Browsers';
import i18next from 'i18next';
import { localizeAnchor } from '_acaSrc/utility/LocalizationHelper';

const TOC_FILTER_SPECIALTIES = 1,
    TOC_FILTER_LANDING = 2;

const state = {
    generalTocFilter: [],
    toc: {
        name: '',
        placeholder: '',
        header: '',
        filter: '',
        isShowHeader: false,
        isShowNavToggle: false,
        isShowFilter: false,
        isShowTocLanding: false,
        items: [],
        specialty: '',
        contributorTypes: '',
        contributorBundles: '',
        isNotFinalNode: true,
        filterType: '',
        csuChecked: false,
        pathwaysFilterValue: '',
        sections: undefined,
        isShowPISubnav: undefined,
        isShowTitle: undefined,
        title: undefined
    },
    tocUrl: {
        id: '',
        section: '',
        specialty: '',
        languageCode: ''
    },
    tocCss: {
        header: '',
        toolbar: '',
        piSubBasics: '',
        piSubBTB: '',
        piFilter: '',
        subCats: undefined,
        subAlpha: undefined
    },
    isTocView: false
};

export const getters = {
    generalTocFilter: state => state.generalTocFilter,
    tocRootItems: (state, getters, rootState, rootGetters) => tocRoot(rootGetters),

    tocSpecialty: state => state.toc.specialty,
    tocContributorTypes: state => state.toc.contributorTypes,
    tocContributorBundles: state => state.toc.contributorBundles,
    tocName: state => state.toc.name,
    tocPlaceholder: state => state.toc.placeholder,
    tocHeader: state => state.toc.header,
    tocFilter: state => state.toc.filter,
    tocIsShowHeader: state => state.toc.isShowHeader,
    tocIsShowNavToggle: state => state.toc.isShowNavToggle,
    tocIsShowFilter: state => state.toc.isShowFilter,
    tocIsShowTocLanding: state => state.toc.isShowTocLanding,
    tocItems: state => state.toc.items,
    tocIsNotFinalNode: state => state.toc.isNotFinalNode,
    tocFilterType: state => state.toc.filterType,
    tocCsuChecked: state => state.toc.csuChecked,
    tocPathwaysFilterValue: state => state.toc.pathwaysFilterValue,
    tocSections: state => state.toc.sections,
    tocIsShowPISubnav: state => state.toc.isShowPISubnav,
    tocIsShowTitle: state => state.toc.isShowTitle,
    tocTitle: state => state.toc.title,
    isTocView: state => state.isTocView,

    tocUrl: state => state.tocUrl,
    tocUrlId: state => state.tocUrl.id,
    tocUrlSection: state => state.tocUrl.section,
    tocUrlSpecialty: state => state.tocUrl.specialty,
    tocUrlLanguageCode: state => state.tocUrl.languageCode,

    tocCssHeader: state => state.tocCss.header,
    tocCssToolbar: state => state.tocCss.toolbar,
    tocCssPiSubBasics: state => state.tocCss.piSubBasics,
    tocCssPiSubBTB: state => state.tocCss.piSubBTB,
    tocCssPiFilter: state => state.tocCss.piFilter,
    tocCssSubCats: state => state.tocCss.subCats,
    tocCssSubAlpha: state => state.tocCss.subAlpha

};

export const SET_GENERAL_TOC_FILTER = 'SET_GENERAL_TOC_FILTER';

export const SET_TOC_SPECIALTY = 'SET_TOC_SPECIALTY';
export const SET_TOC_CONTRIBUTOR_TYPES = 'SET_TOC_CONTRIBUTOR_TYPES';
export const SET_TOC_CONTRIBUTOR_BUNDLES = 'SET_TOC_CONTRIBUTOR_BUNDLES';
export const SET_TOC_NAME = 'SET_TOC_NAME';
export const SET_TOC_PLACEHOLDER = 'SET_TOC_PLACEHOLDER';
export const SET_TOC_HEADER = 'SET_TOC_HEADER';
export const SET_TOC_FILTER = 'SET_TOC_FILTER';
export const SET_TOC_IS_SHOW_HEADER = 'SET_TOC_IS_SHOW_HEADER';
export const SET_TOC_IS_SHOW_NAV_TOGGLE = 'SET_TOC_IS_SHOW_NAV_TOGGLE';
export const SET_TOC_IS_SHOW_FILTER = 'SET_TOC_IS_SHOW_FILTER';
export const SET_TOC_IS_SHOW_TOC_LANDING = 'SET_TOC_IS_SHOW_TOC_LANDING';
export const SET_TOC_ITEMS = 'SET_TOC_ITEMS';
export const SET_TOC_IS_NOT_FINAL_NODE = 'SET_TOC_IS_NOT_FINAL_NODE';
export const SET_TOC_FILTER_TYPE = 'SET_TOC_FILTER_TYPE';
export const SET_TOC_CSU_CHECKED = 'SET_TOC_CSU_CHECKED';
export const SET_TOC_PATHWAYS_FILTER_VALUE = 'SET_TOC_PATHWAYS_FILTER_VALUE';
export const SET_TOC_SECTIONS = 'SET_TOC_SECTIONS';
export const SET_TOC_IS_SHOW_PI_SUBNAV = 'SET_TOC_IS_SHOW_PI_SUBNAV';
export const SET_TOC_IS_SHOW_TITLE = 'SET_TOC_IS_SHOW_TITLE';
export const SET_TOC_TITLE = 'SET_TOC_TITLE';
export const SET_TOC_VIEW = 'SET_TOC_VIEW';

export const SET_TOC_URL_ID = 'SET_TOC_URL_ID';
export const SET_TOC_URL_SECTION = 'SET_TOC_URL_SECTION';
export const SET_TOC_URL_SPECIALTY = 'SET_TOC_URL_SPECIALTY';
export const SET_TOC_URL_LANGUAGE_CODE = 'SET_TOC_URL_LANGUAGE_CODE';

export const SET_TOC_CSS_HEADER = 'SET_TOC_CSS_HEADER';
export const SET_TOC_CSS_TOOLBAR = 'SET_TOC_CSS_TOOLBAR';
export const SET_TOC_CSS_PI_SUB_BASICS = 'SET_TOC_CSS_PI_SUB_BASICS';
export const SET_TOC_CSS_PI_SUB_BTB = 'SET_TOC_CSS_PI_SUB_BTB';
export const SET_TOC_CSS_PI_FILTER = 'SET_TOC_CSS_PI_FILTER';
export const SET_TOC_CSS_SUB_CATS = 'SET_TOC_CSS_SUB_CATS';
export const SET_TOC_CSS_SUB_ALPHA = 'SET_TOC_CSS_SUB_ALPHA';

export const mutations = {
    [SET_GENERAL_TOC_FILTER](state, generalTocFilter) {
        state.generalTocFilter = generalTocFilter;
    },

    [SET_TOC_SPECIALTY](state, specialty) {
        state.toc.specialty = specialty;
    },
    [SET_TOC_CONTRIBUTOR_TYPES](state, contributorTypes) {
        state.toc.contributorTypes = contributorTypes;
    },
    [SET_TOC_CONTRIBUTOR_BUNDLES](state, contributorBundles) {
        state.toc.contributorBundles = contributorBundles;
    },
    [SET_TOC_NAME](state, name) {
        state.toc.name = name;
    },
    [SET_TOC_PLACEHOLDER](state, placeholder) {
        state.toc.placeholder = placeholder;
    },
    [SET_TOC_HEADER](state, header) {
        state.toc.header = header;
    },
    [SET_TOC_FILTER](state, filter) {
        state.toc.filter = filter;
    },
    [SET_TOC_IS_SHOW_HEADER](state, isShowHeader) {
        state.toc.isShowHeader = isShowHeader;
    },
    [SET_TOC_IS_SHOW_NAV_TOGGLE](state, isShowNavToggle) {
        state.toc.isShowNavToggle = isShowNavToggle;
    },
    [SET_TOC_IS_SHOW_FILTER](state, isShowFilter) {
        state.toc.isShowFilter = isShowFilter;
    },
    [SET_TOC_IS_SHOW_TOC_LANDING](state, isShowTocLanding) {
        state.toc.isShowTocLanding = isShowTocLanding;
    },
    [SET_TOC_ITEMS](state, items) {
        state.toc.items = items;
    },
    [SET_TOC_IS_NOT_FINAL_NODE](state, isNotFinalNode) {
        state.toc.isNotFinalNode = isNotFinalNode;
    },
    [SET_TOC_FILTER_TYPE](state, filterType) {
        state.toc.filterType = filterType;
    },
    [SET_TOC_CSU_CHECKED](state, csuChecked) {
        state.toc.csuChecked = csuChecked;
    },
    [SET_TOC_PATHWAYS_FILTER_VALUE](state, pathwaysFilterValue) {
        state.toc.pathwaysFilterValue = pathwaysFilterValue;
    },
    [SET_TOC_SECTIONS](state, sections) {
        state.toc.sections = sections;
    },
    [SET_TOC_IS_SHOW_PI_SUBNAV](state, isShowPISubnav) {
        state.toc.isShowPISubnav = isShowPISubnav;
    },
    [SET_TOC_IS_SHOW_TITLE](state, isShowTitle) {
        state.toc.isShowTitle = isShowTitle;
    },
    [SET_TOC_TITLE](state, title) {
        state.toc.title = title;
    },

    [SET_TOC_URL_ID](state, id) {
        state.tocUrl.id = id;
    },
    [SET_TOC_URL_SECTION](state, section) {
        state.tocUrl.section = section;
    },
    [SET_TOC_URL_SPECIALTY](state, specialty) {
        state.tocUrl.specialty = specialty;
    },
    [SET_TOC_URL_LANGUAGE_CODE](state, languageCode) {
        state.tocUrl.languageCode = languageCode;
    },

    [SET_TOC_CSS_HEADER](state, header) {
        state.tocCss.header = header;
    },
    [SET_TOC_CSS_TOOLBAR](state, toolbar) {
        state.tocCss.toolbar = toolbar;
    },
    [SET_TOC_CSS_PI_SUB_BASICS](state, piSubBasics) {
        state.tocCss.piSubBasics = piSubBasics;
    },
    [SET_TOC_CSS_PI_SUB_BTB](state, piSubBTB) {
        state.tocCss.piSubBTB = piSubBTB;
    },
    [SET_TOC_CSS_PI_FILTER](state, piFilter) {
        state.tocCss.piFilter = piFilter;
    },
    [SET_TOC_CSS_SUB_CATS](state, subCats) {
        state.tocCss.subCats = subCats;
    },
    [SET_TOC_CSS_SUB_ALPHA](state, subAlpha) {
        state.tocCss.subAlpha = subAlpha;
    },
    [SET_TOC_VIEW](state, value) {
        state.isTocView = value;
    }
};

// In the Vue router, the respective get function should
// be envoked in beforeEnter.
export const actions = {
    getToc({ rootGetters, commit, getters, dispatch }) {
        // For calculators, ensure that we have a "section"
        if ((getters.tocUrlId === 'calculators'
        || getters.tocUrlId === 'pathways')
        && typeof getters.tocUrlSection === 'undefined') {
            commit(SET_TOC_URL_SECTION, 'categorized');
        }

        return utdRest('contents/table-of-contents', {
            id: getters.tocUrlId,
            section: getters.tocUrlSection,
            language: getters.tocUrlLanguageCode
        })
            .then(res => resolveToc(res, { rootGetters, commit, getters, dispatch }))
            .catch(err => resolveError(err, { rootGetters, getters }));
    },
    getAuthorsEditors({ rootGetters, commit, getters, dispatch }) {
        return utdRest('contents/authors-and-editors', { specialty: getters.tocUrlSpecialty })
            .then(res => resolveToc(res, { rootGetters, commit, getters, dispatch }))
            .catch(err => resolveError(err, { rootGetters, getters }));
    },
    setPatientEducationMenuState({ commit, getters }) {
        commit(SET_TOC_CSS_PI_SUB_BASICS, '');
        commit(SET_TOC_CSS_PI_SUB_BTB, '');

        if (getters.tocCssPiFilter === '') {
            commit(SET_TOC_CSS_PI_FILTER, 'basicsOnly');
        }
        if (getters.tocCssPiFilter === 'basicsOnly') {
            commit(SET_TOC_HEADER, i18next.t('TOC.PI_BASICS'));
            commit(SET_TOC_PLACEHOLDER, 'The Basics');
            commit(SET_TOC_CSS_PI_SUB_BASICS, 'selected');
        }
        else if (getters.tocCssPiFilter === 'btbOnly') {
            commit(SET_TOC_HEADER, i18next.t('TOC.PI_BEYOND_BASICS'));
            commit(SET_TOC_PLACEHOLDER, 'Beyond the Basics');
            commit(SET_TOC_CSS_PI_SUB_BTB, 'selected');
        }
        pruneTextFromSections('Patient information: ', getters.tocSections);
        pruneTextFromSections('Patient education: ', getters.tocSections);
        setPatientEducationLinkVisibility({ getters });
    },
    filterPathwaysTocBySearchTerms({ rootGetters, getters, commit, dispatch }) {
        if ((getters.tocUrlId === C_TOC.PATHWAYS_NAME) && getters.tocFilter) {
            const params = {
                search: getters.tocFilter,
                include: C_TOC.PATHWAYS_TOC_FILTER_NAME
            };
            const searchSettings = { max: C_TOC.PATHWAYS_TOC_FILTER_SEARCH_SETTINGS_MAX };

            dispatch('search/getSearch', {
                params,
                searchSettings
            }, { root: true })
                .then(res => filterPathwaysTocItems(res, { getters, commit }))
                .catch(err => resolveError(err, { rootGetters, getters }));
        }
    }
};

const filterPathwaysTocItems = (data, { getters, commit }) => {
    if (data.searchResults) {
        if (getters.tocSections) {
            addPathwayFilterToTocSections(data.searchResults, { getters, commit });
        }
        else {
            addPathwayFilterToTocItems(data.searchResults, { getters, commit });
        }
    }
};

const addPathwayFilterToTocSections = (searchResults, { getters, commit }) => {
    const newSections = getters.tocSections.map(section => {
        section.items = addPathwayFilterToItems(searchResults, section.items, { getters, commit });
        return section;
    });
    commit(SET_TOC_SECTIONS, newSections);
};

const addPathwayFilterToTocItems = (searchResults, { getters, commit }) => {
    const newItems = addPathwayFilterToItems(searchResults, getters.tocItems, { getters, commit });
    commit(SET_TOC_ITEMS, newItems);
};

const addPathwayFilterToItems = (searchResults, items, { getters }) => {
    return items.map(item => {
        return addPathwayFilterToItem(searchResults, item, { getters });
    });
};

const addPathwayFilterToItem = (searchResults, item, { getters }) => {
    item.pathwayFilter = '';

    if (searchResults.some(searchResult => item.topicId === searchResult.id)) {
        item.pathwayFilter = getters.tocFilter;
    }

    return item;
};

const resolveToc = (data, { rootGetters, commit, getters, dispatch }) => {
    commit(SET_TOC_NAME, data.name);
    commit(SET_TOC_PLACEHOLDER, getters.tocName);
    commit(SET_TOC_SECTIONS, data.sections);
    commit(SET_TOC_ITEMS, data.items);

    commit(SET_TOC_SPECIALTY, data.specialtyName);
    commit(SET_TOC_CONTRIBUTOR_TYPES, data.contributorTypes);
    commit(SET_TOC_CONTRIBUTOR_BUNDLES, data.contributorBundles);

    let title = getters.tocName;
    if (!title) {
        title = getters.tocSpecialty;
    }
    setBrowserTabTitle(decodeHtml(title));

    preserveTocFilter({ commit, getters });

    resetTocState({ commit });

    commit(SET_TOC_IS_NOT_FINAL_NODE, (data.type !== 'SECTION'));

    if (typeof getters.tocUrlId === 'undefined') {
        setGeneralToc({ rootGetters, commit, getters, dispatch });
    }
    else {
        setCommonTocs({ rootGetters, commit, getters, dispatch });
    }

    return true;
};

const setCommonTocs = ({ rootGetters, commit, getters, dispatch }) => {
    const id = getters.tocUrlId;
    if (id === 'patient-information'
    || id === 'patient-education') {
        setPatientEducationToc({ rootGetters, commit, getters, dispatch });
    }
    else if (id === 'calculators'
    || id === 'pathways'
    || id === 'lab-interpretation') {
        if (id === 'pathways') {
            commit(SET_TOC_FILTER, getters.tocPathwaysFilterValue);
        }
        setFilterToc(getters.tocUrlId, { commit, getters });
    }
    else {
        setSpecificToc({ rootGetters, commit, getters });
    }

};

const setSpecificToc = ({ rootGetters, commit, getters }) => {
    switch (getters.tocUrlId) {
    case 'whats-new':
        setWhatsNewToc({ rootGetters, commit, getters });
        break;

    case 'authorseditors':
        setAuthorsEditorsToc({ rootGetters, commit });
        break;

    default:
        setDefaultToc({ rootGetters, commit });
        break;
    }
};

const preserveTocFilter = ({ commit, getters }) => {
    commit(SET_TOC_PATHWAYS_FILTER_VALUE, '');
    if (getters.tocFilterType === 'pathways') {
        commit(SET_TOC_PATHWAYS_FILTER_VALUE, getters.tocFilter);
    }
};

const resetTocState = ({ commit }) => {
    commit(SET_TOC_HEADER, '');
    commit(SET_TOC_TITLE, '');
    commit(SET_TOC_FILTER, '');

    commit(SET_TOC_IS_SHOW_PI_SUBNAV, false);
    commit(SET_TOC_IS_SHOW_NAV_TOGGLE, false);
    commit(SET_TOC_IS_SHOW_FILTER, false);
    commit(SET_TOC_IS_SHOW_HEADER, false);
    commit(SET_TOC_IS_SHOW_TITLE, false);

    commit(SET_TOC_CSS_HEADER, '');
    commit(SET_TOC_CSS_TOOLBAR, '');
    commit(SET_TOC_CSS_PI_SUB_BASICS, '');
    commit(SET_TOC_CSS_PI_SUB_BTB, '');
    commit(SET_TOC_CSS_PI_FILTER, '');
    commit(SET_TOC_CSS_SUB_CATS, '');
    commit(SET_TOC_CSS_SUB_ALPHA, '');
};

const setDefaultToc = ({ rootGetters, commit }) => {
    // Used to add text to top of Content>Sections page; DE10293
    commit(SET_TOC_HEADER, i18next.t('TOC.CONTENTS_SECTIONS'));
    commit(SET_TOC_IS_SHOW_HEADER, true);
    commit(SET_TOC_CSS_HEADER, 'bottomShadow topPadding');
    commit(SET_TOC_CSS_TOOLBAR, 'noBottomBorder');
    // if ($state.current.name == "tableOfContentsSpecialty"){
    if (rootGetters['app/routeLoading'] === 'tableOfContentsSection'
        || rootGetters['app/routeLoading'] === 'tableOfContentsSectionLanguage') {
        commit(SET_TOC_HEADER, '');
        commit(SET_TOC_IS_SHOW_HEADER, false);
    }
};

const setAuthorsEditorsToc = ({ commit }) => {
    commit(SET_TOC_IS_SHOW_HEADER, false);
    commit(SET_TOC_HEADER, i18next.t('TOC.CONTENTS'));
    commit(SET_TOC_CSS_HEADER, 'bottomShadow topPadding');
    commit(SET_TOC_CSS_TOOLBAR, 'noBottomBorder');
};

const setFilterToc = (filterType, { commit, getters }) => {
    commit(SET_TOC_IS_SHOW_FILTER, true);
    commit(SET_TOC_FILTER_TYPE, filterType);

    if (getters.tocUrlSection === 'categorized') {
        commit(SET_TOC_CSS_SUB_CATS, 'selected');
    }
    else {
        commit(SET_TOC_CSS_SUB_ALPHA, 'selected');
    }

    commit(SET_TOC_CSS_HEADER, 'bottomShadow, topPadding');
    commit(SET_TOC_CSS_TOOLBAR, 'noBottomBorder');
    commit(SET_TOC_IS_NOT_FINAL_NODE, false);

    let label = '';
    if (filterType === 'calculators') {
        label = 'Calculator:';
        commit(SET_TOC_IS_SHOW_NAV_TOGGLE, true);
    }
    else if (filterType === 'pathways') {
        label = 'Pathways:';
        commit(SET_TOC_IS_SHOW_NAV_TOGGLE, true);
        commit(SET_TOC_PLACEHOLDER, 'Pathways');
        commit(SET_TOC_NAME, getters.tocPlaceholder);
    }
    else if (filterType === 'lab-interpretation') {
        label = 'Lab-Interpretation: ';
    }

    pruneTextFromSections(label, getters.tocSections);
    pruneTextFromItemNames(label, getters.tocItems);
};

const setWhatsNewToc = ({ commit, getters }) => {
    commit(SET_TOC_IS_SHOW_HEADER, true);
    commit(SET_TOC_HEADER, i18next.t('TOC.WHATS_NEW'));
    commit(SET_TOC_TITLE, 'Find Out What\'s New In:');
    commit(SET_TOC_IS_SHOW_TITLE, true);

    // Filter out "What's new in " from start of each entry
    pruneTextFromItemNames('What\'s new in ', getters.tocItems);
};

const setPatientEducationToc = ({ commit, getters, dispatch }) => {
    commit(SET_TOC_IS_SHOW_HEADER, true);
    commit(SET_TOC_IS_SHOW_FILTER, false);
    commit(SET_TOC_IS_SHOW_PI_SUBNAV, false);
    commit(SET_TOC_CSS_PI_FILTER, '');

    commit(SET_TOC_HEADER, i18next.t('TOC.PI',
        { healthonnet: 'https://www.healthonnet.org/HONcode/Conduct.html?HONConduct272373',
            honcode: 'https://www.healthonnet.org/HONcode/Conduct.html' }));
    commit(SET_TOC_CSS_TOOLBAR, 'noBottomBorder');

    if (typeof getters.tocUrlSection !== 'undefined') {
        dispatch('setPatientEducationMenuState');
        commit(SET_TOC_IS_SHOW_HEADER, true);
        commit(SET_TOC_IS_SHOW_PI_SUBNAV, true);
    }
    commit(SET_TOC_CSS_HEADER, 'bottomShadow topPadding');
};

const setPatientEducationLinkVisibility = ({ getters }) => {
    // Post process model to set display of individual items
    // dependant upon value of tocCss.piFilter, so that only
    // "The Basics" or "Beyond the Basics" topics will display
    // when the corresponding link is active.
    // Also get run against "Calculators", so that the "display"
    // property can get assigned to true for each section.

    if (getters.tocSections) {
        for (let s = 0; s < getters.tocSections.length; s++) {
            setPatientEducationSectionLinkVisibility(s, { getters });
        }
    }
};

const setPatientEducationSectionLinkVisibility = (sectionIdx, { getters }) => {
    const section = getters.tocSections[sectionIdx];
    section.display = true;
    if (section.items) {
        let itmCnt = 0;
        for (let i = 0; i < section.items.length; i++) {
            const item = section.items[i];
            if (item.url.indexOf('-beyond-the-basics') > -1) {
                item['display'] = getters.tocCssPiFilter === 'btbOnly';
            }
            else if (item.url.indexOf('-the-basics') > -1) {
                item['display'] = getters.tocCssPiFilter === 'basicsOnly';
            }
            if (item.display === true) {
                itmCnt++;
            }
        }
        if (itmCnt === 0) {
            getters.tocSections[sectionIdx]['display'] = false;
        }
    }
};

const pruneTextFromSections = (string, sections) => {
    // Post process results to filter out "Calculator: " from start of each entry
    if (sections) {
        for (let s = 0; s < sections.length; s++) {
            pruneTextFromSubSections(string, sections[s].subSections);
            pruneTextFromItemNames(string, sections[s].items);
        }
    }
};

const pruneTextFromSubSections = (string, subSections) => {
    if (subSections) {
        for (let b = 0; b < subSections.length; b++) {
            pruneTextFromItemNames(string, subSections[b].items);
        }
    }
};

const pruneTextFromItemNames = (string, items) => {
    if (items) {
        const strlen = string.length;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.name.substr(0, strlen) === string) {
                const rplName = item.name.substr(strlen, item.name.length - strlen);
                const capName = rplName.charAt(0).toUpperCase() + rplName.substr(1);
                item.name = capName;
            }
        }
    }
};

const setGeneralToc = ({ rootGetters, commit, getters, dispatch }) => {
    commit(SET_TOC_IS_SHOW_HEADER, true);
    commit(SET_TOC_HEADER, i18next.t('TOC.CONTENTS'));
    commit(SET_TOC_CSS_HEADER, 'bottomShadow topPadding');
    commit(SET_TOC_CSS_TOOLBAR, 'noBottomBorder');

    // CORE-2497: Implements new root ToC landing page for mobile views.
    dispatch('app/delayExecuteUntilDataLoaded', () => {
        if (rootGetters['feature/isHeaderRedesign']
        && makeGeneralTocFilter({ commit, getters })) {
            // If 'isShowTocLanding' is set, then we should just display entries
            // returned from REST call that match URLs in utdConfigService.tocRoot.items[].url
            if (getters.tocIsShowTocLanding) {
                commit(SET_TOC_NAME, 'Contents');
                commit(SET_TOC_HEADER, '');
                commit(SET_TOC_CSS_HEADER, 'bottomShadow');

                applyGeneralTocFilter(TOC_FILTER_SPECIALTIES, { rootGetters, getters });

                // Inject the 'Topics by Specialty' entry
                getters.tocItems.push({
                    navClass: 'navbarTocSpecialties',
                    name: 'Topics by Specialty',
                    type: 'SPECIALTY',
                    url: localizeAnchor('/contents/', 'table-of-contents',
                        rootGetters['user/language']),
                    display: true
                });

                // Sort ToC entries based on index position in vm.generalTocFilter
                getters.tocItems.sort((a, b) => fnSortTocBase(a, b, { getters }));

                // Inject the 'Drug Interactions' entry for UCC user
                if (rootGetters['app/isUccState']) {
                    getters.tocItems.push({
                        navClass: 'navbarTocDrugInteraction',
                        name: 'Drug Interactions',
                        type: 'SPECIALTY',
                        url: '/drug-interactions?source=responsive_home',
                        display: true
                    });
                }

                // Reset 'isShowTocLanding' for next call
                commit(SET_TOC_IS_SHOW_TOC_LANDING, false);
            }
            else {
                // If 'isShowTocLanding' is not set, then we should only display entries
                // returned from REST call that do NOT match URLs in vm.generalTocFilter...
                // In other words, just display the links to Specialties
                commit(SET_TOC_NAME, 'Topics by Specialty');
                commit(SET_TOC_HEADER, i18next.t('TOC.HEADER_REDESIGN_CONTENTS'));
                applyGeneralTocFilter(TOC_FILTER_LANDING, { rootGetters, getters });
            }
        }
    }, { root: true });
};

const makeGeneralTocFilter = ({ commit, getters }) => {
    if (getters.generalTocFilter.length > 0) {
        return true;
    }
    commit(SET_GENERAL_TOC_FILTER, getters.tocRootItems.map(item => item.url));
    return (getters.generalTocFilter.length > 0);
};

const fnSortTocBase = (a, b, { getters }) => {
    return (getters.generalTocFilter.indexOf(
        getLastPathSegment(a.url))
        - getters.generalTocFilter.indexOf(getLastPathSegment(b.url)));
};

const applyGeneralTocFilter = (tocFilterType, { rootGetters, getters }) => {
    for (let i = 0; i < getters.tocItems.length; i++) {
        applyGeneralTocFilterToItem(tocFilterType, i, { rootGetters, getters });
    }
};

const applyGeneralTocFilterToItem = (tocFilterType, itemIdx, { rootGetters, getters }) => {
    const item = getters.tocItems[itemIdx];

    const urlSegment = getLastPathSegment(item.url);

    if (tocFilterType === TOC_FILTER_LANDING) {
        // Filter out any entry that would display on the root (Landing) ToC page
        // --> Flag item as hidden if last segment of URL matches any vm.generalTocFilter entries
        if (getters.generalTocFilter.indexOf(urlSegment) > -1) {
            item.display = false;
        }
    }
    else { // tocFilterType === TOC_FILTER_SPECIALTIES
        // Filter out any Toc entry that links to any of the specialties
        // Flag item as hidden if last segment of URL does not match any vm.generalTocFilter entries
        const tocRootIndex = getters.generalTocFilter.indexOf(urlSegment);
        if (tocRootIndex < 0 || hideCalculatorsFromTocLanding(urlSegment, { rootGetters })) {
            item.display = false;
        }
        else {	// Store element ID to be rendered to template
            item.navClass = getters.tocRootItems[tocRootIndex].navClass;
            // This conditional is specifically to remove the main nav entries from the
            // displayed ToC home view for only UCC users
            if (getters.tocRootItems[tocRootIndex].featureCheck
            && !getters.tocRootItems[tocRootIndex].featureCheck()) {
                item.display = false;
            }
        }
    }
};

const getLastPathSegment = url => {
    return url.split('/').pop();
};

const hideCalculatorsFromTocLanding = (urlSegment, { rootGetters }) => {
    return (urlSegment === 'calculators' && !rootGetters['app/isUccState']);
};

const resolveError = (error, { getters }) => {
    if (typeof error.status !== 'undefined') {
        Logger.error(`Toc not found matching url: ${JSON.stringify(getters.tocUrl)}
                    Error: ${error.status}
                    text: ${error.statusText}`);
    }
    return false;
};

const toc = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default toc;
export {
    setGeneralToc as _setGeneralToc,
    makeGeneralTocFilter as _makeGeneralTocFilter,
    filterPathwaysTocItems as _filterPathwaysTocItems,
    preserveTocFilter as _preserveTocFilter,
    resolveError as _resolveError
};
