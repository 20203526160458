import utdRestClient from '_acaSrc/utility/http/UtdRestClient';

const restHooks = {};
const addHook = (key, callback) => restHooks[key] = callback;

export default function cmeRest(type, options) {
    return restHooks[type](options);
}

addHook('cme/moc/user/savePersonalInfo', ({ params, data }) => {
    const config = { bypass400: true };
    return utdRestClient.post({
        uri: 'cme/moc/user/setting/json',
        params,
        config,
        data
    });
});

addHook('cme/moc/submission/submissionHistory', options => {
    const { authCode } = options;
    return utdRestClient.get({ uri: `cme/moc/submissions/history/${authCode}/json` });
});

addHook('cme/moc/submission/acknowledgeStatus', options => {
    const { transactionId } = options;
    return utdRestClient.put({ uri: `cme/moc/submission/acknowledgeStatus/${transactionId}/json` });
});

addHook('cme/moc/getSubmissionDetails', ({ transactionId }) => {
    return utdRestClient.get({
        uri: `/cme/moc/submissions/${transactionId}/details/json`
    });
});

addHook('cme/redeem/getAvailableCreditsByTime', payload => {
    const {
        startTime,
        endTime,
        offsetIndex,
        limit,
        reflectedOnly = false,
        returnWhenLimitReached
    } = payload;

    return utdRestClient.get({
        uri: 'cme/unredeemed/json',
        params: {
            startTimeStamp: startTime,
            endTimeStamp: endTime,
            offsetIndex,
            limit,
            reflectedOnly,
            returnWhenLimitReached
        }
    });
});

addHook('cme/redeem/getAvailableCreditsBySearch', payload => {
    const {
        credits,
        offsetIndex,
        limit = '',
        reflectedOnly = false
    } = payload;

    return utdRestClient.get({
        uri: '/cme/unredeemed/mostRecentCredits/json',
        params: {
            credits,
            offsetIndex,
            limit,
            reflectedOnly
        }
    });
});

addHook('cme/redemptions/getPastRedemption', options => {
    const { redemptionId } = options;
    return utdRestClient.get({ uri: `cme/redemptions/${redemptionId}/json` });
});

addHook('cme/redeem/redeemInstantly', () => {
    const config = { bypassAll: true };
    return utdRestClient.post({
        uri: '/cme/redemptions/redeemReflected/search/json',
        config
    });
});

addHook('cme/redeem/redeemTimeBasedAccruals', payload => {
    const { data } = payload;
    return utdRestClient.post({
        uri: '/cme/redeem/time/json',
        data
    });
});

addHook('cme/redeem/redeemSearchBasedAccruals', payload => {
    const { data } = payload;
    return utdRestClient.post({
        uri: '/cme/redeem/search/json',
        data
    });
});

addHook('cme/redeem/saveEvaluationData', payload => {
    const { data } = payload;
    return utdRestClient.post({
        uri: '/cme/redeem/survey/json',
        data
    });
});

addHook('cme/moc/getAuthorityMaintenance', () => {
    return utdRestClient.post({
        uri: '/cme/moc/authority/maintenance/json',
        data: {}
    });
});

addHook('/cme/moc/submitAll', () => {
    const config = { bypassAll: true };

    return utdRestClient.post({
        uri: '/cme/moc/submitAll/json',
        config
    });
});

addHook('cme/settings/getUserTrack', () => {
    return utdRestClient.get({ uri: '/cme/settings/track/json' });
});

addHook('cme/settings/updateUserTrack', options => {
    const { trackCode } = options;
    const uri = `/cme/settings/track?cmeTrackCode=${trackCode}`;
    return utdRestClient.put({ uri });
});

addHook('cme/tracks/getTrackDetails', options => {
    const { trackCode } = options;
    const uri = `/cme/tracks/json?trackCode=${trackCode}`;
    return utdRestClient.get({ uri });
});

addHook('cme/tracks/getAllTracks', () => {
    const uri = '/cme/tracks/json';
    return utdRestClient.get({ uri });
});

addHook('cme/redemptions/getPastRedemptions', options => {
    const { offset, limit, includeCertificateViewability } = options;
    const params = {};
    if (offset) {
        params.offsetIndex = offset;
    }
    if (limit) {
        params.limit = limit;
    }
    params.includeCertificateViewability = includeCertificateViewability;

    return utdRestClient.get({ uri: 'cme/redemptions/json', params });
});

addHook('cme/moc/addBoard', options => {
    const { authUserId, authorityCode } = options;
    const params = {
        authorityId: authorityCode,
        userAuthorityId: authUserId.replace(/-/g, '')
    };
    const config = { bypass400: true };
    return utdRestClient.post({
        uri: '/cme/moc/addBoard/json',
        params,
        config
    });
});

addHook('cme/moc/deleteBoard', mocAuthorityCode => {
    const params = {
        authorityId: `${mocAuthorityCode}`
    };
    return utdRestClient.post({
        uri: '/cme/moc/deleteBoard/json',
        params
    });
});

addHook('cme/moc/submitState', year => {
    const params = { year };
    return utdRestClient.post({
        uri: '/cme/moc/submitState/json',
        params
    });
});

addHook('cme/moc/submitMoc', year => {
    const params = { year };
    return utdRestClient.post({
        uri: '/cme/moc/submitMoc/json',
        params
    });
});

addHook('cme/moc/getEligibleMocCredits', () => {
    const uri = '/cme/moc/credits/json';
    return utdRestClient.get({ uri });
});
