import { SET_FIXED_HTML } from '_acaSrc/store/app.store';
import { getWindow } from '_acaSrc/utility/DOM';
import { makeDoubleClick } from '_acaSrc/utility/Events';
import IScroll from '_acaSrc/utility/iScrollZoom';
import store from '_acaSrc/store';

let gfxScroll = null;
let isDefaultFixed, setFixedHTML;

let resizeListener;
let refreshTimeout;
let initialZoomTimeout;
let resizeZoomTimeout;
let hasSetDoubleTapHandler = false;

export default {
    mounted: () => {
        if (store.getters['app/isDesktopBrowser']
            || store.getters['graphic/toolsIsPrintView']) {
            return false;
        }

        const fncZoom = getProperZoomFunction(store.getters['graphic/graphicSubtype']);
        initialZoomTimeout = setTimeout(fncZoom, 0);
        resizeListener = getWindow().addEventListener('resize', () => {
            if (!store.getters['graphic/toolsIsPrintView']) {
                resizeZoomTimeout = setTimeout(fncZoom, 0);
            }
        });
        isDefaultFixed = store.getters['app/isDefaultFixed'];
        setFixedHTML = fixedHTML =>
            store.commit(`app/${SET_FIXED_HTML}`, fixedHTML);
    },
    beforeUnmount: () => {
        clearTimeout(refreshTimeout);
        clearTimeout(initialZoomTimeout);
        clearTimeout(resizeZoomTimeout);
        getWindow().removeEventListener('resize', resizeListener, { passive: true });
        gfxScroll = null;
        hasSetDoubleTapHandler = false;
    }
};

const zoomElement = options => {
    const { vMaxZoom, vZoomOffset, vInitZoom } = options;
    const innerGraphicWidth = getInnerGraphicWidth();

    if (innerGraphicWidth <= 0) {
        return;
    }

    const windowWidth = getWindow().innerWidth;
    const windowHeight = getWindow().innerHeight;

    setFixedHTMLHelper({ windowWidth, windowHeight });

    const bufferAdjustment = 20;
    const scaleAmt = windowWidth < (innerGraphicWidth + bufferAdjustment)
        ? (windowWidth / (innerGraphicWidth + bufferAdjustment)) * .9
        : 1;

    addScrollContainerToGraphicContainer();
    setGfxScrollScaleAmount({ scaleAmt, vMaxZoom });

    const invertZoom = () => {
        const tx = gfxScroll.pointX;
        const ty = gfxScroll.pointY;
        const current = gfxScroll.scale;

        invertZoomHelper({ scaleAmt, vZoomOffset, vMaxZoom, current, tx, ty });
    };

    gfxScroll.zoom((typeof vInitZoom === 'undefined' ? scaleAmt : vInitZoom), 0, 0, 250);
    gfxScroll.scrollTo(0, 0, 250);

    try {
        if (!hasSetDoubleTapHandler) {
            document.querySelector('#graphicContainer').addEventListener('tap',
                makeDoubleClick(invertZoom, () =>
                    document.querySelectorAll('#tbSearch')[0].blur()));
            hasSetDoubleTapHandler = true;
        }
    }
    catch (e) {
        // ignore because it's not supported
    }

    // Force a delayed refresh after initializing to synch everything
    refreshTimeout = setTimeout(() => !!gfxScroll && gfxScroll.refresh(), 100);
};

const getProperZoomFunction = subtype => subtype !== 'graphic_table' ? zoomGraphic : zoomTable;

// Sets up the graphic for zooming, if applicable
const zoomGraphic = () => zoomElement({ vMaxZoom: 2.5, vZoomOffset: 2.5 });

// Reverse zoom tables so that they can be zoomed out
const zoomTable = () => zoomElement({ vMaxZoom: 1.5, vZoomOffset: 1, vInitZoom: 1 });

const addScrollContainerToGraphicContainer = () => {
    const el = document.querySelector('#graphicContainer');
    el && el.classList.add('scrollerContainer');
};

const setGfxScrollScaleAmount = ({ scaleAmt, vMaxZoom }) => {
    if (gfxScroll) {
        gfxScroll.options.zoomMin = scaleAmt;
    }
    else {
        // eslint-disable-next-line no-undef
        gfxScroll = new IScroll('#graphicContainer', {
            zoom: true,
            zoomMax: vMaxZoom,
            zoomMin: scaleAmt,
            scrollX: true,
            scrollY: true,
            mouseWheel: false,
            wheelAction: 'zoom',
            freeScroll: true,
            click: true,
            tap: true,
            HWCompositing: false // slower, but much better quality text
        });
    }
};

const invertZoomHelper = ({ scaleAmt,
    vZoomOffset,
    vMaxZoom,
    current,
    tx,
    ty }) => {
    if (current > (vZoomOffset + scaleAmt) / 2) {
        gfxScroll.zoom(scaleAmt, tx, ty, 350);
    }
    else {
        gfxScroll.zoom(vMaxZoom, tx, ty, 350);
    }
};

const setFixedHTMLHelper = ({ windowWidth, windowHeight }) => {
    if (windowWidth > windowHeight) {
        setFixedHTML('fixedHTML');
    }
    else if (!isDefaultFixed) {
        setFixedHTML('');
    }
};

const getInnerGraphicWidth = () => {
    const gfxDivs = document.querySelectorAll('.graphic > div');
    let innerGraphicWidth = 0;
    // Scan for all graphics contained on page
    for (let gfxI = 0; gfxI < gfxDivs.length; gfxI++) {
        const curWdt = gfxDivs[gfxI].offsetWidth;
        if (curWdt > innerGraphicWidth) {
            innerGraphicWidth = curWdt;
        }
    }
    return innerGraphicWidth;
};
