<template>
  <div class="utd-bookmark">
    <utd-button class="bookmark-control"
                :class="[{bookmarkActive},
                         {'bookmark-remove-focus': bookmarkRemoveFocus}]"
                button-style="text"
                :button-icon="bookmarkIcon"
                v-bind="$attrs"><span class="toolbarText">{{ $t('MYUPTODATE.BOOKMARK') }}</span></utd-button>
  </div>
</template>

<script>
import { BookmarkTooltip } from '_acaSrc/utility/tooltip';
import { getWindow } from '_acaSrc/utility/DOM';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import UtdButton from '../stdlib/UtdButton.vue';
import PubSub from '_acaSrc/utility/PubSub';
import {
    SET_TOOLTIP_SHOW,
    INITIALIZE_BOOKMARK_TOOLTIP,
    CLEANUP_BOOKMARK_TOOLTIP
} from '_acaSrc/store/profile.store';

const TOOLTIP_CLOSE_HTML = '<span class="wkce-icon-filled-close"></span>';
const TOOLTIP_SCROLL_DELAY_MS = 500;
const TOOLTIP_RENDER_DELAY_MS = 1000;

export default {
    components: { UtdButton },
    inheritAttrs: false,
    props: {
        bookmarkActive: {
            type: Boolean,
            default: false
        },
        bookmarkTooltip: {
            type: Object
        },
        // Required due to z-indexing issues on modals
        bookmarkOnModal: {
            type: Boolean,
            default: false
        },
        // Removing the focus to maintain consistency
        bookmarkRemoveFocus: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'tooltip-closed' ],
    data() {
        return {
            tooltip: null
        };
    },
    computed: {
        ...mapGetters('app', [ 'tooltipConfig', 'isPrintView' ]),
        ...mapGetters('profile', [ 'isBookmarkTooltipActive' ]),
        bookmarkIcon() {
            return `${this.bookmarkActive ? 'filled-' : ''}bookmark`;
        },
        tooltipMessage() {
            return this.bookmarkTooltip && this.bookmarkTooltip.message;
        },
        tooltipVisible() {
            return this.bookmarkTooltip && this.bookmarkTooltip.show;
        },
        tooltipActive() {
            return this.tooltip && this.isBookmarkTooltipActive(this.bookmarkTooltipId);
        },
        tooltipEnabled() {
            return this.tooltip && this.tooltip.isEnabled();
        }
    },
    watch: {
        tooltipVisible(newVal) {
            if (this.tooltipEnabled && this.tooltipActive) {
                if (newVal) {
                    this.activateTooltip();
                }
                else {
                    this.tooltip.hideTooltip();
                }
            }
        },
        tooltipMessage(newVal, oldVal) {
            if (this.tooltip && newVal !== oldVal && newVal !== '') {
                if (this.tooltipEnabled) {
                    this.tooltip.hideTooltip();
                    if (this.tooltipActive) {
                        this.activateTooltip();
                    }
                }
            }
        }
    },
    async mounted() {
        // Scroll handler required to correctly reposition tooltip for topic view
        getWindow().addEventListener('scroll', this.onScroll);
        new PubSub().subscribe('wkutd.next/previousModalGraphic', this.onScroll);
        // Don't initialize the tooltip for print preview
        if (this.isPrintView) {
            return;
        }

        await this.confirmConfigLoaded();
        this.tooltip = new BookmarkTooltip(this.$el, this.tooltipConfig);
        this.setupTooltip();
    },
    beforeUnmount() {
        getWindow().removeEventListener('scroll', this.onScroll);
        if (this.tooltipEnabled) {
            getWindow().clearTimeout(this.tooltipRenderTimeout);
            getWindow().clearTimeout(this.scrollTimeout);
            this.tooltip.hideTooltip();
            this[CLEANUP_BOOKMARK_TOOLTIP]();
        }
        new PubSub().unsubscribe('wkutd.next/previousModalGraphic', this.onScroll);
    },
    methods: {
        ...mapActions('app', [ 'confirmConfigLoaded' ]),
        ...mapMutations('profile', [
            SET_TOOLTIP_SHOW,
            INITIALIZE_BOOKMARK_TOOLTIP,
            CLEANUP_BOOKMARK_TOOLTIP
        ]),
        setupTooltipId() {
            // tooltipId is passed on the tooltipClosedCallback handler
            let bookmarkId = this.$attrs.id;
            bookmarkId = bookmarkId || `UtdBookmark${this.uuid}`;
            this.tooltipId = `${bookmarkId}-utdTooltip`;
        },
        setupTooltip() {
            this.setupTooltipId();

            this.tooltip.initialize().then(tooltipId => {
                // As we should only ever have one bookmark tooltip visible at a time,
                // we insert each new tooltip ID into the activeBookmarkTooltips stack.
                // The tooltip ID at index 0 should be treated as the only "active" tooltip.
                this.bookmarkTooltipId = tooltipId;
                this[INITIALIZE_BOOKMARK_TOOLTIP](tooltipId);

                this.tooltip.onClick(this.onCloseTooltip);
                // Required due to z-indexing issues on modals
                if (this.bookmarkOnModal) {
                    this.tooltip.setBookmarkOnModal();
                }
                if (this.tooltipVisible) {
                    this.activateTooltip();
                }
            }, () => {
                // Ignore rejections
            });
        },
        activateTooltip() {
            getWindow().clearTimeout(this.tooltipRenderTimeout);
            this.tooltipRenderTimeout = getWindow().setTimeout(() => {
                this.tooltipRenderTimeout = null;
                this.setTooltipMessage();
                this.tooltip.showTooltip();
            }, TOOLTIP_RENDER_DELAY_MS);
        },
        setTooltipMessage() {
            this.tooltip.setTooltipContent(`${this.bookmarkTooltip.message}${TOOLTIP_CLOSE_HTML}`);
        },
        onScroll() {
            if (this.tooltipEnabled && this.tooltipVisible && !this.tooltipRenderTimeout) {
                this.tooltip.hideTooltip();
                getWindow().clearTimeout(this.scrollTimeout);
                this.scrollTimeout = getWindow().setTimeout(() => {
                    this.tooltip.showTooltip();
                }, TOOLTIP_SCROLL_DELAY_MS);
            }
        },
        onCloseTooltip() {
            this.tooltip.hideTooltip();
            this[SET_TOOLTIP_SHOW](false);
            this.$emit('tooltip-closed', this.tooltipId);
        }
    }
};
</script>

<style  lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-BOOKMARK-TOOLTIP-CLOSE-ICON-COLOR: @WKCE-GRAY-TINT3;
@UTD-BOOKMARK-TOOLTIP-ARROW-BORDER-COLOR: rgba(0,8,16,0.2);

.tippy-box[data-theme~='utd-tooltip-interactive-bookmark'] {
  font-size: 12.8px;
  padding: 5px;
  border-width: 2px;

  .tippy-content {
    padding-right: 22px;

    .wkce-icon-filled-close::before {
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      color: @UTD-BOOKMARK-TOOLTIP-CLOSE-ICON-COLOR;
      right: 4px;
      top: 4px;
    }
  }

  &[data-placement^=bottom] {
    & > .tippy-arrow {
      &::before {
        border-bottom-color: @UTD-BOOKMARK-TOOLTIP-ARROW-BORDER-COLOR;
      }
    }
  }
}

</style>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-bookmark {
  .utd-button {
    font-size: 13px;
    line-height: 26px;
    height: 26px;
    padding: 0;

    &:hover,
    &:active {
      text-decoration: none;
    }

    :deep([class*=wkce-icon]) {
      font-size: 13px;
    }

    @media only screen and (min-width: 768px) {
      .isTablet & {
        font-size: 14px;

        :deep([class*=wkce-icon]) {
          font-size: 14px;
        }
      }

      .isDesktop & {
        font-size: 16px;
        line-height: 31px;
        height: 31px;

        :deep([class*=wkce-icon]) {
          font-size: 16px;
        }
      }
    }
  }
  display: inline-block;

  .bookmarkActive :deep([class^='wkce-icon-']) {
    color: @DS1-UTD-BOOKMARK-ACTIVE-COLOR;
  }

  .bookmark-remove-focus {
    &:focus {
      outline: none;
    }
  }
}

</style>
