import Logger from '_acaSrc/utility/Logger';
import WkceDates from '_acaSrc/utility/Dates';

/*
 * Support method to replace any topicsReviewed from CME accruals
 * where 'title' property is missing, with localization string
 * CONTENT.TOPIC_NOT_AVAILABLE
 */
export const validateReviewedTopics = (accruals, detailInfo, topicNotAvailableMessage = '') => {
    try {
        return accruals.map(accrual => {
            accrual.topicsReviewed = accrual.topicsReviewed.map(topic => {
                return {
                    title: topic.title ? topic.title : topicNotAvailableMessage,
                    url: topic.url
                };
            });
            return accrual;
        });
    }
    catch (e) {
        Logger.warn(`validateReviewedTopics Error: [${e}] - ${detailInfo}`);
        return [];
    }
};

/*
 * Getter method to return CME redemption data
 * mapping displayed in desktop modal components.
 */
export const getAccrualsWithColumns = (accruals, detailInfo) => {
    try {
        return accruals.map(accrual => {
            const columns = [
                {
                    data: [ accrual.credits ]
                },
                {
                    data: [ accrual.searchTerm ],
                    showTooltip: true
                },
                {
                    data: accrual.topicsReviewed.map(topicReviewed => topicReviewed.title),
                    links: accrual.topicsReviewed.map(topicReviewed => topicReviewed.url),
                    listStyle: 'disc'
                },
                {
                    data: [ accrual.searchGoal ],
                    showTooltip: true
                },
                {
                    data: [ accrual.application ],
                    showTooltip: true
                },
                {
                    data: [ WkceDates.dateToMDYUSLocale(accrual.dateSearched) ]
                },
                {
                    data: [ WkceDates.dateToMDYUSLocale(accrual.dateRedeemed) ]
                }
            ];
            return {
                accrual,
                columns
            };
        });
    }
    catch (e) {
        Logger.warn(`getAccrualsWithColumns Error: [${e}] - ${detailInfo}`);
        return [];
    }
};

export const validateAuthorities = (list, authorityCodeKey = 'authorityCode') => {
    if (!list) {
        // Returning true instead of false here as we only want to
        // consider the array invalid if any of the elements are
        // missing the authorityCodeKey property. Otherwise we
        // need for the other steps to be processed.
        return true;
    }
    const validCount = (list || []).reduce((valid, authority) => {
        valid += authority[authorityCodeKey] ? 1 : 0;
        return valid;
    }, 0);

    const isValid = validCount === list.length;
    if (!isValid) {
        Logger.warn(
            `Invalid MOC authority data, missing ${
                authorityCodeKey
            } property - unable to setMocAuthorityData()`
        );
    }
    return isValid;
};


export const sortAuthorities = (list, authorityCodeKey = 'authorityCode') => {
    return list && list.sort((a, b) => {
        return a[authorityCodeKey].localeCompare(b[authorityCodeKey]);
    });
};

export const addTypeToUserAuthorities = (authorities, userAuthorities) => {
    return authorities && userAuthorities
        && userAuthorities.forEach(userAuthority => {
            const a = authorities.find(a => {
                return a.authorityCode === userAuthority.mocAuthorityCode;
            });
            if (a) {
                userAuthority.type = a.type;
            }
        });
};

export const hasActiveBoards = userAuthorities => {
    if (userAuthorities.length === 0) {
        return false;
    }
    return userAuthorities
        && userAuthorities.length
        && (userAuthorities.filter(userAuth => userAuth.active).length > 0);
};
