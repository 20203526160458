<template>
  <footer v-if="isFooterLoaded" role="contentinfo" class="wk-footer">
    <div class="wk-footer-container">
      <div class="wk-footer-nav">
        <div class="wk-footer-nav-list">
          <section>
            <h3 class="wk-footer-heading">{{ $t('PROSPECT_FOOTER.COMPANY') }}</h3>
            <ul>
              <li>
                <a :href="getFooterUrl('aboutUs')">
                  {{ $t('PROSPECT_FOOTER.ABOUT_US') }}
                </a>
              </li>
              <li>
                <a :href="getFooterUrl('editorialPolicy')">
                  {{ $t('PROSPECT_FOOTER.EDITORIAL_POLICY') }}
                </a>
              </li>
              <li>
                <a :href="getFooterUrl('testimonials')">
                  {{ $t('PROSPECT_FOOTER.TESTIMONIALS') }}
                </a>
              </li>
              <li>
                <a v-if="!isUccState"
                   :href="getFooterUrl('woltersKluwer')"
                   class="wk-icon wk-arrow-up-right"
                   target="_blank"
                   rel="noopener">
                  Wolters Kluwer
                </a>
              </li>
              <li>
                <a v-if="!isUccState"
                   :href="getFooterUrl('careers')"
                   target="_blank"
                   rel="noopener"
                   class=" wk-icon wk-arrow-up-right">
                  {{ $t('PROSPECT_FOOTER.CAREERS') }}
                </a>
              </li>
            </ul>
          </section>
          <section>
            <h3 class="wk-footer-heading">{{ $t('PROSPECT_FOOTER.SUPPORT') }}</h3>
            <ul>
              <li>
                <a :href="getFooterUrl('contactUs')">
                  {{ $t('PROSPECT_FOOTER.CONTACT_US') }}
                </a>
              </li>
              <li>
                <a :href="getFooterUrl('helpTraining')">
                  {{ $t('PROSPECT_FOOTER.HELP_TRAINING') }}
                </a>
              </li>
              <li>
                <a :href="getFooterUrl('citingOurContent')">
                  {{ $t('PROSPECT_FOOTER.CITING_CONTENT') }}
                </a>
              </li>
            </ul>
          </section>
        </div>
        <div class="wk-footer-nav-list">
          <h3 class="wk-footer-heading">{{ $t('PROSPECT_FOOTER.NEWS_EVENTS') }}</h3>
          <ul>
            <li>
              <a v-if="!isUccState" :href="getFooterUrl('whatsNew')">
                {{ $t('HEADER.WHATS_NEW') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang && !isUccState"
                 :href="getFooterUrl('clinicalPodcasts')">
                {{ $t('PROSPECT_FOOTER.CLINICAL_PODCASTS') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang || isUccState"
                 :href="getFooterUrl('pressAnnouncements')">
                {{ $t('PROSPECT_FOOTER.PRESS_ANNOUNCEMENTS') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang || language === C_LANGUAGES_NAMES.PT.CODE || isUccState"
                 :href="getFooterUrl('intheNews')">
                {{ $t('PROSPECT_FOOTER.IN_NEWS') }}
              </a>
            </li>
            <li>
              <a v-if="!isUccState"
                 :href="getFooterUrl('events')">
                {{ $t('PROSPECT_FOOTER.EVENTS') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="wk-footer-nav-list">
          <h3 class="wk-footer-heading">{{ $t('PROSPECT_FOOTER.RESOURCES') }}</h3>
          <ul>
            <li>
              <a :href="getFooterUrl('uptodateLogin')">
                {{ $t('PROSPECT_FOOTER.UPTODATE_LOGIN') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang && !isUccState"
                 :href="getFooterUrl('cme')">
                {{ $t('PROSPECT_FOOTER.CME_CE_CPD') }}
              </a>
            </li>
            <li>
              <a :href="getFooterUrl('mobileApps')">
                {{ $t('PROSPECT_FOOTER.MOBILE_APPS') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang && !isUccState"
                 :href="getFooterUrl('webinars')">
                {{ $t('PROSPECT_FOOTER.WEBINARS') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang && !isUccState"
                 :href="getFooterUrl('EHRIntegration')">
                {{ $t('PROSPECT_FOOTER.EHR_INTEGRATION') }}
              </a>
            </li>
            <li>
              <a v-if="isEngLang && !isUccState"
                 :href="getFooterUrl('healthIndustryPodcasts')">
                {{ $t('PROSPECT_FOOTER.HEALTH_INDUSTRY_POD') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="wk-footer-nav-list">
          <div v-if="isUccState" class="ucc-chat-image" />
          <h3 v-if="!isUccState"
              class="wk-footer-heading">
            {{ $t('PROSPECT_FOOTER.FOLLOW_US') }}
          </h3>
          <ul v-if="!isUccState" class="wk-social-icons-container">
            <li>
              <a href="https://www.facebook.com/UpToDateEBM"
                 class="wk-icon wk-social-icon wk-icon-facebook"
                 target="_blank"
                 rel="noopener noreferrer" />
            </li>
            <li>
              <a href="https://twitter.com/UpToDate"
                 class="wk-icon wk-social-icon wk-icon-twitter"
                 target="_blank"
                 rel="noopener noreferrer" />
            </li>
            <li>
              <a href="https://www.linkedin.com/company/uptodate?trk=hb_tab_compy_id_21924"
                 class="wk-icon wk-social-icon wk-icon-linkedin"
                 target="_blank"
                 rel="noopener" />
            </li>
            <li>
              <a href="https://www.youtube.com/uptodateebm"
                 class="wk-icon wk-social-icon wk-icon-youtube"
                 target="_blank"
                 rel="noopener noreferrer" />
            </li>
          </ul>
          <div class="wk-navbar">
            <ul class="wk-nav utd-right">
              <li v-if="!isUccState">{{ $t('PROSPECT_FOOTER.SIGN_TODAY_DESC') }}</li>
              <li role="presentation" class="wk-green">
                <a :href="getFooterUrl('signUp')">{{ $t('PROSPECT_FOOTER.SIGN_UP') }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <a class="wk-logo" href="/home">
        <img src="https://cdn.wolterskluwer.io/wk-logos/1.0.x/wk-brand-white.svg">
      </a>
      <div class="wk-tagline">When you have to be right</div>
    </div>
    <div class="wk-footer-copyright">
      <div class="wk-footer-copyright-container">
        <ul class="wk-footer-meta-info">
          <li>
            <a :href="getFooterUrl('privacyPolicy')">
              {{ $t('PROSPECT_FOOTER.PRIVACY_POLICY') }}
            </a>
          </li>
          <li>
            <a :href="getFooterUrl('trademarks')">
              {{ $t('PROSPECT_FOOTER.TRADEMARKS') }}
            </a>
          </li>
          <li>
            <a :href="getFooterUrl('subscriptionLicense')"
               :target="getFooterTarget('subscriptionLicense')"
               :rel="getFooterRel('subscriptionLicense')">
              {{ $t('PROSPECT_FOOTER.SUBSCRIPTION_LICENCE') }}
            </a>
          </li>
          <li v-if="showManageCookiesLink">
            <a id="ot-sdk-btn" class="ot-sdk-show-settings">
              {{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}
            </a>
          </li>
          <utd-copyright-provider :full="true" :center="true" />
          <li class="nowrap">
            <span>{{ $t('SUPPORTTAG.LICENSEDTO') }}</span><span>: {{ licensee }}</span>
          </li>
          <li>
            <span>{{ $t('SUPPORTTAG.SUPPORTTAG') }}</span>
            <span>
              : {{ supportTag.serverTag }}
              <span class="SupportTagSmall"> - SM</span>
              <span class="SupportTagMedium"> - MD</span>
              <span class="SupportTagLarge"> - LG</span>
              <span class="SupportTagXLarge"> - XL</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { C_LANGUAGES_NAMES } from '_acaSrc/utility/constants';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import { rebindCookieManagerLink } from '_acaSrc/utility/OneTrustCookieTool';

export default {
    components: {
        UtdCopyrightProvider
    },
    data() {
        return {
            C_LANGUAGES_NAMES
        };
    },
    computed: {
        ...mapGetters('app', [
            'isUccState'
        ]),
        ...mapGetters('footer', [
            'getFooterUrl',
            'getFooterTitle',
            'getFooterTarget',
            'getFooterRel',
            'isFooterLoaded',
            'supportTag',
            'licensee'
        ]),
        ...mapGetters('device', [ 'showManageCookiesLink' ]),
        ...mapGetters('user', [ 'language' ]),
        isEngLang() {
            return this.language === C_LANGUAGES_NAMES.EN.CODE;
        }
    },
    mounted() {
        this.setup();
    },
    beforeUnmount() {
        this.unsubscribe({
            eventName: 'RESPONSIVE_TRANSITION',
            handlerFn: this.onResponsiveTransition
        });
    },
    methods: {
        ...mapActions('app', [
            'subscribe',
            'unsubscribe'
        ]),
        setup() {
            this.subscribe({
                eventName: 'RESPONSIVE_TRANSITION',
                handlerFn: this.onResponsiveTransition
            });
        },
        onResponsiveTransition() {
            rebindCookieManagerLink();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-footer .wk-footer-container .wk-footer-nav {
  .wk-footer-nav-list {
    float: none;
    width: auto;
    margin: 0 0 2rem;

    .utd-right {
      float: none !important;
    }
  }
}

.wideProspectFooter() {
  .wk-footer .wk-footer-container .wk-footer-nav {
    .wk-footer-nav-list {
      float: left;
      width: 23.72881%;

      &:nth-child(4n) {
        float: right;
      }
    }
  }
}

@media (min-width: 77rem) {
  .wk-footer .wk-footer-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 37.5rem) {
  .wkProspect {
    .isDesktop.utd-logo-cc {
      footer.wk-footer {
        .wk-footer-container {
          .utd-right {
            background-color: @WKCE-GRAY-TINT1 !important;
            padding-left: 0;
          }
        }
      }
    }
  }

  //wk-footer css
  .wk-footer {
    opacity: 1;
    display: block;
    margin: 0;

    .ucc-chat-image {
      display: block;
      height: 120px;
      width: 120px;
      background: url('~_acaAssets/footer/weChat.png') no-repeat 50% 50% !important;
    }

    .pace-running & {
      display: none;
    }

    .wk-footer-copyright {
      a,
      span {
        font-size: 0.9375rem;
      }

      .copyright {
        background: none;
        font-size: 0.9375rem;
        color: @WKCE-GRAY-TINT1;
        padding: 8px 0 !important;
        cursor: default;
        display: inline-block !important;
      }

      ul li a {
        color: @WKCE-GRAY-TINT1 !important;
        /* Styles below include the !important rule to override styles applied
        to the button via the OneTrust script, which also use !important */
        &#ot-sdk-btn.ot-sdk-show-settings {
          color: @WKCE-GRAY-TINT1 !important;
          border: none !important;
          font-size: 0.9375rem; ;
          padding: 8px 0;
          display: inline-block;
          &:hover {
            background: none !important;
            color: @WKCE-GRAY-TINT1 !important;
          }
        }
      }


    }

    .wk-footer-nav-list {
      section {
        margin: 0 0 2rem;
      }

      ul {
        li {
          margin-bottom: 0.5rem;
          display: list-item;

          a {
            color: @WKCE-GRAY-TINT6 !important;
            font-size: 0.87891rem;
            display: inline !important;
          }

          .wk-icon {
            &::after {
              font-family: "wk-icons-open";
            }
          }

          .wk-social-icon {
            font-size: 20px;
          }

          .wk-arrow-up-right {
            &::after {
              content: '\e909';
              color: @DS1-UTD-DISABLED-TEXT-COLOR;
              margin-left: 3px;
              display: inline-block;
            }
          }

          .wk-icon-facebook {
            &::after {
              content: '\e9f7';
            }
          }

          .wk-icon-twitter {
            &::after {
              content: '\e9f8';
            }
          }

          .wk-icon-linkedin {
            &::after {
              content: '\e9f9';
            }
          }

          .wk-icon-youtube {
            &::after {
              content: '\e9fa';
            }
          }
        }
      }

      .wk-social-icons-container {
        li {
          display: inline-block;

          a:hover {
            text-decoration: none;
            color: @WKCE-GREEN !important;
          }
        }
      }

      .wk-navbar {
        z-index: 0;

        .utd-right {
          background: @WKCE-GRAY;
          margin-top: 20px;
          padding: 20px;
          display: block;

          li {
            margin-bottom: 30px;
            text-align: left;
            font-size: 0.87891rem !important;
            color: @WKCE-GRAY-TINT6;
            margin-left: 0;

            a {
              display: inline-block !important;
            }
          }
        }
      }
    }

    .wk-tagline {
      float: right;
      font-size: 1.13778rem;
      margin-top: 0;
      position: relative;
      top: 7px;
    }
  }
}

//Break points for prospect footer. 1 column content breaks into 4 columns.
@media (min-width: 808px) {
  .en:not(.topicView),
  .ja:not(.topicView),
  .zh-Hans:not(.topicView),
  .zh-Hant:not(.topicView) {
    .wideProspectFooter();
  }
}

@media (min-width: 908px) {
  .es:not(.topicView),
  .fr:not(.topicView),
  .in:not(.topicView),
  .ru:not(.topicView),
  .it:not(.topicView),
  .ko:not(.topicView),
  .nl:not(.topicView),
  .pl:not(.topicView),
  .tr:not(.topicView),
  .vi:not(.topicView),
  .pt:not(.topicView) {
    .wideProspectFooter();
  }
}

@media (min-width: 968px) {
  .de:not(.topicView) {
    .wideProspectFooter();
  }
}

@media (min-width: 1167px) {
  .en.topicView,
  .ja.topicView,
  .zh-Hans.topicView {
    .wideProspectFooter();
  }
}

@media (min-width: 1267px) {
  .es.topicView,
  .pt.topicView {
    .wideProspectFooter();
  }
}

@media (min-width: 1327px) {
  .de.topicView {
    .wideProspectFooter();
  }
}
</style>