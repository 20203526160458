import { getDocument, getNavigator, getWindow } from './DOM';

let _utdCacheInstance = null;

export default class UtdCache {
    constructor() {
        if (_utdCacheInstance) {
            return _utdCacheInstance;
        }

        _utdCacheInstance = this;

        this.cookiesDisabled = false;
        this.doCacheHitEvent = false;

        try {
            this.persistentCache = (typeof getWindow().localStorage === 'undefined')
                ? undefined : getWindow().localStorage;
            this.sessionCache = (typeof getWindow().sessionStorage === 'undefined')
                ? undefined : getWindow().sessionStorage;
        }
        catch (err) {
            // If we get to here, assume that cookies are disabled
            this.cookiesDisabled = true;
        }
        this.supported = !(typeof this.persistentCache === 'undefined');
    }

    /**
     * Pass any type of a string from the localStorage to be parsed
     * so it returns a usable version (like an Object)
     * @param res - a string that will be parsed for type
     * @returns {*} - whatever the real type of stored value was
     */
    parseValue(res) {
        let val;
        try {
            val = JSON.parse(res);
            if (typeof val === 'undefined') {
                val = res;
            }
            if (val === 'true') {
                val = true;
            }
            if (val === 'false') {
                val = false;
            }
            if (getWindow().parseFloat(val) === val && !(typeof val === 'object')) {
                val = getWindow().parseFloat(val);
            }
        }
        catch (e) {
            val = res;
        }
        return val;
    }

    checkStorage() {
        // When Safari (OS X or iOS) is in private browsing mode it appears as though localStorage
        // is available, but trying to call .setItem throws an exception below:
        // "QUOTA_EXCEEDED_ERR: DOM Exception 22:
        //              An attempt was made to add something to storage that exceeded the quota."
        let retval = true;
        const testKey = `__${Math.round(Math.random() * 1e7)}`;

        try {
            localStorage.setItem(testKey, testKey);
            localStorage.removeItem(testKey);
        }
        catch (err) {
            retval = false;
        }
        return retval;
    }

    checkCookies() {
        let retval = true;
        try {
            let cookieEnabled = getNavigator().cookieEnabled;
            if (typeof getNavigator().cookieEnabled === 'undefined' && !cookieEnabled) {
                getDocument().cookie = 'testcookie';
                cookieEnabled = (getDocument().cookie.indexOf('testcookie') !== -1);
            }
            retval = cookieEnabled;
        }
        catch (err) {
            retval = false;
        }
        return retval;
    }

    init() {
        if (this.supported) {
            if (!this.checkStorage()) {
                this.supported = false;
            }
            else if (!this.checkCookies()) {
                this.supported = false;
                this.cookiesDisabled = true;
            }
        }
    }

    /**
    * pruneExpiredInvalidCache - removes old or invalid cache entries
    * @param version - the content version to compare each entry against, if not equal then removed
    */
    // eslint-disable-next-line complexity
    pruneExpiredInvalidCache(version) {
        if (this.supported) {
            const purge = [];
            for (let i = 0; i < localStorage.length; ++i) {
                const curKey = localStorage.key(i);
                const item = this.persistentCache.getItem(curKey);
                if (item) {
                    const cd = this.parseValue(item);
                    if ((typeof cd.contentVersion !== 'undefined')
                        && (cd.contentVersion !== version)) {
                        purge.push(curKey);
                    }
                    else {
                        const now = new Date();
                        if (cd.expires < now.getTime()) {
                            purge.push(curKey);
                        }
                    }
                }
            }
            if (purge.length > 0) {
                for (let k = 0; k < purge.length; k++) {
                    this.removePersistent(purge[k]);
                }
            }
        }
    }

    /**
     * setPersistent - store a persistent localStorage key/value pair
     * @param key - a string that will be used as the accessor for the pair
     * @param value - the value of the localStorage item
     * @param expires - number of days for cache entry to exist,
     *                  if 0 is passed then saves to session only
     * @param version - the content version associated with this entry.
*                      if this is null, then it is not relevant.
     * @returns {*} - will return whatever it is you've stored in the local storage
     */
    setPersistent(key, value, expires, version) {
        let expVal = 0;
        try {
            if (expires) {
                const now = new Date();
                expVal = now.setDate(now.getDate() + expires);
            }
        }
        // eslint-disable-next-line no-empty
        catch (e) {}
        const cacheObj = {
            expires: expVal,
            contentVersion: version,
            cacheData: value
        };
        const jsonVal = JSON.stringify(cacheObj);
        if (this.supported) {
            try {
                this.persistentCache.setItem(key, jsonVal);
                return this.parseValue(jsonVal);
            }
            catch (e) {
                this.supported = false;
            }
        }
    }

    /**
     * getPersistent - attempts to retrieve persistent localStorage value
     * @param key - the string that you set as accessor for the pair
     * @returns {*} - Object,String,Float,Boolean depending on what you stored
     */
    getPersistent(key) {
        if (!this.cookiesDisabled) {
            let item = null;
            if (this.supported) {
                item = this.persistentCache.getItem(key);
                if (item) {
                    const cacheObj = this.parseValue(item);
                    item = cacheObj.cacheData;
                }
                return this.parseValue(item);
            }
        }
    }

    /**
     * Remove - let's you nuke a value from localStorage
     * @param key - the accessor value
     * @returns {boolean} - if everything went as planned
     */
    removePersistent(key) {
        if (this.supported) {
            this.persistentCache.removeItem(key);
            return true;
        }
    }

    /**
     * setSession - store a sessionStorage key/value pair
     * @param key - unique accessor for pair
     * @param value - value to store into sessionStorage
     * @returns {*} - will return whatever it is you've stored into sessionStorage
     */
    setSession(key, value) {
        if (this.supported) {
            const jsonVal = JSON.stringify(value);
            this.sessionCache.setItem(key, jsonVal);
            return this.parseValue(jsonVal);
        }
    }

    /**
     * getSession - retrieve a sessionStorage value
     * @param key - unique accessor for pair
     * @returns {*} - will return whatever it is you've stored into sessionStorage
     */
    getSession(key) {
        if (!this.cookiesDisabled) {
            let item = null;
            if (this.supported) {
                item = this.sessionCache.getItem(key);
                return this.parseValue(item);
            }
        }
    }

    removeSession(key) {
        if (this.supported) {
            this.sessionCache.removeItem(key);
            return true;
        }
    }

    /**
     * Clear All - let's you clear out ALL localStorage
    *              & sessionStorage variables, use this carefully!
     * !!!! THIS WILL REMOVE ALL UTD ENTRIES,
     *          INCLUDING SETTINGS, BOOKMARKS, PERSONALIZATION, ETC... !!!!
     */
    clearAll() {
        if (this.supported) {
            this.persistentCache.clear();
            this.sessionCache.clear();
        }
    }

    /**
     * Destroy - This function is to reset the instance of utdCache in the unit test
     */
    _destroy() {
        _utdCacheInstance = null;
    }
}
