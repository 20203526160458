import { getWindow } from '_acaSrc/utility/DOM';

// Automatically adds attributes:
// > target="_blank"
// > rel="noopener noreferer"
//
// To anchor tags where the domain does not match the browser's host domain.
export default {
    mounted: elem => {
        if (elem.tagName !== 'A') {
            return;
        }

        const parser = document.createElement('a');
        parser.setAttribute('href', elem.getAttribute('href'));
        const currentTarget = elem.getAttribute('target');
        if (parser.host !== getWindow().location.host
            && (!currentTarget || currentTarget === '_blank')) {
            elem.setAttribute('target', '_blank');
            elem.setAttribute('rel', 'noopener noreferer');
        }
    }
};
