import store from '_acaSrc/store';

const vuexUtils = {
    getStore
};

export default vuexUtils;

export function getStore() {
    return store;
}