<template>
  <div v-if="searchBarBody" id="promo-container">
    <utd-promo-box class="disclaimer" :category="'home'" :type="'specific-messaging'" />
  </div>
</template>

<script>
import UtdPromoBox from '_acaSrc/components/promobox/PromoBox.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'HomeSearchDisclaimer',
    components: {UtdPromoBox},
    computed: {
        ...mapGetters('search', [ 'searchBarBody' ])
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utdWkHomePage2024 #promo-container {
  background-color: @WKCE-BLUE-SHADE2;

  #promobox-container {
    .ds1-pv-2();
    .ds1-mv-0();
    width: 85vw;

    @media only screen and (max-width: 768px) {
      width: unset;
      margin: unset;
    }

    #promobox-left {
      & > h3 {
        color: @WKCE-BLUE-TINT5;
      }

      .utdOnly, .uccOnly {
        line-height: 14px;
        font-size: 11px;
        color: @WKCE-BLUE-TINT3;

        strong {
          color: @WKCE-WHITE;
        }

        & > a {
          color: @WKCE-BLUE-TINT3;
        }
      }

      .isTablet& {
        .ds1-ma-0();
      }
    }

    .isTablet& {
      width: auto;
      margin: 0 16px;

      @media screen and (orientation: landscape) {
        width: unset;
      }
    }
  }
}
</style>