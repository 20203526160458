import { C_LANGUAGES_LIST, C_LANGUAGES_NAMES } from '_acaSrc/utility/constants';

export function localizeAnchor(hrefLeft, hrefRight, langauge) {
    let locale = '';
    if (langauge !== C_LANGUAGES_NAMES.EN.CODE) {
        locale = langauge;
    }
    if (locale && hrefRight.indexOf('/') !== 0) {
        locale += '/';
    }
    return `${hrefLeft}${locale}${hrefRight}`;
}

export function isMarketingLanguage(language) {
    return C_LANGUAGES_LIST.some(lang => {
        return lang.marketing && lang.value === language;
    });
}