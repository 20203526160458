import { C_SEARCH } from '_acaSrc/utility/constants';

export default class GraphicsPanelResults {
    constructor(id, type, title, markupTitle, searchResults, {
        searchRank,
        rankIndex,
        searchTerm
    } = {}) {
        this.id = id;
        this.type = type || '';
        this.title = title;
        this.markupTitle = markupTitle;
        this.searchRank = searchRank;
        this.rankIndex = rankIndex;
        this.searchTerm = searchTerm;
        this.featuredGraphics = this.setFeaturedGraphics(searchResults) || [];
    }

    setFeaturedGraphics(searchResults) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .map(result => {
                    return {
                        imageKey: result.id,
                        type: result.type,
                        title: result.title,
                        rankIndex: this.rankIndex,
                        searchRank: this.searchRank,
                        searchTerm: this.searchTerm,
                        source: C_SEARCH.SOURCE.GRAPHICS_PANEL
                    };
                });
        }
    }
}