import { getWindow } from '_acaSrc/utility/DOM';

export default {
    mounted: el => {
        el.addEventListener('click', goBack);
    },
    beforeUnmount: el => {
        el.removeEventListener('click', goBack);
    }
};

function goBack() {
    getWindow().history.back();
}
