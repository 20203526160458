<template>
  <div class="wk-accordion-item"
       :class="{
         'wk-accordion-item-open': open,
         'empty-slot': isSlotEmpty($slots.default),
         [`automation_${computedItemId}Toggle`]: true
       }">
    <div ref="header"
         class="wk-accordion-item-header"
         role="presentation"
         :aria-expanded="open"
         tabindex="0"
         @click="$emit('headerClicked')"
         @keyup.left="$emit('left', $event)"
         @keyup.up="$emit('up', $event)"
         @keyup.right="$emit('right', $event)"
         @keyup.down="$emit('down', $event)"
         @keyup.enter="$emit('enter')"
         @keyup.space="$emit('space')"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div :id="itemId" class="wk-accordion-item-header-label" v-html="markupLabel || textLabel" />
      <span class="wk-accordion-icon" :class="accordionIconClass" />
    </div>
    <div ref="content"
         :class="{ 'wk-accordion-item-hidden': !open,
                   'wk-accordion-item-scrolled': accordionScrolled }"
         class="wk-accordion-item-element"
         :aria-hidden="!open"
         :aria-labelledby="itemId">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Listener } from '_acaSrc/utility/Events';

const stripNonWordsChars = s => s.replace(/\W/g, '');

export default {
    props: {
        textLabel: { type: String, default: '' },
        markupLabel: { type: String, default: '' },
        open: Boolean,
        itemId: String
    },
    data() {
        return {
            accordionScrolled: false
        };
    },
    computed: {
        accordionIconClass() {
            return `wkce-icon-chevron-${this.open ? 'up' : 'down'}`;
        },
        computedItemId() {
            return `wk-accordion-item-${
                stripNonWordsChars(!this.itemId ? this.textLabel : this.itemId)
            }`;
        }
    },
    mounted() {
        this.scrollListener = new Listener(this.$refs.content, 'scroll',
            this.setAccordionScrolled, { passive: true });
        this.setListener(this.scrollListener);
    },
    beforeUnmount() {
        this.clearListener(this.scrollListener);
    },
    methods: {
        ...mapActions('app', [ 'setListener', 'clearListener' ]),
        setAccordionScrolled(event) {
            this.accordionScrolled = event.target.scrollTop > 0;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@DS1-ACCORDION-BORDER-COLOR: @WKCE-GRAY-TINT5;
@DS1-ACCORDION-BG-CLR: @WKCE-GRAY-TINT6;
@DS1-ACCORDION-HEADER-TEXT-COLOR: @WKCE-BLUE-SHADE1;
@DS1-ACCORDION-ITEM-BG-CLR: @DS1-UTD-PRIMARY-BG-COLOR;

.wk-accordion-item-hidden {
  display: none;
}

.wk-accordion-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  position: relative;

  &.empty-slot {
    display: none;
  }
}

.wk-accordion-item-header {
  flex-shrink: 0;
  padding-right: 3rem;
}

.wk-accordion-item-element.wk-accordion-item-scrolled::before {
  /* Next line is to fix a Safari only bug re: box shadow rendering:
    https://stackoverflow.com/questions/18953282/css-box-shadow-property-is-not-working-in-safari */
  .ds1-accordion-box-shadow-with-webkit();
  display: block;
  position: absolute;
  width: 100%;
  height: 8px;
  top: 29px;
  content: '';
}

/** These rules are in (or missing in) the latest 1.20.0 version of the
    DXG Accordion, however upgrading all our components styles from 1.14.0
    would likely break a bunch of layouts and trigger a big regression, so
    placing overrides here for now.
 */
.wk-js2 .wk-accordion .wk-accordion-item-header,
.wk-js2 .wk-accordion .wk-accordion-item-header .wk-accordion-item-header-label {
  cursor: pointer;
}

/**
    A random Chromium bug related to processing of 'rem' units appears to be
    skewing the font-size for UtdAccordionItem components on some Chrome browsers.
    This effects both the accordion title/label and the content.
    This rule overrides the default DXG "font-size: .875rem" CSS to set a fixed "px"
    default that matches the design mockups. See CORE-12355.
 */
.wk-js2 .wk-accordion .wk-accordion-item-header .wk-accordion-item-header-label,
.wk-js2 .wk-accordion .wk-accordion-item-element {
  font-size: 14px;
}

.wk-js2 .wk-accordion .wk-accordion-item-header:focus {
  outline: 0;
}

  /* Style Variant */
  .wk-accordion-item.ds1-accordion {
    .ds1-mt-compact();
    border: 1px solid @DS1-ACCORDION-BORDER-COLOR;
    background-color: @DS1-ACCORDION-BG-CLR;

    .wk-accordion-item-header {
      .ds1-utd-weight-600();
      color: @DS1-ACCORDION-HEADER-TEXT-COLOR;
    }

    .wk-accordion-item-element {
      background-color: @DS1-ACCORDION-ITEM-BG-CLR;
    }
  }
</style>
