<template>
  <div v-if="isInstitutionalLoginVisible"
       id="alt-login"
       class="form-row form-links form-buttons institution-section">

    <div class="options-separator">or</div>
    <div id="siaw-convenience-buttons">
      <div v-if="ssoInstitutionSelected === null"
           id="default-convenience-buttons">
        <utd-button class="siaw-convenience-button"
                    button-style="ghost-link"
                    @click.prevent="redirectToSso('/login/microsoft')">
          <div class="ghost-link-container">
            <span class="ghost-link-text">Sign in with Microsoft</span>
          </div>
          <i class="wk-icon ghost-link-icon microsoft-logo" />
        </utd-button>
        <utd-button class="siaw-convenience-button"
                    button-style="ghost-link"
                    @click.prevent="redirectToSso('/login/open-athens')">
          <div class="ghost-link-container">
            <span class="ghost-link-text">Sign in with Open Athens</span>
          </div>
          <i class="wk-icon ghost-link-icon open-athens-logo" />
        </utd-button>
      </div>
      <div v-if="ssoInstitutionSelected !== null"
           id="recent-convenience-button">
        <utd-button class="siaw-convenience-button"
                    button-style="ghost-link"
                    @click.prevent="redirectToSso(ssoInstitutionSelected.dangerous_url)">
          <div class="ghost-link-container">
            <span class="ghost-link-text">
              Sign in with {{ ssoInstitutionSelected.dangerous_name }}
            </span>
          </div>
          <i class="wk-icon ghost-link-icon"
             :class="getIcon(ssoInstitutionSelected.dangerous_name)"
          />
        </utd-button>
      </div>
    </div>
    <div id="siaw-form"
         class="form-break form-row">
      <utd-button class="form-button siaw-button"
                  button-style="ghost"
                  @click.prevent="signInAnotherWay()">
        {{ $t('LOGIN.SIGN_IN_ANOTHER_WAY') }}
        <span class="wk-button-icon"
              :class="{ 'wkce-icon-chevron-down': !ssoExpanded,
                        'wkce-icon-chevron-up': ssoExpanded }" />
      </utd-button>
    </div>
    <div v-if="ssoExpanded" class="sso-search-input">
      <span class="sso-search-helper">
        {{ $t('LOGIN.SSO_SEARCH_HELPER') }}
      </span>
      <utd-search-input :options="searchOptions"
                        @keydown="onKeyDown($event)"
                        @utd-search-input="onSsoSearchInput" />
      <utd-autocomplete-dropdown v-show="showAutoComplete"
                                 :active-index="selectedAcIndex"
                                 :list="autoCompleteList"
                                 :options="autoCompleteOptions" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdSearchInput from '_acaSrc/components/shared/input/UtdSearchInput.vue';
import UtdAutocompleteDropdown from '_acaSrc/components/shared/stdlib/UtdAutocompleteDropdown.vue';
import { debounce } from '_acaSrc/utility/timers';
import {
    C_DROPDOWN_TYPES,
    C_KEYCODES,
    C_UI,
    maxSearchInputLength,
    SSO_INSTITUTION_SELECTION
} from '_acaSrc/utility/constants';
import { getDecodeCookie } from '_acaSrc/utility/Cookies';
import ACDropdownValue from
    '_acaSrc/components/contents/search/autocomplete/AutocompleteDropdownValue';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { incrementCarousel } from '_acaSrc/utility/math';
import Logger from '_acaSrc/utility/Logger';

const MIN_SEARCH_LENGTH = 1;
const { DOWN_ARROW, UP_ARROW, ESCAPE_KEYCODE, ENTER_KEY } = C_KEYCODES;

export default {
    components: {
        UtdButton,
        UtdSearchInput,
        UtdAutocompleteDropdown
    },
    emits: [ 'show-spinner' ],
    data() {
        return {
            availableInstitutions: [],
            autoCompleteList: [],
            isUnexpectedError: false,
            isAutoCompleteVisible: false,
            ssoExpanded: false,
            searchEntry: '',
            selectedAcIndex: -1,
            isLoading: false,
            debounceSearchFn: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'router'
        ]),
        ...mapGetters('auth', [
            'isInstitutionalLoginVisible'
        ]),
        ...mapGetters('login', [
            'institutions'
        ]),
        showAutoComplete() {
            return this.isAutoCompleteVisible && this.searchEntry.length > MIN_SEARCH_LENGTH;
        },
        searchOptions() {
            return {
                placeholder: 'Search for a way to sign in',
                showSpinner: this.isLoading,
                inputAttrs: {
                    maxlength: maxSearchInputLength,
                    autocomplete: 'off'
                }
            };
        },
        autoCompleteOptions() {
            return {
                autoCompleteTerm: this.searchEntry
            };
        },
        ssoInstitutionSelected() {
            const ssoInstitution = getDecodeCookie(SSO_INSTITUTION_SELECTION);
            try {
                return JSON.parse(ssoInstitution);
            }
            catch (e) {
                return null;
            }
        }
    },
    mounted() {
        this.debounceSearchFn = debounce(
            this.getAutocompleteList, C_UI.AUTOCOMPLETE_DROPDOWN_DELAY
        );
    },
    methods: {
        onKeyDown(e) {
            try {
                if (e.keyCode === DOWN_ARROW || e.keyCode === UP_ARROW) {
                    this.handleACKeyboardNav(e);
                }
                else if (e.keyCode === ESCAPE_KEYCODE) {
                    this.isAutoCompleteVisible = false;
                }
                else if (e.keyCode === ENTER_KEY) {
                    e.preventDefault();
                    this.navigateToSsoLogin();
                }
            }
            catch (error) {
                Logger.error(`Error processing key input with key=${e.keyCode} error:`, error);
            }
        },
        handleACKeyboardNav(e) {
            const prevIdx = this.selectedAcIndex;

            if (this.availableInstitutions.length === 0) {
                return;
            }

            if (prevIdx < 0) {
                this.selectedAcIndex = e.keyCode === UP_ARROW
                    ? this.availableInstitutions.length - 1
                    : 0;
            }
            else {
                const incr = e.keyCode === UP_ARROW ? -1 : 1;
                this.selectedAcIndex = incrementCarousel(
                    prevIdx, incr, this.availableInstitutions.length
                );
            }
            if (e.keyCode === UP_ARROW) {
                e.preventDefault();
            }
        },
        navigateToSsoLogin() {
            if (this.availableInstitutions.length === 0) {
                return;
            }
            if (this.selectedAcIndex < 0) {
                return;
            }
            const selectedSsoInst = this.availableInstitutions[this.selectedAcIndex];
            if (selectedSsoInst.url) {
                this.redirectToSso(selectedSsoInst.url);
            }
        },
        onSsoSearchInput(value) {
            this.searchEntry = value.toLowerCase();
            this.selectedAcIndex = -1;
            if (!value || !(value.length > MIN_SEARCH_LENGTH)) {
                this.isAutoCompleteVisible = false;
                return;
            }
            this.isAutoCompleteVisible = true;
            this.isLoading = true;
            this.debounceSearchFn(value);
        },
        makeSsoSearchHeader(label) {
            return (new ACDropdownValue())
                .setType(C_DROPDOWN_TYPES.HEADER)
                .setLabel(label)
                .setElId('ac-search-suggestions-header');
        },
        makeSsoSearchLink(r, i) {
            return (new ACDropdownValue())
                .setType(C_DROPDOWN_TYPES.LINK)
                .setElId(`search-suggestion-${i}`)
                .setUniqueId(`search-suggestion-${r.name}-${i}`)
                .setClasses('ac-search-suggestion')
                .setOnClick(() => this.redirectToSso(r.url))
                .setAnchorClasses('wkce-icon-search')
                .setLabel(r.name);
        },
        makeNoResultsFound(label) {
            return (new ACDropdownValue())
                .setType(C_DROPDOWN_TYPES.DEFAULT)
                .setClasses('ac-italic')
                .setLabel(label);
        },
        async fetchSsoInstitutions() {
            if (this.searchEntry <= MIN_SEARCH_LENGTH) {
                return;
            }
            try {
                const data = await utdRest('wayf/institutions/siaw', {
                    searchTerm: this.searchEntry
                });
                this.availableInstitutions = data.data || data;
            }
            catch {
                this.isUnexpectedError = true;
                this.availableInstitutions = [];
            }
        },
        async getAutocompleteList() {
            const returnList = [];
            await this.fetchSsoInstitutions();
            if (this.availableInstitutions.length > 0) {
                returnList.push(this.makeSsoSearchHeader(
                    this.$t('SEARCH.SEARCH_SUGGESTIONS').toUpperCase()
                ));
                this.availableInstitutions.forEach((r, i) => {
                    if (r.name && r.url) {
                        returnList.push(this.makeSsoSearchLink(r, i));
                    }
                });
            }
            else {
                returnList.push(this.makeNoResultsFound(
                    this.$t('SEARCH.NO_RESULTS_FOUND')
                ));
            }
            this.autoCompleteList = returnList;
            this.isLoading = false;
        },
        signInAnotherWay() {
            this.ssoExpanded = !this.ssoExpanded;
        },
        redirectToSso(ssoUrl) {
            this.$emit('show-spinner', ssoUrl);
        },
        getIcon(institutionName) {
            if (institutionName === 'Microsoft') {
                return 'microsoft-logo';
            }
            else if (institutionName === 'Open Athens') {
                return 'open-athens-logo';
            }
            return 'wkce-icon-link-external';
        }
    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/global/variables';
@import '~_acaAssets/wkce/colors/wkce-app-styles';
@SELECTBOX-FONT-COLOR: @WKCE-BLUE-SHADE1;
@OPTIONS-SEPARATOR-LINE-COLOR: @WKCE-GRAY-TINT4;
@OPTIONS-SEPARATOR-TEXT-COLOR: @WKCE-GRAY-TINT2;

.institution-section {
  .sso-search-input {
    .ds1-mt-4();
    .ds1-flex-column-wrap();
  }

  .sso-search-helper {
    .ds1-utd-weight-600();
    .ds1-mb-1();
  }

  .wk-button-icon {
    margin-left: 4px;
  }
}

.form-links.institution-section {
  .ds1-mt-3();
  display: flex;
  flex-direction: column;

  .form-row {
    text-align: center;
  }

  #siaw-form {
    padding-top: 20px;
  }

  .form-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;

    > a:nth-child(2) {
      .ds1-mh-3();
    }
  }

  .options-separator {
    overflow: hidden;
    text-align: center;
    font-weight: bold;
    color: @OPTIONS-SEPARATOR-TEXT-COLOR;

    &::before,
    &::after {
      background-color: @OPTIONS-SEPARATOR-LINE-COLOR;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &::before {
      right: 1.5em;
      margin-left: -50%;
    }

    &::after {
      left: 1.5em;
      margin-right: -50%;
    }
  }

  & > * {
    float: none;
  }

  > h3 {
    .ds1-utd-size-3();
  }

  .institution-selector {
    border: none;
    position: relative;
    width: 119px;
    margin-left: -2px;
    margin-top: 14px;
    background-color: transparent;
    color: @SELECTBOX-FONT-COLOR;
  }
}

#siaw-convenience-buttons {
  padding-bottom: 12px;
}

.siaw-convenience-button {
  .ds1-mt-4();
}

.microsoft-logo {
  background: url('~_acaAssets/login/microsoft-logo-blue.svg') no-repeat;
  height: 22.1px;
  width: 22px;
}

.open-athens-logo {
  background: url('~_acaAssets/login/open-athens-logo-purple.svg') no-repeat;
  height: 26px;
  width: 24.88px;
}

.wkce-icon-link-external::before {
  font-size: 22px;
}

@media only screen and (min-width: 768px) {
  .isDesktop .utd-form .form-links.institution-section {
    .ds1-pb-0();
    .ds1-pt-1();

    > h3 {
      .ds1-utd-h3();
    }

    .form-buttons-wrapper {
      > a:nth-child(2) {
        .ds1-mh-4();
      }
    }
  }
}

</style>

<style lang="less">
.sso-search-input .utd-autocomplete-ul {
  overflow-y: auto;
  max-height: 210px;
}
</style>