import { debounce, throttle } from '_acaSrc/utility/timers';
import { C_EVENTS, C_UI, C_KEYCODES, C_PATHS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import { getDocument, getWindow, parseDomForAttributeValue } from '_acaSrc/utility/DOM';
import { isInternetExplorer } from '_acaSrc/utility/Browsers';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';

const { SPACE_KEY, ENTER_KEY } = C_KEYCODES;

export function Listener(eventTarget, eventType, eventHandler, options) {
    return {
        eventTarget,
        eventType,
        eventHandler,
        options
    };
}

export function onScrollEndEvent(element, callback, options = {}) {
    // Method that fires callback after scrolling on passed element has ended.
    // Will only fire the callback once, as it removes the handler once fired.
    if (!element) {
        return;
    }

    const onScrollDebounce = debounce(() => {
        element.removeEventListener('scroll', onScrollDebounce, options);
        callback();
    }, options.checkDelayMs || C_UI.SCROLLING_CHECK_DELAY_LONG_MS);

    element.addEventListener('scroll', onScrollDebounce, options);
}

export function processEventOptions(options, supportsOptions) {
    if (!supportsOptions && options && typeof options === 'object') {
        options = options.capture;
    }
    return options;
}

export function isClickEvent(event) {
    return (event && event.type === 'click') || false;
}

export function setEventTargetHref(event, href) {
    href
        && event
        && event.target
        && event.target.setAttribute
        && event.target.setAttribute('href', href);
}

export function getEventTargetHref(event) {
    return (event
        && event.target
        && event.target.getAttribute
        && event.target.getAttribute('href')) || false;
}

export function eventHrefAttribute(event) {
    return (event
        && event.currentTarget
        && event.currentTarget.getAttribute
        && event.currentTarget.getAttribute('href')) || false;
}

export function usingModifierKeys(e) {
    return e && (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey);
}

export function dispatchEvent(eventName) {
    let event;
    if (isInternetExplorer()) {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }
    else {
        event = new Event(eventName);
    }
    getWindow().dispatchEvent(event);
}

export function triggerResizeEvent() {
    setTimeout(() => {
        dispatchEvent('resize');
    }, C_UI.WINDOW_RESIZE_DELAY_MS);
}

const isKeyboardNavClick = e => {
    return [ SPACE_KEY, ENTER_KEY ].includes(e.keyCode);
};

// Needed when adding click events to elements like divs, which are normally not
// interactable, so that users can use a keyboard instead of a mouse or finger.
export function addClickEventAccessible(el, fn) {
    el.addEventListener('click', e => fn(e));
    // Keydown was chosen because many browsers will scroll the page on space
    // keydown. The caller can preventDefault() and/or stopPropagation() to
    // avoid this sort of behavior if it is unwanted.
    el.addEventListener('keydown', e => {
        if (!isKeyboardNavClick(e)) {
            return;
        }
        fn(e);
    });
}

export function makeDoubleClick(doubleClickCallback, singleClickCallback) {
    return (function() {
        let clicks = 0,
            timeout;
        return function() {
            clicks++;
            if (clicks === 1) {
                // eslint-disable-next-line prefer-rest-params
                singleClickCallback && singleClickCallback.apply(this, arguments);
                timeout = setTimeout(function() {
                    clicks = 0;
                }, 400);
            }
            else {
                timeout && clearTimeout(timeout);
                // eslint-disable-next-line prefer-rest-params
                doubleClickCallback && doubleClickCallback.apply(this, arguments);
                clicks = 0;
            }
        };
    }());
}

export function getAttributeValueFromEvent(attrName, event) {
    let attrVal = 'Unknown';
    const elTarget = event.target || event.srcElement;

    if (elTarget) {
        attrVal = parseDomForAttributeValue(attrName, elTarget);
    }

    if (attrVal === 'Unknown' && event.currentTarget) {
        attrVal = event.currentTarget[attrName];
    }

    return attrVal;
}

export function logUiClickEventHelper(params = {}) {
    if (params.uiElementName || params.optData) {
        new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, params);
    }
}

export function throttleUiClickEvent() {
    return throttle((params = {}) => {
        if (params.uiElementName || params.optData) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, params);
        }
    }, C_UI.CLICK_EVENT_DELAY_MS);
}

export function registerExternalLinkConfirm(selectors, fnConfirm) {
    const fnExternalClickHandler = event => {
        if (event && event.preventDefault && event.srcElement
                && event.srcElement.getAttribute('target') !== '_blank') {
            event.preventDefault();
            fnConfirm(event.srcElement.getAttribute('href'));
        }
        return false;
    };
    const selectorsEl = getDocument().querySelectorAll(selectors);
    if (selectorsEl.length) {
        selectorsEl.forEach(selectorEl => {
            selectorEl.classList.add('confirmBeforeNavigate');
            selectorEl.addEventListener('click', fnExternalClickHandler);
        });
        return () => {
            const bindedEls = getDocument().querySelectorAll('.confirmBeforeNavigate');
            if (bindedEls.length) {
                bindedEls.forEach(bindedEl => {
                    bindedEl.removeEventListener('click', fnExternalClickHandler);
                });
            }
        };
    }
}

export function purgePersistentCache(options = {}) {
    new UtdCache().removeSession(C_PATHS.FOOTER_REST_EP);
    const utdQc = new UtdQueuedCache();
    utdQc.delete('utdSPAAutoComplete');
    utdQc.delete('utdSPAContentCache');

    utdQc.init('utdSPAAutoComplete', 10);
    utdQc.init('utdSPAContentCache', 15);

    if (options.isDesktopView) {
        utdQc.delete('utdSPAStoredSettings');
    }
}