<template>
  <header v-if="!isHeaderRedesign">
    <mobile-prospect-header />
    <desktop-prospect-header v-if="isProspectView" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import MobileProspectHeader from './MobileProspectHeader.vue';
import DesktopProspectHeader from './DesktopProspectHeader.vue';

export default {
    components: {
        MobileProspectHeader,
        DesktopProspectHeader
    },
    computed: {
        ...mapGetters('app', [ 'isProspectView' ]),
        ...mapGetters('feature', [ 'isHeaderRedesign' ])
    }
};
</script>
