import PendoGuide from './PendoGuide';

const PENDO_GUIDE_NAMES = [
    'Core-UsabilityTest-Test1',
    'Core-UsabilityTest-Test2',
    'Core-UsabilityTest-Test3' ];

export default class ResearchGuide extends PendoGuide {
    _getGuideName() {
        if (!this._isPendoAvailable()) {
            return '';
        }

        let foundGuideName = '';
        for (let i = 0; i < PENDO_GUIDE_NAMES.length; i++) {
            const guideName = PENDO_GUIDE_NAMES[i];
            if (this._pendo.findGuideByName(guideName)) {
                foundGuideName = guideName;
                break;
            }
        }

        return foundGuideName;
    }
}