<template>
  <utd-button class="translate-button"
              button-style="text"
              button-icon="repeat"
              @click="toggleTranslatedFeatureResult">
    <span>Translate</span>
  </utd-button>
</template>
<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';

export default {
    components: {
        UtdButton
    },
    emits: [ 'translate-button-click' ],
    methods: {
        toggleTranslatedFeatureResult() {
            new PubSub().publish(C_EVENTS.TRANSLATE_FEATURED_SEARCH_RESULT);
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                targetUrl: getWindow().location.href,
                uiElementName: 'panelTranslateButton'
            });
            this.$emit('translate-button-click');
        }
    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.translate-button {
  .ds1-pl-1();
  .ds1-utd-weight-normal();
  font-size: 14px;
  width: auto;
  display: none;
}

</style>