import { localizeAnchor, isMarketingLanguage } from '_acaSrc/utility/LocalizationHelper';

export default {
    install(Vue) {
        Vue.mixin({
            data: () => ({
                localizeAnchor,
                isMarketingLanguage
            })
        });
    }
};