<template>
  <div class="utd-toolbar">
    <toolbar-back-link v-if="isShowBackLink" />
    <toolbar-title v-if="toolbarTitleText !== ''" :title="toolbarTitleText" />
    <slot />
  </div>
</template>

<script>
import ToolbarBackLink from '../../shared/providers/UtdToolbarBackLinkProvider.vue';
import ToolbarTitle from './UtdToolbarTitle.vue';

export default {
    components: {
        ToolbarBackLink,
        ToolbarTitle
    },
    props: {
        isShowBackLink: {
            type: Boolean,
            default: false
        },
        toolbarTitleText: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD_TOOLBAR_BORDER_COLOR: #e2e2e2;

.utd-toolbar {
  .ds1-ph-2();
  box-sizing: border-box;
  background-color: @DS1-UTD-TOOLBAR-BG-COLOR;
  border-bottom: 1px solid @UTD_TOOLBAR_BORDER_COLOR;

  &.utd-toolbar-fixed {
    position: fixed;
    width: 100%;
    z-index: @ZINDEX-UTD-TOOLBAR;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .utd-toolbar {
      .ds1-pv-0();
      .ds1-ph-3();
      top: calc(@WK-HEADER-CONTAINER-HEIGHT + @WK-NAVBAR-CONTAINER-HEIGHT);
      border-bottom: none;

      .fixedToolbar& {
        top: @WK-HEADER-CONTAINER-HEIGHT;
        box-shadow: @DS1-BOX-SHADOW-RAISED;
      }

      .utdWkHomePage2024& {
        top: @WK-HEADER-CONTAINER-HEIGHT;
      }
    }
  }
}
</style>
