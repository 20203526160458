<!--ToDo: in current header we have unused elements, need to be reviewed in postmigration-->
<template>
  <nav v-if="isMenuVisible"
       v-show="hasHeader && isAppDataLoaded"
       id="utd-menu"
       :class="{'ui-searchbar-header': searchBarHeader,
                'hideNavBar': hideNavBar,
                'utd-abs-white-carbon': !isHomepageRedesign2024,
                isNavbarFixed
       }"
  >
    <div v-if="!isHomepageRedesign2024" id="utd-menu-content">
      <div class="utd-menu-tier1">
        <navigation-skip-links />
        <utd-logo />

        <ul class="utd-menu utd-menu-utd-menu-tier1">
          <li>
            <a href="#" @click="openChangeLanguageDialog($event)">
              Language
            </a>
          </li>
          <li>
            <a id="helpLink"
               v-utd-new-tab="true"
               href="/home/help">
              {{ $t('HEADER.HELP') }}
            </a>
          </li>
        </ul>
      </div>
      <div class="utd-menu-tier2">
        <div class="utd-user-box">
          <div v-if="welcomeName"
               class="utd-user-info"
               :class="{'utd-user-info-institution': !isLoggedIn}">
            <div>
              <span class="utd-user-name">
                {{ $t('HEADER.WELCOME', { name: welcomeName }) }}
              </span>
              <div v-if="showTicker" class="cmeticker small medium">
                <!-- eslint-disable vue/no-v-html -->
                <span class="utd-user-cme" v-html="cmeLabel" />
                <span class="utd-user-cme" v-html="tickerValue" />
              </div>
            </div>
          </div>
          <ul v-if="!isLoggedIn" class="utd-menu utd-menu-login">
            <li>
              <a name="login" href="/login">
                <span>{{ $t('MISC.LOG_IN') }}</span>
              </a>
              <span v-if="isRegister">
                /
                <a name="register" :href="`${loginHref}?source=header`">
                  <span class="large">{{ $t('LOGIN.REGISTER') }}</span>
                </a>
              </span>
            </li>
          </ul>
          <ul v-if="isLoggedIn" class="utd-menu utd-menu-account">
            <li v-if="permissions.myAccount">
              <a :id="myUpToDateHeaderId"
                 :href="myUpToDateHeaderHref"
                 @click="showPersonalizationModal($event)">
                <span v-if="!permissions.myUpToDate">{{ $t('HEADER.MY_ACCOUNT') }}</span>
                <span v-if="permissions.myUpToDate">{{ $t('MYUPTODATE.MY_UPTODATE') }}</span>
              </a>
            </li>
            <li v-if="showTicker" class="large xlarge">
              <a id="cmeTickerDesktopHeaderLegacy" :href="cmeUrls.dashboard">
                <span class="utd-user-cme" v-html="cmeLabel" />
                <span class="utd-user-cme" v-html="tickerValue" />
              </a>
            </li>
            <li>
              <a href="/logout" @click="resolveLogout('/logout')">
                {{ $t('LOGIN.SIGN_OUT') }}
              </a>
              <i />
            </li>
          </ul>
        </div>
      </div>
      <div class="utd-menu-tier3" :class="backToSearchClass">
        <searchbar-provider />
        <ul v-if="!isLoggedIn" id="prospectHeader" class="utd-menu utd-menu-signin">
          <li id="utdLogo">
            <utd-logo />
          </li>
          <li id="loginHeader" class="utd-login-header">
            <a v-if="!oidcPartnerDetails.partnerName" href="/login">
              <span>{{ $t('MISC.LOG_IN') }}</span>
            </a>
            <a v-if="isRegister"
               name="register"
               :href="`${loginHref}?source=header`">
              <span>{{ $t('LOGIN.REGISTER') }}</span>
            </a>
          </li>
          <utd-oidc-banner />
        </ul>
        <ul class="utd-menu utd-menu-content">
          <li v-if="showRenewMobileHeader"
              id="renewMobileHeader"
              class="renew-mobile">
            <div v-for="(expireMessage, idx) in myUpToDateMessage" :key="idx">
              <span class="access-validity" v-html="expireMessage.messageBundle.message" />
              <div v-if="showRenewButton(expireMessage)"
                   class="div-renew-button">
                <a id="renew_now_menu"
                   v-utd-new-tab="true"
                   class="renew-button"
                   :href="renewalSubscriptionUrl">
                  <strong>Renew</strong>
                </a>
              </div>
            </div>
          </li>

          <li v-if="showDrugInteractionsApp" id="drugIntHeader">
            <a href="/drug-interactions?source=responsive_home">
              {{ $t('HEADER.DRUG_INTERACTIONS') }}
            </a>
            <i />
          </li>
          <li v-if="isCustomer || isLoggedIn" id="calcHeader">
            <a :href="localizeAnchor('/contents/',
                                     'table-of-contents/calculators/alphabetized', language)">
              {{ $t('HEADER.CALCULATORS') }}
            </a>
            <i />
          </li>
          <li v-show="permissions.myUpToDate"
              v-if="isLoggedIn"
              id="myUpToDateHeaderMobile">
            <a id="myUpToDateHeaderMob"
               href="/myuptodatemobile">
              <span>{{ $t('MYUPTODATE.MY_UPTODATE') }}</span>
              <span class="bookmarkHistory">{{ $t('MYUPTODATE.BOOKMARK_HISTORY') }}</span>
            </a>
            <i />
          </li>
          <li id="pcuHeader">
            <a href="/contents/practice-changing-updates">
              {{ $t('HEADER.PRACTICE_CHANGING_UPDATES') }}
            </a>
            <i />
          </li>
          <li id="whatsNewHeader">
            <a :href="localizeAnchor('/contents/','table-of-contents/whats-new', language)">
              {{ $t('HEADER.WHATS_NEW') }}
            </a>
            <i />
          </li>
          <li v-if="isCustomer || isLoggedIn" id="patientEdHeader">
            <a :href="localizeAnchor('/contents/','table-of-contents/patient-education', language)">
              {{ $t('HEADER.PATIENT_EDUCATION') }}
            </a>
            <i />
          </li>
          <li v-if="isCustomer || isLoggedIn" id="tocHeader">
            <a :href="localizeAnchor('/contents/','table-of-contents', language)">
              {{ $t('HEADER.CONTENTS') }}
            </a>
            <i />
          </li>
          <li v-show="hasPathwaysAccess"
              id="pathwaysHeader">
            <a :href="localizeAnchor('/contents/', PATHWAYS.toc, language)">
              {{ $t('SEARCH.UPTODATE_PATHWAYS') }}
            </a>
            <i />
          </li>
          <li v-show="isShowRxTransitions"
              id="rxTransitionsHeader">
            <a href="/rxtransitions?source=responsive_home">
              {{ $t('HEADER.RX_TRANSITIONS') }}
            </a>
            <i />
          </li>
        </ul>
        <ul class="utd-menu utd-menu-account">
          <li>
            <a href="/settings">
              {{ $t('MYACCOUNT.SETTINGS') }}
            </a>
            <i />
          </li>
          <li v-if="isLoggedIn">
            <a href="/logout" @click="resolveLogout()">
              {{ $t('LOGIN.SIGN_OUT') }}
            </a>
            <i />
          </li>
        </ul>
      </div>
    </div>
    <mobile-prospect-footer v-if="!searchBarHeader && !isHomepageRedesign2024" />
    <home-prospect-header v-if="isHomepageRedesign2024" @navbarIsFixed="handleNavbarIsFixed"/>

  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';
import NavigationSkipLinks from '_acaSrc/components/header/NavigationSkipLinks.vue';
import SearchbarProvider from '_acaSrc/components/contents/search/SearchBarProvider.vue';
import PubSub from '_acaSrc/utility/PubSub';
import utdOidcBanner from '_acaSrc/components/shared/utd/UtdOidcBanner.vue';
import { C_EVENTS, C_EXPIRE_MESSAGE, C_CME_UI_LINKS, C_PATHWAYS } from '_acaSrc/utility/constants';
import MobileProspectFooter from '_acaSrc/components/footer/MobileProspectFooter.vue';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import HomeProspectHeader from '_acaSrc/components/homePageRedesign/HomeProspectHeader.vue';
export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdLogo,
        NavigationSkipLinks,
        SearchbarProvider,
        utdOidcBanner,
        MobileProspectFooter,
        HomeProspectHeader
    },
    data() {
        return {
            PATHWAYS: C_PATHWAYS,
            cmeUrls: C_CME_UI_LINKS,
            isNavbarFixed: false
        };
    },
    computed: {
        ...mapGetters('feature', [ 'isHomepageRedesign2024' ]),
        ...mapGetters('app', [
            'isAppDataLoaded',
            'isOidcSearchLanding',
            'isProspectView',
            'isAlwaysShowBackButton',
            'hasHeader'
        ]),
        ...mapGetters('profile', [
            'welcomeName',
            'permissions',
            'myUpToDateMessage'
        ]),
        ...mapGetters('cme', [
            'cmeLabel',
            'showTicker',
            'tickerValue'
        ]),
        ...mapGetters('auth', [ 'oidcPartnerDetails' ]),
        ...mapGetters('user', [ 'language', 'userLoggedIn', 'isCustomer' ]),
        ...mapGetters('topic', [ 'isTopicView' ]),
        ...mapGetters('search', [ 'searchBarBody', 'searchBarHeader' ]),
        ...mapGetters('feature', [
            'hideNavBar',
            'showBackToSearch',
            'showDrugInteractionsApp',
            'hasPathwaysAccess',
            'isShowRxTransitions'
        ]),
        ...mapGetters('login', [
            'loginHref',
            'isRegister',
            'renewalSubscriptionUrl'
        ]),
        isMenuVisible() {
            return !this.isProspectView && !this.isOidcSearchLanding;
        },
        isLoggedIn() {
            return this.userLoggedIn;
        },
        myUpToDateHeaderId() {
            return this.permissions.myUpToDate ? 'myUpToDateHeader' : 'myAccountHeader';
        },
        myUpToDateHeaderHref() {
            return this.permissions.myUpToDate
                ? '/contents/search?myUpToDate=true' : '/account/index';
        },
        backToSearchClass() {
            return { backtosearch:
                    (this.isAlwaysShowBackButton && !this.searchBarBody)
                    || (this.showBackToSearch && this.isTopicView) };
        },
        showRenewMobileHeader() {
            return !this.permissions.recurringBilling
                && this.isLoggedIn
                && this.permissions.myAccount
                && this.permissions.myUpToDate
                && this.myUpToDateMessage.length;
        }
    },
    methods: {
        ...mapActions('app', [
            'openChangeLanguageDialog'
        ]),
        ...mapActions('login', [ 'resolveLogout' ]),
        showPersonalizationModal(event) {
            new PubSub().publish(C_EVENTS.OPEN_MODAL, { event });
        },
        showRenewButton(expireMessage) {
            return expireMessage.type === C_EXPIRE_MESSAGE.EXPIRED
                || expireMessage.type === C_EXPIRE_MESSAGE.EXPIRING
                || expireMessage.type === C_EXPIRE_MESSAGE.EXPIRES_TODAY;
        },
        handleNavbarIsFixed(isFixed){
            this.isNavbarFixed = isFixed;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/utd-menu/utd-menu.less';

:deep(.utd-menu-signin .wk-logo) {
  .ds1-mt-0();
  .ds1-pa-0();
  border-bottom: none;
}

#utd-menu.isNavbarFixed {
  box-shadow: @DS1-BOX-SHADOW-RAISED;

  .isHomeSearchEmpty & {
    background-color: @WKCE-BLUE-SHADE2;
  }
}
</style>
