<!-- eslint-disable vue/no-v-html -->
<template>
  <div ref="calcContent" class="calculator-tooltip">
    <a :href="calculator.url" v-html="calculator.title" />
  </div>
</template>

<script>
import { GraphicHoverTooltip } from '_acaSrc/utility/tooltip';
import { elAddClass, getDocument } from '_acaSrc/utility/DOM';
import Logger from '_acaSrc/utility/Logger';
import { mapGetters, mapActions } from 'vuex';
import { safeDecodeUriComponent } from '_acaSrc/utility/http';

const UNIQUE_HOVER_CLASS = 'calc-hover-tooltip-unique';
const RENDERING_TIMEOUT_MS = 100;

export default {
    data() {
        return {
            hoverSelector: '#topicArticle a.calc',
            tooltip: null,
            calculator: {},
            topicCalculators: []
        };
    },
    computed: {
        ...mapGetters('app', [
            'tooltipConfig'
        ])
    },
    mounted() {
        this.activate();
    },
    methods: {
        ...mapActions('app', [ 'confirmConfigLoaded' ]),
        async activate() {
            this.getCalculators();
            await this.confirmConfigLoaded();
            this.tooltip = new GraphicHoverTooltip(
                `.${UNIQUE_HOVER_CLASS}`,
                this.tooltipConfig,
                this.onHoverTrigger);
            this.addHoverUniqueClass();
        },
        onHoverTrigger(tooltip, hoverEvent) {
            const href = safeDecodeUriComponent(hoverEvent.target.pathname);
            this.getCalculatorByHref(href);
            setTimeout(() => {
                tooltip.setContent(this.$refs.calcContent);
            }, RENDERING_TIMEOUT_MS);
        },
        addHoverUniqueClass() {
            Array.from(getDocument().querySelectorAll(this.hoverSelector))
                .forEach(el => elAddClass(el, UNIQUE_HOVER_CLASS));
            this.tooltip.initialize()
                .catch(e => {
                    if (e.indexOf('IGNORE_ERROR') === -1) {
                        Logger.warn(`Error initializing calculator hover tooltip: ${e}`);
                    }
                });
        },
        getCalculatorByHref(href) {
            this.topicCalculators.forEach(calculator => {
                if (calculator.url === href) {
                    this.calculator = calculator;
                }
            });
        },
        getCalculators() {
            const calculatorsElems = getDocument().querySelectorAll('#outlineContent .calc');
            Array.from(calculatorsElems).forEach(elem => {
                const omittedText = 'Calculator: ';
                const title = elem.innerHTML.indexOf(omittedText) === 0
                    && elem.innerHTML.replace(omittedText, '');
                const calculator = {
                    title,
                    url: safeDecodeUriComponent(elem.pathname)
                };
                this.topicCalculators.push(calculator);
            });
        }
    }
};
</script>

<style lang="less" scoped>
.calculator-tooltip {
  max-width: 200px;
}
</style>
