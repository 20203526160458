<template>
  <div>
    <div v-if="showConfirmDeactivate" class="device__deactivate-notification">
      <div class="device__deactivate-container">
        <div class="device__notification-header">
          <span class="device__notification-icon
                       device__notification-icon--warn"
                :class="deactivateIconClass" />
          <h5 class="device-bolded">Confirm Deactivation</h5>
        </div>
        <p class="device__confirm-deactivate-message">
          Are you sure want to deactivate {{ device.deviceTypeLabel }}
          (last used {{ lastUsedDate(device.lastAuthorizationDateMs) }})?
        </p>
        <div class="device__deactivate-action">
          <utd-button class="device__deactivate-cancel"
                      button-color="light"
                      @click="dismissDeactivate()">Cancel</utd-button>
          <utd-button class="device__deactivate-confirm"
                      @click="confirmDeactivate()">Yes, deactivate</utd-button>
        </div>
      </div>
    </div>
    <div v-if="showDeactivateSuccess" class="device__deactivate-notification--success">
      <div class="device__deactivate-container">
        <div class="device__notification-header">
          <span class="device__notification-icon
                       device__notification-icon--success"
                :class="deactivateIconClass" />
          <h5 class="device__deactivate-header
                     device__deactivate-header--success
                     device-bolded">
            Deactivation Successful
          </h5>
          <button id="successDismissDeactivate"
                  class="device__notification-close-icon wkce-icon-filled-close"
                  @click="dismissDeactivate()" />
        </div>
        <p class="device__confirm-deactivate-message">
          Your device has been de-activated and you are now logged out.
        </p>
      </div>
    </div>
    <div v-if="showDeactivateError" class="device__deactivate-notification--error">
      <div class="device__deactivate-container">
        <div class="device__notification-header">
          <span class="device__notification-icon
                       device__notification-icon--error"
                :class="deactivateIconClass" />
          <h5 class="device__deactivate-header
                     device__deactivate-header--error
                     device-bolded">
            Deactivation Failed
          </h5>
          <button id="errorDismissDeactivate"
                  class="device__notification-close-icon wkce-icon-filled-close"
                  @click="dismissDeactivate()" />
        </div>
        <p class="device__confirm-deactivate-message">
          There was an error with deactivating your device. Please try again later.
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from 'vuex';
import WkceDates from '_acaSrc/utility/Dates';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

const STATE_NOTIFICATION_BASE = 'DEVICE';
const STATE_NOTIFICATION_WARN = 'WARN';
const STATE_NOTIFICATION_SUCCESS = 'SUCCESS';
const STATE_NOTIFICATION_ERROR = 'ERROR';

export default {
    components: {
        UtdButton
    },
    props: {
        device: {
            type: Object,
            default() {
                return {};
            }
        },
        notificationState: {
            type: String,
            default: STATE_NOTIFICATION_BASE
        }
    },
    emits: [ 'set-notification-state' ],
    data() {
        return {
            showFullDeviceInfo: false
        };
    },
    computed: {
        showDeactivateSuccess() {
            return this.notificationState === STATE_NOTIFICATION_SUCCESS;
        },
        showConfirmDeactivate() {
            return this.notificationState === STATE_NOTIFICATION_WARN;
        },
        showDeactivateError() {
            return this.notificationState === STATE_NOTIFICATION_ERROR;
        },
        deactivateIconClass() {
            let icon = '';
            if (this.showDeactivateSuccess) {
                icon = 'filled-check-circle';
            }
            else if (this.showConfirmDeactivate
                  || this.showDeactivateError) {
                icon = 'filled-caution';
            }
            return `wkce-icon-${icon}`;
        }
    },
    methods: {
        ...mapActions('profile', [ 'addDeactivatedDevice' ]),
        lastUsedDate(lastAuthorizationDateMs) {
            return WkceDates.dateToMDYUSLocale(lastAuthorizationDateMs);
        },
        dismissDeactivate() {
            this.$emit('set-notification-state', STATE_NOTIFICATION_BASE);
        },
        confirmDeactivate() {
            logUiClickEventHelper({ uiElementName: 'deviceNotification_deactiveConfirmButton' });
            try {
                utdRest('myAccount/deactivateDevice', {
                    deviceId: this.device.deviceId
                }).catch(() => {
                    this.$emit('set-notification-state', STATE_NOTIFICATION_ERROR);
                });
                this.$emit('set-notification-state', STATE_NOTIFICATION_SUCCESS);
                this.addDeactivatedDevice({
                    deviceId: this.device.deviceId,
                    platform: this.device.deviceImageType
                });
            }
            catch {
                this.$emit('set-notification-state', STATE_NOTIFICATION_ERROR);
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-PROFILE-ICON-COLOR: @WKCE-BLUE;
@DS1-BUTTON-BORDER-COLOR: @WKCE-BLUE-SHADE1;

.device__deactivate-notification {
  .ds1-mb-1();
  background-color: @DS1-UTD-GRAY-BG-COLOR;

  &--success {
    .ds1-mb-2();
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border: 1px solid @DS1-UTD-SUCCESS;
    border-left: 3px solid @DS1-UTD-SUCCESS;

    .device__deactivate-container {
      .ds1-pt-1();
      .ds1-pb-0();
    }
  }

  &--error {
    .ds1-mb-2();
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border: 1px solid @DS1-UTD-ERROR;
    border-left: 3px solid @DS1-UTD-ERROR;

    .device__deactivate-container {
      .ds1-pt-1();
      .ds1-pb-0();
    }
  }
}

.device__deactivate-container {
  .ds1-ph-5();
  .ds1-pv-2();
  .ds1-ma-0();
}

.device__notification-header {
  display: flex;
  position: relative;
  right: 22px;
}

.device__notification-icon {
  position: relative;
  line-height: 1.2em;
  right: 5px;
  font-size: 16px;

  &--warn {
    color: @DS1-UTD-FILLED-CAUTION-ICON;
  }

  &--error {
    color: @DS1-UTD-ERROR;
  }

  &--success {
    color: @DS1-UTD-SUCCESS;
    line-height: 1.4em;
  }
}

.device__confirm-deactivate-message {
  .ds1-mt-0();
  .ds1-mb-1();
}

.device__deactivate-cancel {
  .ds1-mr-1();
  .ds1-mb-1();
  border-color: @DS1-BUTTON-BORDER-COLOR;
}

.device__deactivate-header {
  flex-basis: 100%;
}

.device__notification-close-icon {
  background: transparent;
  border: none;
  left: 55px;
  position: relative;
  font-size: 16px;
}

.expanded::after {
  position: relative;
  font-family: "wk-icons-open";
  font-size: 12px;
  content: @DS1-UTD-CHEVRON-UP;
  padding-left: 8px;
}

.collapsed::after {
  position: relative;
  font-family: "wk-icons-open";
  font-size: 12px;
  content: @DS1-UTD-CHEVRON-DOWN;
  padding-left: 8px;
}

.device-bolded {
  .ds1-utd-weight-600();
}
</style>