<template>
  <div v-show="showSearchResultComponent"
       id="search-showmore"
       role="button"
       :aria-pressed="isLoadingResults"
       :aria-hidden="isAriaHidden"
       @keypress="$event.which === ENTER_KEY && getMoreResults()">
    <utd-button v-if="!isLoadingResults"
                id="showmore-button"
                button-style="ghost"
                button-size="large"
                tabindex="0"
                @click="getMoreResults()"
    >{{ $t('SEARCH.SHOW_MORE_RESULTS') }}</utd-button>
    <utd-button v-if="isLoadingResults"
                button-style="ghost"
                button-size="large"
    >{{ $t('SEARCH.SHOW_MORE_RESULTS') }}</utd-button>
  </div>
</template>

<script>
import { C_KEYCODES, C_SEARCH } from '_acaSrc/utility/constants';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

import {
    SET_IS_NEW_RESULTS,
    SET_SEARCH_PARAMS_SOURCE,
    SET_SEARCH_PARAMS_TEXT,
    SET_IS_LOADING_RESULTS
} from '_acaSrc/store/search.store';
import { cleanResetUriComponent } from '_acaSrc/utility/http';
import UtdCache from '_acaSrc/utility/UtdCache';

export default {
    components: {
        UtdButton
    },
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        ...mapGetters('search', [
            'originalSearchText',
            'searchHasMoreResults',
            'searchParamsSearchText',
            'searchParamsOffset',
            'searchStateState',
            'isLoadingResults',
            'searchResultsResults'
        ]),
        showSearchResultComponent() {
            return this.searchHasMoreResults && this.searchResultsResults.length;
        },
        isAriaHidden() {
            return !this.searchHasMoreResults || !this.searchResultsResults.length;
        }
    },
    methods: {
        ...mapActions('app', [ 'logEvent', 'publish' ]),
        ...mapMutations('search', [
            SET_IS_NEW_RESULTS,
            SET_SEARCH_PARAMS_TEXT,
            SET_SEARCH_PARAMS_SOURCE,
            SET_IS_LOADING_RESULTS
        ]),
        ...mapActions('search', [ 'getSearchResults' ]),
        getMoreResults() {
            this[SET_IS_NEW_RESULTS](false);
            this.logEvent({
                eventUrl: 'event/showMoreResults/json',
                eventParams: {
                    searchTerm: this.searchParamsSearchText,
                    rank: this.searchParamsOffset - 1,
                    state: this.searchStateState
                }
            });

            // Note: As this method will never get triggered from a 'back' or 'forward'
            // browser operation, enabling doCacheHitEvent to track cache hits
            new UtdCache().doCacheHitEvent = true;

            // Logic to show the loading widget in the "Show More Results" button,
            // but not in the main display
            this[SET_IS_LOADING_RESULTS](true);

            // Use original search term to get more results,
            // ignore any new test in the search text box
            this[SET_SEARCH_PARAMS_TEXT](cleanResetUriComponent(this.originalSearchText));
            this[SET_SEARCH_PARAMS_SOURCE](C_SEARCH.SOURCE.PAGING);

            this.getSearchResults()
                .finally(() => {
                    this[SET_IS_LOADING_RESULTS](false);

                    this.publish({
                        eventName: 'SEARCH_RESULT_SHOW_MORE'
                    });
                });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles.less";
@import (reference) "~_acaAssets/global/variables";

#searchresults {
  #search-showmore {
    text-align: center;
    margin: 40px 0 0;

    a {
      display: inline-block;
      border: 1px solid @DS1-UTD-JS2-LINK-BASE;
      .border-radius(6px);
      padding: 8px 24px;
      outline: 0;
      font-size: 0.85em;
      cursor: pointer;

      .zh-Hans & {
        font-size: 1.1em;
      }
    }

    .search-showMoreResults-loading {
      color: transparent;
      border-color: @DS1-UTD-JS2-LINK-HOVER;
      background: url("~_acaAssets/global/loader.gif") 50% 50% no-repeat;
      background-size: 28px 28px !important;
      -webkit-background-size: 28px 28px;
    }
  }

  ul.graphicsResults + #search-showmore {
    clear: both;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    #searchresults {
      #search-showmore {
        width: 200px;
        margin: 25px auto;
        text-align: center;

        a {
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
