<template>
  <div v-if="fullAccessToMyAccount">
    <div class="my-account-sidebar">
      <div class="sidebar__container">
        <div class="sidebar__header">{{ $t('MYACCOUNT.MANAGE_DEVICES') }}</div>
        <div class="sidebar__content">
          <ul v-if="hasActiveDevices" class="sidebar__devices-list">
            <li v-for="device in visibleDevicesFlyout"
                :key="device.deviceId"
                class="sidebar__device">
              <div v-if="isDeviceActive(device.deviceId)"
                   class="sidebar__device-container">
                <h4>{{ device.deviceTypeLabel }}</h4>
                <p class="sidebar__device-action">
                  <span class="sidebar__last-used"> Last used {{
                    lastUsedDate(device.lastAuthorizationDateMs)
                  }}
                  </span>
                  <a class="sidebar__deactivate sidebar-link"
                     href="#"
                     @click="openManageDevices({
                       deviceId: device.deviceId,
                       elem: 'deactivateLink'})">
                    Deactivate</a>
                </p>
              </div>
            </li>
          </ul>
          <div v-else class="sidebar-italic">{{ $t('MYACCOUNT.NO_ACTIVE_DEVICES') }}</div>
          <div v-if="hasActiveDevices">
            <span class="sidebar__link-container">
              <span class="wkce-icon-smartphone" />
              <a class="sidebar__view-devices sidebar-link"
                 href="#"
                 @click="openManageDevices({elem: 'viewLink'})">View Devices ({{ totalDevices }})
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <manage-devices-dialog ref="manageDevicesDialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import WkceDates from '_acaSrc/utility/Dates';
import ManageDevicesDialog from './ManageDevicesDialog.vue';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

export default {
    components: { ManageDevicesDialog },
    emits: [ 'open-my-account-dialog' ],
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('account', [
            'isDeviceActive',
            'numActiveDevices',
            'maxActivationsAllowed',
            'myAccountAccessLevel',
            'devices'
        ]),
        totalDevices() {
            return this.numActiveDevices(C_MY_ACCOUNT.DESKTOP_DEVICE)
                  + this.numActiveDevices(C_MY_ACCOUNT.MOBILE_DEVICE);
        },
        visibleDevicesFlyout() {
            return this.devices.listing
                .slice(0, this.maxActivationsAllowed(C_MY_ACCOUNT.MOBILE_DEVICE));
        },
        hasActiveDevices() {
            return this.totalDevices > 0;
        },
        fullAccessToMyAccount() {
            return this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.FULL_ACCESS;
        }
    },
    methods: {
        lastUsedDate(lastAuthorizationDateMs) {
            return WkceDates.dateToMDYUSLocale(lastAuthorizationDateMs);
        },
        openManageDevices({ deviceId, elem }) {
            this.logUiClickEvent(elem);
            this.$refs.manageDevicesDialog.open(deviceId);
            this.$emit('open-my-account-dialog');
        },
        logUiClickEvent(elem) {
            logUiClickEventHelper({
                uiElementName: `myAccountDevices_${elem}`
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.sidebar__devices-list {
  .ds1-reset-ul-li();
  .ds1-mr-1();
}

.sidebar__device {
  .ds1-reset-ul-li();
  display: flex;
  flex-direction: column;
}

.sidebar__device-container {
  .ds1-pb-2();
}

.sidebar__device-action {
  .ds1-ma-0();
  display: flex;
  justify-content: space-between;
}

.sidebar__deactivate {
  overflow-wrap: break-word;
}
</style>