<template>
  <qap-desktop />
</template>

<script>
import QapDesktop from '_acaQap/QapDesktop.vue';

export default {
    components: {
        QapDesktop
    }
};
</script>