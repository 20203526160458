import { getWindow, getDocument, getHostname } from '_acaSrc/utility/DOM';
import Logger from './Logger';

const ONETRUST_BIND_DELAY_MS = 1000;

export function rebindCookieManagerLink() {

    setTimeout(() => {
        if (getWindow().OneTrust !== null) {
            const toggleDisplay = getDocument().querySelectorAll('.ot-sdk-show-settings');
            toggleDisplay.forEach(addOneTrustListener);
        }
    }, ONETRUST_BIND_DELAY_MS);

    function addOneTrustListener(item) {
        item.onclick = function(event) {
            event.stopImmediatePropagation();
            getWindow().OneTrust.ToggleInfoDisplay();
        };
    }
}

export function generateCookieScripts() {
    try {
        if (getDocument().getElementById('oneTrustCookieNotice')) {
            return;
        }

        // CORE-11340: Do not activate cookie tool in embedded browsers
        if (getWindow().self !== getWindow().top) {
            return;
        }

        const cookieGuid = getCookieGuidForDomain(getHostname());
        if (!cookieGuid) {
            return;
        }

        const noticeEl = getDocument().createElement('script');
        noticeEl.id = 'oneTrustCookieNotice';

        getDocument().head.appendChild(noticeEl);

        const consentCookieScript = getDocument().createElement('script');
        consentCookieScript.type = 'text/javascript';
        consentCookieScript.src = `https://cdn.cookielaw.org/consent/${cookieGuid}/OtAutoBlock.js`;
        noticeEl.appendChild(consentCookieScript);

        const cookieSelectionScript = getDocument().createElement('script');
        cookieSelectionScript.type = 'text/javascript';
        cookieSelectionScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        cookieSelectionScript.charset = 'UTF-8';
        cookieSelectionScript.setAttribute('data-domain-script', cookieGuid);
        consentCookieScript.id = 'oneTrustSelectionScript';
        noticeEl.appendChild(cookieSelectionScript);

        return noticeEl;
    }
    catch (e) {
        Logger.error(e);
    }
}

export function oneTrustAvailable() {
    return getDocument().getElementById('oneTrustSelectionScript');
}

export function getCookieGuidForDomain(hostname) {
    let cookieGuid = '';
    if (hostname === 'www.uptodate.cn') {
        cookieGuid = 'a65b0b99-a133-4f3e-84a4-c8f854e9480e';
    }
    else if (hostname === 's20www.utdlab.com') {
        cookieGuid = 'a65b0b99-a133-4f3e-84a4-c8f854e9480e-test';
    }
    else if (isDevOrTestRegion(hostname)) {
        cookieGuid = '560a8aa4-c54c-486f-9bc4-35a3f22bacd9-test';
    }
    else if (hostname.match(/\w+\.uptodate\.com/) || hostname.match(/^ecapp\w+p\.utd\.com/)) {
        cookieGuid = '560a8aa4-c54c-486f-9bc4-35a3f22bacd9';
    }
    return cookieGuid;
}

function isDevOrTestRegion(hostname) {
    return (hostname.indexOf('utdlab.com') > -1
        || hostname.match(/\w+www\.uptodate\.com/)
        || hostname.match(/^app\w+\.utd\.com/)
        || hostname.indexOf('wkglobal.com') > -1
        || hostname === 'localhost');
}