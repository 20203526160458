import { decodeHtml, getWindow } from '_acaSrc/utility/DOM';
import {
    C_HTTP,
    C_HTTP_STATUS,
    pathwaysUrlRegex,
    rxTransitionsUrlRegex,
    drugInteractionsUrlRegex
} from '_acaSrc/utility/constants';
import { sterilizeString } from '_acaSrc/utility/String';

export function safeDecodeUriCollection(obj) {
    if (Array.isArray(obj) || isDefinedObject(obj)) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                obj[prop] = safeDecodeHtmlUriComponent(obj[prop]);
            }
        }
    }
    return obj;
}

// Based off of jQuery "params()"
export function collectionToQueryParams(obj) {
    const s = [];

    if (Array.isArray(obj) || isDefinedObject(obj)) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                appendParam(s, prop, obj[prop]);
            }
        }
    }
    return s.join('&').replace(/%20/g, '+');
}

export function queryParamsToCollection(params) {
    const collection = {};
    let queryParams = params || getWindow().location.search;
    if (queryParams.indexOf('?') === -1) {
        return collection;
    }
    queryParams = queryParams.substr(1);

    queryParams.split('&')
        .forEach(p => {
            const kvp = p.split('=');
            collection[kvp[0]] = `${
                collection[kvp[0]]
                    ? `${collection[kvp[0]]},`
                    : ''}${
                kvp.length > 1
                    ? safeDecodeHtmlUriComponent(kvp[1])
                    : ''}`;
        });

    return collection;
}

export function urlContains(match, url = getWindow().location.href) {
    return match && url && url.indexOf(match) > -1;
}

export function createRestPath(uri) {
    if (!uri) {
        return '';
    }

    if (isAbsoluteUrl(uri)) {
        return uri;
    }

    if (uri.indexOf('/') === 0) {
        uri = uri.substring(1);
    }
    return `${C_HTTP.REST_BASE_PATH}${uri}`;
}

function appendParam(s, key, value) {
    return s[s.length] = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

function isDefinedObject(obj) {
    return ((typeof obj === 'object') && (obj !== null));
}

export function getUrlHash(input) {
    // Pure JS version of AngularJS's $location.hash()
    // Can extract URL hash from a passed string, or the window.location.hash object
    if (!input) {
        input = (getWindow().location
            && getWindow().location.hash
            && safeDecodeUriComponent(getWindow().location.hash)) || '';
    }
    let hashVal = '';
    if (input && input.indexOf('#') > -1) {
        hashVal = input.split('#').pop();
    }
    return hashVal;
}

export function getUrlWithoutHash() {
    return getWindow().location.href && getWindow().location.href.split('#')[0];
}

export function getRelativeUrl(useHash) {
    // Method to return string suitable for using as an index from active URL.
    // Optional useHash argument to replace any current hash on active URL.
    const hashVal = useHash || getUrlHash();
    // eslint-disable-next-line max-len
    return `${getWindow().location.pathname}${getWindow().location.search}${hashVal ? '#' : ''}${hashVal}`;
}

// takes in a url and ensures that the url is a relative url
export function makeRelativeUrl(url) {
    if (!url) {
        return;
    }
    // removes anything before and including first / (skipping //)
    // then adds a / to the start of the resulting string
    return `/${url.replace(/^(?:\/\/|[^/]+)*\//, '')}`;
}

// eslint-disable-next-line complexity
export function setQueryParamValue(url, param, value) {
    // Will either add, update or remove a Url query parameter
    // If 'value' is not passed, will attempt to remove 'param' if present on 'url'
    // If 'param' and 'value' passed, will add to 'url' if not present, will update if present.
    if (!url) {
        return url;
    }
    let hash = '',
        // eslint-disable-next-line prefer-const
        re = new RegExp(`([?&])(${param})((=)([^&;#$]*)([&;#]|$))`),
        // eslint-disable-next-line prefer-const
        m = url.match(re);
    if (url.indexOf('?') < 0) {
        if (url.indexOf('#') > -1) {
            hash = url.replace(/.*#(?!!)/, '#');
            url = url.replace(/#(?!!).*/, '');
        }
        url += (typeof value !== 'undefined'
            ? `?${param}=${value}` : '') + hash;
    }
    else if (m) {
        url = url.replace(re, (typeof value === 'undefined'
            ? (m[6] === '#' ? '$6'
                : m[6] !== ''
                    ? '$1' : '')
            : `$1${param}=${value}$6`));
    }
    else if (typeof value !== 'undefined') {
        if (url.indexOf('#') > -1) {
            hash = url.replace(/.*#(?!!)/, '#');
            url = url.replace(/#(?!!).*/, '');
        }
        url += `${(url.indexOf('?') > -1 ? '&' : '?') + param}=${value}${hash}`;
    }
    return url;
}

export function getQueryParamValues(url, param, all) {
    // Attempt to retrieve value of Url parameter
    // Returns 'null' if not present, '' is present but no value assigned
    // Returns values from multiple identical params as comma separated values when 'all' is true
    if (!url) {
        return null;
    }
    const d = {},
        a = ((url.split('?')[1] || '').split('#')[0] || '');
    a.split('&').forEach(e => {
        const c = e.split('=');
        if (c[0] === param) {
            d[c[0]] = (all
                ? (d[c[0]]
                    ? (d[c[0]]
                        ? d[c[0]] + (c[1]
                            ? ',' : '')
                        : '')
                    : '')
                : '') + (c.length > 1 ? decodeURIComponent(c[1].replace(/\+/g, ' ')) : '');
        }
    });
    return (typeof d[param] === 'undefined' ? null : d[param]);
}

// Warning - post 30.2 release we need to fix a bug with values of zero not
// being allowed. Not fixing now since so close to code freeze and not causing
// known impact. Any newer unit tests supersede this comment.
export function setSearchUrlParamsHelper(url, params) {
    let newUrl = url;
    if (newUrl) {
        newUrl = setUrlOptionIfExists(newUrl, params, 'sourceValue', params.sourceValue, 'source');
        newUrl = setUrlOptionIfExists(newUrl, params, 'kpTab', params.kpTab, 'kp_tab');
        newUrl = setUrlOptionIfExists(newUrl, params, 'selectedTitle', params.selectedTitle);
        newUrl = setUrlOptionIfExists(newUrl, params, 'searchInfo',
            params.searchInfo, 'selectedTitle');
        newUrl = setUrlOptionIfExists(newUrl, params, 'rankIndex',
            params.rankIndex, 'display_rank');
        newUrl = setUrlOptionIfExists(newUrl, params, 'searchRank',
            params.searchRank, 'rank');
        newUrl = setUrlOptionIfExists(newUrl, params, 'searchTerm', params.searchTerm, 'search');
        newUrl = setUrlOptionIfExists(newUrl, params, 'drugName', params.drugName, 'drug');
        newUrl = setUrlOptionIfExists(newUrl, params, 'tabTitle', params.tabTitle);
        newUrl = setUrlOptionIfExists(newUrl, params, 'accordionTitle', params.accordionTitle);
        newUrl = setUrlOptionIfExists(newUrl, params, 'topicRef', params.topicRef);
        newUrl = setUrlOptionIfExists(newUrl, params, 'anchor', params.anchor);
        newUrl = setUrlOptionIfExists(newUrl, params, 'usageType', params.usageType, 'usage_type');
        newUrl = setUrlOptionIfExists(newUrl, params, 'section', params.section);
        newUrl = setUrlOptionIfExists(newUrl, params, 'sectionValue', params.sectionValue);
    }
    return newUrl;
}

export function setUrlOptionIfExists(url, options, key, value, paramName) {
    if (options && options[key] && value) {
        // Default to 'key' for paramName if not passed
        let urlParam = paramName;
        if (typeof urlParam === 'undefined') {
            urlParam = key;
        }
        return setQueryParamValue(url, urlParam, value);
    }
    return url;
}

export function safeDecodeUriComponent(source) {
    // On invalid input, we no longer throw exceptions.
    // We now return the original input instead of an empty string
    let clean = source;
    try {
        clean = decodeURIComponent(source);
    }
    catch (e) {
        // no-op
    }
    return clean;
}

export function safeDecodeHtmlUriComponent(source) {
    return decodeHtml(safeDecodeUriComponent(source));
}

// Function based on https://github.com/sindresorhus/is-absolute-url
export function isAbsoluteUrl(url) {
    if (!url || typeof url !== 'string') {
        return;
    }

    // Don't match Windows paths `c:\`
    if (/^[a-zA-Z]:\\/.test(url)) {
        return false;
    }

    return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
}

function isRelativeUrlWithoutProtocol(url) {
    const relativeFirstCharacters = [ '.', '/' ];
    return relativeFirstCharacters.indexOf(url[0]) > -1;
}

export function setRelativeRootUrl(url) {
    if (isAbsoluteUrl(url)) {
        return url;
    }
    return url.replace(/^(?!\/)/, '/');
}

// Function based on https://github.com/braintree/sanitize-url
// The function should be used sparingly,
// URLs mostly should be sanitized by backend
export function sanitizeUrl(url) {
    const invalidProtocolRegex = /^(%20|\s)*(javascript|data|vbscript)/im;
    const ctrlCharactersRegex = /[^\x20-\x7EÀ-ž]/gim;
    const urlSchemeRegex = /^([^:]+):/gm;


    if (!url) {
        return 'about:blank';
    }

    const sanitizedUrl = url.replace(ctrlCharactersRegex, '').trim();

    if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
        return sanitizedUrl;
    }

    const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

    if (!urlSchemeParseResults) {
        return sanitizedUrl;
    }

    const urlScheme = urlSchemeParseResults[0];

    if (invalidProtocolRegex.test(urlScheme)) {
        return 'about:blank';
    }

    return sanitizedUrl;
}

export function arrayToUrlArgsWithPrefix(argValues, argName) {
    // This method will take a string (or array) 'argValues', convert it into
    // an array from commas within the string, and prefix each entry with
    // '${argName}=', separating each argument with '&', and returning the
    // resulting string.
    //
    // Examples:
    // Inputs: argValues="1111,2222"  argName="test"
    // Output: "test=1111&test=2222"
    //
    // Inputs: argValues="1111"  argName="test"
    // Output: "test=1111"
    if (!argValues) {
        return '';
    }

    return (argValues.indexOf(',') > -1
        ? argValues.split(',')
        : Array.isArray(argValues)
            ? argValues
            : [ argValues ])
        .map((ele, idx) => `${idx > 0 ? '&' : ''}${argName}=${ele}`).join('');
}

export function urlNeedsImmediateRedirect(url) {
    const parser = document.createElement('a');
    parser.href = url;
    const redirectParamPresent = getQueryParamValues(url, 'redirect');
    const pathname = parser.pathname;
    return (isExternalPathwayUrl(pathname) || isRxTransitionsUrl(pathname)) && !redirectParamPresent;
}

// return true if pathname is for non-angular pathway page, e.g. /pathway/12345/
// (angular pathways pages like /pathway/####/contributors being the exception).
export function isExternalPathwayUrl(pathname) {
    return pathname.match(pathwaysUrlRegex);
}

// return true if pathname is for Rx Transitions link, e.g. /rxtransitions
// (angular pages like /rxtransitions/contributors being the exception).
export function isRxTransitionsUrl(pathname) {
    return pathname.match(rxTransitionsUrlRegex);
}

// return true if pathname is for Drug Interactions link
export function isDrugInteractionsUrl(pathname) {
    return pathname.match(drugInteractionsUrlRegex);
}

export function cleanResetUriComponent(source) {
    if (!source) {
        return '';
    }
    const reset = decodeHtml(decodeURIComponent(encodeURIComponent(source)));
    return encodeURIComponent(sterilizeString(reset));
}

// Current method encode specific characters in topic links url params
// like "+", "space" and "/", for support visited links behaviour.
// Requires link href or search params string with "?".
export function encodeTopicLinksUrlParams(href) {
    if (!href) {
        return href;
    }
    const slashRegex = /\//g;
    const spacesRegex = /\+|\s/g;
    const urlParamsRegex = /\?.*/;

    return href.replace(urlParamsRegex, s1 => {
        return s1.replace(slashRegex, '%2F')
            .replace(spacesRegex, '%20');
    });
}

export function isStatusNotAnError(status) {
    return status > -1
        && status < C_HTTP_STATUS.BAD_REQUEST;
}

export function isStatusRequiresLogin(status) {
    return status === C_HTTP_STATUS.FORBIDDEN
        || status === C_HTTP_STATUS.UNAUTHORIZED
        || status === -1;
}

export function isStatusServerError(status) {
    return status === C_HTTP_STATUS.SERVER_ERROR;
}

export function getLanguageFromImageUrl(url) {
    if (!url || typeof url !== 'string') {
        return '';
    }

    const contentSubStr = 'contents/';
    const imageSubStr = '/image';
    if (url.indexOf(contentSubStr) === -1 || url.lastIndexOf(imageSubStr) === -1) {
        return '';
    }

    let lang = '';
    lang = url.substring(
        url.indexOf(contentSubStr) + contentSubStr.length,
        url.lastIndexOf(imageSubStr)
    );

    if (lang.length > 1) {
        return lang;
    }
    return '';
}

export function isDifferentDomain(targetHref) {
    if (!isAbsoluteUrl(targetHref)) {
        return false;
    }

    const targetHost = getHrefHost(targetHref);
    const currentHost = getHrefHost(getWindow().location.href);

    return targetHost !== currentHost;
}

export const getHrefHost = href => {
    if (!href) {
        return '';
    }

    const iProtPos = href.indexOf('://');

    if (iProtPos > -1) {
        const newHref = `${
            href.substr(0, iProtPos + 3)
        }${
            href.slice(iProtPos + 3).replace(/:\/\//g, '//')
        }`;

        return newHref.split('://')[1].split('/')[0];
    }

    return '';
};

export const canUseURL = () => {
    return (URL instanceof Function);
};
