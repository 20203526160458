import { translationsLoader } from '_acaSrc/localizations/translations-loader';
import { isInternetExplorer } from '_acaSrc/utility/Browsers';

// Adapter for Vue files to access the router. This allows us to encapsulate the
// underlying library which will help us convert over from ui-router to
// vue-router by only a couple of files (this one and the file that initializes
// it).

export default class UtdRouter {
    constructor($router) {
        this._go = (...args) => $router.go(...args);
        this._transitionTo = (...args) => $router.transitionTo(...args);
        this._url = (...args) => $router.url(...args);
        this._path = () => $router.path();
        this._stateParams = () => $router.globals.params;
        this._params = () => $router.globals.params;
        this._search = (...args) => $router.search(...args);
        this._current = () => $router.globals.current;
        this._reload = () => $router.reload();
        this._hash = (...args) => $router.hash(...args);
        this._protocol = (...args) => $router.protocol(...args);
        this._host = (...args) => $router.host(...args);
        this._use = (...args) => translationsLoader(...args);
    }

    go(stateName, params) {
        return this._go(stateName, params);
    }

    transitionTo(stateName, toParams, options) {
        return this._transitionTo(stateName, toParams, options);
    }

    url(url) {
        if (typeof url === 'undefined') {
            return this._url();
        }
        this._url(url);
        return this;
    }

    path() {
        return this._path();
    }

    stateParams() {
        return this._stateParams();
    }

    getParams(property) {
        return this._params()[property];
    }

    search(search) {
        if (typeof search === 'undefined') {
            return this._search();
        }
        this._search(search);
        return this;
    }

    current() {
        // NOTE: Do not rely on this value for initial page loads
        // as state.current.name will be blank, instead use app.routeLoading.
        return {
            name: this._current().name
        };
    }

    reload() {
        return this._reload();
    }

    hash(hash) {
        if (typeof hash === 'undefined') {
            return this._hash();
        }
        this._hash(hash);
        return this;
    }

    protocol(protocol) {
        if (typeof protocol === 'undefined') {
            return this._protocol();
        }
        this._protocol(protocol);
        return this;
    }

    host(host) {
        if (typeof host === 'undefined') {
            return this._host();
        }
        this._host(host);
        return this;
    }

    use(lang) {
        return this._use(lang);
    }

    appendQueryParam(param, value) {
        if (!isInternetExplorer()) {
            const url = new URL(location);
            url.searchParams.set(param, value);
            history.replaceState({}, '', url);
        }
    }

    deleteQueryParam(param) {
        if (!isInternetExplorer()) {
            const url = new URL(location);
            url.searchParams.delete(param);
            history.replaceState({}, '', url);
        }
    }
}