<!-- eslint-disable vue/no-v-html -->
<template>
  <utd-modal-dialog ref="modalDialog"
                    :close-on-overlay-click="false"
                    :close-on-escape="false"
                    :modal-classes="'support-tag-modal-dialog'"
                    :dialog-size="'medium'">
    <template #header>{{ $t('SUPPORTTAG.SUPPORTTAG') }}</template>
    <dl id="supportTagDialog">
      <utd-logo />
      <dt v-text="$t('SUPPORTTAG.SUPPORTTAG')" />
      <dd>
        <span v-html="supportTag.serverTag" />
        <span class="SupportTagSmall"> - SM</span>
        <span class="SupportTagMedium"> - MD</span>
        <span class="SupportTagLarge"> - LG</span>
        <span class="SupportTagXLarge"> - XL</span>
      </dd>
      <dt>{{ $t("SUPPORTTAG.RELEASE") }}</dt>
      <dd>
        <div>{{ `${$t("SUPPORTTAG.SERVER")} ${releaseVersion}` }}</div>
        <span>{{ `${$t("SUPPORTTAG.CLIENT")} ${applicationVersion}` }}</span>
      </dd>
      <dt v-text="$t('SUPPORTTAG.CONTENT')" />
      <dd>{{ contentVersion }}</dd>
      <dt v-text="$t('SUPPORTTAG.LICENSEDTO')" />
      <dd>{{ licensee }}</dd>
      <dt v-text="$t('SUPPORTTAG.SUBMIT-REF-TITLE')" />
      <dd v-if="supportTag.submitCaseMsg"
          class="submitCaseMsg"
          v-html="supportTag.submitCaseMsg" />
      <dd v-if="supportTag.submitCaseError"
          class="submitCaseError"
          v-html="supportTag.submitCaseError" />
      <dd class="st_casereference">
        <form name="st_casereference_form"
              novalidate
              @submit="submitCaseReference($event)">
          <input id="st_casereference_input"
                 v-model="caseReferenceModel"
                 type="text"
                 maxlength="38"
                 :placeholder="$t('SUPPORTTAG.SUBMIT-REF-INPUT')"
                 name="st_casereference_input"
                 autocomplete="off"
                 minlength="1"
                 required>
          <input id="btnEmailSupport"
                 type="submit"
                 :value="$t('FORMS.SUBMIT')"
                 :disabled="btnEmailSupportDisabled">
        </form>
      </dd>
      <dd class="st_casereference"
          @click="caseReferenceOnClick($event)"
          v-html="$t('SUPPORTTAG.SUBMIT-REF-HELP',
                     { contactUsUrl: supportTag.contactUsUrl })" />
    </dl>

    <template v-slot:footer>
      <utd-button class="support-tag-close-button" @click="closeModal">
        {{ $t("CLOSE") }}
      </utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { C_EVENTS } from '_acaSrc/utility/constants';
import {
    SET_SUPPORT_TAG_CASE_REFERENCE_ID,
    SET_SUPPORT_TAG_SUBMIT_CASE_ERROR,
    SET_SUPPORT_TAG_SUBMIT_CASE_MSG
} from '_acaSrc/store/footer.store';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';

const BTN_EMAIL_SUPPORT_ID = 'btnEmailSupport';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UtdLogo
    },
    computed: {
        ...mapGetters('app', [
            'contentVersion',
            'releaseVersion',
            'applicationVersion'
        ]),
        ...mapGetters('footer', [ 'caseReferenceId', 'supportTag', 'licensee' ]),
        caseReferenceModel: {
            get() {
                return this.caseReferenceId;
            },
            set(value) {
                this[SET_SUPPORT_TAG_CASE_REFERENCE_ID](value);
            }
        },
        btnEmailSupportDisabled() {
            return !this.caseReferenceModel || !this.caseReferenceModel.trim();
        }
    },
    mounted() {
        new PubSub().subscribe(C_EVENTS.OPEN_SUPPORT_TAG_MODAL, this.openSupportTag);
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.OPEN_SUPPORT_TAG_MODAL, this.openSupportTag);
    },
    methods: {
        ...mapActions('app', [
            'subscribe',
            'unsubscribe'
        ]),
        ...mapMutations('footer', [
            SET_SUPPORT_TAG_CASE_REFERENCE_ID,
            SET_SUPPORT_TAG_SUBMIT_CASE_ERROR,
            SET_SUPPORT_TAG_SUBMIT_CASE_MSG
        ]),
        openSupportTag() {
            this.$refs.modalDialog.open();
        },
        caseReferenceOnClick(event) {
            if (!event.target || event.target.tagName !== 'A') {
                return;
            }
            new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
        },
        closeModal() {
            this.$refs.modalDialog.close();
        },
        submitCaseReference(event) {
            event.preventDefault();
            const id = this.caseReferenceId;

            this[SET_SUPPORT_TAG_SUBMIT_CASE_MSG]('');
            this[SET_SUPPORT_TAG_SUBMIT_CASE_ERROR]('');

            if (!id) {
                return;
            }
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: BTN_EMAIL_SUPPORT_ID
            });

            utdRest('support/feedback', { id })
                .then(data => {
                    if (data.value === 'true') {
                        this[SET_SUPPORT_TAG_SUBMIT_CASE_MSG](this
                            .$t('SUPPORTTAG.SUBMIT-REF-SUCCESS'));
                        this[SET_SUPPORT_TAG_CASE_REFERENCE_ID]('');
                    }
                    else {
                        this[SET_SUPPORT_TAG_SUBMIT_CASE_ERROR](this
                            .$t('SUPPORTTAG.SUBMIT-REF-ERROR'));
                    }
                })
                .catch(error => {
                    this[SET_SUPPORT_TAG_SUBMIT_CASE_ERROR](this
                        .$t('SUPPORTTAG.SUBMIT-REF-ERROR'));
                    Logger.error(`Reference ID Submission Failed. ID:
                       ${id} Error: ${error.statusText}`);
                });
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.support-tag-modal-dialog .utd-dialog-content {
  .ds1-pt-3();
}

#supportTagDialog .wk-logo {
  padding: 0 0 12px;

  .utd-logo {
    width: 115px;

    .utd-logo-cc & {
      width: 170px;
    }
  }

  .wk-wheel {
    .ds1-mr-2();
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/global/variables';
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@SUCCESS-SUPPORT-TAG-COLOR: @WKCE-GREEN-TINT3;
@ERROR-SUPPORT-TAG-COLOR: @WKCE-RED-TINT3;

#supportTagDialog {
  margin: 0;
  padding: 0 16px;

  > dt {
    .ds1-utd-weight-500();
    margin: 15px 0 0;
    padding: 0;
    color: @DS1-UTD-SUB-TEXT-COLOR;
    text-align: center;
    line-height: 18px;
    font-size: 13px;
  }

  > dd {
    .ds1-utd-size-2();
    margin: 0;
    padding: 0 0 12px;
    text-align: center;

    &.submitCaseMsg,
    &.submitCaseError {
      width: 80%;
      padding: 5px;
      margin: 10px auto;
    }

    &.submitCaseMsg {
      background: @SUCCESS-SUPPORT-TAG-COLOR;
    }

    &.submitCaseError {
      background: @ERROR-SUPPORT-TAG-COLOR;
    }

    &.st_casereference {
      margin-top: 10px;

      input[disabled] {
        color: @DS1-UTD-DISABLED-TEXT-COLOR;
      }
    }

    #st_casereference_input {
      width: 180px;
      margin-right: 10px;
    }
  }
}

:deep(.cobrandingLogoRes) {
  display: none;
}
</style>
