import PendoGuide from './PendoGuide';
import { getHostname } from '_acaSrc/utility/DOM';

export const PENDO_PROMO_GUIDE_PREFIX = 'aca-promo-';

export default class PromoGuide extends PendoGuide {
    _getGuideName() {
        if (!this._isPendoAvailable()) {
            return '';
        }

        let foundGuideName = '';
        let guideName = `${PENDO_PROMO_GUIDE_PREFIX}staging`;

        const winHost = getHostname();
        if (winHost === 'www.uptodate.com' || winHost === 'www.uptodate.cn') {
            guideName = `${PENDO_PROMO_GUIDE_PREFIX}prod`;
        }

        if (this._pendo.findGuideByName(guideName)) {
            foundGuideName = guideName;
        }

        return foundGuideName;
    }
}