<template>
  <div id="twoFactorViewContainer" class="utd-abs-white-carbon">
    <div id="twoFactorView" class="authForm utd-white-margin-border">
      <utd-logo class="utdLogo" />
      <span class="mfa-icon" />

      <p class="code-info" v-text="otcVerificationCodeMessage" />
      <p><strong class="code-info" v-text="otcValidForSecondsMessage" /></p>

      <form class="tf-form pure-form pure-form-stacked"
            name="twoFactorForm"
            @submit.prevent="validate">
        <fieldset v-if="canSubmitOtc">
          <input v-if="otcIsAllowOtcEntry"
                 id="code"
                 v-model.trim="formCodeModel"
                 type="text"
                 required
                 :class="{tfInputError: isInputError}"
                 :placeholder="$t('FORMS.ENTER_SECURITY_CODE')"
                 autocomplete="off"
                 :title="$t('FORMS.ENTER_SECURITY_CODE')"
                 :pattern="numberWithSideSpacesPattern">

          <div class="code-info tf-error" v-text="otcErrorMessage" />

          <button v-if="otcIsAllowOtcEntry"
                  type="submit"
                  class="otc-submit-button pure-button pure-button-primary"
                  :disabled="isDisabled || !isCodeValid"
                  v-text="$t('FORMS.SUBMIT')" />

          <a v-show="otcShowSendNewCode"
             id="btnResendCode"
             class="tf-resend-link"
             @click="callSendNewCode"
             v-text="$t('FORMS.SEND_NEW_CODE')" />
        </fieldset>
        <div v-if="isOtcDisabled" class="tf-error-final">
          <!-- eslint-disable vue/no-v-html -->
          <p class="tf-code-error code-info" v-html="otcErrorMessage" />
        </div>
      </form>
      <div v-if="isUccState" class="tf-help">
        <a v-if="isFooterLoaded"
           :href="getFooterUrl('help')"
           target="_blank"
           rel="noreferrer noopener"
           class="tf-helplink"
           v-text="getFooterTitle('help')"
        />
      </div>
      <div v-if="!isUccState" class="tf-marketing">
        <p v-text="$t('MFA.CONTACT_INFO')" />
        <p v-text="$t('MFA.EMAIL')" />
        <p v-text="$t('MFA.PHONE')" />
        <p v-text="$t('MFA.PHONE_OUTSIDE_US')" />
        <br>
        <p v-text="$t('MFA.CS_CONTACT_INFO')" />
        <p v-html="$t('MFA.CS_ADDRESS')" />
        <p v-text="$t('MFA.CS_PHONE')" />
        <p v-text="$t('MFA.CS_PHONE_OUTSIDE_US')" />
        <p v-text="$t('MFA.CS_EMAIL')" />
      </div>
    </div>
    <div class="tf-copyright">
      <utd-copyright-provider :full="true" :center="true" />
    </div>
    <div v-if="otcOneTimeCode" id="oneTimeCode" class="otc-code-info">
      <span class="otc-code" v-text="otcOneTimeCode" />
      <span>*Code only shown in development environments</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { safeTimeout } from '_acaSrc/utility/timers';
import { isNumericOnly } from '_acaSrc/utility/String';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import { numberWithSideSpacesPattern } from '_acaSrc/utility/constants';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';

import {
    SET_OTC_SHOW_SEND_NEW_CODE,
    SET_FORM_CODE
} from '_acaSrc/store/auth.store';

export default {
    components: {
        UtdCopyrightProvider,
        UtdLogo
    },
    data() {
        return {
            isDisabled: false,
            cancelDelayShowNewCode: null,
            numberWithSideSpacesPattern
        };
    },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('footer', [
            'getFooterUrl',
            'getFooterTitle',
            'isFooterLoaded'
        ]),
        ...mapGetters('auth', [
            'formCode',
            'otc',
            'otcIsValid',
            'otcVerificationCodeMessage',
            'otcValidForSecondsMessage',
            'otcErrorMessage',
            'otcIsAllowOtcGeneration',
            'otcIsAllowOtcEntry',
            'otcOneTimeCode',
            'otcTimeoutInterval',
            'otcShowSendNewCode'
        ]),
        isInputError() {
            return this.otcErrorMessage !== '';
        },
        isCodeValid() {
            return isNumericOnly(this.formCode);
        },
        isOtcDisabled() {
            return !this.otcIsAllowOtcEntry && !this.otcIsAllowOtcGeneration;
        },
        canSubmitOtc() {
            return this.otcIsAllowOtcEntry || this.otcIsAllowOtcGeneration;
        },
        formCodeModel: {
            get() {
                return this.formCode;
            },
            set(value) {
                this[SET_FORM_CODE](value);
            }
        }
    },
    mounted() {
        this.onInit();
    },
    methods: {
        ...mapActions('auth', [
            'validateCode',
            'sendNewCode'
        ]),
        ...mapMutations('auth', [
            SET_OTC_SHOW_SEND_NEW_CODE,
            SET_FORM_CODE
        ]),
        delayShowSendNewCode() {
            // Setup 'Send new code' button activation
            this[SET_OTC_SHOW_SEND_NEW_CODE](false);
            const { cancelTimeout } = safeTimeout(() => {
                if (this.otcIsAllowOtcGeneration) {
                    this[SET_OTC_SHOW_SEND_NEW_CODE](true);
                }
            }, this.otcTimeoutInterval * 1000, { cancelHook: true });
            this.cancelDelayShowNewCode = cancelTimeout;
        },
        validate() {
            this.isDisabled = true;
            this.validateCode().then(() => {
                // If user has failed 5 times, then show 'Send New Code' button immediately
                if (!this.otcIsAllowOtcEntry) {
                    this[SET_OTC_SHOW_SEND_NEW_CODE](true);
                    this.cancelDelayShowNewCode && this.cancelDelayShowNewCode();
                }
            }).finally(() => {
                this.isDisabled = false;
            });
        },
        callSendNewCode() {
            this.sendNewCode();
            this.delayShowSendNewCode();
        },
        onInit() {
            this.delayShowSendNewCode();
        }

    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/wkce/colors/wkce-app-styles';

#twoFactorViewContainer {
  &.utd-abs-white-carbon {
    background: white;
  }

  .tf-copyright {
    .copyright {
      text-align: center;
      display: block;
      .utd-copyright();
      font-size: 12px;
      padding-top: 30px;
    }
  }

  .otc-code-info {
    text-align: center;
    padding-top: 30px;
    font-size: 12px;

    .otc-code {
      font-weight: bold;
    }
  }

  .iosfullscreen & {
    border-top: 20px solid #00905a; // No DS1 mapping, legacy color
  }
}

#twoFactorView {
  .pure-form {
    text-align: center;
  }

  input[type='text'] {
    padding: 20px 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 300px;
    text-align: center;
    font-size: 1.4em;
    border-color: @WKCE-GRAY-TINT1; // Legacy color, not mapping to DS1 border color

    &:focus {
      border-color: #0081c6; // No DS1 mapping, legacy color
    }

    &.tf-input-error,
    &:focus.tf-input-error {
      border-color: @WKCE-RED; // Legacy color, not mapping to DS1
    }
  }

  .tf-error {
    color: @WKCE-RED; // Legacy color, not mapping to DS1
    font-size: 14px;
  }

  button {
    width: auto;
    margin: 32px auto 0 !important;
    padding: 11px 16px;
  }

  :deep(a) {
    .ds1-pl-0();
    .ds1-pt-0();
    .ds1-mt-0();
    padding-bottom: 25px;
  }

  .utdLogo {
    display: block;
  }
}

.mfa-icon {
  display: block;
  margin: 0 auto 8px;
  .mfa-icon();
}

.authForm {
  .tf-helplink,
  .tf-resend-link {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #0081c6; // No DS1 mapping, legacy color
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .tf-helplink {
    margin-top: 37px;
  }

  .tf-resend-link {
    margin-top: 27px;
    margin-bottom: 31px;
  }
}

.tf-code-error {
  color: red; // Legacy color
}

@media only screen and (min-width: 768px) {
  .isTablet #twoFactorView {
    margin: 10px 16%;
  }

  .isDesktop .authForm {
    border: 1px solid #dadada;
    border-radius: 0;
    max-width: 600px;
    margin: 60px auto 32px;
    padding-top: 32px;

    &#twoFactorView {
      input[type=text] {
        font-size: 1em;
        padding: 12px 0;
      }

      & > div {
        width: auto;
      }
    }
  }
}
</style>
