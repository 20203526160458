<template>
  <div class="form-footer floating-labels-form">
    <div v-if="isSocialIconsVisible" class="social">
      <ul>
        <li class="social-circle">
          <a id="sfiFacebook"
             class="wkce-icon-filled-facebook social-icon"
             href="https://www.facebook.com/UpToDateEBM"
             target="_blank"
             rel="noopener noreferrer" />
        </li>
        <li class="social-circle">
          <a id="sfiTwitter"
             class="wkce-icon-filled-twitter social-icon"
             href="https://twitter.com/UpToDate"
             target="_blank"
             rel="noopener noreferrer" />
        </li>
        <li class="social-circle">
          <a id="sfiLinkedIn"
             class="wkce-icon-filled-linkedin social-icon"
             href="https://www.linkedin.com/company/uptodate?trk=hb_tab_compy_id_21924"
             target="_blank"
             rel="noopener"
             aria-label="UpToDate LinkedIn" />
        </li>
        <li class="social-circle">
          <a id="sfiYoutube"
             class="wkce-icon-filled-youtube social-icon"
             href="https://www.youtube.com/uptodateebm"
             target="_blank"
             rel="noopener noreferrer"
             aria-label="UpToDate YouTube" />
        </li>
      </ul>
    </div>

    <div class="clear" />

    <div v-if="!isOidcUser" class="utd-login-footer">
      <p class="utd-about-us-link">
        <a v-if="isFooterLoaded"
           :href="getFooterUrl('aboutUs')"
           target="_blank">{{ $t('LOGIN.ABOUT_LINK') }}
        </a>
      </p>
      <utd-copyright-provider :full="true" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';

export default {
    components: {
        UtdCopyrightProvider
    },
    computed: {
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('auth', [ 'isOidcUser' ]),
        ...mapGetters('footer', [
            'getFooterUrl',
            'isFooterLoaded'
        ]),
        ...mapGetters('login', [
            'loginSubscriberBox'
        ]),
        isSocialIconsVisible() {
            return !this.isOidcUser && this.loginSubscriberBox && !this.isDesktopView;
        }
    }
};
</script>
<style lang="less" scoped>
@import '~_acaAssets/global/variables';
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-form {
  .utdWkHeader & {
    .utd-login-footer {
      display: none;
    }
  }

  .social > ul > li {
    margin: 0;
    padding: 16px;
    display: inline-block;

    .social-icon {
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: @WK_UTD_GRAY_100;
      line-height: 32px;

      &::before {
        font-size: 15px;
      }
    }
  }

  .utd-about-us-link {
    padding-top: 15px;

    a {
      font-size: 14px;
      text-decoration: none;
      color: @WK_UTD_BLUE_HOVER;
    }
  }

  .utd-oidc-footer {
    bottom: 16px;
    position: absolute;

    > ul > li {
      display: inline-flex;

      > a {
        color: @DS1-UTD-GRAY-TEXT-COLOR;
      }
    }
  }

  .utd-oidc-footer-container {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .utd-form {
      .utd-login-footer {
        display: none;
      }
    }
  }
}
</style>
