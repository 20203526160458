<template>
  <div class="my-account-sidebar">
    <div class="sidebar__container">
      <div class="sidebar__header">{{ $t('MYACCOUNT.MY_ACCOUNT') }}</div>
      <div class="sidebar__content">
        <div class="sidebar__account-info">
          <span class="sidebar__welcome-name"
                v-text="welcomeName" />
          <span id="sidebar__email"
                class="sidebar__email emailTooltip"
                aria-labelledby="sidebar__email"
                v-text="userEmail" />
        </div>
        <div v-if="!isSso" class="sidebar__username ds1-pb-1">
          <span class="sidebar__username-label sidebar-bolded">
            Username: {{ myAccountUserName }}
          </span>
        </div>
        <div class="sidebar__account-action">
          <span v-if="accessToChangeCredentials"
                class="sidebar__link-container">
            <span class="wkce-icon-pencil" />
            <a class="sidebar__change-username-link ds1-pb-1"
               href="#"
               @click="openUsernameDialog()">{{ $t('MYACCOUNT.CHANGE_USERNAME') }}</a>
          </span>
          <span v-if="accessToChangeCredentials"
                class="sidebar__link-container">
            <span class="wkce-icon-pencil" />
            <a href="#"
               class="sidebar__change-password-link ds1-pb-1"
               @click="openPasswordDialog()">{{ $t('MYACCOUNT.CHANGE_PASSWORD') }}</a>
          </span>
          <span class="sidebar__link-container">
            <span class="wkce-icon-pencil" />
            <a class="sidebar__edit-contact-link ds1-pb-1"
               href="#"
               :aria-label="C_MY_ACCOUNT.CONTACT_INFO_LABEL"
               @click="openContactInfoDialog()">{{ $t('MYACCOUNT.VIEW_EDIT_CONTACT_INFORMATION') }}
            </a>
          </span>
          <span v-if="showMergeAccountsLink"
                class="sidebar__link-container">
            <span class="wkce-icon-users" />
            <a class="sidebar__merge-accounts-link"
               href="#"
               @click="openConsolidateAccounts()">Merge Accounts</a>
          </span>
        </div>
      </div>
    </div>
    <change-password-dialog ref="changePasswordDialog" />
    <change-username-dialog ref="changeUsernameDialog" />
    <contact-information-dialog ref="contactInfoDialog"
                                :is-sso="isSso"
                                :is-edit-my-profile-link="isEditMyProfileLink" />
    <my-account-alert-dialog ref="alertDialog" :title="ContactInfoAlertTitle">
      <utd-notification class="contact-information-alert"
                        :notification-mode="'error'">
        <template #title>Error</template>
        <ul class="ds1-pl-3">
          <li>
            The form for editing or viewing your contact information or your My
            Profile Information is unavailable.
          </li>
          <li>Please try again later.</li>
        </ul>
      </utd-notification>
    </my-account-alert-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChangeUsernameDialog from './ChangeUsernameDialog.vue';
import ChangePasswordDialog from './ChangePasswordDialog.vue';
import ContactInformationDialog from './ContactInformationDialog.vue';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS, C_MY_ACCOUNT, C_UI, isOidcRegex } from '_acaSrc/utility/constants';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import { RichContentTooltip } from '_acaSrc/utility/tooltip';
import MyAccountAlertDialog from './MyAccountAlertDialog.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';

export default {
    components: {
        ChangeUsernameDialog,
        ChangePasswordDialog,
        ContactInformationDialog,
        MyAccountAlertDialog,
        UtdNotification
    },
    emits: [ 'close-sidebar' ],
    data() {
        return {
            C_MY_ACCOUNT,
            isEditMyProfileLink: false
        };
    },
    computed: {
        ...mapGetters('app', [ 'isMobileOnDesktop', 'router', 'tooltipConfig', 'isUccState' ]),
        ...mapGetters('footer', [ 'supportTag' ]),
        ...mapGetters('profile', [
            'welcomeName',
            'userEmail'
        ]),
        ...mapGetters('account', [
            'myAccountUserName',
            'myAccountAccessLevel',
            'myAccountContactInfo'
        ]),
        hasFullAccess() {
            return this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.FULL_ACCESS;
        },
        showMergeAccountsLink() {
            return this.hasFullAccess && !this.isUccState;
        },
        isSso() {
            return isOidcRegex.test(this.supportTag.serverTag);
        },
        accessToChangeCredentials() {
            return this.hasFullAccess && (!this.isSso);
        },
        ContactInfoAlertTitle() {
            return `${this.$t('MYACCOUNT.CONTACT_INFORMATION')} error`;
        }
    },
    async mounted() {
        const emailTooltip = document.querySelector('.emailTooltip');
        if (!emailTooltip) {
            return;
        }
        await this.confirmConfigLoaded();
        this.emailTooltip = new RichContentTooltip(emailTooltip, this.tooltipConfig, {
            theme: 'utd-tooltip-interactive-bookmark',
            placement: 'bottom',
            delay: C_UI.MENU_SHOW_DELAY_MS,
            trigger: 'mouseenter',
            content: ele => ele.innerText,
            zIndex: 9999999
        });
        this.emailTooltip.initialize().catch(() => {
            // No-op
        });
        new PubSub().subscribe(C_EVENTS.OPEN_CONTACT_INFO_MODAL,
            this.openContactInfoDialogMyProfile);
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.OPEN_CONTACT_INFO_MODAL,
            this.openContactInfoDialogMyProfile);
    },
    methods: {
        ...mapActions('app', [
            'confirmConfigLoaded'
        ]),
        openPasswordDialog() {
            this.$refs.changePasswordDialog.open();
            new PubSub().publish('wkutd.openMyAccountDialog');
        },
        openUsernameDialog() {
            this.$refs.changeUsernameDialog.open();
            new PubSub().publish('wkutd.openMyAccountDialog');
        },
        openContactInfoDialog() {
            this.isEditMyProfileLink = false;
            this.logUiClickEvent('contactInfoLink');
            if (this.myAccountContactInfo.isSuccess) {
                this.$refs.contactInfoDialog.open();
            }
            else {
                this.$refs.alertDialog.open();
            }
            new PubSub().publish('wkutd.openMyAccountDialog');
        },
        async openContactInfoDialogMyProfile() {
            this.isEditMyProfileLink = true;
            this.logUiClickEvent('contactInfoLink');
            if (this.myAccountContactInfo.isSuccess) {
                this.$refs.contactInfoDialog.open();
                await this.$nextTick();
                this.$refs.contactInfoDialog.handlePracticeSettingScroll();
            }
            else {
                this.$refs.alertDialog.open();
            }
            new PubSub().publish('wkutd.openMyAccountDialog');
        },
        logUiClickEvent(elem) {
            logUiClickEventHelper({
                uiElementName: `myAccountProfile_${elem}`
            });
        },
        openConsolidateAccounts() {
            this.logUiClickEvent('mergeAccountsLink');
            const options = {
                doEmit: true,
                doRemoveQueryParam: false
            };
            this.$emit('close-sidebar', options);
            this.router.url('/account/consolidate-accounts');
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.change-password-dialog,
.change-username-dialog {
  .dialog__fieldset {
    width: 290px;
  }
}

.change-password-dialog,
.change-username-dialog,
.contact-information-dialog {
  .dialog__header {
    .ds1-mh-3();
    .ds1-mt-3();
    .ds1-mb-2();
    font-size: 16px;
  }

  .dialog__content {
    font-size: 14px;
  }

  .dialog__license-info {
    .ds1-reset-p();
    .ds1-mb-3();
  }

  .dialog__input-title {
    .ds1-mb-2();
  }

  .dialog__input-container {
    .ds1-mb-2();
  }

  .dialog__rules-title {
    .ds1-reset-p();
  }

  .dialog__login-info-form {
    .ds1-utd-size-2();
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .dialog__fieldset {
    .ds1-ma-0();
    .ds1-pa-0();
    border: none;
  }

  .dialog__form-rules {
    .ds1-reset-p();
    .ds1-utd-size-2();
    .ds1-pl-2();
  }

  .dialog__server-error-text,
  .dialog__rule-item {
    .ds1-reset-p();
  }

  .dialog__characters {
    .ds1-pl-2();
  }

  #unexpectedServerError {
    .ds1-ma-0();
    .ds1-mb-1();
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-PROFILE-ICON-COLOR: @WKCE-BLUE;

.sidebar__welcome-name {
  .ds1-utd-weight-600();
  font-size: 18px;
  left: -17px;
  position: relative;

  &::before {
    position: relative;
    font-family: "wk-icons-filled";
    content: '\e800';
    color: @DS1-PROFILE-ICON-COLOR;
    font-size: 16px;
    left: -9px;
  }
}

.contact-information-alert {
    .ds1-mt-0();
    border: none;
    :deep(.utd-notification-icon) {
      font-size: 24px;
      top: 14px;
    }
  }

.sidebar__email {
  text-overflow: ellipsis;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
}
</style>