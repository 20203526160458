import {
    isEqual as lodashIsEqual,
    merge as lodashMerge
} from 'lodash';

export function isEqual(obj1, obj2) {
    return lodashIsEqual(obj1, obj2);
}

export function merge(target, ...sources) {
    return lodashMerge(target, ...sources);
}
