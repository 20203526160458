import store from './store';
import utdRest from './utility/http/UtdRestHooks';
import utdRestClient from '../src/utility/http/UtdRestClient';
import {
    C_APP,
    C_EVENTS,
    C_BROWSER,
    C_TOPIC,
    C_CME_UI_LINKS
} from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import IndexManager from '_acaSrc/utility/IndexManager';
import { safeTimeout } from '_acaSrc/utility/timers';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';
import { getWindow } from '_acaSrc/utility/DOM';
import {
    getRelativeUrl,
    getUrlHash,
    getUrlWithoutHash
} from '_acaSrc/utility/http';
import { setJsnlogOptions } from '_acaSrc/utility/Logger';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';
import { setBrowserTabTitle } from '_acaSrc/utility/Browsers';
import { purgePersistentCache } from '_acaSrc/utility/Events';
import { injectGraphicsStyles } from '_acaSrc/utility/contents/graphic/graphic';
import { applyMobileSafariScrollbarFix } from '_acaSrc/utility/contents/topic/topic';
import {
    SET_COBRANDING_DATA,
    SET_APP_DATA_LOADED,
    REFRESH_STORED_SETTINGS,
    SET_DESTINATION_URL,
    SET_ONE_TRUST_ENABLED,
    SET_CLICK_EVENT_URLS,
    SET_HAS_SESSION_DATA,
    SET_ANKI_TEMPLATE,
    SET_GRAPHICS_STYLES_LOADED,
    SET_ROUTE_LOADING,
    SET_LAST_URL_LOADED,
    SET_ROUTE_PATHNAME,
    SET_PUBLIC_CAPTCHA_KEY
} from '_acaSrc/store/app.store';
import {
    SET_SAVE_SCROLL_POSITION,
    SET_TOPIC_URL,
    DO_NEXT_TOPIC_SCROLL_SMOOTHLY,
    SET_RESET_ELEMENTS_STABLE,
    SET_TOPIC_ELEMENTS_STABLE,
    SET_SHOW_GRAPHIC_TAB_CONTENT
} from '_acaSrc/store/topic/topic.store';
import { SET_SEARCH_STATE } from '_acaSrc/store/search.store';
import { SET_SHOW_ATHENS_LINK } from '_acaSrc/store/feature.store';
import { SET_SKIP_LINK_URL, SET_POST_LOGIN_URL } from '_acaSrc/store/login.store';
import { addPendoUnlockRatingControls } from '_acaSrc/utility/PendoGuides/RatingGuide';
import {
    generateCookieScripts,
    oneTrustAvailable,
    rebindCookieManagerLink
} from '_acaSrc/utility/OneTrustCookieTool';
import UrlScrollTracker from '_acaSrc/utility/http/UrlScrollTracker';
import {
    SET_COUNTRIES_FIELD,
    SET_PRACTICE_SETTINGS_FIELD,
    SET_PRACTICE_TYPES_FIELD,
    SET_SPECIALTIES_FIELD,
    SET_STATES_FIELD
} from '_acaSrc/store/account.store.js';

export default function runUtdApp() {
    new IndexManager().setBodyCssClasses({
        'ds1-utd-js': 'ds1-utd-js',
        ngLoading: 'ngLoading'
    });

    setJsnlogOptions();
    createPersistentCache();
    restoreStoredSettings();
    initUtdAppData();
    setupPositionFooterHandler();
}

const createPersistentCache = () => {
    // Create persistent cache buckets
    new UtdCache().init();
    const utdQc = new UtdQueuedCache();
    utdQc.init('utdSPAAutoComplete', 10);
    utdQc.init('utdSPAContentCache', 15);
    utdQc.init('utdSPAOutlineCache', 15);
    utdQc.init('utdVisitedLinksCache', 50);

    new PubSub().subscribe('wkutd.purgePersistentCache', () => {
        purgePersistentCache({
            isDesktopView: store.getters['device/isDesktopView']
        });
    });
};

const restoreStoredSettings = () => {
    store.commit(`app/${REFRESH_STORED_SETTINGS}`);

    new IndexManager().bodyCss.set('textSize', store.getters['app/storedSetting']('textSize'));
};

const getAppId = () => {
    // To retrieve all the div Ids on the index page
    let id;
    if (!window.btoa) {
        /* eslint-disable-next-line */
        var Base64 = { _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) { var t = ""; var n, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ""; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9\+\/=]/g, ""); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/\r\n/g, "\n"); var t = ""; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ""; var n = 0; var r = 0; var c3 = 0; var c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } }
        id = Base64.encode(
            Array.prototype.map.call(document.querySelectorAll('div[id]'), function(retrieve) {
                return retrieve.id;
            }).toString()
        );
    }
    else {
        id = btoa(
            Array.prototype.map.call(document.querySelectorAll('div[id]'), function(retrieve) {
                return retrieve.id;
            }).toString()
        );
    }

    return id;
};

async function initUtdAppData() {
    utdRestClient.init({ vuexStore: store });
    let data = null;
    if (process.env.BABEL_ENV !== 'test') {
        data = await utdRest('app/init', getAppId());
    }
    processStoreData(data);
    new IndexManager().bodyCss.clear('ngLoading');
    setupPersistentCache();
    setupGraphicStyles();
    setupOneTrustCookies();
    setupPendo();

    new PubSub().publish(C_EVENTS.APP_DATA_LOADED);

    if (store.getters['profile/isKeepOneAccountRequest']) {
        new PubSub().publish(C_EVENTS.NEXT_STEPS_MERGE_CHECK);
    }
}

export const transitionsOnCreate = () => {
    // If not back/forward navigation, then enable cache hit events
    if (!getWindow().popStateActive) {
        new UtdCache().doCacheHitEvent = true;
    }

    // Prior to visiting certain groups of pages, we would like
    // to take special actions.
    const pubSub = new PubSub();
    pubSub.publish('wkutd.$locationChangeStart', {
        fullUrl: getWindow().location.href,
        pathname: getWindow().location.pathname
    });

    store.commit(`topic/${SET_SAVE_SCROLL_POSITION}`, false);
};

const locationChangeSuccessHandler = () => {
    safeTimeout(function() {
        if (store.getters['topic/isTopicView']) {
            store.dispatch('topic/whenTopicElementsStable').then(() =>
                store.commit(`topic/${SET_SAVE_SCROLL_POSITION}`, true));
        }

        if (typeof epicClient !== 'undefined') {
            if (epicClient.isActive()) {
                epicClient.bindExternalWindowTargets();
            }
        }
    }, 1000);
};

const setupPositionFooterHandler = () => {
    new PubSub().subscribe(C_EVENTS.POSITION_FOOTER, params => {
        store.dispatch('footer/positionFooter', params);
    });
};

const getTransitionStateNames = transition => {
    return {
        transFrom: transition.from().name,
        transTo: transition.to().name
    };
};

const isLegalRedeemInstantlyTransition = transition => {
    const { transTo } = getTransitionStateNames(transition);
    return transTo === 'cme.redeem.evaluate' && transition.params().redeemInstantly;
};

const isIllegalRedeemFlowTransition = transition => {
    const { transFrom, transTo } = getTransitionStateNames(transition);
    return (transFrom === 'cme.history' && transTo === 'cme.redeem.evaluate')
        || (transFrom === 'cme.redeem.evaluate' && transTo === 'cme.redeem.reflect')
        || (transFrom === 'cme.dashboard' && transTo.match(/cme.redeem.reflect|cme.redeem.evaluate/));
};

export const transitionOnBeforeCmeRouteGuard = transition => {
    // Route guard to confirm user is logged in before proceeding to 'CME' routes
    return store.dispatch('app/delayExecuteUntilDataLoaded', () => {
        if (!store.getters['user/userLoggedIn']) {
            store.commit(`login/${SET_POST_LOGIN_URL}`, getRelativeUrl());
            return transition.router.stateService.target('login');
        }
    });
};

// This handler is to redirect to CME dashboard when user hits the
// 'Back/Forward' button in the following scenarios:
// - User is viewing CME History, and previous/next view was CME Survey
// - User is viewing CME Survey, and previous/next view was CME
//   Reflection
// - User is viewing Dashboard, and previous/next view is CME Reflection
//   or CME Survey
export const transitionOnBeforeCmeRedeemGuards = transition => {
    if (isLegalRedeemInstantlyTransition(transition)) {
        return;
    }
    if (isIllegalRedeemFlowTransition(transition)) {
        transition.abort();
        store.getters['app/router'].url(C_CME_UI_LINKS.dashboard).replace();
    }
};

export const transitionOnBeforeTopicGraphicsGuard = transition => {
    const urlHash = getUrlHash();
    if (transition.to().name.match(/\btopic\b|\btopicLanguage\b/) && urlHash === 'topicGraphics') {
        store.dispatch('app/delayExecuteUntilDataLoaded', () => {
            if (store.getters['app/isProspectMode'] || store.getters['app/isLapsedUser']) {
                getWindow().location.href = getUrlWithoutHash();
            }
        });
    }
};

// eslint-disable-next-line complexity
export const transitionsOnStart = transition => {
    locationChangeSuccessHandler();

    // Store name of route being loaded
    store.commit(`app/${SET_ROUTE_LOADING}`, transition.to().name);

    // Clear any current topic.store::topicCore.url
    // if transitioning to a non-topic route. This is done to ensure that the
    // onTopicElementsStable() method will run when a topic route is next entered.
    if (!transition.to().name.match(/\btopic\b|\btopicLanguage\b|\bdxyPackageInsert\b/)) {
        store.commit(`topic/${SET_TOPIC_URL}`, '');
    }
    else if (getUrlHash(store.getters['app/lastUrlLoaded']) === C_TOPIC.GRAPHIC_TAB_HASH) {
        // This code is required for accurate article positioning for back/forward navigation.
        // When transitioning to a topic route where the previous URL contained
        // a #topicGraphics hash, then begin process of switching the tabs to
        // ensure the scroller can get an accurate position for any new # target.
        store.commit(`topic/${SET_RESET_ELEMENTS_STABLE}`, true);
        store.commit(`topic/${SET_TOPIC_ELEMENTS_STABLE}`, false);
        store.commit(`topic/${SET_SHOW_GRAPHIC_TAB_CONTENT}`, false);
    }

    // Only reset the UI if the state is changing,
    // -- OR --
    // state is the same, the previous pathname does not
    // equal the loading pathname, and there is no hash
    // found on url.
    if (transition.from().name !== transition.to().name
        || (transition.from().name === transition.to().name
         && store.getters['app/routePathname'] !== getWindow().location.pathname
         && getUrlHash() === '')) {
        const scrollToFixedToolbar = transition.to().name === 'search'
            && store.getters['app/isFixedToolbar'];
        store.dispatch('app/resetUI', { scrollToFixedToolbar });
    }
};

// This function is used for when we have found a valid ACA route,
// but we don't support it on the desktop view yet, so send it back
// to the server for processing.
const determineDesktopRedirect = () => {
    const targetUrl = getRelativeUrl();
    const urls = C_APP.CLIENT_URLS;
    let isDesktopSupportedUrl = false;

    if (
        store.getters['device/browserData'].type === C_BROWSER.TYPE_DESKTOP
        && store.getters['feature/isResponsiveDesktopEnabled']
    ) {
        for (let i = 0; i < urls.length; i++) {
            // ancient code here. Probably a bug, but also might be dead code.
            // Too risky to change during this lint purge.
            // eslint-disable-next-line no-cond-assign
            if (isDesktopSupportedUrl = urls[i].test(targetUrl)) {
                break;
            }
        }
        if (!isDesktopSupportedUrl) {
            getWindow().location.href = targetUrl;
            return false;
        }
    }
    return true;
};

const transitionOnSuccessBegin = () => {
    // transition hook to determine redirect for desktop, replacement of `determineDesktopRedirect`
    // fn in config phase
    store.commit(`app/${SET_DESTINATION_URL}`, getRelativeUrl());

    return determineDesktopRedirect();
};

const transitionOnSuccessSetBrowserTitle = transition => {
    // TODO IMM 06/07 - Validate the following code...
    const to = transition.to();
    const title = to && to.data && to.data.title;
    if (title) {
        setBrowserTabTitle(title);
    }
};

const transitionOnSuccessTopicScroll = transition => {
    if (transition.to().name.match(/\btopic\b|\btopicLanguage\b/)) {
        if (transition.to().name !== transition.from().name) {
            store.commit(`topic/${DO_NEXT_TOPIC_SCROLL_SMOOTHLY}`, false);
        }

        // Check if we need to reposition the current page
        store.dispatch('topic/scrollToActiveHash');
    }
};

const transitionOnSuccessEnd = () => {
    new IndexManager().bodyCss.set(
        'routerName',
        `utdRoute-${store.getters['app/router'].current().name}`
    );
    // Unless history 'Back' or 'Forward' used, this method
    // will be the last to execute for transitionsOnSuccess,
    // so we reset the doCacheHitEvent variable state to 'false' here.
    new UtdCache().doCacheHitEvent = false;
    store.commit(`app/${SET_LAST_URL_LOADED}`);

    new PubSub().publish('wkutd.successful-transition');

    new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
        reset: true,
        doFocusChange: false,
        fromLocation: 'utd-app.run: $transitions.onSuccess'
    });

    // On mobile/tablet devices, where header redesign feature is active,
    // scroll to top of page if there is no '#' in the URL
    if (store.getters['feature/isHeaderRedesign']
    && (store.getters['device/browserData'].type !== C_BROWSER.TYPE_DESKTOP
        || store.getters['device/isMobileOnDesktop']) && getUrlHash() === '') {
        getWindow().scroll(0, 0);
    }

    rebindCookieManagerLink();

    store.dispatch('app/openBookmarkedMyAccountSidebar', store.getters['app/router'].url());
};

const transitionOnSuccessSetRoutePathname = transition => {
    const winPath = getWindow().location.pathname;
    const lstPath = store.getters['app/routePathname'];

    if (transition.to().name === transition.from().name
     && (transition.from().name === 'topic'
      || transition.from().name === 'topicLanguage')
     && winPath !== lstPath) {
        // Store target route's URL path.
        // Used in App.vue to force reactivity for route components.
        // Ensures that from/to topic components are rebuilt when identical.
        store.commit(`app/${SET_ROUTE_PATHNAME}`);
    }
};

const transitionOnSuccessProcessPopState = transition => {
    if (getWindow().popStateActive) {
        getWindow().popStateActive = false;
        backForwardNavigationHandler(transition);
    }
};

export const transitionsOnSuccess = transition => {
    transitionOnSuccessBegin();
    transitionOnSuccessSetBrowserTitle(transition);
    transitionOnSuccessTopicScroll(transition);
    transitionOnSuccessEnd();
    transitionOnSuccessSetRoutePathname(transition);
    transitionOnSuccessProcessPopState(transition);
};

export const backForwardNavigationHandler = transition => {
    // When navigating back to search results, restore last scroll position
    if (transition.from().name !== transition.to().name
     && transition.to().name === 'search') {
        new UrlScrollTracker().restoreLastPosition = true;
    }

    // This code was originally written for Angular's
    // `$rootScope.$watch()` handler watching $location.absUrl().

    // Don't log cache hit events when user clicked
    // either history 'Back' or 'Forward' actions
    new UtdCache().doCacheHitEvent = false;

    // Force close any open modal dialog
    new ServerMessaging().resetModal();

    // Trigger scrollToActiveHash here to cover restoring mobile topic view
    // from outline view, and to restore section pointer when user navigates
    // using the 'Back'/'Forward' browser controls.
    if (store.getters['topic/isTopicView']) {
        store.dispatch('topic/scrollToActiveHash');
    }

    // Resolve CORE-1946
    if (store.getters['feature/isHeaderRedesign']) {
        new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
            reset: true,
            doFocusChange: false,
            fromLocation: 'utd-app.run::backForwardNavigationHandler'
        });
    }

    // Restore missing scrollbars for ios
    if (store.getters['app/isMobileSafari']) {
        applyMobileSafariScrollbarFix();
    }
};

const processStoreData = data => {
    if (!data) {
        return;
    }

    store.commit(`feature/${SET_SHOW_ATHENS_LINK}`, !!data.openAthensEnabled);
    store.commit(`app/${SET_COBRANDING_DATA}`, data);
    store.commit(`app/${SET_CLICK_EVENT_URLS}`, data.uiClickEventUrls);
    store.commit(`login/${SET_SKIP_LINK_URL}`, data.destinationUrl);
    store.commit('profile/SET_PROFILE_REFRESH_INTERVAL', data.profileRefreshInterval);

    store.commit(`app/${SET_DESTINATION_URL}`, data.destinationUrl);
    const searchState = store.getters['search/searchResultsState'];
    store.commit(`search/${SET_SEARCH_STATE}`, searchState);
    store.commit(`app/${SET_HAS_SESSION_DATA}`, true);
    store.commit(`app/${SET_ANKI_TEMPLATE}`, data.ankiDownloadInfoTemplate);
    store.commit(`app/${SET_PUBLIC_CAPTCHA_KEY}`, data.publicCaptchaKey);

    store.commit(`account/${SET_COUNTRIES_FIELD}`, setAccountVariable(data.countriesAndStates.countries));
    store.commit(`account/${SET_STATES_FIELD}`, setAccountVariable(data.countriesAndStates.states));
    store.commit(`account/${SET_PRACTICE_TYPES_FIELD}`, setAccountVariable(data.myAccountOptions.practiceTypes));
    store.commit(`account/${SET_SPECIALTIES_FIELD}`, setAccountVariable(data.myAccountOptions.specialties));
    store.commit(`account/${SET_PRACTICE_SETTINGS_FIELD}`, setAccountVariable(data.myAccountOptions.practiceSettings));

    store.commit(`app/${SET_APP_DATA_LOADED}`);
    store.dispatch('app/executePostDataLoadMethods');

    store.dispatch('footer/initializeFooter');
    store.dispatch('footer/getFooter');
};

const setAccountVariable = res => {
    return res.map(option => {
        return {
            label: option.name,
            value: option.value
        };
    });
};
const setupPersistentCache = () => {
    try {
        new UtdCache().pruneExpiredInvalidCache(store.getters['app/contentVersion']);
        const utdQc = new UtdQueuedCache();
        utdQc.restorePersistent('utdSPAAutoComplete');
        utdQc.restorePersistent('utdSPAContentCache');
        utdQc.restorePersistent('utdSPAOutlineCache');
        utdQc.restorePersistent('utdVisitedLinksCache');
    }
    catch (e) {
        // Don't let init bring down the site
    }
};

const setupGraphicStyles = () => {
    injectGraphicsStyles(store.getters['app/utdGxGenStyles'], () => {
        store.commit(`app/${SET_GRAPHICS_STYLES_LOADED}`, true);
    });
};

const setupOneTrustCookies = () => {
    const showOneTrustCookie = function() {
        // CORE:11340: Block cookie tool for EMR links (links with unid and srcsys params)
        if (store.getters['profile/userProfileVisitor'].isEMRAuthenticated) {
            return false;
        }
        return true;
    };

    if (showOneTrustCookie()) {
        generateCookieScripts();
    }
    store.commit(`app/${SET_ONE_TRUST_ENABLED}`, oneTrustAvailable());

    const initGoogleAnalytics = () => {
        if (oneTrustAvailable()) {
            store.dispatch('app/setupGA4');
        }
    };
    if (getWindow().utd_OneTrustInitialized) {
        // if onetrust is ready, then just start google analytics
        initGoogleAnalytics();
    }
    else {
        // If onetrust is not ready, then create this callback for when it is
        // (OT calls this function when ready)
        getWindow().OptanonWrapper = function() {
            initGoogleAnalytics();
        };
    }
};

const setupPendo = () => {
    // Setup Pendo rating guide
    store.dispatch('pendo/setupGuide', {
        guideName: 'RatingGuide',
        afterSetup: addPendoUnlockRatingControls
    });

    // Setup Pendo feedback guide
    store.dispatch('pendo/setupGuide', {
        guideName: 'FeedbackGuide'
    });

    store.dispatch('pendo/setupGuide', {
        guideName: 'ResourceCenterGuide'
    });

    store.dispatch('pendo/setupGuide', {
        guideName: 'TranslationFeedbackGuide'
    });
};