export const getTabItemClasses = function(item) {
    if (!item) {
        return;
    }
    const glyps = {
        'has-glyph': item.isHasNewWhatsNew,
        'has-bookmark': item.isBookmarked
    };
    return [ glyps, item.contentType, 'with-icons' ];
};
export default getTabItemClasses();
