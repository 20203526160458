<template>
  <div class="floating-labels-form login-register-section">
    <form v-if="loginSubscriberBox" class="form-login">
      <slot name="registerTitle" />
      <fieldset>
        <div class="form-row form-break">
          <span class="form-control">
            <a class="form-button"
               :href="storeUrl"
               target="_blank">{{ $t('HEADER.SUBSCRIBE') }}</a>
          </span>
        </div>
      </fieldset>
      <slot name="registerSectionText" />
    </form>

    <form v-if="loginRegisterButton"
          id="registerRedirect"
          class="form-login jumpstart-form"
          method="post"
          action="/login-register">
      <slot name="registerTitle" />
      <input type="hidden" name="isRegister" value="true">
      <input type="hidden" name="source" value="login-register-view">
      <div class="form-break form-row">
        <span class="form-control">
          <input type="submit"
                 name="submit"
                 class="form-button toggleLrb"
                 :value="$t('LOGIN.REGISTER_NOW')">
        </span>
      </div>

      <slot name="registerSectionText" />
      <a v-if="showSkipLink"
         class="login-register-section-link"
         :href="skipLinkUrl">{{ $t('LOGIN.CONTINUE_WITH_SEARCH') }}</a>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('feature', [
            'showSkipLink'
        ]),
        ...mapGetters('login', [
            'loginSubscriberBox',
            'loginRegisterButton',
            'skipLinkUrl'
        ]),
        ...mapGetters('auth', [ 'storeUrl' ])
    }
};
</script>

<style scoped lang="less">
@import '~_acaAssets/global/variables';
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.login-register-section {
  .ds1-mv-0();
  .ds1-pt-1();
  .ds1-pb-0();

  .login-register-title {
    .ds1-utd-size-3();
    .ds1-pb-0();
  }

  .login-register-section-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    .ds1-mt-1();
  }

  .form-break {
    .ds1-pt-2();
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop .login-view.utd-form {
    .floating-labels-form.login-register-section {
      .ds1-mt-4();
      .ds1-pv-4();

      .login-register-title {
        .ds1-utd-h3();
        line-height: 1;
      }
    }
  }
}
</style>
