const SUBHIT_TYPE = 'SECTION';

export default class BertResult {
    constructor({ id, type, title, snippet, url, searchResults }, searchRank) {
        this.id = id || '';
        this.type = type || '';
        this.title = title || '';
        this.answer = snippet || '';
        this.url = url || '';
        this.searchRank = searchRank;
        this.subHits = this.setSubhits(searchResults) || [];
    }

    setSubhits(searchResults) {
        if (!searchResults || !Array.isArray(searchResults)) {
            return [];
        }
        return searchResults
            .filter(result => result.type.toUpperCase() === SUBHIT_TYPE)
            .map(result => {
                return {
                    title: result.title,
                    id: result.id,
                    url: result.url,
                    searchRank: result.meta && result.meta.searchRank
                };
            });
    }
}