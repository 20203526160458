import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { getQueryParamValues } from '_acaSrc/utility/http';
import {
    isVueQap,
    isFeaturedSearchResult,
    isGraphicalAnswers,
    makeSearchCacheKey,
    isBertResult,
    isGraphicResult,
    prependResultTitle
} from '_acaSrc/utility/contents/search/search';
import { makeRestUrl } from '_acaSrc/utility/http/UtdUrlUtilities';
import {
    C_GRAPHICS,
    C_SEARCH,
    C_QAP,
    C_UI
} from '_acaSrc/utility/constants';
import { decodeHtml } from '_acaSrc/utility/DOM';
import Logger from '_acaSrc/utility/Logger';
import BertResult from '_acaSrc/utility/data-adapters/bert-results-adapter';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';
import { SET_STORED_SETTING } from '_acaSrc/store/app.store';
import { SET_IS_TOPIC_VIEW } from '_acaSrc/store/topic/topic.store';
import {
    RESET_QAP_PANEL,
    SET_IS_QUICK_ACCESS_PANEL,
    SET_QAP_PANEL
} from '_acaSrc/store/qap.store';
import {
    SET_AI_DEADLINE_RESOLVED,
    RESET_AI_SEARCH_STATE,
    SET_MANUALLY_HIDE_AI_COMPONENT
} from '_acaSrc/store/ai.store';
import { setDesktopUrlDisplayRank } from '_acaQap/qap.utils';
import { cloneDeep } from 'lodash';
import IndexManager from '_acaSrc/utility/IndexManager';
import i18next from 'i18next';

const { SIDEBAR_ACCORDION_OG, SIDEBAR_ACCORDION_AIT } = C_GRAPHICS;

export const makeOutlineFlyoutObject = () => {
    return {
        outlineHtml: '',
        showOutline: false,
        outlineLoading: '',
        outlineTopicId: '',
        outlineTopicTitle: '',
        graphicsCount: 0,
        showGraphicsLink: false,
        timeoutHideFlyout: null,
        timeoutOpenFlyout: null,
        topicLanguage: ''
    };
};

export const makeSearchParams = () => {
    return {
        search: '',
        sp: C_SEARCH.PRIORITY.ALL_TOPICS,
        searchType: C_SEARCH.SEARCH_TYPES.PLAIN_TEXT,
        source: C_SEARCH.SOURCE.USER_INPUT,
        searchControl: C_SEARCH.CONTROL.TOP_PULLDOWN,
        searchOffset: 1,
        autoComplete: 'false',
        language: '',
        include: null,
        max: 0,
        index: '',
        autoCompleteTerm: '',
        rawSentence: ''
    };
};

export const makeSearchDisplay = () => {
    return {
        searchFor: '',
        isHL7: false
    };
};

export const makeSearchResults = () => {
    return {
        results: '',
        error: '',
        show: false,
        isLoadingResults: false,
        translatedSearchTerm: '',
        translationDetectedLanguage: '',
        mainSearchResultsEmpty: true
    };
};

const state = {
    origSearchText: '',
    searchResults: {
        hasMoreResults: true,
        hasGraphicalAnswers: false,
        isGraphicsSearch: false,
        isNewResults: true,
        fewSearchResults: false,
        nullSearchResults: false,
        ...makeSearchResults()
    },
    searchLanguageCode: {
        language: ''
    },
    outlineFlyout: makeOutlineFlyoutObject(),
    searchParams: makeSearchParams(),
    desktopFeaturedSearchResults: [],
    desktopGraphicalAnswerResult: null,
    bertSearchResults: [],
    searchSettings: {
        max: 10
    },
    searchBar: {
        body: false,
        header: true,
        autoComplete: '',
        autoCompleteLimit: 10,
        disableAc: false
    },
    searchState: {
        state: 'EXPANDED',
        isCollapse: false,
        isShowExpandCollapse: true
    },
    error: {
        isHasError: false
    },
    searchDisplay: makeSearchDisplay(),
    isGraphicalAnswers: false,
    isSearchView: false,
    isHomeSearchEmpty: false,
    searchMessaging: null,
    searchResultAlternate: false,
    searchResultsState: 'EXPANDED',
    bqpEventData: {},
    kppEventData: {},
    lastSelectedPanelTabIdx: -1
};

export const getters = {
    originalSearchText: state => state.origSearchText,
    isNewResults: state => state.searchResults.isNewResults,
    fewSearchResults: state => state.searchResults.fewSearchResults,
    nullSearchResults: state => state.searchResults.nullSearchResults,
    searchHasMoreResults: state => state.searchResults.hasMoreResults,
    hasGraphicalAnswers: state => state.searchResults.hasGraphicalAnswers,
    searchLanguageCode: state => state.searchLanguageCode.language,
    outlineFlyoutShowOutline: state => state.outlineFlyout.showOutline,
    outlineFlyoutTopicId: state => state.outlineFlyout.outlineTopicId,
    outlineFlyoutTopicLanguage: state => state.outlineFlyout.topicLanguage,
    outlineFlyoutTopicTitle: state => state.outlineFlyout.outlineTopicTitle,
    outlineFlyoutLoading: state => state.outlineFlyout.outlineLoading,
    timeoutOpenOutlineFlyout: state => state.outlineFlyout.timeoutOpenFlyout,
    timeoutHideOutlineFlyout: state => state.outlineFlyout.timeoutHideFlyout,
    outlineFlyoutHtml: state => state.outlineFlyout.outlineHtml,
    outlineFlyoutShowGraphicsLink: state => state.outlineFlyout.showGraphicsLink,
    outlineFlyoutGraphicsCount: state => state.outlineFlyout.graphicsCount,
    desktopFeaturedSearchResults: state => {
        if (state.desktopFeaturedSearchResults) {
            state.desktopFeaturedSearchResults.forEach(featuredResult => {
                Object.assign(featuredResult, { rankIndex: 1 });
            });
        }
        return state.desktopFeaturedSearchResults;
    },
    desktopGraphicalAnswerResult: state => {
        // -1 indicates that it is "out of flow" so not comparable to other search results
        return state.desktopGraphicalAnswerResult
            && Object.assign(state.desktopGraphicalAnswerResult, { rankIndex: -1 });
    },
    bertSearchResults: state => state.bertSearchResults,
    searchSettings: state => state.searchSettings,
    isGraphicsSearch: state => {
        return state.searchResults.isGraphicsSearch
            && state.searchParams.sp === C_SEARCH.PRIORITY.GRAPHICS;
    },
    isAllSearch: state => state.searchParams.sp === C_SEARCH.PRIORITY.ALL_TOPICS,
    searchResults: state => state.searchResults,
    searchParamsSearchText: state => state.searchParams.search,
    searchParamsLanguage: state => state.searchParams.language,
    searchParamsSource: state => state.searchParams.source,
    searchParams: state => state.searchParams,
    searchParamsType: state => state.searchParams.searchType,
    searchParamsPriority: state => state.searchParams.sp,
    searchParamsPriorityInteger: state => parseInt(state.searchParams.sp),
    searchParamsAutoComplete: state => state.searchParams.autoComplete,
    searchParamsControl: state => state.searchParams.searchControl,
    searchParamsOffset: state => state.searchParams.searchOffset,
    searchBarBody: state => state.searchBar.body,
    searchBarHeader: state => state.searchBar.header,
    searchBarAutoComplete: state => state.searchBar.autoComplete,
    searchBarAutoCompleteLimit: state => state.searchBar.autoCompleteLimit,
    searchBarDisableAc: state => state.searchBar.disableAc,
    isGraphicalAnswers: state => state.isGraphicalAnswers,
    isSearchView: state => state.isSearchView,
    isShowExpandCollapse: state => state.searchState.isShowExpandCollapse,
    isCollapse: state => state.searchState.isCollapse,
    searchStateState: state => state.searchState.state,
    searchFor: state => state.searchDisplay.searchFor,
    isHL7: state => state.searchDisplay.isHL7,
    isHomeSearchEmpty: state => state.isHomeSearchEmpty,
    searchResultsResults: state => state.searchResults.results,
    searchResultsError: state => state.searchResults.error,
    searchResultsShow: state => state.searchResults.show,
    isLoadingResults: state => state.searchResults.isLoadingResults,
    translatedSearchTerm: state => state.searchResults.translatedSearchTerm,
    translationDetectedLanguage: state => state.searchResults.translationDetectedLanguage,
    mainSearchResultsEmpty: state => state.searchResults.mainSearchResultsEmpty,
    searchMessaging: state => state.searchMessaging,
    searchResultAlternate: state => state.searchResultAlternate,
    searchResultsState: state => state.searchResultsState,
    bqpEventData: state => state.bqpEventData,
    kppEventData: state => state.kppEventData,
    lastSelectedPanelTabIdx: state => state.lastSelectedPanelTabIdx
};

export const SET_BQP_EVENTS_DATA = 'SET_BQP_EVENTS_DATA';
export const SET_KPP_EVENTS_DATA = 'SET_KPP_EVENTS_DATA';
export const SET_ORIGINAL_SEARCH_TEXT = 'SET_ORIGINAL_SEARCH_TEXT';
export const SET_IS_NEW_RESULTS = 'SET_IS_NEW_RESULTS';
export const SET_SEARCH_HAS_MORE_RESULTS = 'SET_SEARCH_HAS_MORE_RESULTS';
export const SET_SEARCH_HAS_FEW_RESULTS = 'SET_SEARCH_HAS_FEW_RESULTS';
export const SET_SEARCH_HAS_NULL_RESULTS = 'SET_SEARCH_HAS_NULL_RESULTS';
export const SET_SEARCH_LANGUAGE_CODE = 'SET_SEARCH_LANGUAGE_CODE';
export const SET_OUTLINE_FLYOUT_OUTLINE_LOADING = 'SET_OUTLINE_FLYOUT_OUTLINE_LOADING';
export const SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_ID = 'SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_ID';
export const SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_TITLE = 'SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_TITLE';
export const SET_OUTLINE_FLYOUT_OUTLINE_HTML = 'SET_OUTLINE_FLYOUT_OUTLINE_HTML';
export const SET_OUTLINE_FLYOUT_GRAPHICS_COUNT = 'SET_OUTLINE_FLYOUT_GRAPHICS_COUNT';
export const SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK = 'SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK';
export const SET_OUTLINE_FLYOUT_TOPIC_LANGUAGE = 'SET_OUTLINE_FLYOUT_TOPIC_LANGUAGE';
export const SET_OUTLINE_FLYOUT_SHOW_OUTLINE = 'SET_OUTLINE_FLYOUT_SHOW_OUTLINE';
export const RESET_OUTLINE_FLYOUT = 'RESET_OUTLINE_FLYOUT';
export const SET_OUTLINE_FLYOUT_TIMEOUT_OPEN = 'SET_OUTLINE_FLYOUT_TIMEOUT_OPEN';
export const SET_OUTLINE_FLYOUT_TIMEOUT_HIDE = 'SET_OUTLINE_FLYOUT_TIMEOUT_HIDE';
export const SET_DESKTOP_FEATURED_SEARCH_RESULT = 'SET_DESKTOP_FEATURED_SEARCH_RESULT';
export const SET_DESKTOP_FEATURED_SEARCH_RESULTS = 'SET_DESKTOP_FEATURED_SEARCH_RESULTS';
export const SET_DESKTOP_GRAPHICAL_ANSWER_RESULT = 'SET_DESKTOP_GRAPHICAL_ANSWER_RESULT';
export const SET_SEARCH_HAS_GRAPHICAL_ANSWERS = 'SET_SEARCH_HAS_GRAPHICAL_ANSWERS';
export const SET_SEARCH_SETTINGS_MAX = 'SET_SEARCH_SETTINGS_MAX';
export const SET_SEARCH_PARAMS_SOURCE = 'SET_SEARCH_PARAMS_SOURCE';
export const SET_SEARCH_PARAMS_OFFSET = 'SET_SEARCH_PARAMS_OFFSET';
export const SET_SEARCH_PARAMS_TEXT = 'SET_SEARCH_PARAMS_TEXT';
export const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS';
export const REMOVE_FROM_HL7_SEARCH = 'REMOVE_FROM_HL7_SEARCH';
export const SET_SEARCH_PARAMS_TYPE = 'SET_SEARCH_PARAMS_TYPE';
export const SET_SEARCH_PARAMS_SP = 'SET_SEARCH_PARAMS_SP';
export const SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM = 'SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM';
export const SET_SEARCH_PARAMS_AUTO_COMPLETE = 'SET_SEARCH_PARAMS_AUTO_COMPLETE';
export const SET_SEARCH_PARAMS_INDEX = 'SET_SEARCH_PARAMS_INDEX';
export const SET_SEARCH_PARAMS_CONTROL = 'SET_SEARCH_PARAMS_CONTROL';
export const SET_SEARCH_PARAMS_INCLUDE = 'SET_SEARCH_PARAMS_INCLUDE';
export const SET_SEARCH_PARAMS_RAW_SENTENCE = 'SET_SEARCH_PARAMS_RAW_SENTENCE';
export const SET_BERT_SEARCH_RESULTS = 'SET_BERT_SEARCH_RESULTS';
export const SET_SEARCHBAR_BODY = 'SET_SEARCHBAR_BODY';
export const SET_SEARCHBAR_HEADER = 'SET_SEARCHBAR_HEADER';
export const SET_SEARCHBAR_AUTOCOMPLETE = 'SET_SEARCHBAR_AUTOCOMPLETE';
export const SET_SEARCHBAR_DISABLE_AC = 'SET_SEARCHBAR_DISABLE_AC';
export const SET_IS_GRAPHICAL_ANSWERS = 'SET_IS_GRAPHICAL_ANSWERS';
export const SET_IS_SEARCH_VIEW = 'SET_IS_SEARCH_VIEW';
export const SET_IS_HOME_SEARCH_EMPTY = 'SET_IS_HOME_SEARCH_EMPTY';
export const SET_SEARCH_RESULT_ALTERNATE = 'SET_SEARCH_RESULT_ALTERNATE';
export const SET_IS_GRAPHIC = 'SET_IS_GRAPHIC';
export const SET_SEARCH_STATE = 'SET_SEARCH_STATE';
export const SET_SHOW_EXPAND_COLLAPSE = 'SET_SHOW_EXPAND_COLLAPSE';
export const SET_SEARCH_STATE_IS_COLLAPSE = 'SET_SEARCH_STATE_IS_COLLAPSE';
export const SET_SEARCH_STATE_STATE = 'SET_SEARCH_STATE_STATE';
export const SET_SEARCH_FOR = 'SET_SEARCH_FOR';
export const SET_IS_HL7 = 'SET_IS_HL7';
export const RESET_SEARCH_DISPLAY = 'RESET_SEARCH_DISPLAY';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS_ERROR = 'SET_SEARCH_RESULTS_ERROR';
export const SET_SEARCH_RESULTS_SHOW = 'SET_SEARCH_RESULTS_SHOW';
export const SET_IS_LOADING_RESULTS = 'SET_IS_LOADING_RESULTS';
export const SET_TRANSLATED_SEARCH_TERM = 'SET_TRANSLATED_SEARCH_TERM';
export const SET_TRANSLATION_DETECTED_LANGUAGE = 'SET_TRANSLATION_DETECTED_LANGUAGE';
export const SET_MAIN_SEARCH_RESULTS_EMPTY = 'SET_MAIN_SEARCH_RESULTS_EMPTY';
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS_STATE = 'SET_SEARCH_RESULTS_STATE';
export const SET_RESULT_HOVERED = 'SET_RESULT_HOVERED';
export const CLEAR_RESULT_HOVERS = 'CLEAR_RESULT_HOVERS';
const SET_SEARCH_MESSAGING = 'SET_SEARCH_MESSAGING';
const SET_SEARCH_ERROR_STATE = 'SET_SEARCH_ERROR_STATE';
export const SET_LAST_SELECTED_PANEL_TAB_IDX = 'SET_LAST_SELECTED_PANEL_TAB_IDX';

export const mutations = {
    [SET_BQP_EVENTS_DATA]: (state, data) => state.bqpEventData = data,
    [SET_KPP_EVENTS_DATA]: (state, data) => state.kppEventData = data,
    [SET_ORIGINAL_SEARCH_TEXT]: (state, text) => state.origSearchText = text,
    [SET_IS_NEW_RESULTS]: (state, isNew) => state.searchResults.isNewResults = isNew,
    [SET_SEARCH_HAS_MORE_RESULTS]: (state, f) => state.searchResults.hasMoreResults = f,
    [SET_SEARCH_HAS_FEW_RESULTS]: (state, f) => state.searchResults.fewSearchResults = f,
    [SET_SEARCH_HAS_NULL_RESULTS]: (state, f) => state.searchResults.nullSearchResults = f,
    [SET_SEARCH_LANGUAGE_CODE](state, language) {
        state.searchLanguageCode.language = language;
    },
    [SET_OUTLINE_FLYOUT_OUTLINE_LOADING](state, value) {
        state.outlineFlyout.outlineLoading = value;
    },
    [SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_ID](state, outlineTopicId) {
        state.outlineFlyout.outlineTopicId = outlineTopicId;
    },
    [SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_TITLE](state, outlineTopicTitle) {
        state.outlineFlyout.outlineTopicTitle = outlineTopicTitle;
    },
    [SET_OUTLINE_FLYOUT_OUTLINE_HTML](state, outlineHtml) {
        state.outlineFlyout.outlineHtml = outlineHtml;
    },
    [SET_OUTLINE_FLYOUT_GRAPHICS_COUNT](state, graphicsCount) {
        state.outlineFlyout.graphicsCount = graphicsCount;
    },
    [SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK](state, showGraphicsLink) {
        state.outlineFlyout.showGraphicsLink = showGraphicsLink;
    },
    [SET_OUTLINE_FLYOUT_TOPIC_LANGUAGE](state, topicLanguage) {
        state.outlineFlyout.topicLanguage = topicLanguage;
    },
    [SET_OUTLINE_FLYOUT_SHOW_OUTLINE](state, value) {
        state.outlineFlyout.showOutline = value;
    },
    [RESET_OUTLINE_FLYOUT]() {
        state.outlineFlyout.outlineHtml = '';
        state.outlineFlyout.showOutline = false;
        state.outlineFlyout.outlineLoading = '';
        state.outlineFlyout.outlineTopicId = '';
        state.outlineFlyout.outlineTopicTitle = '';
        state.outlineFlyout.graphicsCount = 0;
        state.outlineFlyout.showGraphicsLink = false;
        state.outlineFlyout.timeoutHideFlyout = null;
        state.outlineFlyout.timeoutOpenFlyout = null;
        state.outlineFlyout.topicLanguage = '';
    },
    [SET_OUTLINE_FLYOUT_TIMEOUT_OPEN](state, cb) {
        state.outlineFlyout.timeoutOpenFlyout = cb;
    },
    [SET_OUTLINE_FLYOUT_TIMEOUT_HIDE](state, cb) {
        state.outlineFlyout.timeoutHideFlyout = cb;
    },
    [SET_DESKTOP_FEATURED_SEARCH_RESULT](state, value) {
        state.desktopFeaturedSearchResults.push(value);
    },
    [SET_DESKTOP_FEATURED_SEARCH_RESULTS](state, value) {
        state.desktopFeaturedSearchResults = value;
    },
    [SET_DESKTOP_GRAPHICAL_ANSWER_RESULT](state, value) {
        state.desktopGraphicalAnswerResult = value;
    },
    [SET_SEARCH_HAS_GRAPHICAL_ANSWERS](state, value) {
        state.searchResults.hasGraphicalAnswers = value;
    },
    [SET_SEARCH_SETTINGS_MAX](state, value) {
        state.searchSettings.max = value;
    },
    [SET_SEARCH_PARAMS_SOURCE](state, source) {
        state.searchParams.source = source;
    },
    [SET_SEARCH_PARAMS_OFFSET](state, value) {
        state.searchParams.searchOffset = value;
    },
    [SET_SEARCH_PARAMS_TEXT](state, search = '') {
        state.searchParams.search = search;
    },
    [RESET_SEARCH_PARAMS](state) {
        state.searchParams.search = '';
        state.searchParams.sp = C_SEARCH.PRIORITY.ALL_TOPICS;
        state.searchParams.searchType = C_SEARCH.SEARCH_TYPES.PLAIN_TEXT;
        state.searchParams.source = C_SEARCH.SOURCE.USER_INPUT;
        state.searchParams.searchControl = C_SEARCH.CONTROL.TOP_PULLDOWN;
        state.searchParams.searchOffset = 1;
        state.searchParams.autoComplete = 'false';
        state.searchParams.language = '';
        state.searchParams.include = null;
        state.searchParams.max = 0;
        state.searchParams.index = '';
        state.searchParams.autoCompleteTerm = '';
        state.searchParams.rawSentence = '';
    },
    [REMOVE_FROM_HL7_SEARCH](state, item) {
        delete state.searchParams[item];
    },
    [SET_SEARCH_PARAMS_TYPE](state, type) {
        state.searchParams.searchType = type;
    },
    [SET_SEARCH_PARAMS_SP](state, sp) {
        state.searchParams.sp = sp;
    },
    [SET_SEARCH_PARAMS_AUTO_COMPLETE](state, value) {
        state.searchParams.autoComplete = value;
    },
    [SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM](state, term) {
        state.searchParams.autoCompleteTerm = term;
    },
    [SET_SEARCH_PARAMS_INDEX](state, i) {
        state.searchParams.index = i;
    },
    [SET_SEARCH_PARAMS_CONTROL](state, value) {
        state.searchParams.searchControl = value;
    },
    [SET_SEARCH_PARAMS_INCLUDE](state, value) {
        state.searchParams.include = value;
    },
    [SET_SEARCH_PARAMS_RAW_SENTENCE](state, value) {
        state.searchParams.rawSentence = value;
    },
    [SET_BERT_SEARCH_RESULTS](state, bertResult) {
        if (!bertResult) {
            state.bertSearchResults = [];
            return;
        }
        const searchRank = bertResult.meta && bertResult.meta.searchRank;
        const { id, type, title, snippet, url, searchResults } = bertResult;

        state.bertSearchResults.push(
            new BertResult({ id, type, title, snippet, url, searchResults }, searchRank)
        );
    },
    [SET_SEARCHBAR_BODY]: (state, value) => state.searchBar.body = value,
    [SET_SEARCHBAR_HEADER]: (state, value) => state.searchBar.header = value,
    [SET_SEARCHBAR_AUTOCOMPLETE]: (state, ac) => {
        state.searchBar.autoComplete = ac;
        new IndexManager().bodyCss.setOrClear(
            'searchBarAutoComplete', state.searchBar.autoComplete);
    },
    [SET_SEARCHBAR_DISABLE_AC]: (state, value) => state.searchBar.disableAc = value,
    [SET_IS_GRAPHICAL_ANSWERS]: (state, value) => state.isGraphicalAnswers = value,
    [SET_IS_SEARCH_VIEW]: (state, isSearchView) => state.isSearchView = isSearchView,
    [SET_IS_HOME_SEARCH_EMPTY]: (state, value) => {
        state.isHomeSearchEmpty = value;
        new IndexManager().bodyCss.setOrClear('isHomeSearchEmpty', state.isHomeSearchEmpty);
    },
    [SET_SEARCH_RESULT_ALTERNATE]: (state, value) => state.searchResultAlternate = value,
    [SET_IS_GRAPHIC]: (state, value) => state.searchResults.isGraphicsSearch = value,
    [SET_SHOW_EXPAND_COLLAPSE]: (state, value) => state.searchState.isShowExpandCollapse = value,
    [SET_SEARCH_STATE_IS_COLLAPSE]: (state, value) => state.searchState.isCollapse = value,
    [SET_SEARCH_STATE_STATE]: (state, value) => state.searchState.state = value,
    [SET_SEARCH_STATE](state, searchState) {
        if (typeof searchState !== 'undefined') {
            state.searchState.isCollapse = searchState !== 'EXPANDED';
            state.searchState.state = searchState;
            this.commit(`app/${SET_STORED_SETTING}`, {
                key: 'searchState',
                value: searchState
            }, { root: true });
        }
    },
    [SET_SEARCH_FOR]: (state, value) => state.searchDisplay.searchFor = value,
    [SET_IS_HL7]: (state, value) => state.searchDisplay.isHL7 = value,
    [RESET_SEARCH_DISPLAY](state) {
        state.searchDisplay.searchFor = '';
        state.searchDisplay.isHL7 = false;
    },
    [SET_SEARCH_RESULTS]: (state, value) => state.searchResults.results = value,
    [SET_SEARCH_RESULTS_ERROR]: (state, value) => state.searchResults.error = value,
    [SET_SEARCH_RESULTS_SHOW]: (state, value) => state.searchResults.show = value,
    [SET_IS_LOADING_RESULTS]: (state, value) => state.searchResults.isLoadingResults = value,
    [SET_TRANSLATED_SEARCH_TERM]: (state, value) =>
        state.searchResults.translatedSearchTerm = value,
    [SET_TRANSLATION_DETECTED_LANGUAGE]: (state, value) =>
        state.searchResults.translationDetectedLanguage = value,
    [SET_MAIN_SEARCH_RESULTS_EMPTY]: (state, value) =>
        state.searchResults.mainSearchResultsEmpty = value,
    [RESET_SEARCH_RESULTS](state) {
        state.searchResults.results = '';
        state.searchResults.error = '';
        state.searchResults.show = false;
        state.searchResults.isLoadingResults = false;
        state.searchResults.translatedSearchTerm = '';
        state.searchResults.translationDetectedLanguage = '';
        state.searchResults.mainSearchResultsEmpty = true;
    },
    [SET_SEARCH_RESULTS_STATE](state, data) {
        if ('searchResultsState' in data) {
            state.searchResultsState = data.searchResultsState;
        }
    },
    [SET_RESULT_HOVERED]: (state, index) => {
        if (state.searchResults
            && state.searchResults.results
            && state.searchResults.results.length
            && state.searchResults.results[index]) {
            state.searchResults.results[index].isHovered = true;
        }
    },
    [CLEAR_RESULT_HOVERS]: state => {
        state.searchResults && state.searchResults.results.forEach(result => {
            delete result.isHovered;
        });
    },
    [SET_SEARCH_MESSAGING]: (state, messaging) => state.searchMessaging = messaging,
    [SET_SEARCH_ERROR_STATE]: (state, value) => state.error.isHasError = value,
    [SET_LAST_SELECTED_PANEL_TAB_IDX]: (state, value) =>
        state.lastSelectedPanelTabIdx = value
};

export const actions = {
    checkShowMoreButtonShowing({ getters, commit }, total) {
        const resultsQty = (getters.searchResultsResults
            && getters.searchResultsResults.length) || 0;
        if (resultsQty >= total) {
            commit(SET_SEARCH_HAS_MORE_RESULTS, false);
            // Turning fewSearchResults boolean value true when few search results.
            commit(SET_SEARCH_HAS_FEW_RESULTS, true);
            Logger.debug('There are no more search results');
        }
    },
    // eslint-disable-next-line no-unused-vars
    getOutline({ rootGetters }, { language, params }) {
        // Try to retrieve outline from the cache
        const uri = makeRestUrl(`outline/topic/${params.id}`, language, '/json');

        let promise = new UtdQueuedCache()
            .getDeferred('utdSPAOutlineCache', uri);

        // If we didn't get anything make the REST call
        if (!promise) {
            promise = utdRest('search/getTopicOutline', { params, uri });
        }
        else if (new UtdCache().doCacheHitEvent) {
            try {
                utdRest('search/cacheHitEvent', { params });
            }
            catch (e) {
                Logger
                    .warn(`Could not record outline event for url:${uri},exception:${e}`);
            }
        }
        return promise;
    },
    getTopicOutline({ dispatch, commit, getters }, payload) {
        const { topicId, language } = payload;
        commit(SET_SEARCH_LANGUAGE_CODE, language);
        const params = {
            search: getters.searchParamsSearchText,
            id: topicId
        };

        return dispatch('getOutline', { language, params })
            .then(data => dispatch('resolveOutlineResults', data))
            .catch(error => dispatch('resolveOutlineError', error));
    },
    resolveOutlineResults({ commit, rootGetters, getters }, data) {
        // CACHE>>>: Store outline into session cache
        const cacheKey = makeRestUrl(`outline/topic/${data.topicId}`,
            getters.searchLanguageCode, '/json');
        new UtdQueuedCache()
            .setPersistent('utdSPAOutlineCache',
                cacheKey, data, rootGetters['app/contentVersion']);
        // <<<CACHE
        commit(SET_OUTLINE_FLYOUT_OUTLINE_LOADING, '');
        commit(SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_ID, data.topicId);
        commit(SET_OUTLINE_FLYOUT_OUTLINE_TOPIC_TITLE, data.topicTitle);
        commit(SET_OUTLINE_FLYOUT_OUTLINE_HTML, data.outlineHtml);
        commit(SET_OUTLINE_FLYOUT_GRAPHICS_COUNT, data.numberGraphics);
        commit(SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK, (data.numberGraphics > 0));
        commit(SET_OUTLINE_FLYOUT_TOPIC_LANGUAGE, data.language);
    },
    resolveOutlineError({ commit }, error) {
        commit(RESET_OUTLINE_FLYOUT);
        if (error.status !== '-1' && error.status !== -1 && error.status !== 403) {
            Logger.error(`Error retrieving flyout - error:
            ${error.errors} message:${error.message} status:${error.status}`);
        }
    },
    logTopicViewDrugPanelEvent({ dispatch, getters }, payload) {
        const { drugAccId, drugAccName, drugUrl, rank } = payload;

        const eventParams = {
            id: drugAccId,
            source: 'panel_search_result',
            language: getters.searchParamsLanguage || 'en-US',
            search: getters.searchParamsSearchText,
            accordionName: drugAccName,
            rank: getQueryParamValues(drugUrl, 'selectedTitle'),
            display_rank: rank
        };

        dispatch('app/logEvent', {
            eventUrl: 'event/topicViewDrugPanel/json',
            eventParams
        }, { root: true });
    },
    logKppSectionToggleEvent({ dispatch, getters }, payload) {
        const { topicId, sectionHeader, rankIndex, searchRank, state } = payload;

        const validState = state && state === 'CLOSE' ? 'CLOSE' : 'OPEN';

        const eventParams = {
            topicId,
            search: getters.searchParamsSearchText,
            accordionName: sectionHeader,
            rank: searchRank,
            display_rank: rankIndex,
            state: validState
        };

        dispatch('app/logEvent', {
            eventUrl: 'event/keyPointsPanelSection/json',
            eventParams
        }, { root: true });
    },
    logBqpSectionToggleEvent({ dispatch, getters }, payload) {
        const {
            topicId,
            clickType,
            tabName,
            accordionHeader,
            accordionState,
            rankIndex,
            searchRank
        } = payload;

        const eventParams = {
            topicId,
            search: getters.searchParamsSearchText,
            rank: searchRank,
            display_rank: rankIndex,
            source: clickType,
            section: tabName,
            term: accordionHeader,
            state: accordionState
        };

        dispatch('app/logEvent', {
            eventUrl: 'event/broadQueryPanel/json',
            eventParams
        }, { root: true });
    },
    getSearch({ rootGetters, dispatch, getters }, payload) {
        const { params, searchSettings = getters.searchSettings } = payload;

        // Add parameters that don't need to be in the URL, but need to be in the REST service
        params.language = rootGetters['user/language'];
        params.panelTranslations = true;
        params.max = searchSettings.max;
        if (rootGetters['feature/isCalibrationAppUser']) {
            params.bertResults = rootGetters['feature/isCalibrationAppUser'];
        }

        return dispatch('getSearchFromCacheOrServer', { params });
    },
    // eslint-disable-next-line no-unused-vars
    getSearchFromCacheOrServer({ rootGetters }, payload) {
        const { params } = payload;

        let eventUrl = 'event/search/lucene/json';
        let url = 'contents/search/2/json';
        if (params.source === 'HL7') {
            eventUrl = 'event/search/hl7/json';
            url = 'contents/search/hl7/json';
        }

        // Try to retrieve search results from the cache
        const cacheKey = makeSearchCacheKey(url, params);
        let promise = new UtdQueuedCache()
            .getDeferred('utdSPAContentCache', cacheKey);

        // If we didn't get anything make the REST call
        if (!promise) {
            promise = utdRest('search/getSearch', { uri: url, params });
        }
        else if (new UtdCache().doCacheHitEvent) {
            try {
                utdRest('search/cacheHitEvent', { uri: eventUrl, params });
            }
            catch (e) {
                Logger.warn(`Could not record ${
                    params.source === 'HL7'
                        ? 'HL7 '
                        : ''}search event for url:${url}params: ${params},exception:${e}`);
            }
        }

        return promise;
    },
    setMaxResults({ rootGetters, commit, getters }) {
        if (rootGetters['app/isProspectView']) {
            commit(SET_SEARCH_SETTINGS_MAX, 10);
        }
        else if ((getters.isCollapse
            || getters.isGraphicsSearch)
            && getters.searchStateState !== 'NONE') {
            commit(SET_SEARCH_SETTINGS_MAX, 40);
        }
        else {
            commit(SET_SEARCH_SETTINGS_MAX, 10);
        }
    },
    onLoadMoreResults({ commit, dispatch, getters }) {
        commit('graphic/RESET', null, { root: true });
        if (getters.isGraphicsSearch) {
            dispatch('setupGraphicsViewerForGraphicsSearch');
        }
    },
    setupGraphicsViewerForGraphicsSearch({ commit, getters }) {
        const results = getters.searchResultsResults;
        // sometimes results is "" when there are no results.
        const graphics = (Array.isArray(results) ? results : [])
            .filter(r => r.type === 'graphic').map(r => {
                return {
                    imageKey: getQueryParamValues(r.url, 'imageKey'),
                    title: r.title
                };
            });
        commit('graphic/SET_GRAPHIC_VIEWER_GRAPHICS', graphics, { root: true });
        commit('graphic/SET_GRAPHIC_VIEWER_ACCORDION_ITEMS',
            [ SIDEBAR_ACCORDION_AIT, SIDEBAR_ACCORDION_OG ],
            { root: true });
        commit('graphic/SET_MULTIGRAPHICS_MODE', null, { root: true });
    },
    toggleResults({ dispatch, getters, commit }) {
        const newIsCollapse = !getters.isCollapse;
        commit(SET_SEARCH_STATE_IS_COLLAPSE, newIsCollapse);
        commit(SET_SEARCH_STATE_STATE, newIsCollapse ? 'COLLAPSED' : 'EXPANDED');

        dispatch('setMaxResults');

        if (newIsCollapse) {
            // If we haven't loaded at least 50 topics yet, then grab more for collapsed view
            if (getters.searchResultsResults
                && getters.searchResultsResults.length < 50) {
                commit(SET_IS_NEW_RESULTS, false);
                commit(SET_SEARCH_PARAMS_SOURCE, C_SEARCH.SOURCE.COLLAPSE_VIEW);
                dispatch('getSearchResults');
            }
        }

        utdRest('user/setting', {
            settingCode: 'search-results-state',
            settingValue: getters.searchStateState
        });

        commit('app/SET_STORED_SETTING', {
            key: 'searchState',
            value: getters.searchStateState
        }, { root: true });

        dispatch('app/logEvent', {
            eventUrl: 'event/toggleSearchResultsView/json',
            eventParams: {
                searchTerm: getters.searchParamsSearchText,
                state: getters.searchStateState
            }
        }, { root: true });
    },
    resolveMoreResults({ dispatch, commit, getters }, payload) {
        const { searchResults } = payload;
        if (!searchResults || !searchResults.length) {
            return;
        }

        commit(SET_SEARCH_RESULTS, getters.searchResultsResults.concat(searchResults));
        dispatch('onLoadMoreResults');
    },
    reset({ commit }) {
        commit(SET_IS_NEW_RESULTS, true);
        commit(RESET_SEARCH_RESULTS);
        commit(RESET_SEARCH_DISPLAY);
        commit(RESET_OUTLINE_FLYOUT);
        commit(SET_IS_HOME_SEARCH_EMPTY, false);
        commit(SET_DESKTOP_FEATURED_SEARCH_RESULTS, []);
        commit(SET_DESKTOP_GRAPHICAL_ANSWER_RESULT, null);
        commit(SET_SEARCH_HAS_MORE_RESULTS, true);
        commit(SET_SEARCH_HAS_GRAPHICAL_ANSWERS, false);
        commit(SET_BERT_SEARCH_RESULTS, null);
        commit(SET_SEARCH_ERROR_STATE, false);
        commit(SET_LAST_SELECTED_PANEL_TAB_IDX, -1);
    },
    resetFromHL7Search({ getters, commit }) {
        // Delete any property not found in origSearchParams
        const purgeProps = [];
        const origSearchParams = makeSearchParams();
        for (const prop in getters.searchParams) {
            let found = false;
            for (const origProp in origSearchParams) {
                if (prop === origProp) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                purgeProps.push(prop);
            }
        }
        let isFromHl7;
        for (let remove = 0; remove < purgeProps.length; remove++) {
            commit(REMOVE_FROM_HL7_SEARCH, purgeProps[remove]);
            isFromHl7 = true;
        }
        if (isFromHl7) {
            // Reset searchType and sp values
            commit(SET_SEARCH_PARAMS_TYPE, C_SEARCH.SEARCH_TYPES.PLAIN_TEXT);
            commit(SET_SEARCH_PARAMS_SP, C_SEARCH.PRIORITY.ALL_TOPICS);
        }
    },
    // see ai.store.js --> ai/${SET_MANUALLY_HIDE_AI_COMPONENT}
    checkAndSetAiLmsFlag({ commit }, results) {
        if (results && typeof results.isNlpSearchEnabled === 'boolean') {
            commit(
                `ai/${SET_MANUALLY_HIDE_AI_COMPONENT}`,
                results.isNlpSearchEnabled === false,
                { root: true }
            );
        }
    },
    getSearchResults({ commit, dispatch, getters, rootGetters }, payload) {
        let shouldShowAiPrevResults = false;

        commit(`ai/${SET_MANUALLY_HIDE_AI_COMPONENT}`, false, { root: true });

        dispatch('ai/checkIfHasSwitchedTabs', null, { root: true });
        if (rootGetters['ai/shouldShowPreviousAiResults']) {
            shouldShowAiPrevResults = true;
            dispatch('ai/populatePreviousAiQueryData', null, { root: true });
        }
        else {
            commit(`ai/${RESET_AI_SEARCH_STATE}`, null, { root: true });
        }

        if (getters.isHL7) {
            commit(SET_SEARCH_PARAMS_SOURCE, C_SEARCH.SOURCE.HL7);
        }
        commit(SET_SEARCH_PARAMS_OFFSET,
            getters.searchResultsResults.length + 1);

        dispatch('setMaxResults');

        // Just before sending request, decode the search term so we send correct string,
        // and it displays correctly in the input field
        const searchParamsSearchText
            = decodeHtml(decodeURIComponent(getters.searchParamsSearchText));
        commit(SET_SEARCH_PARAMS_TEXT, searchParamsSearchText);
        commit(SET_ORIGINAL_SEARCH_TEXT, getters.searchParamsSearchText);
        commit(`ai/${SET_AI_DEADLINE_RESOLVED}`, false, { root: true });

        dispatch('inlineCme/handleSearch', getters.searchParamsSearchText, { root: true });
        dispatch('ai/checkIfAiRequestRequired', null, { root: true });

        // Remove rootGetters['ai/shouldMakeRequestIfInFlight'] to do request on all tabs
        if (!shouldShowAiPrevResults &&
            rootGetters['ai/shouldMakeAiRequest'] &&
            rootGetters['ai/shouldMakeRequestIfInFlight']) {
            dispatch('ai/getAiSearchResults', null, { root: true });
        }

        // The "payload" merged below may contain the bypassResetQapPanel value
        return dispatch('getSearch', {
            params: getters.searchParams
        })
            .then(async results => {
                const resolveResults = () => {
                    dispatch('resolveSearchResults', {
                        ...results, ...payload
                    });
                };
                await dispatch('checkAndSetAiLmsFlag', results);
                const shouldDoDeadline
                    = await dispatch('ai/shouldDoDeadline', null, { root: true });
                if (shouldDoDeadline) {
                    dispatch('ai/deadlineLogic', resolveResults, { root: true });
                }
                else {             
                    resolveResults();
                }
            })
            .catch(e => {
                dispatch('resolveError', e);
            });
    },
    processFeaturedSearchResults({ getters, commit, dispatch }, data) {
        // Clear desktop panels to be rebuilt each new results, and reset collapsed state
        if (getters.isNewResults) {
            commit(`qap/${RESET_QAP_PANEL}`, null, { root: true });
        }

        // Parse full list of search results to populate desktop featured
        // results (QAP, KPP, Bert, etc...) entries
        // eslint-disable-next-line complexity
        data.searchResults.forEach((result, resultIdx) => {
            dispatch('qap/processQapDtos', {
                result,
                searchTerm: getters.searchParamsSearchText,
                rankIndex: resultIdx + 1
            }, { root: true });

            if (isVueQap(result)) {
                commit(`qap/${SET_IS_QUICK_ACCESS_PANEL}`, true, { root: true });
                if (result.panelType === C_QAP.PANEL.PCU
                    || result.panelType === C_QAP.PANEL.DRUG_FORMULATION) {
                    const panel = cloneDeep(result);
                    // 'display_rank' for desktop is the only URL param that
                    // cannot be set at the server, so we set it here.
                    setDesktopUrlDisplayRank(panel);
                    commit(`qap/${SET_QAP_PANEL}`, panel, { root: true });
                }
            }
            else if (isFeaturedSearchResult(result)) {
                const featuredSearchResult = cloneDeep(result);
                commit(SET_DESKTOP_FEATURED_SEARCH_RESULT, featuredSearchResult);
            }
            else if (isGraphicalAnswers(result)) {
                const graphicalAnswerResult = cloneDeep(result);
                commit(SET_IS_GRAPHICAL_ANSWERS, true);
                commit(`qap/${SET_IS_QUICK_ACCESS_PANEL}`, true, { root: true });
                commit(SET_SEARCH_HAS_GRAPHICAL_ANSWERS, true);
                commit(SET_DESKTOP_GRAPHICAL_ANSWER_RESULT, graphicalAnswerResult);
            }
            else if (isBertResult(result)) {
                const bertResult = cloneDeep(result);
                commit(SET_BERT_SEARCH_RESULTS, bertResult);
            }
            else if (isGraphicResult(result)) {
                commit(SET_IS_GRAPHIC, true);
            }
        });
    },
    // eslint-disable-next-line complexity
    resolveSearchResults({ getters, commit, dispatch, rootGetters }, data) {
        try {
            if (getters.searchResultsResults
                && getters.searchResultsResults.length < 1) {
                commit(SET_SHOW_EXPAND_COLLAPSE, false);
            }

            dispatch('processFeaturedSearchResults', data);

            for (let i = 0; i < data.searchResults.length; i++) {
                const result = data.searchResults[i];
                if (result.url !== undefined
                    && result.url.indexOf('search=') === -1
                    && getters.searchParamsSearchText !== ''
                    && getters.searchParamsType === C_SEARCH.SEARCH_TYPES.GRAPHICS) {
                    const prefix = (result.url.indexOf('?') > -1 ? '&' : '?');
                    result.url += `${prefix}search=${getters.searchParamsSearchText}`;
                }

                if ((result.snippet !== undefined)
                    || (getters.searchResults !== undefined)) {
                    commit(SET_SHOW_EXPAND_COLLAPSE, true);
                    break;
                }
            }

            commit(SET_SEARCH_LANGUAGE_CODE, data.searchLanguageCode);
            commit(SET_TRANSLATED_SEARCH_TERM, data.translatedSearchTerm);
            commit(SET_TRANSLATION_DETECTED_LANGUAGE, data.translationDetectedLanguage);

            // CACHE>>>: Store results into session cache
            const cacheKey = makeSearchCacheKey(data.route, data.reqParams);
            new UtdQueuedCache().setPersistent(
                'utdSPAContentCache', cacheKey, data,
                rootGetters['app/contentVersion']
            );
            // <<<CACHE

            // If we already have results, try to append them to the existing results
            const results = getters.searchResultsResults;
            if (results && results.length) {
                dispatch('resolveMoreResults', { searchResults: data.searchResults });
            }
            else {
                commit(SET_SEARCH_RESULTS_SHOW, true);
                commit(SET_IS_HOME_SEARCH_EMPTY, false);

                if (data.searchResults !== undefined && !data.searchResults.length) {
                    // Turning nullSearchResults boolean value true when no search results.
                    commit(SET_SEARCH_HAS_NULL_RESULTS, true);
                    if (getters.searchParamsType === C_SEARCH.SEARCH_TYPES.PLAIN_TEXT) {
                        if (showNoSearchResultsError(data)) {
                            commit(SET_SEARCH_RESULTS_ERROR,
                                i18next.t('SEARCH.NO_TOPIC_RESULTS',
                                    { search: getters.searchParamsSearchText }));
                        }
                    }
                    else if (getters.searchParamsType === C_SEARCH.SEARCH_TYPES.GRAPHICS) {
                        commit(SET_SEARCH_RESULTS_ERROR,
                            i18next.t('SEARCH.NO_GRAPHIC_RESULTS',
                                { search: getters.searchParamsSearchText }));
                    }
                    else if (getters.isHL7) {
                        commit(SET_SEARCH_RESULTS_ERROR,
                            i18next.t('SEARCH.NO_HL7_RESULTS'));
                    }
                }
                else {
                    commit(SET_SEARCH_RESULTS, data.searchResults);
                    commit(SET_SEARCH_HAS_NULL_RESULTS, false);
                }

                if (data.searchDescription !== undefined) {
                    commit(SET_SEARCH_FOR, data.searchDescription.toLowerCase());
                }
                else if (data.searchTerm !== ''
                    && getters.searchParamsSource === C_SEARCH.SOURCE.HL7) {
                    commit(SET_SEARCH_FOR, data.searchTerm);
                }

                commit(SET_SEARCH_MESSAGING, data.searchMessaging);

                // CORE-8542: Quick hacky fix to force scroll position of search results back to top
                // for initial search results (Safari only browsers, not for graphics search facet).
                if (getters.searchParamsType !== 'GRAPHICS'
                    && rootGetters['device/shouldResetSearchScrollPosition']) {
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, C_UI.SAFARI_SCROLL_RESET_DELAY_MS);
                }
                dispatch('onLoadMoreResults');
            }

            dispatch('postProcessResults');

            dispatch('checkShowMoreButtonShowing', data.total);

            // Setting here in case direct topic fails
            commit(`topic/${SET_IS_TOPIC_VIEW}`, false, { root: true });
            // Resetting source input value here
            commit(SET_SEARCH_PARAMS_SOURCE, C_SEARCH.SOURCE.USER_INPUT);

            return true;
        }
        catch (e) {
            Logger.warn(`Exception occurred trying to generate search results: ${e}`);
            commit(SET_SEARCH_ERROR_STATE, true);
            // Force a reset of search parameters
            commit(RESET_SEARCH_PARAMS);
            commit(SET_SEARCH_RESULTS_ERROR,
                'An error occurred during your search, please try again.');
            commit(SET_SEARCH_HAS_NULL_RESULTS, true);
            return false;
        }
    },
    postProcessResults({ getters, commit }) {
        if (!getters.searchResultsResults || getters.searchResultsResults.length === 0) {
            return;
        }
        getters.searchResultsResults.forEach((result, idx) => {
            // Ignore QAP types, they are handled through DTOs
            if (result.type === 'qap') {
                return;
            }

            // Assign prefix to main search result titles as needed
            prependResultTitle(result, idx);

            // Checking if main search results is not empty.
            if (!result.isPanelResult
                && result.type !== C_QAP.UI.QAP_TYPE
                && !result.qapContents) {
                commit(SET_MAIN_SEARCH_RESULTS_EMPTY, false);
            }
        });
    },
    resolveError({ commit, getters }, error) {
        if (error.status === 400) {
            // Force a reset of search parameters
            commit('RESET_SEARCH_PARAMS');
        }
        else if (error.status === 403) {
            commit(SET_SEARCH_RESULTS_ERROR, i18next.t('ERROR.CONTENT_NOT_AVAILABLE', {
                productName: ''
            }));
            commit(SET_SEARCH_HAS_NULL_RESULTS, true);
        }
        else if (error.status !== '-1' && error.status !== -1) {
            Logger.warn(`Error trying to display search results for search term
             ${getters.searchParamsSearchText} - error:${error.errors} status:${error.status}`);
        }
    }
};

const search = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default search;

export const showNoSearchResultsError = data => {
    // Should return true if no search messaging is present
    if (!data.searchMessaging
        || !data.searchMessaging.systemMessages
        || !data.searchMessaging.systemMessages.length
        || !data.searchMessaging.systemMessages[0].text) {
        return true;
    }

    const hasIbnWarning
        = data.searchMessaging.systemMessages[0].text
        === C_SEARCH.WARNING_TEXT.BRAND_NAMES_DIFFER_BY_COUNTRY;

    return hasIbnWarning === false;
};
