import PendoGuide from './PendoGuide';

const PENDO_RESOURCE_CENTER_GUIDE = 'aca-resource-center';

export default class ResourceCenterGuide extends PendoGuide {
    _getGuideName() {
        return this._getGuideNameIfActive(PENDO_RESOURCE_CENTER_GUIDE);
    }

    _hasResourceCenterContent() {
        return this._pendo && this._pendo.findGuideByName(PENDO_RESOURCE_CENTER_GUIDE)
            && this._pendo.findGuideByName(PENDO_RESOURCE_CENTER_GUIDE).hasResourceCenterContent;
    }
}