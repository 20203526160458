<template>
  <div class="med-topic-formulink-formulary-selectbox">
    <label :for="selectId" v-text="selectLabel" />
    <div class="med-topic-formulink-formulary-selectbox-container">
      <div v-if="modelValue"
           class="med-topic-formulink-formulary-selectbox-placeholder-value">
        <div>{{ modelValue.label }}</div>
      </div>
      <div v-if="!modelValue"
           class="med-topic-formulink-formulary-selectbox-placeholder"
           v-text="placeholderLabel" />
      <select :id="selectId"
              v-model="modelValue"
              @change="onSelectChange($event)">
        <option v-for="(option, idx) in selectOptions"
                :key="idx"
                :value="option"
                v-text="option.label" />
      </select>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        selectId: {
            type: String,
            default: ''
        },
        selectOptions: {
            type: Array,
            default: () => []
        },
        model: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    emits: [ 'on-system-model', 'on-formulary-model' ],
    data() {
        return {
            modelValue: this.model
        };
    },
    computed: {
        isSystem() {
            return this.selectId === 'selectSystem';
        },
        selectLabel() {
            return this.isSystem
                ? this.$t('FORMULINK.SELECT_SYSTEM_LABEL')
                : this.$t('FORMULINK.SELECT_FORMULARY_LABEL');
        },
        placeholderLabel() {
            return this.isSystem
                ? this.$t('FORMULINK.SELECT_SYSTEM_PLACEHOLDER')
                : this.$t('FORMULINK.SELECT_FORMULARY_PLACEHOLDER');
        }

    },
    watch: {
        model(newVal) {
            if (newVal !== this.modelValue) {
                this.modelValue = newVal;
            }
        }
    },
    methods: {
        onSelectChange() {
            if (this.isSystem) {
                this.$emit('on-system-model', this.modelValue);
            }
            else {
                this.$emit('on-formulary-model', this.modelValue);
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@BORDER-COLOR-MED-TOPIC-SELECT-PLACEHOLDER: @WKCE-GRAY-TINT1;
@BG-COLOR-MED-TOPIC-SELECT-PLACEHOLDER: @DS1-UTD-PRIMARY-BG-COLOR;
@MED_TOPIC_SELECT_ICON_CHEVRON_DOWN: '\e90f';
@MED_TOPIC_ICON_FONT: 'wk-icons-open';

.med-topic-formulink-formulary-selectbox {
  padding: 8px 16px 7px;
  font-size: 16px;
  line-height: 1.625;
  color: @DS1-UTD-GRAY-TEXT-COLOR;
  background: @DS1-UTD-PRIMARY-BG-COLOR;

  label {
    display: block;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.7;
  }

  select {
    width: 100%;
    height: 36px;
    font-size: 16px;
    padding: 0 46px 0 16px;
    position: relative;
    z-index: @ZINDEX-FORMULARY-SELECT;
    opacity: 0;
    -webkit-appearance: none;
  }
}

.med-topic-formulink-formulary-selectbox-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.med-topic-formulink-formulary-selectbox-placeholder,
.med-topic-formulink-formulary-selectbox-placeholder-value {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 4px 46px 4px 16px;
  border: solid 1px @BORDER-COLOR-MED-TOPIC-SELECT-PLACEHOLDER;
  background: @BG-COLOR-MED-TOPIC-SELECT-PLACEHOLDER;

  &::before {
    content: @DS1-UTD-CHEVRON-DOWN;
    font-family: @DS1-UTD-ICON-FONT;
    font-size: 12px;
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
  }

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .med-topic-formulink-formulary-selectbox {
      padding: 0;
      margin: 16px 0;
    }

    .med-topic-formulink-formulary-selectbox-container {
      width: auto;
      min-width: 280px;
    }

    .med-topic-formulink-formulary-selectbox-placeholder-value {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}
</style>
