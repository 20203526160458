<template>
  <div class="my-account-sidebar">
    <div class="sidebar__container">
      <div class="sidebar__header">{{ $t('MYACCOUNT.SUBSCRIPTION_INFORMATION') }}</div>
      <div class="sidebar__content">
        <div class="sidebar__subscription">
          <p v-if="userHasPendingOrder"
             class="sidebar__pending-order sidebar-italic">
            This account has a pending order.
            This information will become available after the order is processed.
          </p>
          <p v-if="!userHasPendingOrder" class="sidebar__expire-date">
            {{ $t('MYACCOUNT.YOUR_ACCOUNT_IS_VALID_THROUGH') }}:
            <span v-if="expirationDate" class="sidebar-bolded ds1-block-style">
              {{ expirationDateFormatted() }}
            </span>
            <span v-else class="sidebar-italic block">Not available</span>
          </p>
        </div>
        <a v-if="showSubscriptionLink"
           v-utd-new-tab="true"
           class="sidebar__manage-subscription-link ds1-pb-1"
           :href="renewalSubscriptionUrl"
           @click="manageSubscription()">{{ $t('MYACCOUNT.MANAGE_SUBSCRIPTION') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WkceDates from '_acaSrc/utility/Dates';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS, SSO_INSTITUTION_SELECTION } from '_acaSrc/utility/constants';
import { getDecodeCookie } from '_acaSrc/utility/Cookies';

export default {
    directives: {
        UtdNewTab
    },
    emits: [ 'close-sidebar' ],
    computed: {
        ...mapGetters('account', [ 'myAccountSubscription' ]),
        ...mapGetters('login', [ 'renewalSubscriptionUrl' ]),
        ...mapGetters('app', [ 'isUccState' ]),
        expirationDate() {
            return this.myAccountSubscription.subscriptionExpirationDate;
        },
        userHasPendingOrder() {
            return this.myAccountSubscription
                && this.myAccountSubscription.userHasPendingOrder;
        },
        showSubscriptionLink() {
            const ssoInstSelected = getDecodeCookie(SSO_INSTITUTION_SELECTION);
            const isSSO = ssoInstSelected !== null && ssoInstSelected.trim() !== '';
            return !isSSO && !this.isUccState;
        }
    },
    methods: {
        expirationDateFormatted() {
            return WkceDates.dateToMDYUSLocale(
                this.expirationDate,
                { year: 'numeric', month: 'long', day: 'numeric' });
        },
        manageSubscription() {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                targetUrl: '/store',
                uiElementName: 'myAccountSubcription_manageSubscriptionLink'
            });
            this.close();
        },
        close() {
            this.$emit('close-sidebar');
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.sidebar__pending-order,
.sidebar__expire-date {
  .ds1-ma-0();
}

.sidebar__manage-subscription-link {
  .ds1-pt-2();
}
</style>