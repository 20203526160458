<template>
  <div>
    <utd-modal-sidebar ref="utdSideBarInstance"
                       :modal-classes="myAccountModalClasses"
                       :close-on-overlay-click="true"
                       :close-on-escape="closeOnEscape"
                       :is-dialog-open="isDialogOpen"
                       @utd-modal-overlay-click="closeMyAccountDialog"
                       @utd-sidebar-escape="closeMyAccountDialog">
      <template #sidebarContent>
        <my-account-profile ref="myAccountProfileRef"
                            tabindex="-1"
                            @close-sidebar="closeSidebar($event)"
                            @open-my-account-dialog="handleClickOpenDialog()" />
        <my-profile-setting />
        <my-account-subscription @close-sidebar="closeSidebar()" />
        <my-account-cme @close-sidebar="closeSidebar($event)" />
        <my-account-devices @open-my-account-dialog="handleClickOpenDialog()"
                            @close-sidebar="closeSidebar()" />
        <my-account-download-center @close-sidebar="closeSidebar()" />
      </template>
    </utd-modal-sidebar>
  </div>
</template>

<script>
import UtdModalSidebar from '_acaSrc/components/shared/stdlib/UtdModalSidebar.vue';
import MyAccountProfile from './MyAccountProfile.vue';
import MyProfileSetting from './MyProfileSetting.vue';
import MyAccountCme from './MyAccountCme.vue';
import MyAccountSubscription from './MyAccountSubscription.vue';
import MyAccountDevices from './MyAccountDevices.vue';
import MyAccountDownloadCenter from './MyAccountDownloadCenter.vue';
import PubSub from '_acaSrc/utility/PubSub';
import { mapActions, mapGetters } from 'vuex';
import { C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import { setQueryParamValueNoReload } from '_acaSrc/utility/Browsers';

export default {
    components: {
        UtdModalSidebar,
        MyAccountProfile,
        MyProfileSetting,
        MyAccountSubscription,
        MyAccountCme,
        MyAccountDevices,
        MyAccountDownloadCenter
    },
    data() {
        return {
            isDialogOpen: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapGetters('account', [ 'myAccountAccessLevel' ]),
        ...mapGetters('app', [ 'router' ]),
        myAccountModalClasses() {
            return `show-mobile-navbar ${this.isDialogOpen ? 'hide-overlay' : ''}`;
        },
        closeOnEscape() {
            return !this.isMobileOnDesktop;
        }
    },
    mounted() {
        new PubSub().subscribe('wkutd.closeMyAccountSidebar', this.closeSidebar);
        new PubSub().subscribe('wkutd.closeMyAccountDialog', this.closeMyAccountDialog);
        new PubSub().subscribe('wkutd.openMyAccountDialog', this.handleClickOpenDialog);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.closeMyAccountSidebar', this.closeSidebar);
        new PubSub().unsubscribe('wkutd.closeMyAccountDialog', this.closeMyAccountDialog);
        new PubSub().unsubscribe('wkutd.openMyAccountDialog', this.handleClickOpenDialog);
    },
    methods: {
        ...mapActions('account', [ 'getMyAccountInfo' ]),
        open() {
            this.$refs.utdSideBarInstance.openSidebarModal();
            setQueryParamValueNoReload(C_MY_ACCOUNT.MY_ACCOUNT_QUERY_PARAM, true);
        },
        closeSidebar(options) {
            this.$refs.utdSideBarInstance.closeSidebar(options);
            this.isDialogOpen = false;
        },
        handleClickOpenDialog() {
            this.isDialogOpen = true;
            setQueryParamValueNoReload(C_MY_ACCOUNT.MY_ACCOUNT_QUERY_PARAM);
        },
        async closeMyAccountDialog() {
            this.isDialogOpen = false;
            await this.$nextTick();
            this.$refs.myAccountProfileRef.$el.focus();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-PROFILE-ICON-COLOR: @WKCE-BLUE;

.my-account-sidebar {
  .ds1-mb-3();
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;

  .sidebar__container {
    .ds1-pl-4();
    .ds1-pr-2();
  }

  .sidebar__header {
    .ds1-pb-2();
    .ds1-utd-weight-600();
    font-size: 14px;
    text-transform: uppercase;
    color: @DS1-UTD-SUB-TEXT-COLOR;
  }

  .sidebar__content {
    .ds1-flex-column-wrap();
    .ds1-ml-4();
    .ds1-mb-3();
    .ds1-utd-size-2();
    overflow-wrap: anywhere;
  }

  .sidebar__subscription,
  .sidebar__account-action {
    .ds1-flex-column-wrap();
  }

  .sidebar__account-info {
    .ds1-flex-column-wrap();
    .ds1-pb-2();
  }

  .sidebar-italic {
    font-style: italic;
  }

  .sidebar-bolded {
    .ds1-utd-weight-600();
  }

  .sidebar__change-username-link,
  .sidebar__change-password-link,
  .sidebar__merge-accounts-link,
  .sidebar__edit-contact-link,
  .sidebar__edit-my-profile-link,
  .sidebar__my-profile-label-container {
    font-size: 13px;
  }

  .sidebar__link-container {
    .ds1-mb-1();

    .wkce-icon-pencil,
    .wkce-icon-home,
    .wkce-icon-smartphone,
    .wkce-icon-info,
    .wkce-icon-users,
    .wkce-icon-download-line {
      .ds1-ml-0();
      color: @WKCE-BLUE-SHADE1;
    }
  }

  .sidebar__purchase-desktop-link,
  .sidebar__manage-subscription-link {
    &::before {
      .ds1-utd-external-link();
      background-size: 12px;
      padding: 8px;
      position: relative;
      top: 11px;
      left: 2px;
      margin-right: 4px;
    }
  }
}
</style>