// Allows for { foo: { a: 1 }, bar: { a: 2 } } => { foo_a: "1", bar_a: "2" }
// This is how we've decided to handle Optimizely's strict data format rules,
// which allows only key-string pairs in its user attributes object.
export const optimizelyAggregateAttributes = (attributes, bucketName, bucket) => {
    for (const key in bucket) {
        if (bucket.hasOwnProperty(key)) {
            const value = bucket[key];
            attributes[`${bucketName}_${key}`] = typeof value === 'string'
                ? value : JSON.stringify(value);
        }
    }
};