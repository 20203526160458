<template>
  <div id="outerGraphicContainer">
    <utd-print-header v-if="toolsIsPrintView" />
    <graphic-toolbar />
    <graphic-toolbar-mobile-overflow />
    <graphic-container />
    <div v-if="graphic && graphic.error"
         class="content-error"
         v-text="graphic.error.message" />

    <utd-print-tools :alternate-languages="graphic.alternateLanguages"
                     :type="'graphic'" />
    <div v-graphic-zoom />
    <utd-share-dialog />
  </div>
</template>

<script>
import UtdPrintHeader from '_acaSrc/components/shared/printHeader/UtdPrintHeader.vue';
import GraphicToolbar from '_acaSrc/components/contents/graphic/toolbar/GraphicToolbar.vue';
import GraphicToolbarMobileOverflow
    from '_acaSrc/components/contents/graphic/toolbar/GraphicToolbarMobileOverflow.vue';
import GraphicContainer from '_acaSrc/components/contents/graphic/GraphicContainer.vue';
import UtdPrintTools from '_acaSrc/components/shared/utd/UtdPrintTools.vue';
import UtdShareDialog from '_acaSrc/components/shared/utd/UtdShare/UtdShareDialog.vue';
import GraphicZoom from '_acaSrc/components/contents/graphic/GraphicZoom.directive';
import { mapGetters } from 'vuex';

export default {
    directives: {
        GraphicZoom
    },
    components: {
        UtdPrintHeader,
        GraphicToolbar,
        GraphicToolbarMobileOverflow,
        GraphicContainer,
        UtdPrintTools,
        UtdShareDialog
    },
    computed: {
        ...mapGetters('graphic', [
            'graphic',
            'tools',
            'toolsIsPrintView'
        ])
    }
};
</script>

<style lang="less">
@import (reference) "~_acaAssets/global/variables";
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#outerGraphicContainer {
  margin-top: 30px;

  .scrollerContainer {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: white;
    touch-action: pan-y pinch-zoom;

    .MobileSafari & {
      // Fix for iphone(iOs 15) safari address bar padding issue (CESUS-2033)
      height: 100%;
    }

    .utdWkHeader & {
      top: 86px;

      .hideNavBar.isSmallScreen&,
      .hideNavBar.isTablet& {
        top: 30px;
      }
    }
  }

  #graphics-copyright {
    margin: 0;
    padding: 2em 1.2em 0;
  }

  .utd-tabControl {
    font-size: 0.8em;
  }

  #scroller,
  .graphic {
    display: inline-block;
  }

  .graphic {
    .content-error {
      margin: 15px 1em;
      padding: 5px;
      box-shadow: inherit;
      .border-radius(0);

      p {
        margin: 0;
        font-weight: bold;
        font-size: 0.9em;
      }
    }

    .figure {
      table {
        border-collapse: inherit;
      }
    }

    .MobileSafari & {
      // Workaround for safari issue where graphic table does not scroll completely to the bottom
      padding-bottom: 85px;
    }
  }

  .videoplayer {
    margin: 10px 10px 50px;
  }

  .graphic_lgnd,
  .graphic_footnotes,
  .graphic_reference {
    width: 100%;
    max-width: 1000px;
  }
}

.topicPrint {
  #outerGraphicContainer {
    padding: 10px;

    .scrollerContainer {
      top: 145px;

      #graphics-copyright {
        display: none;
      }
    }
  }
}

.isSmallScreen.gfxView.UCWeb {
  #outerGraphicContainer #graphics-copyright {
    padding-top: 3em;
  }
}

.tabletAll() {
  #outerGraphicContainer {
    .cntnt {
      display: block;
      width: 100%;
    }
  }
}

.isTablet {
  .tabletAll();
}

@media only screen and (min-width: 768px) {
  .tabletAll();

  .isDesktop {
    #outerGraphicContainer {
      margin-top: 0;

      .wkProspect & {
        margin-top: 30px;
      }

      .cntnt {
        width: auto;
      }
    }
  }
}

@media print {
  #outerGraphicContainer {
    overflow: visible;

    #graphicContainer,
    #scroller,
    .cntnt,
    .graphic {
      display: block;
      overflow: visible;
    }
  }
}
</style>
