<template>
  <div class="utd-search-input wk-js2">
    <div class="wk-field"
         :class="inputClasses"
         v-bind="$attrs">
      <div class="wk-field-header">
        <label class="wk-field-label wk-field-label-hidden"
               for="search-input">Search</label>
      </div>
      <div class="wk-field-body">
        <input id="search-input"
               ref="utdSearchInput"
               v-model="searchInput"
               v-bind="options.inputAttrs"
               type="search"
               name="search-input"
               :placeholder="options.placeholder"
               class="wk-field-input"
               @input="onSearchInput">
        <div class="wk-field-inlay">
          <span v-if="options.showSpinner"
                aria-hidden="true"
                class="wk-field-icon wkce-icon-spinner wk-spin" />
          <button v-if="searchInput"
                  type="button"
                  class="wk-field-button wk-button-icon wkce-icon-close"
                  @click="clearSearchInput">
            <span class="wk-sr-only">Clear search field</span>
          </button>
          <button type="button"
                  class="wk-field-button wk-button-icon wkce-icon-search">
            <span class="wk-sr-only">Submit search</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'UtdSearchInput',
    props: {
        title: String,
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['utd-search-input' ],
    data() {
        return {
            searchInput: ''
        };
    },
    computed: {
        inputClasses() {
            return [
                this.$attrs['type-class'],
                { 'wk-field-is-disabled': this.options.isDisabled },
                { 'wk-field-extra-large': this.options.extraLarge }
            ];
        }
    },
    methods: {
        clearSearchInput() {
            this.searchInput = '';
            this.$refs.utdSearchInput.focus();
            this.$emit('utd-search-input', this.searchInput);
        },
        onSearchInput() {
            this.$emit('utd-search-input', this.searchInput);
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-search-input {
  display: flex;

  .wk-field {
    .ds1-mb-0();
    flex-grow: 1;
  }

  .wk-field-input {
    padding-right: 120px;
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  }

  .wk-button-icon {
    padding: 11px;
  }

  .wk-field-inlay {
    .ds1-mr-0();

    .wk-field-button {
      .ds1-ml-0();
    }

    .wkce-icon-search {
      font-size: 24px;
      margin-top: 4px;
    }

    .wkce-icon-close {
      margin-top: 2px;
      font-size: 16px;
      padding-right: 9px;
    }
  }
}
</style>