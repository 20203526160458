import tippy, { createSingleton, inlinePositioning, hideAll } from 'tippy.js';
import './tooltip.less';
import { C_UI, C_APP } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';

export const TOOLTIPS_INITIALIZED_BEFORE_APP_READY
    = 'Tooltips cannot be initialized before app config loads';

const UTD_BOOKMARK_TOOLTIP = {
    appendTo: () => document.body,
    theme: 'utd-tooltip-interactive-bookmark',
    placement: 'bottom',
    hideOnClick: 'toggle',
    trigger: 'manual',
    allowHTML: true,
    offset: [ 0, 7 ],
    popperOptions: {
        modifiers: [
            {
                name: 'flip',
                enabled: false
            }
        ]
    },
    maxWidth: 220,
    interactive: true
};

const UTD_GRAPHIC_HOVER_TOOLTIP = {
    zIndex: 999999999,
    duration: 0,
    appendTo: () => document.body,
    theme: 'utd-tooltip-interactive-graphic-hover',
    placement: 'top',
    delay: [ C_UI.MENU_SHOW_DELAY_MS, 0 ],
    allowHTML: true,
    maxWidth: 550,
    offset: [ 0, 7 ],
    moveTransition: 'transform 0.2s ease-out',
    interactive: true,
    popperOptions: {
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: [ 'bottom', 'right' ]
                }
            }
        ]
    }
};

const UTD_TOOLBAR_HOVER_TOOLTIP = {
    appendTo: () => document.body,
    theme: 'utd-tooltip-interactive-toolbar-links',
    placement: 'bottom',
    delay: C_UI.MENU_SHOW_DELAY_MS,
    allowHTML: true,
    trigger: 'mouseenter',
    content: ele => ele.getAttribute('aria-label'),
    offset: [ 0, 7 ],
    popperOptions: {
        modifiers: [
            {
                name: 'flip',
                enabled: false
            }
        ]
    },
    maxWidth: 220
};

const UTD_TOOLTIP_ELLIPSIS = {
    appendTo: () => document.body,
    theme: 'utd-tooltip-interactive-toolbar-links',
    arrow: false,
    placement: 'top',
    trigger: 'manual',
    offset: [ 0, 0 ],
    content: ele => ele.innerHTML,
    zIndex: 1000001,
    popperOptions: {
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: [ 'bottom', 'right' ]
                }
            }
        ]
    },
    maxWidth: 220
};

const UTD_FORMULARY_INLINE_HOVER_TOOLTIP = {
    theme: 'wkce-default',
    delay: 500,
    offset: [ 0, 5 ],
    popperOptions: {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    rootBoundary: 'viewport'
                }
            }
        ]
    },
    duration: 0,
    touch: false,
    onShow() {
        getWindow().addEventListener('scroll', hideAll, { passive: true });
    },
    onHide() {
        getWindow().removeEventListener('scroll', hideAll, { passive: true });
    }
};

// Needed due to z-indexing issues on modals
const UTD_BOOKMARK_TOOLTIP_ON_MODAL = {
    zIndex: 9999999
};

// Adapted from tippy.js's FAQ.
const replaceTitleTippyPlugin = {
    fn() {
        return {
            onCreate(instance) {
                const title = instance.reference.getAttribute('title');

                if (title) {
                    instance.setContent(title);
                    instance.reference.removeAttribute('title');
                }
            }
        };
    }
};

// Warning - all tooltips are currently hidden on scroll due to some "leaky" logic
// in wke-tooltip.service.js.
class Tooltip {
    constructor(element, config, options) {
        this._element = element;
        this._config = config;
        this._options = options;
        this._instance = null;
        this._singleton = null;
        this.useSingleton = false;
    }

    isEnabled() {
        return this._hasInstance();
    }

    _hasInstance() {
        return this._instance;
    }

    _pruneCustomProperties() {
        delete this._options.allowFlip;
    }

    _canInitializeTooltips() {
        let tooltipStatus = '';
        if (!this._config.supportsTippy || this._config.isPrintView) {
            // We no longer care about these "errors", so ignore them
            tooltipStatus = 'IGNORE_ERROR';
        }
        return tooltipStatus;
    }

    _resolveInitialize(resolve, reject) {
        try {
            // Prune any non tippy properties before creation
            if (this._options) {
                this._pruneCustomProperties();
            }

            // NOTE: Tippy will return an array if matches multiple elements,
            // otherwise will return a single object if passed an element
            this._instance = tippy(this._element, this._options);

            if (this.useSingleton && this._instance && this._instance.length) {
                this._singleton = createSingleton(this._instance, this._options);
            }
            resolve(true);
        }
        catch (err) {
            reject(`Tooltip initialize error: ${err}`);
        }
    }

    initialize() {
        return new Promise(async(resolve, reject) => {
            if (this._config) {
                const hasInitError = this._canInitializeTooltips();
                if (hasInitError) {
                    reject(hasInitError);
                    return;
                }

                this._resolveInitialize(resolve, reject);
            }
            else {
                reject(TOOLTIPS_INITIALIZED_BEFORE_APP_READY);
            }
        });
    }

    setTooltipContent(text) {
        if (this._hasInstance()) {
            this._instance.setContent(text);
        }
    }

    updateTooltipOptions(options) {
        if (this._hasInstance()) {
            this._instance.setProps(options);
        }
    }

    showTooltip() {
        if (this._hasInstance()) {
            this._instance.show();
        }
    }

    hideTooltip() {
        if (this._hasInstance()) {
            this._instance.hide();
        }
    }

    onClick(fnOnClick) {
        if (fnOnClick) {
            this.updateTooltipOptions({
                onMount: instance => {
                    instance.popper.addEventListener('click', fnOnClick, { passive: true });
                    window.addEventListener('gesturestart', fnOnClick);
                },
                onDestroy: instance => {
                    instance.popper.removeEventListener('click', fnOnClick);
                    window.removeEventListener('gesturestart', fnOnClick);
                }
            });
        }
    }

    destroy() {
        if (this._instance && this._instance.length) {
            this._instance.forEach(instance => {
                instance.destroy();
            });
            this._instance.length = 0;
        }
        if (this._singleton) {
            this._singleton.destroy();
        }
    }
}

export class BookmarkTooltip extends Tooltip {
    constructor(element, config) {
        super(element, config, UTD_BOOKMARK_TOOLTIP);
    }

    initialize() {
        return super.initialize().then(() => {
            return this._instance.id;
        });
    }

    // Required due to z-indexing issues on modals
    setBookmarkOnModal() {
        this.updateTooltipOptions(UTD_BOOKMARK_TOOLTIP_ON_MODAL);
    }
}

export class GraphicHoverTooltip extends Tooltip {
    constructor(element, config, onTriggerUpdate) {
        const tippyOpts = {
            ...UTD_GRAPHIC_HOVER_TOOLTIP,
            ...{
                onTrigger: (ref, tgt) => onTriggerUpdate(ref, tgt),
                onShow: () => this._config && this._config.isDesktopView()
            }
        };
        super(element, config, tippyOpts);
        this.useSingleton = true;
    }
}

// These options should be kept minimal so that migrating from tippy remains feasible.
const defaultOptions = {
    hideOnClick: true,
    trigger: 'click',
    allowFlip: true,
    placement: 'top',
    delay: 0,
    offset: [ 0, 10 ],
    content: '',
    theme: 'utd-tooltip-interactive-rich-content',
    zIndex: 9999, // tippy.js default
    onShown: () => null,
    onHide: () => null
};
export class RichContentTooltip extends Tooltip {
    constructor(element, config, options = {}) {
        const tooltipOptions = {
            appendTo: () => document.body,
            interactive: true,
            popperOptions: {
                modifiers: [
                    {
                        name: 'flip',
                        enabled: options.allowFlip || defaultOptions.allowFlip
                    }
                ]
            },
            ...defaultOptions,
            ...options
        };

        super(element, config, tooltipOptions);
    }
}

export class TopicToolbarHoverTooltip extends Tooltip {
    constructor(element, config) {
        super(element, config, UTD_TOOLBAR_HOVER_TOOLTIP);
    }

    initialize() {
        return super.initialize();
    }
}

export class TooltipEllipsis extends Tooltip {
    constructor(element, config) {
        super(element, config, UTD_TOOLTIP_ELLIPSIS);
    }

    initialize() {
        return super.initialize();
    }
}

export class FormularyInlineHoverTooltip extends Tooltip {
    constructor(element, config, options = {}) {
        const tooltipOptions = {
            inlinePositioning: true,
            plugins: [ replaceTitleTippyPlugin, inlinePositioning ],
            ...UTD_FORMULARY_INLINE_HOVER_TOOLTIP,
            ...options
        };

        super(element, config, tooltipOptions);
    }
}

export class PathwayTooltip extends Tooltip {
    constructor(element, config, options = {}) {
        const tooltipOptions = {
            inlinePositioning: true,
            plugins: [ inlinePositioning ],
            ...options,
            ...UTD_FORMULARY_INLINE_HOVER_TOOLTIP
        };

        super(element, config, tooltipOptions);
    }
}

export class GraphicToolBarLinksHoverTooltip extends Tooltip {
    constructor(element, config) {
        const tooltipOptions = {
            zIndex: C_APP.Z_INDEX.MAX_ZINDEX,
            ...UTD_TOOLBAR_HOVER_TOOLTIP
        };

        super(element, config, tooltipOptions);
    }

    initialize() {
        return super.initialize();
    }

    destroy() {
        return super.destroy();
    }
}


export class ZoomControllerTooltip extends Tooltip {
    constructor(element, config, options) {
        const tooltipOptions = {
            zIndex: C_APP.Z_INDEX.MAX_ZINDEX,
            ...UTD_TOOLBAR_HOVER_TOOLTIP,
            ...options
        };

        super(element, config, tooltipOptions);
    }

    initialize() {
        return super.initialize();
    }

    destroy() {
        return super.destroy();
    }
}

export { Tooltip as _Tooltip };