<template>
  <div class="visuallyHidden">
    <div ref="feedbackTooltip" class="feedback-tooltip-box">
      <utd-button button-style="text"
                  button-icon="highlight"
                  @click="launchFeedbackGuide">
        <span class="feedback-tooltip-label">{{ feedbackTooltipLabel }}</span>
      </utd-button>
    </div>
  </div>
</template>

<script>
import {
    getDocument,
    getWindow,
    clearTextSelection
} from '_acaSrc/utility/DOM';
import { RichContentTooltip } from '_acaSrc/utility/tooltip';
import { C_UI, invisibleCharacter } from '_acaSrc/utility/constants';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapActions, mapGetters } from 'vuex';
import { hideAll } from 'tippy.js';

const MAX_CHARACTER_SELECTION_LIMIT = 200;

export default {
    components: {
        UtdButton
    },
    data() {
        return {
            feedbackTooltip: null,
            tooltipAnchor: null,
            selectionText: ''
        };
    },
    computed: {
        ...mapGetters('app', [ 'tooltipConfig' ]),
        ...mapGetters('feature', [ 'featureAttributes' ]),
        ...mapGetters('profile', [ 'userProfileVisitor' ]),
        ...mapGetters('device', [ 'isNotDesktopView' ]),
        ...mapGetters('topic', [
            'topicId',
            'topicTitle',
            'topicType',
            'topicVersion',
            'topicLanguage',
            'contentLanguage',
            'machineTranslationLabels'
        ]),
        truncatedSelectionText() {
            return this.selectionText.substring(0, MAX_CHARACTER_SELECTION_LIMIT);
        },
        localLanguage() {
            if (!this.contentLanguage) {
                return '';
            }
            const localLanguage = this.contentLanguage.toLowerCase();
            return localLanguage;
        },
        feedbackTooltipLabel() {
            const translationLabelTooltip = this.getLocalTranslationLabels().provideTranslationFeedback;
            if (translationLabelTooltip) {
                return translationLabelTooltip;
            }
            return 'Provide Translation Feedback';
        }
    },
    methods: {
        ...mapActions('app', [ 'confirmConfigLoaded', 'logEvent' ]),
        ...mapActions('pendo', [ 'launchGuide' ]),
        launchFeedbackGuide() {
            this.launchGuide({
                guideName: 'TranslationFeedbackGuide',
                guideData: {
                    topicId: this.topicId,
                    topicTitle: this.topicTitle,
                    topicVersion: this.topicVersion,
                    topicLanguage: this.topicLanguage,
                    Q_PopulateResponse: {
                        QID2: this.truncatedSelectionText
                    },
                    browserWidth: this.userProfileVisitor.userBrowserWidthPx,
                    browserHeight: this.userProfileVisitor.userBrowserHeightPx,
                    utdSessionId: this.userProfileVisitor.sessionId,
                    contentLanguage: this.contentLanguage,
                    ...this.featureAttributes
                }
            });
            this.logEvent({
                eventUrl: 'event/machineTranslationClick/json',
                eventParams: {
                    referringUrl: window.location.href,
                    uiElementName: 'machineTranslation_feedbackTooltip',
                    languageCode: this.contentLanguage.toLowerCase(),
                    contentId: this.topicId
                }
            });
        },
        destroyPreviousTooltip() {
            this.tooltipAnchor
              && this.tooltipAnchor.parentNode
              && this.tooltipAnchor.parentNode.removeChild(this.tooltipAnchor);
        },
        createTooltipAnchor(selectionRange) {
            if (!selectionRange) {
                return null;
            }
            this.tooltipAnchor = getDocument().createElement('span');
            this.tooltipAnchor.appendChild(getDocument().createTextNode(invisibleCharacter));
            selectionRange.insertNode(this.tooltipAnchor);
            this.tooltipAnchor.classList.add('utd-translation-tooltip-anchor');
            this.tooltipAnchor.setAttribute('aria-hidden', true);
            return this.tooltipAnchor;
        },
        async initializeTooltip(selectionRange) {
            try {
                const tooltipAnchor = this.createTooltipAnchor(selectionRange);

                await this.confirmConfigLoaded();
                this.feedbackTooltip = new RichContentTooltip(tooltipAnchor, this.tooltipConfig, {
                    theme: 'utd-tooltip-interactive-simple',
                    placement: 'top',
                    delay: C_UI.MENU_SHOW_DELAY_MS,
                    trigger: 'manual',
                    zIndex: 9999999,
                    interactive: true,
                    onShow() {
                        getWindow().addEventListener('scroll', hideAll, { passive: true });
                    },
                    onHide() {
                        getWindow().removeEventListener('scroll', hideAll, { passive: true });
                    }
                });
                await this.feedbackTooltip.initialize();
                this.feedbackTooltip.onClick(this.clearPreviousSelections);
                this.feedbackTooltip.setTooltipContent(this.$refs.feedbackTooltip);
                this.feedbackTooltip && this.feedbackTooltip.showTooltip();
            }
            catch {
                // No-op
            }
        },
        showFeedbackTooltip() {
            this.destroyPreviousTooltip();
            if (this.isNotDesktopView) {
                return;
            }
            const selection = getWindow().getSelection();
            this.selectionText = selection.toString();
            if (!this.selectionText) {
                return;
            }
            const selectionRange = selection.getRangeAt(0).cloneRange();
            selectionRange.collapse(false);
            this.initializeTooltip(selectionRange);
        },
        clearPreviousSelections() {
            this.selectionText = '';
            clearTextSelection();
            this.feedbackTooltip && this.feedbackTooltip.hideTooltip();
        },
        setupFeedbackTooltip(selector = '') {
            const element = getDocument().querySelector(selector);
            if (!element) {
                return false;
            }
            element.addEventListener('mousedown', this.clearPreviousSelections);
            element.addEventListener('mouseup', this.showFeedbackTooltip);
            return true;
        },
        getLocalTranslationLabels() {
            return this.machineTranslationLabels
                && this.machineTranslationLabels[this.localLanguage] || {};
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.tippy-box .tippy-content .feedback-tooltip-box {
    .utd-button {
        .ds1-ph-1();
    }
}
</style>