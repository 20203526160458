import { C_EVENTS, C_FOOTER_CORE, C_PATHS } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';
import { safeTimeout } from '_acaSrc/utility/timers';
import UtdCache from '_acaSrc/utility/UtdCache';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import IndexManager from '_acaSrc/utility/IndexManager';

export const newFooterLink = (title = '', url = '', target = '', rel = '') => {
    return {
        title,
        url,
        target,
        rel
    };
};

const state = {
    footerLinks: {},
    isFooterLoaded: false,
    supportTag: {
        submitCaseMsg: '',
        submitCaseError: '',
        caseReferenceId: '',
        contactUsUrl: '',
        serverTag: ''
    },
    // The booleans for tier 3 and tier 4 visibility are sent from
    // the backend to determine whether the footer is shown in specific states
    // so we do not need to also control tier 1 and tier 2 visiblity here
    isTier3Visible: false,
    isTier4Visible: false,
    footerHeaderTitle: '',
    isFooterHidden: false,
    licensee: ''
};

export const getters = {
    getFooterUrl: state => footerLink => {
        return (state.footerLinks[footerLink] && state.footerLinks[footerLink].url) || '';
    },
    getFooterTitle: state => footerLink => {
        return (state.footerLinks[footerLink] && state.footerLinks[footerLink].title) || '';
    },
    getFooterTarget: state => footerLink => {
        return (state.footerLinks[footerLink] && state.footerLinks[footerLink].target) || '';
    },
    getFooterRel: state => footerLink => {
        return (state.footerLinks[footerLink] && state.footerLinks[footerLink].rel) || '';
    },
    footerHeaderTitle: state => state.footerHeaderTitle,
    isFooterHidden: state => state.isFooterHidden,
    isFooterLoaded: state => state.isFooterLoaded,
    isTier3Visible: state => state.isTier3Visible,
    isTier4Visible: state => state.isTier4Visible,
    supportTag: state => state.supportTag,
    caseReferenceId: state => state.supportTag.caseReferenceId,
    licensee: state => state.licensee
};

export const CREATE_NEW_FOOTER_LINK = 'CREATE_NEW_FOOTER_LINK';
export const SET_FOOTER_URL = 'SET_FOOTER_URL';
export const SET_FOOTER_TITLE = 'SET_FOOTER_TITLE';
export const SET_TARGET_REL = 'SET_TARGET_REL';
export const SET_IS_FOOTER_LOADED = 'SET_IS_FOOTER_LOADED';
export const SET_FOOTER_HEADER_TITLE = 'SET_FOOTER_HEADER_TITLE';
export const SET_IS_FOOTER_HIDDEN = 'SET_IS_FOOTER_HIDDEN';
export const SET_IS_TIER3_VISIBLE = 'SET_IS_TIER3_VISIBLE';
export const SET_IS_TIER4_VISIBLE = 'SET_IS_TIER4_VISIBLE';
export const SET_SUPPORT_TAG_SUBMIT_CASE_MSG = 'SET_SUPPORT_TAG_SUBMIT_CASE_MSG';
export const SET_SUPPORT_TAG_SUBMIT_CASE_ERROR = 'SET_SUPPORT_TAG_SUBMIT_CASE_ERROR';
export const SET_SUPPORT_TAG_CASE_REFERENCE_ID = 'SET_SUPPORT_TAG_CASE_REFERENCE_ID';
export const SET_SUPPORT_TAG_CONTACT_US_URL = 'SET_SUPPORT_TAG_CONTACT_US_URL';
export const RESET_SUPPORT_TAG = 'RESET_SUPPORT_TAG';
export const SET_SUPPORT_TAG = 'SET_SUPPORT_TAG';
export const SET_LICENSEE = 'SET_LICENSEE';

export const mutations = {
    [CREATE_NEW_FOOTER_LINK]: (state, payload) => {
        const { footerLink, title, url, target, rel } = payload;
        state.footerLinks[footerLink] = newFooterLink(title, url, target, rel);
    },
    [SET_FOOTER_TITLE]: (state, payload) => {
        const { footerLink, title } = payload;
        if (title && state.footerLinks[footerLink]) {
            state.footerLinks[footerLink].title = title;
        }
    },
    [SET_FOOTER_URL]: (state, payload) => {
        const { footerLink, url } = payload;
        if (url && state.footerLinks[footerLink]) {
            state.footerLinks[footerLink].url = url;
        }
    },
    [SET_TARGET_REL]: (state, payload) => {
        const { footerLink, target, rel } = payload;
        if (typeof target !== 'undefined' && state.footerLinks[footerLink]) {
            state.footerLinks[footerLink].target = target;
        }
        if (typeof rel !== 'undefined' && state.footerLinks[footerLink]) {
            state.footerLinks[footerLink].rel = rel;
        }
    },
    [SET_IS_TIER3_VISIBLE]: (state, isTier3Visible) => {
        state.isTier3Visible = isTier3Visible;
    },
    [SET_IS_TIER4_VISIBLE]: (state, isTier4Visible) => {
        state.isTier4Visible = isTier4Visible;
    },
    [SET_FOOTER_HEADER_TITLE]: (state, footerHeaderTitle) => {
        state.footerHeaderTitle = footerHeaderTitle;
    },
    [SET_IS_FOOTER_HIDDEN]: (state, isFooterHidden) => {
        state.isFooterHidden = isFooterHidden;
        new IndexManager().bodyCss.setOrClear('hiddenFooter', state.isFooterHidden);
    },
    [SET_IS_FOOTER_LOADED]: (state, isLoaded) => {
        state.isFooterLoaded = isLoaded;
    },
    [SET_SUPPORT_TAG_SUBMIT_CASE_MSG]: (state, msg) => state.supportTag.submitCaseMsg = msg,
    [SET_SUPPORT_TAG_SUBMIT_CASE_ERROR]: (state, error) => state.supportTag.submitCaseError = error,
    [SET_SUPPORT_TAG_CASE_REFERENCE_ID]: (state, id) => state.supportTag.caseReferenceId = id,
    [SET_SUPPORT_TAG_CONTACT_US_URL]: (state, url) => state.supportTag.contactUsUrl = url,
    [RESET_SUPPORT_TAG]: state => {
        state.supportTag.submitCaseMsg = '';
        state.supportTag.submitCaseError = '';
        state.supportTag.caseReferenceId = '';
    },
    [SET_SUPPORT_TAG]: (state, supportTag) => state.supportTag.serverTag = supportTag,
    [SET_LICENSEE]: (state, licensee) => state.licensee = licensee
};

export const actions = {
    initializeFooter({ commit }, footerData = C_FOOTER_CORE) {
        for (const footerLink in footerData) {
            if (footerData.hasOwnProperty(footerLink)) {
                const linkData = footerData[footerLink];
                commit(CREATE_NEW_FOOTER_LINK, {
                    footerLink,
                    title: linkData.title,
                    url: linkData.url,
                    target: linkData.target,
                    rel: linkData.rel
                });
            }
        }
    },
    getFooter({ dispatch }) {
        let promise = null;
        const data = new UtdCache().getSession(C_PATHS.FOOTER_REST_EP);

        if (data) {
            promise = new Promise(resolve => {
                resolve(data);
            });
        }
        else {
            promise = utdRest('getFooter');
        }
        return promise.then(data => dispatch('resolveFooter', data))
            .catch(() => dispatch('resolveError'));
    },
    resolveFooter({ dispatch, commit, rootGetters }, data) {
        // Store footer into session cache
        new UtdCache().setSession(data.route, data);

        const { tier1, tier2 } = data;
        dispatch('initializeFooter', { ...tier1, ...tier2 });

        // Override footer links
        if (rootGetters['app/countryCode'] === 'CN'
            || rootGetters['app/isUccState']) {
            for (const footerLink in C_FOOTER_CORE) {
                if (C_FOOTER_CORE.hasOwnProperty(footerLink)) {
                    const linkData = C_FOOTER_CORE[footerLink];
                    updateUccFooter({ commit }, { footerLink, linkData });
                }
            }
        }

        commit(SET_SUPPORT_TAG_CONTACT_US_URL, data.tier2.contactUs.url);
        commit(SET_FOOTER_HEADER_TITLE, data.tier2.header);
        commit(SET_IS_TIER3_VISIBLE, data.tier3);
        commit(SET_IS_TIER4_VISIBLE, data.tier4);
        commit(SET_IS_FOOTER_LOADED, true);
        return true;
    },
    resolveError() {
        return false;
    },
    openSupportTag({ commit }, payload) {
        const { event } = payload;

        commit(RESET_SUPPORT_TAG);

        new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
        new PubSub().publish(C_EVENTS.OPEN_SUPPORT_TAG_MODAL, {});
    },
    async adjustTemplateHeights({ rootGetters }, eleFooter) {
        const footerOffset = 100,
            winHgt = getWindow().innerHeight,
            footerMarginTop = parseInt(eleFooter.style.marginTop);

        let bdyHgt = 0;

        if (footerMarginTop) {
            bdyHgt = document.body.clientHeight
                    - (eleFooter.clientHeight + footerMarginTop);
        }
        else {
            bdyHgt = document.body.clientHeight
                    - eleFooter.clientHeight
                    - footerOffset;
        }

        let marginTop = footerOffset;
        if ((winHgt - (rootGetters['feature/isHeaderRedesign'] ? 95 : 55)) > bdyHgt) {
            marginTop = winHgt - bdyHgt + (59 - footerOffset);
        }

        marginTop += rootGetters['feature/isHomepageRedesign2024'] ? 42 : 0;
        eleFooter.style.marginTop = `${marginTop}px`;
    },
    setFooterPositionTimeout({ dispatch }, payload) {
        const { options, doFocus } = payload;
        const delayTime = (options.delay || 1000);
        safeTimeout(() => {
            const eleFooter = document.querySelector('#appContainer > footer');
            if (eleFooter && !eleFooter.classList.contains('displayed')) {
                new PubSub().publish(C_EVENTS.START_POSITION_FOOTER);
                dispatch('adjustTemplateHeights', eleFooter);
                dispatch('setRepositionDialogTimeout', { eleFooter, doFocus });
            }
        }, delayTime);
    },
    setRepositionDialogTimeout({ rootGetters }, payload) {
        const { eleFooter, doFocus } = payload;
        safeTimeout(function() {
            eleFooter.classList.add('displayed');

            // Send message to reposition any dialogs that might be visible
            new PubSub().publish('wkutd.repositionDialog');

            // Adding default focus on hidden element of the page after footer resets.
            if (getWindow().location.href.indexOf('#') === -1 && doFocus) {
                const initialFocus = document.querySelector('.defaultFocusHidden');
                if (!rootGetters['search/searchBarBody']
                        && !!initialFocus
                        && getWindow().pathname !== '/login') {
                    if (rootGetters['device/isBrowserNameMSIE']) {
                        initialFocus.focus();
                    }
                    else {
                        initialFocus.focus({ preventScroll: true });
                    }
                }
            }
        }, 1);
    },
    positionFooter({ dispatch, rootGetters }, options = {}) {
        // Function to calculate if footer should be given additional top margin
        // so that just the top portion (tier) of the footer displays above the fold.
        //
        // This is done by determining if the height of the viewport is greater than the
        // contents displayed. If not, then no top margin is applied and the footer is
        // allowed to position statically against the bottom of the content.
        //
        // If the viewport height is greater than the contents being displayed, then the
        // different minus the desired amount of the footer is used for the top margin.
        dispatch('app/delayExecuteUntilDataLoaded', () => {
            if (rootGetters['user/isCustomer']
                    || rootGetters['profile/permissions'].myAccount) {
                const doReset = (options.reset || false);
                const doFocus = (typeof options.doFocusChange !== 'undefined'
                    ? options.doFocusChange
                    : true);

                if (doReset) {
                    const footer = document.querySelector('#appContainer > footer');
                    footer && footer.classList.remove('displayed');
                }
                dispatch('setFooterPositionTimeout', { options, doFocus });
            }
        }, { root: true });
    }
};

const footerStore = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default footerStore;

function updateUccFooter({ commit }, payload) {
    const { footerLink, linkData } = payload;
    if (linkData.uccUrl) {
        commit(SET_FOOTER_URL, {
            footerLink,
            url: linkData.uccUrl
        });
    }
    // This is required for the Terms of Use and SubscriptionLicense footer links for UCC
    if ('uccRel' in linkData && 'uccTarget' in linkData) {
        commit(SET_TARGET_REL, {
            footerLink,
            rel: linkData.uccRel,
            target: linkData.uccTarget
        });
    }
}
