<template>
  <qap-mobile :result="result" />
</template>

<script>
import QapMobile from '_acaQap/QapMobile.vue';

export default {
    components: {
        QapMobile
    },
    props: {
        result: Object
    }
};
</script>
