<template>
  <div id="medical-topic-formulink"
       :class="{ 'medical-topic-formulink-multiple-formularies': isMultipleFormularies }">
    <utd-toolbar class="med-topic-formulink-toolbar"
                 :is-show-back-link="true"
                 :toolbar-title-text="toolBarTitle" />
    <div class="med-topic-formulink-body">
      <div class="med-topic-formulink-mobile-toolbar">
        <h1 v-if="!topicTitle"
            class="med-topic-formulink-title"
            v-text="$t(`${i18nFormulinkKey}.DRUG_INFO_FOR_TOPIC`)" />
        <!-- eslint-disable vue/no-v-html -->
        <h1 v-if="topicTitle"
            class="med-topic-formulink-title"
            v-html="$t('FORMULINK.DRUG_INFO_FOR_TOPIC_TITLE', { title: topicTitle })" />
        <div v-if="isMultipleFormularies && formularyInfoDrugList.length">
          <formulary-selectbox :select-id="'selectSystem'"
                               :model="systemModel"
                               :select-options="formularyInfoSystemFormulariesMap"
                               @on-system-model="onSystemModelUpdate" />
          <formulary-selectbox v-if="systemFormularies"
                               :select-id="'selectFormulary'"
                               :model="formularyModel"
                               :select-options="systemFormularies"
                               @on-formulary-model="onFormularyModelUpdate" />
        </div>
      </div>
      <div class="med-topic-formulink-content">
        <div v-if="!isMultipleFormularies && formularyInfoDrugList.length"
             class="med-topic-formulink-single-formulary">
          <p v-if="systemModel"
             class="med-topic-formulink-account-name"
             v-text="systemModel.label" />
          <p v-if="formularyModel"
             class="med-topic-formulink-formulary-name"
             v-text="formularyModel.label" />
        </div>
        <div v-if="!formularyInfoDrugList.length"
             class="med-topic-formulink-formulary-notification">
          <i class="wk-icon-info" /><span v-text="$t(`${i18nFormulinkKey}.NO_DRUGS_FOR_TOPIC`)" />
        </div>
        <div v-if="formularyInfoDrugList.length"
             class="med-topic-formulink-formulary-notification med-topic-formulink-sub-selectbox">
          <i class="wk-icon-info" />
          <span v-if="isMultipleFormularies && !formularyModel"
                v-text="$t(`${i18nFormulinkKey}.SELECT_SYSTEM_FORMULARY_NOTICE`)" />
          <span v-if="systemModel && formularyModel"
                v-text="$t('FORMULINK.SELECT_FORMULARY_DRUG_NOTICE')" />
        </div>
        <formulary-table v-if="formularyModel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormularySelectbox
    from '_acaSrc/components/contents/topic/formulary-info/FormularySelectbox.vue';
import FormularyTable from '_acaSrc/components/contents/topic/formulary-info/FormularyTable.vue';
import UtdToolbar from '_acaSrc/components/shared/toolbar/UtdToolbar.vue';
import { getDocument } from '_acaSrc/utility/DOM';
import { truncateString } from '_acaSrc/utility/String';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';
import { SET_FULL_TOPIC_WIDTH, SET_IS_TOPIC_VIEW } from '_acaSrc/store/topic/topic.store';

const RENDERING_TIMEOUT = 100;

export default {
    components: {
        FormularySelectbox,
        FormularyTable,
        UtdToolbar
    },
    data() {
        return {
            systemFormularies: null,
            windowScrollListener: null,
            systemModel: null,
            formularyModel: null,
            adjustMobileContentPaddingTopTimeout: null
        };
    },
    computed: {
        ...mapGetters('formulary', [
            'formularyInfoFormulariesList',
            'formularyInfoTopicTitle',
            'formularyInfoDrugList',
            'formularyInfoSystemFormulariesMap',
            'formularyInfoIsPathways'
        ]),
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        isMultipleFormularies() {
            return this.formularyInfoFormulariesList.length > 1;
        },
        topicTitle() {
            let topicTitle = this.formularyInfoTopicTitle;
            if (!this.isDesktopView) {
                topicTitle = truncateString(topicTitle, 100);
            }
            return topicTitle;
        },
        i18nFormulinkKey() {
            return this.formularyInfoIsPathways ? 'PATHWAYS_FORMULINK' : 'FORMULINK';
        },
        toolBarTitle() {
            return this.topicTitle
                ? this.$t('FORMULINK.DRUG_INFO_FOR_TOPIC_TITLE', { title: this.topicTitle })
                : this.$t(`${this.i18nFormulinkKey}.DRUG_INFO_FOR_TOPIC`);
        }
    },
    mounted() {
        this.init();
    },
    beforeUnmount() {
        this.clearScrollListener(this.windowScrollListener);
        clearTimeout(this.adjustMobileContentPaddingTopTimeout);
    },
    methods: {
        ...mapActions('formulary', [
            'getHighlightsForFormulary'
        ]),
        ...mapActions('app', [ 'broadcast' ]),
        ...mapMutations('topic', [ SET_IS_TOPIC_VIEW, SET_FULL_TOPIC_WIDTH ]),
        init() {
            if (!this.isDesktopView) {
                this.adjustMobileContentPaddingTop();
            }

            this[SET_IS_TOPIC_VIEW](true);
            this[SET_FULL_TOPIC_WIDTH](true);

            this.initSystemSelectValues();
        },
        initSystemSelectValues() {
            if (this.formularyInfoSystemFormulariesMap.length === 1
                && this.formularyInfoDrugList.length) {
                this.systemModel = this.formularyInfoSystemFormulariesMap[0];
                this.onSystemSelect();
            }
        },
        onSystemSelect() {
            // First make sure we clear any active formulary if one is set
            this.formularyModel = null;
            if (this.systemModel) {
                // Get formularies for selected system
                const systemFormulariesArray = this.formularyInfoSystemFormulariesMap
                    .filter(system => system.value === this.systemModel.value);
                this.systemFormularies
                    = systemFormulariesArray[0] && systemFormulariesArray[0].formularies;

                if (this.systemFormularies && this.systemFormularies.length === 1) {
                    this.formularyModel = this.systemFormularies[0];
                    this.onFormularySelect();
                }
            }

            if (!this.isDesktopView) {
                this.adjustMobileContentPaddingTop();
            }
            this.positionFooter();
        },
        onFormularySelect() {
            if (this.formularyModel) {
                const contentId
                    = this.router.stateParams().topic || this.router.stateParams().pathwaysId;

                this.getHighlightsForFormulary({ contentId, formularyModel: this.formularyModel })
                    .finally(() => {
                        this.prepareDrugListForRendering();
                    });
            }
        },
        prepareDrugListForRendering() {
            this.formularyInfoDrugList.forEach(drug => {
                const drugInFormulary = this.formularyModel.drugs
                    .find(drugId => drugId === drug.drugId);
                drug['isExistInFormulary'] = !!drugInFormulary;
                const highlight = drugInFormulary
                && this.formularyModel.highlights
                && this.formularyModel.highlights[drug.drugId]
                    ? this.formularyModel.highlights[drug.drugId]
                    : null;
                drug['highlight'] = highlight;
            });
            this.positionFooter();
        },
        adjustMobileContentPaddingTop() {
            clearTimeout(this.adjustMobileContentPaddingTopTimeout);
            this.adjustMobileContentPaddingTopTimeout = setTimeout(() => {
                this.adjustMobileContentPaddingTopTimeout = null;
                const mobileToolbar
                    = getDocument().querySelector('.med-topic-formulink-mobile-toolbar');
                const formulinkContent
                    = getDocument().querySelector('.med-topic-formulink-content');
                if (!mobileToolbar || !formulinkContent) {
                    return;
                }
                formulinkContent.style.paddingTop = `${mobileToolbar.offsetHeight}px`;
            }, RENDERING_TIMEOUT);
        },
        onSystemModelUpdate(newValue) {
            this.systemModel = newValue;
            this.onSystemSelect();
        },
        onFormularyModelUpdate(newValue) {
            this.formularyModel = newValue;
            this.onFormularySelect();
        },
        positionFooter() {
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true,
                delay: 1
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

// Floating toolbar Note: Currently the page contains fixed margins and padding
// to offset content from floating toolbar (visible when scrolling on mobile).
// This is inflexible and requires truncation for long titles on mobile. We
// would like to remove this in the future.

#medical-topic-formulink {
  display: none;

  .vueLoaded & {
    display: block;
  }
}

.med-topic-formulink-toolbar {
  display: none;
}

.med-topic-formulink-mobile-toolbar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 5;

  .fixedToolbar & {
    box-shadow: 0 6px 12px 0.1px rgba(0, 0, 0, 0.2);
  }
}

.med-topic-formulink-title {
  padding: 0 16px;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.3;
  color: @DS1-UTD-HEADER-TEXT-COLOR;
  background-color: @DS1-UTD-GRAY-BG-COLOR;
}

.med-topic-formulink-mobile-toolbar .med-topic-formulink-title {
  line-height: 1.14;
  padding-top: 8px;
  padding-bottom: 8px;

  // Necessary for long titles. See floating toolbar note above.
  box-sizing: border-box;
  max-height: 70px;
  overflow: hidden;
}

.med-topic-formulink-formulary-selectbox-group {
  font-size: 13px;
  line-height: 1.7;
  color: @DS1-UTD-SUB-TEXT-COLOR;
}

.med-topic-formulink-content {
  padding-top: 32px;

  .medical-topic-formulink-multiple-formularies & {
    padding-top: 133px;
  }
}

.med-topic-formulink-single-formulary {
  padding: 8px 25px;
}

.med-topic-formulink-formulary-name {
  margin: 0 0 4px;
  font-size: 16px;
  line-height: 1.625;
  color: @DS1-UTD-GRAY-TEXT-COLOR;
}

.med-topic-formulink-account-name {
  margin: 0;
  font-size: 13px;
  line-height: 1.7;
  color: @DS1-UTD-SUB-TEXT-COLOR;
}

.med-topic-formulink-formulary-notification {
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 1.4375;
  color: @DS1-UTD-GRAY-TEXT-COLOR;

  .wk-icon-info {
    color: @DS1-UTD-INFO;
    padding-right: 5px;
    margin: 0;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .med-topic-formulink-toolbar {
      display: block;
      padding: 0 24px;
      background: @DS1-UTD-GRAY-BG-COLOR;
      line-height: 40px;
    }

    &.fixedToolbar .med-topic-formulink-toolbar {
      position: fixed;
      left: 0;
      right: 0;
      top: 56px;
      z-index: 5;
    }

    .utd-toolbar :deep(.toolbar-title) {
      display: none;
    }

    &.fixedToolbar .utd-toolbar :deep(.toolbar-title) {
      display: inline-block;
    }

    .med-topic-formulink-body {
      margin: 15px 25px;
    }

    .med-topic-formulink-mobile-toolbar {
      position: static;
    }

    &.fixedToolbar .med-topic-formulink-mobile-toolbar {
      box-shadow: none;
    }

    .med-topic-formulink-title {
      padding: 0;
      margin: 0 0 4px;
      font-size: 16px;
      line-height: 1.5625;
      background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    }

    .med-topic-formulink-content {
      padding-top: 0;
    }

    .med-topic-formulink-single-formulary {
      padding: 0;
    }

    .med-topic-formulink-account-name {
      margin-bottom: 8px;
    }

    .med-topic-formulink-formulary-notification {
      padding: 0;
      margin-top: 60px;
    }

    .med-topic-formulink-formulary-notification.med-topic-formulink-sub-selectbox {
      margin-top: 16px;
    }
  }
}
</style>
