<template>
  <div :class="{ 'collapsed': source === 'outline_thumbnail' && collapsed,
                 'ar': topicLanguage === 'ar'}">
    <UtdModalDialog ref="mediaModalDialog" :modal-classes="'media-modal-dialog'" @closed="closed">
      <template #header>{{ graphicDisplayName }}</template>
      <iframe id="emmi-iframe"
              frameborder="0"
              allowfullscreen="true"
              width="600"
              height="360"
              :src="graphicUrl" />
    </UtdModalDialog>
    <h2 v-text="topicInlineMediaHeading" />
    <ul v-if="topicInlineGraphics"
        class="inline-graphics-list">
      <li v-for="(gfx, index) in topicInlineGraphics"
          :key="index"
          class="inline-graphics-list-item">
        <div>
          <div class="title inline-graphics-list-item-title" v-text="gfx.label" />
          <template v-if="gfx.graphicInfo.type === C_GRAPHICS.TYPE.EMMI">
            <span class="inline-graphics-list-item-image">
              <a v-utd-image-viewer="gfx.thumbnailUrl"
                 :href="`${gfx.graphicInfo.url}&source=${source}&view=modal`"
                 class="inline-graphics-list-item-image-link"
                 @click="showGraphicModal($event, gfx.graphicInfo)">
                <div class="play-icon" />
              </a>

              <!-- eslint-disable vue/no-v-html -->
              <a :href="`${gfx.graphicInfo.url}&source=${source}&view=modal`"
                 class="inline-graphics-title"
                 tabindex="-1"
                 @click="showGraphicModal($event, gfx.graphicInfo)"
                 v-html="limitWithEllipsis(gfx.graphicInfo.displayName)" />
            </span>
          </template>
          <utd-thumbnail v-else
                         :graphic="graphicDataForThumbnail(gfx)"
                         :has-border="true"
                         @utd-thumbnail-click="showGraphicModal($event, gfx.graphicInfo)" />
        </div>
      </li>
    </ul>
    <utd-button v-show="topicInlineGraphics && topicInlineGraphics.length > 2"
                button-size="small"
                button-style="ghost"
                class="viewAllGraphicsLink"
                @click="showAllGraphics($event);">
      View all graphics
    </utd-button>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import utdImageViewer from '_acaSrc/directives/UtdImageViewer.directive';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import { C_GRAPHICS } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';
import { truncateString } from '_acaSrc/utility/String';
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { graphicDataForThumbnail } from '_acaSrc/utility/contents/graphic/graphic';

export default {
    components: {
        UtdModalDialog,
        UtdThumbnail,
        UtdButton
    },
    directives: {
        utdImageViewer
    },
    props: {
        source: String
    },
    data() {
        return {
            graphicUrl: '',
            graphicDisplayName: '',
            collapsed: true,
            C_GRAPHICS,
            graphicDataForThumbnail
        };
    },
    computed: {
        ...mapGetters('topic', [
            'topicLanguage',
            'topicInlineGraphics',
            'topicInlineMediaHeading',
            'topicInlineGraphicsType'
        ]),
        ...mapGetters('device', [ 'isBrowserNameMSIE' ]),
        titleLimit() {
            return this.source === 'outline_thumbnail' ? 43 : '';
        }
    },
    mounted() {
        this.replaceMissingThumbnailsWithDefault();
    },
    beforeUnmount() {
        this.closed();
    },
    methods: {
        ...mapActions('graphic', [
            'handleUseGraphicLink',
            'showMediaModal'
        ]),
        showAllGraphics() {
            this.collapsed = false;
        },
        showGraphicModal(evt, graphicInfo) {
            if (graphicInfo.type === 'emmi') {
                this.graphicUrl = graphicInfo.url || '';
                this.graphicDisplayName = graphicInfo.displayName || '';
                this.showMediaModal({
                    graphic: graphicInfo,
                    mediaModalDialog: this.$refs.mediaModalDialog,
                    inlineGraphicsType: this.topicInlineGraphicsType || '',
                    source: this.source,
                    event: evt
                });
            }
            else {
                this.handleUseGraphicLink({ evt });
            }
        },
        replaceMissingThumbnailsWithDefault() {
            if (!this.topicInlineGraphics) {
                return;
            }

            for (let i = 0; i < this.topicInlineGraphics.length; i++) {
                const graphic = this.topicInlineGraphics[i];
                if (graphic.graphicInfo.type === 'emmi' && !graphic.thumbnailUrl) {
                    graphic.thumbnailUrl = '~_acaAssets/graphic/default_emmi_thumbnail.png';
                }
            }
        },
        limitWithEllipsis(text) {
            return truncateString(text, this.titleLimit);
        },
        closed() {
            if (!this.isBrowserNameMSIE) {
                return;
            }
            const frame = getWindow().document.querySelector('#emmi-iframe');
            if (!frame) {
                return;
            }

            frame.src = 'about:blank';
            frame.parentNode.removeChild(frame);
        }
    }
};
</script>

<style lang="less">
@import '~_acaAssets/graphic/graphic-viewer-inline.less';
</style>
