<template>
  <div class="topic-rating-feedback_container" :class="ratingAndFeedbackClasses">
    <div class="topic-rating-feedback_left">
      <utd-star-rating
        v-if="!showOnlyFeedback"
        id="desktopTopicStarRating"
        :user-rating="topicRating"
        class="utdStarRating_topicDesktop" />
    </div>
    <div class="topic-rating-feedback_right">
      <user-feedback
        :is-for-calculator="isTopicCalculator"
        :feedback-url="getTopicFeedbackUrl()"
        :class="{'expanded': showOnlyFeedback }" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UtdStarRating from '_acaSrc/components/shared/stdlib/UtdStarRating.vue';
import UserFeedback from '_acaSrc/components/contents/topic/UserFeedback.vue';
import { getWindow } from '_acaSrc/utility/DOM';
import { collectionToQueryParams } from '_acaSrc/utility/http';

export default {
    components: {
        UtdStarRating,
        UserFeedback
    },
    props: {
        showOnlyFeedback: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('topic', [
            'topicRating',
            'topicId',
            'fullTopicWidth',
            'topicLanguage',
            'isTopicCalculator'
        ]),
        ratingAndFeedbackClasses() {
            return { onlyFeedback: this.showOnlyFeedback,
                noOutline: this.fullTopicWidth,
                isTopicCalculator: this.isTopicCalculator };
        }
    },
    methods: {
        getTopicFeedbackUrl() {
            const params = {
                utdPopup: true,
                destination: 'editorial',
                topicKey: this.topicId,
                topicLanguage: this.topicLanguage,
                referer: encodeURIComponent(getWindow().location.href)
            };
            collectionToQueryParams(params);
            return `/feedback/letter?${collectionToQueryParams(params)}`;
        }
    }

};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.topic-rating-feedback_container {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: @ZINDEX-STAR-RATING-TOPIC-DESKTOP;
  bottom: 0;
  left: 0;
  box-shadow: @DS1-BOX-SHADOW-ABOVE;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  background-color: @DS1-UTD-TOPIC-RATING-BG-COLOR;
  height: 46px;

  > * {
    box-sizing: border-box;
  }

  &.onlyFeedback {
    height: 40px;
  }

  &.noOutline {
    position: sticky;
    max-width: 132px;
    margin-bottom: -100px;
  }

  &.isTopicCalculator {
    max-width: 158px;
  }
}

.topic-rating-feedback_left,
.topic-rating-feedback_right {
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.topic-rating-feedback_left {
  .ds1-pl-3();
  .ds1-pr-2();
  border-right: 1px solid @WKCE-BLUE-TINT2;

  :deep(.star-rating__container) {
    .star-rating-label {
      color: @DS1-UTD-TOPIC-VIEW-RATING-COLOR;
      font-size: 12px;
      line-height: 16px;
    }

    .wkce-icon-star,
    .wkce-icon-filled-star {
      display: inline;

      &::before {
        font-size: 18px;
        width: 18px;
      }

      &:last-child {
        .ds1-mr-0();
      }

      :hover {
        &::before {
          font-size: 18px;
          width: 18px;
        }
      }
    }
  }
}

.topic-rating-feedback_right {
  background-color: @WKCE-WHITE;
}

@media only screen and (min-width: 768px) {
  .topic-rating-feedback_container {
    display: flex;

    &:not(.noOutline) {
      width: 260px;
    }

    &.onlyFeedback {
      .topic-rating-feedback_left {
        display: none;
      }

      .topic-rating-feedback_right {
        width: 100%;
      }
    }
  }

  .topic-rating-feedback_left {
    width: 166px;
  }

  .topic-rating-feedback_right {
    width: 100px;

    .user-feedback-link {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .wkce-icon-star,
  .wkce-icon-filled-star {
    display: inline;
  }
}

@media only screen and (min-width: 992px) {
  .topic-rating-feedback_container:not(.noOutline) {
    width: 300px;
  }

  .topic-rating-feedback_left {
    width: 195px;
    .ds1-pr-1();

    :deep(.star-rating__container) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;

      .star-rating-label {
        .ds1-mr-1();
        margin-top: 2px;
      }
    }
  }

  .topic-rating-feedback_right {
    width: 110px;
  }
}

@media only screen and (min-width: 1200px) {
  .topic-rating-feedback_container:not(.noOutline) {
    width: 340px;
  }

  .topic-rating-feedback_left {
    width: 235px;
  }
}
</style>