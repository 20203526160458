<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { safeTimeout } from '_acaSrc/utility/timers';
import { C_TOPIC } from '_acaSrc/utility/constants';
import {
    elAddClass,
    getWindow,
    getMaxScrollY,
    isOrientationLandscape
} from '_acaSrc/utility/DOM';
import {
    isGraphicRef,
    getGraphicRef,
    isTopicDrugDxySubtype,
    collapseAllFormulinkAccordions,
    processScrollTargets
} from '_acaSrc/utility/contents/topic/topic';
import {
    SET_SCROLLER_ACTIVE,
    SET_TOPIC_SCROLLER,
    SET_TOPIC_SCROLLING,
    RESET_SCROLL_TARGET_QUEUE,
    SET_URL_SCROLL_POSITION,
    SET_SCROLLER_RESET,
    SET_SECTION_POINTER_POSITION,
    SET_SECTION_POINTER_VISIBLE,
    SET_SECTION_POINTER_TARGET,
    SET_MACHINE_LEARNING_PAUSED,
    SET_IS_TOPIC_VIEW,
    SET_IS_TITLE_COLLAPSE
} from '_acaSrc/store/topic/topic.store';
import { ScrollTarget } from '_acaSrc/utility/contents/topic/ScrollTarget';

const {
    ANCHOR_HASH_PREFIX,
    SCROLL_TARGET_SUFFIX,
    TOOLBAR_SCROLL_OFFSET_PX
} = C_TOPIC;

const INITIAL_SCROLL_OFFSET_PX = 148;

const TABBED_CONTENT_SCROLL_OFFSET_PX = 151;
const DESKTOP_TABBED_CONTENT_SCROLL_OFFSET_PX = 195;
const PROSPECT_MOBILE_SCROLL_OFFSET_PX = 100;
const PROSPECT_BANNER_SCROLL_OFFSET_PX = 285;
const PROSPECT_NOBANNER_SCROLL_OFFSET_PX = 169;
const PROSPECT_SUSBCRIBE_SCROLL_OFFSET_PX = 28;
const PROSPECT_SUSBCRIBE_FROM_GRAPHIC_SCROLL_OFFSET_PX = 24;

const MEDICAL_H1_SCROLL_OFFSET_PX = 28;
const DRUG_H1_SCROLL_OFFSET_PX = 17;
const FORMULINK_TITLE_SCROLL_OFFSET_PX = 3;
const GRAPHIC_SCROLL_OFFSET_PX = 6;
const ARTICLE_SCROLL_BOTTOM_BUFFER = 5;

const DESKTOP_UNFIXED_HEADER_HEIGHT = 123;
const DESKTOP_FIXED_HEADER_HEIGHT = 97;
const MOBILE_HEADER_OFFSET_PX = 120;

const TABLET_LANDSCAPE_DRUG_DXY_SCROLL_OFFSET_PX = 105;
const MOBILE_DESKTOP_DRUG_DXY_SCROLL_OFFSET_PX = 13;

const ORIENTATION_CHANGE_UPDATE_MS = 250;
export const POINTER_ANIMATION_DELAY_MS = 250;

const SUBSCRIBE_MESSAGE_ID = 'subscribeMessage';
const REFERENCES_TARGET_HASH = 'references';

export default {
    data() {
        /* eslint-disable no-multi-spaces */
        return {
            scrollerEl: null,   // Either global window, or #topicArticle
            targetHash: '',     // Target URL hash being scrolled to (if any)
            targetEl: '',       // Target element being scrolled to (if any)
            targetPos: -1,      // Topic position being scrolled to
            headerOffset: 0,
            topicArticleEl: null,
            scrollDebounce: null
        };
        /* eslint-enable no-multi-spaces */
    },
    computed: {
        ...mapGetters('app', [
            'isProspectView',
            'isProspectMode',
            'isTabletDevice',
            'isFixedToolbar'
        ]),
        ...mapGetters('topic', [
            'scrollTargets',
            'topicType',
            'topicSubtype',
            'topicIsScrolling',
            'previousSection',
            'scrollTrigger',
            'activeScrollTarget',
            'lastPendingScrollTarget',
            'isSaveScrollPosition',
            'doScrollerReset',
            'isTabbedContent',
            'isScrollTargetFromClick',
            'topicFontSize',
            'hideMarketingBanner'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isMobileOnDesktop' ])
    },
    watch: {
        async scrollTrigger() {
            if (this.activeScrollTarget) {
                this[SET_IS_TITLE_COLLAPSE](false);

                // Copying active scroll target as it may get cleared before processing
                this.workingActiveTarget
                    = new ScrollTarget({ fromTarget: this.lastPendingScrollTarget });

                await this.whenTopicElementsStable();

                if (this.isNewTargetHash()) {
                    this.targetHash = this.workingActiveTarget.hash;
                    this[SET_TOPIC_SCROLLING](true);
                    this.scrollToHash();
                }
                else if (this.isNewTargetElement()) {
                    this.targetEl = this.workingActiveTarget.element;
                    this[SET_TOPIC_SCROLLING](true);
                    this.scrollToElement();
                }
                else if (this.isNewTargetPosition()) {
                    this.targetPos = this.workingActiveTarget.position;
                    this[SET_TOPIC_SCROLLING](true);
                    this.scrollToPosition();
                }
            }
        },
        doScrollerReset(newVal) {
            if (newVal) {
                this.setScrollerElement();
                this.setTopicHeaderOffset();
                this[SET_SCROLLER_RESET](false);
            }
        },
        isMobileOnDesktop() {
            this.setScrollerElement();
            this.setTopicHeaderOffset();
        },
        hideMarketingBanner() {
            this.setTopicHeaderOffset();
        }
    },
    mounted() {
        this.$nextTick(this.setup);
    },
    beforeUnmount() {
        this[SET_SCROLLER_ACTIVE](false);
        this.clearScrollListener(this.windowScrollListener);
        this.clearScrollListener(this.articleScrollListener);
        this.clearOrientationChangeListener(this.onOrientationChange);
    },
    methods: {
        ...mapActions('topic', [
            'scrollToActiveHash',
            'processNextScrollRequest',
            'whenTopicElementsStable',
            'setArticleTop'
        ]),
        ...mapMutations('topic', [
            SET_TOPIC_SCROLLER,
            SET_TOPIC_SCROLLING,
            SET_SCROLLER_ACTIVE,
            RESET_SCROLL_TARGET_QUEUE,
            SET_URL_SCROLL_POSITION,
            SET_SCROLLER_RESET,
            SET_SECTION_POINTER_TARGET,
            SET_SECTION_POINTER_VISIBLE,
            SET_SECTION_POINTER_POSITION,
            SET_MACHINE_LEARNING_PAUSED,
            SET_IS_TOPIC_VIEW,
            SET_IS_TITLE_COLLAPSE
        ]),
        isNewTargetHash() {
            return this.workingActiveTarget
                && this.workingActiveTarget.hash
                && this.workingActiveTarget.hash !== this.targetHash;
        },
        isNewTargetElement() {
            return this.workingActiveTarget
                && this.workingActiveTarget.element
                && this.workingActiveTarget.element !== this.targetEl;
        },
        isNewTargetPosition() {
            return this.workingActiveTarget
                && this.workingActiveTarget.position
                && this.workingActiveTarget.position !== this.targetPos;
        },
        setup() {
            if (this.topicType === 'calc') {
                return;
            }
            this.setScrollerElement();
            this.setTopicHeaderOffset();

            processScrollTargets(this.topicType);

            this.windowScrollListener
                = this.setScrollListener(getWindow(), this.trackScrollPosition);

            this.setOrientationChangeListener(this.onOrientationChange);

            this.topicTitleEl = document.querySelector('#topic-title');
            this.topicArticleEl = document.querySelector('#topicArticle');
            this.topicToolbarEl = document.querySelector('#topic-toolbar');
            this.topicContainerEl = document.querySelector('#topicContainer');

            if (this.topicArticleEl) {
                this.articleScrollListener
                    = this.setScrollListener(this.topicArticleEl, this.trackScrollPosition);
            }

            this._resetScrollPipeline();
            this[SET_SCROLLER_ACTIVE](true);
        },
        setScrollerElement() {
            // For mobile prospect, or any tablet in landscape view, #topicArticle
            // is the scroll container. Otherwise, it is the Window object.
            let scrollerEl = getWindow();
            if ((!this.isDesktopView && this.isProspectMode)
                || (this.isTabletDevice && isOrientationLandscape())) {
                const topicArticleEl = document.querySelector('#topicArticle');
                if (topicArticleEl) {
                    scrollerEl = topicArticleEl;
                }
            }
            this[SET_TOPIC_SCROLLER](scrollerEl);
            this.scrollerEl = scrollerEl;
        },
        onOrientationChange() {
            this[SET_MACHINE_LEARNING_PAUSED](true);
            setTimeout(() => {
                this[SET_SCROLLER_RESET](true);
                if (!this.previousSection) {
                    this.scrollToActiveHash();
                    return;
                }

                const previousSection = document.querySelector(
                    `#${this.previousSection} > [class*=${SCROLL_TARGET_SUFFIX}]`
                );
                if (!previousSection) {
                    this[SET_MACHINE_LEARNING_PAUSED](false);
                    return;
                }

                this.targetEl = previousSection;
                this.scrollToElement();
            }, ORIENTATION_CHANGE_UPDATE_MS);
        },
        setTopicHeaderOffset() {
            // Called after a resize or oritentationchange event to reset
            // the header offset to be used when calculating topic scroll position
            let headerOffset = INITIAL_SCROLL_OFFSET_PX;

            if (this.isTabbedContent) {
                headerOffset = this.isDesktopView
                    ? DESKTOP_TABBED_CONTENT_SCROLL_OFFSET_PX
                    : TABBED_CONTENT_SCROLL_OFFSET_PX;
            }
            else if (this.isProspectMode) {
                headerOffset = PROSPECT_MOBILE_SCROLL_OFFSET_PX;

                if (this.isDesktopView) {
                    headerOffset = PROSPECT_BANNER_SCROLL_OFFSET_PX;

                    if (this.hideMarketingBanner) {
                        headerOffset = PROSPECT_NOBANNER_SCROLL_OFFSET_PX;
                    }
                }
            }

            this.headerOffset = headerOffset;
        },
        trackScrollPosition() {
            if (this.isSaveScrollPosition && !this.topicIsScrolling) {
                const position = this.scrollerEl.pageYOffset | this.scrollerEl.scrollTop;
                if (position > 0) {
                    this[SET_URL_SCROLL_POSITION]({ position });
                }
            }
        },
        scrollToHash() {
            // Set isTopicView true here because a hash change resets the UI
            this[SET_IS_TOPIC_VIEW](true);

            // Bypass smooth scrolling for Drug DXY topics
            if (isTopicDrugDxySubtype(this.topicSubtype)) {
                this._processDrugDxyScrollTarget();
                this._resetScrollPipeline();
                return;
            }

            // Attempt to assign scrollTarget from hashed class
            const selector = `.${ANCHOR_HASH_PREFIX}${this.targetHash}${SCROLL_TARGET_SUFFIX}`;
            let scrollTarget = document.querySelector(selector);

            scrollTarget = scrollTarget || this._setScrollTargetForGraphic(scrollTarget);
            scrollTarget = scrollTarget || this._setScrollTargetForPCU(scrollTarget);
            scrollTarget = scrollTarget || this._setScrollTargetForSubscribe(scrollTarget);
            scrollTarget = scrollTarget || this._setScrollTargetForReferences(scrollTarget);

            if (!scrollTarget) {
                // If scrollTarget not found, abort scroll and reset.
                // This can happen if scrollToActiveHash() is called before
                // the new topic content has finshed rendering.
                this._resetScrollPipeline();
                return;
            }

            this.targetEl = scrollTarget;

            // Handle expanding any Formulink accordion
            this._expandFormulinkAccordionIfCollapsed(this.targetEl);

            this[SET_SECTION_POINTER_TARGET](this.targetEl);
            this.scrollToElement();
        },
        scrollToElement() {
            if (!this.targetEl) {
                this._resetScrollPipeline();
                return;
            }

            this.targetPos = this._calculateScrollToPosition(this.targetEl);
            this.scrollToPosition();
        },
        scrollToPosition() {
            // Pause machine learning during automatic scrolling of article,
            // unless scrollTarget is #topicContent. When user clicks on the
            // 'View Topic' toggle, machine learning should still pick up the
            // initial visible article sections.
            const pauseML = !this.targetEl
                         || !this.targetEl.classList.contains(C_TOPIC.TOPIC_TAB_HASH);
            this[SET_MACHINE_LEARNING_PAUSED](pauseML);

            if (this._atTargetOrBeyondMaxScroll()) {
                // Without the timeout, the pointer might hide and never re-show
                safeTimeout(this.postScrollActions, POINTER_ANIMATION_DELAY_MS);
                return;
            }

            this[SET_SECTION_POINTER_VISIBLE](false);

            // Otherwise, setup the scroll end event handler
            this.setScrollEndEvent(this.scrollerEl, this.postScrollActions);

            const scrollOptions = { top: this.targetPos };
            if (!this.workingActiveTarget || this.workingActiveTarget.useSmoothScroll) {
                scrollOptions.behavior = 'smooth';
            }
            this.scrollerEl.scroll(scrollOptions);
        },
        processPostScrollActions() {
            // If applicable, update section pointer position
            if (!this.isProspectView
                && this.isDesktopView
                && this.targetEl
                && this.targetHash !== C_TOPIC.TOPIC_TAB_HASH) {
                this[SET_SECTION_POINTER_POSITION](this.targetPos);
            }

            // If scroll was triggered from directly clicking a link, then
            // remove any prior manual scroll position for current URL.
            if (this.isScrollTargetFromClick) {
                this[SET_URL_SCROLL_POSITION]();
            }

            this._resetScrollPipeline();

            // See if there is another scroll request to be processed
            this.processNextScrollRequest();
        },
        postScrollActions() {
            this[SET_IS_TITLE_COLLAPSE](false);
            this._positionSanityCheck();
            setTimeout(this.processPostScrollActions, POINTER_ANIMATION_DELAY_MS);
        },
        // PIPELINE SUPPORT METHODS
        _resetScrollPipeline() {
            this.targetHash = '';
            this.targetEl = '';
            this.targetPos = -1;

            this[SET_MACHINE_LEARNING_PAUSED](false);
            this[RESET_SCROLL_TARGET_QUEUE]();
            this[SET_TOPIC_SCROLLING](false);
        },
        _expandFormulinkAccordionIfCollapsed(el) {
            if (!el) {
                return;
            }
            if (el.classList.contains('drawer-opener')) {
                const accordion = el.closest('[data-accordion]');
                if (!accordion.classList.contains('drawer-open')) {
                    collapseAllFormulinkAccordions();
                    elAddClass(accordion, 'drawer-open');
                }
            }
        },
        _calculateScrollToPosition(targetEl) {
            // Unfortunately it is necessary to evaluate the article tops
            // before we get the target element position due to timing
            // issues related to forward/back navigation.
            this.setArticleTop({
                articleEl: this.topicArticleEl,
                toolbarEl: this.topicToolbarEl,
                containerEl: this.topicContainerEl
            });
            const rect = targetEl.getBoundingClientRect();

            // Get current amount topic scrolled, then calc initial scroll position
            const currentScroll = this.scrollerEl.pageYOffset | this.scrollerEl.scrollTop;
            let scrollToPos = Math.round(rect.top) + currentScroll - this.headerOffset;

            // Offset by height of toptic title if prospect or non-desktop view
            if (this.isProspectMode || !this.isDesktopView) {
                if (this.topicTitleEl) {
                    scrollToPos -= this.topicTitleEl.offsetHeight;
                }
            }
            else {
                if (!this.isFixedToolbar
                  && this.topicTitleEl) {
                    scrollToPos
                      -= this.topicTitleEl.offsetHeight - this._adjustTopicTitleFontSize();
                }
                scrollToPos -= TOOLBAR_SCROLL_OFFSET_PX;
            }

            return this._adjustScrollPositionByContent(scrollToPos, targetEl);
        },
        _adjustScrollPositionByContent(scrollToPos, targetEle) {
            // For prospect topics, adjust if targetElement is gated
            scrollToPos += targetEle.classList.contains('emptyAnchor')
                ? PROSPECT_SUSBCRIBE_SCROLL_OFFSET_PX : 0;
            scrollToPos += targetEle.classList.contains('subscribeMessageBanner')
                ? PROSPECT_SUSBCRIBE_FROM_GRAPHIC_SCROLL_OFFSET_PX : 0;

            // Medical H1 targets have significantly more top padding than other H# sections,
            // which is unfortunately impacted by font size.
            if (targetEle.classList.contains('h1')) {
                scrollToPos += MEDICAL_H1_SCROLL_OFFSET_PX + this._adjustMedicalH1ByFontSize();
            }
            else if (targetEle.classList.contains('drugH1')) {
                // Drug topic H1 targets have different padding than medical topics
                scrollToPos += DRUG_H1_SCROLL_OFFSET_PX + this._adjustDrugH1ByFontSize();
            }
            else if (targetEle.classList.contains('formulink-section-title')) {
                scrollToPos += FORMULINK_TITLE_SCROLL_OFFSET_PX;
            }
            else if (getGraphicRef(targetEle)) {
                // Highlighted graphic anchors have different padding as well
                scrollToPos += GRAPHIC_SCROLL_OFFSET_PX;
            }

            return scrollToPos;
        },
        _adjustMedicalH1ByFontSize() {
            const fontSize = this.topicFontSize;
            return fontSize === 'textSize-S' ? -10 : (fontSize === 'textSize-L' ? 3 : 0);
        },
        _adjustDrugH1ByFontSize() {
            const fontSize = this.topicFontSize;
            return fontSize === 'textSize-S' ? -2 : (fontSize === 'textSize-L' ? 3 : 0);
        },
        _adjustTopicTitleFontSize() {
            const fontSize = this.topicFontSize;
            return fontSize === 'textSize-S' ? 33 : (fontSize === 'textSize-L' ? 37 : 34);
        },
        _setScrollTargetForGraphic(scrollTarget) {
            // Returns new scrollTarget if passed isGraphicRef(hash),
            // otherwise, returns original scrollTarget passed.

            // If hash is for a graphic anchor
            if (isGraphicRef(this.targetHash)) {
                // Highlight all anchors matching hash, and set first to scrollTarget
                const anchors = Array.from(document.querySelectorAll(`.${this.targetHash}`));
                if (anchors.length > 0) {
                    anchors.forEach(ele => {
                        elAddClass(ele, 'highlightedCurrent');
                    });
                    // Set the scrollTarget to the first graphicRef
                    scrollTarget = anchors[0];
                }
            }
            return scrollTarget;
        },
        _setScrollTargetForPCU(scrollTarget) {
            // If valid scrollTarget is passed, and topicSubType is a medial pcu,
            // then returns new scrollTarget for correct topic PCU element.
            // Otherwise, returns original scrollTarget passed.

            if (scrollTarget && this.topicSubtype === 'medical_pcu') {
                // ToDo: Review using behaviour in scrollToHash function
                // If current topic is a PCU, hash target should be changed to
                // point to the parent header of the target.
                const prevElement = scrollTarget.parentElement.previousElementSibling;
                const prevElementIsHeading = prevElement.className.indexOf('headingAnchor') > -1;
                const prevElementH1Child = prevElement.querySelector('span.h1');

                scrollTarget = prevElementIsHeading && prevElementH1Child
                    ? prevElementH1Child
                    : scrollTarget;
            }
            return scrollTarget;
        },
        _setScrollTargetForSubscribe(scrollTarget) {
            if (this.targetHash === SUBSCRIBE_MESSAGE_ID) {
                scrollTarget = getWindow().document.querySelector(`#${this.targetHash}`)
                            || getWindow().document.querySelector('#subscribeInterrupt');
            }
            return scrollTarget;
        },
        _setScrollTargetForReferences(scrollTarget) {
            if (this.targetHash === REFERENCES_TARGET_HASH) {
                scrollTarget = getWindow().document.querySelector(`#${this.targetHash}`)
                            || getWindow().document.querySelector('#subscribeInterrupt')
                            || getWindow().document.querySelector('#subscribeMessage');
            }
            return scrollTarget;
        },
        _atTargetOrBeyondMaxScroll() {
            // If topic is already at target, or target is beyond the max scroll
            // and already scrolled to the bottom, then run post actions
            // immediately and return.
            // NOTE: Providing for a pixel buffer at the bottom to allow for
            // odd positioning problems noticed on some QA chrome browsers.
            this.maxScrollY = getMaxScrollY(this.scrollerEl);
            const currentScroll = this.scrollerEl.pageYOffset | this.scrollerEl.scrollTop;

            if (currentScroll === this.targetPos
                || (this.targetPos > this.maxScrollY
                    && currentScroll >= (this.maxScrollY - ARTICLE_SCROLL_BOTTOM_BUFFER))) {
                if (currentScroll !== this.targetPos) {
                    this[SET_SECTION_POINTER_VISIBLE](false);
                }
                return true;
            }

            return false;
        },
        _positionSanityCheck() {
            // CORE-8303: On some browsers (Desktop Windows Chrome, slower mobile devices), the
            // scroll position might not be at the correct location during "Back" navigation
            // when trying to restore prior manual scroll positioning.
            // This is a confirmation step to ensure that the current position matches up to the
            // targeted position, only if target does not exceed max scroll height (i.e.; topic is
            // already at the bottom of the page, so cannot be scrolled further).
            const currentScroll = this.scrollerEl.pageYOffset | this.scrollerEl.scrollTop;

            const scrollDiff = Math.abs(currentScroll - this.targetPos);

            if (scrollDiff > 0
                && this.targetPos <= this.maxScrollY) {
                this.scrollerEl.scroll({ top: this.targetPos });
            }
        },
        _processDrugDxyScrollTarget() {
            const dxyTargetEl = document.querySelector(`#${this.targetHash}`);
            if (dxyTargetEl) {
                // Perform adjustments to brower native scroll positioning
                const currentScroll = this.scrollerEl.pageYOffset | this.scrollerEl.scrollTop;
                const rectTop = dxyTargetEl.getBoundingClientRect().top;
                let scrollToPos, headerOffset;

                if (this.isTabletDevice && isOrientationLandscape()) {
                    scrollToPos = (rectTop + currentScroll) - TABLET_LANDSCAPE_DRUG_DXY_SCROLL_OFFSET_PX;
                }
                else {
                    const dxyPosOffsetPx = MOBILE_DESKTOP_DRUG_DXY_SCROLL_OFFSET_PX;
                    headerOffset = MOBILE_HEADER_OFFSET_PX;
                    if (this.isDesktopView) {
                        headerOffset = this.isFixedToolbar
                            ? DESKTOP_FIXED_HEADER_HEIGHT
                            : DESKTOP_UNFIXED_HEADER_HEIGHT;
                    }

                    scrollToPos = (rectTop + currentScroll) - headerOffset - dxyPosOffsetPx;
                }

                setTimeout(() => {
                    this.scrollerEl.scroll({ top: scrollToPos });
                }, 0);
            }
        }
    },
    render: () => null
};
</script>
