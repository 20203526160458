<template>
  <div :id="uuid"
       class="utd-tabs__container">
    <div class="tab-bar"
         :class="{'nav-buttons-enabled': enableCarousel }">
      <button v-if="enableCarousel"
              aria-hidden="true"
              tabindex="-1"
              class="chevron left"
              :class="{'left-scroll-visible': leftNavButtonShown }"
              @click.prevent="scrollDirection(-1)" />
      <div class="wk-tabs"
           :class="tabStyle"
           :aria-label="$attrs['tabbar-label']"
           :aria-labelledby="$attrs['tabbar-label-id']"
           role="tablist">
        <!-- eslint-disable vue/no-v-html -->
        <button v-for="(tab, index) in tabs"
                v-show="!tab.isHidden"
                :id="tab.tabId || `${uuid}-tab-${index}`"
                :key="tab.tabId || `${uuid}-tab-${index}`"
                role="tab"
                :aria-controls="`${uuid}-panel-${index}`"
                :aria-selected="tab.isActive ? 'true' : 'false'"
                :tabindex="tab.isActive ? '0' : '-1'"
                class="wk-tab wk-has-inner-content ds1-utd-size-2"
                :class="{ 'wk-is-active': tab.isActive ,
                          'disable-tabs': disableTabs}"
                :aria-disabled="disableTabs"
                @click="selectTab(index, $event)"
                @keydown="evokeFocusKeypress(index, $event)"
                @mouseover="hoverTab(index, $event)"
                @mouseout="unhoverTab(index)">
          <span v-html="tab.title" />
        </button>
        <div aria-hidden="true"
             class="wk-tabs-underline wk-tabs-underline-hovered"
             :style="defaultHoverUnderlineStyles" />
        <div aria-hidden="true"
             class="wk-tabs-underline"
             :style="defaultSelectedUnderlineStyles" />
      </div>
      <button v-if="enableCarousel"
              aria-hidden="true"
              tabindex="-1"
              class="chevron right"
              :class="{'right-scroll-visible': rightNavButtonShown }"
              @click.prevent="scrollDirection(1)"
              @mousedown.stop />
    </div>
    <slot />
  </div>
</template>

<script>
import { provide } from 'vue';
import PubSub from '_acaSrc/utility/PubSub';
import { getWindow } from '_acaSrc/utility/DOM';
import { C_TABS, C_KEYCODES } from '_acaSrc/utility/constants';
import { onScrollEndEvent } from '_acaSrc/utility/Events';
import { throttleBounce } from '_acaSrc/utility/timers';

export default {
    props: {
        activeTab: {
            type: Number,
            default: 0
        },
        tabStyle: {
            type: String,
            default: 'wk-tabs-default'
        },
        disableTabs: {
            type: Boolean,
            default: false,
            required: false
        },
        enableCarousel: {
            type: Boolean,
            default: false
        },
        ignoreCarouselClick: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'selectedTab' ],
    data() {
        return {
            tabs: [],
            activeIndex: 0,
            curVisibleTabs: {
                left: 0,
                right: 0
            },
            defaultHoverUnderlineStyles: {},
            defaultSelectedUnderlineStyles: {},
            leftNavButtonShown: false,
            rightNavButtonShown: false,
            navButtonSize: 0,
            inDebouncePeriod: false,
            initialTabSelect: true,
            resizeDebounce: null
        };
    },
    watch: {
        activeTab(newVal, oldVal) {
            if (this.activeIndex !== newVal && newVal !== oldVal) {
                this.selectTab(newVal);
            }
        },
        tabs(newVal) {
            if (newVal && newVal.length) {
                this.$nextTick(() => this.selectTab(this.activeTab));
            }
        },
        tabStyle(newVal) {
            if (newVal === 'wk-tabs-default') {
                this.$nextTick(this.updateTabLabelDimensions);
            }
        },
        enableCarousel(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$nextTick(() => {
                    this.setNavButtonWidths();
                    this.checkNavButtonVisibility();
                    this.updateTabLabelDimensions();
                });
            }
        }
    },
    created() {
        provide('addTab', this.addTab);
        this.activeIndex = this.activeTab;
    },
    mounted() {
        this.$nextTick(this.updateTabLabelDimensions);
        new PubSub().subscribe('wkutd.utd-tabs-update-label-dimensions',
            this.updateTabLabelDimensions);

        if (this.enableCarousel) {
            this.$nextTick(() => {
                this.setNavButtonWidths();
                this.checkNavButtonVisibility();
            });
        }

        if (this.enableCarousel) {
            this.resizeDebounce = throttleBounce(
                () => this.resizeCheck(),
                'tabbar-carousel-resize',
                C_TABS.RESIZE_RECHECK_THROTTLEBOUNCE_DELAY_MS
            );
            this.resizeListener
            = this.setResizeListener(getWindow(), this.resizeDebounce);
        }
        this.selectTab(this.activeIndex);
    },
    beforeUnmount() {
        this.clearResizeListener(this.resizeListener);
        new PubSub().unsubscribe('wkutd.utd-tabs-update-label-dimensions',
            this.updateTabLabelDimensions);
    },
    methods: {
        addTab(tab) {
            this.tabs.push(tab);
        },
        /**
         * Returns the offsetHeight of the element. Used to facilitate unit testing.
         * @param { Element } element The element to check the offsetHeight of
         */
        getOffsetHeight(element) {
            return element.offsetHeight;
        },
        /**
         * Returns the offsetWidth of the element. Should only be used with the
         * tab bar element, in order to best facilitate unit testing.
         * @param { Element } tabBarElement
         * The tab bar element to check the offsetWidth of
         */
        getTabBarOffsetWidth(tabBarElement) {
            return tabBarElement.offsetWidth;
        },
        /**
         * Returns the offsetWidth of the element. Should only be used
         * with a tab element, in order to best facilitate unit testing.
         * @param { Element } tabElement
         * The tab element to check the offsetWidth of
         */
        getTabOffsetWidth(tabElement) {
            return tabElement.offsetWidth;
        },
        /**
         * Returns the scrollLeft of the element. Used to facilitate unit testing.
         * @param { Element } element The element to check the scrollLeft of
         */
        getScrollLeft(element) {
            return element.scrollLeft;
        },
        /**
         * Adds the provided value to scrollLeft of the provided element.
         * This method is used to facilitate unit testing.
         * @param { Element } element The element to add to the scrollLeft of
         * @param { number } val The amount of scrollLeft to add
         */
        addScrollLeft(element, val) {
            element.scrollLeft += val;
        },
        /**
         * Returns the scrollWidth of the element. Used to facilitate unit testing.
         * @param { Element } element The element to check the scrollWidth of
         */
        getScrollWidth(element) {
            return element.scrollWidth;
        },
        /**
         * Returns the offsetLeft of the element. Used to facilitate unit testing.
         * @param { Element } element The element to check the offsetLeft of
         */
        getOffsetLeft(element) {
            return element.offsetLeft;
        },
        /**
         * Checks to see whether or not the root DOM node and querySelector is available.
         *
         * The DOM node is not always available as the page is building, so this check
         * helps avoid using selectors before appropriate.
         * @returns { Boolean } Whether or not the root DOM node/querySelector can be used
         */
        isElementAvailable() {
            return !!(this.$el && this.$el.querySelectorAll);
        },
        /**
         * The function run when the page resizes. Should only be called through a
         * throttle/debounce function to avoid excessive calculations.
         */
        resizeCheck() {
            this.updateTabLabelDimensions();
            if (this.enableCarousel) {
                this.checkNavButtonVisibility();
                this.setNavButtonWidths();
            }
        },
        /**
         * Returns the tab bar and tab if they're found, or undefined otherwise
         *
         * @return { {
         *  tabBar: Element,
         *  tab: Element
         * }} An object containing the tab bar and tab elements or undefined
         */
        getTabBarAndTabs(tabNum) {
            if (!this.isElementAvailable()) {
                return;
            }

            const tabBar = this.$el.querySelector('.wk-tabs');
            const tabs = this.$el.querySelectorAll('.wk-tab');

            return tabBar && tabs && tabs[tabNum] && { tabBar, tab: tabs[tabNum] };
        },
        /**
         * Checks to see if the left nav button should be visible by confirming
         * whether or not the tab bar has scrolled to the right at all.
         *
         * Please call checkNavButtonVisibility instead of this function directly.
         *
         * @returns { Boolean } Whether or not the left nav button should be visible
         */
        _shouldLeftNavButtonBeVisible() {
            const tabBar = this.$el.querySelector('.wk-tabs');
            return tabBar && this.getScrollLeft(tabBar) > C_TABS.WIDTH_BOUNDS_RANGE_PX;
        },
        /**
         * Checks to see if the right nav button should be visible by finding if the
         * tab bar has any overflow, or if the tab bar is scrolled all the way to
         * the right (within a small tolerance).
         *
         * Please call checkNavButtonVisibility instead of this function directly.
         *
         * @returns { Boolean } Whether or not the right nav button should be visible
         */
        _shouldRightNavButtonBeVisible() {
            const tabBar = this.$el.querySelector('.wk-tabs');

            if (!tabBar) {
                return false;
            }

            // Total tab bar size that's visible (as if the button wasn't there)
            const offsetWidth = this.getTabBarOffsetWidth(tabBar);
            // Total size of tab bar (including what's not visible)
            const scrollWidth = this.getScrollWidth(tabBar);
            // The max amount you can scroll before the very right edge is in view
            const maxScrollLeft = Math.trunc
                ? Math.trunc(scrollWidth - offsetWidth)
                : scrollWidth - offsetWidth; // IE11 support
            // Below checks if you've hit max scroll within a tolerance (needed due to bold)
            const hitMaxScrollWithinTolerance
                = this.getScrollLeft(tabBar) < (maxScrollLeft + C_TABS.WIDTH_BOUNDS_RANGE_PX)
                && this.getScrollLeft(tabBar) > (maxScrollLeft - C_TABS.WIDTH_BOUNDS_RANGE_PX);

            /* If your total tab bar size is larger than the space it can be fit in,
                and you haven't scrolled to max within a tolerance, display right nav button */
            return scrollWidth > offsetWidth && !hitMaxScrollWithinTolerance;
        },
        /**
         * Checks whether or not the nav buttons need to be visible, and sets them
         * to the correct state based on the results of the check.
         */
        checkNavButtonVisibility() {
            if (!this.enableCarousel || !this.isElementAvailable()) {
                return;
            }

            this.leftNavButtonShown = this._shouldLeftNavButtonBeVisible();
            this.rightNavButtonShown = this._shouldRightNavButtonBeVisible();
        },
        /**
         * Sets the width of the nav buttons to be equal to their height
         */
        setNavButtonWidths() {
            const leftNavButton = this.$el.querySelector('.chevron.left');
            const rightNavButton = this.$el.querySelector('.chevron.right');

            if (leftNavButton && rightNavButton) {
                const curNavButtonSize = this.getOffsetHeight(rightNavButton)
                                      || this.getOffsetHeight(leftNavButton);
                this.navButtonSize = curNavButtonSize;
                leftNavButton.style.width = `${this.navButtonSize}px`;
                rightNavButton.style.width = `${this.navButtonSize}px`;
            }
        },
        /**
         * Checks how much the provided tab is clipped on both its left and right,
         * and returns this amount in pixels.
         *
         * Note that the index entered has to exist in the tab bar. If you cannot
         * be certain it does, run the tab index through validateTab() before
         * entering it into this function.
         *
         * @param { number } tabNum
         * The index of the tab to check the overlap of (assumes the tab exists)
         * @returns { {
         *  tabLeftOverAmount: number
         *  tabRightOverAmount: number
         * } } How much of the tab is clipped on the right and left in pixels
         */
        calculateTabOverlap(tabNum) {
            const tabBarElements = this.getTabBarAndTabs(tabNum);

            if (!tabBarElements) {
                return {
                    tabLeftOverAmount: 0,
                    tabRightOverAmount: 0
                };
            }

            const tab = tabBarElements.tab;
            const tabBar = tabBarElements.tabBar;

            // The tab's left edge placement in tab bar overall (with overlap)
            const tabLeftOverallPlacement = this.getOffsetLeft(tab);
            // The tab's right edge placement in tab bar overall (with overlap)
            const tabRightOverallPlacement
                = tabLeftOverallPlacement + this.getTabOffsetWidth(tab);
            // Pixels to the right of tab bar needed to get current in-view edge of tab bar
            const curTabBarRight
                = this.getTabBarOffsetWidth(tabBar) + this.getScrollLeft(tabBar);
            // The minimum overall left edge that's possible and still in view
            const curInViewLeftEdgeMin = this.leftNavButtonShown
                ? this.getScrollLeft(tabBar) + this.navButtonSize
                : this.getScrollLeft(tabBar);
            // The maximum overall right edge that's possible and still in view
            const curInViewRightEdgeMax = this.rightNavButtonShown
                ? curTabBarRight - this.navButtonSize : curTabBarRight;

            // How many pixels are 'clipped' (in the overflow) on the left for this tab
            const tabLeftOverAmount = curInViewLeftEdgeMin - tabLeftOverallPlacement;
            // How many pixels are 'clipped' (in the overflow) on the right for this tab
            const tabRightOverAmount = tabRightOverallPlacement - curInViewRightEdgeMax;

            return {
                tabLeftOverAmount, tabRightOverAmount
            };
        },
        /**
         * Checks to see if there needs to be an adjustment to how far the tab bar scrolls to the
         * right when pulling the next tab in focus. Specifically, it checks to see if there's a
         * tab wider than the visible area, so that it scrolls to the beginning of the tab text,
         * rather than the very end.
         *
         * @param { {
         *  left: number
         *  right: number
         * } } tabOverlaps The tab overlaps returned by a call to calculateTabOverlap
         * @param { Element } tabBar The tab bar element
         * @param { Element } tab The element for the tab in question
         * @returns { number } The amount to scroll
         */
        checkIfMoveRightNeedsAdjustment(tabOverlaps, tabBar, tab) {
            let scrollAmount = tabOverlaps.tabRightOverAmount;
            const assumedVisibleArea = this.getTabBarOffsetWidth(tabBar) - (2 * this.navButtonSize);
            const tabWidth = this.getTabOffsetWidth(tab);

            if (tabWidth <= assumedVisibleArea) {
                return scrollAmount;
            }

            const newScrollAmount
                = tabOverlaps.tabRightOverAmount
                - (tabWidth - assumedVisibleArea);

            if (newScrollAmount > C_TABS.WIDTH_BOUNDS_RANGE_PX) {
                scrollAmount = newScrollAmount;
            }

            return scrollAmount;
        },
        /**
         * Moves the provided tab into view by scrolling towards it. If provided
         * a directional bias, will guarantee to move only in the directions
         * marked as true. By default it can move in either direction.
         *
         * @param { number } tabNum The index of the tab to scroll into view
         * @param { {
         *  left: Boolean
         *  right: Boolean
         * } } bias Whether or not there's a bias to move in a specific direction
         * @param { () => {} } [customCheck] An optional function that's run on scroll-end
         */
        moveTabInView(tabNum, bias = { left: true, right: true }, customCheck) {
            const tabBarElements = this.getTabBarAndTabs(tabNum);

            if (!tabBarElements) {
                return;
            }

            const validatedTab = this.validateTab(tabNum);
            const tabOverlaps = this.calculateTabOverlap(validatedTab);

            if (tabOverlaps.tabRightOverAmount > 0 && bias.right) {
                const scrollAmount = this.checkIfMoveRightNeedsAdjustment(
                    tabOverlaps,
                    tabBarElements.tabBar,
                    tabBarElements.tab
                );
                this.addScrollLeft(tabBarElements.tabBar, scrollAmount);
                this.curVisibleTabs.right = validatedTab;
                onScrollEndEvent(tabBarElements.tabBar, () => {
                    customCheck && customCheck();
                    this.checkNavButtonVisibility();
                });
            }
            if (tabOverlaps.tabLeftOverAmount > 0 && bias.left) {
                this.addScrollLeft(tabBarElements.tabBar, -(tabOverlaps.tabLeftOverAmount));
                this.curVisibleTabs.left = validatedTab;
                onScrollEndEvent(tabBarElements.tabBar, () => {
                    customCheck && customCheck();
                    this.checkNavButtonVisibility();
                });
            }
        },
        /**
         * Focuses on the tab found at the provided index if found,
         * and attempts to pull the tab into view
         *
         * @param { number } index The index of the tab to pull into view & focus
         */
        moveFocus(index) {
            const allTabs = this.$el.querySelectorAll('.wk-tab');
            if (!allTabs || !allTabs[index]) {
                return;
            }

            allTabs[index].focus();
            this.$nextTick(() => {
                this.moveTabInView(index);
            });
        },
        /**
         * Called whenever a tab is currently in focus, and a key is pressed. Handles the
         * functionality for the left, right, home, and end tab bar navigation keys.
         *
         * @param { number } index The index of the focused-tab where the keypress occured
         * @param { object } event The keypress event object
         */
        evokeFocusKeypress(index, event) {
            let moveToIndex = 0;
            switch (event.keyCode) {
            case C_KEYCODES.LEFT_ARROW:
                event.preventDefault();
                moveToIndex = index - 1 < 0 ? this.tabs.length - 1 : index - 1;
                break;
            case C_KEYCODES.RIGHT_ARROW:
                event.preventDefault();
                moveToIndex = index + 1 >= this.tabs.length ? 0 : index + 1;
                break;
            case C_KEYCODES.HOME_KEYCODE:
                event.preventDefault();
                moveToIndex = 0;
                break;
            case C_KEYCODES.END_KEYCODE:
                event.preventDefault();
                moveToIndex = this.tabs.length - 1;
                break;
            default:
                return;
            }
            this.moveFocus(moveToIndex);
        },
        /**
         * Finds the index of the tabs that are clipped by the
         * overflow on both the left and right edges of the tab bar
         *
         * @returns { {
         *  left: number
         *  right: number
         * } } The index of the tabs partially visible on the left and
         * right edges. If there is no overlap on one side, it'll return
         * the index of the furthest tab on that side.
         */
        findTabEdges() {
            const tabEdges = {
                left: this.curVisibleTabs.left,
                right: this.curVisibleTabs.right = this.tabs.length - 1
            };

            const allTabs = this.$el.querySelectorAll('.wk-tab');

            if (!allTabs) {
                return tabEdges;
            }

            for (let i = 0; i < allTabs.length; i++) {
                const curTabBounds = this.calculateTabOverlap(i);

                if (curTabBounds.tabRightOverAmount > C_TABS.WIDTH_BOUNDS_RANGE_PX) {
                    tabEdges.right = i;
                    this.curVisibleTabs.right = i - 1;
                    break;
                }
            }

            for (let i = tabEdges.right; i >= 0; i--) {
                const curTabBounds = this.calculateTabOverlap(i);

                if (curTabBounds.tabLeftOverAmount > C_TABS.WIDTH_BOUNDS_RANGE_PX) {
                    tabEdges.left = i;
                    this.curVisibleTabs.left = i + 1;
                    break;
                }
            }

            return tabEdges;
        },
        /**
         * Scrolls to pull the next clipped tab into focus, if provided the
         * number of tabs it should move over, and the direction it should move in
         *
         * @param { number } val
         * The direction (right is positive, left is negative),
         * and number (integer) of tabs you want to scroll in that direction
         */
        scrollDirection(val) {
            this.checkNavButtonVisibility(); // Backup check if it doesn't scroll
            const moveLeft = { left: true };
            const moveRight = { right: true };

            this.findTabEdges();

            const intendedTab = val > 0
                ? this.curVisibleTabs.right + val : this.curVisibleTabs.left + val;

            this.moveTabInView(intendedTab, val > 0 ? moveRight : moveLeft);
        },
        validateTab(tab) {
            if (tab < 0) {
                tab = 0;
            }
            else if (tab > this.tabs.length - 1) {
                tab = this.tabs.length - 1;
            }
            return tab;
        },
        /**
         * Contains the logic for moving tabs into view when the carousel
         * functionality has been enabled
         *
         * @param { () => {} } selectedTabCheck
         * The function needed to double-check tab dimensions and underlines
         */
        _selectTabCarouselHelper(selectedTabCheck) {
            this.$nextTick(() => {
                this.checkNavButtonVisibility(); // Backup check if it doesn't scroll
                this.moveTabInView(this.activeIndex, undefined, selectedTabCheck);

                if (this.initialTabSelect) { // Extra check for custom starting tabs
                    this.initialTabSelect = false;
                    setTimeout(() => {
                        this.moveTabInView(this.activeIndex);
                        selectedTabCheck();
                        this.checkNavButtonVisibility();
                    }, C_TABS.INITIAL_TAB_RECHECK_DELAY_MS);
                }
            });
        },
        _shouldIgnoreCarouselClick(event) {
            return event
                && event.type === 'click'
                && this.ignoreCarouselClick;
        },
        selectTab(tabIndex, event) {
            if (this.disableTabs && !this.initialTabSelect) {
                return;
            }
            this.activeIndex = this.validateTab(tabIndex);

            const selectedTabCheck = () => {
                this.$nextTick(() => {
                    this.updateTabLabelDimensions();
                    this.setSelectedTabUnderline(!!event);
                });
            };

            this.tabs.forEach((tab, index) => {
                tab.isActive = (index === this.activeIndex);
            });

            if (this.enableCarousel && !this._shouldIgnoreCarouselClick(event)) {
                this._selectTabCarouselHelper(selectedTabCheck);
            }
            else if (this.initialTabSelect) { // Extra check for custom starting tabs
                this.initialTabSelect = false;
                setTimeout(() => {
                    selectedTabCheck();
                }, C_TABS.INITIAL_TAB_RECHECK_DELAY_MS);
            }

            selectedTabCheck();
            this.$emit('selectedTab', this.activeIndex, event);
        },
        hoverTab(tabIndex, isMouseEvent) {
            this.defaultHoverUnderlineStyles = {
                transform: `translate(${this.tabs[tabIndex].left}px, ${this.tabs[tabIndex].top}px)`,
                width: `${this.tabs[tabIndex].width}px`,
                visibility: 'visible'
            };
            if (!isMouseEvent) {
                this.defaultHoverUnderlineStyles.transition = 'none';
            }
        },
        unhoverTab() {
            this.defaultHoverUnderlineStyles = this.defaultSelectedUnderlineStyles;
        },
        setSelectedTabUnderline(isClick) {
            if (!this.tabs) {
                return;
            }
            const x = this.tabs[this.activeIndex].left;
            const y = this.tabs[this.activeIndex].top;
            this.defaultSelectedUnderlineStyles = {
                transform: `translate(${x}px, ${y}px)`,
                width: `${this.tabs[this.activeIndex].width}px`,
                visibility: 'visible'
            };
            if (!isClick) {
                this.defaultSelectedUnderlineStyles.transition = 'none';
            }
            this.hoverTab(this.activeIndex, isClick);
        },
        updateTabLabelDimensions() {
            if (!this.isElementAvailable()) {
                return;
            }
            const tabsHeight = this.$el.querySelector('.wk-tabs').offsetHeight - 1;
            this.$el.querySelectorAll('.wk-tab').forEach((tabEl, tabIdx) => {
                this.tabs[tabIdx].left = tabEl.offsetLeft + tabEl.firstChild.offsetLeft;
                this.tabs[tabIdx].width = tabEl.firstChild.offsetWidth;
                this.tabs[tabIdx].top = (tabEl.offsetTop + tabEl.offsetHeight) - tabsHeight;
            });

            this.setSelectedTabUnderline();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DEFAULT-TAB-ACTIVE-BG-COLOR: @WKCE-GREEN;
@DEFAULT-TAB-HOVER-BG-COLOR: @WKCE-GREEN-TINT2;
@DEFAULT-TAB-INACTIVE-TEXT-COLOR: @WKCE-GRAY-SHADE1;

/* Fix for underline color changing on active tab when hovering inactive tabs */
.wk-js2 .wk-tabs-default.wk-tabs .wk-tab:hover:not(.wk-is-disabled):not(.wk-is-active) ~ .wk-tabs-underline {
  background-color: @DEFAULT-TAB-ACTIVE-BG-COLOR;
  color: @DEFAULT-TAB-INACTIVE-TEXT-COLOR;

  &.wk-tabs-underline-hovered {
    background-color: @DEFAULT-TAB-HOVER-BG-COLOR;
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@DEFAULT-TAB-ACTIVE-BG-COLOR: @WKCE-GREEN;
@DEFAULT-TAB-ACTIVE-TEXT-COLOR: @WKCE-GRAY-SHADE2;
@DEFAULT-TAB-INACTIVE-TEXT-COLOR: @WKCE-GRAY-SHADE1;

@DEFAULT-NAV-BUTTON-BG-COLOR: @WKCE-WHITE;
@DEFAULT-NAV-BUTTON-HOVER-BG-COLOR: @WKCE-GRAY-TINT4;
@DEFAULT-NAV-BUTTON-OPACITY-TRANSITION-DURATION: @DS1-UTD-TRANSITION-DURATION;
@DEFAULT-NAV-BUTTON-BACKGROUND-TRANSITION-DURATION: calc(@DS1-UTD-TRANSITION-DURATION/2);


.wk-js2 .wk-tabs .wk-is-active ~ .wk-tabs-underline {
  pointer-events: none;
  background-color: @DEFAULT-TAB-ACTIVE-BG-COLOR;
  height: 3px;

  &:not(.wk-tabs-underline-hovered) {
    height: 4px;
  }
}

.utd-tabs__container.with-divider {
  .wk-tabs {
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
  }
}

.tab-bar {
  position: relative;
  display: flex;

  &.nav-buttons-enabled .wk-tabs {
    flex-wrap: nowrap;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  > button.chevron {
    position: absolute;
    display: block;
    transition:
      opacity @DEFAULT-NAV-BUTTON-OPACITY-TRANSITION-DURATION ease-out,
      background-color @DEFAULT-NAV-BUTTON-BACKGROUND-TRANSITION-DURATION ease-out;
    opacity: 0;
    z-index: 0;
    border: none;
    background-color: @DEFAULT-NAV-BUTTON-BG-COLOR;
    height: 100%;
    pointer-events: none;

    &::before {
      width: 8px;
      height: 8px;
      margin-bottom: 2px;
      border-width: 1.5px 1.5px 0 0;
    }

    &.left-scroll-visible,
    &.right-scroll-visible {
      position: absolute;
      transition:
        opacity @DEFAULT-NAV-BUTTON-OPACITY-TRANSITION-DURATION ease-in,
        background-color @DEFAULT-NAV-BUTTON-BACKGROUND-TRANSITION-DURATION ease-out;
      z-index: 1;
      opacity: 1;
      cursor: pointer;
      pointer-events: auto;

      &:hover {
        background-color: @DEFAULT-NAV-BUTTON-HOVER-BG-COLOR;
      }
    }

    &.left {
      left: 0;

      &::before {
        margin-left: 4px;
      }
    }

    &.right {
      right: 0;

      &::before {
        margin-right: 4px;
      }
    }
  }
}

.utd-tabs__container .wk-tabs {
  margin: 0;
  flex-wrap: wrap;

  &::after {
    width: auto;
    flex-grow: 1;
  }

  .wk-tab {
    line-height: 18px;
    padding: 0.625rem 1rem;
    margin: 0;
    font-weight: 500;
    display: inline-block;
    outline: none;
    border-top: 0.0625rem solid transparent;
    border-left: 0.0625rem solid transparent;
    border-right: 0.0625rem solid transparent;
    color: @DEFAULT-TAB-INACTIVE-TEXT-COLOR;
    flex: 0;

    &:hover:not(.wk-is-active) {
      color: @DEFAULT-TAB-INACTIVE-TEXT-COLOR;
      text-decoration: none;
      background-color: transparent;

      > span {
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        transition: font-weight 0s;
      }
    }

    &.wk-is-active {
      cursor: initial;
      color: @DEFAULT-TAB-ACTIVE-TEXT-COLOR;
      font-weight: bold;
      letter-spacing: -0.5px;

      &:focus:not(:focus-visible) {
        outline: none;
      }

      &:hover {
        color: @DS1-UTD-GRAY-BUTTON-TEXT;
      }
    }

    &:focus-visible {
      outline: 2px solid black;
      border-radius: 4px 4px 0 0;
      outline-offset: -2px;
    }
  }

  &.wk-tabs-classic {
    &::before {
      border-bottom: 0.0625rem solid @DS1-UTD-GRAY-BORDER-COLOR;
    }

    .wk-tab {
      &:hover:not(.wk-is-active) {
        text-decoration: underline;
        background-color: @DS1-UTD-PRIMARY-BG-COLOR;
      }

      &.wk-is-active {
        background-color: @DS1-UTD-PRIMARY-BG-COLOR;
        border-bottom: 0.0625rem solid @DS1-UTD-PRIMARY-BG-COLOR;
      }
    }

    .wk-tabs-underline {
      display: none;
    }
  }

  .search-facet &::before {
    width: 0;
  }

  .InternetExplorer & {
    .ds1-pl-1();
    display: inline-block;
  }
}

.disable-tabs {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
</style>
