<template>
  <div class="utd-push-notification ds1-pa-2">
    <div class="utd-push-notification-close-btn wkce-icon-close"
         :class="{'wkce-icon-filled-close': isHovering}"
         @click="hide()"
         @mouseover="isHovering = true"
         @mouseout="isHovering = false" />
    <div class="utd-push-notification-header ds1-mb-2 ds1-pr-4"><slot name="header" /></div>
    <div class="utd-push-notification-body"><slot /></div>
  </div>
</template>

<script>

export default {
    emits: [ 'hide-notification' ],
    data() {
        return {
            isHovering: false
        };
    },
    methods: {
        hide() {
            this.$emit('hide-notification');
        }
    }
};
</script>
<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

/* Refer this link for notification DXG component styles:
    https://jumpstart.wolterskluwer.io/?path=/docs/components-notification-html-with-classes--inline-default
*/

@PUSH-NOTIFICATION-BG-COLOR: @WKCE-GRAY-TINT5;
@PUSH-NOTIFICATION-BODY-TEXT-COLOR: @WKCE-GRAY;
@PUSH-NOTIFICATION-HEADER-TEXT-COLOR: @WKCE-GRAY-SHADE1;

.utd-push-notification {
  border: 1px solid @PUSH-NOTIFICATION-BG-COLOR;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background: @DS1-UTD-PRIMARY-BG-COLOR;
  font-size: 16px;
  color: @PUSH-NOTIFICATION-BODY-TEXT-COLOR;
}

.utd-push-notification-close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}

.utd-push-notification-header {
  font-weight: bold;
  line-height: 1.625;
  color: @PUSH-NOTIFICATION-HEADER-TEXT-COLOR;
}

.utd-push-notification-body {
  line-height: 1;

  * {
    vertical-align: middle;
  }
}

.med-topic-formulink-push-notification {
  position: fixed;
  top: 193px;
  right: 1px;

  .fixedToolbar & {
    top: 131px;
  }

  .wk-icon-info {
    color: @DS1-UTD-INFO;
    padding-right: 5px;
    margin: 0;
    font-size: 14px;
  }
}
</style>
