import { setQueryParamValue } from '_acaSrc/utility/http';
import { getWindow, injectCss } from '_acaSrc/utility/DOM';
import { C_REST_URL } from '_acaSrc/utility/constants';
import Logger from '_acaSrc/utility/Logger';

export function makeThumbnailUrl(imageKey) {
    if (imageKey) {
        return `${C_REST_URL.GRAPHIC_IMAGE_REST_PREFIX_URL}${imageKey}/thumbnail.png`;
    }
    return '';
}

export function makeGraphicUrl(graphic) {
    if (graphic && graphic.imageKey) {
        const imageLangPath = graphic.languageCode
            ? `${graphic.languageCode}/`
            : '';
        const source = graphic.source
            ? `&source=${graphic.source}`
            : '';
        const topicKey = graphic.topicKey
            ? `&topicKey=${graphic.topicKey}`
            : '';

        return `/contents/${imageLangPath}image?imageKey=${graphic.imageKey}${source}${topicKey}`;
    }
    return '';
}

export function imageKeyToId(imageKey) {
    if (!imageKey || !imageKey.replace) {
        return '';
    }
    return `thumbnail-${imageKey.replace('/', '-')}`;
}

export function idFromImageKey(imageKey) {
    return imageKey.match(/\d*$/)[0];
}

export function idFromTopicKey(topicKey) {
    return topicKey.match(/\d*$/)[0];
}

export function getPrintAndPowerPointUrls(data) {
    const url = data.url ? data.url : getWindow().location.href;
    const Urls = {};

    const paramPos = url.search(/\?/);
    const urlParams = (paramPos === -1) ? '' : url.substring(paramPos);
    const servicePrefix = '/services/app';

    if (data.language === 'en-US') {
        Urls.printUrl = `/contents/image/print${urlParams}`;
    }
    else {
        Urls.printUrl = `/contents/${data.language}/image/print${urlParams}`;
    }

    Urls.powerPointUrl = `${servicePrefix}/contents/graphic/download${urlParams}`;

    Urls.powerPointUrl = Urls.powerPointUrl.replace('zh-Hans/', '');
    Urls.powerPointUrl = setQueryParamValue(Urls.powerPointUrl, 'view', 'powerpoint');

    return Urls;
}

export const graphicDataForThumbnail = graphic => {
    return {
        ...(graphic.graphicInfo),
        ...graphic,
        title: graphic.graphicInfo && graphic.graphicInfo.displayName
            || graphic.title
    };
};

export const injectGraphicsStyles = (graphicsCssObject, resolverFn) => {
    if (!graphicsCssObject || !graphicsCssObject.id || !graphicsCssObject.url) {
        Logger.error(
            // eslint-disable-next-line max-len
            'injectGraphicsStyles() A valid graphicCssObject is required, no graphic style sheet is loaded!'
        );
        return;
    }

    if (graphicsCssObject.cssLoaded) {
        return; // Css already loaded
    }

    injectCss(graphicsCssObject, resolverFn);
};
