<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="my-most-viewed-tab-content-wrapper tabcontent" data-autotest="myuptodateMostViewed">
    <span v-if="mostViewed.isLoadingResults"
          class="loading-message">
      {{ $t("MYUPTODATE.PLEASE_WAIT") }}
    </span>
    <span v-if="mostViewed.error"
          class="error">
      {{ $t("MYUPTODATE.MOST_VIEWED_ERROR_LOADING") }}
    </span>
    <div v-if="!mostViewed.isLoadingResults && !mostViewed.error" class="most-viewed-data-wrapper">
      <ul v-show="mostViewedData.length" class="my-utd-tab-list">
        <li v-for="(item, index) in mostViewedData"
            :key="index">
          <a :href="setQueryParamValue(item.url, 'source', `mostViewed${source}`)"
             :class="getTabItemClasses(item)"
             @click="handleLinkClick($event, item.contentType)"
             v-html="item.title" />
        </li>
      </ul>
      <div v-show="mostViewedData.length"
           class="most-viewed-description">
        {{ $t("MYUPTODATE.MOST_VIEWED_MESSAGE_TOP") }}
      </div>
      <div v-if="!mostViewedData.length"
           class="no-content">
        {{ $t("MYUPTODATE.MOST_VIEWED_EMPTY") }}
      </div>
    </div>
    <graphic-hover-tooltip v-if="mostViewedData.length && !mostViewed.isLoadingResults"
                           :hover-selector="graphicHoverTooltipSelector"
                           :off-topic-variant="true" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { getTabItemClasses } from './myuptodate';
import GraphicHoverTooltip from '_acaSrc/components/contents/topic/GraphicHoverTooltip.vue';

export default {
    components: {
        GraphicHoverTooltip
    },
    props: {
        source: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            setQueryParamValue
        };
    },
    computed: {
        ...mapGetters('profile', [
            'mostViewed',
            'mostViewedData'
        ]),
        graphicHoverTooltipSelector() {
            return `.my-uptodate-wrapper${
                this.source} .my-most-viewed-tab-content-wrapper a[class*=graphic]`;
        }
    },
    watch: {
        mostViewedData() {
            this.setTabContentScroll();
        }
    },
    mounted() {
        this.scrollListener = this.setScrollListener(this.$el, this.onScrollTabContent);
    },
    beforeUnmount() {
        this.clearScrollListener(this.scrollListener);
    },
    methods: {
        ...mapActions('app', [
            'publish'
        ]),
        ...mapActions('graphic', [
            'handleUseGraphicLink'
        ]),
        getTabItemClasses,
        handleLinkClick(evt, contentType) {
            this.publish({
                eventName: 'wkutd.close-myuptodate-modal'
            });
            if (!contentType || contentType.indexOf('graphic') === -1) {
                return;
            }
            this.handleUseGraphicLink({
                evt,
                options: { showAppearsInTopics: true }
            });
        },
        setTabContentScroll() {
            if (!this.scrollPosition) {
                return;
            }
            setTimeout(() => {
                this.$el.scrollTop = this.scrollPosition;
            }, 0);
        },
        onScrollTabContent() {
            if (this.$el.scrollTop) {
                this.scrollPosition = this.$el.scrollTop;
            }
        }
    }
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.my-most-viewed-tab-content-wrapper {
  .ds1-pt-2();

  .my-uptodate-wrapper_widget & {
    .ds1-pt-1();
  }

  .my-utd-tab-list {
    > li {
      .ds1-mt-0();

      > a {
        .ds1-ph-2();
        .ds1-pv-1();
      }
    }
  }

  .most-viewed-description {
    .ds1-utd-size-1();
    .ds1-pv-3();
    .ds1-pl-1();
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .my-most-viewed-tab-content-wrapper {
      .most-viewed-data-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .most-viewed-description {
          .ds1-pb-1();
          .ds1-pl-3();
        }
      }

      .my-utd-tab-list {
        > li {
          > a {
            .ds1-pr-3();
          }
        }
      }
    }
  }
}
</style>
