<template>
  <utd-modal-dialog ref="modalDialog"
                    :close-on-overlay-click="false"
                    :close-on-escape="false"
                    :hide-header-close="true"
                    modal-classes="next-sign-in-steps-modal"
                    dialog-size="medium">
    <template #header>{{ $t('LOGIN.NEXT_STEPS') }}</template>

    <div v-if="isNextStepsDisabled" class="ds1-utd-js2">
      <a id="nextStepsBackLink" tabindex="0" @click="resetWorkflow">
        <i class="wkce-icon-chevron-left" />{{ $t('BACK') }}
      </a>
      <utd-notification notification-mode="warn"
                        class="next-steps-bcdr">
        <template #title>Warning! UpToDate is having scheduled maintenance</template>
        <ul>
          <li>Sign in with Single Sign-On (SSO) is unavailable due to scheduled maintenance</li>
          <li>Please select from these options</li>
          <ul>
            <li><a id="bcdr-skip-for-now-link" tabindex="0" @click="skipWorkflow">Skip for now</a>
              (allows you to sign in with your enterprise email)
            </li>
            <li><a id="bcdr-back-link" tabindex="0" @click="resetWorkflow">Back</a>
              (allows you to sign in with a different UpToDate account)
            </li>
            <li>Try again later</li>
          </ul>
        </ul>
      </utd-notification>
    </div>
    <div v-else class="next-steps-content">
      <div class="next-steps-disclaimer ds1-utd-size-3">
        <p>
          <span class="ds1-utd-weight-bold">{{ institutionName }}</span>
          has changed how you sign in to UpToDate.
          You will sign in with your email, <br>
          <span class="ds1-utd-weight-bold">{{ userName }}.</span>
        </p>
      </div>
      <div class="next-steps-disclaimer ds1-utd-size-4">
        <p class="ds1-utd-weight-600">
          Please select one of these options for your UpToDate account
        </p>
        <p class="next-steps-subtext">
          (you will only need to do this once)
        </p>
      </div>
      <div class="next-steps-option-card primary-card">
        <div class="option-card__header">
          <h4 class="option-card__header-text">One account: Single Sign-On (SSO)</h4>
          <div class="option-card__sticky-note">
            <span class="option-card__sticky-text">Most common choice</span>
            <i class="wk-icon wkce-icon-users" />
          </div>
        </div>
        <div class="option-card__content">
          <a id="learnMoreOneAccountLink"
             aria-label="info about one account option"
             href="#"
             class="option-card__subtitle"
             @click="openLearnMore({ keepOneAccount: true })">
            Learn more about this option<i class="wk-icon wkce-icon-assignments-matched" />
          </a>
          <ul>
            <li>Your existing "Continuing Medical Education"
              (CME) credits will be in one merged account.</li>
            <li>You will sign in using your organization email, {{ userName }}.</li>
          </ul>
          <div class="option-card__action-area">
            <utd-button id="keep-one-account"
                        :button-size="isMobileOnDesktop ? 'small' : 'large'"
                        button-style="ghost"
                        @click="singleAccountWorkflow()">
              Keep one account
            </utd-button>
          </div>
        </div>
      </div>
      <div class="next-steps-option-card secondary-card">
        <div class="option-card__header">
          <h4 class="ds1-utd-size-5">Two accounts: Existing, plus new SSO</h4>
        </div>
        <div class="option-card__content">
          <a id="learnMoreTwoAccountsLink"
             aria-label="info about two accounts option"
             href="#"
             class="option-card__subtitle"
             @click="openLearnMore({ keepOneAccount: false })">
            Learn more about this option<i class="wk-icon wkce-icon-assignments-matched" />
          </a>
          <ul>
            <li>Your existing "Continuing Medical Education"
              (CME) credits for your new and existing accounts will be kept separate.</li>
            <li>You will need to change your username.</li>
            <li>You will use your username and password to sign in to your
              existing account and your organization email, {{ userName }}, to
              sign in to your new account.
            </li>
          </ul>
          <div class="option-card__action-area">
            <utd-button id="keep-two-accounts"
                        :button-size="isMobileOnDesktop ? 'small' : 'large'"
                        button-style="ghost"
                        @click="separateAccountsWorkflow()">
              Keep two accounts
            </utd-button>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <utd-button id="skip-next-steps"
                  button-style="text"
                  @click="skipWorkflow()">Skip for now</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { BCDR_MESSAGE } from '_acaSrc/utility/constants';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UtdNotification
    },
    props: {
        institutionName: {
            type: String,
            default: ''
        },
        ssoLoginUrl: {
            type: String,
            default: ''
        },
        userName: {
            type: String,
            default: ''
        }
    },
    emits: [
        'open-learn-more-modal',
        'open-two-accounts-modal',
        'navigate-to-sso'
    ],
    data() {
        return {
            BCDR_MESSAGE
        };
    },
    computed: {
        ...mapGetters('device', [
            'isMobileOnDesktop'
        ]),
        ...mapGetters('app', [ 'isDisasterRecovery' ]),
        isNextStepsDisabled() {
            return this.isDisasterRecovery;
        }
    },
    mounted() {
        new PubSub().subscribe('wkutd.closeNextStepsInnerDialog',
            this.closeNextStepsInnerDialog);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.closeNextStepsInnerDialog',
            this.closeNextStepsInnerDialog);
    },
    methods: {
        openModal() {
            this.$refs.modalDialog.open();
        },
        closeModal({ showPasswordInput }) {
            this.$refs.modalDialog.close();
            new PubSub().publish('wkutd.closeNextStepsDialog', { showPasswordInput });
        },
        openLearnMore({ keepOneAccount }) {
            logUiClickEventHelper({ uiElementName: 'nextSteps_helpLink' });
            this.$emit('open-learn-more-modal', { keepOneAccount });
        },
        closeNextStepsInnerDialog() {
            this.openModal();
        },
        singleAccountWorkflow() {
            logUiClickEventHelper({ uiElementName: 'nextSteps_oneAccountBtn' });
            this.closeModal({ showPasswordInput: false });
            this.$emit('navigate-to-sso',
                setQueryParamValue(this.ssoLoginUrl, 'keep_one_account', 'true'));
        },
        separateAccountsWorkflow() {
            logUiClickEventHelper({ uiElementName: 'nextSteps_twoAccountsBtn' });
            this.$emit('open-two-accounts-modal');
        },
        skipWorkflow() {
            logUiClickEventHelper({ uiElementName: 'nextSteps_skipLink' });
            this.closeModal({ showPasswordInput: true });
        },
        resetWorkflow() {
            logUiClickEventHelper({ uiElementName: 'nextSteps_backLink' });
            this.closeModal({ showPasswordInput: false });
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.next-sign-in-steps-modal {
  .utd-modal-content .utd-dialog-frame {
    width: auto;
    max-width: 360px;

    .utd-dialog-content {
      .ds1-pa-2();
    }
  }
}

@media screen and (min-width: 768px) {
  .next-sign-in-steps-modal {
    .utd-modal-content .utd-dialog-frame {
      min-width: 320px;
      max-width: 800px;
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@NEXT-STEPS-PRIMARY-BG-COLOR: @WKCE-BLUE-TINT6;

.next-steps-bcdr {
  .ds1-mb-3();

  ul {
    .ds1-ma-0();
    .ds1-pl-3();
  }
}

.next-steps-content {
  .next-steps-disclaimer {
    .ds1-mb-2();

    p {
      .ds1-ma-0();
    }
  }

  .next-steps-subtext {
    .ds1-utd-size-2();
    color: @DS1-UTD-SUB-TEXT-COLOR;
  }

  .next-steps-option-card {
    .ds1-mb-3();
    border: @DS1-UTD-GRAY-SINGLE-BORDER;

    ul {
      .ds1-pl-2();
    }

    li {
      .ds1-mb-2();
      overflow-wrap: anywhere;
    }

    &.primary-card {
      border-top: @DS1-UTD-PRIMARY-CARD-BORDER;
      background: @NEXT-STEPS-PRIMARY-BG-COLOR;
    }

    &.secondary-card {
      border-top: @DS1-UTD-SECONDARY-CARD-BORDER;
    }
  }

  .primary-card {
    .option-card__header {
      .ds1-pb-0();
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-bottom: 80px;
      position: relative;
    }

    .option-card__header-text {
      .ds1-utd-size-5();
      .ds1-mt-5();
      .ds1-mh-3();
      position: absolute;
    }
  }

  .secondary-card .option-card__header {
    .ds1-pt-2();
    .ds1-ph-3();
    .ds1-pb-1();
  }

  .option-card__header-text {
    .ds1-utd-size-5();
    flex: 1;
  }

  .option-card__action-area {
    display: flex;
    justify-content: flex-end;
  }

  .option-card__content {
    .ds1-pa-3();
    .ds1-pt-0();
  }

  .option-card__sticky-note {
    background: @DS1-UTD-JS2-INACTIVE-HOVER-BG-COLOR;
    border: 1px solid @DS1-UTD-ACCOUNT-BUTTON-DISABLED-BG-COLOR;
    border-right: none;
    border-top: none;
    display: flex;
    justify-content: center;
    height: 29px;
    width: 197px;
  }

  .option-card__sticky-text {
    .ds1-mr-1();
    .ds1-utd-weight-bold();
    font-size: 13px;
    align-self: center;
    color: @DS1-UTD-JS2-LINK-HOVER;
  }

  .wkce-icon-users {
    color: @DS1-SELECTED-BORDER-COLOR;
    align-self: center;
  }

  .wkce-icon-assignments-matched {
    margin-left: 6px;
  }

  .option-card__subtitle {
    .ds1-utd-size-2();

    &:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) {
  .next-steps-content {
    .primary-card,
    .secondary-card {
      .option-card__header {
        .ds1-mb-0();
      }
    }

    .primary-card .option-card__header-text {
      .ds1-ma-0();
      .ds1-pt-2();
      .ds1-ph-3();
      .ds1-pb-1();
      position: initial;
    }
  }
}
</style>