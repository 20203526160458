export default function(items, field, reverse) {
    const filtered = [];
    if (!(items && items.length)) {
        return filtered;
    }
    items.forEach(function(item) {
        filtered.push(item);
    });
    filtered.sort(function(a, b) {
        return (a[field] > b[field] ? 1 : -1);
    });
    if (reverse) {
        filtered.reverse();
    }
    return filtered;
}