<template>
  <div class="utd-spinner" :class="spinnerClasses">
    <div class="utd-spinner-container">
      <div class="utd-spinner-graphic" />
      <div v-if="showText" class="utd-spinner-text">
        <slot name="text">
          <div class="utd-spinner-text-heading">{{ $t("LOADING") }}</div>
          <div>{{ $t("PLEASE_WAIT") }}</div>
        </slot>
      </div>
    </div>
    <div class="utd-spinner-content"><slot /></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { SET_BODY_SCROLL_LOCK } from '_acaSrc/store/app.store';

export default {
    props: {
        isVisible: {
            type: Boolean,
            default: true
        },
        isOverlay: {
            type: Boolean,
            default: true
        },
        isFullscreen: {
            type: Boolean,
            default: true
        },
        lockScroll: {
            type: Boolean,
            default: true
        },
        showText: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        lockBodyScroll() {
            return this.lockScroll && this.isVisible;
        },
        spinnerClasses() {
            return { 'utd-spinner-with-overlay': this.isOverlay,
                'utd-spinner-fullscreen': this.isFullscreen,
                'utd-spinner-visible': this.isVisible,
                'utd-spinner-locked': this.lockScroll };
        }
    },
    watch: {
        lockBodyScroll() {
            this.updateUiLockBodyScroll();
        }
    },
    methods: {
        ...mapMutations('app', [ SET_BODY_SCROLL_LOCK ]),
        updateUiLockBodyScroll() {
            this[SET_BODY_SCROLL_LOCK](this.lockBodyScroll);
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-SPINNER-COLOR: @WKCE-WHITE;

.utd-spinner {
  .utd-spinner-text {
    display: none;
  }

  &.utd-spinner-visible {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
    z-index: @ZINDEX-SPINNER-LOADING;

    &.utd-spinner-locked,
    &.utd-spinner-fullscreen {
      position: fixed;
    }

    &.utd-spinner-with-overlay {
      background-color: @DS1-UTD-SPINNER-TRANSPARENCY;

      .utd-spinner-graphic {
        border: 3px solid @UTD-SPINNER-COLOR;
        border-top: 3px solid transparent;
      }
    }

    .utd-spinner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 160px;
      .css-transform(translate(-50%, -50%));
    }

    .utd-spinner-graphic {
      display: block;
      position: absolute;
      top: 10px;
      width: 40px;
      height: $width;
      left: calc(50% - $width / 2);
      border: 3px solid @WKCE-GRAY-TINT1;
      .border-radius(50%);
      border-top: 3px solid transparent;
      .css-animation(spin 5s linear infinite);
    }

    .utd-spinner-text {
      display: block;
      position: absolute;
      color: @DS1-UTD-PRIMARY-BG-COLOR;
      top: 70px;
      width: 100%;
      text-align: center;
      font-size: 18px;

      .utd-spinner-text-heading {
        font-size: 24px;
        margin: 10px;
      }
    }

    .css-keyframes(spin;{
      0% { .css-transform(rotate(0deg)); }
      100% { .css-transform(rotate(360deg)); }
    });
  }
}

</style>