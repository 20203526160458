<template>
  <div id="shareView" class="utd-form">
    <div class="floating-labels-form">
      <form id="shareForm"
            ref="shareForm"
            v-blur-input-onsubmit
            name="shareForm"
            class="form-share"
            :class="{'isActive': formState.isActive}"
            @submit.prevent>
        <fieldset>
          <p v-if="shareMessageError"
             class="form-invalid"
             v-text="shareMessageError" />
          <div class="share-section top-section">
            <div class="form-row form-links">
              <label class="header-label">From</label>
              <span id="help-links" class="right">
                <a href="/home/privacy-policy" target="_blank">View privacy policy</a>
              </span>
            </div>

            <div class="form-row desktop-inline userName">
              <span class="form-field">
                <input id="fromName"
                       v-model.trim="fromName"
                       type="text"
                       placeholder="Your Name"
                       required
                       autocomplete="off"
                       autocapitalize="off"
                       autocorrect="off"
                       maxlength="60"
                       autofocus>
              </span>
            </div>

            <div class="form-row desktop-inline userEmail"
                 :class="{'showUserEmail': showUserEmail }">
              <span class="form-field">
                <input id="fromEmail"
                       v-model="fromEmail"
                       name="fromEmail"
                       type="text"
                       placeholder="Your E-mail Address"
                       maxlength="60">
              </span>
              <span id="emailNote">Your e-mail address will not be shared with recipients.</span>
            </div>

            <div id="sendCopy" class="form-row form-links">
              <span class="share-checkbox">
                <input id="remember" v-model="sendConfirmCopy" type="checkbox">
                <label for="remember"
                       class="pure-checkbox"><span><span /></span>Send a copy to me</label>
              </span>
            </div>
          </div>

          <div class="share-section">
            <label class="header-label">To <!--
                --><span class="note">(Separate multiple addresses with commas)</span></label>
            <div class="form-row personMsg">
              <span class="form-field">
                <input id="recipients"
                       v-model="toEmailsStr"
                       name="recipients"
                       type="text"
                       placeholder="Recipient E-mail Addresses"
                       maxlength="300"
                       required
                       @change="validateMultipleEmails()">
              </span>
            </div>
          </div>

          <div id="personSection" class="share-section">
            <label class="header-label"
                   for="message">Personalized Message <span class="note">(Optional)</span></label>
            <div class="form-row personMsg">
              <span class="form-field textarea">
                <textarea id="message"
                          v-model="formMessage"
                          name="message"
                          type="text"
                          maxlength="300" />
              </span>
            </div>

            <div id="message-details" class="form-break form-row">
              <span>Your message will include a link to the following:</span>
              <div><i v-html="contentTitlePreview" /></div>
            </div>

            <div v-if="issueGuestPass"
                 id="guest-pass"
                 class="form-break form-row">
              <span>
                <input id="includeCopy"
                       v-model="includeGuestPass"
                       type="checkbox"
                       :disabled="formState.multipleEmails">
                <label for="includeCopy"
                       :class="{'disabled': formState.multipleEmails}"
                       class="pure-checkbox"><span><span /></span><!--
                  -->Include a complimentary 30 day guest pass to UpToDate. <!--
                --> <a class="links"
                     target="_blank"
                     :tabindex="formState.tabIndex"
                     href="http://www.uptodate.com/home/help-manual-print-exp-online#email"><!--
                    -->Learn more</a>
                </label>
              </span>
              <div v-if="formState.multipleEmails"><!--
                -->Guest passes are not available when sending to multiple recipients.</div>
            </div>
          </div>

          <div class="share-section">
            <div class="form-break form-row">
              <span class="form-control">
                <button id="btnLoginSubmit"
                        class="form-button login-button"
                        :class="{'isActive': formState.isActive}"
                        @click="submitShareForm">
                  <span v-show="formState.isSubmitting" translate="PLEASE_WAIT" />
                  <span v-show="!formState.isSubmitting">Send</span>
                </button>
              </span>
            </div>
            <div class="form-break form-row note">
              <p>This feature is not intended for use with individually identifiable health <!--
                --> information (including protected health information or "PHI") and is not <!--
                --> designed to protect such information. <!--
                -->It is your responsibility to comply with your organization’s policies <!--
                -->and applicable privacy laws with respect to the communication of <!--
                -->health information.</p>
            </div>
          </div>
        </fieldset>
      </form>

      <div class="clear" />
    </div>
  </div>

</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    SET_SHARE_FORM_EMAIL,
    SET_SHARE_FORM_NAME,
    SET_SHARE_FORM_TOPIC_ID,
    SET_SHARE_FORM_LANGUAGE_CODE,
    SET_SHARE_FORM_IMAGE_IDS,
    SET_SHARE_FORM_RECIPIENTS_LENGTH,
    SET_SHARE_MESSAGE_ERROR,
    SET_SHARE_FORM_INCLUDE_GUEST_PASS,
    SET_SHARE_FORM_RECIPIENTS,
    SET_SHARE_FORM_VISIBILITY,
    SET_SHARE_CONFIRMATION_MODAL_VISIBILITY,
    SET_SHARE_FORM_SEND_CONFIRM_COPY,
    SET_SHARE_MESSAGE_TO_EMAIL_STR,
    SET_SHARE_FORM_MESSAGE,
    RESET
} from '_acaSrc/store/share.store';
import BlurInputOnsubmit from '_acaSrc/components/ui/BlurInputOnsubmit';
import { xssInjectionPattern, emailRegex, maxEmailLength }
    from '_acaSrc/utility/constants';
import { getWindow, checkAndSetEventBinding } from '_acaSrc/utility/DOM';


export default {
    directives: {
        BlurInputOnsubmit
    },
    data() {
        return {
            sendConfirmCopy: '',
            toEmailsStr: '',
            fromName: '',
            fromEmail: '',
            formMessage: '',
            includeGuestPass: '',
            formState: {
                isActive: false,
                multipleEmails: false,
                isSubmitting: false,
                contentTitle: '',
                tabIndex: 0
            }
        };
    },
    computed: {
        ...mapGetters('share', [ 'shareFormSendConfirmCopy',
            'shareFormIncludeGuestPass',
            'shareMessageError',
            'shareFormFromName',
            'shareFormFromEmail',
            'shareMessageToEmailStr',
            'shareFormMessage',
            'shareFormTopicId',
            'shareSource',
            'shareConfirmationMessage' ]),
        ...mapGetters('topic', [
            'topicId',
            'topicLanguage',
            'topicTitle' ]),
        ...mapGetters('graphic', [
            'getAllGraphics',
            'graphicLanguage',
            'graphicTitle',
            'graphicId',
            'graphic' ]),
        ...mapGetters('profile', [
            'welcomeName',
            'userEmail'
        ]),
        ...mapGetters('feature', [ 'issueGuestPass' ]),
        ...mapGetters('device', [ 'isBrowserTypeDesktop' ]),
        ...mapGetters('user', [ 'userLoggedIn' ]),
        showUserEmail() {
            return this.shareFormSendConfirmCopy || this.shareFormIncludeGuestPass;
        },
        contentTitlePreview() {
            return `"${this.formState.contentTitle}"`;
        }
    },
    watch: {
        sendConfirmCopy(newVal) {
            this[SET_SHARE_FORM_SEND_CONFIRM_COPY](newVal);
        },
        toEmailsStr(newVal) {
            this[SET_SHARE_MESSAGE_TO_EMAIL_STR](newVal);
        },
        fromName(newVal) {
            this[SET_SHARE_FORM_NAME](newVal);
        },
        fromEmail(newVal) {
            this[SET_SHARE_FORM_EMAIL](newVal);
        },
        formMessage(newVal) {
            this[SET_SHARE_FORM_MESSAGE](newVal);
        },
        includeGuestPass(newVal) {
            this[SET_SHARE_FORM_INCLUDE_GUEST_PASS](newVal);
        }
    },
    mounted() {
        this.populateShareForm();
        this.setDataBindings();
        this.setEventBindings();
    },
    beforeUnmount() {
        document.removeEventListener('focusout', this.resetScrollOnDismiss);
    },
    methods: {
        ...mapMutations('share', [
            SET_SHARE_FORM_EMAIL,
            SET_SHARE_FORM_NAME,
            SET_SHARE_FORM_TOPIC_ID,
            SET_SHARE_FORM_LANGUAGE_CODE,
            SET_SHARE_FORM_IMAGE_IDS,
            SET_SHARE_FORM_RECIPIENTS_LENGTH,
            SET_SHARE_MESSAGE_ERROR,
            SET_SHARE_FORM_INCLUDE_GUEST_PASS,
            SET_SHARE_FORM_RECIPIENTS,
            SET_SHARE_FORM_VISIBILITY,
            SET_SHARE_CONFIRMATION_MODAL_VISIBILITY,
            SET_SHARE_FORM_SEND_CONFIRM_COPY,
            SET_SHARE_MESSAGE_TO_EMAIL_STR,
            SET_SHARE_FORM_MESSAGE,
            RESET
        ]),
        ...mapActions('share', [ 'sendShareForm' ]),
        populateShareForm() {
            // pre-populating fields only when user is logged in
            if (this.userLoggedIn) {
                this[SET_SHARE_FORM_NAME](this.welcomeName);
                this[SET_SHARE_FORM_EMAIL](this.userEmail);
            }

            // Checking if content type is graphic or topic.
            if (this.shareSource === 'topic') {
                this[SET_SHARE_FORM_TOPIC_ID](this.topicId);
                this[SET_SHARE_FORM_LANGUAGE_CODE](this.topicLanguage);
                this.formState.contentTitle = this.topicTitle;
                this[SET_SHARE_FORM_IMAGE_IDS]([]);
            }
            else {
                if (this.getAllGraphics.length) {
                    for (let i = 0; i < this.getAllGraphics.length; i++) {
                        const graphic = this.getAllGraphics[i];
                        this[SET_SHARE_FORM_IMAGE_IDS](graphic.graphicInfo.id);
                    }
                }
                else if (this.graphic) {
                    this[SET_SHARE_FORM_IMAGE_IDS](this.graphicId);
                }

                this[SET_SHARE_FORM_LANGUAGE_CODE](this.graphicLanguage);
                this.formState.contentTitle = this.graphicTitle;
                this[SET_SHARE_FORM_TOPIC_ID]('');
            }
        },
        setDataBindings() {
            this.sendConfirmCopy = this.shareFormSendConfirmCopy;
            this.toEmailsStr = this.shareMessageToEmailStr;
            this.fromName = this.shareFormFromName;
            this.fromEmail = this.shareFormFromEmail;
            this.formMessage = this.shareFormMessage;
            this.includeGuestPass = this.shareFormIncludeGuestPass;
        },
        setEventBindings() {
            // Ensure view is reset when keyboard on mobile devices is dismissed
            if (!this.isBrowserTypeDesktop) {
                document.addEventListener('focusout', this.resetScrollOnDismiss);

                Array.from(document.querySelectorAll('#shareView input, #shareView textarea'))
                    .forEach(el => {
                        checkAndSetEventBinding(el,
                            'share-form-input-focus',
                            'focus',
                            this.setFocusedField(el));
                        checkAndSetEventBinding(el,
                            'share-form-input-blur',
                            'blur',
                            this.clearFocusedField(el));
                    });
            }
        },
        setFocusedField(el) {
            if (this.formFields) {
                this.formFields.focused = el;
            }
        },

        clearFocusedField() {
            if (this.formFields) {
                this.formFields.focused = undefined;
            }
        },

        isValidEmail(email) {
            if (!email) {
                return false;
            }
            return emailRegex.test(email) && email.length <= maxEmailLength;
        },
        resetScrollOnDismiss() {
            getWindow().scrollTo(0, 0);
        },
        verifyToEmails() {
            let isValid = true;
            const result = this.shareMessageToEmailStr.split(',');
            this[SET_SHARE_FORM_RECIPIENTS_LENGTH](0);
            for (let i = 0; i < result.length; i++) {
                const email = result[i].trim();
                if (email.length > 0) {
                    if (!this.isValidEmail(email) || email === '') {
                        this[SET_SHARE_MESSAGE_ERROR](
                            this.$t('SHARE.RECIPIENT_EMAIL_INVALID'));
                        document.querySelector('#recipients').focus();
                        this.formState.isSubmitting = false;
                        this.formState.isActive = false;
                        isValid = false;
                        break;
                    }
                    else if (email === this.shareFormFromEmail && this.shareFormIncludeGuestPass) {
                        this[SET_SHARE_MESSAGE_ERROR](
                            this.$t('SHARE.IDENTICAL_EMAILS'));
                        document.querySelector('#recipients').focus();
                        this.formState.isSubmitting = false;
                        this.formState.isActive = false;
                        isValid = false;
                        break;
                    }
                    else {
                        this[SET_SHARE_FORM_RECIPIENTS](email);
                    }
                }
            }

            return isValid;
        },

        validateMultipleEmails() {
            this.formState.tabIndex = 0;
            if (this.shareMessageToEmailStr) {
                this.formState.multipleEmails = this.shareMessageToEmailStr.indexOf(',') > -1;
                if (this.formState.multipleEmails) {
                    this[SET_SHARE_FORM_INCLUDE_GUEST_PASS](false);
                    this.formState.tabIndex = -1;
                }
            }
            else {
                this.formState.multipleEmails = false;
            }
        },
        // eslint-disable-next-line complexity
        submitShareForm() {
            if (this.formState.isSubmitting) {
                return;
            }
            this[SET_SHARE_MESSAGE_ERROR]('');
            // Hide keyboard
            const focus = getWindow().document.querySelector(':focus');
            if (focus) {
                focus.blur();
            }

            if (this.checkFormValidations()) {
                this.formState.isActive = true;
                this.formState.isSubmitting = true;
                this.sendShareForm()
                    .finally(() => {
                        this.formState.isActive = false;
                        this.formState.isSubmitting = false;
                        if (this.shareConfirmationMessage) {
                            this[SET_SHARE_CONFIRMATION_MODAL_VISIBILITY](true);
                        }
                    });
            }

        },

        checkFormValidations() {
            return this.isFormNameValid()
            && this.isFormMessageValid()
            && this.isToEmailStrValid()
            && this.isConfirmCopyValid()
            && this.verifyToEmails();
        },

        isFormNameValid() {
            if (this.shareFormFromName === '' || this.shareFormFromName === undefined) {
                this[SET_SHARE_MESSAGE_ERROR](this.$t('SHARE.NAME_REQUIRED'));
                document.querySelector('#fromName').focus();
            }
            else if (this.shareFormFromName.match(xssInjectionPattern)) {
                this[SET_SHARE_MESSAGE_ERROR](this.$t('ERROR.SUBMISSION_XSS_ERROR'));
                this.$nextTick(() => {
                    document.querySelector('#fromName').focus();
                });
            }
            else {
                return true;
            }
            return false;
        },

        isFormMessageValid() {
            if (this.shareFormMessage
                && this.shareFormMessage.match(xssInjectionPattern)) {
                this[SET_SHARE_MESSAGE_ERROR](this.$t('ERROR.SUBMISSION_XSS_ERROR'));
                document.querySelector('#message').focus();
            }
            else {
                return true;
            }
            return false;
        },

        isToEmailStrValid() {
            if (this.shareMessageToEmailStr === ''
                || this.shareMessageToEmailStr === undefined) {
                this[SET_SHARE_MESSAGE_ERROR](this.$t('SHARE.RECIPIENT_EMAIL_REQUIRED'));
                document.querySelector('#recipients').focus();
            }
            else {
                return true;
            }
            return false;
        },

        isConfirmCopyValid() {
            const isConfirmCopyAndGuestPass = this.sendConfirmCopy
            || this.shareFormIncludeGuestPass;

            if (isConfirmCopyAndGuestPass
                && (this.shareFormFromEmail === '' || this.shareFormFromEmail === undefined)) {
                this[SET_SHARE_MESSAGE_ERROR](this.$t('SHARE.EMAIL_REQUIRED'));
                document.querySelector('#fromEmail').focus();
            }
            else if (isConfirmCopyAndGuestPass && !this.isValidEmail(this.shareFormFromEmail)) {
                this[SET_SHARE_MESSAGE_ERROR](this.$t('SHARE.FROM_EMAIL_INVALID'));
                document.querySelector('#fromEmail').focus();
            }

            else {
                return true;
            }

            return false;
        }
    }
};
</script>
<style lang="less" scoped>
  @import './share.less';
</style>
