<template>
  <div class="topic-feedback" v-bind="$attrs">
    <div v-if="isContentFeedbackMigration">
      <utd-content-feedback-dialog :feedback-title="$t(feedbackText)"
                                   feedback-class="user-feedback-link"
                                   :button-text="$t(feedbackText)"
                                   has-chat-box-icon />
    </div>
    <div v-else>
      <utd-feedback-dialog ref="feedbackDialog"
                           :feedback-title="$t(feedbackText)"
                           :feedback-url="feedbackUrl" />
      <a class="user-feedback-link"
         tabindex="0"
         :aria-label="`Provide ${$t(feedbackText)}`"
         @click.prevent="openFeedback()"
         @keydown.enter.prevent="openFeedback()">
        <span class="wkce-icon-chat-box" />
        <span>{{ $t(feedbackText) }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import UtdContentFeedbackDialog from '_acaSrc/components/shared/utd/UtdContentFeedbackDialog.vue';
import UtdFeedbackDialog from '_acaSrc/components/shared/utd/UtdFeedbackDialog.vue';
import { USER_CONTENT_FEEDBACK } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdFeedbackDialog,
        UtdContentFeedbackDialog
    },
    props: {
        feedbackUrl: {
            type: String
        },
        isForCalculator: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapGetters('feature', [
            'isContentFeedbackMigration'
        ]),
        feedbackText() {
            return this.isForCalculator ?
                USER_CONTENT_FEEDBACK.TITLE.CALCULATOR : USER_CONTENT_FEEDBACK.TITLE.TOPIC;
        }
    },
    methods: {
        openFeedback() {
            this.$refs.feedbackDialog.openFeedbackForm();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.topic-feedback {
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  box-sizing: border-box;

  &:hover {
    background-color: @DS1-FLYOUT-TOGGLE-HOVER-BGCLR;
  }
}
</style>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.user-feedback-link {
  padding: 6px 0;
  display: block;

  &:hover {
    text-decoration: none;
  }

  .wkce-icon-chat-box {
    font-size: 18px;
    display: block;

    &::before {
      width: 18px;
      height: 16px;
    }
  }

  .expanded & {
    padding: 10px 8px 6px;
    line-height: 24px;
    text-align: center;

    .wkce-icon-chat-box {
      display: inline-block;
    }
  }
}

.topic-feedback-button {
  .ds1-utd-ellipsis-nowrap();

  .topic-feedback-button-text {
    .ds1-ph-compact();
  }
}
</style>
