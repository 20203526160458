<template>
  <div id="setLanguageContainer">
    <div id="setLanguageContent">
      <form>
        <div class="searchLangText">UpToDate allows you to search in the languages below.
          Please select your preference.  Topics will continue to be in English.
        </div>
        <ul class="language-columns">
          <li v-for="(language, index) in supportedLanguages"
              :key="index"
              class="language-entry">
            <input :id="language.value"
                   v-model="localization.dialogLanguage"
                   type="radio"
                   name="selectedLanguage"
                   :value="language.value"
                   required>
            <label :for="language.value"
                   class="language-label"
                   v-text="$t(`${language.name}`)" />
          </li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_LANGUAGES_LIST } from '_acaSrc/utility/constants';

export default {
    computed: {
        ...mapGetters('user', [ 'localization' ]),
        supportedLanguages() {
            return C_LANGUAGES_LIST;
        }
    }
};
</script>