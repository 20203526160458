<template>
  <div class="home-prospect-header">
    <div>
      <ul class="login-register" role="menu">
        <li>
          <a v-if="isAnonymousUser && !isUccState"
             id="navbarSubscribeBtn"
             :href="storeUrl"
             class="subscribe-btn"
             tabindex="0"
             role="menuitem">{{
               $t("HEADER.SUBSCRIBE")
             }}</a>
        </li>
        <li>
          <a v-if="isProspectView"
             id="navbarRegisterBtn"
             href="/login?register"
             class="register-btn"
             tabindex="0"
             role="menuitem"> {{
               $t('LOGIN.REGISTER')
             }}</a>
        </li>
        <li>
          <a id="navbarLoginBtn"
             class="login-btn"
             href="/login"
             tabindex="0"
             role="menuitem">{{ $t('MISC.LOG_IN')
             }}</a>
        </li>
      </ul>
    </div>

    <div v-if="shouldShowMobileSearchView" id="utdMobileBar" class="utdMobileBar">
      <button v-if="showBackButton"
              v-utd-back
              class="search-bar-left"
              @click="navGoBack()" />
      <utd-content-searchbar />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import UtdContentSearchbar from '_acaSrc/components/contents/search/SearchBar.vue';
import utdBack from '_acaSrc/directives/UtdBack.directive';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { C_UI } from '_acaSrc/utility/constants';
import { SET_IS_FIXED_TOOLBAR } from '_acaSrc/store/app.store';
const FIXED_TOOLBAR_POSITION_LANDING = 20;

export default {
    components: {
        UtdContentSearchbar
    },
    directives: {
        utdBack
    },
    data(){
        return {
            scrollListener: null,
            utdMain: null,
            isNavbarFixed : false
        };
    },
    computed: {
        shouldShowMobileSearchView() {
            return this.searchBarHeader && this.isNotDesktopView;
        },
        ...mapGetters('app', [ 'isAnonymousUser', 'router', 'isProspectView', 'isUccState' ]),
        ...mapGetters('auth', [ 'storeUrl' ]),
        ...mapGetters('device', [ 'isDesktopView', 'isNotDesktopView' ]),
        ...mapGetters('feature', [ 'hasPathwaysAccess', 'isShowRxTransitions' ]),
        ...mapGetters('search', [ 'searchBarHeader', 'isSearchView', 'isHomeSearchEmpty' ])
    },
    mounted() {
        this.navMenuResizeListener = this.setResizeListener(getWindow(), this.checkResizeScreen);
        this.utdMain = document.getElementById('utd-main');
        this.scrollListener = this.setScrollListener(this.utdMain, this.setFixedBarOnHome);
    },
    beforeUnmount() {
        this.clearScrollListener(this.scrollListener);
    },
    methods: {
        ...mapMutations('app', [ 'SET_IS_FIXED_TOOLBAR', 'SET_BODY_SCROLL_LOCK' ]),
        setFixedBarOnHome() {
            this.isNavbarFixed = this.utdMain.scrollTop > 0 ;
            this.$emit('navbarIsFixed', this.isNavbarFixed);
        },
        checkResizeScreen() {
            this.checkFixedToolbarState();
        },
        checkFixedToolbarState() {
            if (this.rxTransitionsHamburger()) {
                this[SET_IS_FIXED_TOOLBAR](true);
                return;
            }

            const fixedOffsetPosition = FIXED_TOOLBAR_POSITION_LANDING ;

            const isScrolledTop = getWindow().pageYOffset >= fixedOffsetPosition;
            if (this.isFixedToolbar !== isScrolledTop) {
                this[SET_IS_FIXED_TOOLBAR](isScrolledTop);
            }
        },
        rxTransitionsHamburger() {
            const screenWidth = getDocument().documentElement.clientWidth;
            if (this.isShowRxTransitions
          && this.hasPathwaysAccess
          && screenWidth < C_UI.RX_TRANSITIONS_MENU_BREAKPOINT) {
                return true;
            }
        }
    }
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) '~_acaAssets/global/global.less';

  .home-prospect-header {
    .ds1-ph-2();
    width: 100%;
    display: flex;
    transition: all 0.3s ease-in-out;

    .isTablet & {
      .ds1-pv-0();
    }

     .login-register {
      .ds1-ma-0();
      display: none;
      padding: 10px 0;
      text-decoration: none;
      list-style: none;

      .isTablet & {
        .ds1-pa-0();
      }

      > li > a {
        .ds1-ph-2();
        .ds1-pv-1();
        color: white;
        display: inline-block;
        font-size: 14px;
        line-height: 20px;

        &.login-btn {
          background-color: @DS2-UTD-NAVBAR-LOGIN-BG-COLOR;

          &:hover {
            background-color: @DS2-UTD-NAVBAR-LOGIN-HOVER-COLOR;
          }
        }

        &.register-btn, &.subscribe-btn {
          background-color: @DS2-UTD-NAVBAR-REGISTER-BG-COLOR;

          &:hover {
            background-color: @DS2-UTD-NAVBAR-REGISTER-HOVER-COLOR;
          }
        }

        &:first-child {
          margin-right: 10px;
        }
      }

      .isHomeSearchEmpty & {
        display: flex;
      }
    }
  }

.utdWkHomePage2024 .login-register-buttons__container .wk-nav> li > a.login-btn {
  .ds1-ma-0();
}
</style>
