<template>
  <div class="prospect-banner-wrapper">
    <section id="prospect-banner" class="prospect-banner">
      <div id="banner-header" />
      <div id="panel-left">
        <h3>{{ $t('MARKETING_BANNER.LEARN_HOW_UTD_CAN_HELP') }}</h3>
        <p>{{ $t(subtitleKey) }}</p>
      </div>
      <div id="panel-right">
        <div id="leftPoly" role="presentation" />
        <ul class="anchor-link-list">
          <li v-for="anchor in anchors"
              :key="anchor.class"
              class="anchor-link"
              @click="navigateAnchor(anchor)">
            <a :href="anchor.href">
              <div :class="anchor.class" />
              <span>{{ $t(anchor.title) }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div id="banner-footer">
        <a :href="storeUrl">{{ $t("HEADER.SUBSCRIBE") }}</a>
      </div>
      <div id="banner-close" title="Close Banner" @click="closeBanner()" />
    </section>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { getWindow } from '_acaSrc/utility/DOM';
import { triggerResizeEvent } from '_acaSrc/utility/Events';
import { SET_HIDE_MARKETING_BANNER } from '_acaSrc/store/topic/topic.store';

const HIDE_MARKETING_BANNER_DELAY = 600;
export default {
    props: {
        subtitleKey: {
            type: String,
            default: 'MARKETING_BANNER.SELECT_OPTION_BEST_DESCRIBES'
        }
    },
    data() {
        return {
            localMarketingUrls: {}
        };
    },
    computed: {
        ...mapGetters('auth', [ 'marketingUrls', 'storeUrl' ]),
        ...mapGetters('user', [ 'language' ]),
        currentLanguage() {
            return this.language;
        },
        maketingUrlSet() {
            if (Object.keys(this.marketingUrls).length === 0) {
                this.updateMarketingUrls();
                return this.localMarketingUrls;
            }
            return this.marketingUrls;
        },
        anchors() {
            return [
                {
                    class: 'icon-stethoscope',
                    href: this.maketingUrlSet.medProfessionalUrl,
                    title: 'MARKETING_BANNER.MEDICAL_PROFESSIONAL'
                },
                {
                    class: 'icon-large-book',
                    href: this.maketingUrlSet.medTraineeUrl,
                    title: 'MARKETING_BANNER.RESIDENT_FELLOW_STUDENT'
                },
                {
                    class: 'icon-ciricled-h',
                    href: this.maketingUrlSet.institutionUrl,
                    title: 'MARKETING_BANNER.HOSPITAL_INSTITUTION'
                },
                {
                    class: 'icon-person',
                    href: this.maketingUrlSet.groupPracticeUrl,
                    title: 'MARKETING_BANNER.GROUP_PRACTICE'
                }
            ];
        }
    },
    watch: {
        currentLanguage(newVal) {
            if (newVal) {
                this.updateMarketingUrls();
            }
        }
    },
    beforeUnmount() {
        clearTimeout(this.hideBannerTimeout);
    },
    methods: {
        ...mapMutations('topic', [
            SET_HIDE_MARKETING_BANNER
        ]),
        closeBanner() {
            this[SET_HIDE_MARKETING_BANNER]('bannerClosed bannerClosing');
            this.hideBannerTimeout = setTimeout(() => {
                this[SET_HIDE_MARKETING_BANNER]('bannerClosed');
                triggerResizeEvent();
            }, HIDE_MARKETING_BANNER_DELAY);
        },
        navigateAnchor(anchor) {
            getWindow().location.href = anchor.href;
        },
        languageMappings(currentLang) {
            const langs = {
                en: 'en',
                es: 'es',
                ja: 'ja-jp',
                pt: 'pt-br',
                de: 'de-de'
            };
            return langs[currentLang] || 'en';
        },
        updateMarketingUrls() {
            const anchorUrlPrefix = `https://www.wolterskluwer.com/${this.languageMappings(this.currentLanguage)}/solutions/uptodate/`;
            this.localMarketingUrls = {
                medProfessionalUrl: `${anchorUrlPrefix}professional-preview-individuals`,
                medTraineeUrl: `${anchorUrlPrefix}professional-preview-individuals`,
                institutionUrl: `${anchorUrlPrefix}who-we-help/hospitals-health-systems`,
                groupPracticeUrl: `${anchorUrlPrefix}who-we-help/individuals/groups`
            };
        }
    }
};
</script>
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) "~_acaAssets/global/global.less";

#prospect-banner {
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  display: none;

  ul li {
    .icon-stethoscope {
      .ds1-utd-stethoscope(11px, 14px);
    }

    .icon-large-book {
      .ds1-utd-large-book(13px, 14px);
    }

    .icon-ciricled-h {
      .ds1-utd-circled-h(14px, 14px);
    }

    .icon-person {
      .ds1-utd-person(13px, 14px);
    }

    &:hover {
      background-color: @DS1-UTD-GRAY-BG-COLOR;

      a {
        div svg g path {
          fill: @DS1-UTD-JS2-LINK-HOVER;
        }

        span {
          text-decoration: underline;
        }
      }
    }
  }
}

.prospectBannerLargeIcons() {
  .icon-stethoscope {
    .ds1-utd-stethoscope();
  }

  .icon-large-book {
    .ds1-utd-large-book();
  }

  .icon-ciricled-h {
    .ds1-utd-circled-h();
  }

  .icon-person {
    .ds1-utd-person();
  }
}

/*
  Desktop Style Tweaks
  These styles affect only desktop size and up.
*/
@media only screen and (min-width: 768px) {
  .wkProspect {
    .isDesktop {
      /* Common styles for all banner usage */
      #prospect-banner {
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        display: block;
        border: 1px solid #dadada;

        #banner-header,
        #banner-footer,
        #banner-close {
          display: none;
        }

        #panel-left {
          h3 {
            font-weight: bold;
          }
        }

        #panel-right {
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0 auto;

            > li {
              cursor: pointer;

              > a {
                display: block;
                padding: 7px 10px;
                line-height: 22px;
                text-decoration: none;

                > div {
                  display: inline-block;
                  vertical-align: middle;
                }

                > span {
                  font-size: 12px;
                  line-height: 14px;
                  display: inline-block;
                }
              }
            }

            &::after {
              content: ' ';
              clear: both;
              display: table;
            }
          }
        }
      }

      /* Prospect Topic View Header Banner */
      header {
        & > section > .prospect-banner-wrapper {
          visibility: visible;
          position: absolute;
          width: 100%;
          top: @PROSPECT-BANNER-TOP;
          background-color: #fff;
        }

        #prospect-banner {
          position: relative;
          max-width: 70rem;
          margin: 12px auto 1em;

          #leftPoly {
            position: absolute;
            top: 0;
            left: 16px;
            height: 88px;
            width: 251px;
            background: #474747;
            -webkit-transform: skew(-20deg);
            -moz-transform: skew(-20deg);
            -ms-transform: skew(-20deg);
            -o-transform: skew(-20deg);
          }

          #panel-left {
            position: absolute;
            z-index: 4;
            color: #fff;
            background-color: #474747;
            padding: 26px 15px;

            h3 {
              font-size: 0.75rem;
              line-height: 1.5;
              margin: 0;
            }

            p {
              font-size: 0.75rem;
              line-height: 1.5;
              font-weight: lighter;
              margin: 0;
            }
          }

          #panel-right {
            margin: 8px 0 8px 283px;

            ul {
              max-width: 600px;

              li {
                padding: 0;
                float: left;

                a div {
                  margin: 0 auto -2px;
                }
              }
            }
          }

          #banner-close {
            .utd-close-x();
          }
        }
      }

      &.es header #prospect-banner {
        #leftPoly {
          width: 299px;
        }

        #panel-right {
          margin: 18px 0 18px 329px;

          ul li a {
            padding: 2px 10px;
          }
        }
      }

      &.pt header #prospect-banner {
        #leftPoly {
          width: 258px;
        }

        #panel-right {
          margin: 18px 0 18px 283px;

          ul li a {
            padding: 2px 10px;
          }
        }
      }

      &.ja header #prospect-banner {
        #leftPoly {
          width: 325px;
        }

        #panel-right {
          margin-left: 351px;
        }
      }

      &.de header #prospect-banner {
        #panel-left {
          p {
            font-size: 0.65rem;
            line-height: 1.8;
          }
        }

        #leftPoly {
          width: 323px;
        }

        #panel-right {
          margin: 18px 0 18px 351px;

          ul li a {
            padding: 2px 10px;
          }
        }
      }

      /* Prospect Search Results Right Side Banner */
      .contentError #searchresults #prospect-banner {
        position: inherit;
        float: right;
      }

      #searchresults {
        #prospect-banner {
          width: 268px;

          #banner-header {
            display: block;
            border: 0;
            height: 98px;
            width: 268px;
            background: url('~_acaAssets/marketing/rsb-header-image.png') no-repeat;
            position: static;
          }

          #panel-left {
            position: static;
            text-align: center;
            z-index: auto;
            padding: 16px 20px 8px;

            h3 {
              color: #474747;
              font-size: 12px;
              line-height: 17px;
            }

            p {
              color: #474747;
              font-size: 12px;
              line-height: 24px;
              padding-top: 10px;
            }
          }

          #panel-right {
            margin: 0 12px;

            ul {
              li {
                float: none;
                min-width: auto;

                a {
                  display: table;
                  padding: 7px 13px;

                  div {
                    display: table-cell;
                    padding-top: 6px;
                    padding-bottom: 0;
                    height: auto;
                    margin: 0;
                  }

                  span {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 21px;
                    line-height: 16px;
                  }
                }
                .prospectBannerLargeIcons();
              }
            }
          }

          #banner-footer {
            display: block;
            background-color: @WK_UTD_ORANGE_BASE;
            font-size: 16px;
            text-align: center;
            padding: 13px 0;
            margin-top: 10px;

            a {
              text-decoration: none;
              color: #fff;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

/*
   Wider screen tweaks for marketing panels
 */

.expendedRightMarketingBannerElements() {
  #panel-right {
    ul {
      li {
        padding: 0;
        min-width: 128px;

        a {
          padding: 7px 15px;
        }
      }
    }
  }
}

.largeLeftMarketingBanner() {
  #panel-left {
    padding: 20px 15px;

    h3 {
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

.largeRightMarketingBanner() {
  ul {
    > li {
      padding: 0;
      min-width: auto;

      > a {
        padding: 16px 15px 14px 14px;
        height: 57px;
        display: block;

        > div {
          margin: 0 auto;
          display: block;
          text-align: center;
          height: 26px;
        }

        > span {
          padding-top: 15px;
        }
      }
      .prospectBannerLargeIcons();
    }
  }
}

@media only screen and (min-width: 775px) {
  .wkProspect {
    .isDesktop.pt header #prospect-banner {
      #panel-right {
        margin: 8px 0 8px 283px;

        ul {

          > li > a {
            padding: 7px 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 807px) {
  .wkProspect {
    .isDesktop.es header #prospect-banner {
      #panel-right {
        margin: 8px 0 8px 329px;

        ul > li > a {
          padding: 7px 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 846px) {
  .wkProspect {
    .isDesktop.en header #prospect-banner {
      .expendedRightMarketingBannerElements();
    }

    .isDesktop.de header #prospect-banner {
      #panel-right {
        margin: 8px 0 8px 351px;

        ul > li > a {
          padding: 7px 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 877px) {
  .wkProspect .isDesktop.ja header #prospect-banner {
    #panel-right {
      margin: 25px 0 25px 351px;
    }
  }
}

@media only screen and (min-width: 1013px) {
  .wkProspect {
    .isDesktop.ja header #prospect-banner {
      #panel-right {
        margin: 0 0 0 351px;
        .largeRightMarketingBanner();

        ul {
          max-width: 500px;

          > li > a {
            padding: 16px 15px 14px 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1081px) {
  .wkProspect {
    .isDesktop.en header #prospect-banner {
      .largeLeftMarketingBanner();

      #leftPoly {
        width: 326px;
      }

      #panel-right {
        margin: 0 0 0 338px;
        .largeRightMarketingBanner();
      }
    }

    .isDesktop.de header #prospect-banner {
      #panel-right {
        .largeRightMarketingBanner();

        ul {
          max-width: 582px;

          > li > a {
            padding: 7px 10px;
          }
        }
      }
    }

    .isDesktop.ja header #prospect-banner {
      .largeLeftMarketingBanner();

      #leftPoly {
        width: 424px;
      }

      #panel-right {
        margin: 0 0 0 450px;
      }
    }
  }
}

@media only screen and (min-width: 1125px) {
  .wkProspect {
    .isDesktop.pt header #prospect-banner {
      .largeLeftMarketingBanner();

      #leftPoly {
        width: 333px;
      }

      #panel-right {
        margin-left: 359px;
        .largeRightMarketingBanner();

        ul {
          max-width: 640px;

          > li > a {
            padding: 7px 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1136px) {
  .wkProspect {
    .isDesktop.es header #prospect-banner {
      .largeLeftMarketingBanner();

      #leftPoly {
        width: 388px;
      }

      #panel-right {
        margin-left: 401px;
        .largeRightMarketingBanner();

        ul > li > a {
            padding: 7px 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1148px) {
  .wkProspect {
    .isDesktop.de header #prospect-banner {
      #panel-left {
        p {
          font-size: 0.75rem;
          line-height: 1.5;
        }
      }

      #leftPoly {
        width: 362px;
      }
    }
  }
}
</style>
