import { cloneDeep } from 'lodash';
import { C_QAP } from '_acaSrc/utility/constants';
import { setQueryParamValue, setSearchUrlParamsHelper } from '_acaSrc/utility/http';
import QapDto from './qap.dto';

export default class DrugInformationDto extends QapDto {
    constructor(payload) {
        super(payload);

        const { result, rankIndex } = payload;

        result.panelType = C_QAP.PANEL.DRUG_INFO;
        result.subtype = result.panelType;

        this.panelType = result.panelType;
        this.subtype = result.subtype;

        this._setDrugInformationContents(result, rankIndex);
        this._setQapPanelItem(result, rankIndex, C_QAP.SECTION.DRUG_INTERACTIONS);
        this._setQapPanelItem(result, rankIndex, C_QAP.SECTION.RX_TRANSITIONS);

        result.qapContents = cloneDeep(this.qapContents);
    }

    _setDrugInformationContents(result, rankIndex) {
        this.qapContents = result.searchResults
            .filter(result => result.type === 'drug')
            .map(result => {
                const sections = this._collectPanelSections(result, rankIndex);
                return {
                    contentId: result.id,
                    contentLabel: `QAP_TABS.${result.subtype}`,
                    sections,
                    type: result.subtype,
                    title: result.title,
                    snippet: result.snippet || '',
                    url: setSearchUrlParamsHelper(result.url, {
                        rankIndex,
                        kpTab: result.subtype
                    })
                };
            });
    }

    _collectPanelSections(result, rankIndex) {
        let sections = [];
        const dosingSection = this._getDrugInfoDosings(result, rankIndex);
        if (dosingSection) {
            sections.push(dosingSection);
        }

        const accordions = this._getDrugInfoAccordions(result);
        if (accordions) {
            sections.push(accordions);
        }

        const alerts = this._getDrugInfoAlerts(result, rankIndex);
        if (alerts) {
            sections.push(alerts);
        }

        if (sections.length === 0) {
            sections = null;
        }
        return sections;
    }

    _getDrugInfoDosings(result, rankIndex) {
        const items = result.drugPanel
                   && result.drugPanel.dosing.map(dosage => {
                       // For dosing links include parameter to indicate we should display
                       // the drug name next to section header label in topic view
                       dosage.url = setQueryParamValue(dosage.url, 'showDrugLabel', 'true');
                       return {
                           title: dosage.label,
                           url: setSearchUrlParamsHelper(dosage.url, {
                               rankIndex,
                               kpTab: result.subtype
                           }),
                           items: dosage.childDrugContent && dosage.childDrugContent.map(child => {
                               // For dosing links include parameter to indicate we should display
                               // the drug name next to section header label in topic view
                               child.url = setQueryParamValue(child.url, 'showDrugLabel', 'true');
                               return {
                                   title: child.label,
                                   url: setSearchUrlParamsHelper(child.url, {
                                       rankIndex,
                                       kpTab: result.subtype
                                   })
                               };
                           }) || []
                       };
                   });

        // Return null if no dosing items found
        return items && {
            type: C_QAP.SECTION.LINKS,
            title: 'Dosing',
            items
        };
    }

    _getDrugInfoAccordions(result) {
        const accordions = result.drugPanel
                        && result.drugPanel.accordion.map(accordion => {
                            return {
                                label: accordion.name,
                                detail: accordion.value
                            };
                        }) || [];

        return accordions
            && accordions.length
            && {
                type: C_QAP.SECTION.ACCORDION,
                items: accordions
            };
    }

    _getDrugInfoAlerts(result, rankIndex) {
        const alerts = result.drugPanel
                        && result.drugPanel.alerts.map(alert => {
                            const { content, label, url } = alert;
                            return {
                                content,
                                label,
                                url: setSearchUrlParamsHelper(url, {
                                    rankIndex,
                                    kpTab: result.subtype
                                })
                            };
                        }) || [];

        return alerts
            && alerts.length
            && {
                type: C_QAP.SECTION.ALERT,
                items: alerts
            };
    }
}
