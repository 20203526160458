import { C_QAP } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper } from '_acaSrc/utility/http';
import QapDto from './qap.dto';
import { cloneDeep } from 'lodash';

export default class DrugDXYDto extends QapDto {
    constructor(payload) {
        super(payload);

        const { result, rankIndex } = payload;

        result.panelType = C_QAP.PANEL.DRUG_DXY;
        result.subtype = result.panelType;

        this.panelType = result.panelType;
        this.subtype = result.subtype;

        this._setDxyPanelContent(result, rankIndex);
        this._setForeignPanelContent(result, rankIndex);
        this._setDrugInteractionPanel(result, rankIndex);

        result.qapContents = cloneDeep(this.qapContents);
    }

    _setDxyPanelContent(result) {
        this.qapContents = result.searchResults
            .filter(result => result.type === 'drug'
                            && result.subtype === C_QAP.PANEL.DRUG_DXY)
            .map(result => {
                return {
                    contentId: result.id,
                    contentLabel: `QAP_TABS.${result.subtype}`,
                    sections: [],
                    type: result.subtype,
                    title: result.title,
                    url: result.url
                };
            });
    }

    _setForeignPanelContent(result, rankIndex) {
        const lexiSections = this._getForeignLexiSections(result, rankIndex);
        const drugArticles = this._getForeignDrugArticles(result, rankIndex);

        // Get total count of all 'items' collected in lexiSections
        const articleCount
            = lexiSections.reduce((count, section) => count + section.items.length, 0);

        // Create 'FOREIGN DRUG' tab panel, if there should be one
        if (drugArticles.length || articleCount) {
            this.qapContents.push({
                type: C_QAP.PANEL.DRUG_FOREIGN,
                contentLabel: 'QAP_TABS.drug_foreign',
                sections: !articleCount
                    ? [{
                        title: 'HEADER.DRUG_INFORMATION',
                        items: drugArticles
                    }]
                    : lexiSections
            });
        }
    }

    _getForeignLexiSections(result, rankIndex) {
        // Return list of sections where results are
        // for either FORMULATIONS or ROUTED DRUGS.
        return result.searchResults
            .filter(result => result.type === 'drug'
                && result.subtype !== C_QAP.PANEL.DRUG_DXY)
            .map(result => {
                return {
                    title: result.title,
                    items: result.pageType === 'DRUG_LANDING_PAGE'
                        || result.pageType === 'FORMULATION_PAGE'
                        ? result.searchResults.map(inner => {
                            return {
                                title: inner.label,
                                url: setSearchUrlParamsHelper(inner.url, {
                                    rankIndex,
                                    searchTerm: this.searchTerm,
                                    kpTab: C_QAP.PANEL.DRUG_FOREIGN
                                }),
                                class: 'wk-blue'
                            };
                        })
                        : []
                };
            });
    }

    _getForeignDrugArticles(result, rankIndex) {
        // Return list of items to be used for
        // DRUG INFORMATIION or INTERNATIONAL DRUG
        return result.searchResults
            .filter(result => result.type === 'drug'
                && result.subtype !== C_QAP.PANEL.DRUG_DXY)
            .map(({ title, url, snippet = '' }) => {
                return {
                    title,
                    snippet,
                    url: setSearchUrlParamsHelper(url, {
                        rankIndex,
                        searchTerm: this.searchTerm,
                        kpTab: C_QAP.PANEL.DRUG_FOREIGN
                    })
                };
            });
    }

    _setDrugInteractionPanel(result, rankIndex) {
        const drugInteractionsDto = this._toQapObject({
            searchTerm: this.searchTerm,
            results: result.searchResults,
            rankIndex,
            kpTab: C_QAP.PANEL.DRUG_FOREIGN,
            type: C_QAP.SECTION.DRUG_INTERACTIONS
        });

        result.dipCount = 0;
        if (drugInteractionsDto) {
            // Indicate there is at least 1 'Drug Interactions' panel
            // present for accurate assignment of 'display_rank' on
            // main search results URLs
            result.dipCount = 1;

            result.dip = drugInteractionsDto[0];
            this.dip = cloneDeep(drugInteractionsDto[0]);
        }
    }
}
