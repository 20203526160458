<template>
  <div id="paceHolder" />
  <ProspectHeader />
  <AuthenticatedHeader v-if="isHeaderRedesign" />
  <div id="utd-main"
       class="utd-content-main"
       :class="mainClasses">
    <ui-view :key="routePathname" />
  </div>
  <vue-progress-bar />
  <MobileProspectFooter v-if="!isHeaderRedesign && isDesktopView" />
  <AuthenticatedFooter v-if="showWkFooter" />

  <TrialSubscribe />
  <SupportTag />
  <TrackClickEvents />
  <UtdSpinner :is-visible="isSpinnerVisible" />
  <ServerMessaging />
  <MyUtdDialog />
  <ResponsiveMyUpToDate />
  <ResponsiveNotifier />
  <GlobalEventListener />
  <NextStepsMergeAccounts />
  <div id="portalTarget" />
</template>

<script>
import { mapGetters } from 'vuex';
import ProspectHeader from '_acaSrc/components/header/ProspectHeader.vue';
import AuthenticatedHeader from '_acaSrc/components/header/AuthenticatedHeader.vue';
import MobileProspectFooter from '_acaSrc/components/footer//MobileProspectFooter.vue';
import AuthenticatedFooter from '_acaSrc/components/footer/AuthenticatedFooter.vue';
import TrialSubscribe from '_acaSrc/components/header/TrialSubscribe.vue';
import SupportTag from '_acaSrc/components/footer/SupportTag.vue';
import TrackClickEvents from '_acaSrc/components/ui/TrackClickEvents.vue';
import UtdSpinner from '_acaSrc/components/shared/utd/UtdSpinner.vue';
import ServerMessaging from '_acaSrc/components/ui/ServerMessaging.vue';
import MyUtdDialog from '_acaSrc/components/myuptodate/MyUtdDialog.vue';
import ResponsiveMyUpToDate from '_acaSrc/components/ui/ResponsiveMyUpToDate.vue';
import ResponsiveNotifier from '_acaSrc/components/ui/ResponsiveNotifier.vue';
import GlobalEventListener from '_acaSrc/components/ui/GlobalEventListener.vue';
import NextStepsMergeAccounts from '_acaSrc/account/components/NextStepsMergeAccounts.vue';
import IndexManager from '_acaSrc/utility/IndexManager';

export default {
    components: {
        ProspectHeader,
        AuthenticatedHeader,
        MobileProspectFooter,
        AuthenticatedFooter,
        TrialSubscribe,
        SupportTag,
        TrackClickEvents,
        UtdSpinner,
        ServerMessaging,
        MyUtdDialog,
        ResponsiveMyUpToDate,
        ResponsiveNotifier,
        GlobalEventListener,
        NextStepsMergeAccounts
    },
    computed: {
        ...mapGetters('app', [
            'showWkFooter',
            'isSpinnerVisible',
            'isProspectView',
            'routePathname',
            'isAnonymousUser'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('feature', [ 'isHeaderRedesign', 'isShowRxTransitions' ]),
        ...mapGetters('topic', [ 'hideMarketingBanner' ]),
        ...mapGetters('search', [ 'fewSearchResults', 'nullSearchResults' ]),
        ...mapGetters('error', [ 'errorClass' ]),
        mainClasses() {
            return {
                hasFewResults: this.fewSearchResults,
                contentError: this.nullSearchResults,
                utdError: this.errorClass,
                rxTransitions: this.isShowRxTransitions
            };
        }
    },
    watch: {
        isProspectView(newVal) {
            new IndexManager().htmlCss.setOrClear('wkProspect', newVal);
        },
        isAnonymousUser(newVal) {
            new IndexManager().bodyCss.setOrClear('anonymous-user', newVal);
        }
    },
    mounted() {
        // When App.vue is finish loading finish the progress bar
        this.$Progress.finish();
    },
    created() {
        // When App.vue is first loaded start the progress bar
        this.$Progress.start();
    }
};

</script>
