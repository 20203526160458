<script>
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';

export default {
    mounted() {
        new PubSub().subscribe(C_EVENTS.TRACK_CLICK_EVENT, this._trackClickEvent);
        new PubSub().subscribe(C_EVENTS.TRACK_UI_CLICK_EVENT, this._trackUiClickEvent);
    },
    methods: {
        _trackClickEvent(payload) {
            this.trackClickEvent(payload);
        },
        _trackUiClickEvent(payload) {
            this.trackUiClickEvent(payload);
        }
    },
    render: () => null
};
</script>