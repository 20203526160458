<template>
  <div>
    <div v-if="isShowBackButton" class="contributors-toolbar large">
      <toolbar-back-link />
    </div>

    <dl class="utd-tabControl">
      <dt>Contributors</dt>
    </dl>

    <div id="contributors">
      <section>
        <!-- eslint-disable vue/no-v-html -->
        <h1 v-html="topicTitle" />

        <div v-for="(contributorTypes,index) in definedTypes" :key="`def${index}`">
          <h2>{{ contributorTypes.contributorTypeInfo.displayName }}</h2>
          <dl v-for="(contributor,idx) in contributorTypes.contributors" :key="idx">
            <dt class="contributorName">{{ contributor.contributorInfo.name }}</dt>
            <dd
              v-for="(association,i) in contributor.contributorInfo.associations"
              :key="i"
              class="contributorCredential"
            >{{ association }}</dd>
          </dl>
        </div>

        <div v-if="showPeerReviewers">
          <h2>Peer Reviewer</h2>
          <dl>
            <dt class="reviewerText">
              <i>Reviewers are not identified on topic reviews to preserve anonymity</i>
            </dt>
            <dd v-for="(link,index) in contributors.links" :key="index" class="reviewerLink">
              <div v-if="isPeerReviewer(link)">
                <a :href="setBaseUrl(link.url)">{{ link.label }}</a>
              </div>
            </dd>
          </dl>
        </div>

        <div v-for="(contributorTypes,index) in undefinedTypes" :key="`und${index}`">
          <h2>{{ contributorTypes.contributorTypeInfo.displayName }}</h2>
          <dl v-for="(contributor,idx2) in contributorTypes.contributors" :key="idx2">
            <dt class="contributorName">{{ contributor.contributorInfo.name }}</dt>
            <dd
              v-for="(association,i2) in contributor.contributorInfo.associations"
              :key="i2"
              class="contributorCredential"
            >{{ association }}</dd>
          </dl>
        </div>
      </section>

      <section v-if="contributors.acknowledgement" class="acknowledgement">
        <div>
          <strong>ACKNOWLEDGEMENT</strong>
          — {{ contributors.acknowledgement }}
        </div>
      </section>
      <section class="disclosureLink">
        <div v-for="(link,index) in contributors.links" :key="`dsc${index}`">
          <div v-if="isDisclosure(link)" class="contributorButton">
            <a :href="setBaseUrl(link.url)">
              <span>{{ link.label }}</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ToolbarBackLink from '../../shared/providers/UtdToolbarBackLinkProvider.vue';
import { mapGetters } from 'vuex';
import { setRelativeRootUrl } from '_acaSrc/utility/http';
import { browserHasHistory } from '_acaSrc/utility/Browsers';

export default {
    components: {
        ToolbarBackLink
    },
    props: {
        contributors: Object
    },
    computed: {
        ...mapGetters('app', [ 'isAlwaysShowBackButton' ]),
        showPeerReviewers() {
            return !this.contributors.hidePeerReviewers;
        },
        topicTitle() {
            return this.contributors.topicInfo.title;
        },
        definedTypes() {
            const contByType = this.contributors.contributorsByType || [];
            return contByType.filter(typeInfo => {
                const type = typeInfo.contributorTypeInfo.type;
                return ('AUTHOR|SECTION_EDITOR|DEPUTY_EDITOR'.indexOf(type) > -1);
            });
        },
        undefinedTypes() {
            const contByType = this.contributors.contributorsByType || [];
            return contByType.filter(typeInfo => {
                const type = typeInfo.contributorTypeInfo.type;
                return ('AUTHOR|SECTION_EDITOR|DEPUTY_EDITOR'.indexOf(type) === -1);
            });
        },
        isShowBackButton() {
            return this.isAlwaysShowBackButton && browserHasHistory();
        }
    },
    methods: {
        isPeerReviewer(link) {
            return link.label.indexOf('Peer reviewer') > -1;
        },
        isDisclosure(link) {
            return link.label.indexOf('disclosure') > -1;
        },
        setBaseUrl(url) {
            return setRelativeRootUrl(url);
        }
    }
};
</script>
