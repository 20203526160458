<template>
  <div id="utd-genericMenuContainer" class="utd-abs-white-carbon">
    <dl class="utd-tabControl">
      <dt>{{ $t('MYACCOUNT.SETTINGS') }}</dt>
    </dl>
    <ul class="utd-menu">
      <li><a href="/settings/change-language">{{ $t('MYACCOUNT.LANGUAGE') }}</a><i></i></li>
      <li v-if="!topicRedesign">
        <a href="/settings/change-text-size">{{ $t('MYACCOUNT.CHANGE_TEXT_SIZE') }}</a><i></i></li>
      <li v-if="topicRedesign">
        <span class="fontSizeMsg">{{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_MSG') }}</span>
      </li>
    </ul>
    <ul class="utd-menu utd-legal-menu">
      <li>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <a :href="policyUrl" v-html="$t('MYACCOUNT.POLICIESLEGAL')" /><i></i></li>
    </ul>
    <ul class="utd-menu">
      <li>
        <a @click="clearCache()">{{ $t('MYACCOUNT.CLEAR_CACHE') }}</a>
      </li>
    </ul>
    <p class="description">{{ $t('MYACCOUNT.CLEAR_CACHE_INSTRUCTION') }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdCache from '_acaSrc/utility/UtdCache';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';

export default {
    computed: {
        ...mapGetters('feature', [ 'topicRedesign' ]),
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('footer', [ 'getFooterUrl' ]),
        policyUrl() {
            return this.getFooterUrl('policies');
        }
    },
    methods: {
        clearCache() {
            new UtdCache().clearAll();
            new ServerMessaging().loadAndDisplayDialog({
                content: this.$t('MYACCOUNT.CACHE_CLEARED'),
                isFullscreen: false,
                buttons: [{
                    label: this.$t('OK'),
                    action: 'hide'
                }]
            }, true);
        }
    }
};

</script>