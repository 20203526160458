import { isAbsoluteUrl, canUseURL } from '../http';
import { getWindow } from '../DOM';
import isUrl from 'is-url';

export default class UrlParser {
    constructor(href) {
        if (!href) {
            this._error = 'UrlParser: No href passed to constructor!';
            return this;
        }

        this._isAbsoluteUrl = isAbsoluteUrl(href);
        this._href = href;
        this._error = '';
        this._protocol = '';
        this._hostname = '';
        this._port = '';
        this._pathname = '';
        this._search = '';
        this._hash = '';
        this._host = '';

        this._createParser(href);
        if (!this._error) {
            this._setParserValues();
        }
    }

    _prepareUrl(href) {
        // Methods require href to be an absolute URL
        if (this._isAbsoluteUrl) {
            return href;
        }

        // Turn href into relative URL if not starting with '/', or hash '#'
        if (href.substring(0, 1) !== '#' && href.substring(0, 1) !== '/') {
            href = `/${href}`;
        }

        const winLoc = getWindow().location;
        return `${winLoc.origin}${href}`;
    }

    _createParser(href) {
        href = this._prepareUrl(href);

        if (canUseURL()) {
            this._createParserUrl(href);
        }
        else {
            this._createParserLegacy(href);
        }
    }

    _createParserUrl(href) {
        try {
            this._parser = new URL(href);
        }
        catch (e) {
            this._error = e.toString();
        }
    }

    _createParserLegacy(href) {
        if (!isUrl(href)) {
            // eslint-disable-next-line quotes
            this._error = `TypeError: Failed to construct 'URL': Invalid URL`;
            return;
        }
        this._parser = getWindow().document.createElement('a');
        this._parser.href = href;
    }

    _setParserValues() {
        this._protocol = this._parser.protocol;
        this._hostname = this._parser.hostname;
        this._port = this._parser.port;
        this._pathname = this._parser.pathname;
        this._search = this._parser.search;
        this._hash = this._parser.hash;
        this._host = this._parser.host;
    }

    get lastError() {
        return this._error;
    }

    get isAbsoluteUrl() {
        return this._isAbsoluteUrl;
    }

    get href() {
        return this._href;
    }

    get protocol() {
        return this._protocol;
    }

    get hostname() {
        return this._hostname;
    }

    get port() {
        return this._port;
    }

    get pathname() {
        return this._pathname;
    }

    get search() {
        return this._search;
    }

    set search(params) {
        if (!this._parser) {
            return;
        }
        this._search = params || '';
    }

    get hash() {
        return this._hash;
    }

    get host() {
        return this._host;
    }

    get origin() {
        if (!this._protocol) {
            return '';
        }

        return `${this._protocol}//${this._host}`;
    }

    get url() {
        if (!this._parser) {
            return '';
        }
        // Only return absolute URL, if originally sent absolute URL
        return `${
            this._isAbsoluteUrl ? this.origin : ''
        }${
            this.pathname
        }${
            this.search
        }${
            this.hash
        }`;
    }

    toString() {
        return `Parser:
lastError: [${this.lastError}]
isAbsoluteUrl: [${this.isAbsoluteUrl}]
href: [${this.href}]
protocol: [${this.protocol}]
hostname: [${this.hostname}]
port: [${this.port}]
pathname: [${this.pathname}]
search: [${this.search}]
hash: [${this.hash}]
host: [${this.host}]
origin: [${this.origin}]
url: [${this.url}]`;
    }
}