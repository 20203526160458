<template>
  <div class="device">
    <div v-if="showDevice" class="device__container">
      <div class="device__info">
        <h4>{{ device.deviceTypeLabel }}</h4>
        <p class="device__last-used">
          <span> {{ $t('MYACCOUNT.DEVICES_LAST_USED') }} {{
            lastUsedDate(device.lastAuthorizationDateMs)
          }}
          </span>
        </p>
        <p class="device__more-info-link">
          <a class="device__expand-information-link"
             :class="isShowFullInfo"
             href="#"
             @click="toggleFullDeviceInfo(device)">Device Information</a>
        </p>
        <div v-if="showFullDeviceInfo" class="device__more-info">
          <p class="device__id">{{ $t('MYACCOUNT.DEVICES_DEVICE_ID') }}: {{ device.deviceId }}</p>
          <p class="device__app-version">
            {{ $t('MYACCOUNT.DEVICES_APPLICATION') }}: {{ device.appVersion }}
          </p>
          <p class="device__content-version">
            {{ $t('MYACCOUNT.DEVICES_CONTENT') }}: {{ device.contentVersion }}
          </p>
        </div>
      </div>
      <a class="device__deativate-link"
         href="#"
         @click="triggerDeactivate()">Deactivate (logout)</a>
    </div>
    <device-notification
      ref="deviceNotificationRef"
      tabindex="-1"
      :device="device"
      :notification-state="notificationState"
      @set-notification-state="setNotificationState" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WkceDates from '_acaSrc/utility/Dates';
import DeviceNotification from './DeviceNotification.vue';

const STATE_DEVICE_BASE = 'DEVICE';
const STATE_DEVICE_WARN = 'WARN';

export default {
    components: {
        DeviceNotification
    },
    props: {
        device: {
            type: Object,
            default() {
                return {};
            }
        },
        startState: {
            type: String,
            default: STATE_DEVICE_BASE
        }
    },
    data() {
        return {
            notificationState: this.startState,
            showFullDeviceInfo: false
        };
    },
    computed: {
        ...mapGetters('account', [ 'devices', 'isDeviceActive' ]),
        showDevice() {
            return this.notificationState === STATE_DEVICE_BASE
                && this.isDeviceActive(this.device.deviceId);
        },
        isShowFullInfo() {
            return this.showFullDeviceInfo ? 'expanded' : 'collapsed';
        }
    },
    methods: {
        lastUsedDate(lastAuthorizationDateMs) {
            return WkceDates.dateToMDYUSLocale(lastAuthorizationDateMs);
        },
        toggleFullDeviceInfo() {
            this.showFullDeviceInfo = !this.showFullDeviceInfo;
        },
        triggerDeactivate() {
            this.notificationState = STATE_DEVICE_WARN;
            this.$refs.deviceNotificationRef.$el.focus();
        },
        setNotificationState(state) {
            this.notificationState = state;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-PROFILE-ICON-COLOR: @WKCE-BLUE;
@DS1-BUTTON-BORDER-COLOR: @WKCE-BLUE-SHADE1;

.device {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.device__container {
  .ds1-mh-3();
  .ds1-mb-2();
  display: flex;
  width: 100%;
}

.device__info {
  flex-basis: 100vw;
}

.device__last-used {
  .ds1-mt-0();
  .ds1-mb-1();
}

.device__more-info {
  .ds1-pa-1();
  line-height: 19px;
  background-color: @DS1-UTD-GRAY-BG-COLOR;
  display: inline-block;
}

.device__id,
.device__content-version,
.device__app-version,
.device__more-info-link {
  .ds1-reset-p();
}

.device__more-info-link {
  .expanded::after {
    position: relative;
    font-family: "wk-icons-open";
    font-size: 16px;
    content: @DS1-UTD-CHEVRON-UP;
    padding-left: 6px;
  }

  .collapsed::after {
    position: relative;
    font-family: "wk-icons-open";
    font-size: 16px;
    content: @DS1-UTD-CHEVRON-DOWN;
    padding-left: 6px;
  }
}

.device__deativate-link,
.device__expand-information-link {
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
</style>