/**
 * Name:       utd-tooltip-ellipsis
 * Purpose:    Directive to provide tooltip shown on text with ellipsis.
 * Usage:      <span class="text-overflow" v-utd-tooltip-ellipsis></span>
 *
 * Notes:      Use multiline arg with multiline ellipsis
 */
import { TooltipEllipsis } from '_acaSrc/utility/tooltip';
import { getWindow } from '_acaSrc/utility/DOM';
import { nextTick } from 'vue';
import store from '_acaSrc/store';

const helperSpan = document.createElement('span');
helperSpan.classList.add('tooltip-ellipsis-helper');
helperSpan.style.display = 'none';
helperSpan.style.height = '0';
helperSpan.style.visibility = 'hidden';
helperSpan.style.width = 'auto';
document.body.appendChild(helperSpan);

export default {
    mounted: async(element, binding) => {
        if (!element.innerHTML || !store) {
            return;
        }

        let tooltipShown = false;
        await nextTick();
        await store.dispatch('app/confirmConfigLoaded');
        const tooltip = new TooltipEllipsis(element, store.getters['app/tooltipConfig']);

        try {
            await tooltip.initialize();
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(`Warning initializing TooltipEllipsis: ${e}`);
            return;
        }

        const clearHelperSpan = () => {
            helperSpan.innerHTML = '';
            helperSpan.style.display = 'none';
        };

        element.addEventListener('mouseenter', () => {
            if (tooltipShown) {
                return;
            }
            if (binding.arg === 'multiline') {
                if (element.offsetHeight < element.scrollHeight) {
                    tooltipShown = true;
                    tooltip.showTooltip();
                }
                return;
            }

            const computedStyle = getWindow().getComputedStyle(element);
            helperSpan.style.fontFamily = computedStyle.getPropertyValue('font-family');
            helperSpan.style.fontSize = computedStyle.getPropertyValue('font-size');
            helperSpan.style.fontWeight = computedStyle.getPropertyValue('font-weight');
            helperSpan.style.letterSpacing
                = computedStyle.getPropertyValue('letter-spacing');
            helperSpan.style.padding = computedStyle.getPropertyValue('padding');
            helperSpan.style.border = computedStyle.getPropertyValue('border');
            helperSpan.style.display = 'inline-block';

            helperSpan.innerHTML = element.innerHTML;

            if (helperSpan.offsetWidth > element.offsetWidth) {
                tooltipShown = true;
                clearHelperSpan();
                tooltip.showTooltip();
            }
            else {
                clearHelperSpan();
            }

        });

        element.addEventListener('mouseout', () => {
            tooltipShown = false;
            tooltip.hideTooltip();
        });
    }
};
