/**
 * Directive which adds 'target="_blank"' to all anchors
 * contained within defined element, that have a "host"
 * property that differs from the browser's host domain,
 * and is not already set.
 * If value passed to the directive is true,
 * then wait graphic ready event
 */

import { getWindow } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';

let targetAnchorBound;

export default {
    mounted: (elem, binding) => {
        if (binding.value) {
            targetAnchorBound = targetAnchor.bind(null, elem);
            new PubSub().subscribe('ANCHOR_CONTENT_READY', targetAnchorBound);
        }
        else {
            targetAnchor(elem);
        }
    },
    beforeUnmount: () => {
        new PubSub().unsubscribe('ANCHOR_CONTENT_READY', targetAnchorBound);
        targetAnchorBound = null;
    }
};

function targetAnchor(elem) {
    // Retrieve list of all anchor tags this element is a parent of
    const anchors = elem.querySelectorAll('a');
    // Pull the current host name from browser
    const ourHost = getWindow().location.host;
    // Begin looping through all child anchor tags found
    Array.from(anchors).forEach(anchor => {
        const parser = document.createElement('a');
        // Assign the href of new element to equal found anchor href.
        // This will automatically set all of the other properties we need to test.
        parser.setAttribute('href', anchor.getAttribute('href'));
        const currentTarget = anchor.getAttribute('target');
        // Compare anchor host against our host, if different then...
        // Check if target is either not set, or explcitly set to "_blank"
        if (parser.host !== ourHost && (!currentTarget || currentTarget === '_blank')) {
            anchor.setAttribute('target', '_blank');
            anchor.setAttribute('rel', 'noopener noreferrer');
        }
    });
}
