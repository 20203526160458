<template>
  <div class="my-bookmarks-tab-content-wrapper" data-autotest="myuptodateBookmarks">
    <div class="tabcontent">
      <span v-if="bookmarks.isLoadingResults"
            class="loading-message"
            v-text="$t('MYUPTODATE.PLEASE_WAIT')" />
      <span v-if="bookmarks.error"
            class="error">Error loading bookmarks, please try again.</span>
      <template v-if="showBookmarksContent">
        <ul v-show="bookmarksData.length"
            class="my-utd-tab-list"
            :class="{'edit-view': editBookmarksView}">
          <li v-for="(bookmark, idx) in filteredBookmarks"
              :key="bookmarkKey(bookmark)">
            <span class="remove-bookmark"
                  @click="bookmarkDeletion($event, bookmark, idx)" />
            <!-- eslint-disable vue/no-v-html -->
            <a v-if="!editBookmarksView"
               class="bookmark-title"
               :href="setQueryParamValue(bookmark.url, 'source', `bookmarks${view.source}`)"
               :class="[{'has-glyph': bookmark.isHasNewWhatsNew}, bookmark.contentType]"
               @click="handleLinkClick($event, bookmark.contentType)"
               v-html="bookmark.title" />
            <span v-if="editBookmarksView"
                  class="bookmark-title my-utd-tab-list-item"
                  :class="[{'has-glyph': bookmark.isHasNewWhatsNew,'with-icon': editBookmarksView}, bookmark.contentType]"
                  v-html="bookmark.title" />
            <utd-button class="remove-bookmark-mobile"
                        button-color="red"
                        button-size="small"
                        @click="deleteBookmark(bookmark, idx)">
              {{ $t('MYUPTODATE.DELETE') }}
            </utd-button>
          </li>
        </ul>
        <div v-show="!bookmarksData.length"
             class="no-content"
             v-text="$t('MYUPTODATE.BOOKMARK_EMPTY')" />
        <div v-show="showAllBookmarksButton" class="all-bookmarks-wrapper">
          <utd-button button-size="small"
                      button-color="blue"
                      button-style="ghost"
                      @click="showAllBookmarks">
            All Bookmarks
          </utd-button>
        </div>
      </template>
    </div>
    <div v-show="showEditBookmarksButton" class="edit-button-wrapper all-bookmarks-wrapper">
      <utd-button button-size="small"
                  button-color="blue"
                  button-style="ghost"
                  @click="toggleEditBookmarksView">
        {{ editBookmarksView ? 'Done' : 'Edit Bookmarks' }}
      </utd-button>
    </div>
    <graphic-hover-tooltip v-if="showBookmarksContent && bookmarksData.length"
                           :hover-selector="graphicHoverTooltipSelector"
                           :off-topic-variant="true" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { C_MYUTD_TABS } from '_acaSrc/utility/constants';
import { SET_BOOKMARKS_IS_EDITABLE } from '_acaSrc/store/profile.store';
import { setQueryParamValue } from '_acaSrc/utility/http';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import GraphicHoverTooltip from '_acaSrc/components/contents/topic/GraphicHoverTooltip.vue';

const WIDGET_BOOKMARKS_ROW_LIMIT = 10;
const CONFIRM_DELETION_MOBILE_CLASS = 'confirm-deletion-mobile';

export default {
    components: {
        UtdButton,
        GraphicHoverTooltip
    },
    props: {
        view: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            scrollPosition: 0,
            setQueryParamValue,
            allBookmarksVisible: false,
            editBookmarksView: false
        };
    },
    computed: {
        ...mapGetters('profile', [
            'bookmarks',
            'bookmarksData',
            'permissions',
            'bookmarksLoaded'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isNotDesktopView' ]),
        myUtdTabs() {
            return C_MYUTD_TABS;
        },
        showBookmarksContent() {
            return !this.bookmarks.isLoadingResults && !this.bookmarks.error;
        },
        hasBookmarkData() {
            return this.bookmarksData
                && this.bookmarksData.length;
        },
        showAllBookmarksButton() {
            return !this.allBookmarksVisible
                && this.hasBookmarkData && this.view.source === '_widget'
                && this.bookmarksData.length > WIDGET_BOOKMARKS_ROW_LIMIT;
        },
        filteredBookmarks() {
            if (this.view.source !== '_widget' || this.allBookmarksVisible) {
                return this.bookmarksData;
            }
            return this.bookmarksData.slice(0, WIDGET_BOOKMARKS_ROW_LIMIT);
        },
        showEditBookmarksButton() {
            return this.view.source !== '_widget'
                && !this.bookmarks.error && this.hasBookmarkData;
        },
        graphicHoverTooltipSelector() {
            return `.my-uptodate-wrapper${
                this.view.source} .my-bookmarks-tab-content-wrapper a[class*=graphic]`;
        }
    },
    watch: {
        bookmarksLoaded() {
            this.setTabContentScroll();
        }
    },
    beforeUnmount() {
        this.clearScrollListener(this.scrollListener);
    },
    mounted() {
        this.scrollListener
            = this.setScrollListener(this.$el,
                this.onScrollTabContent);
    },
    methods: {
        ...mapMutations('profile', [
            SET_BOOKMARKS_IS_EDITABLE
        ]),
        ...mapActions('app', [ 'publish' ]),
        ...mapActions('profile', [ 'deleteBookmarks' ]),
        ...mapActions('graphic', [ 'handleUseGraphicLink' ]),
        showAllBookmarks() {
            this.allBookmarksVisible = true;
        },
        toggleEditBookmarksView() {
            this.removeConfirmDeletionMobileClass();
            this.editBookmarksView = !this.editBookmarksView;
        },
        launchModalFromWidget(event, tab) {
            this.publish({
                eventName: 'wkutd.open-myuptodate-modal',
                eventData: { event, tab }
            });
        },
        bookmarkKey(bookmark) {
            return `${bookmark.contentId}${bookmark.languageCode}`;
        },
        removeConfirmDeletionMobileClass() {
            if (this.isDesktopView) {
                return;
            }
            const confirmElem
                = this.$el.querySelector(`.${CONFIRM_DELETION_MOBILE_CLASS}`);
            confirmElem && confirmElem.classList.remove(CONFIRM_DELETION_MOBILE_CLASS);
        },
        addConfirmDeletionMobileClass(event) {
            if (!(event && event.target && event.target.closest)) {
                return;
            }
            const liElem = event.target.closest('li');
            liElem && liElem.classList.add(CONFIRM_DELETION_MOBILE_CLASS);
        },
        bookmarkDeletion(event, bookmark, index) {
            if (this.isNotDesktopView) {
                this.removeConfirmDeletionMobileClass();
                this.addConfirmDeletionMobileClass(event);
            }
            else {
                this.deleteBookmark(bookmark, index);
            }
        },
        deleteBookmark(bookmark, index) {
            this.deleteBookmarks({ bookmark, index });
            this.publish({
                eventName: 'wkutd.myutd-bookmark-deleted'
            });
        },
        handleLinkClick(evt, contentType) {
            this.publish({
                eventName: 'wkutd.close-myuptodate-modal',
                eventData: {}
            });

            if (!contentType || contentType.indexOf('graphic') === -1) {
                return;
            }
            this.handleUseGraphicLink({
                evt,
                options: { showAppearsInTopics: true }
            });
        },
        setTabContentScroll() {
            if (this.scrollPosition) {
                this.$el.scrollTop = this.scrollPosition;
            }
        },
        onScrollTabContent() {
            if (this.$el.scrollTop) {
                this.scrollPosition = this.$el.scrollTop;
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.my-uptodate-wrapper,
.my-uptodate-wrapper_mobile {
  .my-bookmarks-tab-content-wrapper {
    .tabcontent {
      padding-bottom: 80px;
    }
  }
}

.my-uptodate-wrapper_widget {
  .my-bookmarks-tab-content-wrapper {
    .tabcontent {
      .ds1-pt-1();
      .ds1-pb-2();
    }
  }
}

.my-bookmarks-tab-content-wrapper {
  position: relative;
  height: 100%;

  .tabcontent {
    .ds1-pt-2();
    padding-bottom: 80px;
  }

  .all-bookmarks-wrapper {
    .ds1-mt-2();
    display: flex;
    justify-content: center;

    &.edit-button-wrapper {
      .ds1-pa-3();
      .ds1-pt-4();
      position: absolute;
      bottom: 0;
      width: calc(100% - 17px);
      box-sizing: border-box;
      z-index: @ZINDEX-MYUTD-EDIT-BUTTON-WRAPPER;
      background: linear-gradient(to bottom, rgba(256, 256, 256, 0) 0%, rgba(256, 256, 256, 1) 36%);
    }
  }

  .my-utd-tab-list {
    > li {
      .ds1-mt-0();

      .bookmark-title {
        .ds1-ph-2();
      }
    }

    &.edit-view {
      > li {
        display: flex;

        .remove-bookmark {
          display: block;
        }

        .bookmark-title {
          .ds1-pl-5();

          &.has-glyph::after {
            display: none;
          }
        }

        .remove-bookmark-mobile {
          .ds1-ph-0();
          display: inline-block;
          width: 0;
          transition: transform 0.25s ease-out;
          transform: scaleX(0);
          transform-origin: right;
          align-self: stretch;
          height: auto;
          border: none;
        }

        &.confirm-deletion-mobile {
          .remove-bookmark-mobile {
            .ds1-ph-2();
            width: auto;
            transform: scaleX(1);
          }

          .remove-bookmark {
            display: none;
          }

          > a {
            .ds1-pl-2();
            transition: all 0.25s ease-out;
          }
        }
      }
    }

    .remove-bookmark-mobile {
      display: none;
      align-self: center;
    }

    .remove-bookmark {
      display: none;
      font-family: 'wk-icons-open';
      position: absolute;
      z-index: @ZINDEX-REMOVE-BOOKMARK;
      margin-top: 7px;
      margin-left: 16px;
      font-size: 12px;
      color: @WKCE-BLUE;
      cursor: pointer;

      &::after {
        content: '\e866';
      }

      &:hover {
        font-family: 'wk-icons-filled';
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .my-bookmarks-tab-content-wrapper {
      .tabcontent {
        .my-utd-tab-list {
          .remove-bookmark {
            .ds1-ml-2();
          }

          &.edit-view {
            > li .bookmark-title {
              .ds1-pl-6();
            }
          }

          > li {
            .bookmark-title {
              .ds1-ph-3();
            }
          }
        }
      }
    }
  }
}
</style>
