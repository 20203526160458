<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="wk-utd-cme">
    <span class="wk-utd-cme-label">
      <span v-text="cmeLabel" />
      <span class="wk-utd-cme-suffix-label" v-text="mocMenuLabelSuffix" />
      <span class="wk-utd-cme-suffix-label" v-text="stateMenuLabelSuffix" />
    </span>
    <span id="wkUtdCmeValue" 
          class="wk-utd-cme-value" 
          v-html="capNumberAt(tickerValue, 500)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import capNumberAt from '_acaSrc/filters/CapNumberAt';

export default {
    data() {
        return {
            mocMenuLabelSuffix: ' / MOC',
            capNumberAt
        };
    },
    computed: {
        ...mapGetters('profile', [ 'isHasStateCme' ]),
        ...mapGetters('cme', [
            'cmeLabel',
            'tickerValue'
        ]),
        stateMenuLabelSuffix() {
            return this.isHasStateCme ? ' / State' : '';
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.wk-utd-cme-label {
  .ds1-utd-weight-normal();
  margin-right: 2px;
}

.wk-utd-cme-value {
  font-weight: 600;
}

.utdWkHeader {
  .wk-navbar {
    #utdMainNav {
      .wk-utd-cme-suffix-label {
        display: inline;
      }

      .wk-utd-cme-value {
        display: none;
      }
    }
  }

  .wk-utd-cme-suffix-label {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop.utdWkHeader .wk-header .wk-nav {
    #wkUtdWelcomeName {
      .utdAcctNav .wk-nav {
        .wk-utd-cme-value {
          display: none;
        }

        .wk-utd-cme-suffix-label {
          display: inline;
        }
      }
    }
  }

  .isDesktop .wk-utd-cme {
    .ds1-pa-1();
    color: @WKCE-BLUE-SHADE1;
    background-color: @WKCE-BLUE-TINT6;
    border: @DS1-CME-TICKER-BORDER;

    &:hover {
      color: @WKCE-WHITE;
      background-color: @WKCE-BLUE-TINT1;
    }
  }
}
</style>
