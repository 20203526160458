<template>
  <utd-modal-dialog ref="modalDialog"
                    :close-on-overlay-click="false"
                    :close-on-escape="false"
                    :hide-header-close="shouldShowWarningAcceptanceButtons"
                    modal-classes="translation-warning-modal"
                    dialog-size="medium">
    <template #header>
      <h4 class="translation-warning__modal-header">
        <span v-if="shouldShowWarningAcceptanceButtons">
          {{ localWarningLabels.disclaimerTitle }}
          <span class="english-warning-header">
            {{ englishWarningLabels.disclaimerTitle }}
          </span>
        </span>
        <span v-else-if="shouldShowLocalWarning">
          {{ localWarningLabels.warning }}
        </span>
        <span v-else-if="shouldShowEnglishWarning">
          {{ englishWarningLabels.warning }}
        </span>
      </h4>
    </template>

    <div class="translation-warning__container">
      <div v-if="shouldShowLocalWarning" class="translation-warning__local">
        <div class="translation-warning__heading">
          <span class="wkce-icon wkce-icon-filled-caution" />
          <h5 class="translation-warning__heading-label">
            {{ localWarningLabels.disclaimerTitle }}
          </h5>
        </div>
        <div class="translation-warning__content">
          <!--eslint-disable-next-line vue/no-v-html-->
          <span v-html="localWarningText.translationDisclaimerHtml" />
          <!--eslint-disable-next-line vue/no-v-html-->
          <span v-if="shouldShowWarningAcceptanceButtons" v-html="localWarningText.legalWarning" />
        </div>
      </div>
      <div v-if="shouldShowEnglishWarning" class="translation-warning__english">
        <div class="translation-warning__heading">
          <span class="wkce-icon wkce-icon-filled-caution" />
          <h5 class="translation-warning__heading-label">
            {{ englishWarningLabels.disclaimerTitle }}
          </h5>
        </div>
        <div class="translation-warning__content">
          <!--eslint-disable-next-line vue/no-v-html-->
          <span v-html="englishWarningText.translationDisclaimerHtml" />
          <!--eslint-disable-next-line vue/no-v-html-->
          <span v-if="shouldShowWarningAcceptanceButtons" v-html="englishWarningText.legalWarning" />
        </div>
      </div>
    </div>

    <template #footer>
      <div v-if="shouldShowWarningAcceptanceButtons"
           class="translation-warning__button-container">
        <utd-button class="translation-acknowledgement-button"
                    button-size="large"
                    button-style="ghost"
                    @click="handleDenyClick()">
          <span class="translation-acknowledgement-label">
            {{ localWarningLabels.doNotTranslate }}
          </span>
          <span class="translation-acknowledgement-label">
            {{ englishWarningLabels.doNotTranslate }}
          </span>
        </utd-button>
        <utd-button class="translation-acknowledgement-button"
                    button-size="large"
                    @click="handleConfirmClick()">
          <span class="translation-acknowledgement-label">
            {{ localWarningLabels.acknowledgement }}
          </span>
          <span class="translation-acknowledgement-label">
            {{ englishWarningLabels.acknowledgement }}
          </span>
        </utd-button>
      </div>
      <div v-else class="translation-warning__button-container">
        <utd-button button-size="large" @click="close()">
          <span v-if="shouldShowEnglishWarning">{{ englishWarningLabels.closeLabel }}</span>
          <span v-if="shouldShowLocalWarning">{{ localWarningLabels.closeLabel }}</span>
        </utd-button>
      </div>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { C_LOCALIZATION, C_LANGUAGES_NAMES, C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PracticePoint from '_acaSrc/components/localization/PracticePoint.vue';
import { SET_SHOW_TRANSLATION_WARNING } from '_acaSrc/store/topic/topic.store';

const ENGLISH_CODE = C_LANGUAGES_NAMES.EN.COUNTRY_CODE;

export default {
    components: {
        UtdModalDialog,
        UtdButton
    },
    mixins: [ PracticePoint ],
    data() {
        return {
            C_LANGUAGES_NAMES,
            C_LOCALIZATION,
            shouldShowWarningAcceptanceButtons: true
        };
    },
    computed: {
        ...mapGetters('topic', [
            'machineTranslationWarnings',
            'machineTranslationLabels',
            'contentLanguage',
            'machineTranslationUrl',
            'shouldShowTranslationWarning',
            'selectedTranslationToggleOption'
        ]),
        localLanguage() {
            if (!this.contentLanguage) {
                return '';
            }
            const localLanguage = this.contentLanguage.toLowerCase();
            return localLanguage;
        },
        englishWarningText() {
            return this.machineTranslationWarnings
                && this.machineTranslationWarnings[ENGLISH_CODE]
                && this.machineTranslationWarnings[ENGLISH_CODE][this.localLanguage] || {};
        },
        englishWarningLabels() {
            return this.machineTranslationLabels
                && this.machineTranslationLabels[ENGLISH_CODE] || {};
        },
        localWarningText() {
            return this.machineTranslationWarnings
                && this.machineTranslationWarnings[this.localLanguage]
                && this.machineTranslationWarnings[this.localLanguage][this.localLanguage] || {};
        },
        localWarningLabels() {
            return this.machineTranslationLabels
                && this.machineTranslationLabels[this.localLanguage] || {};
        },
        shouldShowEnglishWarning() {
            return this.selectedTranslationToggleOption === C_LOCALIZATION.ENGLISH_ID
                || (this.shouldShowTranslationWarning && this.shouldShowWarningAcceptanceButtons);
        },
        shouldShowLocalWarning() {
            return this.selectedTranslationToggleOption === C_LOCALIZATION.LOCAL_LANGUAGE_ID
                || (this.shouldShowTranslationWarning && this.shouldShowWarningAcceptanceButtons);
        }
    },
    methods: {
        ...mapActions('topic', [ 'setLocalizationContent', 'triggerTopicViewRemount' ]),
        ...mapMutations('topic', [
            SET_SHOW_TRANSLATION_WARNING
        ]),
        handleConfirmClick() {
            this[SET_SHOW_TRANSLATION_WARNING](false);
            new PubSub().publish(
                C_EVENTS.CLOSE_TRANSLATION_WARNING,
                C_LOCALIZATION.LOCAL_LANGUAGE_ID
            );
            utdRest('localization/accept-agreement');
            this.close();
        },
        handleDenyClick() {
            this.close();
            new PubSub().publish(
                C_EVENTS.CLOSE_TRANSLATION_WARNING,
                C_LOCALIZATION.ENGLISH_ID
            );
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'machineTranslationWarning_deny'
            });
        },
        open(shouldShowAcceptanceButtons) {
            this.shouldShowWarningAcceptanceButtons = shouldShowAcceptanceButtons;
            this.$refs.modalDialog.open();
        },
        close() {
            this.$refs.modalDialog.close();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@media only screen and (min-width: 768px) {
  .translation-warning__button-container .utd-button.translation-acknowledgement-button {
    flex-direction: row;
    width: 220px;
    height: 39px;
  }

  span.translation-acknowledgement-label {
    display: contents;
  }
}

@media only screen and (min-width:380px) {
  div.translation-warning__button-container {
    flex-direction: row;
    gap: 0px;
  }
}

.translation-warning__button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column-reverse;
  gap: 12px;

  .translation-acknowledgement-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    width: 150px;
    height: 70px;
  }
}

.translation-warning__container {
  display: flex;
  flex-flow: wrap;
  gap: 24px;
}

.translation-acknowledgement-label {
  display: block;
  line-height: 24px;
}

.translation-warning__modal-header {
  .ds1-utd-weight-bold();
  font-size: 20px;
  color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
}

.translation-warning__heading {
  .ds1-flex-vertical-center();
  .ds1-mb-1();
}

.translation-warning__heading-label {
  .ds1-utd-size-4();
  .ds1-ml-2();
  .ds1-utd-weight-600();
}

.translation-warning__content {
  .ds1-ml-5();
}

.wkce-icon-filled-caution {
  .ds1-ma-0();
  font-size: 24px;
  color: @DS1-UTD-WARN;
}

.english-warning-header {
  display: inline-block;
}
</style>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.translation-warning-modal {
  .utd-modal-content {
    .utd-dialog-frame {
      max-width: 600px;
      .utd-dialog-content {
        .ds1-pa-4();
      }
    }
  }
}
</style>