import { getObjectCopy } from '_acaSrc/utility/Object';
import { getWindow } from './DOM';
import PubSub from './PubSub';

let _serverMessagingInstance = null;

export default class ServerMessaging {
    constructor() {
        if (_serverMessagingInstance) {
            return _serverMessagingInstance;
        }

        _serverMessagingInstance = this;

        this.messageQueue = [];
        this.dialog = {
            visible: false,
            show: false,
            message: null,
            working: {
                chromeless: '',
                title: '',
                content: '',
                buttons: [],
                name: '',
                titleHtml: '',
                fnClose: null,
                fnPreResize: null,
                fnPostResize: null
            }
        };
        // Save original state
        this.origDialog = getObjectCopy(this.dialog);

        this.excludedPaths = [ '/legal/license', '/legal/china/license',
            '/validate-code', '/logout' ];
        this.excludedPathsForSla = this.excludedPaths.slice();
        this.excludedPathsForSla.push('/login');
    }

    enqueueMessage(json) {
        const msgTitle = json.utdStatus;
        for (let ii = 0; ii < this.messageQueue.length; ii++) {
            const msg = this.messageQueue[ii];

            if (msg.utdStatus === msgTitle) {
                return;
            }
        }
        if (this.dialog.working.name === msgTitle) {
            return;
        }
        this.messageQueue.unshift(json);
    }

    isExcludedPath(isSla) {
        const path = getWindow().location.href;
        let index = -1;
        if (isSla) {
            index = this.excludedPathsForSla.indexOf(path);
        }
        else {
            index = this.excludedPaths.indexOf(path);
        }

        return index > -1;
    }

    scrollPixel() {
        return getWindow().scrollBy(0, 1);
    }

    popMessage() {
        if (this.messageQueue.length > 0 && this.dialog.visible === false
            && !this.isExcludedPath()) {
            const msg = this.messageQueue.pop();
            if ((msg.utdStatus === 'VIEW_INDIVIDUAL_SLA')
                    || (msg.utdStatus === 'CONFIRM_AND_EXECUTE')) {
                if (!this.isExcludedPath(true)) {
                    this.dialog.message = msg;
                    this.convertMessageFromServer();
                    this.dialog.visible = true;
                    const self = this;
                    setTimeout(() => {
                        self.scrollPixel();
                        new PubSub().publish('wkutd.displayModal', this.dialog);
                    });
                }
                else {
                    this.enqueueMessage(msg);
                }
            }
            else {
                this.dialog.message = msg;
                this.convertMessageFromServer();
                this.dialog.visible = true;
                const self = this;
                setTimeout(() => {
                    self.scrollPixel();
                    new PubSub().publish('wkutd.displayModal', this.dialog);
                });
            }
            return true;
        }
        return false;
    }

    resolveAsync(data) {
        try {
            for (let ii = 0; ii < this.messageQueue.length; ii++) {
                const msg = this.messageQueue.get(ii);
                // eslint-disable-next-line eqeqeq
                if (msg.utdStatus == data) {
                    return;
                }
            }
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Error trying to show the message', e);
        }
    }

    resetModal() {
        new PubSub().publish('wkutd.closeModal');
    }

    restoreDefault() {
        if (this.dialog.working.fnClose) {
            this.dialog.working.fnClose();
        }

        this.dialog = getObjectCopy(this.origDialog);
    }

    clearMessageQueue() {
        this.messageQueue = [];
    }

    quickMessage(msg) {
        this.loadAndDisplayDialog({
            content: msg,
            isFullscreen: false,
            buttons: [{
                label: 'Ok',
                action: 'hide'
            }]
        }, false);
    }

    // Public method to launch modal dialog manually
    //
    // Warning. Do not call resetModal programmatically before loadAndDisplayDialog
    // is finished setting up. This can cause an overlay to appear without any content,
    // and breaks the page for the user. If onDialogVisible is set to true, this method
    // returns a promise. If it's necessary to cancel the opening of the modal,
    // call resetModal only after this promise is resolved.
    //
    // Here is an example of how to launch the modal dialog manually with injected content
    //
    // messageService.loadAndDisplayDialog({
    //	title: "Here is the license agreement",
    //	content: "<p>The quick brown fox jumped over the lazy dogs back.</p>",
    // If not empty, will load template provided. Pass template using `import` statement
    //  template: 'your-template',
    //  doCompile: true. // If true, will run $compile on either 'template', or 'content'
    //	isFullscreen: false,
    //	buttons: [{
    //		label: "Ok",
    //		action: "hide"
    //	},{
    //		label: "What does the fox say?",
    //		alert: "A-oo-oo-oo-oooo!"
    //	},{
    //		label: "Look it up...",
    //		windowOpen: "http://www.google.com",
    //		action: "hide"
    //	},{
    //		label: "Are you Sancho?",
    //		alert: "No, you are NOT Sancho. I AM SANCHO!",
    //		action: "hide"
    //	}]
    // }, true);
    // eslint-disable-next-line complexity
    loadAndDisplayDialog(json, includeTitleClose) {
        if (typeof json !== 'undefined') {
            this.dialog.working.title = json.title;
            this.dialog.working.content = json.content;
            this.dialog.working.component = json.component || '';
            this.dialog.working.isFullscreen = json.isFullscreen;
            this.dialog.working.buttons = json.buttons;
            this.dialog.working.showTitleClose = (typeof includeTitleClose !== 'undefined'
                ? includeTitleClose : false);
            this.dialog.working.cssClass = json.cssClass;
            this.dialog.working.fnClose = (json.onCloseFn ? json.onCloseFn : null);
            this.dialog.working.fnPreResize = (json.onPreResizeFn ? json.onPreResizeFn : null);
            this.dialog.working.fnPostResize = (json.onPostResizeFn ? json.onPostResizeFn : null);
            this.dialog.working.fnPostRender = (json.onPostRenderFn ? json.onPostRenderFn : null);
            this.dialog.working.context = (json.context ? json.context : null);
            this.dialog.doCompile = json.doCompile;
            this.dialog.visible = true;

            new PubSub().publish('wkutd.displayModal', this.dialog);

            if (json.onDialogVisible) {
                const oThis = this;
                return new Promise(function(resolve) {
                    oThis.dialog.working.fnDialogVisible = resolve;
                });
            }
        }
    }

    // Function to convert messageBundle output from server
    // to a structure consumable by the ModalDialog directive.
    // eslint-disable-next-line complexity
    convertMessageFromServer() {
        // eslint-disable-next-line eqeqeq
        if (this.dialog.message != null) {
            const jMsgObj = this.dialog.message;
            // NOTE: The !! sequence is described in the following stackoverflow question:
            // http://stackoverflow.com/questions/784929/what-is-the-not-not-operator-in-javascript
            const bMsgTtl = !!jMsgObj.title;
            const bMsgCnt = !!jMsgObj.message;
            const msgName = jMsgObj.utdStatus;
            if (bMsgTtl && bMsgCnt) {
                this.dialog.working.title = jMsgObj.title;
                this.dialog.working.content = jMsgObj.message;
                this.dialog.working.isFullscreen = jMsgObj.isFullscreen;
                this.dialog.working.name = msgName;
            }
            if (typeof jMsgObj.translatedActions !== 'undefined'
                && jMsgObj.translatedActions.constructor === Array) {
                this.dialog.working.buttons = Array(jMsgObj.translatedActions.length);
                this.dialog.working.fnClose = () => {
                    // CESUS-610: For server-initiated messages, attempt to restore
                    // focus back into the search bar input if present.
                    setTimeout(() => {
                        const searchBar = document.querySelector('#tbSearch');
                        if (searchBar) {
                            searchBar.focus();
                        }
                    }, 1);
                };
                for (let iMsgAct = 0; iMsgAct < jMsgObj.translatedActions.length; iMsgAct++) {
                    const jMsgAct = jMsgObj.translatedActions[iMsgAct];
                    const oBtn = {
                        label: jMsgAct.message
                    };
                    switch (jMsgAct.action) {
                    case 'OK':
                    case 'EXIT':
                    case 'CLOSE':
                    case 'CANCEL':
                    case 'CONTINUE':
                        oBtn.action = 'hideAndGetNext';
                        break;

                    case 'RESET_OK':
                        oBtn.windowReload = 'reload';
                        break;

                    case 'SEARCH_OK':
                        // This action is currently only set when
                        // jMsgObj.utdStatus === 'CONCURRENCY_VIOLATION'
                        // So we just want to clear local cache and
                        // land the user on the home search view
                        oBtn.flushApplication = true;
                        break;

                    case 'ACCEPT_LICENSE':
                        oBtn.asynchRequest = 'agreement/accept';
                        oBtn.asynchType = 'PUT';
                        oBtn.action = 'hideAndGetNext';
                        oBtn.id = 'accept-license';
                        break;

                    case 'DECLINE_LICENSE':
                        oBtn.windowLocate = `${getWindow().location.protocol}//${
                            getWindow().location.host}/contact-us`;
                        oBtn.id = 'decline-license';
                        break;

                    case 'VIEW_LICENSE':
                        jMsgAct.url ? oBtn.windowOpen = jMsgAct.url
                            : oBtn.windowLocate = 'legal/china/license';
                        oBtn.id = 'view-license';
                        if (jMsgObj.utdStatus === 'VIEW_INDIVIDUAL_SLA') {
                            oBtn.style = 'text';
                            oBtn.size = 'small';
                        }
                        break;

                    case 'VIEW_PRIVACY_POLICY':
                        // eslint-disable-next-line
                        const privacyPolicyUrl = this.dialog.message.privacyPolicyUrl || `${getWindow().location.protocol}//${getWindow().location.host}/home/privacy-policy`;
                        oBtn.windowOpen = `${privacyPolicyUrl}`;
                        oBtn.id = 'view-privacy-policy';
                        if (jMsgObj.utdStatus === 'VIEW_INDIVIDUAL_SLA') {
                            oBtn.style = 'text';
                            oBtn.size = 'small';
                        }
                        break;

                    case 'RENEW_NOW':
                        oBtn.logEvent = 'renew_now_popup';
                        oBtn.windowLocate = `${getWindow().location.protocol}//${
                            getWindow().location.host}/store`;
                        oBtn.action = 'hideAndGetNext';
                        break;

                    case 'RENEW_LATER':
                        oBtn.logEvent = 'renew_later_popup';
                        oBtn.action = 'hideAndGetNext';
                        break;

                    case 'SUBSCRIBE_NOW':
                        oBtn.windowLocate = `${getWindow().location.protocol}//${
                            getWindow().location.host}/store`;
                        oBtn.action = 'hideAndGetNext';
                        break;

                    case 'ERA_LEARN':
                        oBtn.windowOpen = 'http://learn.uptodate.com/verify';
                        oBtn.action = 'hideAndGetNext';
                        break;

                    case 'CONFIRM_REMOVE_DEVICE':
                        oBtn.asynchRequest = `user/device/${jMsgAct.data}/json`;
                        oBtn.asynchType = 'DELETE';
                        oBtn.action = 'hideAndGetNext';
                        oBtn.postActionData = jMsgAct.data;
                        oBtn.asynchConfig = { bypass404: true };
                        oBtn.successHandler = jMsgAct.successHandler;
                        oBtn.errorHandler = jMsgAct.errorHandler;
                        break;

                    case 'MARKETO_LAUNCHER':
                        oBtn.windowOpen = '/compliance/info';
                        oBtn.action = 'hideAndGetNext';
                        break;

                    case 'CANCEL_COMPLIANCE':
                        oBtn.asynchRequest = '/compliance/info/cancel';
                        oBtn.asynchType = 'DELETE';
                        oBtn.action = 'hideAndGetNext';
                        break;
                    default:
                        // Let's the close the window in case we missed a case above
                        oBtn.action = 'hideAndGetNext';
                        break;
                    }
                    this.dialog.working.buttons[iMsgAct] = oBtn;
                }
            }
            else {
                // No buttons found, add in default button
                this.dialog.working.buttons = Array(1);
                const oBtn = {
                    labelKey: 'CLOSE',
                    action: 'hideAndGetNext'
                };
                this.dialog.working.buttons[0] = oBtn;
            }
        }
    }
}