// DEPRECATED: only use this to migrate Angular stories
// DO NOT USE for new features
export function getObjectCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function removeNullOrUndefinedProperties(obj) {
    const result = {};
    Object.keys(obj).forEach(key => {
        if (obj[key] !== null && typeof obj[key] !== 'undefined') {
            result[key] = obj[key];
        }
    });
    return result;
}

const ObjectUtils = {
    getObjectCopy
};

export default ObjectUtils;