<template>
  <a class="utd-thumbnail__container"
     :href="graphicUrl"
     :tabindex="tabIndex"
     :target="target"
     :class="{
       'thumbnail-border': hasBorder,
       'thumbnail-large': large,
       'thumbnail-horizontal': horizontal
     }"
     @keypress="$event.which === ENTER_KEY && thumbnailClicked()"
     @click="thumbnailClicked">
    <div class="utd-thumbnail__image-container">
      <span v-if="graphicThumbnailUrl"
            class="utd-thumbnail__image-anchor"
            :style="graphicThumbnailStyle" />
    </div>
    <div class="utd-thumbnail__label-anchor ds1-pt-1">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-utd-tooltip-ellipsis:multiline v-html="graphic.title" />
    </div>
  </a>
</template>

<script>
import { C_KEYCODES, C_GRAPHICS } from '_acaSrc/utility/constants';
import { makeThumbnailUrl, makeGraphicUrl } from '_acaSrc/utility/contents/graphic/graphic';
import utdTooltipEllipsis from '_acaSrc/directives/UtdTooltipEllipsis.directive';

export default {
    directives: {
        utdTooltipEllipsis
    },
    props: {
        graphic: Object,
        tabIndex: {
            type: Number,
            default: 0
        },
        target: {
            type: String,
            default: '_self'
        },
        hasBorder: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'utd-thumbnail-click' ],
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        graphicThumbnailStyle() {
            if (this.graphic.type === C_GRAPHICS.TYPE.MOVIE
                || this.graphic.subtype === C_GRAPHICS.SUB_TYPE.MOVIE) {
                return {
                    background: `url(/app/graphic/icn_play.png) no-repeat center,
                    url(${this.graphicThumbnailUrl}) no-repeat 50% 50% / contain`
                };
            }
            return {
                background: `url(${this.graphicThumbnailUrl}) no-repeat 50% 50% / contain`
            };

        },
        graphicThumbnailUrl() {
            const thumbnailUrl = this.graphic.imageKey ? makeThumbnailUrl(this.graphic.imageKey) : this.graphic.thumbnailUrl;
            if (thumbnailUrl) {
                return this.large ? thumbnailUrl.replace('thumbnail', 'largethumbnail') : thumbnailUrl;
            }
            return '';
        },
        graphicUrl() {
            if (!this.graphic.url) {
                return makeGraphicUrl(this.graphic);
            }
            return this.graphic.url;
        }
    },
    methods: {
        thumbnailClicked(event) {
            this.$emit('utd-thumbnail-click', event);
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@THUMBNAIL-CONTAINER-WIDTH-PX: 155px;
@IMAGE-CONTAINER-WIDTH-PX: 147px;
@THUMBNAIL-IMAGE-WIDTH-PX: 145px;
@THUMBNAIL-IMAGE-WIDTH-LARGE-PX:  @THUMBNAIL-IMAGE-WIDTH-PX + 80px;
@SEARCH-RESULT-ITEM-HOVER-BORDER-COLOR: @WKCE-BLUE-TINT3;

.responsive-horizontal-thumbnails .utd-thumbnail__container,
.thumbnail-horizontal.utd-thumbnail__container {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;

  &.thumbnail-border {
    height: auto;
  }

  .utd-thumbnail__image-container {
    min-width: @THUMBNAIL-IMAGE-WIDTH-PX; // IE11 support
  }

  .utd-thumbnail__label-anchor {
    width: 100%; // IE11 support
    height: auto;
    .ds1-mv-0();
    .ds1-mh-2();
  }
}

.utd-thumbnail__container {
  .ds1-mb-2();
  width: @THUMBNAIL-CONTAINER-WIDTH-PX;
  display: inline-block;
  padding: 0;
  vertical-align: top;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;

  &:hover:not(.thumbnail-border) {
    .utd-thumbnail__image-container {
      box-shadow: @DS1-BOX-SHADOW-THUMBNAILS;
    }
  }

  &.thumbnail-border {
    height: 291px;
    border: 2px solid @WKCE-GRAY-TINT4;

    &:hover {
      border: 2px solid @SEARCH-RESULT-ITEM-HOVER-BORDER-COLOR;
      box-shadow: @DS1-BOX-SHADOW-OVERLAY;
    }
  }
}

.utd-thumbnail__image-container {
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  width: @IMAGE-CONTAINER-WIDTH-PX;
  height: $width;
  margin: auto;

  .thumbnail-border & {
    border: 4px solid @DS1-UTD-PRIMARY-BG-COLOR;
    width: @THUMBNAIL-IMAGE-WIDTH-PX;
    height: $width;

    .utd-thumbnail__image-anchor {
      border: none;
    }
  }

  .utd-thumbnail__image-anchor {
    border: @DS1-UTD-GRAY-SINGLE-BORDER;
  }
}

.utd-thumbnail__image-anchor {
  display: block;
  width: @THUMBNAIL-IMAGE-WIDTH-PX;
  height: $width;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  cursor: pointer;
}

.utd-thumbnail__label-anchor {
  .ds1-mh-compact();
  .ds1-utd-size-1();
  height: 74px;
  padding-top: 4px;
  cursor: pointer;
  overflow: hidden;

  .thumbnail-border & {
    .ds1-utd-size-2();
    .ds1-utd-weight-500();
    height: 128px;
    margin-left: 12px;
    margin-right: 12px;
    line-height: 18px;

    > span {
      .line-clamp(7);
    }
  }

  > span {
    word-wrap: break-word;
  }
}

@media (min-width: 768px) {
  // Unfortunately necessary to override overly generic rule in search.less
  .search-results ul.utd-listView li a.utd-thumbnail__container:not(.thumbnail-horizontal) {
    width: @THUMBNAIL-CONTAINER-WIDTH-PX;
  }

  .utd-thumbnail__container {
    margin-right: 32px;
  }

  .utd-thumbnail__label-anchor {
    height: 108px;
  }
}

@media only screen and (min-width: 1280px) {
  .responsive-horizontal-thumbnails .utd-thumbnail__container.thumbnail-large,
  .utd-thumbnail__container.thumbnail-large:not(.thumbnail-horizontal) {
    width: 250px;

    &.thumbnail-border {
      height: 340px;
    }

    .utd-thumbnail__image-container {
      width: @THUMBNAIL-IMAGE-WIDTH-LARGE-PX;
      height: @THUMBNAIL-IMAGE-WIDTH-LARGE-PX;
    }

    .utd-thumbnail__image-anchor {
      width: @THUMBNAIL-IMAGE-WIDTH-LARGE-PX;
      height: @THUMBNAIL-IMAGE-WIDTH-LARGE-PX;
    }

    .utd-thumbnail__label-anchor {
      height: 92px;

      > span {
        .line-clamp(5);
      }
    }
  }

  .search-results ul.utd-listView li a.utd-thumbnail__container.thumbnail-large {
    &:not(.thumbnail-horizontal) {
      width: @THUMBNAIL-IMAGE-WIDTH-LARGE-PX + 24px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .responsive-horizontal-thumbnails .utd-thumbnail__container {
    display: block;
    height: 291px;
    width: 155px;

    &.thumbnail-border {
      height: 291px;
    }

    .utd-thumbnail__label-anchor {
      width: auto;
      margin: auto 12px;
    }
  }
}
</style>
