<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="wk-field wk-input" :class="inputClasses" v-bind="$attrs">
    <div class="wk-field-header">
      <label class="wk-field-label"
             :for="$attrs['input-id']"
             v-text="$attrs.label" />
      <div v-if="hasAttr('optional')">
        <span aria-hidden="true">Optional</span>
      </div>
    </div>
    <div v-if="$attrs.description" class="wk-field-tip" v-text="$attrs.description" />
    <div class="wk-field-body">
      <slot />
      <div class="wk-field-inlay">
        <span class="wk-field-icon wk-field-check-mark-icon">
          <span v-if="isSuccess"
                class="success-state-icon wkce-icon" />
        </span>
      </div>
    </div>
    <div v-if="isError && errorMessage"
         class="wk-field-error"
         v-html="errorMessage" />
  </div>
</template>
<script>
export default {
    props: {
        inputSize: {
            type: String,
            default: 'small'
        },
        errorMessage: {
            type: String,
            default: ''
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        inputClasses() {
            return [
                this.$attrs['type-class'],
                { 'wk-field-errored': this.isError },
                { 'wk-field-success': this.isSuccess },
                { 'wk-field-is-disabled': this.isDisabled },
                { 'wk-field-has-required-indicator': this.hasAttr('required') },
                { 'wk-field-optional': this.hasAttr('optional') }
            ];
        }
    },
    methods: {
        hasAttr(attr) {
            return this.$attrs[attr] === true;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-js2 .wk-field-label {
  .ds1-utd-weight-600();
}

.wk-js2 .wk-field-success {
  input {
    padding-right: 40px;
  }

  &.wk-field-small {
    input {
      padding-right: 30px;
    }
  }
}

.wk-js2 .success-state-icon {
  .ds1-ph-1();

  &::after {
    content: '\E863';
    font-family: 'wk-icons-filled';
    color: @WKCE-GREEN-SHADE1;
    font-size: 14px;
  }
}
</style>
