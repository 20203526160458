/** Function called from topic.controller, graphic.controller, and single-graphic.directive
    Receives object containing following properties and function pointers:
    > tools
    > tooltip
    > bookmarks
    > permissions
    > fnAddBookmark
    > fnRemoveBookmark
    > fnCloseTooltip
    > fnSetupTooltip
    > fnSetBookmarkActive << Temporary parameter so this works with both graphics and topics
 */
import i18next from 'i18next';

export const toggleBookmark = bm => {
    if (bm.tools.bookmarkIsActive) {
        bm.fnSetBookmarkActive(false);
        bm.fnRemoveBookmark();

        // If we are displaying the "Manage Bookmark" tooltip, hide it
        bm.fnCloseTooltip();
    }
    else if (bm.bookmarks.length >= bm.permissions.bookmarkLimit) {
        bm.tooltip.message = i18next.t('MYUPTODATE.TOOLTIP_BOOKMARK_FULL');
        bm.tooltip.show = true;
        bm.tooltip.showTip = true;
    }
    else {
        bm.fnSetBookmarkActive(true);
        bm.fnAddBookmark();
    }
};
