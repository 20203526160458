<template>
  <utd-modal-dialog ref="modalDialog"
                    :modal-classes="'my-account-error-dialog'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop">
    <template #header>Welcome to My Account</template>
    <div class="my-account-error-dialog-text">
      <div v-if="isUnregisteredUser()" class="my-account-error-explanation">
        <p class="register-text">
          You must register with UpToDate before using the features of My Account.</p>
        <p class="register-link"><a href="/login-register?source=my-account-page">
          Click here to register.</a></p>
      </div>
      <div v-else-if="isDisasterRecovery" class="my-account-error-explanation">
        <p>{{ disasterExplanation }}</p>
        <p>{{ disasterApology }}</p>
      </div>
      <div v-else>
        <p>My Account is unavailable at this time.</p>
      </div>
    </div>
    <template #footer>
      <utd-button @click="closeModal()">Close</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters } from 'vuex';
import { C_MY_ACCOUNT, BCDR_MESSAGE } from '_acaSrc/utility/constants';


export default {
    components: {
        UtdModalDialog,
        UtdButton
    },
    data() {
        return {
            disasterExplanation: BCDR_MESSAGE.DEFAULT,
            disasterApology: BCDR_MESSAGE.APOLOGY
        };
    },
    computed: {
        ...mapGetters('account', [ 'myAccountAccessLevel' ]),
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapGetters('login', [ 'renewalSubscriptionUrl' ]),
        ...mapGetters('app', [ 'isDisasterRecovery' ])
    },
    methods: {
        openModal() {
            this.$refs.modalDialog.open();
        },
        closeModal() {
            this.$refs.modalDialog.close();
        },
        isUnregisteredUser() {
            return this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.UNREGISTERED;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.my-account-error-dialog {
  .utd-modal-content .utd-dialog-frame {
    width: 360px;

    .utd-dialog-content {
      .ds1-pa-2();
    }
  }
}

@media screen and (max-width: 768px) {
  .my-account-error-dialog {
    .utd-modal-content .utd-dialog-frame {
      width: auto;
      max-width: 360px;
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.my-account-error-dialog {
  .register-text,
  .register-link {
    .ds1-reset-p();
  }

  .register-text {
    .ds1-mb-2();
  }
}
</style>
