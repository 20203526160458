<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="content-container">
    <div v-for="(topic, topicIndex) in searchResults" 
         :key="topic.topicTitle + topicIndex" 
         class="ai-topic">
      <div v-for="(passage, passageIndex) in topic.passages" 
           :key="`${passage.snippet}: ${topicIndex}-${passageIndex}`"
           class="ai-passage"
           :class="{'multi-passage': topic.passages.length > 1}">
        <div class="passage-body">
          <div class="topic-content">
            <div id="ai-topic-snippet"
                 class="topic-snippet utd-longform-content-styles-v1">
              <span class="snippet"
                    :section="getSection(passage?.sectionHierarchy)"
                    :section-id="passage?.sectionId"
                    :display-rank="passage?.passageNum"
                    :search-rank="passage?.searchRank"
                    v-html="passage.snippet" />
              <a id="read-more"
                 class="topic-read-more"
                 :href="getTopicSectionHref(topic, passage)"
                 target="utd-ai">
                Read more
              </a>
            </div>
            <utd-media-collection class="passage-graphic collection-type-thumbnails"
                                  :media-collection="passage.associatedGraphics"
                                  :skip-sidebar-state-reset="false" />
          </div>
        </div>
        <div class="breadcrumbs">
          <span class="source"> SOURCE: </span>
          <span class="topic-title breadcrumb"> {{ topic.topicTitle }} </span>
          <span v-for="(section, sectionIndex) in passage.sectionHierarchy"
                :key="`${section}: ${topicIndex}-${passageIndex}-${sectionIndex}`" 
                class="breadcrumb">
            <span> {{ section }} </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtdMediaCollection from '_acaSrc/components/shared/stdlib/UtdMediaCollection.vue';
import { C_AI_SEARCH } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper } from '_acaSrc/utility/http';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdMediaCollection
    },
    props: {
        searchResults: {
            type: Array
        }
    },
    data() {
        return {
            hasSetPassageNum: false
        };
    },
    computed: {
        ...mapGetters('search', [ 'searchParamsSearchText' ])
    },
    methods: {
        getSection(sectionHierarchy) {
            if (!Array.isArray(sectionHierarchy) || sectionHierarchy.length < 1) {
                return;
            }
            return sectionHierarchy[sectionHierarchy.length - 1] || '';
        },
        getOverallPassageNum() {
            if (!this.searchResults) {
                return;
            }
            let curPassage = 1;
            this.searchResults.forEach(topic => {
                if (topic.passages) {
                    topic.passages.forEach(passage => {
                        passage.passageNum = curPassage;
                        curPassage++;
                    });
                }
            });
            this.hasSetPassageNum = true;
        },
        getHighestRank(topic, rankType) {
            if (!topic?.passages || !Array.isArray(topic.passages)) {
                return;
            }
            const highestRankPassage = topic.passages.reduce(
                (accumulator, currentValue) => {
                    const accumulatorVal = accumulator[rankType] || null;
                    const curVal = currentValue[rankType] || null;
                    return accumulatorVal && accumulatorVal <= curVal
                        ? accumulator
                        : currentValue;
                }
            );
            return highestRankPassage[rankType] || null;
        },
        // eslint-disable-next-line complexity
        generateTopicEventQps(topic, passage, curHref) {
            if (!this.hasSetPassageNum) {
                this.getOverallPassageNum(); 
            }

            let rankIndex = passage && passage.passageNum || '';
            let searchInfo = passage && passage.searchRank || '';

            if (topic && !passage) {
                rankIndex = this.getHighestRank(topic, 'passageNum');
                searchInfo = this.getHighestRank(topic, 'searchRank');
            }

            const params = {
                sourceValue: C_AI_SEARCH.EVENTS.TOPIC_SOURCE_RM,
                topicRef: topic && topic.topicId || '',
                usageType: C_AI_SEARCH.EVENTS.USAGE_TYPE,
                anchor: passage && passage.sectionId || '',
                searchTerm: this.searchParamsSearchText || '',
                rankIndex,
                searchInfo
            };

            return setSearchUrlParamsHelper(curHref, params);
        },
        getTopicSectionHref(topic, passage) {
            let href = `/contents/${topic.topicId}?source=`;
            href = this.generateTopicEventQps(topic, passage, href);
            href += `#${passage.sectionId}`;
            return href;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) "~_acaAssets/global/variables.less";

.content-container {
  .ds1-pb-4();

  .ai-topic {
    .ds1-pt-2();

    &:nth-of-type(n + 2) { // Line between every topic following the first topic
      .ds1-mt-2();
      border-top: 1px solid @WKCE-BLUE-TINT2;
    }

    .breadcrumbs {
      .ds1-mt-1();
      display: block;
      min-height: 24px;

      .source {
        .ds1-mr-1();
        font-size: 12px;
        font-weight: 400;
        color: @WKCE-GREEN-SHADE2;
        letter-spacing: 1px;
      }

      .breadcrumb {
        .ds1-mr-1();
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;

        > span {
          white-space: balance;
          text-wrap: balance;
        }

        &.topic-title {
          font-size: 14px;
          white-space: balance;
          text-wrap: balance;
        }
        
        &::before { // Chevron before each breadcrumb
          .ds1-mr-1();
          content: @WK_ICON_CHEVRON_RIGHT;
          font-family: "wk-icons-open";
          width: 12px;
          height: 12px;
          font-size: 10px;
          color: @WKCE-GRAY-TINT1;
          vertical-align: 1px;
          font-weight: 400;
          text-decoration: none;
          display: inline-block;
        }

        &:nth-child(2)::before { // No chevron before first breadcrumb
          display: none;
        }
      }
    }

    .ai-passage {
      &.multi-passage:nth-child(n + 2) { // Results with multiple passages under the same topic
        .ds1-mt-2();
        .ds1-pt-2();
        border-top: 1px solid @WKCE-BLUE-TINT2;
      }

      .passage-body .topic-content {
        // Flex styles used for featured graphics (not Phase 1)
        display: flex; 
        justify-content: space-between;
        width: 100%;

        .topic-snippet {
          font-size: 16px;
          line-height: 26px;

          .snippet {
            > :deep(div) {
              display: inline;
            }
          }

          .topic-read-more {
            .ds1-ml-compact();
            font-weight: 600;
            font-size: 12px;
            color: @WKCE-BLUE-SHADE1;
            transition: border-color 0.25s ease-in-out,
                        background-color 0.25s ease-in-out;
            white-space: nowrap;
            padding: 2px 22px 2px 6px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiDQogICBoZWlnaHQ9IjEyIg0KICAgdmlld0JveD0iMCAwIDEzIDEyIg0KICAgZmlsbD0ibm9uZSINCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuOTcyMTIgMC4zOTk5MDJIMi4xNzIxMlYxMS41OTk5SDEwLjk3MjFWNC4zOTk5TDYuOTcyMTIgMC4zOTk5MDJaTTkuODIyMTIgNC4zOTk5SDYuOTcyMTJWMS41NDk5TDkuODIyMTIgNC4zOTk5Wk0yLjk3MjEyIDEwLjc5OTlIMTAuMTcyMVY1LjE5OTlINi4xNzIxMlYxLjE5OTlIMi45NzIxMlYxMC43OTk5WiINCgkgIGZpbGw9IiMwMDVCOTIiIC8+DQo8cmVjdCB4PSI0LjA3MjE0Ig0KCSAgeT0iOC41Ig0KCSAgd2lkdGg9IjUiDQoJICBoZWlnaHQ9IjAuNSIgDQoJICBmaWxsPSIjMDA1QjkyIiAvPg0KPHJlY3QgeD0iNC4wNzIxNCINCgkgIHk9IjciDQoJICB3aWR0aD0iNCINCgkgIGhlaWdodD0iMC41Ig0KCSAgZmlsbD0iIzAwNUI5MiIgLz4NCjwvc3ZnPg==);
            background-repeat: no-repeat;
            background-size: 14px;
            background-position-y: 4px;
            background-position-x: 73px;
            background-color: @WKCE-WHITE;
            border: 1px solid @WKCE-BLUE-TINT3;
            border-radius: 7px;
            line-height: 16px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .passage-graphic { // Featured graphic support (not Phase 1)
          .ds1-mt-1();

          :deep(.collection-thumbnails) {
            flex-direction: column;

            .utd-thumbnail__container {
              display: flex;
              align-items: center;
              width: 245px;
              height: 92px;
              margin: 0;

              &:nth-child(n + 2) {
                margin-top: 10px;
              }

              .utd-thumbnail__image-container {
                width: 65px;
                height: 65px;

                .utd-thumbnail__image-anchor {
                  width: 65px;
                  height: 65px;
                }
              }

              .utd-thumbnail__label-anchor {
                .ds1-mv-0();
                .ds1-mh-1();
                width: 100%; // IE11 support
                height: auto;
                font-size: 9.5px;
                line-height: 14px;
                color: @WKCE-BLUE-SHADE1;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) { 
  .isDesktop .content-container {
    .ds1-pb-2();
  }

  .isDesktop .ai-topic {
    .ds1-pt-3();
    margin-right: 36px;
    margin-left: 36px;

    #read-more {
      margin-left: 13px;
    }

    #ai-topic-snippet {
      line-height: 24px;
    }
  }
}
</style>