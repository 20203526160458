<template>
  <div class="utd-expand-collapse__container"
       :class="{ 'is-collapsed': isCollapsed,
                 'show-collapse': showCollapse }">
    <div class="utd-expand-collapse__slot"
         :style="slotHeight">
      <slot />
    </div>
    <div class="utd-expand-collapse__expand">
      <div class="utd-expand-collapse__expand-arrow"
           :class="expandCollapseIcon"
           @click="onClick()" />
    </div>
  </div>
</template>

<script>
import { getWindow } from '_acaSrc/utility/DOM';

const DEFAULT_COLLAPSED_HEIGHT = 325;

export default {
    props: {
        maxHeight: {
            type: [ String, Number ],
            default: DEFAULT_COLLAPSED_HEIGHT
        }
    },
    emits: [ 'isCollapsed' ],
    data() {
        return {
            collapsedHeight: DEFAULT_COLLAPSED_HEIGHT,
            isCollapsed: false,
            showCollapse: false
        };
    },
    computed: {
        slotHeight() {
            return this.isCollapsed
                ? { maxHeight: `${this.collapsedHeight.toString()}px` }
                : { };
        },
        expandCollapseIcon() {
            if (this.isCollapsed) {
                return 'wkce-icon-chevron-down';
            }
            else if (this.showCollapse) {
                return 'wkce-icon-chevron-up';
            }

            return '';
        }
    },
    mounted() {
        this.validateMaxHeight();
        this.$nextTick(() => {
            // Store all immediate children of slot for faster computation
            this.slottedElements = Array.from(
                document.querySelectorAll('.utd-expand-collapse__slot > *')
            );

            this.setExpandCollapseState();

            this.resizeListener
                = this.setResizeListener(getWindow(), this.setExpandCollapseState);
        });
    },
    beforeUnmount() {
        this.clearResizeListener(this.resizeListener);
    },
    methods: {
        validateMaxHeight() {
            if (!isNaN(this.maxHeight)) {
                this.collapsedHeight = this.maxHeight;
            }
        },
        onClick() {
            this.isCollapsed = !this.isCollapsed;
            this.$emit('isCollapsed', this.isCollapsed);
        },
        setExpandCollapseState() {
            const contentHeightPx = this.slottedElements.reduce((curHeight, curEl) => {
                return curHeight + Math.ceil(curEl.getBoundingClientRect().height);
            }, 0);

            if (contentHeightPx > this.collapsedHeight) {
                if (!this.showCollapse) {
                    this.isCollapsed = true;
                    this.showCollapse = true;
                }
            }
            else {
                this.isCollapsed = false;
                this.showCollapse = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-expand-collapse__container {
  position: relative;
}

.is-collapsed {
  .utd-expand-collapse__slot {
    overflow: hidden;
    position: relative;
  }

  &::after {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, @DS1-UTD-PRIMARY-BG-COLOR);
    height: @ds1-spacing-multiplier * 5;
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &.hide-expand-gradient:after {
    background: transparent;
  }
}

.utd-expand-collapse__expand {
  .ds1-utd-size-2();
  .ds1-mh-2();
  color: @DS1-UTD-JS2-LINK-BASE;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;

  .is-collapsed & {
    display: block;
    box-shadow: @DS1-BOX-SHADOW-ABOVE;

    .hide-expand-gradient & {
      box-shadow: none;
    }
  }
}

.utd-expand-collapse__expand-arrow {
  .border-radius(50%);
  .ds1-utd-weight-bold;
  cursor: pointer;
  width: 28px;
  height: 28px;
  color: @DS1-UTD-JS2-LINK-BASE;
  background-color: @DS1-UTD-LIGHT-BLUE-BG-COLOR;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  z-index: 2;
  display: none;

  @media only screen and (min-width: 768px) {
    .isDesktop & {
       top: 0;
    }
  }

  &::before {
    position: relative;
    top: 6px;
  }

  .is-collapsed &,
  .show-collapse & {
    display: block;
  }
}

</style>