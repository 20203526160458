<template>
  <div v-if="showTicker" class="my-account-sidebar">
    <div class="sidebar__container">
      <div class="sidebar__header">CME/MOC/STATE</div>
      <div class="sidebar__content">
        <div class="sidebar__cme-credits ds1-pb-1">
          You currently have
          <span class="sidebar__cme-credits-label sidebar-bolded">
            {{ tickerValue }} {{ cmeLabel }} credits
          </span>
        </div>
        <span class="sidebar__link-container">
          <span class="wkce-icon-home" />
          <a class="sidebar__cme-home-link"
             href="#"
             @click="openCmeHome()">{{ cmeLabel }} Home</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_CME_UI_LINKS } from '_acaSrc/utility/constants';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

export default {
    emits: [ 'close-sidebar' ],
    data() {
        return {
            isDialogOpen: false,
            cmeUrls: C_CME_UI_LINKS
        };
    },
    computed: {
        ...mapGetters('app', [ 'isMobileOnDesktop', 'router' ]),
        ...mapGetters('cme', [ 'cmeLabel', 'showTicker', 'tickerValue' ]),
        ...mapGetters('profile', [
            'welcomeName',
            'userEmail'
        ])
    },
    methods: {
        openCmeHome() {
            logUiClickEventHelper({
                targetUrl: this.cmeUrls.dashboard,
                uiElementName: 'myAccountCme_cmeHomeLink'
            });
            const options = {
                doEmit: true,
                doRemoveQueryParam: false
            };
            this.$emit('close-sidebar', options);
            this.router.url(this.cmeUrls.dashboard);
        }
    }
};
</script>