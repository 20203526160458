import { C_QAP } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper, setQueryParamValue } from '_acaSrc/utility/http';
import QapDto from './qap.dto';
import { cloneDeep } from 'lodash';

// Data transformation object class for Lab Interpretations QAP (Quick Access Panel)
// ---------------------------------------------------------------------------------
// This DTO differs from other QAP types in that the search results can
// contain Lab Interpretations QAP entries in any result. While other QAP types will
// have a single result contain all of that specific QAP data.

// Other QAP types will have their multiple entries contained within a single
// search result entry.

// The first Lab Interpretation QAP result encountered will initialize this class and go through
// the _setLabInterpretationContents() path. Subsequent Lab Interpretation QAP results will execute
// the member method "processNextLabInterpretation()" from the first Lab Interpretation QAP object.
//
// [Class Properties]
//  :"this"
//  Contains the data properties used to display the "Right Side" desktop QAP.
//
//  :"result"
//  Contains the data properties used to render the "inline" QAP for mobile/tablet results.
//
// Both "this" and "result" share similar properties, but will differ in the URL
// query parameters present in their URLs. Specifically for "display_rank" and "source".

// For QapContent, QapSection, and QapSectionItem schemas, see "QAP Property Definitions"
// section of Confluence page:
// https://confluence.ce.wolterskluwer.io/display/WAT/Converting+existing+QAPs+into+new+framework

/**
 * @typedef {object} QapSectionItem
 *      @property {string} id
 *      @property {string} url
 *      @property {string} label
 *      @property {string} imgsrc
 *      @property {string} detail
 */
/**
 * @typedef {object} QapSection
 *      @property {string} type
 *      Set by parent class to simply "QAP". Assignment overrides original QAP templating.
 *      @property {Array.<QapSectionItem>} items
 */

/**
 * @typedef {object} QapContent
 *      @property {Array.<QapSection>} sections
 */

/**
 * @typedef {object} result
 * Represents the original QAP result as found in the response.
 * Used to render the mobile/tablet QAP.
 *      @property {string} panelType
 *      @property {string} subtype
 *      Set to constant "PATHWAYS". Controls template rendering
 *      @property {Array.<QapContent>} qapContents
 *      @property {boolean} isPanelResult
 *      When true is rendered by AngularJS templating system
 *      @property {boolean} hideInResults
 *      When true prevents result from displaying in normal result stream
 */

/**
 * @typedef {object} LabInterpretationDto
 *      @property {string} panelType
 *      See constants.js C_QAP.PANEL - will be "PATHWAYS" for this object
 *       @property {string} subtype
 *      Set identical to panelType to bypass original processing
 *      @property {object} firstResult
 *      Maintains pointer to first Lab Interpretations QAP result that
 *      all subsequent Lab-I results get appended to.
 *      @property {Array.<QapContent>} qapContents
 *      Array of QAP content objects used to render desktop QAP
 *       @property {result} result
 *      The inline search result used to render mobile/tablet QAP
 */
export default class LabInterpretationDto extends QapDto {
    /**
     * Instantiate LabInterpretationDTO object
     * @constructor
     * @param {object} payload
     * Individual result from search results containing Lab Interpretation QAP data
     */
    constructor(payload) {
        // Ensure we maintain the Lab Interpretations title "as-is" from the result.
        payload.retainOriginalTitle = true;
        super(payload);

        const { result, rankIndex } = payload;

        result.panelType = C_QAP.PANEL.LABI;
        result.subtype = result.panelType;

        this.panelType = result.panelType;
        this.subtype = result.subtype;
        this.firstResult = result;

        this._setFirstLabInterpretationContents(result, rankIndex);

        result.qapContents = cloneDeep(this.qapContents);

        // Set correct URL params for desktop QAP
        this.qapContents[0].sections[0].items.forEach(item => {
            item.url = setSearchUrlParamsHelper(result.url, { rankIndex: 1 });
            item.url = setQueryParamValue(item.url, 'source', 'panel_search_results');
        });
    }

    _getResultThumbnail(result) {
        return result.searchResults
            .find(subres => subres.type === 'graphic'
                     && subres.subtype.indexOf('graphic_') > -1
                     && subres.thumbnailUrl);
    }

    _createLabInterpretationSection(payload) {
        const { result, url, title } = payload;
        const imageSrc = this._getResultThumbnail(result);
        return {
            type: 'accordion',
            title,
            items: [{
                id: result.id,
                url,
                label: title,
                imgsrc: imageSrc && imageSrc.thumbnailUrl,
                detail: imageSrc && imageSrc.url
            }]
        };
    }

    _setFirstLabInterpretationContents(result, rankIndex) {
        const { title } = result;

        const url = setSearchUrlParamsHelper(result.url, { rankIndex });

        this.qapContents = [{
            sections: [ this._createLabInterpretationSection({ result, url, title }) ]
        }];
    }

    processNextLabInterpretation(payload) {
        const { result, rankIndex } = payload;
        const { title } = result;

        // Set appropriate URL params for desktop QAP
        let panelUrl = setSearchUrlParamsHelper(result.url, {
            rankIndex: this.qapContents[0].sections.length + 1
        });
        panelUrl = setQueryParamValue(panelUrl, 'source', 'panel_search_results');

        this.qapContents[0].sections.push(
            this._createLabInterpretationSection({ result, url: panelUrl, title })
        );

        // Turns off "classic" AngularJS QAP templating
        result.isPanelResult = false;

        // Prevent result from displaying in normal result stream
        result.hideInResults = true;

        // Update the first QAP result
        const resultUrl = setSearchUrlParamsHelper(result.url, { rankIndex });
        this.firstResult.qapContents[0].sections.push(
            this._createLabInterpretationSection({ result, url: resultUrl, title })
        );
    }
}
