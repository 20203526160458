import { cloneDeep } from 'lodash';
import { C_QAP } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper } from '_acaSrc/utility/http';
import QapDto from './qap.dto';

export default class InternationalDrugDto extends QapDto {
    constructor(payload) {
        super(payload);

        const { result, rankIndex } = payload;

        result.panelType = C_QAP.PANEL.DRUG_INT;
        result.subtype = result.panelType;

        this.panelType = result.panelType;
        this.subtype = result.subtype;

        this._setInternationalDrugContents(result, rankIndex);

        result.qapContents = cloneDeep(this.qapContents);
    }

    _setInternationalDrugContents(result, rankIndex) {
        let activeDisplayRank;

        this.qapContents = result.searchResults
            .filter(result => result.type === 'drug')
            .map(result => {
                const sections = this._collectPanelSections(result, rankIndex);
                return {
                    contentId: result.id,
                    contentLabel: 'QAP_TABS.drug_general',
                    sections,
                    type: result.subtype,
                    title: result.title,
                    url: setSearchUrlParamsHelper(result.url, {
                        rankIndex,
                        kpTab: result.subtype
                    })
                };
            });
        return activeDisplayRank;
    }

    _collectPanelSections(result) {
        let sections = [];

        const accordions = this._getIntDrugAccordions(result);
        if (accordions) {
            sections.push(accordions);
        }

        if (sections.length === 0) {
            sections = null;
        }
        return sections;
    }

    _getIntDrugAccordions(result) {
        const accordions = result.drugPanel
                        && result.drugPanel.accordion.map(accordion => {
                            return {
                                label: accordion.name,
                                detail: accordion.value
                            };
                        }) || [];

        return accordions
            && accordions.length
            && {
                type: C_QAP.SECTION.ACCORDION,
                items: accordions
            };
    }
}
