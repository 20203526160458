const tocRoot = rootGetters => {
    const isUccState = rootGetters['app/isUccState'];
    const isLabI = rootGetters['feature/isShowLabI'];
    const showDrugInteractionsApp = rootGetters['feature/showDrugInteractionsApp'];

    return [
        {
            navClass: 'navbarTocWhatsNew',
            url: 'whats-new',
            key: 'HEADER.WHATS_NEW',
            hasTocPrefix: true,
            hasContentsPrefix: true,
            featureCheck: () => !isUccState,
            redesignPosition: 2
        },
        {
            navClass: 'navbarTocPcu',
            url: 'practice-changing-updates',
            key: 'HEADER.PRACTICE_CHANGING_UPDATES',
            notLocalized: true,
            hasContentsPrefix: true,
            featureCheck: () => !isUccState,
            redesignPosition: 1
        },
        {
            navClass: 'navbarTocLabI',
            url: 'lab-interpretation',
            key: 'SEARCH.LAB_INTERPRETATION',
            hasTocPrefix: true,
            hasContentsPrefix: true,
            featureCheck: () => isLabI,
            redesignPosition: 4
        },
        {
            navClass: 'navbarTocDrugInfo',
            url: 'drug-information',
            key: 'HEADER.DRUG_INFORMATION',
            hasTocPrefix: true,
            hasContentsPrefix: true,
            redesignPosition: 5
        },
        {
            navClass: 'navbarTocPatientEd',
            url: 'patient-education',
            key: 'HEADER.PATIENT_EDUCATION',
            hasTocPrefix: true,
            hasContentsPrefix: true,
            featureCheck: () => !isUccState,
            redesignPosition: 3
        },
        {
            navClass: 'navbarTocCalculators',
            url: 'calculators',
            key: 'HEADER.CALCULATORS',
            hasTocPrefix: true,
            hasContentsPrefix: true,
            featureCheck: () => isUccState
        },
        {
            navClass: 'navbarTocSpecialties',
            url: 'table-of-contents',
            hasContentsPrefix: true,
            key: 'HEADER.TOPICS_SPECIALTY',
            redesignPosition: 6
        },
        {
            navClass: 'navbarTocAuthorsEditors',
            url: 'authors-and-editors',
            key: 'HEADER.AUTHORS_EDITORS',
            notLocalized: true,
            hasContentsPrefix: true,
            featureCheck: () => !isUccState,
            redesignPosition: 7
        },
        {
            navClass: 'navbarTocDrugInteraction',
            url: '/drug-interactions?source=responsive_home',
            key: 'HEADER.DRUG_INTERACTIONS',
            notLocalized: true,
            featureCheck: () => isUccState && showDrugInteractionsApp
        }
    ];
};

export default tocRoot;