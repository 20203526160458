<template>
  <div class="utd-qap-v utd-qap-desktop-v">
    <div v-for="(panel, index) in qapPanels"
         :key="index"
         class="utd-qap-desktop-panel">
      <component :is="componentName(panel)"
                 :result="panel"
                 source="panel_search_result" />
    </div>
  </div>
</template>

<script>
import { QAP_COMPONENTS as componentKeys } from '_acaSrc/utility/component-dictionary';
import QapPcu from '_acaQap/panels/QapPcu.vue';
import QapDrugDXY from '_acaQap/panels/QapDrugDXY.vue';
import QapDrugFormulation from '_acaQap/panels/QapDrugFormulation.vue';
import QapRoutedDrug from '_acaQap/panels/QapRoutedDrug.vue';
import QapPathways from '_acaQap/panels/QapPathways.vue';
import QapLabInterpretations from '_acaQap/panels/QapLabInterpretations.vue';
import QapDrugInformation from '_acaQap/panels/QapDrugInformation.vue';
import QapInternationalDrug from '_acaQap/panels/QapInternationalDrug.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        QapPcu,
        QapDrugDXY,
        QapDrugFormulation,
        QapRoutedDrug,
        QapPathways,
        QapLabInterpretations,
        QapDrugInformation,
        QapInternationalDrug
    },
    computed: {
        ...mapGetters('qap', [ 'qapPanels' ])
    },
    methods: {
        componentName(panel) {
            return panel && panel.panelType && componentKeys[panel.panelType] || '';
        }
    }
};
</script>

<style lang="less" scoped>
.utd-qap-desktop-v {
  width: 100%;
  min-width: 320px;
  max-width: 450px;

  :deep(.qap-list-item) {
    line-height: 1;
  }
}
</style>