<template>
  <div id="utd-genericMenuContainer"
       class="utd-abs-white-carbon">
    <dl class="utd-tabControl">
      <dt>
        <span class="breadcrumb"><span v-text="$t('MYACCOUNT.SETTINGS')" /></span>
        <span v-text="$t('MYACCOUNT.POLICIESLEGAL')" />
      </dt>
    </dl>
    <div id="policiesContainer">
      <ul class="utd-menu utd-menu-legal">
        <li v-if="editorialPolicy">
          <a v-auto-anchor-attrs
             :href="editorialPolicy.url"
             v-text="editorialPolicy.title" />
          <i class="fa fa-angle-right" />
        </li>
        <li v-if="gradingGuide">
          <a v-auto-anchor-attrs
             :href="gradingGuide.url"
             v-text="gradingGuide.title" />
          <i class="fa fa-angle-right" />
        </li>
        <li v-if="conflictOfInterest">
          <a v-auto-anchor-attrs
             :href="conflictOfInterest.url"
             v-text="conflictOfInterest.title" />
          <i class="fa fa-angle-right" />
        </li>
        <li v-if="offLabelDrugUse">
          <a v-auto-anchor-attrs
             :href="offLabelDrugUse.url"
             v-text="offLabelDrugUse.title" />
          <i class="fa fa-angle-right" />
        </li>
        <li v-if="privacyPolicy">
          <a v-auto-anchor-attrs
             :href="privacyPolicy.url"
             v-text="privacyPolicy.title" />
          <i class="fa fa-angle-right" />
        </li>
      </ul>
    </div>
    <div id="legalSetContainer">
      <ul v-if="legalSet.agreements"
          class="utd-menu utd-menu-legal disclaimers">
        <li v-for="(entity, idx) in legalSet.agreements"
            :key="idx">
          <a v-auto-anchor-attrs
             :href="entity.link.url"
             v-text="entity.title" />

          <i class="fa fa-angle-right" />
        </li>
      </ul>
    </div>
    <div v-if="legalSet.error">
      This legal text was not found
    </div>
  </div>
</template>

<script>
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import AutoAnchorAttrs from '_acaSrc/directives/AutoAnchorAttrs.directive';

export default {
    directives: {
        AutoAnchorAttrs
    },
    props: {
        legalSet: Object
    },
    data() {
        return {
            policies: {}
        };
    },
    computed: {
        editorialPolicy() {
            return this.policies.editorialPolicy;
        },
        gradingGuide() {
            return this.policies.gradingGuide;
        },
        conflictOfInterest() {
            return this.policies.conflictOfInterest;
        },
        offLabelDrugUse() {
            return this.policies.offLabelDrugUse;
        },
        privacyPolicy() {
            return this.policies.privacyPolicy;
        }
    },
    async beforeMount() {
        this.policies = await utdRest('settings/policies');
    }
};
</script>