/* eslint-disable complexity */
import {
    getRelativeUrl,
    getQueryParamValues
} from '_acaSrc/utility/http';
import { getWindow, htmlToElem } from '_acaSrc/utility/DOM';
import { regexHref, getReplaceHref } from '_acaSrc/utility/contents/topic/topic';
import TopicGraphicsGallery from './TopicGraphicsGallery';
import { C_LANGUAGES_NAMES, C_TOPIC } from '_acaSrc/utility/constants';

const { ANCHOR_HASH_PREFIX } = C_TOPIC;

export default class UtdTopicCore {
    constructor() {
        this.initialize();
    }

    get isTopicCalculator() {
        return this.type === 'calc';
    }

    get isHasCalculatorContent() {
        return !!this.bodyDesktop
            && !!this.bodyMobile
            && !!this.calculatorScript.desktop
            && !!this.calculatorScript.mobile;
    }

    get isDrugDxySubtype() {
        return this.subtype === 'drug_dxy'
            || this.subtype === 'drug_dxy_package_insert';
    }

    get isMedicalBasics() {
        return this.subtype === 'medical_basics';
    }

    get isNarrativeLabI() {
        return this.subtype === 'narrative_labi';
    }

    get isLanguageEs419() {
        return this.language === 'es-419';
    }

    get isLanguageArabic() {
        return this.language === 'ar';
    }

    get isLanguageEnglish() {
        return this.language === C_LANGUAGES_NAMES.EN.CODE
            || this.language === C_LANGUAGES_NAMES.EN.COUNTRY_CODE;
    }

    get isLanguageChinese() {
        return this.language === C_LANGUAGES_NAMES.ZH_HANS.CODE
            || this.language === C_LANGUAGES_NAMES.ZH_HANT.CODE;
    }

    get topicHasError() {
        return this.topicError.isHasError;
    }

    get topicErrorFormulaLogs() {
        return this.topicError.formulaLogs;
    }

    set topicHasError(hasError) {
        this.topicError.isHasError = hasError;
    }

    set topicErrorStatus(status) {
        this.topicError.status = status;
        this.topicError.isHasError = status !== 0;
    }

    set topicErrorMessageKey(key) {
        this.topicError.messageKey = key;
    }

    set topicErrorFormulaLogs(logs) {
        this.topicError.formulaLogs = logs;
    }

    initialize() {
        this.data = '';
        this.id = '';
        this.specialtyId = '';
        this.title = '';
        this.language = 'en-US';
        this.type = '';
        this.subtype = '';
        this.version = '';
        this.hasInlineMedia = false;
        this.inlineMediaHeading = 'Graphics';
        this.inlineGraphics = [];
        this.inlineGraphicsType = '';
        this.showBookmarkLink = false;
        this.metaDescription = '';
        this.anchor = '';

        this.topicShowing = false;
        this.showOutline = false;
        this.showOutlineToggle = true;

        this.outlineVisible = false;
        this.outlineToggleVisible = true;

        this.bodyHtml = '';
        this.bodyMobile = '';
        this.bodyDesktop = '';
        this.outlineHtml = '';
        this.previousSection = '';
        this.topicGraphics = [];
        this.topicGraphicImageKeyMap = {};
        this.galleryGraphics = {};
        this.ibnList = [];
        this.injectableAssets = [];
        this.alternatePrintLanguages = [];
        this.machineLearningPaused = false;
        this.showTopicFeedback = false;
        this.isCanBookmark = false;
        this.isCanShare = false;
        this.showPrintLink = false;
        this.calculatorScript = {
            desktop: null,
            mobile: null
        };
        this.machineTranslationBundle = {};
        this.resetError();
    }

    resetError() {
        this.topicError = {
            formulaLogs: [],
            isHasError: false,
            messageKey: '',
            clearSearch: false,
            status: 0
        };
    }

    resolveTopic(data, forPrint = false) {
        this.resetError();
        try {
            this.data = data;

            this.id = data.topicInfo.id;
            this.title = data.topicInfo.title;
            this.language = data.topicInfo.languageCode;
            this.type = data.topicInfo.type;
            this.topicAnchor = getQueryParamValues(getRelativeUrl(), 'anchor');
            this.metaDescription = data.metaDescription;

            if (forPrint) {
                this.showOutlineToggle = false;
                this.outlineToggleVisible = false;

                this.bodyHtml = data.printHtml;
                this.setAlternatePrintLanguages();
                return;
            }

            this.subtype = data.topicInfo.subtype;
            this.version = data.topicInfo.version;

            this.isCanBookmark = data.isCanBookmark;
            this.isCanShare = data.isCanShare;
            this.isDrugLandingPage = data.topicInfo.isDrugLandingPage;
            this.showTopicFeedback = data.showTopicFeedback;
            this.showPrintLink = data.isCanViewPrintLink;
            this.machineTranslationBundle = data.machineTranslationBundle || {};

            this.topicGraphics = data.topicInfo.relatedGraphics || [];
            this.ibnList = data.topicInfo.internationalBrandNames || [];

            this.showOutlineToggle = !this.isDrugLandingPage && !this.isTopicCalculator;
            this.outlineToggleVisible = this.showOutlineToggle;

            this.inlineGraphics = data.inlineGraphics;
            this.processInlineGraphics();

            this.setOutlineAndBodyHtml(data);
            this.processCalculatorTitles();

            this.calculatorScript.desktop = data.calculatorJavaScript;
            this.calculatorScript.mobile = data.calculatorJavaScriptMobile;

            this.injectableAssets = data.injectableAssets || [];
            this.setBookmarkCurrentTopic();
            this.galleryGraphics = new TopicGraphicsGallery(this.topicGraphics, this.id);
            this.topicGraphicImageKeyMap = this.createGraphicImageKeyMap(this.topicGraphics);

            this.specialtyId = data.topicInfo.specialtyId;
        }
        catch (err) {
            this.topicErrorStatus = -2;
        }
    }

    /* Due to the volatile nature of the GVD's graphicsImageKeyMap,
    creating minimum stable imageKey map for use in inline graphics */
    createGraphicImageKeyMap(graphics) {
        const graphicImageKeyMap = {};
        graphics.forEach(graphic => {
            if (!graphic.imageKey) {
                return;
            }
            graphicImageKeyMap[graphic.imageKey] = { ...graphic };
        });
        return graphicImageKeyMap;
    }

    setAlternatePrintLanguages() {
        this.alternatePrintLanguages = [];
        if (this.data.topicInfo.languageDisplayNames) {
            for (const langId in this.data.topicInfo.languageDisplayNames) {
                this.alternatePrintLanguages.push({
                    id: langId,
                    name: this.data.topicInfo.languageDisplayNames[langId]
                });
            }
        }

    }

    processInlineGraphics() {
        this.inlineMediaHeading = 'Graphics';

        if (this.inlineGraphics && this.inlineGraphics.length
            && (this.isNarrativeLabI || this.isMedicalBasics)) {
            this.hasInlineMedia = true;
            this.inlineGraphicsType = 'algoLab';

            if (this.isMedicalBasics) {
                this.inlineMediaHeading = 'Videos';
                this.inlineGraphicsType = this.inlineGraphics[0].graphicInfo.type;
            }
        }
    }

    setOutlineAndBodyHtml(data = {}) {
        const {
            outlineHtml = '',
            bodyHtml = '',
            bodyHtmlMobile = ''
        } = data;
        this.outlineHtml = '';
        this.bodyMobile = '';
        this.bodyDesktop = '';

        if (outlineHtml) {
            this.outlineHtml = outlineHtml.replace(regexHref, getReplaceHref());
        }

        this.bodyHtml = !this.isDrugDxySubtype
            ? this.processBodyHtml(bodyHtml)
            : bodyHtml;

        if (bodyHtmlMobile) {
            this.bodyDesktop = this.bodyHtml;
            this.bodyMobile = !this.isDrugDxySubtype
                ? this.processBodyHtml(bodyHtmlMobile)
                : bodyHtml;
        }
        this.setOutlineVisible();
    }

    processBodyHtml(html) {
        if (!html) {
            return '';
        }
        html = html.replace(/id="H/g, `id="${ANCHOR_HASH_PREFIX}H`);
        html = html.replace(/id="F/g, `id="${ANCHOR_HASH_PREFIX}F`);
        html = html.replace(/id="references/g,
            `id="${ANCHOR_HASH_PREFIX}references`);

        return html.replace(regexHref, getReplaceHref());
    }

    setOutlineVisible() {
        this.outlineVisible = this.outlineHtml
                && this.outlineHtml.length > 10
                && this.type !== 'calc';
        this.showOutline = this.outlineVisible;

        this.topicShowing = !this.showOutline;

        if (this.isTopicCalculator) {
            this.outlineVisible = true;
        }
    }

    setBookmarkCurrentTopic() {
        this.currentTopic = {
            contentId: this.id,
            contentType: this.type,
            contentSubType: this.subtype,
            exists: true,
            languageCode: this.language,
            title: this.title,
            url: getWindow().location.pathname
        };
    }

    processCalculatorTitles() {
        if (this.type !== 'calc') {
            return;
        }

        if (this.bodyMobile !== '') {
            const mobileCalcEl = htmlToElem(this.bodyMobile);
            this.removeCalculatorTitlesHelper(mobileCalcEl);
            this.bodyMobile = mobileCalcEl.innerHTML;
        }

        if (this.bodyDesktop !== '') {
            const desktopCalcEl = htmlToElem(this.bodyDesktop);
            this.removeCalculatorTitlesHelper(desktopCalcEl);
            this.bodyDesktop = desktopCalcEl.innerHTML;
        }
    }

    removeCalculatorTitlesHelper(workingEl) {
        // This line isolates the element that immediately wraps the passed title.
        const mc3kEl = workingEl.querySelector('#mc3k');
        if (!mc3kEl) {
            return;
        }
        const calc = Array.from(mc3kEl.getElementsByTagName('*'))
            .find(el => el.childNodes.length === 1
            && el.textContent.indexOf(this.title) > -1
            && el.childNodes[0].nodeType === Node.TEXT_NODE);

        const tableEl = calc && calc.closest('table');
        if (tableEl) {
            tableEl.parentNode.removeChild(tableEl);
        }
    }
}
