import { C_SEARCH } from '_acaSrc/utility/constants';

export default class GraphicalAnswerResults {
    constructor(id, type, title, markupTitle, searchResults, {
        rankIndex,
        searchRank,
        searchTerm
    } = {}) {
        this.id = id;
        this.type = type || '';
        this.title = title;
        this.markupTitle = markupTitle;
        this.rankIndex = rankIndex;
        this.searchRank = searchRank;
        this.searchTerm = searchTerm;
        this.graphic = this.setGraphicalAnswer(searchResults) || [];
    }

    setGraphicalAnswer(searchResults) {
        if (!searchResults || !Array.isArray(searchResults)) {
            return;
        }
        return searchResults
            .map(result => {
                return {
                    imageSrcDesktop: result.desktopSnippet || '',
                    imageSrcMobile: result.snippet || '',
                    imageKey: result.id,
                    type: result.type,
                    title: result.title,
                    source: C_SEARCH.SOURCE.GRAPHICAL_ANSWERS,
                    searchRank: this.searchRank,
                    rankIndex: this.rankIndex,
                    searchTerm: this.searchTerm
                };
            });
    }

    get imageSrcMobile() {
        return this.graphic.length > 0
            && this.graphic[0].imageSrcMobile
            || '';
    }

    get imageSrcDesktop() {
        return this.graphic.length > 0
            && this.graphic[0].imageSrcDesktop
            || '';
    }

    get imageTitle() {
        return this.firstGraphicalAnswer.title
            || 'Title not found';
    }

    get firstGraphicalAnswer() {
        return this.graphic.length > 0
            && this.graphic[0]
            || {};
    }
}