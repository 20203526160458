<template>
  <footer v-if="isOidcFooterVisible">
    <div class="utd-oidc-footer-container utd-form">
      <ul class="ds1-utd-js2 ds1-utd-list-style-none utd-oidc-footer-links">
        <li>
          <a href="/settings/change-language">{{ $t("MYACCOUNT.LANGUAGE") }}</a>
        </li>
        <li>
          <a href="/settings/change-text-size">{{ $t("CONTENT.FONT_SIZE") }}</a>
        </li>
        <li>
          <a @click="showSupportTag($event)">{{ $t("SUPPORTTAG.SUPPORTTAG") }}</a>
        </li>
        <li v-if="isFooterLoaded">
          <a :href="getFooterUrl('contactUs')"
             target="_blank"
             rel="noopener">{{ $t('PROSPECT_FOOTER.CONTACT_US') }}
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('app', [
            'isAuthenticatedByIp',
            'showWkFooter',
            'isFooterLoaded',
            'routeLoading'
        ]),
        ...mapGetters('footer', [
            'getFooterUrl',
            'isFooterLoaded'
        ]),
        ...mapGetters('auth', [
            'isOidcUser'
        ]),
        ...mapGetters('search', [ 'isHomeSearchEmpty' ]),
        isOidcFooterVisible() {
            return this.isOidcUser
                && this.isHomeSearchEmpty
                && this.routeLoading === 'search'
                && !this.isAuthenticatedByIp
                && !this.showWkFooter;
        }
    },
    methods: {
        ...mapActions('footer', [ 'openSupportTag' ]),
        showSupportTag(event) {
            this.openSupportTag({ event });
        }
    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/wkce/colors/wkce-app-styles';

footer {
  .ds1-ma-0();
}

.utd-oidc-footer-container {
  display: flex;
  justify-content: flex-start;
}

.utdWkHeader footer {
  opacity: 100%;
}

.utd-oidc-footer-links {
  z-index: 1;
  bottom: 16px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  > li {
    font-size: 1em;
  }
}

@media only screen and (max-height: 753px) {
  .utd-oidc-footer-links {
    position: relative;
    margin-top: 40%;
  }
}

</style>
