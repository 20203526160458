<template>
  <utd-modal-dialog ref="shareFormModal"
                    modal-classes="shareForm ds1-utd-font"
                    :close-on-overlay-click="false"
                    :autofocus="enableAutoFocus"
                    :close-on-escape="false"
                    @closed="closeShareModal()">
    <template #header>Share</template>
    <div class="share_form_content">
      <div v-if="shareConfirmationModal"
           class="ds1-ph-2 ds1-pb-2"
           v-text="shareConfirmationMessage" />
      <utd-share-form v-if="shareFormVisible" />
    </div>
  </utd-modal-dialog>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import { mapGetters, mapMutations } from 'vuex';
import UtdShareForm from './UtdShareForm.vue';
import { safeTimeout } from '_acaSrc/utility/timers';
import {
    SET_SHARE_FORM_VISIBILITY,
    SET_SHARE_CONFIRMATION_MODAL_VISIBILITY,
    RESET
} from '_acaSrc/store/share.store';

const SHARE_FORM_DIALOG_DELAY_MS = 100;

export default {
    components: {
        UtdModalDialog,
        UtdShareForm
    },
    computed: {
        ...mapGetters('app', [ 'isAppDataLoaded', 'isTabletDevice' ]),
        ...mapGetters('share', [
            'shareFormFieldsFocused',
            'shareFormVisible',
            'shareConfirmationModal',
            'shareConfirmationMessage'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        enableAutoFocus() {
            return this.isDesktopView && !this.isTabletDevice;
        },
        modalVisible() {
            return this.shareFormVisible || this.shareConfirmationModal;
        }
    },
    watch: {
        modalVisible(newVal) {
            if (newVal) {
                this.showShareModal();
            }
            else {
                this.closeShareModal();
            }
        },
        shareFormVisible(newVal) {
            if (!newVal) {
                this.$refs.shareFormModal.close();
            }
        }
    },
    beforeUnmount() {
        this.closeShareModal();
        if (this.shareFormTimeout) {
            this.shareFormTimeout();
        }
        clearTimeout(this.domElementsTimeout);
    },
    methods: {
        ...mapMutations('share', [ SET_SHARE_FORM_VISIBILITY,
            SET_SHARE_CONFIRMATION_MODAL_VISIBILITY,
            RESET ]),
        closeShareModal() {
            if (this.shareFormVisible) {
                this[SET_SHARE_FORM_VISIBILITY](false);
            }
            else {
                this[SET_SHARE_CONFIRMATION_MODAL_VISIBILITY](false);
            }
            this[RESET]();
            clearTimeout(this.domElementsTimeout);
        },
        showShareModal(event) {
            if (event) {
                event.preventDefault();
            }
            // Make sure the app has finished loading before determining what to do
            this.shareFormTimeout = safeTimeout(() => {
                if (this.isAppDataLoaded) {
                    this.$refs.shareFormModal.open();
                }
            }, SHARE_FORM_DIALOG_DELAY_MS, { cancelHook: true }).cancelTimeout;
        }
    }
};
</script>
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.shareForm {
  position: fixed;

  .utd-modal-content {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) {
  .shareForm .utd-modal-content {
    width: 650px;
  }
}

/* in landscape... */
.isTablet {
  .shareForm {
    .form-field {
      max-width: inherit;
    }

    fieldset {
      .form-links {
        max-width: inherit;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.share_form_content {
  .ds1-pt-2();
  overflow: auto;
  box-sizing: border-box;
}
</style>
