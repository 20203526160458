import { C_HTTP_STATUS } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';
import { JL } from 'jsnlog';

export const initErrorState = () => {
    return {
        code: 0,
        message: '',
        clearSearch: false,
        params: {}
    };
};

const state = {
    errorClass: false,
    error: initErrorState()
};

export const getters = {
    errorClass: state => state.errorClass,
    errorMessage: state => state.error.message,
    errorParams: state => state.error.params,
    errorClearSearch: state => state.error.clearSearch,
    isUnspecifiedError: state => {
        return !state.error.message
            || state.error.code === C_HTTP_STATUS.SERVER_ERROR;
    }
};

export const SET_ERROR_CLASS = 'SET_ERROR_CLASS';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_ERROR_PARAMS = 'SET_ERROR_PARAMS';
export const SET_ERROR_CLEARSEARCH = 'SET_ERROR_CLEARSEARCH';
export const RESET_ERROR = 'RESET_ERROR';

export const mutations = {
    [SET_ERROR_CLASS]: (state, errorClass) => state.errorClass = errorClass,
    [SET_ERROR_CODE]: (state, code) => state.error.code = code,
    [SET_ERROR_MESSAGE]: (state, msg) => state.error.message = msg,
    [SET_ERROR_PARAMS]: (state, params) => state.error.params = params,
    [SET_ERROR_CLEARSEARCH]: (state, value) => state.error.clearSearch = value,
    [RESET_ERROR]: state => state.error = initErrorState()
};

export const actions = {
    resolveContent403({ commit }, error) {
        let isHasError = true;
        try {
            switch (error.data.errors[0].utdStatus) {      
            case 'REQUESTS_PER_SEARCH_LIMIT':
                // Redirect to search page
                isHasError = false;
                commit(SET_ERROR_CLEARSEARCH, true);
                getWindow().location.href = '/contents/search';
                break;

            case 'ANON_REQUESTS_PER_TIMESPAN_LIMIT_WITHOUT_CAPTCHA':
                isHasError = false;
                getWindow().location.href = '/login?captcha=true';
                break;

            case 'REQUESTS_PER_SESSION_LIMIT':
                // eslint-disable-next-line no-var
                var errorMessage = error.data.errors[0].message;
                JL('Angular.Ajax').fatalException({
                    errorMessage,
                    status: error.status,
                    config: error.config
                });

                commit(SET_ERROR_CLASS, true);
                commit(SET_ERROR_MESSAGE, errorMessage);
                getWindow().location.replace('/view-error');
                break;

            default:
                isHasError = false;
                getWindow().location.href = '/content-not-available';
                break;
            }
        }
        catch (ex) {
            isHasError = false;
            getWindow().location.href = '/content-not-available';
        }
        return isHasError;
    }
};

const error = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default error;