import { nullUndefinedOrEmpty } from '_acaSrc/utility/Validators';

export function makeRestUrl(baseUrl, language, appendPath) {
    if ((language !== 'en')) {
        baseUrl += segmentOrEmptyString(language);
    }
    if (!nullUndefinedOrEmpty(appendPath)) {
        baseUrl += appendPath;
    }
    return baseUrl;
}

export function makeTocPath(id, section) {
    if (nullUndefinedOrEmpty(id)) {
        return '';
    }
    return `/${id}${segmentOrEmptyString(section)}`;
}

export function segmentOrEmptyString(value) {
    return nullUndefinedOrEmpty(value) ? '' : `/${value}`;
}
