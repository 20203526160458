<template>
  <a class="utd-graphic-link__container utd-icon-before-anchor ds1-utd-size-2"
     :class="graphicType"
     :href="graphicUrl"
     :tabindex="tabIndex"
     @keypress="$event.which === ENTER_KEY && linkClicked()"
     @click="linkClicked">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="graphic.title" />
  </a>
</template>

<script>
import { C_KEYCODES } from '_acaSrc/utility/constants';
import { makeGraphicUrl } from '_acaSrc/utility/contents/graphic/graphic';

export default {
    props: {
        graphic: Object,
        tabIndex: {
            type: Number,
            default: null
        }
    },
    emits: [ 'utd-graphic-link-click' ],
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        graphicType() {
            return this.graphic.type && `graphic-type-${this.graphic.type.toLowerCase()}`;
        },
        graphicUrl() {
            if (!this.graphic.url) {
                return makeGraphicUrl(this.graphic);
            }
            return this.graphic.url;
        }
    },
    methods: {
        linkClicked(event) {
            event.preventDefault();
            this.$emit('utd-graphic-link-click', event);
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-graphic-link__container {
  display: inline-block;
}

[class*='graphic-type-']::before {
  .ds1-utd-image();
  margin-right: 4px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 2px;
}

.graphic-type-figure::before {
  .ds1-utd-figure();
}

.graphic-type-table::before {
  .ds1-utd-table();
}

.graphic-type-movie::before {
  .ds1-utd-movie();
}

.graphic-type-algorithm::before {
  .ds1-utd-algorithm();
}

</style>