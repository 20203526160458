import { setSearchUrlParamsHelper } from '_acaSrc/utility/http';
import QapDto from './qap.dto';

export default class DrugFormulationDto extends QapDto {
    constructor(payload) {
        super(payload);
        const { result } = payload;
        this._setUrlParams(result);
    }

    _setUrlParams(result) {
        result.qapContents.forEach(content =>
            content.sections.forEach(section => {
                if (section.type !== 'dip') {
                    section.items.forEach(item =>
                        item.url = setSearchUrlParamsHelper(
                            item.url, {
                                kpTab: item.subtype
                            })
                    );
                }
            })
        );
    }
}