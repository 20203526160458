<template>
  <div v-if="isDip" class="qap-drug-interactions">
    <!-- eslint-disable vue/no-v-html -->
    <a :href="setUrlSource"
       class="qap-link"
       target="_self"
       v-html="getTitle" />
  </div>
</template>

<script>
import { setQueryParamValue } from '_acaSrc/utility/http';
import { C_QAP } from '_acaSrc/utility/constants';

export default {
    props: {
        result: Object,
        source: String
    },
    computed: {
        isDip() {
            return this.result.dip
                || this.result.type === C_QAP.SECTION.DRUG_INTERACTIONS;
        },
        setUrlSource() {
            let url = '';
            if (this.result.dip) {
                url = this.result.dip.url;
            }
            else if (this.result.items && this.result.items.length) {
                url = this.result.items[0].url;
            }
            return setQueryParamValue(url, 'source', this.source);
        },
        getTitle() {
            let title = '';
            if (this.result.items && this.result.items.length) {
                if (this.result.items[0].label) {
                    title = this.result.items[0].label;
                }
                else if (this.result.items[0].title) {
                    // Support Routed Drug QAP
                    title = this.result.items[0].title;
                }
            }
            else if (this.result.dip) {
                title = this.result.dip.title;
            }
            else if (this.result.title) {
                title = this.result.title;
            }
            return `${title}<span class="wkce-icon-arrow-right ds1-pl-1"></span>`;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import "../vue-qap.less";

@QAP-DI-BGCLR: @WKCE-BLUE-TINT5;

.qap-drug-interactions {
    .ds1-ph-2();
    .ds1-pv-1();
    border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
    background-color: @DS1-UTD-GRAY-BG-COLOR;

    .isDesktop & {
        background-color: @QAP-DI-BGCLR;
    }

    .wkce-icon-arrow-right::before {
        font-size: 16px;
        display: inline-block;
        color: @DS1-QAP-BASE-LINK-COLOR;
    }

    .qap-link:hover .wkce-icon-arrow-right::before {
        color: @DS1-QAP-LINK-HOVER-COLOR;
    }

    .qap-link {
        .ds1-pv-1();
    }
}

</style>