<template>
  <div id="topic-toolbar"
       :class="[ toolbarClass,
                 { 'toolbar-variant-tabbed': isTitleExtracted }]">
    <div class="topic-toolbar-default">
      <div id="topic-tools" :class="{'topic-tabs-variant': isTabbedContent }">
        <ul>
          <li v-show="outlineToggleVisible" id="liOutlineToggle">
            <a id="toggleOutline"
               @click="toggleOutline()">{{ $t(toolbarViewToggleLabelKey) }} </a>
          </li>
          <li v-show="toolbarTfiVisible"
              class="hoverTooltip"
              aria-label="Translation Feedback Instructions">
            <a aria-label="Translation Feedback Instructions"
               class="topic-toolbar__translation-feedback"
               tabindex="0"
               @click="toggleTranslationFeedbackInstructions">
              <span class="wkce-icon-book-open" />
            </a>
          </li>
          <li v-show="toolbarFindInTopicVisible"
              class="hoverTooltip"
              aria-label="Find in topic">
            <a aria-label="Find in topic"
               tabindex="0"
               @click="toggleFit">
              <span class="wkce-icon-fit" />
            </a>
          </li>
          <li v-show="toolbarInlineCmeVisible"
              class="automation_toolbar-inline-cme toolbar-inline-cme">
            <inline-cme tabindex="0" class="hoverTooltip" aria-label="Reflect on credits for CME" />
          </li>
          <li v-show="toolbarPrintVisible"
              id="topicPrint"
              aria-label="Print"
              class="topicPrint mobileOverflow hoverTooltip">
            <a id="printTopic"
               tabindex="0"
               aria-label="Print"
               target="_blank"
               rel="noopener"
               :href="toolbarPrintUrl">
              <span class="wkce-icon-print" /></a>
          </li>
          <li v-show="toolbarShareVisible"
              id="topicShare"
              aria-label="Share"
              tabindex="0"
              class="mobileOverflow hoverTooltip">
            <topic-toolbar-share />
          </li>
          <li v-show="toolbarFormularyVisible"
              id="topicFormulary"
              aria-label="Formulary"
              tabindex="0"
              class="topic-formulary hoverTooltip">
            <a @click="navigateToFormulinkInfo()">
              <span class="wk-icon-medicine" />
            </a>
          </li>
          <li v-show="toolbarFontSizeVisible"
              id="topicFontSize"
              aria-label="FontSize"
              tabindex="0"
              class="mobileOverflow topicFontSize">
            <font-size />
          </li>
          <li v-show="toolbarBookmarkVisible"
              id="bookmarkTopic"
              aria-label="Bookmark"
              class="liBookmark hoverTooltip"
              @click="topicLoaded && bufferBookmark()">
            <utd-bookmark id="tpcBookmark"
                          :bookmark-active="toolbar.bookmarkIsActive"
                          :bookmark-tooltip="tooltip"
                          @tooltip-closed="closeTooltip" />
          </li>
          <utd-toolbar-menu v-show="showStarRating"
                            class="toolbar_rateTopic">
            <template v-slot:menuActivatorLabel>
              <span aria-hidden="true" class="wkce-icon-star" />
              <span class="rateLabel" aria-label="Rate this topic" v-text="$t('RATE')" />
            </template>
            <utd-star-rating id="mobileTopicStarRating"
                             class="utdStarRating_topicMobile star-rating-wide"
                             :user-rating="topicRating"
                             :options="{ showLabel: false }" />
          </utd-toolbar-menu>
          <li v-show="toolbarContentFeedbackVisible"
              aria-label="Provide Feedback"
              class="mobileOverflow">
            <utd-content-feedback-dialog ref="feedbackDialog"
                                         :feedback-title="feedbackTitle" 
                                         has-chat-box-icon
                                         :button-text="$t('HEADER.FEEDBACK')" />
          </li>
          <li v-show="toolbarToolsVisible"
              id="toolsMenu"
              :class="{'overflowOn': toolbarOverflowVisible}"
              @click="topicLoaded && toggleOverflowTopicMenu()">
            <a>{{ $t("CONTENT.TOOLS") }} </a>
          </li>
        </ul>

      </div>
      <div v-if="toolbarBackLinkVisible" id="backtosearchicon"><!--
        --><toolbar-back-link
        :show-back-button="showBackButton"
        :tab-index="0"
         />
      </div>
      <utd-oidc-banner :check-hash="true" />
      <!-- eslint-disable vue/no-v-html -->
      <div id="topic-title"
           :title="topicTitle"
           class="topicToolbarTitle"
           :class="topicTitleClasses"
           @click="scrollToTop()"
           v-html="topicTitle" />
    </div>
    <topic-toolbar-bread-crumbs v-if="showBreadcrumbs"
                                :class="{ 'utd-rtl': isRTL }"
    />
    <utd-tabs v-if="isTabbedContent"
              id="topicContentTabs"
              class="wk-js2 topic-content-tabs"
              :tab-style="'wk-tabs-default'"
              :active-tab="activeTopicTab"
              @selectedTab="(activeTab, event) => onSwitchTab(activeTab, event)">
      <utd-tab title="Topic" />
      <utd-tab :title="graphicsTabTitle" />
    </utd-tabs>
    <machine-translation-widget v-if="hasMachineTranslation" />
    <push-notification v-show="isShowFormulinkNotification"
                       class="med-topic-formulink-push-notification"
                       @hide-notification="hideNotification">
      <template v-slot:header>{{ $t("FORMULINK.DRUG_INFO_FOR_TOPIC") }}</template>
      <template v-slot><i class="wk-icon-info" /><!--
        --><span>{{ $t("FORMULINK.NO_DRUGS_FOR_TOPIC") }}</span><!--
      --></template>
    </push-notification>
    <utd-notification v-if="showSuccessMessage"
                      class="inline-cme-success ds1-mh-4"
                      :notification-mode="'success'">
      <template v-slot:title>Your reflection has been saved.</template>
      <template v-slot>You now have {{ accruedCredits }} reflected credits.</template>
    </utd-notification>
  </div>
</template>

<script>
import topicToolbarShare from '_acaSrc/components/contents/topic/toolbar/TopicToolbarShare.vue';
import fontSize from '_acaSrc/components/contents/topic/toolbar/FontSize.vue';
import TopicToolbarBreadCrumbs from '_acaSrc/components/contents/topic/toolbar/TopicToolbarBreadCrumbs.vue';
import utdBookmark from '_acaSrc/components/shared/utd/UtdBookmark.vue';
import pushNotification from '_acaSrc/components/shared/stdlib/UtdPushNotification.vue';
import toolbarBackLink from '_acaSrc/components/shared/utd/UtdToolbarBackLink.vue';
import inlineCme from '_acaSrc/components/contents/topic/toolbar/InlineCme.vue';
import utdOidcBanner from '_acaSrc/components/shared/utd/UtdOidcBanner.vue';
import UtdToolbarMenu from '_acaSrc/components/shared/stdlib/UtdToolbarMenu.vue';
import UtdStarRating from '_acaSrc/components/shared/stdlib/UtdStarRating.vue';
import { lockRatingControls } from '_acaSrc/utility/PendoGuides/RatingGuide';
import { TopicToolbarHoverTooltip } from '_acaSrc/utility/tooltip';
import {
    SET_TOOLBAR_OVERFLOW_VISIBLE,
    SET_TOOLBAR_BOOKMARK_ACTIVE,
    SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE,
    SET_TOPIC_RATING,
    SET_IS_FIT_DIALOG_SHOWN,
    SET_FULL_TOPIC_WIDTH
} from '_acaSrc/store/topic/topic.store';
import { TOGGLE_SUCCESS_MESSAGE_VISIBILITY } from '_acaSrc/store/inlineCme.store';
import { ScrollTarget } from '_acaSrc/utility/contents/topic/ScrollTarget';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import UtdNotification from '../../../shared/utd/UtdNotification.vue';
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import {
    BOOKMARK_BUFFER_PAUSE_MS,
    SET_BOOKMARK_BUFFER_DELAY,
    TOGGLE_TOOLTIP_HIDE,
    RESET_TOOLTIP
} from '_acaSrc/store/profile.store';
import { getUrlHash } from '_acaSrc/utility/http';
import { getWindow } from '_acaSrc/utility/DOM';
import { C_EVENTS, C_LANGUAGES_NAMES, C_TOPIC } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import { toggleBookmark } from '_acaSrc/utility/BookmarkHelper';
import { throttleUiClickEvent } from '_acaSrc/utility/Events';
import MachineTranslationWidget from '_acaSrc/components/localization/MachineTranslationWidget.vue';
import UtdContentFeedbackDialog from '_acaSrc/components/shared/utd/UtdContentFeedbackDialog.vue';
import { USER_CONTENT_FEEDBACK } from '_acaSrc/utility/constants';

const TOP_CONTENT_ANCHOR = C_TOPIC.TOPIC_TAB_HASH;
const HIDE_CME_SUCCESS_NOTIFICATION_DELAY = 3000;

export default {
    components: {
        topicToolbarShare,
        TopicToolbarBreadCrumbs,
        fontSize,
        utdBookmark,
        toolbarBackLink,
        pushNotification,
        inlineCme,
        utdOidcBanner,
        UtdNotification,
        UtdToolbarMenu,
        UtdStarRating,
        UtdTabs,
        UtdTab,
        MachineTranslationWidget,
        UtdContentFeedbackDialog
    },
    data() {
        return {
            scrollToTopTimeout: null,
            isTitleExtracted: true, // Using variable until new title display is stable
            hash: ''
        };
    },
    computed: {
        ...mapGetters('topic', [
            'toolbar',
            'topic',
            'topicId',
            'topicTitle',
            'topicType',
            'topicVersion',
            'topicLanguage',
            'topicRating',
            'topicScroller',
            'toolbarPrintUrl',
            'toolbarPrintVisible',
            'toolbarOverflowVisible',
            'toolbarInlineCmeVisible',
            'toolbarShareVisible',
            'toolbarFontSizeVisible',
            'toolbarViewToggleLabelKey',
            'topicLoaded',
            'bookmarkedTopics',
            'toolbarBackLinkVisible',
            'toolbarBookmarkVisible',
            'toolbarFormularyVisible',
            'isShowFormulinkNotification',
            'outlineToggleVisible',
            'toolbarToolsVisible',
            'isTopicCalculator',
            'isTabbedContent',
            'toolbarFindInTopicVisible',
            'toolbarTranslationFeedbackVisible',
            'isFitDialogShown',
            'showTopicFeedback',
            'isScrollerActive',
            'hasMachineTranslation',
            'isNotLocalizedTopicView'
        ]),
        ...mapGetters('pendo', [ 'showRatingGuide' ]),
        ...mapGetters('app', [
            'tooltipConfig',
            'isUccState',
            'router'
        ]),
        ...mapGetters('inlineCme', [ 'showSuccessMessage', 'accruedCredits' ]),
        ...mapGetters('profile', [
            'permissions',
            'tooltip',
            'bookmarkBufferDelayMs'
        ]),
        ...mapGetters('feature', [ 'isContentFeedbackMigration' ]),
        ...mapGetters('formulary', [ 'formularyInfoDrugList' ]),
        ...mapGetters('graphic', [ 'graphicViewerGraphics' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('user', [ 'qualtricsURL' ]),
        showBackButton() {
            return this.isDesktopView;
        },
        toolbarClass() {
            return `${this.topicType === 'calc'
                ? 'calculator-toolbar'
                : 'topic-toolbar'} ${!this.isTabbedContent ? 'toolbar-tabs-collapsed' : ''}`;
        },
        showStarRating() {
            return this.showRatingGuide
                && this.showTopicFeedback
                && !this.isDesktopView
                && !this.isTopicCalculator
                && (!this.isUccState
                    || this.topicLanguage === 'en-US')
                && this.isNotLocalizedTopicView;
        },
        toolbarTfiVisible() {
            return this.isDesktopView && this.toolbarTranslationFeedbackVisible;
        },
        activeTopicTab() {
            return this.hash === C_TOPIC.GRAPHIC_TAB_HASH
                ? C_TOPIC.GRAPHIC_TAB_INDEX
                : C_TOPIC.TOPIC_TAB_INDEX;
        },
        topicTitleClasses() {
            return {
                'utd-rtl': this.topicLanguage === C_LANGUAGES_NAMES.AR.CODE,
                'title-variant-tabbed': this.isTitleExtracted,
                'title-with-tabs': this.isTabbedContent,
                'title-with-breadcrumbs': this.showBreadcrumbs
            };
        },
        graphicsTabTitle() {
            const count = this.graphicViewerGraphics.length
                && `<span class="count">(${this.graphicViewerGraphics.length})</span>` || '';
            return `Graphics ${count}`;
        },
        showBreadcrumbs() {
            if (this.isDesktopView) {
                return false;
            }
            return this.hash !== ''
                && this.activeTopicTab === C_TOPIC.TOPIC_TAB_INDEX
                && this.isScrollerActive;
        },
        isRTL() {
            return this.topicLanguage === C_LANGUAGES_NAMES.AR.CODE;
        },
        toolbarContentFeedbackVisible() {
            return !this.isDesktopView && this.isTopicCalculator && this.isNotLocalizedTopicView
            && this.isContentFeedbackMigration;
        },
        feedbackTitle() {
            return this.isTopicCalculator ?
                USER_CONTENT_FEEDBACK.TITLE.CALCULATOR : USER_CONTENT_FEEDBACK.TITLE.TOPIC;
        }
    },
    watch: {
        showSuccessMessage(newVal) {
            if (newVal) {
                this.successMessageTimeout = setTimeout(() => {
                    this[TOGGLE_SUCCESS_MESSAGE_VISIBILITY](false);
                }, HIDE_CME_SUCCESS_NOTIFICATION_DELAY);
            }
        }
    },
    async mounted() {
        const links = document.querySelectorAll('.hoverTooltip');
        await this.confirmConfigLoaded();
        this.linkTooltip = new TopicToolbarHoverTooltip(links, this.tooltipConfig);
        this.linkTooltip.initialize().catch(() => {
            // No-op
        });
        this.subscribe({
            eventName: 'wkutd.star-rating',
            handlerFn: this.doRatingGuide
        });
        this.throttleTabClickEvent = throttleUiClickEvent();
        getWindow().addEventListener('hashchange', this.onHashChange);
        this.$nextTick(() => {
            const curHash = this.hash;
            this.onHashChange();
            if (this.hash !== curHash) {
                this.onSwitchTab(this.activeTopicTab);
            }
        });
    },
    beforeUnmount() {
        clearTimeout(this.scrollToTopTimeout);
        clearTimeout(this.successMessageTimeout);
        this[TOGGLE_SUCCESS_MESSAGE_VISIBILITY](false);
        this.unsubscribe({
            eventName: 'wkutd.star-rating',
            handlerFn: this.doRatingGuide
        });
        getWindow().removeEventListener('hashchange', this.onHashChange);
    },
    methods: {
        ...mapMutations('profile', [
            RESET_TOOLTIP,
            SET_BOOKMARK_BUFFER_DELAY,
            TOGGLE_TOOLTIP_HIDE ]),
        ...mapMutations('topic', [
            SET_TOOLBAR_OVERFLOW_VISIBLE,
            SET_TOOLBAR_BOOKMARK_ACTIVE,
            SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE,
            SET_TOPIC_RATING,
            SET_IS_FIT_DIALOG_SHOWN,
            SET_FULL_TOPIC_WIDTH ]),
        ...mapMutations('inlineCme', [ TOGGLE_SUCCESS_MESSAGE_VISIBILITY ]),
        ...mapActions('app', [
            'subscribe',
            'unsubscribe',
            'publish',
            'confirmConfigLoaded'
        ]),
        ...mapActions('pendo', [ 'launchGuide' ]),
        ...mapActions('topic', [
            'processScrollRequest',
            'bookmarkTopic',
            'removeBookmarkTopic',
            'navigateToFormulinkInfo'
        ]),
        ...mapActions('profile', [ 'resetBookmarkDelay' ]),
        onHashChange() {
            this.hash = getUrlHash();
        },
        toggleOutline() {
            this.$emit('toggle-outline', TOP_CONTENT_ANCHOR);
        },
        hideNotification() {
            this[SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE](false);
        },
        toggleOverflowTopicMenu() {
            if (this.toolbarOverflowVisible) {
                this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
            }
            else {
                this[TOGGLE_TOOLTIP_HIDE]();
                this[SET_TOOLBAR_OVERFLOW_VISIBLE](true);
            }
            this[RESET_TOOLTIP]();
        },
        bufferBookmark() {
            if (this.toBookmark) {
                clearTimeout(this.toBookmark);
            }
            this.toBookmark = setTimeout(() => {
                toggleBookmark({
                    tools: this.toolbar,
                    tooltip: this.tooltip,
                    bookmarks: this.bookmarkedTopics,
                    permissions: this.permissions,
                    fnAddBookmark: this.bookmarkTopic,
                    fnRemoveBookmark: this.removeBookmarkTopic,
                    fnCloseTooltip: this.closeTooltip,
                    fnSetBookmarkActive: this[SET_TOOLBAR_BOOKMARK_ACTIVE]
                });
                // Long pause before resetting delay back to 0
                this.resetBookmarkDelay();
            }, this.bookmarkBufferDelayMs);

            this[SET_BOOKMARK_BUFFER_DELAY](BOOKMARK_BUFFER_PAUSE_MS);
        },
        closeTooltip(tooltipId) {
            if (this.bookmarkedTopics.length >= this.permissions.bookmarkLimit) {
                this[RESET_TOOLTIP]();
            }
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, { uiElementName: tooltipId });
            this[TOGGLE_TOOLTIP_HIDE]();
        },
        scrollToTop() {
            // Don't bother if we're already scrolled to the top
            if ((this.topicScroller.pageYOffset | this.topicScroller.scrollTop) <= 0) {
                return;
            }

            getWindow().clearTimeout(this.scrollToTopTimeout);
            this.scrollToTopTimeout = getWindow().setTimeout(() => {
                this.router.hash('');
                const target = new ScrollTarget({
                    position: C_TOPIC.SCROLL_TOP_POSITION,
                    fromClick: true
                });
                this.processScrollRequest(target);
            }, 0);
        },
        doRatingGuide(eventData) {
            if (eventData.id !== 'mobileTopicStarRating') {
                return;
            }

            lockRatingControls();
            this[SET_TOPIC_RATING](eventData.rating);

            this.launchGuide({
                guideName: 'RatingGuide',
                guideData: {
                    topicId: this.topicId,
                    topicTitle: this.topicTitle,
                    topicVersion: this.topicVersion,
                    topicLanguage: this.topicLanguage,
                    element: eventData.id,
                    rating: eventData.rating,
                    feedbackUrl: this.qualtricsURL
                }
            });

            // Dismiss rating menu on selecting star, as Pendo will provide feedback
            this.publish({ eventName: 'wkutd.close-toolbar-menus' });
        },
        onSwitchTab(activeTab, event) {
            if (activeTab === this.activeTopicTab && event) {
                return;
            }
            if (event) {
                this.throttleTabClickEvent({
                    uiElementName: C_TOPIC.SOURCE.TOPIC_TABS,
                    optData: JSON.stringify({ 'Active Tab': event.target.innerText.trim() }),
                    contentId: this.topicId
                });
            }
            this[SET_FULL_TOPIC_WIDTH](activeTab === C_TOPIC.GRAPHIC_TAB_INDEX);
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true,
                fromLocation: 'TopicToolbar: onSwitchTab'
            });

            this.publish({
                eventName: 'wkutd.switch-topic-tab',
                eventData: {
                    event,
                    activeTab
                }
            });
        },
        toggleFit() {
            this[SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE](false);
            this[SET_IS_FIT_DIALOG_SHOWN](!this.isFitDialogShown);
        },
        toggleTranslationFeedbackInstructions() {
            new PubSub().publish(C_EVENTS.OPEN_TRANSLATION_FEEDBACK_INSTRUCTION);
        }
    }
};
</script>
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-TOOLTIP-ARROW-BORDER-COLOR: rgba(0,8,16,0.2);

.tippy-box[data-theme^='utd-tooltip-interactive-toolbar-links'] {
  display: none;

  @media only screen and (min-width: 768px) {
    .isDesktop & {
      display: block;
    }
  }

  &[data-placement^=bottom] {
    & > .tippy-arrow::before {
      bottom: 4px;
      border-bottom-color: @UTD-TOOLTIP-ARROW-BORDER-COLOR;
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-TOOLBAR-TITLE-FONT-SIZE: 25px;
@UTD-TOOLBAR-TITLE-SMALL-FONT-SIZE: @UTD-TOOLBAR-TITLE-FONT-SIZE * 0.875;
@UTD-TOOLBAR-TITLE-LARGE-FONT-SIZE: @UTD-TOOLBAR-TITLE-FONT-SIZE * 1.125;

@UTD-FIXED-TOOLBAR-TITLE-FONT-SIZE:  @UTD-TOOLBAR-TITLE-FONT-SIZE * 0.8;
@UTD-FIXED-TOOLBAR-SMALL-TITLE-FONT-SIZE: @UTD-FIXED-TOOLBAR-TITLE-FONT-SIZE* 0.875;
@UTD-FIXED-TOOLBAR-LARGE-TITLE-FONT-SIZE: @UTD-FIXED-TOOLBAR-TITLE-FONT-SIZE * 1.125;

#topic-toolbar {
  background-color: @DS1-UTD-TOOLBAR-BG-COLOR;

  #backtosearchicon {
    line-height: 28px;
  }

  .isTabbedContent & {
    .topic-toolbar-breadcrumbs {
      .ds1-pb-0();
    }
  }

  .topic-toolbar-breadcrumbs {
    .ds1-pb-1();
  }

  .title-collapsed & {
    .topic-toolbar-breadcrumbs {
      .ds1-pb-1();
    }

    .topic-content-tabs {
      height: 0;
      overflow: hidden;
    }
  }
}

.topicToolbarTitle {
  .ds1-pv-1();
  .ds1-ph-2();
  .ds1-utd-weight-bold();
  font-size: 16px;
  line-height: 1.375;
  color: @DS1-UTD-TOPIC-TEXT-COLOR;
  background-color: @DS1-UTD-TOOLBAR-BG-COLOR;

  .textSize-S & {
    font-size: 13px;
    line-height: 1.5;
  }

  .textSize-L & {
    font-size: 19px;
    line-height: 1.5;
  }

  .title-collapsed & {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.title-with-breadcrumbs {
    padding-bottom: 4px;
  }
}

.mobileOverflow {
  white-space: nowrap;
}

:deep(.utd-tabs__container) {
  background-color: @DS1-UTD-TOOLBAR-BG-COLOR;
  border-bottom: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;

  .wk-tabs::before {
    width: 0;
  }
}

.wkce-icon-print,
.wkce-icon-fit,
:deep(.inline-cme-button) {
  display: none;
}

.wkce-icon-fit {
  background-image: url('~_acaAssets/topic/fitIcon.svg');
  width: 22px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
}

.toolbar_rateTopic {
  .rateLabel {
    cursor: pointer;
  }

  :deep(.toolbar-menu__drawer) {
    .ds1-pa-2();
  }
}

#topic-tools > ul > li {
  // Prevents wrapping of toolbar items at
  // 320px wide devices for English only
  padding: 0 7px 1px;

  @media only screen and (min-width: 375px) {
    padding: 0 10px 1px;
  }

  :deep(.toolbar-menu__drawer) {
    background-color: @DS1-UTD-TOOLBAR-BG-COLOR;
  }

  // These rules are unfortunately necessary to override global
  // CSS rules in app.less with overly broad specificity.
  :deep(.star-rating) {
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;

    & > .rating-list {
      padding: 0;
    }
  }

  &.toolbar-inline-cme {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .wkce-icon-print,
  .wkce-icon-fit,
  :deep(.wkce-icon-share),
  :deep(.inline-cme-button),
  :deep(.wk-icon-medicine) {
    margin: 0;
    display: inline-block;
  }

  #topicContentTabs {
    .ds1-pl-1();

    .isTablet & {
      .ds1-pl-0();
    }
  }

  .isDesktop {
    .topicToolbarTitle {
      .ds1-pv-2();
      background-color: @DS1-UTD-TOOLBAR-BG-COLOR;
      font-size: @UTD-TOOLBAR-TITLE-FONT-SIZE;
      line-height: 28px;

      body:not(.mobileOnDesktop)& {
        text-align: center;
      }

      .textSize-S& {
        font-size: @UTD-TOOLBAR-TITLE-SMALL-FONT-SIZE;
        line-height: 21.875px;
      }

      .textSize-L& {
        font-size: @UTD-TOOLBAR-TITLE-LARGE-FONT-SIZE;
        line-height: 31.5px;
      }

      &.title-with-tabs {
        .ds1-pt-2();
        .ds1-pb-1();
        padding-right: 80px;
      }
    }

    #backtosearchicon {
      .ds1-pt-2();
    }

    .topicContainer:not(.isTabbedContent) .topic-toolbar-default {
      border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
    }

    #topic-toolbar {
      #topic-tools {
        #topicFeedbackLk {
          padding-left: 10px;
          padding-right: 10px;
        }

        > ul > li {
          &#topicPrint {
            .ds1-pv-1();
            display: inline;
          }

          &#bookmarkTopic {
            margin-left: 0;
            .ds1-pv-compact();
            padding-left: 12px;
            padding-right: 12px;

            :deep(.utd-button) {
              border: none;
            }

            :deep([class*=wkce-icon]) {
              margin-right: 0;
            }
          }

          &.toolbar-inline-cme {
            display: inline-block;
          }
        }
      }

      &.calculator-toolbar {
        max-height: inherit;

        .topicToolbarTitle {
          display: block;
          white-space: normal;
          overflow: hidden;
          box-shadow: none;
          background: none;
        }

        #topic-tools {
          min-width: 40px;
        }
      }

      .topicToolbarTitle {
        display: none;
      }

      &.toolbar-variant-tabbed {
        display: flex;
        flex-direction: column;
        max-height: inherit;

        // To support safari browser
        height: auto;

        .topic-toolbar-default {
          display: flex;
          align-items: flex-start;

          #topic-title.title-variant-tabbed {
            .ds1-pl-3();
            display: block;
            order: 1;
            flex-grow: 1;
            box-shadow: none;
          }

          #topic-tools {
            order: 2;
            margin-left: auto;
            flex-shrink: 0;

            &.topic-tabs-variant {
              z-index: @ZINDEX-TOPIC-TOOLBAR-TOOLS;
              position: absolute;
              bottom: 0;
              right: 0;
            }

            &:not(.topic-tabs-variant) {
              padding: 10px 0;
            }
          }

          #backtosearchicon {
            order: 0;
          }
        }

        :deep(.utd-tabs__container) {
          .wk-tabs::before {
            width: 7px;
          }

          .wk-tab {
            font-size: 18px;
            line-height: 24px;

            > label > .count {
              .ds1-utd-size-2();
              .ds1-utd-weight-300();
            }
          }
        }
      }
    }
  }

  .topicView.fixedToolbar.isDesktop #topic-toolbar {
    .topic-toolbar-default {
      .topicToolbarTitle {
        .ds1-pv-1();
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: @UTD-FIXED-TOOLBAR-TITLE-FONT-SIZE;
        line-height: 1.3;

        .textSize-S& {
          font-size: @UTD-FIXED-TOOLBAR-SMALL-TITLE-FONT-SIZE;
        }

        .textSize-L& {
          font-size: @UTD-FIXED-TOOLBAR-LARGE-TITLE-FONT-SIZE;
        }
      }
    }

    #backtosearchicon {
      .ds1-pt-1();
      line-height: 25px;
    }

    &.calculator-toolbar {
      .topic-toolbar-default .topicToolbarTitle {
        .ds1-pb-1();
        white-space: normal;
      }
    }

    .topicToolbarTitle {
      display: block;
      box-shadow: none;
    }

    #topic-tools {
      &:not(.topic-toolbar-default) {
        .ds1-pv-0();
        margin-top: 0;
      }
    }

    &.toolbar-variant-tabbed {
      .topicToolbarTitle {
        .ds1-pb-0();
      }
    }

    :deep(.inline-cme-success) {
      top: 74px;
    }
  }

  .toolbarText,
  :deep(.toolbarText) {
    display: none;
  }

  :deep(.inline-cme-success) {
    max-width: 250px;
    position: absolute;
    right: -16px;
    top: 90px;
  }

  .topic-toolbar__translation-feedback {
    display: block;

    .wkce-icon-book-open {
      .ds1-ma-0();
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .isTablet .topicToolbarTitle {
    text-align: center;
  }
}

@media only screen and (min-width: 1140px) {
  .topic-toolbar__translation-feedback {
    display: none;
  }
}
</style>