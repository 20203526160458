export default class UtdRestResponse {

    // responseBody: javascript object, the server response body (after JSON.parse)
    // request: Represents the request that initiated this response
    // headers: javascript object with key-value pairs, all lowercase.
    // status: integer, e.g. 200, 404, 301
    constructor(context = {}) {
        const {
            responseBody = {},
            request = {},
            headers = {},
            status,
            statusText,
            error
        } = context;

        this._data = responseBody;
        this._request = request;
        this._headers = headers;
        this._status = status;
        this._statusText = statusText;
        this._error = error;
    }

    get data() {
        return this._data;
    }

    set data(data) {
        this._data = data;
    }

    get request() {
        return this._request;
    }

    // alias for data
    get body() {
        return this._data;
    }

    // alias for data
    set body(data) {
        this._data = data;
    }

    get status() {
        return this._status;
    }

    get statusText() {
        return this._statusText;
    }

    get error() {
        return this._error;
    }

    mergeConfig(config) {
        if (!this._request.config) {
            this._request.config = {};
        }
        Object.assign(this._request.config, config);
    }

    headers(headerName) {
        return this._headers[headerName.toLowerCase()];
    }
}
