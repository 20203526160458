import PubSub from '_acaSrc/utility/PubSub';

export default class PendoGuide {
    constructor(eventName, postLaunchFn) {
        this._pendo = null;
        this._postLaunchFn = postLaunchFn;
        this.pubsub = new PubSub();

        this._pendoReadyPromise = new Promise(resolve => {
            new PubSub().subscribe('pendo-guides-ready', pendo => {
                this._pendo = pendo;
                this.pubsub.publish(eventName);
                resolve(true);
            });
        });
    }

    isShowGuide() {
        return this._pendoReadyPromise.then(() => {
            return this._getGuideName.apply(this);
        });
    }

    launchGuide() {
        this._pendoReadyPromise.then(() => {
            return this._launchGuide.apply(this);
        });
    }

    _isPendoAvailable() {
        return this._pendo && this._pendo.findGuideByName;
    }

    _getGuideNameIfActive(guideName) {
        if (!this._isPendoAvailable) {
            return '';
        }

        return this._pendo.findGuideByName(guideName)
            ? guideName
            : '';
    }

    _getGuideName() {
        // eslint-disable-next-line no-console
        console.warn('This method must be implemented by child class.');
        return null;
    }

    _launchGuide() {
        if (!this._pendo && this._pendo.showGuideByName) {
            return;
        }

        const guideName = this._getGuideName.apply(this);
        if (guideName) {
            this._pendo.showGuideByName(guideName);
            if (this._postLaunchFn) {
                this._postLaunchFn();
            }
        }
    }
}
