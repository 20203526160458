<template>
  <div>
    <utd-modal-dialog ref="modalDialog"
                      :close-on-overlay-click="false"
                      :close-on-escape="false"
                      :hide-header-close="true"
                      modal-classes="two-accounts-modal"
                      dialog-classes="ds1-default-dialog"
                      dialog-size="medium">
      <template #header>{{ $t('LOGIN.NEXT_STEPS_TWO_ACCOUNTS') }}</template>

      <two-accounts-password-validation v-show="validatingPassword && !updateUserNameSuccess"
                                        id="twoAccountsPasswordValidation"
                                        @close-modal="closeModal"
                                        @forgot-password="openForgotCredentials" />
      <two-accounts-username-validation v-show="!validatingPassword && !updateUserNameSuccess"
                                        id="twoAccountsUsernameValidation"
                                        ref="usernameValidation"
                                        @close-modal="closeModal" />
      <div class="two-accounts__notification">
        <utd-notification v-show="updateUserNameSuccess"
                          tabindex="0"
                          class="two-accounts__success"
                          notification-mode="success">
          <template #title>{{ $t('MYACCOUNT.USERNAME_CHANGE_SUCCESS') }}</template>

          <ul>
            <li>You're all set. Your next time accessing UpToDate
              you can use your enterprise email to sign in with SSO. </li>
            <li>Or you can sign in to your existing account
              with your Password and updated Username.
              (Note: this may take a few minutes to update everywhere.) </li>
          </ul>

        </utd-notification>
      </div>

      <template #footer>
        <utd-button button-size="large"
                    :disabled="!canSubmit"
                    @click="submitForm()">
          {{ modalButtonText }}
        </utd-button>
      </template>
    </utd-modal-dialog>
    <forgot-password-modal ref="forgotPassword" @close-forgot-password-modal="openModal" />
  </div>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import { getDocument } from '_acaSrc/utility/DOM';
import {
    SET_FORM_SUBMITTING,
    SET_MESSAGE_ERROR,
    SET_MESSAGE_TITLE,
    SET_FORM_USER_NAME,
    SET_ERROR_LEVEL,
    SET_NEXT_STEPS_FLOW,
    SET_FORM_PASSWORD
} from '_acaSrc/store/login.store';
import TwoAccountsPasswordValidation from './TwoAccountsPasswordValidation.vue';
import TwoAccountsUsernameValidation from './TwoAccountsUsernameValidation.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import ForgotPasswordModal from '_acaSrc/account/components/ForgotPasswordModal.vue';
import { C_HTTP } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        TwoAccountsPasswordValidation,
        TwoAccountsUsernameValidation,
        UtdNotification,
        ForgotPasswordModal
    },
    data() {
        return {
            validatingPassword: true,
            updateUserNameSuccess: false
        };
    },
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('login', [
            'form',
            'message'
        ]),
        ...mapGetters('account', [ 'serverErrors' ]),
        canSubmit() {
            if (this.updateUserNameSuccess) {
                return true;
            }
            if (this.validatingPassword) {
                return this.form.password !== '';
            }
            return this.$refs.usernameValidation.isUserNameValid
                && this.$refs.usernameValidation.isConfirmUserNameValid;
        },
        modalButtonText() {
            if (this.updateUserNameSuccess) {
                return 'Continue to UpToDate';
            }
            if (this.validatingPassword) {
                return this.$t('LOGIN.CONTINUE');
            }
            return 'Save Update';
        }
    },
    methods: {
        ...mapActions('login', [
            'setErrorMessage',
            'setErrorTitle',
            'loginUser'
        ]),
        ...mapActions('account', [
            'changeUserName'
        ]),
        ...mapMutations('login', [
            SET_MESSAGE_ERROR,
            SET_MESSAGE_TITLE,
            SET_FORM_USER_NAME,
            SET_FORM_SUBMITTING,
            SET_ERROR_LEVEL,
            SET_NEXT_STEPS_FLOW,
            SET_FORM_PASSWORD
        ]),
        openModal() {
            this.$refs.modalDialog.open();
        },
        closeModal() {
            this[SET_FORM_PASSWORD]('');
            this.validatingPassword = true;
            this.updateUserNameSuccess = false;
            this.resetLoginError();
            this.$refs.modalDialog.close();
            this.hideOverlay();
        },
        hideOverlay() {
            new PubSub().publish('wkutd.closeNextStepsInnerDialog');
        },
        resetLoginError() {
            this[SET_MESSAGE_ERROR]('');
            this[SET_MESSAGE_TITLE]('');
            this[SET_ERROR_LEVEL]('error');
            if (this.form.isSubmitting) {
                return;
            }
            // Hide keyboard
            const focus = getDocument().querySelector(':focus');
            if (focus) {
                focus.blur();
            }
        },
        submitForm() {
            if (this.form.isSubmitting) {
                return;
            }

            this.resetLoginError();
            this[SET_FORM_SUBMITTING](true);

            if (this.updateUserNameSuccess) {
                this.router.url(C_HTTP.DEFAULT_HOMEPAGE_PATH);
            }

            if (this.validatingPassword) {
                this.validatePassword();
            }
            else {
                this.validateUsername();
            }
        },
        setNotificationFocus() {
            const notificationTitle = getDocument()
                .querySelector('.two-accounts__notification .utd-notification-title');
            notificationTitle && notificationTitle.focus();
        },
        async validatePassword() {
            this[SET_NEXT_STEPS_FLOW](true);
            await this.loginUser();
            this[SET_NEXT_STEPS_FLOW](false);
            this[SET_FORM_SUBMITTING](false);

            if (this.message.error === '') {
                this.validatingPassword = false;
            }
            else {
                this.setNotificationFocus();
            }
        },
        async validateUsername() {
            try {
                await this.changeUserName({
                    password: this.form.password,
                    newUserName: this.$refs.usernameValidation.newUserName
                });
            }
            catch {
                this[SET_MESSAGE_ERROR](
                    this.$t('MYACCOUNT.UPDATE_INFORMATION_UNEXPECTED_ERROR_FULL')
                );
            }
            this[SET_FORM_SUBMITTING](false);
            this.setNotificationFocus();
            if (this.serverErrors.newUserName !== ''
                || (this.message.error && this.message.error !== '')) {
                return;
            }
            this.updateUserNameSuccess = true;
        },
        openForgotCredentials() {
            this.$refs.forgotPassword.open();
            this.$refs.modalDialog.close();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.two-accounts-modal {
  .utd-modal-content .utd-dialog-content {
    .ds1-pa-3();
    height: 420px;
  }
}

@media screen and (min-width: 768px) {
  .two-accounts-modal {
    .utd-modal-content {
      width: 800px;

      .utd-dialog-frame {
        min-width: 320px;
        max-width: 800px;
      }
    }
  }
}
</style>

<style lang="less" scoped>

.two-accounts__notification {
  display: flex;
  justify-content: center;

  ul {
    padding-left: 15px;
  }
}

.two-accounts__success {
  width: 550px;
  align-self: flex-start;
}
</style>