<template>
  <div class="shareRoot">
    <a :id="anchorId"
       tabindex="0"
       role="button"
       :aria-label="$t('SHARE.SHARE_KEY')"
       @click="showShareForm"
       @keyup.enter.space="showShareForm">
      <span class="wkce-icon-share" />
      <span class="toolbarText">{{ $t('SHARE.SHARE_KEY') }}</span></a>
    <span v-for="(content, index) in sharedContentsGetter"
          :key="index"
          :guid="content.guid"
          class="visuallyHidden"
    >{{ content.url }}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    SET_SHARE_SOURCE
} from '_acaSrc/store/share.store';

export default {
    props: {
        shareType: {
            type: String,
            default() {
                return 'topic';
            }
        }
    },
    computed: {
        ...mapGetters('share', [ 'sharedContents' ]),
        sharedContentsGetter() {
            return this.sharedContents;
        },
        anchorId() {
            return this.shareType === 'graphic' ? 'shareGraphic' : 'shareTopic';
        }
    },
    methods: {
        ...mapActions('topic', [ 'showTopicShareForm' ]),
        ...mapActions('graphic', [ 'showGraphicShareForm' ]),
        ...mapMutations('share', [ SET_SHARE_SOURCE ]),
        showShareForm($event) {
            if (this.shareType === 'graphic') {
                this.showGraphicShareForm($event);
                this[SET_SHARE_SOURCE]('graphic');
            }
            else {
                this.showTopicShareForm($event);
                this[SET_SHARE_SOURCE]('topic');
            }
        }
    }
};
</script>

<style lang=less scoped>
  .shareRoot {
    display: inline;
  }

  #overflowTopicDropdown .toolbarText {
    padding: 0;
  }

  #overflowTopicDropdown ul li span {
    padding: 0;
    display: inline;
  }

</style>
