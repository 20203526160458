<template>
  <div>
    <div v-if="isShowBackButton" class="contributors-toolbar large">
      <toolbar-back-link />
    </div>
    <dl class="utd-tabControl">
      <dt>Contributor disclosures</dt>
    </dl>

    <div id="contributorDisclosures">
      <dl v-for="(contributor, index) in disclosures.contributors" :key="`cnt${index}`">
        <dt v-text="contributor.contributorInfo.name" />
        <dd v-text="contributor.contributorInfo.disclosure" />
      </dl>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <div id="conflictPolicy" v-html="disclosures.conflictOfInterestPolicyHtml" />
    </div>
  </div>
</template>

<script>
import ToolbarBackLink from '../../shared/providers/UtdToolbarBackLinkProvider.vue';
import { mapGetters } from 'vuex';
import { browserHasHistory } from '_acaSrc/utility/Browsers';

export default {
    components: {
        ToolbarBackLink
    },
    props: {
        disclosures: Object
    },
    computed: {
        ...mapGetters('app', [ 'isAlwaysShowBackButton' ]),
        isShowBackButton() {
            return this.isAlwaysShowBackButton && browserHasHistory();
        }
    }
};
</script>
