<template>
  <div>
    <div v-for="(item, idx) in items"
         :key="idx"
         v-html="item.html" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import PromoGuide from '_acaSrc/utility/PendoGuides/PromoGuide';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';
import Logger from '_acaSrc/utility/Logger';
import { SET_HAS_PROMOBOX } from '_acaSrc/store/app.store';

export default {
    props: {
        category: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            items: [],
            promoGuide: null
        };
    },
    beforeMount() {
        this.getRssItems(this.category, this.type)
            .then(this.resolveRssContentItems)
            .catch(this.resolveError);

        this.setupPromoGuide('promo-content-ready');
    },
    methods: {
        ...mapMutations('app', [ 'SET_HAS_PROMOBOX' ]),
        resolveRssContentItems(data) {
            this.items = data.rssItems;
            if (typeof this.items !== 'undefined') {
                this[SET_HAS_PROMOBOX](this.items.length);
            }
        },
        resolveError(error) {
            if (error.status === 403 || error.status === 500
                || error.status === '403' || error.status === '500') {
                return;
            }
            if (error.status !== '-1' && error.status !== -1) {
                Logger.warn(`Error trying to retrieve RSS item - error:${error.errors} status:${error.status}`);
            }
        },
        fixFooter() {
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true
            });
        },
        setupPromoGuide(eventName) {
            this.promoGuide = new PromoGuide(eventName, this.fixFooter);

            // Initial Pendo promo box check.
            this.getPromoGuide();

            // Subsequent checks (after login, for example)
            new PubSub().subscribe(eventName, this.getPromoGuide);
        },
        getPromoGuide() {
            this.promoGuide.isShowGuide().then(shouldShow => {
                if (shouldShow) {
                    this.promoGuide.launchGuide();
                }
            });
        },
        getRssItems(category, title) {
            const params = {
                category,
                title
            };
            return utdRest('rss/content/json', params);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@PROMO-BACKGROUND-BLUE: #e1eaf1;
@PROMO-BACKGROUND-GREEN: #e7edde;
@PROMO-BACKGROUND-GREY: #e2e3e2;
@PROMO-BACKGROUND-RED: #fae4e0;

@PROMO-ANCHOR-GREEN: #363;
@PROMO-ANCHOR-BLUE: #007bb1;

.newsearchpromo-blue {
  background: @PROMO-BACKGROUND-BLUE;
}

.newsearchpromo-green {
  background: @PROMO-BACKGROUND-GREEN;
}

.newsearchpromo-grey {
  background: @PROMO-BACKGROUND-GREY;
}

.newsearchpromo-blue a {
  color: @PROMO-ANCHOR-GREEN;
}

.newsearchpromo-green,
.newsearchpromo-grey,
.newsearchpromo-red {
  a {
    color: @PROMO-ANCHOR-BLUE;
  }
}

.newsearchpromo-red {
  background: @PROMO-BACKGROUND-RED;
}

.newsearchpromo-blue,
.newsearchpromo-green,
.newsearchpromo-grey,
.newsearchpromo-red {
  .border-radius(4px);
  margin: 0 auto;
  margin-top: 10px !important;
  font-size: 12px;
  padding: 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    /* New Search Promo Themes >>> */
    .newsearchpromo-blue,
    .newsearchpromo-green,
    .newsearchpromo-grey,
    .newsearchpromo-red {
      // !important is necessary here to override hardcoded styles in injected HTML
      width: 550px !important;
      font-family: Arial, sans-serif;
    }
  }
}
</style>