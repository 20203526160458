import { JL } from 'jsnlog';
import { getDecodeCookie } from '_acaSrc/utility/Cookies';
import { C_APP, C_CSRF } from '_acaSrc/utility/constants';
/**
 * Class which will return Logger object
 *
 * JSNLog.js is a tiny JavaScript logging library
 * Details and documentation: js.jsnlog.com
 *
 */
class Log {
    log(message) {
        // eslint-disable-next-line no-console
        console.log(message);
        JL(C_APP.LOG_NAME).trace(message);
    }
    debug(message) {
        // eslint-disable-next-line no-console
        console.debug(message);
        JL(C_APP.LOG_NAME).debug(message);
    }
    info(message) {
        // eslint-disable-next-line no-console
        console.info(message);
        JL(C_APP.LOG_NAME).info(message);
    }
    warn(message) {
        // eslint-disable-next-line no-console
        console.warn(message);
        JL(C_APP.LOG_NAME).warn(message);
    }
    error(message, error) {
        const errorMsg = `${message} error: ${error || ''}`;
        // eslint-disable-next-line no-console
        console.error(errorMsg);
        JL(C_APP.LOG_NAME).error(errorMsg);
    }
}

// Added only to get unit tests to pass, as they run twice for some reason.
// The `setJsnlogOptions` method is only run once during regular site usage.
let gJsnlogAppendIndex = 0;
export function setJsnlogOptions() {
    JL.setOptions({ defaultAjaxUrl: '/services/app/logging/data' });
    const appender = JL.createAjaxAppender(`utd-appender-${gJsnlogAppendIndex++}`);

    const beforeSendJsnLog = xhr => {
        const csrfToken = getDecodeCookie(C_CSRF.COOKIES_TOKEN);
        xhr.setRequestHeader(C_CSRF.HEADER_TOKEN, csrfToken);
    };

    // These settings save messages in a buffer and only send a report after an error/warn occurs
    // Message severity below a warn will be stored in buffer and not sent to server
    appender.setOptions({
        beforeSend: beforeSendJsnLog,
        bufferSize: 1,
        storeInBufferLevel: JL.getTraceLevel(),
        maxMessages: 50,
        level: JL.getWarnLevel(),
        sendWithBufferLevel: JL.getErrorLevel(),
        batchSize: 1
    });

    JL().setOptions({
        appenders: [ appender ]
    });
}

const Logger = new Log();
export default Logger;
