import { nextTick } from 'vue';
let inputFields;

export default {
    mounted: async(el) => {
        await nextTick();
        inputFields = el.querySelectorAll('input');
        el.addEventListener('submit', blurInput);
    },
    beforeUnmount: el => {
        el.removeEventListener('submit', blurInput);
    }
};

function blurInput() {
    inputFields[0].blur();
}