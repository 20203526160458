import superagent from 'superagent';
import UtdRestResponse from './UtdRestResponse';
import { C_CSRF, C_HTTP } from '../constants';
import { getDecodeCookie } from '_acaSrc/utility/Cookies';

const agent = superagent.agent();

export function registerRequestHook(fn) {
    agent.use(req => {
        req.on('request', fn);

        const csrfToken = getDecodeCookie(C_CSRF.COOKIES_TOKEN);
        if (!csrfToken) {
            return;
        }
        req.header[C_CSRF.HEADER_TOKEN] = csrfToken;
    });
}

class UtdRestResponseSuperagent extends UtdRestResponse {
    constructor(saResponse) {
        // response may be empty in cases like network error or timeout
        if (saResponse && saResponse.req) {
            super({
                responseBody: saResponse.body,
                request: saResponse.req,
                headers: saResponse.header,
                status: saResponse.status,
                statusText: saResponse.statusText
            });
        }
        else {
            super({ error: saResponse });
        }
    }
}

const handleError = error => {
    // error.response is undefined in cases like network error or timeout
    throw new UtdRestResponseSuperagent(error && error.response || error);
};

// Methods to perform HTTP REST requests
// All methods return promises.
export default {
    getRaw(uri) {
        return agent
            .get(uri)
            .timeout({
                response: C_HTTP.DEFAULT_TIMEOUT
            })
            .then(response => {
                return { data: response.text };
            });
    },
    // Asynchronous GET method to deal with simple binary data responses
    getStream(uri) {
        return agent
            .get(uri)
            .timeout({
                response: C_HTTP.DEFAULT_TIMEOUT
            })
            .then(response => {
                return { data: response.text };
            })
            .catch(handleError);
    },
    // Asynchronous GET method.
    get(uri, headers = {}, params = {}) {
        return agent
            .get(uri)
            .set(headers)
            .query(params)
            .timeout({
                response: C_HTTP.DEFAULT_TIMEOUT
            })
            .then(response => new UtdRestResponseSuperagent(response))
            .catch(handleError);
    },

    // Asynchronous POST method
    post(uri, data, headers = {}, params = {}) {
        return agent
            .post(uri)
            .send(data)
            .set(headers)
            .query(params)
            .timeout({
                response: C_HTTP.DEFAULT_TIMEOUT
            })
            .then(response => new UtdRestResponseSuperagent(response))
            .catch(handleError);
    },

    // Asynchronous PUT method
    // Warning: this is an example implementation, it is untested
    put(uri, data, headers = {}, params = {}) {
        return agent
            .put(uri)
            .send(data)
            .set(headers)
            .query(params)
            .timeout({
                response: C_HTTP.DEFAULT_TIMEOUT
            })
            .then(response => new UtdRestResponseSuperagent(response))
            .catch(handleError);
    },

    // Asynchronous DELETE method
    // Warning: this is an example implementation, it is untested
    delete(uri, data, headers = {}, params = {}) {
        return agent
            .delete(uri)
            .send(data)
            .set(headers)
            .query(params)
            .timeout({
                response: C_HTTP.DEFAULT_TIMEOUT
            })
            .then(response => new UtdRestResponseSuperagent(response))
            .catch(handleError);
    }
};
