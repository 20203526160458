/* eslint-disable complexity */
import { h, ref, computed, watch, getCurrentInstance } from 'vue';

export default {
    name: 'UiView',
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: 'default'
        }
    },
    compatConfig: { MODE: 3 },
    setup(props, { attrs, slots }) {
        const vm = getCurrentInstance();
        const router = vm.appContext.app.config.globalProperties.$router;
        const currentRoute = computed(() => router.currentRoute.value);
        const currentProps = computed(() => router.currentProps.value);

        const viewRef = ref();
        watch(() => [ viewRef.value, currentRoute.value, props.name ],
            ([ instance, to, name ]) => {
                // copy reused instances
                if (to && to.instances) {
                    // this will update the instance for new instances as well as reused
                    // instances when navigating to a new route
                    to.instances[name] = instance;
                }
            }, { flush: 'post' });

        return () => {
            const matchedRoute = currentRoute.value;
            const ViewComponent = matchedRoute && matchedRoute.component;

            if (!ViewComponent) {
                return normalizeSlot(slots.default, { Component: ViewComponent, matchedRoute });
            }

            // props from route configuration
            const routePropsOption = currentProps.value;
            const routeProps = routePropsOption
                ? routePropsOption === true
                    ? routePropsOption
                    : typeof routePropsOption === 'function'
                        ? routePropsOption(matchedRoute)
                        : routePropsOption
                : null;

            const currentName = (routeProps && routeProps.name) || 'default';
            const onVnodeUnmounted = vnode => {
                // remove the instance reference to prevent leak
                if (vnode.component.isUnmounted) {
                    matchedRoute.instances[currentName] = null;
                }
            };

            const component = h(ViewComponent, Object.assign({}, routeProps, attrs, {
                onVnodeUnmounted,
                ref: viewRef
            }));

            return (
                // pass the vnode to the slot as a prop.
                // h and <component :is="..."> both accept vnodes
                normalizeSlot(slots.default, { Component: component, matchedRoute }) ||
                component);
        };
    }
};

function normalizeSlot(slot, data) {
    if (!slot) {
        return null;
    }
    const slotContent = slot(data);
    return slotContent.length === 1 ? slotContent[0] : slotContent;
}