import { safeDecodeUriComponent } from '_acaSrc/utility/http';
import Cookies from 'js-cookie';
import { getDocument, getNavigator } from './DOM';

/**
 * Read cookie.
 * @param {String} name The name of cookie
 */
export function getCookie(name) {
    return Cookies.get(name) || '';
}

export function getDecodeCookie(name) {
    const cookieValue = Cookies.get(name);
    if (!cookieValue) {
        return '';
    }
    return safeDecodeUriComponent(cookieValue);
}

export function getCookies() {
    return Cookies.get();
}

/**
 * Create a cookie.
 * @param {String} name The name of cookie
 * @param {String} value The value of cookie
 * @param {Object} options Cookie Attributes
 * @param {Object} options.expires Define when the cookie will be removed.
 * Value must be a Number which will be interpreted as days from time of
 * creation or a Date instance. If omitted, the cookie becomes a session cookie.
 *
 * @param {Object} options.path A String indicating the path where the cookie is visible
 * @param {Object} options.domain A String indicating a valid domain where the cookie should
 * be visible. The cookie will also be visible to all subdomains.
 * Default: Cookie is visible only to the domain or subdomain of
 * the page where the cookie was created
 *
 * @param {Object} options.secure Either true or false, indicating if the cookie transmission
 * requires a secure protocol (https).
 *
 * @param {Object} options.sameSite A String, allowing to control whether the browser
 * is sending a cookie along with cross-site requests.
 */
export function setCookie(name, value, options = {}) {
    return Cookies.set(name, value, options);
}

export function deleteCookie(name, options = {}) {
    Cookies.remove(name, options);
}

export function isCookiesEnabled() {
    let retval = true;
    try {
        let cookieEnabled = getNavigator().cookieEnabled;
        if (typeof getNavigator().cookieEnabled === 'undefined' && !cookieEnabled) {
            getDocument().cookie = 'testcookie';
            // eslint-disable-next-line eqeqeq
            cookieEnabled = (getDocument().cookie.indexOf('testcookie') != -1);
        }
        retval = cookieEnabled;
    }
    catch (err) {
        retval = false;
    }
    return retval;
}