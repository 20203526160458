import { isInsideAnkiFrame } from '_acaSrc/utility/Browsers';
import { C_RESPONSIVE, C_BROWSER } from '_acaSrc/utility/constants';
import IndexManager from '_acaSrc/utility/IndexManager';
import {
    getWindow,
    getDocument,
    isTablet,
    isSmallScreen
} from '_acaSrc/utility/DOM';

const state = {
    browser: {
        type: C_BROWSER.TYPE_DESKTOP,
        os: '',
        osVersion: '',
        name: '',
        version: '',
        nVersion: 0, // Numeric format of "version"
        vendor: '',
        customVersion: '',
        isChrome: false,
        isMobileOnDesktop: false,
        desktopViewType: C_RESPONSIVE.IS_NOT_DESKTOP,
        isSupported: {
            tippy: false,
            canvas: false
        },
        isAnkiAddon: false
    },
    fullscreenClass: ''
};

export const getters = {
    browserName: state => state.browser.name,
    browserType: state => state.browser.type,
    isBrowserNameMSIE: state => state.browser.name === C_BROWSER.NAME_MICROSOFT_IE,
    isBrowserNameSafari: state => state.browser.name === C_BROWSER.NAME_APPLE_SAFARI,
    isBrowserOSAppleDesktop: state => state.browser.os === C_BROWSER.OS_APPLE_DESKTOP,
    isBrowserTypeSmallScreen: state => state.browser.type === C_BROWSER.TYPE_SMALL_SCREEN,
    isBrowserTypeDesktop: state => state.browser.type === C_BROWSER.TYPE_DESKTOP,
    isMobileOnDesktop: state => state.browser.isMobileOnDesktop,
    isAnkiAddon: state => state.browser.isAnkiAddon,
    desktopViewType: state => state.browser.desktopViewType,
    isDesktopView: state => {
        return state.browser.type === C_BROWSER.TYPE_DESKTOP
            && !state.browser.isMobileOnDesktop;
    },
    isNotDesktopView: state => {
        return state.browser.type !== C_BROWSER.TYPE_DESKTOP
            || state.browser.isMobileOnDesktop;
    },
    browserData: state => state.browser,
    deviceSize: state => {
        if (!getWindow().matchMedia) {
            // Fallback for old browsers
            return 'L';
        }
        let deviceSize = 'S';
        if (state.browser.type === C_BROWSER.TYPE_TABLET) {
            deviceSize = 'M';
        }
        else if (getWindow().matchMedia('(min-width: 768px)').matches
            && getWindow().matchMedia('(max-width: 1280px)').matches) {
            deviceSize = 'L';
        }
        else if (getWindow().matchMedia('(min-width: 1280px)').matches) {
            deviceSize = 'XL';
        }
        return deviceSize;
    },
    deviceType: state => {
        let deviceType = 'phone';
        if (state.browser.type === C_BROWSER.TYPE_TABLET) {
            deviceType = 'tablet';
        }
        else if (state.browser.type === C_BROWSER.TYPE_DESKTOP) {
            deviceType = 'desktop';
        }
        return deviceType;
    },
    supportsCanvas: state => {
        return (state.browser.name === C_BROWSER.NAME_MICROSOFT_IE
            && (state.browser.nVersion >= 10));
    },
    supportsEventOptions: state => state.browser.name !== C_BROWSER.NAME_MICROSOFT_IE,
    noNativeCapsLock: state => {
        const isSafariBrowser = state.browser.name.indexOf(C_BROWSER.NAME_APPLE_SAFARI) > -1;
        return (!isSafariBrowser
            || (isSafariBrowser && state.browser.os === C_BROWSER.OS_APPLE_MOBILE));
    },
    shouldResetSearchScrollPosition: (state, getters, rootState, rootGetters) => {
        return !rootGetters['app/isFixedToolbar']
            && (state.browser.os === C_BROWSER.OS_APPLE_DESKTOP
                || state.browser.os === C_BROWSER.OS_APPLE_MOBILE);
    },
    browserVersionIsGTE: state => options => {
        if (options) {
            if (options.ie) {
                if (state.browser.name !== C_BROWSER.NAME_MICROSOFT_IE) {
                    return true;
                }
                return state.browser.nVersion >= Number(options.ie);
            }
        }

        return false;
    },
    knowledgeCenterSupportsUserAgent: state => {
        const deviceOsVersionNum = parseFloat(state.browser.osVersion, 10);
        return state.browser.os !== C_BROWSER.OS_APPLE_MOBILE || deviceOsVersionNum >= 10;
    },
    fullscreenClass: state => state.fullscreenClass,
    showManageCookiesLink(state, getters, rootState) {
        return state.browser.name !== C_BROWSER.NAME_MICROSOFT_IE
            && rootState.app.isOneTrustEnabled;
    }
};

export const SET_BROWSER_INFO = 'SET_BROWSER_INFO';
export const SET_DEVICE_TYPE_FROM_SCREEN = 'SET_DEVICE_TYPE_FROM_SCREEN';
export const SET_IS_MOBILE_ON_DESKTOP = 'SET_IS_MOBILE_ON_DESKTOP';
export const SET_DESKTOP_VIEW_TYPE = 'SET_DESKTOP_VIEW_TYPE';
export const SET_IS_SUPPORTED_TIPPY = 'SET_IS_SUPPORTED_TIPPY';
export const SET_IS_SUPPORTED_OPTIMIZELY = 'SET_IS_SUPPORTED_OPTIMIZELY';
export const SET_IS_SUPPORTED_CANVAS = 'SET_IS_SUPPORTED_CANVAS';
export const SET_FULLSCREEN_CLASS = 'SET_FULLSCREEN_CLASS';
export const SET_IS_ANKI_ADDON = 'SET_IS_ANKI_ADDON';

export const mutations = {
    // eslint-disable-next-line complexity
    [SET_BROWSER_INFO](state, data) {
        const type = data.browserType;
        const os = data.deviceOs;
        const osVersion = data.deviceOsVersion;
        const name = data.mobileBrowser;
        const version = data.mobileBrowserVersion;
        const vendor = data.mobileBrowserVendor;
        // Only change it if the value is set and different
        if (type && state.browser.type !== type) {
            state.browser.type = type;
        }
        if (os && state.browser.os !== os) {
            state.browser.os = os.toLowerCase();
        }
        if (osVersion && state.browser.osVersion !== osVersion) {
            state.browser.osVersion = osVersion;
        }
        if (name && state.browser.name !== name) {
            // Remove all whitespace from browser name, as they
            // are included as classes on body tag in index.ejs
            state.browser.name = name.replace(/\s+/g, '');
        }
        if (version && state.browser.version !== version) {
            state.browser.version = version;
            try {
                state.browser.nVersion = parseFloat(version);
            }
            catch (e) {
                state.browser.nVersion = -1;
            }
        }
        if (vendor && state.browser.vendor !== vendor) {
            state.browser.vendor = vendor;
        }

        new IndexManager().setBodyCssClasses({
            browserName: state.browser.name,
            browserType: state.browser.type,
            customVersion: state.browser.customVersion,
            browserOs: state.browser.os
        });
    },
    [SET_DEVICE_TYPE_FROM_SCREEN](state) {
        // This check is needed for Apple browsers, as they are now using the same
        // user agent for Safari on iPads, iPhones and desktop platforms.
        if (state.browser.type !== C_BROWSER.TYPE_DESKTOP
            || state.browser.vendor !== C_BROWSER.VENDOR_APPLE) {
            return;
        }
        if (isTablet()) {
            state.browser.type = C_BROWSER.TYPE_TABLET;
            new IndexManager().bodyCss.setOrClear('browserType', state.browser.type);
            return;
        }
        if (isSmallScreen()) {
            state.browser.type = C_BROWSER.TYPE_SMALL_SCREEN;
            new IndexManager().bodyCss.setOrClear('browserType', state.browser.type);
        }
    },
    [SET_IS_MOBILE_ON_DESKTOP](state) {
        const devWidth = Math.max(
            getDocument().documentElement.clientWidth,
            getWindow().innerWidth || 0
        );
        state.browser.isMobileOnDesktop = (
            (devWidth < C_RESPONSIVE.MIN_DESKTOP_THRESHOLD_PX)
         && (state.browser.type === C_BROWSER.TYPE_DESKTOP)
        );

        new IndexManager().bodyCss.setOrClear('mobileonDesktop', state.browser.isMobileOnDesktop);
    },
    [SET_DESKTOP_VIEW_TYPE]: (state, type) => state.browser.desktopViewType = type,
    [SET_IS_SUPPORTED_TIPPY](state, supportsTippy) {
        state.browser.isSupported.tippy = supportsTippy;
    },
    [SET_IS_SUPPORTED_OPTIMIZELY](state, supportsOptimizely) {
        state.browser.isSupported.optimizely = supportsOptimizely;
    },
    [SET_IS_SUPPORTED_CANVAS](state, supportsCanvas) {
        state.browser.isSupported.canvas = supportsCanvas;
    },
    [SET_FULLSCREEN_CLASS](state, fullscreenClass) {
        state.fullscreenClass = fullscreenClass;
        new IndexManager().bodyCss.set('fullScreenClass', state.fullScreenClass);
    },
    [SET_IS_ANKI_ADDON](state) {
        state.browser.isAnkiAddon = isInsideAnkiFrame();
    }
};

export const actions = {
    setBrowserInfo: ({ commit, getters }, data) => {
        commit('SET_BROWSER_INFO', data);
        commit('SET_DEVICE_TYPE_FROM_SCREEN');
        commit('SET_IS_MOBILE_ON_DESKTOP');
        commit('SET_IS_SUPPORTED_TIPPY', getters.browserVersionIsGTE({ ie: 11 }));
        commit('SET_IS_SUPPORTED_OPTIMIZELY', getters.browserVersionIsGTE({ ie: 11 }));
        commit('SET_IS_SUPPORTED_CANVAS', getters.supportsCanvas);
        commit('SET_IS_ANKI_ADDON');
    },
    checkResponsiveAppType({ getters, commit, dispatch }) {
        if (getters.browserType !== C_BROWSER.TYPE_DESKTOP) {
            return;
        }

        commit(SET_IS_MOBILE_ON_DESKTOP);

        let responsiveTransition;
        const currentAppType = getters.desktopViewType;
        const isMobileOnDesktop = getters.isMobileOnDesktop
            ? C_RESPONSIVE.MOBILE_VIEW
            : C_RESPONSIVE.DESKTOP_VIEW;
        commit(SET_DESKTOP_VIEW_TYPE, isMobileOnDesktop);

        if (currentAppType !== isMobileOnDesktop) {
            responsiveTransition = {
                previous: currentAppType,
                current: isMobileOnDesktop
            };
        }

        if (responsiveTransition) {
            dispatch('app/publish', {
                eventName: 'RESPONSIVE_TRANSITION',
                eventData: responsiveTransition
            }, { root: true });
        }
    }
};

const device = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default device;
