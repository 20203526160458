<template>
  <div v-if="showOidcPartner" class="top-banner-container-outline">
    <div class="partner-messaging-narrow">
      <div class="partner-text-narrow" v-html="oidcPartnerDetails.bannerMessage" />
      <div v-if="isNotDesktopView" class="top-banner-button-container">
        <utd-button class="partner-subscribe-button"
                    @click="subscribe">{{ $t("HEADER.SUBSCRIBE") }} </utd-button>
        <utd-button class="partner-login-button"
                    @click="login">{{ $t("LOGIN.LOG_IN") }} </utd-button>
      </div>
    </div>
  </div>

</template>

<script>
import { getWindow } from '_acaSrc/utility/DOM';
import { getUrlHash } from '_acaSrc/utility/http';
import { mapGetters } from 'vuex';
import UtdButton from '../stdlib/UtdButton.vue';

export default {
    components: { UtdButton },
    props: {
        checkHash: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hash: getUrlHash()
        };
    },
    computed: {
        ...mapGetters('app', [ 'isProspectMode' ]),
        ...mapGetters('auth', [ 'oidcPartnerDetails', 'storeUrl' ]),
        ...mapGetters('device', [ 'isNotDesktopView' ]),
        isShowBanner() {
            return (this.checkHash && !this.hash) || !this.checkHash;
        },
        showOidcPartner() {
            return this.oidcPartnerDetails.partnerName
                && this.isShowBanner
                && this.isProspectMode
                && this.isNotDesktopView;
        }
    },
    mounted() {
        getWindow().addEventListener('hashchange', this.onHashChange);
    },
    beforeUnmount() {
        getWindow().removeEventListener('hashchange', this.onHashChange);
    },
    methods: {
        onHashChange() {
            this.hash = getUrlHash();
        },
        login() {
            getWindow().location.href = '/login';
        },
        subscribe() {
            getWindow().open(this.storeUrl, 'storefront', 'noopener');
        }
    }
};
</script>

<style lang="less" scoped>
/* LESS for Partner messaging banner */
@import (reference) '~_acaAssets/global/variables';
@import (reference) '~_acaAssets/global/global';
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@button-height: 40px;
@button-width: 120px;
@button-border-radius: 2px;
@button-transition-time: @DS1-UTD-TRANSITION-DURATION;
@banner-font-size: 14px;
@banner-min-height: 1px;

.top-banner-container {
  .ds1-pa-2();
  display: flex;
  flex-direction: column;
  min-height: @banner-min-height;
}

.top-banner-container-outline {
  .ds1-pa-2();
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  min-height: @banner-min-height;
}

.top-banner-button-container {
  .ds1-pb-1();
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: @banner-min-height;
}

.partner-messaging-narrow {
  width: auto;
  background-color: @WKCE-BLUE-TINT5;
  border-style: solid;
  border-color: @WKCE-BLUE-SHADE1;
  border-width: @banner-min-height;
  border-radius: 8px;
}

.partner-text-narrow {
  .ds1-ph-2();
  .ds1-pv-1();
  font-size: @banner-font-size;
  justify-content: center;
}

.partner-image-narrow {
  .ds1-pb-1();
  max-height: @button-height;
  justify-content: center;
  width: auto;
}

.partner-login-button {
  background-color: @WKCE-GREEN;
  color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
  width: @button-width;
  height: @button-height;
  align-self: center;

  &:hover {
    background-color: @WKCE-GREEN-SHADE1;
  }
}

.partner-button-link {
  text-decoration: none;
  color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
}

.partner-subscribe-button {
  margin-right: 10%;
  background-color: @WKCE-ORANGE;
  color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
  width: @button-width;
  height: @button-height;
  align-self: center;

  &:hover {
    background-color: @WKCE-ORANGE-SHADE1;
  }
}
</style>