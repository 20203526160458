import {
    elAddClass,
    elRemoveClass,
    getDocument,
    getPreviousSiblingTarget,
    getWindow,
    reassignNextSiblings,
    replaceParent
} from '_acaSrc/utility/DOM';
import { getUrlHash, getQueryParamValues, getUrlWithoutHash } from '_acaSrc/utility/http';
import { stripPrefix, pxToNum } from '_acaSrc/utility/String';
import { debounce } from '_acaSrc/utility/timers';
import { C_TOPIC } from '_acaSrc/utility/constants';
import { rebindCollapsibleIndicationLinks as rebindCIL } from './topic-accordions';

const { ANCHOR_HASH_PREFIX, SCROLL_TARGET_SUFFIX } = C_TOPIC;
const UTD_ADT_PATHWAYS_CLASS = 'utd-adt-pathwys';
const UTD_ADT_RX_TRANSITIONS_CLASS = 'utd-adt-rx-transitions';
const UTD_CONTENT_CLASS_PREFIX = 'utd-content-';
const UTD_PATHWAYS_UNAVAILABLE_CLASS = 'pathway-unavailable';

export const regexHref = /href="#/g;

export const getReplaceHref
    = () => `href="${getWindow().location.pathname}${getWindow().location.search}#`;

export function isGraphicRef(input) {
    return /^graphicRef[a-zA-Z0-9]+$/.test(input);
}

export function getGraphicRef(ele) {
    const classes = ele
        && ele.classList
        && Array.from(ele.classList).filter(className => isGraphicRef(className));
    return (classes && classes[0]) || '';
}

export function processTopicTitleForLandingPage(topic) {
    const title = stripPrefix(topic.title, ':', 2);
    if (topic.type === 'pathway') {
        return `UpToDate Pathways: ${title}`;
    }
    return title;
}

export function isTopicDrugDxySubtype(subtype) {
    return subtype === 'drug_dxy'
        || subtype === 'drug_dxy_package_insert';
}

export function applyMobileSafariScrollbarFix() {
    // Called from utd-app.run $rootScope.$watch back/forward handler, this
    // method handles restoring the scrollbars for ios devices on back/forward
    // navigation.
    // https://stackoverflow.com/a/26168413
    setTimeout(() => {
        const topicTextEl = document.getElementById('topicText');
        const topicArticleEl = document.getElementById('topicArticle');
        if (topicTextEl && topicArticleEl) {
            const curScrollTop = topicArticleEl.scrollTop;
            topicTextEl.style.display = 'none';
            // Need to read offsetHeight for fix to work
            // eslint-disable-next-line no-unused-vars
            const doots = topicTextEl.offsetHeight;
            topicTextEl.style.display = 'block';
            topicArticleEl.scrollTo(0, curScrollTop);
        }
    }, 100);
}

export function collapseAllFormulinkAccordions() {
    Array.from(document.querySelectorAll('.drawer-open[data-accordion]'))
        .forEach(el => elRemoveClass(el, 'drawer-open'));
}

export function setFormulinkSrollTargetIds(htmlAssets) {
    htmlAssets && htmlAssets.forEach(asset => {
        asset.content = asset.content.replace(/id='fiuTitle/g, `id='${ANCHOR_HASH_PREFIX}fiuTitle`);
        asset.content = asset.content.replace(/id='fiuAcct/g, `id='${ANCHOR_HASH_PREFIX}fiuAcct`);
    });
}

export function getTopicOutlineMap() {
    // Build map of article heading target elements, referenced by their hash
    // NOTES:
    // - Conditionally called from within the TopicOutlineManager.vue scroll event handler
    // - Including ANCHOR_HASH_PREFIX for index references to simplify later comparisons
    const outlineMap = {};
    Array.from(document.querySelectorAll('#outlineSections a')).forEach(anchorEl => {
        outlineMap[`${ANCHOR_HASH_PREFIX}${getUrlHash(anchorEl.href)}`] = anchorEl;
    });
    return outlineMap;
}

export function getTopicArticleHeadings() {
    let topicHeadings = [];

    topicHeadings = Array.from(document.querySelectorAll('#topicText [id]'));

    // Special processing for "References" heading
    const refEle = document.getElementById(`${ANCHOR_HASH_PREFIX}references`);
    if (topicHeadings.length && refEle) {
        topicHeadings.push(refEle);
    }

    // Special processing for dynamic "What's New" content elements
    if (topicHeadings.length && document.getElementById('topicWhatsNewContainer')) {
        const callouts = document.querySelectorAll('#topicWhatsNewContainer .topicCallout');
        // Order matters, and must match topic layout.
        topicHeadings = Array.from(callouts).concat(topicHeadings);
    }

    return topicHeadings;
}

export function moveSameArticleClickToTop(selector, callback) {
    Array.from(document.querySelectorAll(selector)).forEach(el => {
        if (el.href
            && el.href.search('#') === -1
            && el.pathname === getWindow().location.pathname) {
            el.addEventListener('click', event => {
                event.preventDefault();
                callback();
            });
        }
    });
}

export function extractDrugNameFromTopicTitle(title) {
    let index = title.lastIndexOf(':');
    if (index < 0) {
        index = title.lastIndexOf('：') < 0 ? title.length : title.lastIndexOf('：');
    }
    return title.substr(0, index);
}

export function appendAutoSuggestDrugSectionLabel(targetSelector, topicTitle) {
    if (targetSelector
        && getQueryParamValues(getWindow().location.href, 'showDrugLabel') === 'true') {
        const drugName = extractDrugNameFromTopicTitle(topicTitle);
        const labelTargetEl = document.querySelector(targetSelector);
        if (drugName && labelTargetEl) {
            labelTargetEl.innerHTML = `${
                labelTargetEl.innerHTML
            } <span class="autosuggest__injected-drug-name">(${drugName})</span>`;
        }
    }
}

export function checkAppendDrugSectionLabel(topicTitle, hash) {
    if (hash) {
        appendAutoSuggestDrugSectionLabel(
            `#xhash_${hash} > span.drugH1`, topicTitle);
    }
}

export function bindRehashHandler(handler) {
    // eslint-disable-next-line max-len
    Array.from(document.querySelectorAll('#outlineSections li a,#topicContent a.local, #disclaimerLink a'))
        .forEach(el => el.addEventListener('click', handler));
}

export function unbindRehashHandler(handler) {
    // eslint-disable-next-line max-len
    Array.from(document.querySelectorAll('#outlineSections li a,#topicContent a.local, #disclaimerLink a'))
        .forEach(el => el.removeEventListener('click', handler));
}

export function bindFormulinkRehashHandlers(handlerFn) {
    Array.from(getDocument().querySelectorAll('#outlineSections a[href^="#fiu"]')).forEach(el => {
        el.addEventListener('click', handlerFn);
    });
}

export function unbindFormulinkRehashHandlers(handlerFn) {
    Array.from(getDocument().querySelectorAll('#outlineSections a[href^="#fiu"]')).forEach(el => {
        el.removeEventListener('click', handlerFn);
    });
}

export function processScrollTargets(topicType) {
    // This method handles assigning special '_scrollTarget' classes to the
    // topic article elements (or their immediate child elements), which have an
    // ID starting with 'xhash_'.

    // This is called in the setup for the TopicSmoothScoller, and in topic.controller
    // when new content is injected into the DOM.
    if (!topicType) {
        return;
    }

    // Assign scroll target to #topicContent
    _processTopicContentScrollTargets();

    // Assign scroll target classess for all topic anchors
    _processTopicAnchorScrollTargets(topicType);

    // Assign scroll target class for the disclaimer box
    _processTopicDisclaimerScrollTarget();

    // The "#references" markup is setup differently from other sections.
    // Refactor markup to work with the smooth scroller.
    _processReferencesAnchorScrollTarget();
}

// Exported for unit tests
export function _processTopicContentScrollTargets() {
    const topicContentEl = document.querySelector('#topicContent');
    if (topicContentEl) {
        elAddClass(topicContentEl,
            `${ANCHOR_HASH_PREFIX}topicContent${SCROLL_TARGET_SUFFIX}`);
    }
}

export function _processTopicDisclaimerScrollTarget() {
    const disclaimerContentEl = document.querySelector('#disclaimer');
    if (disclaimerContentEl) {
        elAddClass(disclaimerContentEl,
            `${ANCHOR_HASH_PREFIX}disclaimerContent${SCROLL_TARGET_SUFFIX}`);
    }
}

export function _processTopicAnchorScrollTargets(topicType) {
    const typePrefix = topicType === 'drug' ? 'F' : 'H';
    const selector = `#topicArticle [id^="${ANCHOR_HASH_PREFIX}${typePrefix}"]`;
    Array.from(document.querySelectorAll(selector)).forEach(el => {
        // Elements with 'emptyAnchor' classes are for prospect topics
        const elId = el.getAttribute('id');
        el = el.classList.contains('emptyAnchor') ? el : el.firstChild;
        elAddClass(el, `${elId}${SCROLL_TARGET_SUFFIX}`);
    });
}

export function _processReferencesAnchorScrollTarget() {
    const el = document.querySelector(`#${ANCHOR_HASH_PREFIX}references`);
    if (el && !el.classList.contains('topic-references')) {
        elAddClass(el, 'topic-references');
        const refH1 = el.querySelector('H1');
        const newRef = document.createElement('span');
        elAddClass(newRef, 'h1');
        elAddClass(newRef, `${el.getAttribute('id')}${SCROLL_TARGET_SUFFIX}`);
        newRef.innerHTML = refH1.innerHTML;
        el.replaceChild(newRef, refH1);
    }
}

export const TOPIC_ELEMENTS_STABLE_DEBOUNCE_MS = 10;
// If we encounter mispositions, try increasing this number by 1
export const TOPIC_ELEMENTS_STABLE_MIN_STABLE_COUNT = 12;
export const TOPIC_ELEMENTS_STABLE_MAX_ITERATIONS = 20; // Max seen is 7, but just to be safe...

let topicStablePromise;
export function onTopicElementsStable(reset = false) {
    if (reset) {
        topicStablePromise = null;
    }

    if (topicStablePromise) {
        return topicStablePromise;
    }

    topicStablePromise = new Promise(resolve => {
        // Used to ensure topic element positions have stabalized in the DOM
        // before initiating a scroll to their position.

        // PLEASE LEAVE 'debug()' METHOD AND CALLS IN FILE AND COMMENTED
        // const debug = (newPos, status) => {
        //     if (status !== 'START') {
        //         console.log(`onTopicElementsStable() - ${status}
        // > iterations=[${iterations}]
        // > stableCount=[${stableCount}]
        // > pos=[${newPos}]
        // > last=[${lastElementPosition}]
        // > aborted=[${iterations > TOPIC_ELEMENTS_STABLE_MAX_ITERATIONS}]`);
        //     }
        //     if (status === 'START') {
        //         console.time('TIME IN onTopicElementsStable()');
        //     }
        //     else if (status === 'STABLE') {
        //         console.timeEnd('TIME IN onTopicElementsStable()');
        //     }
        // };
        // debug('n/a', 'START');

        let testElement,
            isInitialized = false,
            isStable = false,
            stableCount = 0,
            lastElementPosition = 0,
            topicArticleEl = null,
            iterations = 0;

        const initializeTestElement = () => {
            // Set element to calculate scroll position for mobile prospect or tablet landscape
            topicArticleEl = document.querySelector('#topicArticle');

            // Retrieve all scroll target topic elements, use last in collection for testing
            if (!topicArticleEl) {
                return;
            }

            const topicElements = Array.from(
                topicArticleEl.querySelectorAll(`[id^="${ANCHOR_HASH_PREFIX}"]`)
            );

            if (topicElements.length) {
                testElement = topicElements[topicElements.length - 1];
            }

            if (testElement && testElement.getBoundingClientRect) {
                isInitialized = true;
            }
        };

        const testTopicElementsStable = () => {
            // Calculate current element position, offset by any scroll
            const newLastElementPosition = testElement.getBoundingClientRect().top
                + (window.pageYOffset | (topicArticleEl && topicArticleEl.scrollTop));

            if (newLastElementPosition === lastElementPosition && newLastElementPosition > 0) {
                // debug(newLastElementPosition, 'MATCHED');
                stableCount++;
            }
            // else {
            //     debug(newLastElementPosition, 'DID NOT MATCH');
            // }
            lastElementPosition = newLastElementPosition;

            if (stableCount >= TOPIC_ELEMENTS_STABLE_MIN_STABLE_COUNT) {
                // eslint-disable-next-line max-len
                // console.log(`topic.js::onTopicElementsStable() isStable after [${stableCount}] iterations`);
                isStable = true;
            }
        };

        const onStableDebounce = debounce(() => {
            iterations++;

            if (!isInitialized) {
                initializeTestElement();
            }

            if (isInitialized) {
                testTopicElementsStable();
            }

            if (isStable || iterations > TOPIC_ELEMENTS_STABLE_MAX_ITERATIONS) {
                resolve('STABLE');
                return;
            }

            onStableDebounce();

        }, TOPIC_ELEMENTS_STABLE_DEBOUNCE_MS);

        onStableDebounce();
    });

    return topicStablePromise;
}

// Replaces all placeholders in topic and outline with injectable html content
export function renderInjectableHtmlAssets(htmlAssets) {
    const replaceHref = getReplaceHref();
    htmlAssets && htmlAssets.forEach(sourceAsset => {
        const asset = convertOutlineHrefs(sourceAsset, replaceHref);
        const div = document.createElement('div');
        div.innerHTML = asset.content;
        const newElement = div.firstChild;
        if (newElement) {
            const elToRepl = document.getElementById(asset.htmlDestinationId);
            if (elToRepl) {
                elToRepl.parentElement.insertBefore(newElement, elToRepl);
                elToRepl.parentElement.removeChild(elToRepl);
            }
        }
    });
}

function convertOutlineHrefs(asset, replaceHref) {
    let { content } = asset;
    const { htmlDestinationId } = asset;
    const destination = document.getElementById(htmlDestinationId);
    const outlineContent = destination && destination.closest('#outlineContent');

    if (outlineContent) {
        content = content.replace(regexHref, replaceHref);
    }

    content = content.trim();

    return {
        ...asset,
        content
    };
}

export function renderInjectableCssAssets(cssAssets) {
    if (cssAssets && cssAssets.length) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.setAttribute('id', 'injectedCss');
        let injectedCss = '';

        cssAssets.forEach(asset => {
            if (asset.content && asset.content.trim) {
                injectedCss = `${injectedCss}${asset.content.trim()}`;
            }
        });

        style.innerHTML = injectedCss;
        return style;
    }
    return '';
}

export const rebindCollapsibleIndicationLinks = rebindCIL;

export const postInjectionCleanup = () => {
    // A hack to hide lims links. css only fails in IE.
    const className = 'hide-lims-link';
    Array.from(getDocument().querySelectorAll('a[href*="urn:lims"]')).forEach(elem => {
        elem.href = '';
        elem.classList.add(className);
        elem.addEventListener('click', e => e.preventDefault());
    });
};

export const getPathwayLinks = pathwayId => {
    return getDocument()
        .querySelectorAll(`.${UTD_ADT_PATHWAYS_CLASS}.${UTD_CONTENT_CLASS_PREFIX}${pathwayId}`);
};

export const getRxTransitionsLinks = () => {
    return getDocument().querySelectorAll(`.${UTD_ADT_RX_TRANSITIONS_CLASS}`);
};

export const hideDisabledPathwayLink = pathwayLink => {
    pathwayLink.setAttribute('style', 'display:none !important');
};

export const setPathwayUnavailableClass = pathwayLink => {
    pathwayLink.setAttribute('class', `${UTD_PATHWAYS_UNAVAILABLE_CLASS}`);
    pathwayLink.setAttribute('onclick', 'return false;');
};

export const showEnabledPathwayLink = pathwayLink => {
    if (pathwayLink.classList && pathwayLink.classList.contains('plainItem')) {
        pathwayLink.setAttribute('style', 'display:block !important');
    }
    else {
        pathwayLink.setAttribute('style', 'display:inline !important');
    }
};

export const processBodyHtml = (html, subtype) => {
    if (!html) {
        return '';
    }

    // Abort for drug DXY monographs and package inserts, as we
    // want the browser to try automatically positioning first,
    // and it's possible they may have "H#" or "F#" ID attributes.
    if (isTopicDrugDxySubtype(subtype)) {
        return html;
    }

    // Assign prefix for all topic body ID elements to prevent the browser
    // from automatically positioning when ID hash is in the URL.
    html = html.replace(/id="H/g, `id="${ANCHOR_HASH_PREFIX}H`);
    html = html.replace(/id="F/g, `id="${ANCHOR_HASH_PREFIX}F`);
    html = html.replace(/id="references/g, `id="${ANCHOR_HASH_PREFIX}references`);

    // Ensure all href attributes are prefixed with correct domain
    return html.replace(regexHref, getReplaceHref());
};

export const processOutlineHtml = outlineHtml => {
    if (!outlineHtml) {
        return '';
    }
    return outlineHtml.replace(regexHref, getReplaceHref());
};

export const getProspectDesktopArticlePaddingTop = elements => {
    const { articleEl, toolbarEl, containerEl } = elements;

    const articleStyles = getWindow().getComputedStyle(articleEl);
    let articlePaddingTopPx
        = (toolbarEl.offsetTop + toolbarEl.offsetHeight)
        - containerEl.getBoundingClientRect().top
        - window.pageYOffset;

    if (articleStyles) {
        articlePaddingTopPx -= pxToNum(articleStyles.marginTop);
    }

    return articlePaddingTopPx;
};

export const TopicLinkTypes = Object.freeze({
    // eslint-disable-next-line complexity
    isExternal: (link, currentHost = getWindow().location.host) => {
        if (!link.href) {
            return false;
        }
        const includesCurrentHost = link.href.includes(currentHost);
        const includesRedirect = link.href.includes('external-redirect');
        const isDrugInteraction = link.href.includes('drug-interactions');
        const isRxTransitions = link.href.includes('/rxtransitions');
        const includesPathway = link.href.includes('/pathway/');
        const containsExternalClass = link.classList.contains('external');
        return (!includesCurrentHost && !includesPathway)
            || (containsExternalClass && !isDrugInteraction && !isRxTransitions)
            || (includesRedirect && !isDrugInteraction);
    },
    isPathway: (link, origin = getWindow().location.origin) => {
        return link.href.startsWith(`${origin}/pathway/`);
    }
});

export const getHashFromElementParent = el => {
    const hash = el.parentElement.getAttribute('id');
    return hash
        ? hash.replace(C_TOPIC.ANCHOR_HASH_PREFIX, '') : '';
};

export const getUrlFromElement = el => {
    return `${getUrlWithoutHash()}#${getHashFromElementParent(el)}`;
};

/**
 * Takes a DOM element in a topic and puts it in a new parent div along with its sibling elements.
 * Since topics have a flat content hierarchy, this means looking
 * through the element's previous siblings until it hits a section heading and
 * then searches forward, adding all of its sibling paragraph elements under one parent div.
 * The search for sibling paragraphs stops when it detects the next section heading.
 *
 * @param {string} element - The element that needs a shared parent div
 * @returns {Element} Returns the new parent div
 */
export const createSharedTopicSection = element => {
    try {
        if (!element) {
            return null;
        }
        const sectionHeading = getPreviousSiblingTarget(element, '.headingAnchor');
        const newParent = replaceParent(sectionHeading);
        if (!newParent) {
            return null;
        }
        newParent.classList.add('topicSectionGroup');

        reassignNextSiblings(newParent, newParent, '.h1');
        return newParent;
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error while creating shared topic section: ', e);
    }
};

/**
 * Takes an {element} and moves it directly below a top level heading (h1)
 * If that heading has any children text nodes they will be extracted out
 * and then inserted after the {element}
 * If the previous element is a subheading instead and ignoreSubheading is true,
 * the {element} will be moved above the subheading (any heading type below an h1).
 *
 * @param {Element} element - the element that will be moved
 * @param {Boolean} ignoreSubheading - whether we should move the {element}
 *  above a subheading
 * @returns The moved element
 */
export const moveElementBelowHeading = (element, ignoreSubheading = false) => {
    try {
        const heading = getPreviousSiblingTarget(element, '.headingAnchor');
        if (!heading) {
            return null;
        }
        heading.after(element);
        if (ignoreSubheading) {
            heading.before(element);
        }
        return element;
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error moving element below heading: ', e);
    }
};

export const getOutlineHeadingHash = nearestOutlineHeadingId => {
    if (!nearestOutlineHeadingId) {
        return '';
    }
    const hash = nearestOutlineHeadingId.replace(C_TOPIC.ANCHOR_HASH_PREFIX, '');
    if (!hash) {
        return '';
    }
    return hash;
};