<template>
  <div class="ovid-dialog-container">
    <div v-if="ovidLinks" id="ovidDialog" class="ovidDialogLinks">
      <span class="ovidDialogInstructions"
      >Please select the full text article you wish to view:</span>
      <ul>
        <li v-for="(ovidLink, index) in ovidLinks" :key="index">
          <a class="ds1-utd-link"
             :href="ovidLink.link"
             target="_fulltext"
             v-text="ovidLink.name" />
        </li>
      </ul>
    </div>
    <div v-if="!ovidLinks">Full text link information not available.</div>
  </div>
</template>

<script>
export default {
    props: {
        ovidLinks: Array
    }
};
</script>

<style lang="less" scoped>
.ovidDialogLinks {
  ul li {
    padding: 4px;
  }
}

.ovidDialogInstructions {
  color: #555;
}
</style>
