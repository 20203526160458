<template>
  <utd-modal-dialog
    ref="topicFeedbackDialog"
    modal-classes="topic-feedback-modal">
    <template #header>{{ feedbackTitle }}</template>
    <iframe id="topic-feedback-iframe"
            frameborder="0"
            scrolling="no"
            allowfullscreen="true"
            :width="POPUP_WIDTH"
            :height="POPUP_HEIGHT"
            :src="feedbackUrl" />
  </utd-modal-dialog>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import { getDocument } from '_acaSrc/utility/DOM';

export default {
    components: {
        UtdModalDialog
    },
    props: {
        feedbackUrl: {
            type: String
        },
        feedbackTitle: {
            type: String
        }
    },
    data() {
        return {
            POPUP_HEIGHT: 650,
            POPUP_WIDTH: 650
        };
    },
    methods: {
        openFeedbackForm() {
            this.$refs.topicFeedbackDialog.open();

            setTimeout(() => {
                this.closeModalFromIframe();
            });
        },
        // This function can be removed once this modal is converted to the VUE JS.
        // Currently this function holds the functionality for closing the modal when user click cancel or close button inside the iframe
        closeModalFromIframe() {
            getDocument().getElementById('topic-feedback-iframe').addEventListener('load', function() {
                const buttons = getDocument().getElementById('topic-feedback-iframe').contentDocument.body.querySelectorAll('input[type="button"]');
                // Loop through each button
                buttons.forEach(function(button) {
                    if (button.value.trim() === 'Close' || button.value.trim() === 'Cancel') {
                        button.addEventListener('click', function() {
                            getDocument().querySelector('.topic-feedback-modal .wkce-icon-filled-close').click();
                        });
                    }
                });
            });
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.topic-feedback-modal .utd-dialog-content {
  .ds1-pl-1();
}
</style>