<script>
import { mapActions, mapGetters } from 'vuex';
import { getDocument } from '_acaSrc/utility/DOM';
import { C_LOCALIZATION } from '_acaSrc/utility/constants';
import { isBqpKpp } from '_acaSrc/utility/Validators';

const COLLAPSED = 'pp_collapsed';
const EXPANDED = 'pp_expanded';
const CHEVRON_DOWN = 'wkce-icon-chevron-down';
const CHEVRON_UP = 'wkce-icon-chevron-up';
const VIEW_LESS = 'View Less';
const VIEW_MORE = 'View More';

export default {
    data() {
        return {
            practicePoints: [],
            isPanelTranslated: false,
            contentId: '',
            selectedLanguage: '',
            componentType: ''
        };
    },
    computed: {
        ...mapGetters('topic', [
            'practicePointsLabels',
            'contentLanguage',
            'selectedTranslationToggleOption'
        ]),
        englishPracticePointsLabels() {
            const englishLabels = {
                viewLessLabel: VIEW_LESS,
                viewMoreLabel: VIEW_MORE
            };
            return this.practicePointsLabels
                && this.practicePointsLabels[C_LOCALIZATION.ENGLISH_CODE]
                || englishLabels;
        },
        localPracticePointsLabels() {
            if (!this.contentLanguage) {
                return this.englishPracticePointsLabels;
            }
            return this.practicePointsLabels
                && this.practicePointsLabels[this.contentLanguage.toLowerCase()] || {};
        },
        viewButtonLabels() {
            if (isBqpKpp(this.componentType)) {
                if (this.isPanelTranslated) {
                    return this.localPracticePointsLabels;
                }
                return this.englishPracticePointsLabels;
            }
            if (this.selectedTranslationToggleOption === C_LOCALIZATION.LOCAL_LANGUAGE_ID) {
                return this.localPracticePointsLabels;
            }
            return this.englishPracticePointsLabels;
        }
    },
    beforeUnmount() {
        this.practicePoints.forEach(practicePoint => {
            const viewButton = practicePoint.querySelector('button');
            viewButton && viewButton.removeEventListener('click', this.handleViewMoreClick);
            const externalLinks = practicePoint.querySelectorAll('.external');
            externalLinks.forEach(link => {
                link && link.removeEventListener('click', this.handlePPExternalLinkClick);
            });
        });
    },
    methods: {
        ...mapActions('app', [ 'logEvent' ]),
        setupPracticePoints({
            contentId,
            languageCode,
            source
        }) {
            this.componentType = source;
            this.contentId = contentId;
            this.selectedLanguage = languageCode || C_LOCALIZATION.ENGLISH_CODE.toLowerCase();
            this.practicePoints = getDocument().querySelectorAll('.practice-point');
            this.practicePoints.forEach(practicePoint => {
                if (practicePoint.querySelector('.wkce-icon')) {
                    return null;
                }
                this.attachPracticePointListener(practicePoint);
                this.changeButtonStyles(practicePoint);
                const externalLinks = practicePoint.querySelectorAll('.external');
                externalLinks.forEach(link => {
                    this.attachPPLinkClickListener(link, practicePoint);
                });
            });
            return this.practicePoints;
        },
        changeButtonStyles(practicePoint) {
            if (!practicePoint) {
                return null;
            }
            const btn = practicePoint.querySelector('[id*=ppViewMoreBtn_]');
            if (!btn) {
                return null;
            }
            btn.classList.add(
                'utd-button-style-text',
                'utd-button-color-blue',
                'utd-button-size-small');

            this.addIcon(btn);
            return btn;
        },
        addIcon(buttonElement) {
            const icon = getDocument().createElement('span');
            icon.classList.add('wkce-icon', 'wkce-icon-chevron-down');

            buttonElement.appendChild(icon);
            return icon;
        },
        attachPracticePointListener(practicePoint) {
            if (!practicePoint) {
                return null;
            }
            const viewButton = practicePoint.querySelector('button');
            viewButton.addEventListener('click', this.handleViewMoreClick);
        },
        handleViewMoreClick(event) {
            const clickElement = event && event.target;
            if (!clickElement) {
                return null;
            }
            const expandedContent = clickElement.parentElement
                && clickElement.parentElement.previousElementSibling;
            this.toggleExpandedContent(expandedContent, clickElement);
        },
        toggleExpandedContent(expandedContent, element) {
            let action = 'viewMore';
            if (!expandedContent || !element) {
                return null;
            }
            const btnIcon = element.querySelector('.wkce-icon');
            const btnText = element.querySelector('.practice-point__button-label');
            if (!btnText || !btnIcon) {
                return null;
            }
            if (expandedContent.classList.contains(COLLAPSED)) {
                btnText.textContent = this.viewButtonLabels.viewLessLabel;
                btnIcon.classList.replace(CHEVRON_DOWN, CHEVRON_UP);
                expandedContent.classList.replace(COLLAPSED, EXPANDED);
            }
            else {
                btnText.textContent = this.viewButtonLabels.viewMoreLabel;
                btnIcon.classList.replace(CHEVRON_UP, CHEVRON_DOWN);
                expandedContent.classList.replace(EXPANDED, COLLAPSED);
                action = 'viewLess';
            }
            this.logEvent({
                eventUrl: 'event/machineTranslationClick/json',
                eventParams: {
                    referringUrl: window.location.href,
                    uiElementName: `practicePoints_${action}_${this.componentType}`,
                    optData: element.id,
                    contentId: this.contentId,
                    languageCode: this.selectedLanguage
                }
            });
            return expandedContent;
        },
        attachPPLinkClickListener(externalLink, practicePoint) {
            if (!externalLink) {
                return null;
            }
            externalLink.practicePointId = practicePoint && practicePoint.id;
            externalLink.addEventListener('click', this.handlePPExternalLinkClick);
        },
        handlePPExternalLinkClick(e) {
            this.logEvent({
                eventUrl: 'event/machineTranslationClick/json',
                eventParams: {
                    referringUrl: window.location.href,
                    uiElementName: `practicePointsLink_${this.componentType}`,
                    optData: e && e.target && e.target.practicePointId,
                    contentId: this.contentId,
                    languageCode: this.selectedLanguage
                }
            });
        }
    }
};
</script>