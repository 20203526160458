<template lang="">
  <div class="graphic__counter">
    <button class="graphic__counter-button counterHoverTooltip wkce-icon-arrow-left ds1-mr-1"
            tabindex="0"
            aria-label="Previous graphic"
            :disabled="isDisabled()"
            @click="handleButtonClick('prev')"
            @keypress="$event.which === ENTER_KEY && handleButtonClick('prev')" />
    <span class="graphic__counter-label">
      {{ $t('HEADER.COUNTER_OF',{current,total}) }}
    </span>
    <button class="graphic__counter-button counterHoverTooltip wkce-icon-arrow-right ds1-ml-1"
            tabindex="0"
            aria-label="Next graphic"
            :disabled="isDisabled()"
            @click="handleButtonClick('next')"
            @keypress="$event.which === ENTER_KEY && handleButtonClick('next')" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { C_KEYCODES } from '_acaSrc/utility/constants';

export default {
    emits: [ 'onNextClick', 'onPrevClick' ],
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        ...mapGetters('graphic', [
            'graphicViewerCollection',
            'graphicViewerGraphics',
            'graphicViewerImageKey'
        ]),
        thumbnails() {
            return this.graphicViewerCollection > 0
                ? this.graphicViewerCollection
                : this.graphicViewerGraphics;
        },
        total() {
            return this.thumbnails.length || 1;
        },
        current() {
            const index = this.thumbnails.findIndex(
                x => x.imageKey === this.graphicViewerImageKey);
            return index !== -1 ? index + 1 : 1;
        }
    },
    methods: {
        handleButtonClick(flow) {
            if (this.total > 1) {
                if (flow === 'next') {
                    this.$emit('onNextClick');
                }
                else {
                    this.$emit('onPrevClick');
                }
            }
        },
        isDisabled() {
            return !(this.total > 1);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphic__counter {
  display: inline-block;
  border-right: 1px solid @WKCE-GRAY-TINT3;
  .ds1-pr-3();
  .ds1-mr-3();

  &-label {
    .ds1-utd-size-2();
    .ds1-utd-weight-500();
    color: @WKCE-GRAY-SHADE2;
  }

  &-button {
    flex-shrink: 0;
    border: none;
    width: 24px;
    height: 24px;
    position: relative;
    padding: 0;
    color: white;
    background-color: @WKCE-BLUE;
    border-radius: 50%;

    &::before {
      font-size: 11px;
      position: relative;
    }

    &:hover {
      background-color: @WKCE-BLUE-SHADE2;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
</style>
