export function addVideoPlayer(element) {
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    const movieSrc = element.getAttribute('h264');
    const videoTemplate = `<video width="${width}"
                                  height="${height}"
                                  onclick="this.play()"
                                  autoplay
                                  controls>
                             <source src="${movieSrc}" type="video/mp4">
                             <div class="video-warning">
                               <p>Your browser does not support the video element.</p>
                             <div>
                           </video>`;
    element.appendChild(htmlToEle(videoTemplate));
}

function htmlToEle(html) {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.firstChild;
}
