<template>
  <utd-modal-dialog ref="downloadCenterRef"
                    :modal-classes="'download-center-modal'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop"
                    @closed="handleClose()">
    <template #header>Download Center</template>
    <div class="dialog">
      <h5 class="dialog__header">
        UpToDate for Desktop
      </h5>
      <div class="dialog__content">
        <utd-notification v-if="isDownloadError"
                          id="download-error-notification"
                          :notification-mode="'error'">
          <p class="notification-text">
            There was a problem downloading the UpToDate desktop app.
          </p>
          <p class="notification-text">Please try again later or contact us using
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/contact-us"
               class="dialog__bolded">
              UpToDate Support.
            </a>
          </p>
        </utd-notification>
        <div class="dialog__download">
          <span class="dialog__windows-icon" />
          <div class="dialog__download-container">
            <p class="dialog__download-title">UpToDate for Windows</p>
            <a id="windowsDownload"
               href="#"
               class="dialog__download-link"
               aria-label="Download UpToDate for Windows"
               @click="downloadDesktop('win')">Download now</a>
          </div>
        </div>
        <div class="dialog__download">
          <span class="dialog__mac-icon" />
          <div class="dialog__download-container">
            <p class="dialog__download-title">UpToDate for Macintosh</p>
            <a id="macDownload"
               href="#"
               class="dialog__download-link"
               aria-label="Download UpToDate for Macintosh"
               @click="downloadDesktop('mac')">Download now</a>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <utd-button @click="closeDialog()">Close</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import PubSub from '_acaSrc/utility/PubSub';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { getWindow } from '_acaSrc/utility/DOM';
import { C_EVENTS } from '_acaSrc/utility/constants';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdModalDialog,
        UtdButton,
        UtdNotification
    },
    data() {
        return {
            isDownloadError: false,
            isDownloading: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'isMobileOnDesktop' ])
    },
    methods: {
        async downloadDesktop(platform) {
            if (this.isDownloading) {
                return;
            }
            try {
                this.isDownloadError = false;
                this.isDownloading = true;
                const data = await utdRest('myAccount/downloadDesktop', { platform });
                const url = (data && data.data) || data;
                getWindow().location.assign(url);
            }
            catch (e) {
                this.isDownloadError = true;
                let error = `desktopDownload:${e._status}`;
                const request = e._request;
                if (request !== undefined) {
                    error += `-${request.method}-${request.url}`;
                }
                const eventParams = {
                    uiElementName: 'myAccountDesktopDownload_downloadButton_failure',
                    optData: error
                };
                new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, eventParams);
            }
            this.isDownloading = false;
        },
        open() {
            this.$refs.downloadCenterRef.open();
        },
        closeDialog() {
            this.$refs.downloadCenterRef.close();
            new PubSub().publish('wkutd.closeMyAccountDialog');
        },
        handleClose() {
            new PubSub().publish('wkutd.closeMyAccountDialog');
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.download-center-modal {
  .utd-dialog-frame {
    min-width: auto;
  }

  .utd-dialog-content {
    .ds1-pa-2();
    width: 360px;
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-UTD-DIALOG-ICON-COLOR: @WKCE-BLUE-SHADE1;
@DS1-UTD-DIALOG-ICON-COLOR-HOVER: @WKCE-BLUE-SHADE2;
@DS1-UTD-INFO-DIALOG-ICON: '\e80d';

.dialog__header {
  .ds1-utd-weight-600();
  .ds1-mb-2();
  display: flex;
  justify-content: space-between;
}

.dialog__bolded {
  .ds1-utd-weight-600();
}

.dialog__utd-logo {
  height: 32px;
  align-self: center;
}

.dialog__wk-logo {
  height: 60px;
}

.dialog__download-link {
  .ds1-utd-weight-600();

  &::after {
    .ds1-utd-weight-normal();
    .ds1-utd-size-3();
    content: '\eac6';
    font-family: 'wkce-icons';
    position: relative;
    left: 8px;
    top: 2px;
  }

  &:hover {
    text-decoration: none;
  }
}

.dialog__windows-icon {
  .ds1-mr-2();
  content: url(~_acaAssets/account/windows_icon_flat.svg);
}

.dialog__mac-icon {
  .ds1-mr-2();
  content: url(~_acaAssets/account/mac_icon_flat.svg);
}

.dialog__content {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.dialog__download {
  .ds1-mv-1();
  display: flex;
  flex-basis: 100%;
}

.dialog__download-title {
  .ds1-reset-p();
}
</style>