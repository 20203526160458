<template>
  <div id="authorsEditors" class="authors-editors">
    <h2 v-text="tocSpecialty" />
    <div v-for="(contributorType, contributorTypeIdx) in tocContributorTypes"
         :key="contributorTypeIdx">
      <h3 v-text="contributorType.name" />
      <dl v-for="(contributor, contributorIdx) in contributorType.contributors"
          :key="contributorIdx"
          class="utd-contributor">
        <dt v-text="contributor.name" />
        <dd v-for="(credential, credentialIdx) in contributor.credentials" :key="credentialIdx">
          <div v-text="credential" />
        </dd>
        <dd v-if="!contributor.credentials" />
      </dl>
    </div>
    <div v-if="tocContributorBundles">
      <div v-for="(contributorBundle, contributorBundleIdx) in tocContributorBundles"
           :key="`header ${contributorBundleIdx}`">
        <a :href="`#${createAnchor(contributorBundle.specialtyName)}`"
           v-text="createAnchorLabel(contributorBundle.specialtyName)" />
      </div>
      <div v-for="(contributorBundle, contributorBundleIdx) in tocContributorBundles"
           :key="`content ${contributorBundleIdx}`">
        <a class="specialty-name-anchor" :name="createAnchor(contributorBundle.specialtyName)" />
        <h2 v-text="contributorBundle.specialtyName" />
        <div v-for="(contributorType, contributorTypeIdx) in contributorBundle.contributorTypes"
             :key="contributorTypeIdx">
          <h3 v-text="contributorType.name" />
          <dl v-for="(contributor, contributorIdx) in contributorType.contributors"
              :key="contributorIdx"
              class="utd-contributor">
            <dt v-text="contributor.name" />
            <dd v-for="(credential, credentialIdx) in contributor.credentials" :key="credentialIdx">
              <div v-text="credential" />
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('toc', [
            'tocSpecialty',
            'tocContributorTypes',
            'tocContributorBundles' ])
    },
    methods: {
        createAnchor(anchorText) {
            return encodeURIComponent(anchorText);
        },
        createAnchorLabel(labelText) {
            return `Skip to ${labelText}`;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utdWkHeader {
  .authors-editors {
    a[name] {
      position: relative;
      top: -60px;
      display: block;

      @media only screen and (min-width: 768px) {
        .isDesktop& {
          top: -110px;
          left: -100px; // Fix for I.E scrolling to the right when visiting from an hash link
        }
      }
    }
  }
}

.authors-editors .utd-contributor {
  .ds1-ma-0();
}
</style>