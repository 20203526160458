<script>
import { mapGetters, mapActions } from 'vuex';
import { getWindow } from '_acaSrc/utility/DOM';
import { Listener } from '_acaSrc/utility/Events';

export default {
    computed: {
        ...mapGetters('app', [ 'isDesktopBrowser' ])
    },
    mounted() {
        this.delayExecuteUntilDataLoaded(() => {
            if (this.isDesktopBrowser) {
                this.resizeListener
                    = new Listener(getWindow(),
                        'resize', this.checkResponsiveAppType, { passive: true });
                this.setListener(this.resizeListener);

                this.checkResponsiveAppType();
            }
        });
    },
    beforeUnmount() {
        if (this.isDesktopBrowser) {
            this.clearListener(this.resizeListener);
        }
    },
    methods: {
        ...mapActions('app', [ 'setListener', 'clearListener', 'delayExecuteUntilDataLoaded' ]),
        ...mapActions('device', [ 'checkResponsiveAppType' ])
    },
    render: () => null
};
</script>
