import { C_QAP } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper, setQueryParamValue } from '_acaSrc/utility/http';
import QapDto from './qap.dto';
import { cloneDeep } from 'lodash';

export default class RoutedDrugDto extends QapDto {
    constructor(payload) {
        super(payload);

        const { result, rankIndex } = payload;

        result.panelType = C_QAP.PANEL.DRUG_ROUTED;
        result.subtype = result.panelType;

        this.panelType = result.panelType;
        this.subtype = result.subtype;

        const dipRankIndex = this._setRoutedDrugContents(result, rankIndex);
        this._setDrugInteractionPanel(result, dipRankIndex);

        result.qapContents = cloneDeep(this.qapContents);
    }

    _setRoutedDrugContents(result, rankIndex) {
        let activeDisplayRank;
        this.qapContents = [{
            sections: result.searchResults
                .filter(result => result.type === 'drug' && result.pageType === 'DRUG_LANDING_PAGE')
                .map((result, resultIdx) => {
                    activeDisplayRank = rankIndex + resultIdx;
                    return {
                        type: 'links',
                        title: result.title,
                        items: result.searchResults.map(item => {
                            // For routed drugs include parameter to indicate we should display
                            // the drug name next to section header label in topic view
                            item.url = setQueryParamValue(item.url, 'showDrugLabel', 'true');
                            return {
                                url: setSearchUrlParamsHelper(item.url, {
                                    rankIndex: activeDisplayRank,
                                    searchTerm: this.searchTerm
                                }),
                                label: item.label
                            };
                        })
                    };
                })
        }];
        return activeDisplayRank;
    }

    _setDrugInteractionPanel(result, rankIndex) {
        const drugInteractionsDto = this._toQapObject({
            searchTerm: this.searchTerm,
            results: result.searchResults,
            rankIndex,
            type: C_QAP.SECTION.DRUG_INTERACTIONS
        });

        result.dipCount = 0;
        if (drugInteractionsDto) {
            // Indicate there is at least 1 'Drug Interactions' panel
            // present for accurate assignment of 'display_rank' on
            // main search results URLs
            result.dipCount = 1;

            result.dip = drugInteractionsDto[0];
            this.dip = cloneDeep(drugInteractionsDto[0]);

            this.qapContents[0].sections.push({
                type: C_QAP.SECTION.DRUG_INTERACTIONS,
                items: [ result.dip ]
            });
        }
    }
}
