<template>
  <div class="skipnav">
    <a v-if="showSkipToResult"
       id="skipToResult"
       href="#leftSidePanel">Skip to Results</a>

    <a v-if="showSkipToQuickPanel"
       id="skipToQuickPanel"
       href="#rightSidePanel">Skip to Quick Access Panel</a>

    <a v-if="showSkipToGraphicalAnswers"
       id="skipToGraphicalAnswers"
       href="#rightSidePanel">Skip to Graphical Answers</a>

    <a v-if="showskipToOutline"
       id="skipToOutline"
       href="#topicOutline">Skip to Outline</a>

    <a v-if="showskipToTopic"
       id="skipToTopic"
       href="#topicArticle">Skip to Topic</a>

    <a v-if="showskipToReferences"
       id="skipToReferences"
       href="#references">Skip to References</a>

    <a v-if="showskipToContent"
       id="skipToContent"
       href="#utd-main">Skip to Content</a>

    <a v-if="isFooterLoaded"
       id="skipToHelp"
       :href="getFooterUrl('help') || ''">Skip to Help</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('footer', [
            'getFooterUrl',
            'isFooterLoaded'
        ]),
        ...mapGetters('topic', [ 'isTopicView', 'showReferences', 'fullTopicWidth' ]),
        ...mapGetters('search', [ 'searchBarBody', 'isGraphicalAnswers', 'isSearchView' ]),
        ...mapGetters('qap', [ 'isQuickAccessPanel' ]),
        showSkipToResult() {
            return this.isSearchView;
        },
        showSkipToQuickPanel() {
            return this.isSearchView
              && this.isQuickAccessPanel
              && !this.isGraphicalAnswers;
        },
        showSkipToGraphicalAnswers() {
            return this.isSearchView
              && this.isQuickAccessPanel
              && this.isGraphicalAnswers;
        },
        showskipToOutline() {
            return this.isTopicView && !this.fullTopicWidth;
        },
        showskipToTopic() {
            return this.isTopicView;
        },
        showskipToReferences() {
            return this.isTopicView && this.showReferences;
        },
        showskipToContent() {
            return !this.isTopicView
                && !this.isSearchView
                && !this.searchBarBody;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

@SKIPNAV-BG-COLOR: @WKCE-GRAY;

.skipnav {
  text-align: left;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;

  > a {
    padding: 5px;
    background: @SKIPNAV-BG-COLOR;
    color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    font-size: 12px;
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:focus,
    &:active {
      position: static;
      left: 0;
      width: auto;
      height: auto;
      overflow: visible;
      text-decoration: underline;
    }
  }
}
</style>