<template>
  <div class="trial-subscribe__container" :class="{'footer-message': !isDesktopView}">
    <div v-if="trialStateIsEnabled" class="trial-subscribe">
      <a id="trial-subscribe-link"
         class="subscribe-link"
         track="enabled"
         :href="renewalSubscriptionUrl"
         @click.prevent="onClickStoreLink">Subscribe Now
        <div class="trial-duration-inline" v-text="getTrialDurationMessage" />
      </a>
      <div class="trial-duration-floating" v-text="getTrialDurationMessage" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('auth', [ 'trialStateDaysLeft', 'trialStateIsEnabled' ]),
        ...mapGetters('login', [ 'renewalSubscriptionUrl' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        getTrialDurationMessage() {
            return `Trial ends in
                ${this.trialStateDaysLeft} ${this.trialStateDaysLeft !== 1 ? 'days' : 'day'}`;
        }
    },
    methods: {
        onClickStoreLink(e) {
            // Use this to navigate to external urls while safely
            // tracking clicks.
            //
            // Explanation: Ajax requests for a closed page are cancelled
            // after a varying delay. This is usually not noticable on fast connections,
            // but is a serious issue on mobile. This can cause ui click tracking to fail.
            // To avoid this, this method tries to let the tracking finish first before
            // navigating, within a timeboxed allowance of x ms to avoid user annoyance.
            const attrId = e.currentTarget.id;
            const attrHref = this.renewalSubscriptionUrl;
            this.trackAndNavigate(attrHref, attrId);
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@FOOTER-MESSAGE-MARGIN: 16px;
@FOOTER-MESSAGE-BORDER-COLOR: @WKCE-GRAY-TINT5;
@TRIAL-SUBSCRIBE-BG-COLOR: @WKCE-ORANGE;

.footer-message-default-border() {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px @FOOTER-MESSAGE-BORDER-COLOR;
}

.footer-message {
  z-index: @ZINDEX-TRIAL-SUBSCRIBE;
  position: fixed;
  bottom: @FOOTER-MESSAGE-MARGIN;
  left: @FOOTER-MESSAGE-MARGIN;
  right: @FOOTER-MESSAGE-MARGIN;
}

.trial-subscribe {
  .footer-message-default-border();
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  box-sizing: border-box;
  height: 72px;
  padding: 16px 19px;
  position: relative;
  overflow: hidden;

  .trial-duration-floating {
    margin-right: 180px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
  }

  .subscribe-link {
    &:extend(.jumpstart-button-v2);
    color: @DS1-UTD-PRIMARY-BG-COLOR;
    font-size: 14px;
    float: right;
    background-color: @TRIAL-SUBSCRIBE-BG-COLOR;
    -webkit-appearance: none;
    height: auto;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 16px;

    &:hover {
      background-color: lighten(@TRIAL-SUBSCRIBE-BG-COLOR, 10%);
    }

    &:active {
      background-color: lighten(@TRIAL-SUBSCRIBE-BG-COLOR, 5%);
    }
  }

  .trial-duration-inline {
    display: none;
  }
}

.utdWkHeader {
  .wk-header-container .trial-subscribe__container {
    display: none;
  }

  .wk-navbar .trial-subscribe__container {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  #appContainer > .trial-subscribe__container {
    display: none;
  }

  .isDesktop {
    .trial-subscribe {
      padding: 0;
      border: none;
      box-shadow: none;
      height: auto;

      .subscribe-link {
        padding: 4px 16px;
        border: none;
        height: 40px;
        box-sizing: border-box;

        /* overrides :focus accessibility feature, but currently necessary for design */
        outline: none;
      }

      .trial-duration-floating {
        display: none;
      }

      .trial-duration-inline {
        display: block;
        font-size: 12px;
      }
    }

    &.utdWkHeader .utdNavRoot .trial-subscribe__container {
      display: inline;

      // hack to defeat some overly generic and specific styles
      .trial-subscribe .subscribe-link {
        padding: 4px 16px;
      }
    }

    &.fixedToolbar .wk-header-container .trial-subscribe__container {
      display: inline-block;
      margin-top: -2px;

      .trial-subscribe .subscribe-link {
        padding: 2px 16px 6px;
      }
    }
  }
}

</style>