/**
 * Class to parse received markup for <img> tags, then attempts to load them off screen.
 *
 * Mainly used when notification is required upon all images having been loaded, or any
 * images fail to load.
 *
 * Usage:
 *
 * 1. Instantiate class, passing "ImageLoaded" and "ImageError" callbacks.
 * 2. Call "loadImagesFromMarkup(src)", passing in source markup to load images from.
 *
 *    Source will be returned, either modified with 1x1 transparent images placeholders
 *    if any <img> tags were found.
 *    Or if no <img> tags were found, the original unmodified source will be returned.
 */
export const BLANK_1X1_GRAPHIC = '/graphic/1x1fff.png?idx=';

export class MarkupImageLoader {
    /**
     * @param {function} cbImagesLoaded
     *    Called after last graphic for each <img> src has been loaded
     *
     * @param {function} cbImageError
     *    Called if error occurs while loading an <img>
    */
    constructor(cbImagesLoaded, cbImageError) {
        this.cbImagesLoaded = cbImagesLoaded;
        this.cbImageError = cbImageError;
    }

    _cleanupImageLoaders() {
        document.querySelectorAll('[class^=offscreen_image]').forEach(el => {
            el.parentNode.removeChild(el);
        });
    }

    _checkImagesLoaded() {
        if (this.imagesToLoad > 0) {
            this.imagesToLoad--;
        }

        if (this.imagesToLoad === 0) {
            this.cbImagesLoaded && this.cbImagesLoaded();
            this._cleanupImageLoaders();
        }
    }

    _onImageLoaded(src, idx) {
        // Get reference to original <img> tag, and restore original "src"
        const origImgEl = document.querySelector(
            `.graphic-viewer-content img[src='${BLANK_1X1_GRAPHIC}${idx}']`);
        origImgEl && origImgEl.setAttribute('src', src);

        this._checkImagesLoaded();
    }

    _onImageError() {
        this.cbImageError && this.cbImageError();
        this._checkImagesLoaded();
    }

    _loadImagesOffScreen() {
        this.imageSources.forEach((src, idx) => {
            const gfx = document.createElement('img');
            gfx.setAttribute('class', `offscreen_image${idx}`);
            gfx.addEventListener('load', () => this._onImageLoaded(src, idx));

            if (this.cbImageError) {
                gfx.addEventListener('error', this._onImageError);
            }

            // Attach loader element to body and set src to begin loading attempt
            document.body.appendChild(gfx);
            gfx.src = src;
        });
    }

    /** Public: loadImagesFromMarkup
     *
     * @param {String} srcHtml
     *    HTML to parse for <img> tags to be preloaded
     *
     * @returns Object
     *    Returns object containing:
     *      {String} srcHtml
     *          If <img> tags were found, then this will be the passed srcHtml with
     *          any "src" attribute values replaced with a 1x1 transparent pixel.
     *          if no <img> tags found, then this will just be the same srcHtml
     *          passed in originally.
     *      {Integer} hasImages
     *          The number of images found in srcHtml
     */
    loadImagesFromMarkup(srcHtml) {
        this.imageSources = [];
        this.imagesToLoad = 0;

        const divEl = document.createElement('div');
        divEl.innerHTML = srcHtml;
        divEl.querySelectorAll('img').forEach((img, index) => {
            // Store original "src" value, temporarily replace with placeholder
            const originalSrc = img.getAttribute('src');
            if (originalSrc) {
                this.imageSources.push(originalSrc);
                srcHtml = srcHtml.replace(originalSrc, `${BLANK_1X1_GRAPHIC}${index}`);
            }
        });

        this.imagesToLoad = this.imageSources.length;
        this._loadImagesOffScreen();

        const hasImages = this.imageSources.length > 0;

        return { srcHtml, hasImages };
    }
}