export const CollectionType = Object.freeze({
    FIFO: 'FIFO', // First-In, First-Out
    QUEUE: 'QUEUE', // Same as FIFO
    LIFO: 'LIFO', // Last-In, First-Out
    STACK: 'STACK' // Same as LIFO
});

export class Collection {
    constructor(type = CollectionType.FIFO) {
        this._elements = [];
        this._type = type;
        this._first = null;
        this._last = null;
        this._next = null;

        switch (this._type) {
        case CollectionType.FIFO:
        case CollectionType.QUEUE:
            this._enqueue = this._fifoEnqueue;
            this._dequeue = this._fifoDequeue;
            break;

        case CollectionType.LIFO:
        case CollectionType.STACK:
            this._enqueue = this._lifoEnqueue;
            this._dequeue = this._lifoDequeue;
            break;
        }
    }

    enqueue(element) {
        this._enqueue(element);
    }

    dequeue() {
        return this._dequeue();
    }

    _fifoEnqueue(element) {
        this._elements.push(element);
        this._first = this._elements[0];
        this._last = this._elements[this._elements.length - 1];
        this._next = this._first;
    }

    _fifoDequeue() {
        let removed = null;

        if (this._elements.length > 0) {
            removed = this._elements.shift();

            this._first = null;
            this._last = null;
            this._next = null;

            if (this._elements.length > 0) {
                this._first = this._elements[0];
                this._last = this._elements[this._elements.length - 1];
                this._next = this._first;
            }
        }

        return removed;
    }

    _lifoEnqueue(element) {
        this._elements.push(element);
        this._first = this._elements[0];
        this._last = this._elements[this._elements.length - 1];
        this._next = this._last;
    }

    _lifoDequeue() {
        let removed = null;

        if (this._elements.length > 0) {
            removed = this._elements.pop();

            this._first = null;
            this._last = null;
            this._next = null;

            if (this._elements.length > 0) {
                this._first = this._elements[0];
                this._last = this._elements[this._elements.length - 1];
                this._next = this._last;
            }
        }

        return removed;
    }

    get first() {
        return this._first;
    }

    get last() {
        return this._last;
    }

    get next() {
        return this._next;
    }

    get length() {
        return this._elements.length;
    }

    get hasElements() {
        return this._elements.length > 0;
    }

    get isEmpty() {
        return this._elements.length === 0;
    }

    firstPropValue(propName) {
        return this._first && this._first[propName];
    }

    lastPropValue(propName) {
        return this._last && this._last[propName];
    }

    nextPropValue(propName) {
        return this._next && this._next[propName];
    }

    purge() {
        this._elements = [];
        this._first = null;
        this._last = null;
        this._next = null;
    }
}