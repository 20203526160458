<template>
  <div class="mt-widget">
    <div class="mt-widget__container">
      <div class="mt-widget__language-toggle">
        <utd-radio-set :items="languageOptions"
                       :value="selectedTranslationToggleOption"
                       @input="onLanguageChange" />
      </div>
      <div v-if="showFeedbackInstructionLink"
           class="mt-widget__feedback">
        <div class="mt-widget__feedback-container">
          <utd-button class="mt-widget__feedback-button"
                      aria-label="Provide translation feedback"
                      button-icon="book-open"
                      button-style="link"
                      button-size="extra-small"
                      @click="openTranslationFeedbackInstruction">
            <span>{{ getTranslationLabels().translationFeedback }}</span>
          </utd-button>
        </div>
      </div>
      <div v-else class="mt-widget__warning">
        <span class="mt-widget__warning-label">
          <span class="mt-widget__local-warning">
            {{ getTranslationLabels(C_LOCALIZATION.LOCAL_LANGUAGE_ID).warningMachineTranslation }}
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span class="mt-widget__english-warning">
            {{ getTranslationLabels(C_LOCALIZATION.ENGLISH_ID).warningMachineTranslation }}
          </span>
        </span>
      </div>
      <div class="mt-widget__learn-more">
        <div class="mt-widget__learn-more-container">
          <utd-button class="mt-widget__learn-more-button"
                      aria-label="Learn more about localization"
                      button-icon="caution"
                      button-style="link"
                      button-size="extra-small"
                      @click="openLearnMore">
            <span>{{ getTranslationLabels().learnMore }}</span>
          </utd-button>
        </div>
      </div>
    </div>
    <translation-feedback-tooltip ref="feedbackTooltip" />
  </div>
</template>

<script>
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdRadioSet from '_acaSrc/components/shared/stdlib/UtdRadioSet.vue';
import {
    C_LOCALIZATION,
    C_LANGUAGES_NAMES,
    C_EVENTS,
    C_TOPIC
} from '_acaSrc/utility/constants';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import {
    SET_SELECTED_TRANSLATION_TOGGLE_OPTION,
    SET_URL_SCROLL_POSITION,
    SET_SAVE_SCROLL_POSITION
} from '_acaSrc/store/topic/topic.store';
import PracticePoint from '_acaSrc/components/localization/PracticePoint.vue';
import TranslationFeedbackTooltip from './TranslationFeedbackTooltip.vue';
import { getWindow, getNearestSectionName } from '_acaSrc/utility/DOM';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdButton,
        UtdRadioSet,
        TranslationFeedbackTooltip
    },
    mixins: [ PracticePoint ],
    props: {
        translationLanguage: String
    },
    data() {
        return {
            C_LANGUAGES_NAMES,
            C_LOCALIZATION,
            C_TOPIC
        };
    },
    computed: {
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('topic', [
            'machineTranslationLabels',
            'contentLanguage',
            'shouldShowTranslationWarning',
            'selectedTranslationToggleOption',
            'nearestOutlineHeadingId',
            'topicId'
        ]),
        languageOptions() {
            if (!this.contentLanguage) {
                return [];
            }
            return [
                {
                    option: this.$t(C_LANGUAGES_NAMES[this.contentLanguage].NAME),
                    value: C_LOCALIZATION.LOCAL_LANGUAGE_ID,
                    name: this.contentLanguage.toLowerCase(),
                    ariaLabel: C_LANGUAGES_NAMES[this.contentLanguage].NAME
                },
                {
                    option: this.$t(`${C_LANGUAGES_NAMES.EN.NAME}`),
                    value: C_LOCALIZATION.ENGLISH_ID,
                    name: C_LOCALIZATION.ENGLISH_CODE
                }
            ];
        },
        onLocalizedContent() {
            return this.selectedTranslationToggleOption !== C_LOCALIZATION.ENGLISH_ID;
        },
        showFeedbackInstructionLink() {
            return this.selectedTranslationToggleOption === C_LOCALIZATION.LOCAL_LANGUAGE_ID
                && this.isDesktopView;
        }
    },
    mounted() {
        new PubSub().subscribe(
            C_EVENTS.CLOSE_TRANSLATION_WARNING, this.onLanguageChange
        );
        new PubSub().subscribe(
            C_EVENTS.OPEN_LEARN_MORE, this.openLearnMore
        );
        if (this.onLocalizedContent) {
            this.$refs.feedbackTooltip
              && this.$refs.feedbackTooltip.setupFeedbackTooltip
              && this.$refs.feedbackTooltip.setupFeedbackTooltip('#topicArticle');
        }
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.CLOSE_TRANSLATION_WARNING, this.onLanguageChange);
        new PubSub().unsubscribe(C_EVENTS.OPEN_LEARN_MORE, this.openLearnMore);
    },
    methods: {
        ...mapMutations('topic', [
            SET_SELECTED_TRANSLATION_TOGGLE_OPTION,
            SET_URL_SCROLL_POSITION,
            SET_SAVE_SCROLL_POSITION
        ]),
        ...mapActions('topic', [
            'setLocalizationContent',
            'triggerTopicViewRemount'
        ]),
        ...mapActions('app', [ 'logEvent' ]),
        async onLanguageChange(value) {
            this[SET_SELECTED_TRANSLATION_TOGGLE_OPTION](value);
            if (this.shouldShowTranslationWarning
              && value === C_LOCALIZATION.LOCAL_LANGUAGE_ID) {
                new PubSub().publish(C_EVENTS.OPEN_TRANSLATION_WARNING, {
                    shouldShowAcceptanceButtons: true
                });
                this.logEvent({
                    eventUrl: 'event/showModal/json',
                    eventParams: {
                        referringUrl: getWindow().location.href,
                        uiElementName: 'machineTranslation_warningModal'
                    }
                });
            }
            else if (!this.shouldShowTranslationWarning
              && value === C_LOCALIZATION.ENGLISH_ID) {
                await this.setLocalizationContent({ language: C_LOCALIZATION.ENGLISH_ID });
                await this.triggerTopicViewRemount();
                this.logTopicViewLocalizationEvent();
            }
            else if (!this.shouldShowTranslationWarning
              && value === C_LOCALIZATION.LOCAL_LANGUAGE_ID) {
                this.logTopicViewLocalizationEvent();
                await this.setLocalizationContent({ language: C_LOCALIZATION.LOCAL_LANGUAGE_ID });
                this.triggerTopicViewRemount();
            }
        },
        logTopicViewLocalizationEvent() {
            const languageCode
                = this.languageOptions[this.selectedTranslationToggleOption].name;
            const nearestHeadingId = this.nearestOutlineHeadingId || 'xhash_H1';
            const sectionId = nearestHeadingId.replace('xhash_', '');
            const section = getNearestSectionName(nearestHeadingId);
            this.logEvent({
                eventUrl: 'event/topic/localization-view/json',
                eventParams: {
                    topicId: this.topicId,
                    languageCode,
                    section,
                    sectionId
                }
            });
            return true;
        },
        getTranslationLabels(languageId = this.selectedTranslationToggleOption) {
            const languageCode = this.languageOptions[languageId]
                              && this.languageOptions[languageId].name;
            if (!languageCode) {
                return {};
            }
            return this.machineTranslationLabels
                && this.machineTranslationLabels[languageCode] || {};
        },
        openLearnMore() {
            new PubSub().publish(C_EVENTS.OPEN_TRANSLATION_WARNING, {
                shouldShowAcceptanceButtons: false
            });
            this.logEvent({
                eventUrl: 'event/machineTranslationClick/json',
                eventParams: {
                    referringUrl: window.location.href,
                    uiElementName: 'machineTranslation_learnMore',
                    languageCode: this.languageOptions[this.selectedTranslationToggleOption].name,
                    contentId: this.topicId
                }
            });
        },
        openTranslationFeedbackInstruction() {
            new PubSub().publish(C_EVENTS.OPEN_TRANSLATION_FEEDBACK_INSTRUCTION);
            this.logEvent({
                eventUrl: 'event/machineTranslationClick/json',
                eventParams: {
                    referringUrl: window.location.href,
                    uiElementName: 'machineTranslation_feedbackInstruction',
                    languageCode: this.languageOptions[this.selectedTranslationToggleOption].name,
                    contentId: this.topicId
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-RADIO-TOGGLE-COLOR: #0060df;
@MT-WIDGET-HEIGHT: 45px;

@media only screen and (min-width: 768px) {
  .mt-widget {
    display: inline-block;
    bottom: -@MT-WIDGET-HEIGHT;

    .mt-widget__learn-more {
      .ds1-flex-vertical-center();
      display: flex;
    }
  }
}

@media only screen and (min-width: 1140px) {
  .topic-toolbar .mt-widget {
    .mt-widget__feedback,
    .mt-widget__warning {
      .ds1-pv-1();
      display: flex;
    }
  }
}

.mt-widget {
  background: @DS1-UTD-LIGHT-BLUE-BG-COLOR;
  box-shadow: @DS1-BOX-SHADOW-RAISED;
  border-top: @DS1-UTD-WIDGET-BORDER;
  position: absolute;
  display: inline-block;
  z-index: 1;
  right: 0;

  :deep(.wk-field-choice-label) {
    display: flex;
    align-items: center;
  }

  :deep(.wk-field-choice) {
    .ds1-mr-1();
    accent-color: @UTD-RADIO-TOGGLE-COLOR;
  }

  :deep(.wk-field-body) {
    display: flex;
    gap: 16px;
  }
}

.mt-widget__container {
  .ds1-ph-2();
  background: @DS1-UTD-WIDGET-BG-COLOR;
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
  border-left: @DS1-UTD-GRAY-SINGLE-BORDER;
  border-right: @DS1-UTD-GRAY-SINGLE-BORDER;
  display: flex;
  gap: 16px;
}

.mt-widget__language-toggle {
  .ds1-pv-1();
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.mt-widget__learn-more,
.mt-widget__warning,
.mt-widget__feedback {
  display: none;
}

.mt-widget__warning-label {
  .ds1-utd-size-1();
  .ds1-pl-2();
  .ds1-flex-vertical-center();
  border-left: @DS1-UTD-GRAY-VERTICAL-SEPARATOR;
}

.mt-widget__local-warning {
  font-size: 14px;
}

.mt-widget__english-warning {
  font-size: 12px;
}

.mt-widget__feedback-container {
  .ds1-pl-2();
  border-left: @DS1-UTD-GRAY-VERTICAL-SEPARATOR;
}

.mt-widget__feedback-button {
  .ds1-pa-0();
}

.mt-widget__learn-more-container {
  .ds1-pl-2();
  border-left: @DS1-UTD-GRAY-VERTICAL-SEPARATOR;
}

.mt-widget__learn-more-button {
  .ds1-pa-0();
  .ds1-flex-vertical-center();
}
</style>
