import handlers from './UtdRestClientHandlers';
import request, { registerRequestHook } from './SuperagentRestClient';
import { createRestPath } from '_acaSrc/utility/http';
import { C_CSRF } from '_acaSrc/utility/constants';
import { getDecodeCookie } from '_acaSrc/utility/Cookies';

let context = {};
const promiseGetAbort = [];

export function responseErrorHandler(config, utdRestResponse) {
    config && utdRestResponse.mergeConfig && utdRestResponse.mergeConfig(config);

    return new Promise(async resolve => {
        try {
            await handlers.processMetaData(utdRestResponse, context);
        }
        catch (ex) {
            // No-op, any rejection from processMetaData will be handled in responseError()
        }
        finally {
            resolve(handlers.responseError(utdRestResponse, context));
        }
    });
}

async function responseHandler(config, utdRestResponse) {
    await handlers.processMetaData(utdRestResponse, context);
    config && utdRestResponse.mergeConfig && utdRestResponse.mergeConfig(config);
    return handlers.response(utdRestResponse, context).body;
}

export default {
    init(vcontext) {
        context = vcontext;
        registerRequestHook(this.requestHandler);
    },
    requestHandler(request) {
        return handlers.request(context, request);
    },
    getCustom(uri) {
        return request.getRaw(uri);
    },
    getStream({ uri, headers = {}, config = {}, params = {} }) {
        uri = createRestPath(uri);
        return request.getStream(uri, headers, params)
            .catch(responseErrorHandler.bind(null, config));
    },
    get({ uri, headers = {}, config = {}, params = {} }) {
        uri = createRestPath(uri);
        return request.get(uri, headers, params)
            .then(responseHandler.bind(null, config))
            .catch(responseErrorHandler.bind(null, config));
    },
    // eslint-disable-next-line complexity
    postSync(uri, data, cache) {
        // Synchronous POST method
        if (data && typeof data === 'object') {
            let y = '';
            const e = encodeURIComponent;
            for (const x in data) {
                y += `&${e(x)}=${e(data[x])}`;
            }
            uri += `?${y.slice(1)}${!cache ? `&_t=${new Date}` : ''}`;
        }

        try {
            // eslint-disable-next-line no-undef
            const xhr = new (XMLHttpRequest || ActiveXObject)('MSXML2.XMLHTTP.3.0');
            xhr.open('POST', uri, false);
            // TODO - do we *need* a timeout?
            // xhr.timeout = reqTimeout;
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            const csrfToken = getDecodeCookie(C_CSRF.COOKIES_TOKEN);
            csrfToken && xhr
                .setRequestHeader(C_CSRF.HEADER_TOKEN, csrfToken);

            xhr.send(null);
        }
        catch (error) {
            // noop
        }
    },
    post({ uri, data, headers = {}, config = {}, params = {} }) {
        uri = createRestPath(uri);
        return request.post(uri, data, headers, params)
            .then(responseHandler.bind(null, config))
            .catch(responseErrorHandler.bind(null, config));
    },
    put({ uri, data, headers = {}, config = {}, params = {} }) {
        uri = createRestPath(uri);
        return request.put(uri, data, headers, params)
            .then(responseHandler.bind(null, config))
            .catch(responseErrorHandler.bind(null, config));
    },
    delete({ uri, data, headers = {}, config = {}, params = {} }) {
        uri = createRestPath(uri);
        return request.delete(uri, data, headers, params)
            .then(responseHandler.bind(null, config))
            .catch(responseErrorHandler.bind(null, config));
    },
    getWithAbort({ uri, headers = {}, config = {}, params = {} }) {
        // Version of "get" which can handle being manually aborted
        const indexOf = uri.indexOf('?');
        const path = indexOf > -1 ? uri.substring(0, indexOf) : uri;

        // If promise is unresolved...
        if (promiseGetAbort[path]) {
            promiseGetAbort[path]();	// Abort the promise
        }
        config = config || {};
        config.timeout = new Promise(resolve => {
            promiseGetAbort[path] = resolve;
        });

        uri = createRestPath(uri);
        return request.get(uri, headers, params)
            .then(responseHandler.bind(null, config))
            .catch(responseErrorHandler.bind(null, config));
    }
};
