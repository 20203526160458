import { C_TOPIC, C_GRAPHICS } from '_acaSrc/utility/constants';

export default class TopicGraphicsGallery {
    constructor(topicGraphics, topicId) {
        if (!topicGraphics) {
            return {};
        }

        return topicGraphics.map(graphic => {
            return {
                ...graphic,
                topicKey: topicId || '',
                source: C_TOPIC.SOURCE.GRAPHICS_GALLERY
            };
        }).reduce((graphicsByType, graphic) => {
            const { type } = graphic;
            const sectionTitle = C_GRAPHICS.SECTION_TITLE[type]
                              || C_GRAPHICS.SECTION_TITLE.OTHERGRAPHICS;

            graphicsByType[type] = graphicsByType[type] || { graphics: [] };
            graphicsByType[type].sectionTitle = sectionTitle;
            graphicsByType[type].graphics.push(graphic);

            return graphicsByType;
        }, {}) || {};
    }
}