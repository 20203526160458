import { FORMULARY_TYPES, C_EVENTS } from '_acaSrc/utility/constants';
import { FormularyInlineHoverTooltip } from '_acaSrc/utility/tooltip';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { arrayToUrlArgsWithPrefix } from '_acaSrc/utility/http';
import { nextTick } from 'vue';
import PubSub from '_acaSrc/utility/PubSub';
import i18next from 'i18next';

const state = {
    formularyInfo: {
        drugList: [],
        formulariesList: [],
        systemFormulariesMap: [],
        isDataLoaded: false,
        topicTitle: '',
        contentType: 'topic'
    }
};

export const getters = {
    formularyInfoDrugList: state => state.formularyInfo.drugList,
    formularyInfoFormulariesList: state => state.formularyInfo.formulariesList,
    formularyInfoSystemFormulariesMap: state => state.formularyInfo.systemFormulariesMap,
    formularyInfoIsDataLoaded: state => state.formularyInfo.isDataLoaded,
    formularyInfoTopicTitle: state => state.formularyInfo.topicTitle,
    formularyInfoContentType: state => state.formularyInfo.contentType,
    formularyInfoIsPathways: state => state.formularyInfo.contentType === FORMULARY_TYPES.PATHWAYS
};

export const SET_FORMULARY_INFO_DRUG_LIST = 'SET_FORMULARY_INFO_DRUG_LIST';
export const SET_FORMULARY_INFO_FORMULARIES_LIST = 'SET_FORMULARY_INFO_FORMULARIES_LIST';
export const SET_FORMULARY_INFO_SYSTEM_FORMULARIES_MAP
    = 'SET_FORMULARY_INFO_SYSTEM_FORMULARIES_MAP';
export const SET_FORMULARY_INFO_IS_DATA_LOADED = 'SET_FORMULARY_INFO_IS_DATA_LOADED';
export const SET_FORMULARY_INFO_TOPIC_TITLE = 'SET_FORMULARY_INFO_TOPIC_TITLE';
export const SET_FORMULARY_INFO_CONTENT_TYPE = 'SET_FORMULARY_INFO_CONTENT_TYPE';

export const mutations = {
    [SET_FORMULARY_INFO_DRUG_LIST](state, drugList = []) {
        state.formularyInfo.drugList = drugList.sort((drugA, drugB) =>
            drugA.drugName.localeCompare(drugB.drugName));
    },
    [SET_FORMULARY_INFO_FORMULARIES_LIST](state, formulariesList) {
        state.formularyInfo.formulariesList = formulariesList;
    },
    [SET_FORMULARY_INFO_SYSTEM_FORMULARIES_MAP](state, systemFormulariesMap) {
        state.formularyInfo.systemFormulariesMap = systemFormulariesMap;
    },
    [SET_FORMULARY_INFO_IS_DATA_LOADED](state, isDataLoaded) {
        state.formularyInfo.isDataLoaded = isDataLoaded;
    },
    [SET_FORMULARY_INFO_TOPIC_TITLE](state, topicTitle) {
        state.formularyInfo.topicTitle = topicTitle || '';
    },
    [SET_FORMULARY_INFO_CONTENT_TYPE](state, contentType) {
        state.formularyInfo.contentType = contentType;
    }
};

export const actions = {
    reset({ commit }) {
        commit(SET_FORMULARY_INFO_DRUG_LIST, []);
        commit(SET_FORMULARY_INFO_FORMULARIES_LIST, []);
        commit(SET_FORMULARY_INFO_IS_DATA_LOADED, false);
        commit(SET_FORMULARY_INFO_TOPIC_TITLE, '');
        commit(SET_FORMULARY_INFO_CONTENT_TYPE, FORMULARY_TYPES.TOPIC);
    },
    getFormulariesInfo({ commit, dispatch }, payload) {
        commit(SET_FORMULARY_INFO_CONTENT_TYPE, FORMULARY_TYPES.TOPIC);
        return getFormulariesInfo(payload.topicId, payload.opts)
            .then(data => dispatch('handleFormulariesResponse', data))
            .catch(error => handleFormulariesError(error));
    },
    handleFormulariesResponse({ commit }, payload) {
        commit(SET_FORMULARY_INFO_DRUG_LIST, payload.drugsInTopic);
        commit(SET_FORMULARY_INFO_TOPIC_TITLE, payload.topicTitle);
        const formulariesList = payload.lexiAccountFormularyInfos
            .reduce((memo, account) => {
                return memo.concat(account.formularies.map(
                    formulary => ({
                        ...formulary,
                        accountName: account.accountName
                    })
                ));
            }, []);
        commit(SET_FORMULARY_INFO_FORMULARIES_LIST, formulariesList);
        // Prepare health system and related formularies
        // data for use in select drop down controls.
        const systemFormulariesMap = payload.lexiAccountFormularyInfos.map(system => {
            return {
                label: system.accountName,
                value: system.accountName,
                formularies: system.formularies.map(formulary => {
                    return {
                        label: formulary.name,
                        value: formulary.code,
                        drugs: formulary.drugs
                    };
                }).sort((a, b) => a.label.localeCompare(b.label))
            };
        }).sort((a, b) => a.label.localeCompare(b.label));
        commit(SET_FORMULARY_INFO_SYSTEM_FORMULARIES_MAP, systemFormulariesMap);

        commit(SET_FORMULARY_INFO_IS_DATA_LOADED, true);
    },
    getPathwaysFormulariesInfo({ commit, dispatch }, payload) {
        commit(SET_FORMULARY_INFO_CONTENT_TYPE, FORMULARY_TYPES.PATHWAYS);
        return getPathwaysFormulariesInfo(payload.pathwaysId, payload.drugIds, payload.source)
            .then(data => dispatch('handleFormulariesResponse', data))
            .catch(error => handleFormulariesError(error));
    },
    getHighlightsForFormulary({ getters }, payload) {
        if (!payload.formularyModel
            || !payload.formularyModel.drugs
            || !payload.formularyModel.drugs.length
            || payload.formularyModel.highlights) {
            return;
        }

        let method = null;
        if (getters.formularyInfoIsPathways) {
            method = getPathwaysFormularyHighlights;
        }
        else {
            method = getFormularyHighlights;
        }

        return method(payload.contentId, payload.formularyModel.value,
            payload.formularyModel.drugs)
            .then(({ drugHighlights }) => {
                payload.formularyModel.highlights = drugHighlights;
            });
    },
    async enhanceDrugReferences({ rootGetters, getters, dispatch }) {
        const drugTooltipMessages = drugTooltipMessageMap(
            getters.formularyInfoDrugList,
            getters.formularyInfoFormulariesList);

        Array.from(document.querySelectorAll('[data-topicid]'))
            .forEach(drugEl => {
                enhanceDrugElement(
                    drugEl,
                    drugTooltipMessages);
            });

        await nextTick(async() => {
            await dispatch('app/confirmConfigLoaded', null, { root: true });
            new FormularyInlineHoverTooltip('.drug-ref-tooltip', rootGetters['app/tooltipConfig'], {
                onShown: () => new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                    uiElementName: 'hovers-over-drug-name'
                })
            }).initialize().catch(() => {
                // Ignoring rejection, as this is expected for certains browser, or print view
            });
        });
    },
    resolveFormularyInfo({ rootGetters, dispatch, getters }, payload) {
        const { topic, pathwaysId, drugIds, source } = payload.params;
        return dispatch('app/confirmConfigLoaded', null, { root: true })
            .then(() => {
                if (rootGetters['feature/showFormulinkContent']) {
                    dispatch('reset');
                    if (topic) {
                        return dispatch('getFormulariesInfo',
                            { topicId: topic, opts: { bypassAll: true } });
                    }

                    return dispatch('getPathwaysFormulariesInfo',
                        { pathwaysId, drugIds, source });
                }

                const stateName = rootGetters['user/userLoggedIn']
                    ? 'forbiddenIcgAccessMobile'
                    : 'forbiddenAccessMobile';
                rootGetters['app/router'].go(stateName);
            })
            .then(() => {
                const formulariesList = getters.formularyInfoFormulariesList;
                const formularyInfoIsDataLoaded = getters.formularyInfoIsDataLoaded;
                if (formularyInfoIsDataLoaded && !formulariesList.length) {
                    rootGetters['app/router'].go('forbiddenIcgAccessMobile');
                }
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export const enhanceDrugElement = (drugEl, drugTooltipMessages) => {
    const tooltipTranslationKey = drugTooltipMessages[drugEl.getAttribute('data-topicid')];
    if (tooltipTranslationKey) {
        const tooltipMsg = i18next.t(tooltipTranslationKey);
        const newSpanEl = document.createElement('span');
        newSpanEl.className = 'drug-ref-tooltip';
        newSpanEl.setAttribute('title', tooltipMsg);
        newSpanEl.appendChild(drugEl.cloneNode(true));

        drugEl.parentNode.replaceChild(newSpanEl, drugEl);
    }
};

export const drugTooltipMessageMap = (drugsInTopic, formularies) => {
    return drugsInTopic.reduce((result, drug) => {
        result[drug.topicId] = toolTipMessage(drug, formularies);
        return result;
    }, {});
};

export const toolTipMessage = (drug, formularies) => {
    let toolTip;
    if (drug.isLandingPageDrug) {
        toolTip = 'FORMULINK.VIEW_FORMULARY_STATUS';
    }
    else if (isDrugFoundInFormulary(drug.drugId, formularies)) {
        toolTip = 'FORMULINK.MATCH_FOUND';
    }
    else {
        toolTip = 'FORMULINK.MATCH_NOT_FOUND';
    }
    return toolTip;
};

const isDrugFoundInFormulary = (drugId, formularies) => {
    return !!formularies.find(formulary => formulary.drugs.find(id => id === drugId));
};

export const handleFormulariesError = error => {
    if (error && error.config && error.config.bypassAll) {
        return true;
    }
};

export const getFormularyHighlights = (topicId, formularyCode, drugList) => {
    return utdRest('topic/formulary/highlights', {
        topicId,
        formularyCode,
        drugList
    });
};

export const getPathwaysFormularyHighlights = (pathwayId, formularyCode, drugList) => {
    const drugIdArgs = arrayToUrlArgsWithPrefix(drugList, 'drugIds');
    return utdRest('pathways/formulary/highlights', {
        pathwayId,
        drugIdArgs,
        formularyCode
    });
};

export const getPathwaysFormulariesInfo = (pathwaysId, drugIds, source) => {
    const drugIdArgs = arrayToUrlArgsWithPrefix(drugIds, 'drugIds');
    const params = {
        source
    };
    return utdRest('formularies/drugsForFormularies', {
        pathwaysId,
        drugIdArgs,
        params
    });
};

export const getFormulariesInfo = (topicId, config) => {
    return utdRest('topic/formularies', {
        topicId,
        config
    });
};
