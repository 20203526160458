<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="two-accounts__content ds1-utd-js2">
    <form id="validateUserNameForm"
          class="form-login jumpstart-form"
          name="validateUserNameForm">
      <div class="two-accounts__action">
        <h3 class="two-accounts__header">
          Update your Username
        </h3>
        <a id="nextStepsBackLink" href="#" @click="processLogout()">
          <i class="wkce-icon-chevron-left" />{{ $t('BACK') }}
        </a>
        <p class="two-accounts__info">
          Please create a username that is different than your enterprise email:
        </p>
        <p class="two-accounts__info ds1-mt-0">
          {{ form.userName }}
        </p>
        <p class="ds1-utd-size-2 ds1-mb-0">
          Your username must be 6-50 characters and may contain numbers
          and the following special characters:
        </p>
        <p class="ds1-utd-size-2 ds1-mt-0">
          • _ @ # $ * ! ( ) + =
        </p>
        <fieldset class="two-accounts__input-fields wk-js2">
          <utd-notification v-if="message.error"
                            id="serverError"
                            tabindex="0"
                            class="two-accounts__notification"
                            notification-mode="error">
            <template #title>{{ getErrorTitle() }}</template>
            <span v-html="getErrorContent()" />
          </utd-notification>
          <utd-text-input class="utd-text-input" :label="$t('LOGIN.ORIGINAL_USERNAME')">
            <input id="userNameInput"
                   :value="form.userName"
                   readonly
                   class="wk-field-input"
                   type="username">
          </utd-text-input>
          <utd-text-input class="utd-text-input"
                          :label="$t('MYACCOUNT.NEW_USER_NAME')"
                          :is-error="!isUserNameValid && isDirty.newUserName"
                          :is-success="isUserNameValid"
                          :error-message="userNameValidator">
            <input id="newUserNameInput"
                   ref="userNameInputRef"
                   v-model="newUserName"
                   class="wk-field-input"
                   type="username"
                   @blur="handleNewUserNameBlur">
          </utd-text-input>
          <utd-text-input :label="$t('MYACCOUNT.REENTER_NEW_USERNAME')"
                          :is-error="!isConfirmUserNameValid && isDirty.confirmUserName"
                          :is-success="isConfirmUserNameValid"
                          :error-message="confirmUserNameValidator">
            <input id="confirmUserNameInput"
                   v-model="confirmUserName"
                   class="wk-field-input"
                   type="username"
                   @blur="handleConfirmUserNameBlur">
          </utd-text-input>
        </fieldset>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import { C_HTTP, C_ACCOUNT } from '_acaSrc/utility/constants';
import { validateUserName, validateConfirmUserName } from '_acaSrc/utility/Validators';
import { SET_SERVER_ERROR } from '_acaSrc/store/account.store';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';

export default {
    components: {
        UtdTextInput,
        UtdNotification
    },
    emits: [ 'close-modal' ],
    data() {
        return {
            inputType: 'password',
            isDirty: {
                newUserName: false,
                confirmUserName: false
            },
            newUserName: '',
            confirmUserName: ''
        };
    },
    computed: {
        ...mapGetters('login', [ 'form', 'message' ]),
        ...mapGetters('account', [ 'serverErrors' ]),
        ...mapGetters('app', [ 'router' ]),
        isUserNameValid() {
            return this.userNameValidator === '';
        },
        isConfirmUserNameValid() {
            return this.confirmUserNameValidator === '';
        },
        userNameValidator() {
            if (this.serverErrors.newUserName) {
                return this.$t(this.serverErrors.newUserName);
            }
            return this.$t(validateUserName(this.newUserName));
        },
        confirmUserNameValidator() {
            return this.$t(validateConfirmUserName(this.newUserName, this.confirmUserName));
        }
    },
    methods: {
        ...mapMutations('account', [ SET_SERVER_ERROR ]),
        ...mapActions('login', [ 'resolveLogout' ]),
        clearUserNameServerError() {
            this[SET_SERVER_ERROR]({ newUserName: '' });
        },
        getErrorTitle() {
            return this.message.error === C_ACCOUNT.FORM_MESSAGING.WAYF_GENERIC_ERROR_ID
                ? this.$t('LOGIN.INCORRECT_PASSWORD') : this.message.title;
        },
        getErrorContent() {
            return this.message.error === C_ACCOUNT.FORM_MESSAGING.WAYF_GENERIC_ERROR_ID
                ? this.$t('LOGIN.WAYF_INCORRECT_PASSWORD') : this.message.error;
        },
        handleNewUserNameBlur() {
            this.isDirty.newUserName = true;
            this.clearUserNameServerError();
        },
        handleConfirmUserNameBlur() {
            this.isDirty.confirmUserName = true;
        },
        async processLogout() {
            try {
                await utdRest('logout');
                this.router.url(C_HTTP.DEFAULT_LOGIN);
                this.$emit('close-modal');
            }
            catch {
                // No-op
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@NEXT-STEPS-PRIMARY-BG-COLOR: @WKCE-BLUE-TINT6;

.two-accounts__header {
  .ds1-utd-weight-bold();
  .ds1-mt-0();
  .ds1-mb-2();
  font-size: 22px;
}

.two-accounts__notification {
  .ds1-mb-2();
  .ds1-mt-0();
}

.two-accounts__input-fields {
  .ds1-pa-0();
  border: none;
  max-width: 400px;

  .utd-text-input {
    .ds1-mb-3();
  }
}

.two-accounts__action {
  .ds1-mb-4();
}

.two-accounts__info {
  .ds1-mb-0();
  .ds1-utd-weight-bold();
  .ds1-utd-size-3();
}
</style>