<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="utd-email-code wk-js2">
    <div class="verification-code-info">
      <p class="ds1-utd-size-2">
        Check the email account you submitted for a message containing a verification code.
        If you don't see the email, please check your spam folder.
        Paste or type the code below. The code is valid for 10 minutes.
        <span class="ds1-utd-weight-600">
          No account data will be saved until the code is accepted.
        </span>
      </p>
    </div>
    <utd-text-input :label="$t('MYACCOUNT.CONTACT_VERIFICATION_CODE')"
                    input-id="verificationCode"
                    :is-error="showEmailCodeError"
                    :error-message="emailCodeValidator"
                    :input-size="input.inputSize"
                    :is-disabled="options.isDisabled">
      <input id="verificationCodeInput"
             v-model="emailCode"
             class="wk-field-input"
             @input="handleCodeInput">
    </utd-text-input>
    <div v-if="showSendNewCodeMessage" class="max-attempts-note">
      <p class="max-attempts__text--error">
        Please send a new verification code.
      </p>
    </div>
    <div>
      <utd-button id="sendCodeButton"
                  :disabled="isSendCodeButtonDisabled"
                  button-size="large"
                  button-style="ghost"
                  @click.prevent="sendNewCode">
        Send new verification code
      </utd-button>
    </div>
    <div v-if="newCodeSent" class="email-code-sent">
      <span class="wkce-icon-check" />
      A new code has been sent.
    </div>
    <div v-if="input.attemptLimitReached" class="max-attempts-note">
      <p class="max-attempts__text"
         v-html="$t('LOGIN.LOGIN_HELP_CONTACT')" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { numericRegex } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdButton,
        UtdTextInput
    },
    props: {
        input: {
            type: Object,
            default() {
                return {
                    inputSize: 'small',
                    email: '',
                    error: '',
                    emailLimitReached: false,
                    codeLimitReached: false,
                    attemptLimitReached: false,
                    formSubmitted: false
                };
            }
        },
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        newCodeSent: {
            type: Boolean
        }
    },
    emits: [ 'send-email-verification-code', 'utd-email-code-input' ],
    data() {
        return {
            numericRegex,
            emailCode: '',
            isDirty: false
        };
    },
    computed: {
        ...mapGetters('profile', [ 'myAccountContactInfo' ]),
        emailCodeValidator() {
            if (!this.emailCode) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            if (!this.emailCode.match(numericRegex)) {
                return this.$t('MYACCOUNT.INVALID_EMAIL_CODE');
            }
            if (this.input.error) {
                return this.$t(this.input.error);
            }
            return '';
        },
        isSendCodeButtonDisabled() {
            return this.input.emailLimitReached;
        },
        showEmailCodeError() {
            return this.emailCodeValidator !== ''
                && (this.isDirty || this.input.formSubmitted);
        },
        showSendNewCodeMessage() {
            return this.input.codeLimitReached && !this.input.attemptLimitReached;
        }
    },
    methods: {
        sendNewCode() {
            this.$emit('send-email-verification-code');
        },
        handleCodeInput() {
            this.isDirty = true;
            this.$emit('utd-email-code-input', {
                emailCode: this.emailCode
            });
        }
    }
};
</script>
<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-email-code {
  .ds1-mb-2();
  .ds1-pa-1();
  background-color: @DS1-UTD-GRAY-BG-COLOR;
}

.email-code-sent {
  .ds1-utd-size-2();
  .ds1-mt-1();
  font-style: italic;
}

.verification-code-info {
  .ds1-mb-2();
}

.max-attempts-note {
  .ds1-utd-size-2();
}

.max-attempts__text {
  .ds1-mv-1();

  &--error {
    color: @DS1-UTD-ERROR;
  }
}
</style>
