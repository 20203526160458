<template>
  <utd-modal-dialog ref="changeUsernameRef"
                    :modal-classes="'change-username-modal'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop"
                    @closed="handleClose()">
    <template #header>{{ $t('MYACCOUNT.CHANGE_USERNAME') }}</template>
    <div class="change-username-dialog">
      <div v-if="successMessage === ''" class="dialog__content">
        <utd-notification v-if="isUnexpectedError"
                          id="unexpectedServerError"
                          :notification-mode="'error'">
          <p class="dialog__server-error-text">
            {{ $t('MYACCOUNT.UPDATE_INFORMATION_UNEXPECTED_ERROR') }}
          </p>
          <p class="dialog__server-error-text">Please try again later or contact us using
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/contact-us"
               class="ds1-utd-weight-600">
              UpToDate Support.
            </a>
          </p>
        </utd-notification>
        <p class="dialog__license-info">
          {{ $t('MYACCOUNT.LICENSE_AGREEMENT_INFO') }}
        </p>
        <p class="ds1-utd-weight-600">
          Username: <span class="dialog__username">{{ myAccountUserName }}</span>
        </p>
        <form class="dialog__login-info-form">
          <fieldset class="dialog__fieldset">
            <div class="dialog__input-container wk-js2">
              <h3 class="dialog__input-title">
                {{ $t('MYACCOUNT.PASSWORD_OLD_CONFIRM') }}
              </h3>
              <utd-password-input ref="passwordInputRef"
                                  input-id="curPasswordInput"
                                  :is-error="!isPasswordValid && isDirty.password"
                                  :label="$t('MYACCOUNT.ENTER_OLD_PASSWORD')"
                                  :error-message="passwordValidator"
                                  @text-input-toggle-show="toggleVisibleInput">
                <template v-slot="parentScope">
                  <input id="curPasswordInput"
                         v-model="password"
                         tabindex="0"
                         class="wk-field-input"
                         :type="inputType"
                         autocapitalize="off"
                         autocorrect="off"
                         name="passwordInput"
                         @blur="handlePasswordBlur(parentScope)">
                </template>
              </utd-password-input>
            </div>
            <p>
              <a href="/account/reset-password" @click="closeDialog(true)">
                {{ $t('MYACCOUNT.FORGOT_PASSWORD') }}
              </a>
            </p>
          </fieldset>
          <fieldset class="dialog__fieldset">
            <div class="dialog__input-container wk-js2">
              <h3 class="dialog__input-title">
                {{ $t('MYACCOUNT.NEW_USER_NAME') }}
              </h3>
              <utd-text-input :is-error="!isUserNameValid && isDirty.newUserName"
                              :is-success="isUserNameValid"
                              :label="$t('MYACCOUNT.ENTER_NEW_USERNAME')"
                              :error-message="userNameValidator">
                <input id="newUserNamenput"
                       v-model="newUserName"
                       class="wk-field-input"
                       type="username"
                       autocomplete="username"
                       autocapitalize="off"
                       autocorrect="off"
                       name="userNameInput"
                       @blur="handleNewUserNameBlur">
              </utd-text-input>
              <utd-text-input :is-error="!isConfirmUserNameValid && isDirty.confirmUserName"
                              :is-success="isConfirmUserNameValid"
                              :label="$t('MYACCOUNT.REENTER_NEW_USERNAME')"
                              :error-message="confirmUserNameValidator">
                <input id="confirmUserNameInput"
                       v-model="confirmUserName"
                       class="wk-field-input"
                       type="username"
                       autocomplete="username"
                       autocapitalize="off"
                       autocorrect="off"
                       name="confirmUserNameInput"
                       @blur="handleConfirmUserNameBlur">
              </utd-text-input>
            </div>
            <p class="dialog__rules-title">Username rules:</p>
            <ul class="dialog__form-rules">
              <li>
                <p class="dialog__rule-item">
                  6 to 50 characters
                </p>
              </li>
              <li>
                <p class="dialog__rule-item">
                  It may contain numbers and special characters from the following set:
                </p>
                <p class="dialog__rule-item dialog__characters">
                  . - _ @ # $ * ! ( ) + =
                </p>
              </li>
            </ul>
          </fieldset>
        </form>
      </div>
      <div v-else class="dialog__success-message">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t(successMessage)" />
        <p>{{ $t('MYACCOUNT.CREDENTIAL_CHANGE_WAIT_ADVICE') }}</p>
      </div>
    </div>
    <template #footer>
      <utd-button id="closeDialogButton"
                  :button-style="successMessage ? '' : 'ghost'"
                  @click="closeDialog()">Close</utd-button>
      <utd-button v-if="!successMessage"
                  id="saveUsernameButton"
                  :disabled="isSubmitDisabled"
                  button-style="ghost"
                  @click="submitUsername()">Save Changes</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdPasswordInput from '_acaSrc/components/shared/input/UtdPasswordInput.vue';
import PubSub from '_acaSrc/utility/PubSub';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import {
    SET_SERVER_ERROR,
    SET_SUCCESS_MESSAGE
} from '_acaSrc/store/account.store';
import { validateUserName, validateConfirmUserName } from '_acaSrc/utility/Validators';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdModalDialog,
        UtdButton,
        UtdNotification,
        UtdTextInput,
        UtdPasswordInput
    },
    data() {
        return {
            inputType: 'password',
            isDirty: {
                password: false,
                newUserName: false,
                confirmUserName: false
            },
            validationErrors: {
                password: '',
                newUserName: '',
                confirmUserName: ''
            },
            isSubmitting: false,
            password: '',
            newUserName: '',
            confirmUserName: '',
            isUnexpectedError: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapGetters('account', [
            'myAccountUserName',
            'serverErrors',
            'successMessage'
        ]),
        passwordValidator() {
            if (this.serverErrors.password) {
                return this.$t(this.serverErrors.password);
            }
            if (!this.password.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            return '';
        },
        userNameValidator() {
            if (this.serverErrors.newUserName) {
                return this.$t(this.serverErrors.newUserName);
            }
            return this.$t(validateUserName(this.newUserName));
        },
        confirmUserNameValidator() {
            return this.$t(validateConfirmUserName(this.newUserName, this.confirmUserName));
        },
        isSubmitDisabled() {
            return !this.isFormValid || this.isSubmitting || this.isUnexpectedError;
        },
        isPasswordValid() {
            return this.passwordValidator === '';
        },
        isUserNameValid() {
            return this.userNameValidator === '';
        },
        isConfirmUserNameValid() {
            return this.confirmUserNameValidator === '';
        },
        isFormValid() {
            return this.isPasswordValid
                && this.isUserNameValid
                && this.isConfirmUserNameValid;
        }
    },
    beforeUnmount() {
        this.resetForm();
    },
    methods: {
        ...mapActions('account', [ 'getMyAccountInfo', 'changeUserName' ]),
        ...mapMutations('account', [ SET_SERVER_ERROR, SET_SUCCESS_MESSAGE ]),
        toggleVisibleInput(hideText) {
            this.inputType = hideText ? 'password' : 'text';
        },
        handlePasswordBlur(parentScope) {
            this.isDirty.password = true;
            this.clearPasswordServerError();
            parentScope && parentScope.customBlur && parentScope.customBlur();
        },
        handleNewUserNameBlur() {
            this.isDirty.newUserName = true;
            this.clearUserNameServerError();
        },
        handleConfirmUserNameBlur() {
            this.isDirty.confirmUserName = true;
        },
        resetForm() {
            this.clearServerErrors();
            this.clearFields();
            this.setDirtyFields(false);
            this[SET_SUCCESS_MESSAGE]('');
            this.isUnexpectedError = false;
            this.inputType = 'password';
        },
        clearPasswordServerError() {
            this[SET_SERVER_ERROR]({ password: '' });
        },
        clearUserNameServerError() {
            this[SET_SERVER_ERROR]({ newUserName: '' });
        },
        clearServerErrors() {
            this.clearPasswordServerError();
            this.clearUserNameServerError();
        },
        setDirtyFields(state) {
            this.isDirty = {
                password: state,
                newUserName: state,
                confirmUserName: state
            };
        },
        clearFields() {
            this.password = '';
            this.newUserName = '';
            this.confirmUserName = '';
        },
        open() {
            this.$refs.changeUsernameRef.open();
        },
        closeDialog(redirect = false) {
            this.$refs.changeUsernameRef.close();
            new PubSub().publish('wkutd.closeMyAccountDialog');
            if (redirect) {
                new PubSub().publish('wkutd.closeMyAccountSidebar');
            }
            this.resetForm();
        },
        handleClose() {
            new PubSub().publish('wkutd.closeMyAccountDialog');
            this.resetForm();
        },
        async submitUsername() {
            if (this.isSubmitting) {
                return;
            }
            try {
                this.isUnexpectedError = false;
                this.isSubmitting = true;
                this.setDirtyFields(true);
                await this.changeUserName({
                    password: this.password,
                    newUserName: this.newUserName
                });
            }
            catch {
                this.isUnexpectedError = true;
            }
            this.isSubmitting = false;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.change-username-modal .utd-dialog-content {
  .ds1-pa-2();
}

@media screen and (min-width: 768px) {
  .change-username-modal {
    .utd-modal-content .utd-dialog-frame {
      max-width: 670px;
      min-width: 360px;

      .utd-dialog-content {
        height: 550px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .change-username-modal {
    .utd-modal-content .utd-dialog-frame {
      width: auto;
      max-width: 360px;
    }
  }
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-DIALOG-USERNAME-COLOR: @WKCE-ORANGE-SHADE1;

.dialog__username {
  color: @DS1-DIALOG-USERNAME-COLOR;
}

.change-username-modal .utd-dialog-footer .utd-button {
  .ds1-ml-2();
}

</style>