<template>
  <div>
    <div v-if="isExpiredSite" id="expiredView" class="expired-enterprise-message">
      <ul class="utd-menu utd-menu-news ">
        <li>
          <h2>Enterprise subscription has expired</h2>
          <p>
            The UpToDate online subscription for
            <strong>
              <span v-text="expiredSiteName" />
            </strong>
            has expired.
          </p>
          <p>
            If you have an active UpToDate account, <a href="/login">sign in.</a>
          </p>
          <p>
            For continued access to UpToDate, <a href="/store" target="_blank"> subscribe now</a> for an individual subscription
            (and get the added benefits of access anytime, anywhere and effortless CME credit).
          </p>
          <p>
            Or for questions about the
            <span v-text="expiredSiteName" />
            site subscription, please contact UpToDate:
          </p>
          <blockquote>
            <p>
              <span v-text="$t('LOGIN.ENTERPRISE_EXPIRED_DOMESTIC_TITLE')" /><br>
              <span v-html="$t('LOGIN.ENTERPRISE_EXPIRED_EMAIL')" />
              <a href="mailto:enterprise@uptodate.com"
                 class="enterpriseEmail">enterprise@uptodate.com</a><br>
              <span v-html="$t('LOGIN.ENTERPRISE_EXPIRED_DOMESTIC_CONTACT_PHONE')" /><br>
              <span v-text="$t('LOGIN.ENTERPRISE_EXPIRED_DOMESTIC_CONTACT_FAX')" /><br><br>

              <span v-text="$t('LOGIN.ENTERPRISE_EXPIRED_INTERNATIONAL_TITLE')" /><br>
              <span v-text="$t('LOGIN.ENTERPRISE_EXPIRED_EMAIL')" />
              <a href="mailto:international@uptodate.com"
                 class="enterpriseEmail">international@uptodate.com</a><br>
              <span v-text="$t('LOGIN.ENTERPRISE_EXPIRED_INTERNATIONAL_CONTACT_PHONE')" /><br>
              <span v-text="$t('LOGIN.ENTERPRISE_EXPIRED_INTERNATIONAL_CONTACT_FAX')" /><br>
            </p>
          </blockquote>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('auth', [ 'expiredSiteName', 'isExpiredSite' ])
    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/global/variables';

#expiredView {
  width: 90%;
  font-size: 1em;
  max-width: 437px;
  margin: 0 auto;
  padding: 11px 0 0;

  .utd-menu {
    border-bottom: none;

    li {
      display: block;

      h2 {
        color: red;
        font-size: 1.15em;
        font-weight: bold;
      }

      :deep(a) {
        display: inline;
        // Important need to overwrite style from
        // utd-menu.less of links which already has !important
        color: @WK_UTD_GREEN_BASE !important;
        padding: 0;
        margin: 0;
      }

      &:first-child {
        border-top: none;
      }
    }
  }
}
</style>
