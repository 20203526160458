<template>
  <div id="topicGraphicCollection"
       ref="galleryContainer">
    <div v-show="isGalleryScrolled"
         class="gallery-shadow" />
    <div v-for="(type, index) in galleryGraphics"
         :key="index"
         class="graphics-galleries">
      <utd-media-collection class="collection-type-thumbnails collection-display-row"
                            with-large-thumbnails
                            :title="type.sectionTitle"
                            :media-collection="type.graphics" />
    </div>
  </div>
</template>

<script>
import UtdMediaCollection from '_acaSrc/components/shared/stdlib/UtdMediaCollection.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdMediaCollection
    },
    props: {
        galleryGraphics: {
            type: Object
        }
    },
    data() {
        return {
            isGalleryScrolled: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'isNotDesktopView' ])
    },
    mounted() {
        this.$nextTick(() => {
            this.galleryScrollListener
                = this.setScrollListener(this.$refs.galleryContainer, this.onScrollEvent);
        });
    },
    beforeUnmount() {
        this.clearScrollListener(this.galleryScrollListener);
    },
    methods: {
        onScrollEvent(event) {
            this.isGalleryScrolled = this.isNotDesktopView && event.target.scrollTop > 0;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#topicGraphicCollection {
  position: fixed;
  top: 165px;
  left: 0;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  overflow-y: auto;
  width: 100%; // To support IE11 responsive view and some mobile devices

  .graphics-galleries:last-child {
    margin-bottom: 80px;
  }

  .gallery-shadow{
    position: fixed;
    height: 8px;
    margin-top: -8px;
    width: 100%;
    box-shadow: @DS1-BOX-SHADOW-RAISED;
  }

  :deep(.wk-media-collection) {
    .wk-media-title {
      .ds1-utd-size-3();
      .ds1-utd-weight-300();
      .ds1-mh-2();
      .ds1-mb-2();
      .ds1-mt-3();
      color: @DS1-UTD-GRAY-TEXT-COLOR;
      display: flex;
      letter-spacing: 2px;

      &:after {
        .ds1-ml-1();
        content: ' ';
        flex-grow: 1;
        margin-bottom: 7px;
        border-bottom: 2px solid @DS1-UTD-GRAY-BORDER-COLOR;
      }
    }

    .collection-thumbnails {
      .ds1-mr-1();

      .utd-thumbnail__container {
        .ds1-ml-2();
      }
    }
  }

  @media only screen and (min-width: 768px) and (orientation: landscape) {
    .isTablet & {
        position: absolute;
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    #topicGraphicCollection {
      position: static;
      overflow-y: initial;
      .ds1-mt-3();

      .graphics-galleries:last-child {
        .ds1-mb-0();
      }

      .gallery-shadow {
        display: none !important; // Required to override inline style
      }

      :deep(.wk-media-collection) {
        .wk-media-title {
          .ds1-utd-size-4();
          .ds1-pl-1();
          .ds1-mt-2();
          .ds1-mr-6();
        }

        .collection-thumbnails {
          .ds1-ml-3();
        }

        .utd-thumbnail__container {
          .ds1-ml-0();
          .ds1-mb-3();
          .ds1-mr-5();
        }
      }
    }
  }
}

</style>