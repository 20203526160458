<template>
  <utd-alert-dialog ref="modalDialog"
                    :hide-header-close="false"
                    modal-classes="translation-feedback-instruction-modal"
                    title="Provide translation feedback"
                    dialog-size="medium">
    <div class="translation-feedback__content">
      <div class="translation-feedback__heading">
        <span class="wkce-icon wkce-icon-filled-info" />
        <h5 class="translation-feedback__heading-label">
          Help UpToDate with your feedback
        </h5>
      </div>
      <ul class="translation-feedback__content-details">
        <li>To provide feedback, please select the sentence(s) you have feedback for.</li>
        <li>A 翻訳にフィードバックを提供する button will appear.</li>
        <li>Select that button to help UpToDate make the translation better.</li>
      </ul>
    </div>
  </utd-alert-dialog>
</template>

<script>
import UtdAlertDialog from '_acaSrc/components/shared/stdlib/UtdAlertDialog.vue';

export default {
    components: {
        UtdAlertDialog
    },
    methods: {
        open() {
            this.$refs.modalDialog.open();
        },
        close() {
            this.$refs.modalDialog.close();
        }
    }
};
</script>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  .translation-feedback__heading {
    .ds1-flex-vertical-center();
  }

  .translation-feedback__heading-label {
    .ds1-utd-size-6();
    .ds1-ml-2();
    .ds1-utd-weight-bold();
  }

  .translation-feedback__content {
    .ds1-ml-4();
  }

  .wkce-icon-filled-info {
    .ds1-ma-0();
    font-size: 24px;
    color: @DS1-UTD-INFO;
  }

  .translation-feedback__content-details {
    .ds1-ml-3();
    .ds1-mt-1();
    padding-inline-start: 44px;
    li {
      .ds1-pb-1();
    }
  }
</style>

<style lang="less">
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  @media only screen and (min-width: 768px) {
    .alert-modal-dialog .utd-dialog-frame .utd-dialog-footer {
      flex-direction: row;
      gap: 0;
    }
  }

  .alert-modal-dialog {
    .utd-modal-content {
      .utd-dialog-frame {
        max-width: 600px;
        .utd-dialog-header {
          .ds1-utd-weight-bold();
          font-size: 20px;
        }
        .utd-dialog-content {
          .ds1-pa-4();
          .ds1-pb-0();
        }
      }
    }

    .utd-dialog-footer {
      flex-wrap: wrap;
      align-content: flex-end;
      flex-direction: column-reverse;
      gap: 16px;
    }
  }
</style>