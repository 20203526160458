<template>
  <utd-modal-dialog ref="myAccountDialogRef"
                    :modal-classes="'manage-devices-modal'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop"
                    @closed="handleClose()">
    <template #header>{{ $t('MYACCOUNT.MANAGE_DEVICES') }}</template>
    <user-devices-list :selected-device-id="selectedDeviceId"
                       :platform="C_MY_ACCOUNT.MOBILE_DEVICE">
      Current Mobile Devices
    </user-devices-list>
    <user-devices-list v-if="numActiveDevices(C_MY_ACCOUNT.DESKTOP_DEVICE)"
                       :selected-device-id="selectedDeviceId"
                       :platform="C_MY_ACCOUNT.DESKTOP_DEVICE">
      Current Desktop Devices
    </user-devices-list>
    <template #footer>
      <utd-button @click="closeDialog()">Close</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UserDevicesList from '_acaSrc/components/header/my-account/UserDevicesList.vue';
import { C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UserDevicesList
    },
    data() {
        return {
            C_MY_ACCOUNT,
            selectedDeviceId: ''
        };
    },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapGetters('account', [ 'numActiveDevices' ])
    },
    methods: {
        open(deviceId) {
            if (deviceId) {
                this.selectedDeviceId = deviceId.toString();
            }
            this.$refs.myAccountDialogRef.open();
        },
        closeDialog() {
            this.$refs.myAccountDialogRef.close();
            new PubSub().publish('wkutd.closeMyAccountDialog');
        },
        handleClose() {
            new PubSub().publish('wkutd.closeMyAccountDialog');
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.manage-devices-modal {
  .utd-modal-content {
    .utd-dialog-frame {
      .ds1-mb-0();
      min-width: 0;
      max-width: 360px;
    }
  }
}

@media screen and (min-width: 768px) {
  .manage-devices-modal {
    .utd-modal-content {
      width: 360px;
    }
  }
}
</style>