<template>
  <div id="legalNotice">
    <dl class="utd-tabControl">
      <dt v-text="legal.title" />
    </dl>
    <article id="legalContainer">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div id="scroller" v-html="legal.legalAgrementHtml" />
    </article>
    <div v-if="legal.error" class="content-error">
      <p><strong>{{ $t('ERROR.PAGE_NOT_FOUND') }}</strong></p>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        legal: Object
    }
};
</script>
