import { getWindow } from '_acaSrc/utility/DOM';
import { SET_ERROR_FORMULA_LOGS } from '_acaSrc/store/topic/topic.store';
import { JL } from 'jsnlog';
import i18next from 'i18next';
import store from '_acaSrc/store';

const SCALE_INIT_DELAY_MS = 500;
const SCALE_RESIZE_DELAY_MS = 100;
const SCALE_REFRESH_DELAY_MS = 0;
let initTimeout, resizeTimeout, refreshTimeout;

export default {
    mounted: () => {
        // [CORE-3479] Removed "mobile/tablet only" conditional
        scaleWithInitDelay();
        getWindow().addEventListener('resize', scaleWithResizeDelay);
        // [CORE-5760] Implemented custom event listener to refresh scaled elements
        store.dispatch('app/subscribe', {
            eventName: 'wkutd.refresh-scaled-elements',
            handlerFn: scaleWithRefreshDelay
        });
    },
    beforeUnmount: () => {
        getWindow().removeEventListener('resize', scaleWithResizeDelay);
        store.dispatch('app/unsubscribe', {
            eventName: 'wkutd.refresh-scaled-elements',
            handlerFn: scaleWithRefreshDelay
        });
        clearTimeout(initTimeout);
        clearTimeout(resizeTimeout);
        clearTimeout(refreshTimeout);
    }
};

const scaleFormulas = () => {
    const formulas = document.querySelectorAll('.formulaContainer');

    // Flush any current scaling adjustments to get accurate measurements
    const formulaParents = document.querySelectorAll('.formula');
    Array.from(formulaParents).forEach(formula => {
        formula.removeAttribute('style');
    });

    // Clean up formulas with errors because we will be re-processing them
    const formulaErrors = document.querySelectorAll('.formulaError');
    Array.from(formulaErrors).forEach(formulaError => {
        const pNode = formulaError.parentNode;
        pNode.className = 'formulaContainer';
        pNode.childNodes[0].style.fontSize = 'inherit';
        pNode.removeChild(formulaError);
    });

    // Process each formula and resize them if necessary
    Array.from(formulas).forEach(formula => {
        if (!formula.offsetWidth) {
            return;
        }
        if (formula.offsetWidth > 0) {
            const containerWidth = formula.parentNode.offsetWidth;
            let formulaWidth = formula.children[0].children[0].offsetWidth;
            const elemStyles = getWindow().getComputedStyle(formula.children[0].children[0]);
            const elemMarginLeft = getWindow().parseInt(elemStyles.marginLeft, 10);
            formulaWidth += elemMarginLeft;
            let scaleAmt = (containerWidth / formulaWidth);

            // Maximum scale size for formulas - 1 = the same size as the topic text
            if (scaleAmt > 1) {
                scaleAmt = 1;
            }

            // Minimum scale size for formulas - should be about when formulas are too small to read
            // If formula falls below minimum, display an error message instead of the formula
            if (scaleAmt < 0.5) {
                logFormulaScalingError();
                const message = (store.getters['app/isDesktopBrowser']
                    ? 'ERROR.FORMULA_ERROR_DESKTOP'
                    : 'ERROR.FORMULA_ERROR');
                formula.className = 'formulaContainer formulaHasError';
                formula.innerHTML += i18next.t(message);
                return false;
            }

            // Apply the calculated scale to the formula
            // Use scale3d to force hardware GPU rendering to improve performance
            // [CORE-3479] Replacing scale3d() with font-size as it's not working anymore...
            formula.children[0].style.fontSize = `${scaleAmt}em`;
        }
    });
};

function logFormulaScalingError() {
    const id = store.getters['topic/topicId'];
    if (id && !store.getters['topic/errorFormulaLogs'][id]) {
        const details = {
            topicId: id,
            browser: store.getters['device/browserType'],
            width: window.innerWidth
        };

        // Store topicId as logged so we don't hit it again this session
        store.commit(`topic/${SET_ERROR_FORMULA_LOGS}`, {
            topicId: id,
            formulaLog: details
        });

        // eslint-disable-next-line max-len
        JL('Angular.Ajax').warn(`Formula scaled below 50% - topicId: ${details.topicId}, browser: ${details.browser}, width: ${details.width}`);
    }
}

function scaleWithInitDelay() {
    initTimeout = setTimeout(scaleFormulas, SCALE_INIT_DELAY_MS);
}

function scaleWithResizeDelay() {
    resizeTimeout = setTimeout(scaleFormulas, SCALE_RESIZE_DELAY_MS);
}

function scaleWithRefreshDelay() {
    refreshTimeout = setTimeout(scaleFormulas, SCALE_REFRESH_DELAY_MS);
}
