<template>
  <div v-if="showBertResults"
       class="bert-results-container ds1-pv-2">
    <div v-for="(result, index) in bertResults"
         :key="index"
         class="bert-results">
      <div :id="result.id" class="nlp-result-container">
        <span class="bert-result-title ds1-utd-weight-600">ANSWER {{ index + 1 }}</span>
        <!--eslint-disable-next-line vue/no-v-html-->
        <div class="bert-result-snippet ds1-utd-size-2 ds1-mv-1" v-html="result.answer" />
        <div class="bert-result-see-more ds1-utd-size-2 ds1-mv-1">
          <span>See more at </span>
          <a v-for="(subhit, i) in result.subHits"
             :key="i"
             class="bert-result-subhits ds1-utd-weight-600"
             :class="`subhit-${i + 1}`"
             target="_blank"
             rel="noopener"
             :href="subhit.url">
            <span>{{ subhit.title }} </span>
          </a>
          <span>in </span>
          <a class="bert-topic-link  ds1-utd-weight-600"
             target="_blank"
             rel="noopener"
             :href="result.url">
            <span>{{ result.title }}</span>
          </a>
        </div>
      </div>
      <iframe id="bert-qualtrics-iframe"
              ref="qualtricsIframe"
              class="bert-result-survey ds1-mb-2"
              title="Bert Results Survey"
              frameborder="0"
              allowfullscreen="false"
              width="350"
              height="200"
              :src="surveyUrl(index)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_DOMAINS } from '_acaSrc/utility/constants';
import { collectionToQueryParams } from '_acaSrc/utility/http';
import BertResult from '_acaSrc/utility/data-adapters/bert-results-adapter';

export default {
    props: {
        bertResults: [ Array, Array(BertResult) ]
    },
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('profile', [ 'userProfileVisitor' ]),
        ...mapGetters('feature', [ 'isCalibrationAppUser' ]),
        showBertResults() {
            return this.bertResults
              && this.bertResults.length
              && this.isCalibrationAppUser;
        },
        surveyData() {
            return index => {
                return {
                    query: this.router.stateParams().search,
                    topicTitle: this.bertResults[index].title,
                    topicId: this.bertResults[index].id,
                    topicRank: this.bertResults[index].searchRank,
                    answerRank: (index + 1),
                    answer: this.bertResults[index].answer,
                    utdId: this.userProfileVisitor.userUtdId,
                    userId: this.userProfileVisitor.id,
                    userCountry: this.userProfileVisitor.userCountry,
                    userSpecialty: this.userProfileVisitor.userSpecialty,
                    sessionId: this.userProfileVisitor.sessionId,
                    deviceType: this.userProfileVisitor.deviceType,
                    deviceSize: this.userProfileVisitor.deviceSize

                };
            };
        },
        surveyUrl() {
            return index => {
                return `${C_DOMAINS.QUALTRICS_CDN}/jfe/form/SV_5dJn791RTcZXM7c?${
                    collectionToQueryParams(this.surveyData(index))}`;
            };
        }
    }
};
</script>

<style lang ="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.bert-results-container {
  .ds1-ph-2();

  .bert-results {
    .ds1-mb-2();
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
    display: flex;
    flex-direction: column;

    .nlp-result-container {
      flex-grow: 1;
    }
  }

  .bert-result-survey {
    border: @DS1-UTD-GRAY-SINGLE-BORDER;
    flex-shrink: 0;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop .bert-results-container {
    .ds1-ph-3();
    max-width: 1200px;

    .bert-results {
      flex-direction: row;
    }

    .bert-result-survey {
      .ds1-ml-3();
    }
  }
}

@media only screen and (min-width: 1280px) {
  .isDesktop .bert-results-container {
    max-width: 1300px;
  }
}
</style>