<template>
  <div>
    <utd-modal-dialog ref="modalDialog"
                      :hide-header-close="true"
                      :close-on-overlay-click="false"
                      :close-on-escape="false">
      <template #header>Add Single Sign-On (SSO) to your UpToDate Account</template>
      <div class="next-steps-merge-accounts ds1-utd-js2">
        <div v-if="mergeSuccess" class="next-steps__notification">
          <utd-notification v-if="mergeSuccess"
                            id="nextStepsMergeNotification"
                            notification-mode="success">
            <template #title>Success! Your account now has Single Sign-On (SSO).</template>
            <ul>
              <li>
                You're all set. Your next time accessing UpToDate you can use your
                enterprise email to sign in with SSO.
                <a id="tryNowLink"
                   :href="C_HTTP.DEFAULT_LOGIN"
                   @click="processLogout()">Try it now!</a>
              </li>
              <li>
                There will likely be a delay in updating your CME credits.
              </li>
              <li>
                You can <a id="mergeAccountsContinueLink"
                           :href="C_HTTP.DEFAULT_HOMEPAGE_PATH"
                           @click="closeModal">continue to UpToDate</a>,
                or if you want to fully refresh your account
                and see your updated CME credits now,
                <a id="mergeAccountsSignOutLink"
                   :href="C_HTTP.DEFAULT_LOGIN"
                   @click="processLogout()">sign out</a>
                and sign back in to UpToDate.
              </li>
            </ul>
          </utd-notification>
        </div>
        <div v-else class="next-steps__merge-step">
          <div>
            <a id="mergeBackLink" :href="C_HTTP.DEFAULT_LOGIN" @click="processLogout()">
              <i class="wkce-icon-chevron-left" />{{ $t('BACK') }}
            </a>
            <p class="ds1-utd-size-3">
              To add the ability to sign in with your enterprise email
              <span class="ds1-utd-weight-bold">{{ oidcLoginHint }}</span> via Single Sign-On,
              please access your existing UpToDate account.
            </p>
          </div>
          <form class="form-login jumpstart-form">
            <div class="next-steps__input">
              <h2>
                Enter your existing UpToDate account:
              </h2>
              <login-form-messaging v-if="mergeError"
                                    id="nextStepsMergeErrorNotification"
                                    @login-form-sign-out="processLogout"
                                    @login-form-forgot-credentials="openForgotPassword" />
              <fieldset class="next-steps__fieldset wk-js2">
                <utd-text-input class="utd-text-input" :label="$t('LOGIN.USER_NAME')">
                  <input id="nextStepsUserNameInput"
                         :value="oidcLoginHint"
                         readonly
                         class="wk-field-input"
                         type="username"
                         aria-label="Username">
                </utd-text-input>
                <utd-password-input :label="$t('LOGIN.PASSWORD')"
                                    @text-input-toggle-show="toggleVisibleInput">
                  <input id="nextStepsPasswordInput"
                         ref="nextStepsPasswordInputRef"
                         v-model="form.password"
                         :maxlength="maxPasswordFieldLength"
                         :type="inputType"
                         autofocus
                         class="wk-field-input"
                         aria-label="Password"
                         @keydown.enter="processSsoMerge">
                </utd-password-input>
                <div class="next-steps__help">
                  <a class="mergeAccountsForgotPasswordLink"
                     href="#"
                     @click="openForgotPassword">
                    <span class="form-link-text">Forgot password</span>
                    <i class="wk-icon wkce-icon-question-circle" />
                  </a>
                </div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
      <template #footer>
        <utd-button id="mergeAccountsBtn"
                    :disabled="isSubmitDisabled"
                    button-size="large"
                    @click="handleContinueClick">
          {{ mergeSuccess ? $t('LOGIN.CONTINUE_TO_UTD') : $t('LOGIN.CONTINUE') }}
        </utd-button>
      </template>
    </utd-modal-dialog>
    <forgot-password-modal ref="forgotPassword" @close-forgot-password-modal="openModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { C_EVENTS, C_HTTP, maxPasswordFieldLength } from '_acaSrc/utility/constants';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdPasswordInput from '_acaSrc/components/shared/input/UtdPasswordInput.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import PubSub from '_acaSrc/utility/PubSub';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import ForgotPasswordModal from './ForgotPasswordModal.vue';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import {
    SET_FORM_SUBMITTING,
    SET_NEXT_STEPS_FLOW,
    SET_MESSAGE_ERROR
} from '_acaSrc/store/login.store';
import LoginFormMessaging from '_acaSrc/components/login/LoginFormMessaging.vue';
import { SET_KEEP_ONE_ACCOUNT_REQUEST } from '_acaSrc/store/profile.store';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';

export default {
    components: {
        UtdTextInput,
        UtdPasswordInput,
        UtdButton,
        UtdNotification,
        ForgotPasswordModal,
        LoginFormMessaging,
        UtdModalDialog
    },
    data() {
        return {
            C_HTTP,
            inputType: 'password',
            maxPasswordFieldLength,
            mergeSuccess: false,
            mergeError: false,
            errorType: '',
            errorTitle: ''
        };
    },
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('profile', [ 'oidcLoginHint' ]),
        ...mapGetters('login', [ 'form', 'message' ]),
        isSubmitDisabled() {
            return !this.mergeSuccess && this.form.password === '';
        }
    },
    mounted() {
        new PubSub().subscribe(C_EVENTS.NEXT_STEPS_MERGE_CHECK, this.openModal);
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.NEXT_STEPS_MERGE_CHECK, this.openModal);
    },
    methods: {
        ...mapMutations('login', [ SET_FORM_SUBMITTING, SET_NEXT_STEPS_FLOW, SET_MESSAGE_ERROR ]),
        ...mapActions('login', [ 'ssoMerge' ]),
        ...mapMutations('login', [
            SET_FORM_SUBMITTING,
            SET_NEXT_STEPS_FLOW
        ]),
        ...mapMutations('profile', [ SET_KEEP_ONE_ACCOUNT_REQUEST ]),
        ...mapActions('login', [ 'loginUser' ]),
        toggleVisibleInput(hideText) {
            this.inputType = hideText ? 'password' : 'text';
        },
        handleContinueClick() {
            if (this.mergeSuccess) {
                this.navigateHome();
            }
            else {
                this.processSsoMerge();
            }
        },
        navigateHome() {
            this.router.url(C_HTTP.DEFAULT_HOMEPAGE_PATH);
            this.closeModal();
        },
        async processSsoMerge() {
            this.form.userName = this.oidcLoginHint;
            this.mergeError = false;
            this[SET_FORM_SUBMITTING](true);
            this[SET_NEXT_STEPS_FLOW](true);
            this[SET_MESSAGE_ERROR]('');
            await this.ssoMerge();
            if (!this.message.error) {
                this.mergeSuccess = true;
                this.mergeError = false;
            }
            else {
                this.mergeError = true;
                this.mergeSuccess = false;
            }
            this[SET_NEXT_STEPS_FLOW](false);
            this[SET_FORM_SUBMITTING](false);
        },
        async processLogout() {
            try {
                await utdRest('logout');
                this[SET_KEEP_ONE_ACCOUNT_REQUEST](false);
                this.closeModal();
            }
            catch {
                // No-op
            }
        },
        openModal() {
            this.$refs.modalDialog.open();
        },
        closeModal() {
            this.$refs.modalDialog.close();
        },
        openForgotPassword() {
            logUiClickEventHelper({
                uiElementName: 'nextSteps_mergeForgotPasswordLink'
            });
            this.$refs.forgotPassword.open();
            this.closeModal();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.next-steps-merge-accounts {
  .ds1-mt-2();
  .ds1-mh-2();
  .ds1-mb-3();
  max-width: 515px;
}

#nextStepsMergeNotification {
  .ds1-mb-4();

  ul {
    .ds1-pl-3();
  }
}

.next-steps__header {
  font-size: 22px;
}

.next-steps__fieldset {
  .ds1-pa-0();
  border: none;

  .utd-text-input {
    .ds1-mb-3();
  }
}

.next-steps__input {
  .ds1-mb-3();
}

.form-login.jumpstart-form {
  max-width: 420px;
}

.wk-field-password {
  .ds1-mb-1();
}

.next-steps__help {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  font-size: 14px;
}

.mergeAccountsForgotPasswordLink {
  vertical-align: middle;
}

#nextStepsMergeErrorNotification {
  .ds1-mb-3();
}
</style>