<template>
  <Teleport to="body" :disabled="disablePortal">
    <input ref="defaultFocus"
           class="visuallyHidden utd-modal-default-focus"
           readonly
           tabindex="-1"
           aria-hidden="true"
           inert>
    <transition name="fade">
      <div v-if="visible"
           class="utd-modal-overlay"
           :class="modalClasses"
           @click.self="onOverlayClick">
        <slot name="outerElements" />
        <div class="utd-modal-overlay__container" @click.self="onOverlayClick">
          <slot name="topLeft" />
          <div class="utd-modal-content">
            <slot />
          </div>
        </div>
      </div>
    </transition>
    <slot name="siblingElements" />
  </Teleport>
</template>

<script>
import { C_KEYCODES } from '_acaSrc/utility/constants';
import {
    disableTabbingForContainedElements,
    restoreTabbingForContainedElements
} from '_acaSrc/utility/DOM';

const { ESCAPE_KEYCODE } = C_KEYCODES;
const TRANSITION_ANIMATION_DURATION = 500;
const SCROLL_UNLOCK_THRESHOLD = 2;

export default {
    inheritAttrs: false,
    props: {
        // Allows for users to override default modal / dialog styling
        modalClasses: {
            type: String,
            default: ''
        },
        // By default, focuses on an element in the modal when opened, allowing
        // user to control the modal via keyboard. Important for accessibility.
        autofocus: {
            type: Boolean,
            default: true
        },
        // By default, allows user to close modal by clicking the overlay.
        closeOnOverlayClick: {
            type: Boolean,
            default: true
        },
        // By default, detects user hitting escape key to close modal. Important
        // for accessibility.
        closeOnEscape: {
            type: Boolean,
            default: true
        },
        // This should almost never be needed except in testing mode. Disables
        // vue-portal functionality.
        disablePortal: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'utd-modal-overlay-click', 'utd-modal-closing' ],
    data() {
        return {
            visible: false,
            overlayClickDebounce: false,
            windowListeners: [],
            tabData: []
        };
    },
    beforeUnmount() {
        this.removeListeners();
        if (this.utdUnlockScroll) {
            this.utdUnlockScroll();
        }
    },
    methods: {
        open() {
            this.visible = true;
            this.tabData = disableTabbingForContainedElements(document.querySelector('#appContainer'));
            return new Promise(resolve => {
                this.$nextTick(() => {
                    if (this.autofocus && this.$refs.defaultFocus) {
                        this.$refs.defaultFocus.focus();
                    }
                    if (this.hasKeyEvents()) {
                        this.registerWindowListener('keyup', this.onKeyUp);
                    }
                    if (this.utdLockScroll) {
                        this.utdLockScroll();
                    }
                    resolve();
                });
            });
        },
        close() {
            this.visible = false;
            restoreTabbingForContainedElements(this.tabData);
            this.removeListeners();
            const modalsRemaining = document.querySelectorAll('.utd-modal-overlay');
            const mobileNavBar = document.querySelector('.show-mobile-navbar');
            if (this.utdUnlockScroll
                && modalsRemaining.length < SCROLL_UNLOCK_THRESHOLD
                && !mobileNavBar) {
                this.utdUnlockScroll();
            }
        },
        onOverlayClick() {
            // ignore double clicks on overlay during transition animation
            if (!this.overlayClickDebounce && this.closeOnOverlayClick) {
                this.overlayClickDebounce = true;
                setTimeout(this.resetOverlayClickDebounce, TRANSITION_ANIMATION_DURATION);
                this.close();
                this.$emit('utd-modal-overlay-click');
            }
        },
        resetOverlayClickDebounce() {
            this.overlayClickDebounce = false;
        },
        hasKeyEvents() {
            return this.closeOnEscape;
        },
        onKeyUp(e) {
            const isEscapeKey = e.code === 'Escape' || e.keyCode === ESCAPE_KEYCODE;
            if (isEscapeKey && this.closeOnEscape) {
                this.close();
                this.$emit('utd-modal-closing');
            }
        },
        registerWindowListener(eventType, listener) {
            const boundListener = listener.bind(this);
            window.addEventListener(eventType, boundListener);
            this.windowListeners.push({ eventType, listener: boundListener });
        },
        removeListeners() {
            for (const { eventType, listener } of this.windowListeners) {
                window.removeEventListener(eventType, listener);
            }
            this.windowListeners = [];
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-DIALOG-TRANSITION-DURATION: @DS1-UTD-TRANSITION-DURATION;
@UTD-DIALOG-TRANSPARENCY: @DS1-UTD-TRANSPARENCY;
@UTD-MODAL-CONTENT-BG-COLOR: @DS1-UTD-PRIMARY-BG-COLOR;
@UTD-BG-MODAL-HIDDEN: @DS1-UTD-FULL-TRANSPARENCY;

.utd-modal-overlay,
.utd-modal-default-focus {
  position: fixed;
}

.utd-modal-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: @UTD-DIALOG-TRANSPARENCY;
  z-index: @ZINDEX-MODAL-OVERLAY;

  &__container {
    position: relative;
    overflow: visible;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.utd-modal-overlay.hide-overlay {
  background-color: @UTD-BG-MODAL-HIDDEN;
}

@media only screen and (max-width: 768px) {
  .utd-modal-overlay.show-mobile-navbar {
    top: 56px;
  }
}

.utd-modal-content {
  .ds1-mvw-4();
  background-color: @UTD-MODAL-CONTENT-BG-COLOR;
}

.fade-enter-active,
.fade-leave-active {
  /* If speed is changed, update TRANSITION_ANIMATION_DURATION above */
  transition: opacity @UTD-DIALOG-TRANSITION-DURATION;
}

.InternetExplorer {
  .fade-enter-active,
  .fade-leave-active {
    opacity: 1;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.media-modal-dialog {
  :deep(.utd-dialog-frame) {
    width: 664px;
    margin-bottom: 0;
  }
}
</style>
