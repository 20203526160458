<!-- eslint-disable max-len -->
<template>
  <svg width="15"
       height="16"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       :class="{'solid-fill': isSolid}"
  >
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.40005 11.2C1.00005 11.6 0.800049 12.2 0.800049 12.8C0.800049 14.1 1.90005 15.2 3.20005 15.2H10.4C9.90005 16.5 9.60005 18.6 9.60005 20.8C9.60005 22.3 10.9 23.2 12 23.2C13.4 23.2 14.4 22.7 14.4 20.8C14.4 17.9 16.3 14.9 20 13.6H23.2V2.40005H20C18.2001 2.40005 17.6 1.90005 13.6 0.800049H4.00005C2.70005 0.800049 1.60005 1.90005 1.60005 3.20005C1.60005 3.60005 1.70005 4.00005 1.90005 4.40005C1.20005 4.80005 0.800049 5.60005 0.800049 6.40005C0.800049 7.00005 1.00005 7.60005 1.40005 8.00005C1.00005 8.40005 0.800049 9.00005 0.800049 9.60005C0.800049 10.2 1.00005 10.8 1.40005 11.2Z"
          fill="currentColor"
          stroke="black"
          stroke-width="1.6"
    />
  </svg>
</template>

<script>
export default {
    props: {
        isSolid: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  svg {
    width: 15px;
    height: 15px;
    overflow: visible;
  
    > path {
      color: @WKCE-WHITE;
      transition: color 125ms ease-in, stroke 125ms ease-in;

      &:hover {
          color: @WKCE-BLUE-SHADE1;
          stroke: @WKCE-BLUE-SHADE1;
          cursor: pointer;
      }
    }

    &.solid-fill > path {
      color: @WKCE-BLUE-SHADE1;
      stroke: @WKCE-BLUE-SHADE1;
    }
  }
</style>