import { getDocument } from '_acaSrc/utility/DOM';

const HIDDEN_FORMULARY_CLASS = 'hide-formulary-item';
const SHOW_MORE_BTN_CLASS = 'show-more-formulary-items-btn';
const FORMULARY_LIST_CLASS = 'formulary-list';
const FORMULARY_ITEM_CLASS = 'formulary-item';

export const collapseFormulariesList = () => {
    const accounts = getDocument().querySelectorAll(`.${FORMULARY_LIST_CLASS}`);

    accounts.forEach(accountItem => {
        const formularies = accountItem.querySelectorAll(`.${FORMULARY_ITEM_CLASS}`);
        if (formularies.length > 3) {
            for (let index = 3; index < formularies.length; index++) {
                formularies[index].classList.add(HIDDEN_FORMULARY_CLASS);
            }
            attachClickHandlersShowMoreButtons();
        }
    });
};

export const attachClickHandlersShowMoreButtons = () => {
    getDocument().querySelectorAll(`.${SHOW_MORE_BTN_CLASS}`).forEach(el => {
        el.addEventListener('click', onClickShowMore);
    });
};

export const onClickShowMore = event => {
    const buttonElement = event && event.target;
    const formularies = buttonElement.closest(`.${FORMULARY_LIST_CLASS}`);
    if (!formularies) {
        return;
    }

    formularies.querySelectorAll(`.${HIDDEN_FORMULARY_CLASS}`).forEach(showFormulary);
    removeShowMoreButton(buttonElement);
};

export const showFormulary = element => {
    element.classList.remove(HIDDEN_FORMULARY_CLASS);
};

export const removeShowMoreButton = buttonEl => {
    const buttonContainer = buttonEl.closest('li');
    buttonContainer && buttonContainer.parentNode.removeChild(buttonContainer);
};
