import { C_QAP } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper, setQueryParamValue } from '_acaSrc/utility/http';
import QapDto from './qap.dto';
import { cloneDeep } from 'lodash';


// Data transformation object class for Pathways QAP (Quick Access Panel)
// ----------------------------------------------------------------------
// This DTO differs from other QAP types in that the search results can
// contain Pathways QAP entries in any result. While other QAP types will
// have a single result contain all of that specific QAP data.

// For example, searching on "copd" will return a result with 5 Pathways QAP entries.
// The total number of results returned is 15. Ten of which are non-QAP results.
// The Pathways QAP are contained in results numbered 3 through 8.
//
// Other QAP types will have their multiple entries contained within a single
// search result entry.
//
// This makes this particular DTO slightly more complex as it has to retain the first
// Pathways QAP encountered while processing the search results, then append to that
// for QAP for additional Pathway entries encountered.
//
// The first Pathway QAP result encountered will initialize this class and go through
// the _setPathwayContents() path. Subsequent Pathway QAP results will execute the member
// method "processNextPathway()" from the first Pathway QAP object.
//
// [Class Properties]
//  :"this"
//  - Contains the data properties used to display the desktop "Right Side" QAP.
//
//  :"result"
//  - Contains the data properties used to render the "inline" QAP for mobile results.
//
// Both "this" and "result" share similar properties, but will differ in the URL
// query parameters present in their URLs. Specifically for "display_rank" and "source".
//
// :this.firstResult
// - Maintains reference to first Pathways QAP result.
//   Assigned in constructor, referenced in processNextPathway()
//
// :retainOriginalTitle
// - If present and true, will bypass custom title processing in qap.store action.
//   This is used by Pathways QAP to retain title assigned in the result unmodified.
//
// :type
// - Set by parent class to simply "QAP". Assignment overrides original QAP templating.
//
// :subtype
// - Set to constant "PATHWAYS". Controls template rendering.
//
// :panelType
// :qapContents
// - See "QAP Property Definitions" section of Confluence page:
//   https://confluence.ce.wolterskluwer.io/display/WAT/Converting+existing+QAPs+into+new+framework

/**
 * @typedef {object} QapSectionItem
 * @property {string} url
 * @property {string} label
 * @property {string} detail
 */
/**
 * @typedef {object} QapSection
 * @property {string} type
 * @property {Array.<QapSectionItem>} items
 */

/**
 * @typedef {object} QapContent
 * @property {Array.<QapSection>} sections
 */

/**
 * @typedef {object} InlineSearchResult
 * @property {string} panelType
 * @property {string} subtype
 * @property {Array.<QapContent>} qapContents
 * @property {boolean} isPanelResult
 * @property {boolean} hideInResults
 */

/**
 * @typedef {object} PathwayDto
 * @property {string} panelType - See constants.js C_QAP.PANEL - will be "PATHWAYS" for this object
 * @property {string} subtype - Set identical to panelType to bypass original processing
 * @property {object} firstResult - Maintains pointer to first Pathways QAP result
 * @property {Array.<QapContent>} qapContents - Array of QAP content objects
 * @property {InlineSearchResult} result - The inline search result
 */
export default class PathwayDto extends QapDto {
    /**
     * Instantiate PathwayDTO object
     * @constructor
     * @param {object} payload - Individual result from search results containing Pathway QAP data
     */
    constructor(payload) {
        // Ensure we maintain the Pathways title "as-is" from the result.
        payload.retainOriginalTitle = true;
        super(payload);

        const { result, rankIndex } = payload;

        result.panelType = C_QAP.PANEL.PATHWAYS;
        result.subtype = result.panelType;

        this.panelType = result.panelType;
        this.subtype = result.subtype;
        this.firstResult = result;

        this._setPathwayContents(result, rankIndex);
        this._setQapPanelItem(result, rankIndex, C_QAP.SECTION.RX_TRANSITIONS);

        result.qapContents = cloneDeep(this.qapContents);

        this.qapContents[0].sections[0].items.forEach(item => {
            item.url = setSearchUrlParamsHelper(result.url, { rankIndex: 1 });
            item.url = setQueryParamValue(item.url, 'source', 'panel_search_result');
        });
    }

    _setPathwayContents(result, rankIndex) {
        const { title, customizedBy } = result;

        const url = setSearchUrlParamsHelper(result.url, { rankIndex });

        this.qapContents = [{
            sections: [{
                type: 'links',
                items: [{
                    url,
                    label: title,
                    detail: customizedBy
                }]
            }]
        }];
    }

    processNextPathway(payload) {
        const { result, rankIndex } = payload;
        const { title, customizedBy } = result;

        const resultUrl = setSearchUrlParamsHelper(result.url, { rankIndex });
        let panelUrl = setSearchUrlParamsHelper(result.url, {
            rankIndex: this.qapContents[0].sections[0].items.length + 1
        });
        panelUrl = setQueryParamValue(panelUrl, 'source', 'panel_search_result');

        this.qapContents[0].sections[0].items.push({
            url: panelUrl,
            label: title,
            detail: customizedBy
        });

        // Turns off "classic" AngularJS QAP templating
        result.isPanelResult = false;

        // Prevent result from displaying in normal result stream
        result.hideInResults = true;

        // Update the first pathway QAP result
        this.firstResult.qapContents[0].sections[0].items.push({
            url: resultUrl,
            label: title,
            detail: customizedBy
        });
    }
}
