<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="two-accounts__content ds1-utd-js2">
    <form id="validatePasswordForm"
          class="form-login jumpstart-form"
          name="validatePasswordForm">
      <div class="two-accounts__disclaimer">
        <p class="ds1-mt-0 ds1-utd-size-3">
          To help you keep your original account, you need to update your username so you can use
          <span class="ds1-utd-weight-bold two-accounts_email">{{ form.userName }}</span>
          to sign in to UpToDate through Single Sign-On (SSO).
          The fields below guide you through that.
        </p>
      </div>
      <div class="two-accounts__action">
        <h3 class="two-accounts__header">
          Enter your Password
        </h3>
        <a id="nextStepsBackLink" href="#" @click="$emit('close-modal')">
          <i class="wkce-icon-chevron-left" />
          {{ $t('BACK') }}
        </a>
        <p class="two-accounts__subtext ds1-utd-weight-bold">
          To continue, please enter the password for your existing account:
        </p>
        <fieldset class="two-accounts__input-fields wk-js2">
          <utd-notification v-if="message.error"
                            id="serverError"
                            tabindex="0"
                            class="two-accounts__notification"
                            notification-mode="error">
            <template #title>{{ getErrorTitle() }}</template>
            <span v-html="getErrorContent()" />
          </utd-notification>
          <utd-text-input class="utd-text-input" :label="$t('LOGIN.ORIGINAL_USERNAME')">
            <input id="nextStepsUserNameInput"
                   :value="form.userName"
                   readonly
                   class="wk-field-input"
                   type="username">
          </utd-text-input>
          <utd-password-input class="utd-password-input"
                              :label="$t('LOGIN.PASSWORD')"
                              @text-input-toggle-show="toggleVisibleInput">
            <input id="nextStepsPasswordInput"
                   v-model="form.password"
                   :maxlength="maxPasswordFieldLength"
                   :type="inputType"
                   autofocus
                   class="wk-field-input">
          </utd-password-input>
          <div class="two-accounts__help">
            <a href="#"
               @click="openForgotCredentials">
              Forgot password
              <i class="wkce-icon-question-circle" />
            </a>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdPasswordInput from '_acaSrc/components/shared/input/UtdPasswordInput.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import { C_ACCOUNT, maxPasswordFieldLength } from '_acaSrc/utility/constants';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

export default {
    components: {
        UtdTextInput,
        UtdPasswordInput,
        UtdNotification
    },
    emits: [ 'forgot-password', 'close-modal' ],
    data() {
        return {
            inputType: 'password',
            maxPasswordFieldLength
        };
    },
    computed: {
        ...mapGetters('login', [
            'form',
            'message'
        ])
    },
    methods: {
        toggleVisibleInput(hideText) {
            this.inputType = hideText ? 'password' : 'text';
        },
        getErrorTitle() {
            return this.message.error === C_ACCOUNT.FORM_MESSAGING.WAYF_GENERIC_ERROR_ID
                ? this.$t('LOGIN.INCORRECT_PASSWORD') : this.message.title;
        },
        getErrorContent() {
            return this.message.error === C_ACCOUNT.FORM_MESSAGING.WAYF_GENERIC_ERROR_ID
                ? this.$t('LOGIN.WAYF_INCORRECT_PASSWORD') : this.message.error;
        },
        openForgotCredentials() {
            logUiClickEventHelper({
                uiElementName: 'twoAccounts_forgotCredentials'
            });
            this.$emit('forgot-password');
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@NEXT-STEPS-PRIMARY-BG-COLOR: @WKCE-BLUE-TINT6;

.two-accounts__header {
  .ds1-utd-weight-bold();
  font-size: 22px;
}

.two-accounts__notification {
  .ds1-mb-2();
  .ds1-mt-0();
}

.two-accounts__input-fields {
  .ds1-pa-0();
  border: none;
  max-width: 400px;

  .utd-text-input {
    .ds1-mb-3();
  }

  .utd-password-input {
    margin-bottom: 11px;
  }
}

.two-accounts__action {
  .ds1-mb-4();
}

.two-accounts__subtext {
  .ds1-utd-size-3();
}

.two-accounts__help {
  .ds1-utd-size-2();
  display: flex;
  justify-content: flex-end;
}

.two-accounts_email {
  display: inline-block;
}
</style>