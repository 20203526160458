<template>
  <div v-if="loggedUser && myUpToDateWidget"
       id="my-uptodate-widget">
    <my-utd-tabbed-content :source="'_widget'" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MyUtdTabbedContent from '_acaSrc/components/myuptodate/MyUtdTabbedContent.vue';

export default {
    components: {
        MyUtdTabbedContent
    },
    computed: {
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        loggedUser() {
            return this.userLoggedIn;
        },
        myUpToDateWidget() {
            return this.permissions.myUpToDateWidget;
        }
    }
};
</script>