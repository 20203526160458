<template>
  <div id="my-account-mobile">
    <my-utd-tabbed-content :source="'_mobile'" />
  </div>
</template>
<script>
import MyUtdTabbedContent from '_acaSrc/components/myuptodate/MyUtdTabbedContent.vue';

export default {
    components: {
        MyUtdTabbedContent
    }
};
</script>