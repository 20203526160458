<template>
  <utd-toolbar-back-link :show-back-button="showBackButton" />
</template>

<script>
import UtdToolbarBackLink from '_acaSrc/components/shared/utd/UtdToolbarBackLink.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdToolbarBackLink
    },
    props: {
        checkServerCondition: Boolean
    },
    computed: {
        ...mapGetters('app', [ 'isAlwaysShowBackButton' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        showBackButton() {
            return this.checkServerCondition ? this.isAlwaysShowBackButton : this.isDesktopView;
        }
    }
};
</script>