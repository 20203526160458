<template>
  <div id="fontSize">
    <a ref="fontSizeIcon"
       v-hover-delay="{ afterDelay: showDesktopFontView,
                        afterUnhoverDelay: hideDesktopFontView,
                        sharedHoverState: 'desktopFontSize' }"
       class="desktopFontIcon"
       @click="toggleDesktopFontView" />
    <nav v-show="isMobile || menuVisibleDesktop"
         ref="fontMenu"
         v-hover-delay="{ afterDelay: showDesktopFontView,
                          afterUnhoverDelay: hideDesktopFontView,
                          sharedHoverState: 'desktopFontSize' }">
      <span>{{ $t('CONTENT.FONT_SIZE') }}</span>
      <ul>
        <li class="wk-icon-filled-check"
            :class="{'selected': size === 'textSize-S'}">
          <a @click="setTextSize('textSize-S')">{{ $t('CONTENT.SMALL') }}</a>
        </li>
        <li class="wk-icon-filled-check"
            :class="{'selected': size ==='textSize-M'}">
          <a @click="setTextSize('textSize-M')">{{ $t('CONTENT.NORMAL') }}</a>
        </li>
        <li class="wk-icon-filled-check"
            :class="{'selected': size === 'textSize-L'}">
          <a @click="setTextSize('textSize-L')">{{ $t('CONTENT.LARGE') }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import HoverDelay from '_acaSrc/directives/UtdHoverDelay.directive';
import { createPositionManager } from '_acaSrc/utility/DOM';
import { dispatchEvent } from '_acaSrc/utility/Events';
import { SET_IS_FIT_DIALOG_SHOWN,
    SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE,
    SET_TOOLBAR_OVERFLOW_VISIBLE } from '_acaSrc/store/topic/topic.store';
import PubSub from '_acaSrc/utility/PubSub';

export default {
    directives: {
        HoverDelay
    },
    data() {
        return {
            size: null,
            menuVisibleDesktop: false
        };
    },
    computed: {
        ...mapGetters('app', [
            'isDesktopBrowser'
        ]),
        ...mapGetters('topic', [
            'fontSizeVisible',
            'topicFontSize'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isNotDesktopView' ]),
        isMobile() {
            return this.isNotDesktopView;
        }
    },
    watch: {
        menuVisibleDesktop() {
            this.$nextTick(() => {
                if (this.isDesktopView && this.positionManager) {
                    this.positionManager.update();
                }
            });
        },
        isMobile() {
            // Remove position fixed upon switch to mobile layout
            this.isMobile
                && this.positionManager
                && this.positionManager.reset();
        }
    },
    mounted() {
        if (this.isDesktopBrowser) {
            this.positionManager
                = createPositionManager(this.$refs.fontSizeIcon, this.$refs.fontMenu);
        }
        if (this.positionManager && this.isNotDesktopView) {
            this.positionManager.reset();
        }
    },
    created() {
        this.size = this.topicFontSize;
    },
    methods: {
        ...mapActions('app', [ 'evalBodyScrollLock' ]),
        ...mapMutations('topic', [
            SET_TOOLBAR_OVERFLOW_VISIBLE,
            SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE,
            SET_IS_FIT_DIALOG_SHOWN ]),
        ...mapActions('topic', [ 'setTopicFontSize' ]),
        toggleDesktopFontView() {
            this[SET_IS_FIT_DIALOG_SHOWN](false);
            this[SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE](false);

            if (this.isDesktopView) {
                this.menuVisibleDesktop = !this.menuVisibleDesktop;
            }
        },
        showDesktopFontView() {
            this[SET_IS_FIT_DIALOG_SHOWN](false);
            this[SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE](false);
            this.menuVisibleDesktop = true;
        },
        hideDesktopFontView() {
            this.menuVisibleDesktop = false;
        },
        setTextSize(size) {
            this.size = size;
            new PubSub().publish('wkutd.close-toolbar-menus');
            this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
            this.menuVisibleDesktop = false;
            this.evalBodyScrollLock();
            this.setTopicFontSize(size);
            new PubSub().publish('topic:font-size-changed');
            dispatchEvent('resize');
        }
    }
};
</script>

<style scoped lang="less">
// TODO - This css is very messy and should be refactored during the next UI update.

@import (reference) "~_acaAssets/global/variables";
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#overflowTopicDropdown .desktopFontIcon {
  display: none;
}

@media only screen and (min-width: 768px) {
  .isDesktop #topic-toolbar #topic-tools #fontSize {
    display: inline-block;

    .desktopFontIcon {
      display: inline-block;
      width: 24px;
      height: 36px;

      &::before {
        .ds1-utd-font-size();
        display: inline-block;
        position: relative;
        top: 2px;
      }
    }

    nav {
      position: fixed;
      height: 120px;
      width: 160px;
      top: 132px;
      background-color: @WK_UTD_WHITE_100;
      border: solid 1px @UTD_INACTIVE_GRAY;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      z-index: 1001;
      right: 30px;

      .fixedToolbar& {
        top: 92px;
      }

      span {
        display: none;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          display: block;
          width: 142px;
          padding-right: 0;
          margin-left: 0;
          padding-left: 18px;

          &:hover {
            background-color: @UTD_TINT_6;
          }

          &.selected {
            a {
              padding-right: 0;
              padding-left: 17px;
            }
          }

          a {
            width: 90px;
            text-align: left;
            padding-left: 32px;
            display: inline-block;
            font-size: 14px;
            color: @WK_UTD_GRAY_75;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
