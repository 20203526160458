/* eslint-disable quote-props */
/* eslint-disable quotes */
const localizationEn = {
    "BAHASA_INDONESIAN": "Bahasa Indonesia",
    "SIMPLIFIED_CHINESE": "简体中文",
    "TRADITIONAL_CHINESE": "繁體中文",
    "DUTCH": "Nederlands",
    "ENGLISH": "English",
    "FRENCH": "Français",
    "GERMAN": "Deutsch",
    "ITALIAN": "Italiano",
    "JAPANESE": "日本語",
    "KOREAN": "한국어",
    "POLISH": "Polski",
    "PORTUGESE": "Português",
    "RUSSIAN": "Русский",
    "SPANISH": "Español",
    "TURKISH": "Türkçe",
    "VIETNAMESE": "Tiếng Việt",
    "HOME": "Home",
    "BETA": "BETA",
    "BACK": "Back",
    "CLOSE": "Close",
    "OK": "OK",
    "CANCEL": "Cancel",
    "SAVE": "Save",
    "PLEASE_WAIT": "Please wait",
    "LOADING": "Loading",
    "RATE": "Rate",
    "YES": "Yes",
    "NO": "No",
    "AND": " and ",
    "SHOW": "Show",
    "EXPAND": "Expand",
    "HIDE": "Hide",
    "COLLAPSE": "Collapse",
    "CME": "Continuing Medical Education",
    "CE": "Continuing Education",
    "CPD": "Continuing Professional Development",
    "MOC": "Maintenance of Certification",
    "FORMS": {
        "SUBMIT": "Submit",
        "SEND_NEW_CODE": "Send new code",
        "ENTER_SECURITY_CODE": "Enter Security Code"
    },
    "GRAPHICS": {
        "EXPAND_RELATED_CONTENT": "Expand related content",
        "EXPAND_GRAPHICS_IN_THIS_TOPIC": "Expand graphics in this topic",
        "EXPAND_APPEARS_IN_TOPICS": "Expand appears in topics"
    },
    "ERRORS": {
        "TOPIC_ERROR": "Error loading this topic, please try again.",
        "RESPONSE_400": "This request is not valid",
        "COOKIES_DISABLED": "<p>It would appear you have cookies disabled.</p><p>This application requires cookies to be enabled in order to function correctly.</p><p>Please enable cookies in your browser before proceeding, then reload this page.</p>",
        "CONTACT_ON_ERROR": "Please try again. If the problem persists, contact <a href=\"/home/contact-us\" target=\"_blank\">customer support</a>.",
        "ERROR_OCCURRED": "An error has occurred",
        "PLEASE_TRY_AGAIN": "Please try again",
        "INLINE_REF_WARNING": "One or more references could not be displayed. The full list will be available soon.",
        "INLINE_REF_ERROR": "References could not be displayed. The full list will be available soon.",
        "VERIFICATION_CODE_ERROR": "Verification code was not sent. Please correct errors and click \"Send Verification Code\".",
        "SUBMISSION_GENERAL_ERROR": "There was an error during submission. Please review the form, then resubmit."
    },
    "SEARCH": {
        "SEARCH_UPTODATE": "Search UpToDate",
        "SEARCH_SUGGESTIONS": "Search Suggestions",
        "SEARCH_RESULTS": "Search Results",
        "NO_TOPIC_RESULTS": "No results were found containing {{search}}.",
        "NO_GRAPHIC_RESULTS": "No graphics were found containing {{search}}.",
        "NO_RESULTS_FOUND": "No results found",
        "NO_HL7_RESULTS": "No results were found matching your search query.",
        "ERROR_MESSAGE": "Error obtaining search results {{error}}",
        "MEANS_1": "means",
        "MEANS_2": "",
        "CLICK_ALTERNATIVE_TERM": "Click alternative term:",
        "CLICK_ON_WHAT_YOU_MEANT_BY": "Click on what you meant by {{term}}:",
        "CLICK_RELATED_TERM_FOR": "Click related term for {{term}}:",
        "COLLAPSE_RESULTS": "Collapse Results",
        "EXPAND_RESULTS": "Expand Results",
        "HIDE_TOPIC_OUTLINE": "Hide Topic Outline",
        "SHOW_TOPIC_OUTLINE": "Show Topic Outline",
        "DID_YOU_MEAN": "Did you mean:",
        "SEARCH_INSTEAD": "Search instead: ",
        "SHOWING_RESULTS_FOR": "Showing results for {{-results}}",
        "SEARCH_RESULTS_FOR": "Showing results for <strong>{{search}}</strong>",
        "SEARCH_RESULTS_FOR_REPLACEMENT": "Showing results for <strong>{{searchMessageText}}</strong> (instead of <i>{{search}}</i>)",
        "SEARCH_RESULTS_FOR_RELAUNCH": "Showing results for <strong>{{searchMessageText}}</strong>; No results for <i>{{search}}</i>",
        "SEARCH_RESULTS_FOR_RELAUNCH_QUOTES": "Showing results for <strong>{{searchMessageText}}</strong>; No results for <i>{{search}}</i> (with quotes)",
        "HL7_RESULTS_FOR": "<strong>Search Results {{search}}</strong>",
        "SHOW_MORE_RESULTS": "Show More Results",
        "CLICK_HERE_SEARCH": "Click <a href=\"{{search_link}}\"> here</a>",
        "TO_SEARCH_GRAPHICS_FOR_THAT_TERM": " to search graphics for that term.",
        "TO_SEARCH_TOPICS_FOR_THAT_TERM": "to search topics for that term.",
        "ALL_TOPICS": "All",
        "ADULT": "Adult",
        "PEDIATRIC": "Pediatric",
        "PATIENT": "Patient",
        "GRAPHICS": "Graphics",
        "GENERAL": "General",
        "CHINESE_DRUG_INFO": "Chinese Drug Info",
        "FOREIGN_DRUG_INFO": "Foreign Drug Info",
        "SHOW_MORE": "Show More",
        "VIEW_FULL_TOPIC": "View Full Topic",
        "SHOW_GRAPHICS": "Show Graphics ({{count}})",
        "QUICK_ACCESS": "Quick access for {{search}}",
        "DRUG_INFORMATION_FOR": "Drug Information for {{search}}",
        "SEARCH_PANEL_LAB_SNIPPET": "Guidance for initial evaluation of an abnormal test result",
        "SEARCH_PANEL_ICG_SNIPPET": "Interactive decision support",
        "SEARCH_PANEL_ICG_GUIDELINES": "Use this Pathway to:",
        "SEARCH_PANEL_VIEW_ALGO": "View full size graphic",
        "MAIN_SEARCH_RESULT_EMPTY": "All available search results are displayed in the panel.",
        "UPTODATE_PATHWAYS": "UpToDate Pathways",
        "LAB_INTERPRETATION": "Lab Interpretation",
        "PRACTICE_CHANGE_UPDATE": "Practice Changing UpDates",
        "NO_COUNTRY_MATCH": "No country match available for this drug."
    },
    "MISC": {
        "LOG_IN": "Sign in"
    },
    "LOGIN": {
        "CONTINUE_WITH_SEARCH": "Continue with your search",
        "MAXIMUM_ATTEMPTS": "Maximum attempts",
        "LOG_IN": "Sign in",
        "CONTINUE": "Continue",
        "CONTINUE_TO_UTD": "Continue to UpToDate",
        "LOG_IN_USING_INSTITUTION": "Sign in with",
        "USER_NAME": "Username",
        "PASSWORD": "Password",
        "EMAIL_ADDRESS": "Email Address",
        "OFFERS_A_NUMBER_OF_SUBSCRIPTIONS": "UpToDate offers a number of subscriptions and add-on products, allowing you to have the most up-to-date information and improve patient care.",
        "SEND_REQUEST": "Send Request",
        "SEND_USER_NAME": "Send User Name",
        "REMEMBER_ME": "Remember me",
        "REGISTER": "Register",
        "SHIBBOLETH": "Shibboleth",
        "MICROSOFT": "Microsoft",
        "NEXT_STEPS": "Next Steps for Sign in",
        "NEXT_STEPS_TWO_ACCOUNTS": "Your UpToDate Next Steps for Sign in",
        "ORIGINAL_USERNAME": "Original Username",
        "FORGOT_USERNAME": "Forgot username",
        "SIGN_IN_HELP": "Forgot username or password",
        "SIGN_IN_EXPLAINER": "Need help signing in",
        "SIGN_OUT": "Sign out",
        "INCORRECT_LOGIN": "Incorrect Sign-in",
        "INCORRECT_PASSWORD": "Incorrect Password",
        "SIGN_IN_ANOTHER_WAY": "Sign in Another Way",
        "TWO_FACTOR_INTRO": "In order to sign in to UpToDate, please check your phone for the six digit security code.",
        "LOGIN_HELP_CONTACT": "For further assistance, contact us at:<br /><a class=\"form-footer-link\" href=\"mailto:customerservice@uptodate.com\">customerservice@uptodate.com</a><br/><a href=\"tel://1-800-998-6374\" class=\"form-footer-link\">1-800-998-6374</a> (US & Canada)<br/>Or <a href=\"tel://+1-781-392-2000\" class=\"form-footer-link\">+1-781-392-2000</a></br>Monday through Friday<br/>7 AM to 9 PM ET",
        "LOGIN_HELP_CONTACT_UCC": "Email <a href=\"mailto:DL-UTD-ChinaSupport@uptodate.com\" class=\"form-footer-link\">DL-UTD-ChinaSupport@uptodate.com</a></br> or you can call at</br><a href=\"tel://400-886-7266\" class=\"form-footer-link\">400-886-7266</a> (weekdays 9:00-17:30)",
        "REGISTER_NOW": "Register Now",
        "RESET_PASSWORD_INTRO": "Forgot Username or Password?",
        "RESET_PASSWORD_INSTRUCTIONS": "Enter the email address associated with your UpToDate account in the space below and click \"Send Request.\"",
        "RESET_PASSWORD_ERROR": "Email or Username must be specified.",
        "RESET_PASSWORD_EMAIL_SENT": "Email Sent to You",
        "ABOUT_LINK": "About Us",
        "ENTERPRISE_EXPIRED_DOMESTIC_TITLE": "Domestic Sales (US & Canada):",
        "ENTERPRISE_EXPIRED_EMAIL": "Email:",
        "ENTERPRISE_EXPIRED_DOMESTIC_CONTACT_PHONE": "Phone: <a href=\"tel://1-888-550-4788\"> 1-888-550-4788</a> or <a href=\"tel://+1-781-392-2920\">+1-781-392-2920</a>",
        "ENTERPRISE_EXPIRED_DOMESTIC_CONTACT_FAX": "Fax: +1-781-642-8890",
        "ENTERPRISE_EXPIRED_INTERNATIONAL_TITLE": "International Sales:",
        "ENTERPRISE_EXPIRED_INTERNATIONAL_CONTACT_PHONE": "Phone: +31 172 641440",
        "ENTERPRISE_EXPIRED_INTERNATIONAL_CONTACT_FAX": "Fax: +31 172 641486",
        "IMPORTANT_NEWS_AND_INFORMATION": "Important News and Information.",
        "MORE_OPTIONS": "More Options",
        "OPEN_ATHENS": "OpenAthens",
        "READ_ABOUT_UPTODATE_IN_THE_NEWS": "Read about <a href=\"/home/uptodate-news\" target=\"_blank\">UpToDate in the News</a>.",
        "REGISTER_FOR_AN_ACCOUNT": "Make the most of your UpToDate experience: Register for an account and benefit from mobile access to our trusted clinical content. Plus, earn and redeem <span>{{value}}</span> credits while you work.",
        "FOLLOW_UPDATE_ON_FACEBOOK_OR_TWITTER": "Follow UpToDate on <a href=\"https://www.facebook.com/UpToDateEBM\" target=\"_blank\">Facebook</a> or <a href=\"https://twitter.com/UpToDate\" target=\"_blank\">Twitter</a>.",
        "CHECK_PRACTICE_CHANGING_UPDATES": "Check <a href=\"/contents/practice-changing-updates?source=login&selectedTitle=1~1\">Practice Changing UpDates</a>.",
        "USERNAME_EMAIL_REQUIRED": "Your Username field needs a value.",
        "SSO_SEARCH_HELPER": "Search for your Organization or Provider to Sign in",
        "WAYF_GENERIC_ERROR": "<ol style=\"padding-left:22px\"><li>Please check the spelling of your Username and Password.</li>"
            + "<li>If you have entered your Email but you have a Username, use that instead.</li><li>If you forgot your Username or Password, <a href=\"/account/reset-password\" class=\"login-form-link\" track=\"enabled\">click here</a></li></ol>",
        "WAYF_INCORRECT_PASSWORD": "<ol style=\"padding-left:22px\"><li>Please check the spelling of your Password.</li>"
            + "<li>If you forgot your Password, <a href=\"/account/reset-password\" class=\"login-form-link\" track=\"enabled\">click here</a></li></ol>",
        "SSO_LOADING_MESSAGE": "Connecting you to your home sign-in page",
        "FOR_FURTHER_ASSISTANCE": "For further assistance, contact UpToDate Customer Service in the following ways:",
        "PHONE_AVAILABILITY_UCC": "Available Monday through Friday, 9:00-17:30",
        "PHONE_AVAILABILITY_UTD": "Or Monday through Friday, 7 AM to 9 PM ET at:"
    },
    "MFA": {
        "CONTACT_INFO": "For questions or assistance, please contact UpToDate Compliance.",
        "EMAIL": "Email: CS-UTD-Compliance-UpToDate@wolterskluwer.com",
        "PHONE": "1-781-392-3931 (from within the U.S.)",
        "PHONE_OUTSIDE_US": "From outside of the U.S.:\r\nDial the international access code (00 in many countries), the U.S. country code (1), and the last 7 digits of our local phone number shown above (392-3931).",
        "CS_CONTACT_INFO": "UpToDate Customer Service",
        "CS_ADDRESS": "Wolters Kluwer Health<br>230 Third Avenue<br>Waltham, MA 02451",
        "CS_PHONE": "1.800.998.6374 (US & Canada) tel.",
        "CS_PHONE_OUTSIDE_US": "+1.781.392.2000 (all other countries) tel.",
        "CS_EMAIL": "customerservice@uptodate.com"
    },
    "CONTENT": {
        "COPYRIGHT_TEXT": ", Inc. and/or its affiliates. All Rights Reserved.",
        "TOOLS_PRINT": "Print",
        "TOOLS_EMAIL": "Email",
        "TOOLS_PATIENT": "Patient",
        "GRAPHICS_FOR": "Graphics for: ",
        "APPEARS_IN_TOPICS": "Appears in topics",
        "PLEASE_VIEW_GRAPHICS": "Please view graphics in the context of the topic in which they appear below.",
        "PRINT_OPTIONS": "Print Options",
        "PRINT_TEXT": "Text",
        "PRINT_REFERENCES": "References",
        "PRINT_GRAPHICS": "Graphics",
        "PRINT_DISCLOSURES": "Contributor Disclosures",
        "EXPORT_TO_POWERPOINT": "Export to Powerpoint",
        "DOWNLOAD_FOR_POWERPOINT": "Download for PowerPoint",
        "FULL_VIEW": "Full View",
        "PRACTICE_CHANGING_UPDATE": "Practice Changing UpDate",
        "VIEW_TOPIC": "View Topic",
        "VIEW_OUTLINE": "View Outline",
        "TOOLS": "Tools",
        "SMALL": "Small",
        "NORMAL": "Normal",
        "LARGE": "Large",
        "FONT_SIZE": "Font Size",
        "TOPIC_NOT_AVAILABLE": "Topic Not Available",
        "PHARMA_LIST": "Pharma List"
    },
    "HEADER": {
        "DRUG_INTERACTIONS": "Drug Interactions",
        "DRUG_INFORMATION": "Drug Information",
        "DRUG_MONOGRAPH": "Drug Monograph",
        "DRUG_DOSAGE_FORMS": "Drug Dosage Forms",
        "PATIENT_EDUCATION": "Patient Education",
        "WHATS_NEW": "What's New",
        "PRACTICE_CHANGING_UPDATES": "Practice Changing UpDates",
        "CALCULATORS": "Calculators",
        "CONTENTS": "Contents",
        "AUTHORS_EDITORS": "Authors and Editors",
        "TOPICS_SPECIALTY": "Topics by Specialty",
        "WELCOME": "Welcome, {{name}}",
        "ABOUT_UPTODATE": "About UpToDate",
        "MOBILE_OPTIONS": "Mobile Options",
        "HELP": "Help",
        "BACK_TO_SEARCH": "Back to Search",
        "MY_ACCOUNT": "My Account",
        "FEEDBACK": "Feedback",
        "WHY_UPTODATE": "Why UpToDate?",
        "PRODUCT": "Product",
        "EDITORIAL": "Editorial",
        "SUBSCRIPTION_OPTIONS": "Subscription Options",
        "SUBSCRIBE": "Subscribe",
        "MENU": "Menu",
        "OFFICIAL_REPRINT": "Official reprint from  UpToDate<sup>&reg;</sup>",
        "COUNTER_OF": "{{current}} of {{total}}",
        "RX_TRANSITIONS": "Rx Transitions for Mental Health",
        "OTHER": 'Other',
        "CONTENT": "Content" 
    },
    "SUBSCRIBE_INTERRUPT": {
        "HEADER_MESSAGE": "To continue reading this article, you must sign in with your personal, hospital, or group practice subscription.",
        "SUBSCRIBE_LINK": "/store",
        "LOGIN_LINK": "/login",
        "HEADER_MESSAGE_UCC": "If you want to continue reading, please click here to <a class=\"loginLink\" href=\"/login\">sign in</a>.",
        "HEADER_MESSAGE2_UCC": "If you can't sign in, we provide two subscription options",
        "INSTITUTIONAL_USER": "Institutional User",
        "PERSONAL_USER": "Personal User"
    },
    "MARKETING_BANNER": {
        "LEARN_HOW_UTD_CAN_HELP": "Learn how UpToDate can help you.",
        "SELECT_OPTION_BEST_DESCRIBES": "Select the option that best describes you",
        "INFO_ON_SUBSCRIPTION_OPTIONS": "For more information on subscription options, click below on the option that best describes you:",
        "MEDICAL_PROFESSIONAL": "Medical Professional",
        "RESIDENT_FELLOW_STUDENT": "Resident, Fellow, or Student",
        "HOSPITAL_INSTITUTION": "Hospital or Institution",
        "GROUP_PRACTICE": "Group Practice"
    },
    "TOC": {
        "WHATS_NEW": "Our editors select a small number of the most important updates and share them with you via What's New. See these updates by clicking on the specialty you are interested in below. You may also enter \"What's new\" in the search box.",
        "CONTENTS": "<h2>Table of Contents</h2><p>You have access to the entire UpToDate&#174; library of specialties with your subscription. Click on one of the specialties below to see sections associated with each. You can also view Practice Changing UpDates, What’s New, Patient Education, Authors and Editors, Calculators, and Drug Information.</p>",
        "CONTENTS_SECTIONS": "<p>You receive the entire UpToDate library of specialties with your subscription. Click on a section below to view a detailed list of topics associated with that particular section. If you'd like to see the table of contents for other specialties, <a href=\"/contents/table-of-contents\">click here</a>.</p>",
        "PI": "<h2>Patient Education</h2><p>UpToDate offers two levels of content for patients:</p><ul><li><strong>The Basics</strong> are short overviews. They are written in accordance with plain language principles and answer the four or five most important questions a person might have about a medical problem.</li><li><strong>Beyond the Basics</strong> are longer, more detailed reviews. They are best for readers who want detailed information and are comfortable with some medical terminology.</li></ul><p><a href='/home/patient-information' target='_blank'>Learn more</a> about UpToDate's patient education materials.</p><div class='honcode'><p><a href=\"{{healthonnet}}\" target=\"_blank\"><img src=\"https://www.honcode.ch/HONcode/Seal/HONConduct272373_s.gif\" width=\"49\" height=\"72\" title=\"This website is certified by Health On the Net Foundation. Click to verify.\" alt=\"This website is certified by Health On the Net Foundation. Click to verify.\"></a></p><p class='honcodep'>This site complies with the <a href=\"{{honcode}}\" target=\"_blank\"> HONcode standard for trustworthy health</a> information: <a href=\"{{healthonnet}}\" target=\"_blank\">verify here.</a></p></div><p>To browse the available patient education topics in UpToDate, click on a category below.</p>",
        "PI_BASICS": "\"The Basics\" are short (1 to 3 page) articles written in plain language. They answer the 4 or 5 most important questions a person might have about a medical problem. These articles are best for people who want a general overview.",
        "PI_BEYOND_BASICS": "\"Beyond the Basics\" articles are 5 to 10 pages long and more detailed than \"The Basics\". These articles are best for readers who want a lot of detailed information and who are comfortable with some technical medical terms.",
        "HEADER_REDESIGN_CONTENTS": "You have access to the entire UpToDate&#174; library of specialties with your subscription. Click on one of the specialties below to see sections associated with each."
    },
    "QAP_TABS": {
        "drug_general": "General",
        "drug_pediatric": "Pediatric",
        "drug_patient": "Patient",
        "drug_dxy": "Chinese Drug Information",
        "drug_foreign": "Foreign Drug Information"
    },
    "MYACCOUNT": {
        "SETTINGS": "Settings",
        "POLICIESLEGAL": "Policies &amp; Legal",
        "LANGUAGE": "Language",
        "CHANGE_TEXT_SIZE": "Change Text Size",
        "CHANGE_TEXT_SIZE_DESC": "Select the text size you would like to use for the UpToDate application below.",
        "CHANGE_TEXT_SIZE_XS": "The quick brown fox jumped over the lazy dog and ran into the woods.",
        "CHANGE_TEXT_SIZE_S": "The quick brown fox jumped over the lazy dog and ran into the woods.",
        "CHANGE_TEXT_SIZE_M": "The quick brown fox jumped over the lazy dog and ran into the woods.",
        "CHANGE_TEXT_SIZE_L": "The quick brown fox jumped over the lazy dog and ran into the woods.",
        "CHANGE_TEXT_SIZE_XL": "The quick brown fox jumped over the lazy dog and ran into the woods.",
        "CHANGE_TEXT_SIZE_MSG": "To change the font size for topics, tap Tools when viewing a topic.",
        "MY_ACCOUNT": "My Account",
        "MY_PROFILE": "My Profile",
        "UPDATE_PROFILE": "Please update",
        "EDIT_MY_PROFILE": "Edit My Profile",
        "CHANGE_USERNAME": "Change Username",
        "CHANGE_PASSWORD": "Change Password",
        "VIEW_EDIT_CONTACT_INFORMATION": "View/edit contact information",
        "CONTACT_INFORMATION": "Contact Information",
        "SUBSCRIPTION_INFORMATION": "Subscription Information",
        "YOUR_ACCOUNT_IS_VALID_THROUGH": "Your account is valid through",
        "MANAGE_SUBSCRIPTION": "Manage Subscription",
        "MANAGE_DEVICES": "Manage Devices",
        "DOWNLOAD_CENTER": "Download Center",
        "PURCHASE_UTD_DESKTOP": "Purchase UTD Desktop",
        "DESKTOP_DOWNLOAD_CENTER": "Desktop download center",
        "MOBILE_DOWNLOAD_INSTRUCTIONS": "Mobile download instructions",
        "CLEAR_CACHE": "Clear Cache",
        "CLEAR_CACHE_INSTRUCTION": "Choose if you are having trouble loading topics or search results.",
        "CACHE_CLEARED": "<p>All cached items have been cleared.</p>",
        "LICENSE_AGREEMENT_INFO": "Please protect your username and password. Under your license agreement with UpToDate, they are intended for your personal use only. Sharing of your username and password will result in termination of your online access to UpToDate.",
        "EMPTY_FIELD": "This field is required",
        "FORGOT_PASSWORD": "Forgot password?",
        "NEW_USER_NAME": "New Username",
        "NEW_USER_NAME_INVALID": "This username is invalid",
        "NEW_USER_NAME_TAKEN": "This username is already taken",
        "NEW_USER_NAME_MUST_MATCH": "Please verify the usernames match",
        "NEW_USER_NAME_INVALID_LENGTH": "Username must be between 6 and 50 characters",
        "NEW_USER_NAME_INVALID_CHARACTERS": "Username cannot contain invalid characters",
        "PASSWORD_INVALID": "The password provided is invalid",
        "ENTER_NEW_USERNAME": "Enter your new username",
        "REENTER_NEW_USERNAME": "Re-enter your new username",
        "ENTER_NEW_PASSWORD": "Enter your new password",
        "REENTER_NEW_PASSWORD": "Re-enter your new password",
        "ENTER_OLD_PASSWORD": "Enter your current password",
        "PASSWORD_RULES": "<dt>Passwords must:</dt><dd>Be between 8 and 24 characters</dd><dd>Contain at least 1 uppercase letter</dd><dd>Contain 1 number or special character</dd><dd>User Name and Password cannot match</dd><dd>The following special characters are allowed:<br/><span class=\"fixed red\">. - _ @ # $ * ! ( ) + =</span></dd>",
        "PASSWORD_OLD_CONFIRM": "Confirm Password",
        "PASSWORD_NEW": "New User Password",
        "PASSWORD_NEW_CONFIRM": "Confirm New Password",
        "PASSWORD_INVALID_MATCHES_USERNAME": "Cannot match username",
        "PASSWORD_INVALID_LENGTH": "Password must be between 8 and 24 characters",
        "PASSWORD_INVALID_NOUPPERCASE": "Password must have at least one capital letter",
        "PASSWORD_INVALID_NONUMERIC_OR_SPECIAL": "Password must contain one number or special character",
        "PASSWORD_INVALID_CHARACTERS": "Password contains invalid characters",
        "PASSWORD_INVALID_MISMATCH": "Passwords do not match",
        "PASSWORD_NEW_SAVE_CHANGES": "Save Changes",
        "PASSWORD_CHANGE_SUCCESS": "You have successfully updated your sign-in information.</br> It may take a few minutes for this change to take effect. If you have trouble signing in, please wait a few minutes then try again.",
        "USERNAME_CHANGE_SUCCESS": "Success! Your Username was updated.",
        "CREDENTIAL_CHANGE_WAIT_ADVICE": "Please note, these changes may not be reflected immediately. Please wait for an email confirmation before signing in with your new credentials.",
        "UPDATE_INFORMATION_UNEXPECTED_ERROR": "There was a problem updating your information.",
        "UPDATE_INFORMATION_UNEXPECTED_ERROR_FULL": `There was a problem updating your information. Please try again later or contact us using <a v-utd-new-tab="true" href="https://www.wolterskluwer.com/en/solutions/uptodate/contact-us" class="ds1-utd-weight-600">UpToDate Support.</a>`,
        "VALIDATE_INFORMATION_UNEXPECTED_ERROR": "There was a problem validating your information.",
        "CONTACT_INFORMATION_SETUP_ERROR": "There was a problem with our contact information form.",
        "MANAGE_DEVICES_COMPUTER": " desktop computer",
        "MANAGE_DEVICES_COMPUTERS": " desktop computers",
        "MANAGE_DEVICES_DEVICE": " mobile device",
        "MANAGE_DEVICES_DEVICES": " mobile devices",
        "NO_ACTIVE_DEVICES": "There are no computers or devices linked to your account",
        "DEVICES_LAST_USED": "Last Used",
        "DEVICES_DEVICE_ID": "Device ID",
        "DEVICES_APPLICATION": "Application",
        "DEVICES_CONTENT": "Content",
        "ACCOUNT_GUID_EXPIRED": "This link has expired.",
        "ACCOUNT_GUID_CONSUMED": "This link has been used already.",
        "ACCOUNT_GUID_INVALID": "This link is invalid.",
        "ACCOUNT_GUID_MESSAGE": "Please submit your e-mail address again to receive a new link.",
        "ACCOUNT_EXPIRED": "The enterprise license has expired. Please contact your site administrator for additional assistance.",
        "ACCOUNT_DISABLED": "This user has been disabled.",
        "COOKIE_PREFERENCES": "Manage Cookie Preferences",
        "CONTACT_EMAIL": "Email",
        "PHONE": "Phone",
        "CONTACT_PHONE": "Phone number",
        "CONTACT_COUNTRY": "Country",
        "CONTACT_ADDRESS1": "Mailing address",
        "CONTACT_ADDRESS2": "Address line 2",
        "CONTACT_ADDRESS3": "Address line 3",
        "CONTACT_ZIP": "ZIP/Postal Code",
        "CONTACT_CITY": "City",
        "CONTACT_STATE": "State/Province",
        "SELECT_PRACTICE_SETTING": "Select your practice setting",
        "SELECT_ROLE": "Select your role",
        "SELECT_SPECIALTY": "Select your specialty",
        "CONTACT_PRIVACY_DISCLOSURE": "<a href='https://www.wolterskluwer.com/en/solutions/uptodate/policies-legal/privacy-policy' target='_blank'>View privacy policy</a>",
        "CONTACT_PRIVACY_DISCLOSURE_UCC": "<a href='https://www.uptodate.cn/home/uptodate-china-privacy-policy' target='_blank'>View privacy policy</a>",
        "CONTACT_VERIFICATION_CODE": "Verification Code",
        "INVALID_EMAIL": "Please enter a valid email address",
        "INVALID_FIELD_LENGTH": "This field cannot exceed {{ maxLength }} characters",
        "INVALID_ZIPCODE": "Please enter a valid zip code",
        "INVALID_PHONE": "Please enter a valid phone number",
        "INVALID_EMAIL_CODE": "Please enter a valid numeric code",
        "INVALID_ADDRESS": "Please enter a valid address",
        "INVALID_CITY": "Please enter a valid city"
    },
    "MYUPTODATE": {
        "MY_ACCOUNT": "My Account",
        "HISTORY": "History",
        "BOOKMARKS": "Bookmarks",
        "MOST_VIEWED": "Most Viewed",
        "TODAY": "TODAY",
        "YESTERDAY": "YESTERDAY",
        "SHOW_WIDGET": "Show my shortcuts",
        "CLOSE_WIDGET": "Close my shortcuts",
        "SHORTCUTS": "Shortcuts",
        "TOPIC_VIEW": "Topic View",
        "MY_UPTODATE": "My UpToDate",
        "FIND": "Find",
        "BOOKMARK": "Bookmark",
        "WIDGET": "Widget",
        "MANAGE_BOOKMARK_HISTORY": "Manage Bookmarks/History",
        "BOOKMARK_HISTORY": "Bookmarks & History",
        "EDIT": "Edit",
        "DONE": "Done",
        "TO_EDIT": "To Edit",
        "HISTORY_EMPTY": "You have no history.",
        "PLEASE_WAIT": "Please Wait",
        "CLEAR_HISTORY": "Clear All History and Most Viewed",
        "CONTINUE": "Continue",
        "CLEAR_ALL": "Clear All",
        "CANCEL": "Cancel",
        "DELETE": "Delete",
        "EDIT_BOOKMARK_PART1": "To edit bookmarks, visit your",
        "EDIT_BOOKMARK_PART2": " bookmarks page.",
        "BOOKMARK_EMPTY": "You have not added any bookmarks.",
        "HISTORY_CLEAR_CONFIRM": "Are you sure you want to clear all of your History and Most Viewed content?",
        "MOST_VIEWED_EMPTY": "As you use UpToDate, content you view most often will show here.",
        "TOOLTIP_BOOKMARK_FULL": "Your bookmark list has reached the maximum of 200 bookmarks. Remove bookmarks using My UpToDate and try again.",
        "CME_CE_CPD": "CME/CE/CPD",
        "EDIT_BOOKMARK": "",
        "MOST_VIEWED_ERROR_LOADING": "Error loading most viewed, please try again.",
        "MOST_VIEWED_MESSAGE_TOP": "Displaying your most viewed content (top 10 links)"
    },
    "SUPPORTTAG": {
        "RELEASE": "Release",
        "CONTENT": "Content",
        "LICENSEDTO": "Licensed to",
        "SUPPORTTAG": "Support Tag",
        "SERVER": "Server",
        "CLIENT": "Client",
        "SUBMIT-REF-SUCCESS": "Your support email has been sent",
        "SUBMIT-REF-ERROR": "Invalid case reference ID",
        "SUBMIT-REF-TITLE": "Submit Case Reference",
        "SUBMIT-REF-INPUT": "Enter case reference ID",
        "SUBMIT-REF-HELP": "No case reference ID? <a  target=\"_blank\" href=\"{{contactUsUrl}}\" rel=\"noopener noreferrer\">Contact Us</a>"
    },
    "ERROR": {
        "CONTENT_NOT_AVAILABLE": "This content is only available to {{productName}} subscribers.  Please <a href=\"/login\">sign in</a> to gain access.",
        "ICG_COOKIES_DISABLED": "<h3>Error - UpToDate&#174; Pathways require cookies to be enabled in your browser settings.</h3><p>Please enable cookies in your browser security settings, and restart your request. Contact your Information Technology support department for assistance with configuring your browser settings.</p><p>We apologize for the inconvenience. If this problem persists, please contact UpToDate Technical Support for assistance.</p><p>Toll-free number: 1.800.998.6374<br/>International: +1.781.392.2000<br/>Fax: +1.781.642.8840<br/>Email: <a href=\"mailto:support@uptodate.com\">support@uptodate.com</a><br/>Hours: Monday-Friday, 8 AM - 9 PM ET (-5 GMT)</p>",
        "ICG_NOT_AVAILABLE": "Your license does not provide access to this content. <a href=\"/home/product\" target=\"_blank\">Learn more</a> about our products.",
        "CME_CERTIFICATE_NOT_FOUND": "The CME certificate you requested cannot be retrieved. Please contact customer service or try again later.",
        "PAGE_NOT_FOUND": "Page Not Found",
        "PAGE_NOT_FOUND_DESCRIPTION": "We're sorry, the page you are looking for could not be found.",
        "GO_TO_HOME_PAGE": "Go To Home Page",
        "PAGE_NOT_AVAILABLE_MOBILE": "The page you're trying to access is unavailable through your mobile device. Please access this page from a desktop browser.",
        "FORMULA_ERROR": "<div class='formulaError'>This formula is too large to be displayed on your mobile device. Try again <span class='portraitOnly'>in landscape or </span>on a larger device.</div>",
        "FORMULA_ERROR_DESKTOP": "<div class='formulaError'>This formula is too large to be displayed at your current browser width. Try increasing the browser width, or close the topic outline to gain more space.</div>",
        "SYSTEM_ERROR": "Due to a system error we are unable to process your request at this time.",
        "TRIAL_ACCOUNT_ERROR": "Your trial account could not be created due to an unforeseen error. Please try again or for support please contact your organization or platform that was providing access to UpToDate.",
        "INLINE_CME_ERROR": "Inline CME reflection is currently unavailable. Please try again later.",
        "SUBMISSION_XSS_ERROR": "There was an error during submission. HTML is not allowed in messages. Please review the form, then resubmit."
    },
    "SHARE": {
        "SHARE_KEY": "Share",
        "NAME_REQUIRED": "Your Name is required.",
        "EMAIL_REQUIRED": "Your E-mail is required.",
        "RECIPIENT_EMAIL_REQUIRED": "Recipient E-mail Addresses is required.",
        "RECIPIENT_EMAIL_INVALID": "Recipient E-mail Addresses is not valid.",
        "IDENTICAL_EMAILS": "A user may not send a Guest Pass to themselves.",
        "FROM_EMAIL_INVALID": "From E-mail Address is not valid.",
        "DENIED": "Your content has been shared. Your guest pass request was denied.",
        "DENIED_ALREADY_RECEIVED": "Your content has been shared. Your guest pass was not sent because there is a limit of one guest pass per recipient.",
        "SUCCESS": "Your content has been shared. This recipient has been given a guest pass.",
        "ERROR": "Your content has been shared. Your guest pass was not sent due to internal errors.",
        "PENDING": "Your content has been shared. Your guest pass was not sent due to internal errors.",
        "CONTENT_SHARE_LINK_INVALID": "<p>The link to this UpToDate® content has expired and is no longer valid, but you can <a href=\"/home/sample-topics\" target=\"_blank\">view sample UpToDate topics.</a></p> <p>UpToDate is the clinical decision support resource accessed by more than 1.3 million clinicians worldwide. <a href=\"/home\" target=\"_blank\">Learn more</a> about UpToDate or <a href=\"/store\" target=\"_blank\">subscribe risk-free.</a></p>"
    },
    "MOC_REJECTIONS": {
        "REASON_661": "&nbsp;because your {{authorityCode}} ID was incorrect",
        "STEPS_661": "<li>Correct this information by visiting your <a href=\"/cme/settings/moc\">MOC Settings</a>.</li><li>Submit these credits again.</li>",
        "REASON_664": "&nbsp;because your birthdate does not match what {{authorityCode}} has on file",
        "STEPS_664": "<li>Correct this information by visiting your <a href=\"/cme/settings/moc\">MOC Settings</a>.</li><li>Submit these credits again.</li>",
        "REASON_665": "&nbsp;because your name does not match what {{authorityCode}} has on file",
        "STEPS_665": "<li>Please contact <a href=\"/home/contact-us\" target=\"_blank\">Customer Service</a>.</li>",
        "REASON_MULTIPLE": "&nbsp;for multiple reasons",
        "STEPS_DEFAULT": "<li>Please contact <a href=\"/home/contact-us\" target=\"_blank\">Customer Service</a>.</li>",
        "REASON_DEFAULT": ""
    },
    "STATE_CME_REJECTIONS": {
        "REASON_661": "&nbsp;because your {{authorityCode}} ID was incorrect",
        "STEPS_661": "<li>Correct this information by visiting your <a href=\"/cme/settings/state\">State Medical Boards Settings</a>.</li><li>Submit these credits again.</li>",
        "REASON_664": "&nbsp;because your birthdate does not match what {{authorityCode}} has on file",
        "STEPS_664": "<li>Correct this information by visiting your <a href=\"/cme/settings/state\">State Medical Boards Settings</a>.</li><li>Submit these credits again.</li>",
        "REASON_665": "&nbsp;because your name does not match what {{authorityCode}} has on file",
        "STEPS_665": "<li>Please contact <a href=\"/home/contact-us\" target=\"_blank\">Customer Service</a>.</li>",
        "REASON_MULTIPLE": "&nbsp;for multiple reasons",
        "STEPS_DEFAULT": "<li>Please contact <a href=\"/home/contact-us\" target=\"_blank\">Customer Service</a>.</li>",
        "REASON_DEFAULT": ""
    },
    "PROSPECT_FOOTER": {
        "COMPANY": "Company",
        "ABOUT_US": "About Us",
        "EDITORIAL_POLICY": "Editorial Policy",
        "TESTIMONIALS": "Testimonials",
        "CAREERS": "Careers",
        "NEWS_EVENTS": "News & Events",
        "CLINICAL_PODCASTS": "Clinical Podcasts",
        "PRESS_ANNOUNCEMENTS": "Press Announcements",
        "IN_NEWS": "In the News",
        "EVENTS": "Events",
        "RESOURCES": "Resources",
        "UPTODATE_LOGIN": "UpToDate Sign-in",
        "CME_CE_CPD": "CME/CE/CPD",
        "MOBILE_APPS": "Mobile Apps",
        "WEBINARS": "Webinars",
        "EHR_INTEGRATION": "EHR Integration",
        "HEALTH_INDUSTRY_POD": "Health Industry Podcasts",
        "FOLLOW_US": "Follow Us",
        "SUPPORT": "Support",
        "CONTACT_US": "Contact Us",
        "HELP_TRAINING": "Help & Training",
        "CITING_CONTENT": "Citing Our Content",
        "PRIVACY_POLICY": "Privacy Policy",
        "TRADEMARKS": "Trademarks",
        "SUBSCRIPTION_LICENCE": "Terms of Use",
        "SIGN_UP": "Sign Up",
        "SIGN_TODAY_DESC": "Sign up today to receive the latest news and updates from UpToDate."
    },
    "FOOTER": {
        "USABILITY_RESEARCH": "Usability Research"
    },
    "FORMULINK": {
        "SHOW_MORE_BTN": "Show more...",
        "FORMULARY": "Formulary",
        "HOSPITAL_CUSTOM_FORMULARY": "Hospital Custom Formulary",
        "DRUG_INFO_FOR_TOPIC": "Formulary drug information for this topic",
        "DRUG_INFO_FOR_TOPIC_TITLE": "Formulary drug information for <i>\"{{title}}\"</i>",
        "NO_DRUGS_FOR_TOPIC": "No drug references linked in this topic.",
        "SELECT_SYSTEM_LABEL": "Health System",
        "SELECT_SYSTEM_PLACEHOLDER": "Select a health system",
        "SELECT_FORMULARY_LABEL": "Formulary",
        "SELECT_FORMULARY_PLACEHOLDER": "Select a formulary",
        "SELECT_SYSTEM_FORMULARY_NOTICE": "Select from the options above to view formulary drug information for this topic.",
        "SELECT_FORMULARY_DRUG_NOTICE": "Select drug for more information.",
        "DRUG_NAME": "Drug Name",
        "FORMULARY_STATUS": "Formulary Status",
        "MATCH_FOUND": "Match found in formulary",
        "MATCH_NOT_FOUND": "No match found in formulary",
        "VIEW_FORMULARY_STATUS": "View formulary status"
    },
    "PATHWAYS_FORMULINK": {
        "DRUG_INFO_FOR_TOPIC": "Formulary drug information for this pathway",
        "NO_DRUGS_FOR_TOPIC": "No drug references linked in this pathway.",
        "SELECT_SYSTEM_FORMULARY_NOTICE": "Select from the options above to view formulary drug information for this pathway."
    },
    "MOC_SETTINGS": {
        "ADD_BOARD": "Add an MOC board",
        "MANAGE_BOARDS": "Manage existing MOC boards",
        "SUBMIT": "Submit for MOC",
        "PRE_REMOVE_WARNING": "You will no longer be able to submit MOC to this board. Past submissions will still be viewable in your MOC Status and history.",
        "REMOVAL_ERROR": "An error has occurred while removing your MOC board.",
        "MANAGE_BOARD_CAVEAT": "If you have multiple certifications with {{authCode}}, the MOC points you earn count toward all your certifications.",
        "BOARD_ID_LABEL": "ID",
        "PERMISION_NOTE": 'I give UpToDate permission to submit my information to the ACCME and each added board. Submissions cannot be edited. Credit submitted cannot be reused for submission to the same board.'
    },
    "STATE_CME_SETTINGS": {
        "ADD_BOARD": "Add a state medical board",
        "MANAGE_BOARDS": "Manage existing state medical boards",
        "SUBMIT": "Submit to State Medical Boards",
        "PRE_REMOVE_WARNING": "You will no longer be able to submit credits to this board. Past submissions will still be viewable in your state medical board Status and History.",
        "REMOVAL_ERROR": "An error has occurred while removing your state medical board.",
        "MANAGE_BOARD_CAVEAT": "UpToDate is not reporting learner completion data to the state medical boards for the Controlled Substances/Safe Prescribing/Pain Education CME activity requirement.",
        "BOARD_ID_LABEL": "Licensure Number",
        "PERMISION_NOTE": 'I give UpToDate permission to submit my information to the ACCME and each added board. Submissions cannot be edited. Credit submitted cannot be reused for submission to the same board.'
    },
    "MOC_STATUS": {
        "TITLE": "MOC Status and History",
        "NO_HISTORY": "You have no MOC history",
        "HELP_LINK": "MOC Help",
        "SUBMISSION_NAME": "MOC"
    },
    "STATE_CME_STATUS": {
        "TITLE": "State Medical Boards Status and History",
        "NO_HISTORY": "You have no State Medical Boards submission history",
        "HELP_LINK": "State Medical Boards Help",
        "SUBMISSION_NAME": "State Medical Board"
    },
    "MOC_SELECT_CREDITS": {
        "ONLY_REDEEMED_NOTE": "Only redeemed CME can be submitted for MOC."
    },
    "STATE_CME_SELECT_CREDITS": {
        "ONLY_REDEEMED_NOTE": "Only redeemed CME can be submitted for state medical boards."
    },
    "MOC_DASHBOARD": {
        "TITLE": "Maintenance of Certification (MOC)",
        "LEARN": "Learn about Maintenance of Certification and UpToDate",
        "ADD_BOARD": "Add a board to get started and submit for MOC",
        "SUBMIT_TITLE": "Submit for MOC",
        "BEGIN_PROCESS": "Begin the submission process"
    },
    "STATE_CME_DASHBOARD": {
        "TITLE": "State Medical Boards",
        "LEARN": "Learn about State Medical Boards and UpToDate",
        "ADD_BOARD": "Add a board to get started and submit to State Medical Boards",
        "SUBMIT_TITLE": "Submit to State Medical Boards",
        "BEGIN_PROCESS": "Begin the submission process"
    },
    "CME_HISTORY": {
        "SUBMISSION_CONFIRMATION": "Submit your redeemed credits directly to State Medical and MOC boards. Once your preferences have been set up using the link below, redeemed credits will automatically be sent to selected boards.",
        "LOG": "CME History Log"
    },
    "REDIRECT_CONFIRM_DIALOG": {
        "TITLE": "Confirm Redirect",
        "MESSAGE": "Would you like to sign in with your corporate account?",
        "CHECKBOX_TEXT": "Do not show this dialog again."
    },
    "ANKI": {
        "TITLE": "UpToDate for Anki Add-On"
    },
    "MACHINE_TRANSLATION": {
        "FEEDBACK": "Translation Feedback",
        "LEARN_MORE": "Learn More"
    }
};

export default localizationEn;
