<template>
  <div v-if="isRxTransitions" class="qap-rx-transitions">
    <a :href="setUrlSource"
       class="qap-link qap-rx-transitions-link"
       target="_self"
       v-html="getTitle" />
  </div>
</template>

<script>
import { setQueryParamValue } from '_acaSrc/utility/http';
import { C_QAP } from '_acaSrc/utility/constants';

export default {
    props: {
        result: Object,
        source: String
    },
    computed: {
        isRxTransitions() {
            return (this.result && this.result.rxTransitions
                        || this.result && this.result.type === C_QAP.SECTION.RX_TRANSITIONS);
        },
        setUrlSource() {
            let url = '';
            if (this.result && this.result.rxTransitions) {
                url = this.result.rxTransitions.url;
            }
            else if (this.result.items && this.result.items.length) {
                const rxTransitionsItem = this.result.items.filter(item => item.type === C_QAP.SECTION.RX_TRANSITIONS)[0];
                url = rxTransitionsItem.url;
            }
            return setQueryParamValue(url, 'source', this.source);
        },
        getTitle() {
            let title = '';
            if (this.result && this.result.rxTransitions) {
                title = this.result.rxTransitions.title;
            }
            else if (this.result.items && this.result.items.length) {
                const rxTransitionsItem = this.result.items.filter(item => item.type === C_QAP.SECTION.RX_TRANSITIONS)[0];
                title = rxTransitionsItem.title;
            }
            return `${title}<span class="wkce-icon-arrow-right ds1-pl-1"></span>`;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import "../vue-qap.less";

.qap-rx-transitions {
    .ds1-ph-2();
    .ds1-pv-1();
    border-top: @DS1-UTD-GRAY-SINGLE-BORDER;

    .wkce-icon-arrow-right::before {
        font-size: 16px;
        display: inline-block;
        color: @DS1-QAP-BASE-LINK-COLOR;
    }

    .qap-link:hover .wkce-icon-arrow-right::before {
        color: @DS1-QAP-LINK-HOVER-COLOR;
    }

    .qap-rx-transitions-link::before {
        content: "";
        background: url(~_acaAssets/search/rxtransitions.svg);
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        display: inline-block;
        position: relative;
        vertical-align: top;
    }
}

</style>