<template>
  <div class="wk-field-body">
    <label v-for="(item, idx) in items"
           :key="idx"
           class="wk-field-choice-label">
      <input v-model="picked"
             type="radio"
             :value="idx"
             :name="name"
             :aria-label="item.ariaLabel"
             class="wk-field-choice"
             @change="onChange($event)">
      <span class="wk-field-choice-text"><!--
          -->{{ item.option }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
    props: {
        items: Array,
        name: String,
        value: Number
    },
    emits: [ 'input' ],
    data() {
        return {
            picked: this.value
        };
    },
    watch: {
        // deriving picked from value rather than just setting v-model="value",
        // fixes a vue warning about setting prop values. The parent's v-model
        // will remain in sync thanks to the $emit('input') above. Most inputs
        // won't need this complexity; this is a special case for encapsulating
        // multiple child inputs. This encapsulation is a trade-off of
        // convenience for flexibility - in the future, a low-level single radio
        // input component may be needed for complex forms.
        value() {
            this.picked = this.value;
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', parseInt(event.target && event.target.value, 10));
        }    
    }
};
</script>

<style scoped lang="less">
.wk-field-choice-label {
  cursor: pointer;
}
</style>
