<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { SET_SEARCHBAR_AUTOCOMPLETE } from '_acaSrc/store/search.store';
import ServerMessaging from '../../utility/ServerMessaging';

export default {
    computed: {
        ...mapGetters('app', [ 'router', 'isDesktopBrowser' ]),
        ...mapGetters('device', [ 'isMobileOnDesktop' ])
    },
    mounted() {
        this.delayExecuteUntilDataLoaded(() => {
            if (this.isDesktopBrowser) {
                this.subscribe({
                    eventName: 'RESPONSIVE_TRANSITION',
                    handlerFn: this.processTransition
                });
            }
        });
    },
    beforeUnmount() {
        if (this.isDesktopBrowser) {
            this.unsubscribe({
                eventName: 'RESPONSIVE_TRANSITION',
                handlerFn: this.processTransition
            });
        }
    },
    methods: {
        ...mapActions('app', [ 'subscribe', 'unsubscribe', 'delayExecuteUntilDataLoaded' ]),
        ...mapMutations('search', [ SET_SEARCHBAR_AUTOCOMPLETE ]),
        processTransition() {
            // If browser is in "mobile" view on a desktop browser, and either...
            // > Single graphic modal is visible
            // > All related graphics modal is visible
            // > My UpToDate personalization modal is visible
            // ... then close the modal
            if (this.isMobileOnDesktop) {
                // DE13210: due to wonderous IE11 bug, forcing autoComplete to clear here as well
                this[SET_SEARCHBAR_AUTOCOMPLETE]('');

                if (document.querySelectorAll('#utdMessageBox.singleGraphic, #utdMessageBox.drugAlerts, #utdMessageBox.graphicsAll,.my-utd-modal.myUptodateModal').length) {
                    new ServerMessaging().resetModal();

                    if (this.router.url().indexOf('myUpToDate=true') > -1) {
                        this.router.url('/myuptodatemobile');
                    }
                }
            }
            else if (this.router.current().name === 'myuptodatemobile') {
                // Otherwise, if NOT mobile on desktop, and the state is MyUTD,
                // redirect to desktop modal version
                this.router.url('/contents/search?myUpToDate=true');
            }
        }
    },
    render: () => null
};
</script>