<template>
  <div class="utd-alert-dialog">
    <utd-modal-dialog ref="modalDialogRef"
                      :dialog-size="'medium'"
                      :hide-header-close="hideHeaderClose"
                      :close-on-overlay-click="false"
                      :close-on-escape="false"
                      :modal-classes="modalClasses">
      <template #header>{{ title }}</template>
      <slot />
      <template v-if="!suppressButton" #footer>
        <utd-button @click="close">{{ $t('OK') }}</utd-button>
      </template>
    </utd-modal-dialog>
  </div>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

export default {
    name: 'UtdAlertDialog',
    components: {
        UtdModalDialog,
        UtdButton
    },
    props: {
        title: String,
        suppressButton: {
            type: Boolean,
            default: false
        },
        hideHeaderClose: {
            type: Boolean,
            default: true
        }
    },
    emits: [ 'closed' ],
    computed: {
        modalClasses() {
            return `alert-modal-dialog ${!this.title ? 'headerIsEmpty' : ''}`;
        }
    },
    methods: {
        open() {
            this.$refs.modalDialogRef.open();
        },
        close() {
            this.$emit('closed');
            this.$refs.modalDialogRef.close();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.alert-modal-dialog {
  .utd-dialog-frame.no-footer {
    .ds1-mb-0();
  }

  .utd-dialog-header {
    .ds1-pa-2();
    background-color: @DS1-MODAL-TOOLBAR-BG-COLOR;
    color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    height: auto;
    font-weight: normal;
    vertical-align: middle;
  }

  &.headerIsEmpty .utd-dialog-header {
    .ds1-pa-1();
  }

  .utd-dialog-content {
    .ds1-pa-2();
  }

  .utd-dialog-footer {
    text-align: right;
  }
}
</style>