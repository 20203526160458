<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="qap-panel qap-dxy ds1-qap-container wk-js2">
    <div class="qap-header">
      <div class="qap-title wkce-icon-medicine">{{ result.title }}</div>
    </div>
    <utd-tabs class="with-divider">
      <utd-tab v-for="(content, contentIdx) in result.qapContents"
               :key="contentIdx"
               :title="$t(content.translationKey || content.contentLabel)">
        <section v-if="!isForeignTab(content.type)" class="qap-content">
          <utd-spinner :is-visible="dxyShowSpinner"
                       :is-fullscreen="false"
                       :lock-scroll="false">
            <div class="qap-dxy-content ds1-pa-2"
                 :class="{'qap-tab-async-content': !dxyHasMadeRequest }">
              <div v-if="dxyHasMadeRequest">
                <div v-if="monograph && !hasError">
                  <div class="qap-section-title" v-text="$t('HEADER.DRUG_MONOGRAPH')" />
                  <a class="qap-link"
                     :href="setUrlSource(monographUrl)"
                     v-html="monograph.name" />
                </div>

                <dl v-if="dosages && !hasError" class="qap-list">
                  <dt class="qap-section-title" v-text="$t('HEADER.DRUG_DOSAGE_FORMS')" />
                  <dd v-for="(dosage, dosageIdx) in dosages"
                      :key="dosageIdx"
                      class="qap-list-item qap-dosage-item">
                    <a class="qap-link"
                       :href="setUrlSource(dosage.url)"
                       v-html="dosage.name" />
                  </dd>
                  <dd v-if="hasDosagesLimit" class="qap-list-item dosages-show-more">
                    <a class="qap-link"
                       @click="displayAllDosages()"
                       v-text="$t('SEARCH.SHOW_MORE')" />
                  </dd>
                </dl>
                <div v-if="hasNoResults || hasError">
                  <div v-if="!hasError">
                    <div id="qapDxyNoResults"
                         class="qap-section-title"
                         v-text="$t('SEARCH.NO_RESULTS_FOUND')" />
                  </div>

                  <div v-if="hasError" id="qapDxyError">
                    <div class="qap-section-title"
                         v-text="$t('ERRORS.ERROR_OCCURRED')" />
                    <span class="qap-section-title"
                          v-text="$t('ERRORS.PLEASE_TRY_AGAIN')" />
                  </div>
                </div>
              </div>
            </div>
          </utd-spinner>
        </section>

        <section v-if="isForeignTab(content.type)"
                 class="qap-content qap-dxy-foreign qap-section">
          <div class="qap-section-container">
            <dl v-for="(section, sectionIdx) in content.sections"
                :key="sectionIdx"
                class="qap-list">
              <dt class="qap-section-title"
                  v-html="getSectionTitle(section.title)" />
              <dd v-for="(item, itemIdx) in section.items"
                  :key="itemIdx"
                  class="qap-list-item">
                <a class="qap-link"
                   :class="item.class"
                   :href="setUrlSource(item.url)"
                   v-html="item.title" />
                <p v-if="item.snippet" class="ds1-utd-size-1" v-html="item.snippet" />
              </dd>
            </dl>
          </div>
          <qap-drug-interactions :result="result"
                                 :source="source"
                                 :qap-tab="dxyForeignTabType" />
        </section>
      </utd-tab>
    </utd-tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { C_QAP } from '_acaSrc/utility/constants';
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import UtdSpinner from '_acaSrc/components/shared/utd/UtdSpinner.vue';
import QapDrugInteractions from './QapDrugInteractions.vue';
import { setQueryParamValue } from '_acaSrc/utility/http';
import {
    SET_DXY_DOSAGES_LIMIT,
    DEFAULT_DOSAGES_LIMIT
} from '_acaSrc/store/dxy.store';

const NO_DOSAGE_LIMIT = undefined;

export default {
    components: {
        UtdTabs,
        UtdTab,
        UtdSpinner,
        QapDrugInteractions
    },
    props: {
        result: Object,
        source: String
    },
    computed: {
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('dxy', [
            'dxyContent',
            'dxyDosagesLimit',
            'dxyHasMadeRequest',
            'dxyCancelSpinner',
            'dxyShowSpinner',
            'dxyHasError'
        ]),
        monograph() {
            return this.dxyContent.monograph;
        },
        monographUrl() {
            return this.dxyContent.monograph.url;
        },
        dxyForeignTabType() {
            return C_QAP.SECTION.DRUG_INTERACTIONS;
        },
        dosages() {
            const limitDosages = this.isDesktopView ? this.dxyDosagesLimit : NO_DOSAGE_LIMIT;

            return this.dxyContent.dosages
              && this.dxyContent.dosages.filter((dosage, dosageIdx) => {
                  return !limitDosages || (limitDosages && dosageIdx < limitDosages);
              });
        },
        hasDosagesLimit() {
            return this.dxyContent.dosages.length > DEFAULT_DOSAGES_LIMIT
                && this.dxyDosagesLimit
                && this.isDesktopView;
        },
        hasNoResults() {
            return !this.dxyContent.monograph
                && !this.dxyContent.dosages;
        },
        hasError() {
            return this.dxyHasError;
        }
    },
    methods: {
        ...mapMutations('dxy', [
            'SET_DXY_DOSAGES_LIMIT',
            'NO_DOSAGE_LIMIT',
            'DEFAULT_DOSAGES_LIMIT'
        ]),
        isForeignTab(type) {
            return type === C_QAP.PANEL.DRUG_FOREIGN;
        },
        setUrlSource(url) {
            return setQueryParamValue(url, 'source', this.source);
        },
        displayAllDosages() {
            this[SET_DXY_DOSAGES_LIMIT](NO_DOSAGE_LIMIT);
        },
        getSectionTitle(title) {
            // For some foreign tab QAP types, section titles include the
            // drug name prefixed onto the title.
            // Example: See foreign tab for "lidocaine" or "acyclovir".
            // For these cases, we just want to pass the title unmodified to the template.
            // Otherwise, everything up to and including the ': ' is stripped out of the title.
            // If there is no ': ' found, then we assume that we can run it through the
            // localization $t() method as these are most likely localization key strings.
            // Example: See foreign tab for "warfarin" or "aspirin"
            return title.indexOf(': ') > -1
                ? title
                : this.$t(title);
        }
    }
};
</script>

<style lang="less" scoped>
@import "../vue-qap.less";

.wkce-icon-medicine::before {
  .ds1-mr-1();
  font-size: 16px;
  position: relative;
  top: 2px;
}

.qap-list {
  .ds1-pt-2();
}

.qap-dxy-content {
  min-height: 100px;
}

.qap-dxy-foreign {
  .qap-list {
    .ds1-mh-2();
  }
}

.qap-section-container {
  .ds1-pb-2();
}

.utd-spinner-visible .qap-dxy-content {
  min-height: 300px;
}

</style>
