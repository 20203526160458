export const getMath = () => {
    // Used to allow for easier mocking of global "Math" object in unit tests
    return Math;
};

// Increment a number so that any overflow beyond a given length returns to the
// beginning (zero-based for convenience with arrays).
// Useful for rotating lists like an image carousel component with
// next / previous buttons.
export const incrementCarousel = (index, delta, length) => {
    if (delta !== 1 && delta !== -1) {
        throw new Error('Invalid "delta" argument passed, must be 1 or -1');
    }
    if (length < 1) {
        throw new Error('Argument "length" must be greater than 0');
    }
    if (length <= index) {
        throw new Error('Argument "length" must be greater than "index"');
    }
    const r = (index + delta) % length;
    return r < 0 ? length - 1 : r;
};

// eslint-disable-next-line complexity
export const roundToDecimalPlace = (input, places = 0) => {
    if (typeof input === 'undefined'
        || input === null
        || isNaN(input)
        || typeof places === 'undefined'
        || isNaN(places)) {
        return NaN;
    }

    const multiplier = getMath().pow(10, places);
    const sign = input < 0 ? -1 : 1;

    const rounded = getMath().floor(input * multiplier * sign + 0.5);

    let retval = rounded * sign / multiplier;
    // To ensure we don't return "-0"
    if (Object.is(retval, -0)) {
        retval = 0;
    }

    return retval;
};

/**
 * Generates a random number between the upper (inclusive) and lower (exclusive) bounds.
 *
 * @param { Number } min The lowerbound of the random number (inclusive)
 * @param { Number } max The upperbound of the random number (exclusive)
 * @returns { Number } The randomly generated number
 */
export const generateRandomNumber = (min, max) => {
    if (typeof min === 'undefined' || typeof max === 'undefined') {
        throw new Error('Expected two arguments, found one or more missing arguments');
    }
    if (typeof min !== 'number' || typeof max !== 'number') {
        throw new Error('Expected both arguments to be numbers, found one or more non-numbers');
    }

    let upperBound = max;
    let lowerBound = min;

    if (min > max) {
        upperBound = min;
        lowerBound = max;
    }
    return getMath().random() * (upperBound - lowerBound) + lowerBound;
};

/**
 * Will return whether or not a randomly generated number "hit" using the provided percent chance
 * @param { Number } percent The percent chance between 0-100 that a "hit" will occur
 * @returns { Boolean } Whether or not a "hit" occured
 */
export const percentChanceHit = percent => {
    if (percent < 0 || percent > 100) {
        throw new Error(`Expected percent to be between 0-100 inclusive, found ${percent}%`);
    }
    return generateRandomNumber(0, 100) < percent;
};

const MathUtils = {
    generateRandomNumber,
    percentChanceHit
};

export default MathUtils;