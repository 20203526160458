<template>
  <div v-if="isMobileView"
       class="utd-qap-v utd-qap-mobile-v"
       :class="{ 'qap-is-collapsed ds1-pb-1': isCollapsed }">
    <div class="utd-qap-mobile-slot ds1-pa-2">
      <component :is="componentName"
                 :result="result"
                 source="search_result" />
    </div>
    <div class="utd-qap-mobile-expand ds1-utd-size-2 ds1-mh-2">
      <div class="utd-qap-mobile-expand-arrow-v ds1-utd-weight-bold wkce-icon-chevron-down"
           @click="expandQap()" />
    </div>
  </div>
</template>

<script>
import { QAP_COMPONENTS as componentKeys } from '_acaSrc/utility/component-dictionary';
import { C_QAP } from '_acaSrc/utility/constants';
import QapPcu from '_acaQap/panels/QapPcu.vue';
import QapDrugDXY from '_acaQap/panels/QapDrugDXY.vue';
import QapDrugFormulation from '_acaQap/panels/QapDrugFormulation.vue';
import QapRoutedDrug from '_acaQap/panels/QapRoutedDrug.vue';
import QapPathways from '_acaQap/panels/QapPathways.vue';
import QapLabInterpretations from '_acaQap/panels/QapLabInterpretations.vue';
import QapDrugInformation from '_acaQap/panels/QapDrugInformation.vue';
import QapInternationalDrug from '_acaQap/panels/QapInternationalDrug.vue';
import { mapGetters, mapActions } from 'vuex';

const alwaysExpanded = {
    [C_QAP.PANEL.DRUG_ROUTED]: 'QapRoutedDrug'
};

export default {
    components: {
        QapPcu,
        QapDrugDXY,
        QapDrugFormulation,
        QapRoutedDrug,
        QapPathways,
        QapLabInterpretations,
        QapDrugInformation,
        QapInternationalDrug
    },
    props: {
        result: Object
    },
    data() {
        return {
            isCollapsed: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'isNotDesktopView' ]),
        ...mapGetters('qap', [ 'mobileCollapsed' ]),
        isMobileView() {
            return this.isNotDesktopView;
        },
        componentName() {
            return this.result
              && this.result.panelType
              && componentKeys[this.result.panelType] || '';
        }
    },
    mounted() {
        this.$nextTick(() => {
            // Never auto-collapse for these panel types
            if (alwaysExpanded[this.result.panelType]) {
                return;
            }

            if (typeof this.mobileCollapsed === 'undefined') {
                // Set collapsed state if QAP height exceeds threshold
                this.isCollapsed = this.qapHeightExceedsThreshold();
                this.setMobileCollapsed(this.isCollapsed);
            }
            else {
                this.isCollapsed = this.mobileCollapsed;
            }
        });
    },
    methods: {
        ...mapActions('qap', [ 'setMobileCollapsed' ]),
        expandQap() {
            this.isCollapsed = false;
            this.setMobileCollapsed(this.isCollapsed);
        },
        qapHeightExceedsThreshold() {
            return this.$el.offsetHeight > C_QAP.UI.DEFAULT_MOBILE_HEIGHT;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/global/global';
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@QAP-COLLAPSED-SHADOW-COLOR: rgba(0, 0, 0, 0.1);
@QAP-COLLAPSED-BOX-SHADOW: 0 -4px 8px 0 @QAP-COLLAPSED-SHADOW-COLOR;

.utd-qap-mobile-v {
  position: relative;
}

.qap-is-collapsed {
  &::after {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
    height: @ds1-spacing-multiplier * 5;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.qap-is-collapsed .utd-qap-mobile-slot {
  max-height: calc(300px - (@ds1-spacing-multiplier * 4));
  overflow: hidden;
  position: relative;
}

.utd-qap-mobile-expand {
  color: @DS1-UTD-JS2-LINK-BASE;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  border-radius: 0 0 4px 4px;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  box-shadow: @QAP-COLLAPSED-BOX-SHADOW;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
  display: none;

  .qap-is-collapsed & {
    display: block;
  }
}

.utd-qap-mobile-expand-arrow-v {
  .border-radius(50%);
  cursor: pointer;
  width: 28px;
  height: 28px;
  color: @DS1-UTD-JS2-LINK-BASE;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  z-index: 2;
  display: none;

  .qap-is-collapsed & {
    display: block;
  }

  &::before {
    position: relative;
    top: 6px;
  }
}

</style>