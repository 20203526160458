<template>
  <div v-show="isDesktopView"
       id="subscribeInterrupt"
       ref="subscribeInterrupt"
       :class="bannerClass">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="subscribe_header_message" v-html="headerMessage" />
    <div v-if="isUccState" v-text="headerMessage2" />
    <div class="wk-navbar">
      <ul class="wk-nav utd-right">
        <li role="presentation" class="wk-orange ds1-pr-4">
          <a :href="firstButtonAnchor" track="enabled">
            <img v-if="isUccState" src="/topic/hospital.png">
            <span v-text="firstButtonText" />
          </a>
        </li>
        <li role="presentation" :class="secondButtonClass">
          <a :href="secondButtonAnchor">
            <img v-if="isUccState" src="/topic/doctor.png">
            <span v-text="secondButtonText" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import {
    SET_TOOLBAR_FINDINTOPIC_VISIBLE,
    SET_TOPIC_TOOLS_SAMPLE_TOPIC
} from '_acaSrc/store/topic/topic.store';
import { getDocument } from '_acaSrc/utility/DOM';

export default {
    computed: {
        ...mapGetters('topic', [ 'topicLoaded', 'isTopicCalculator' ]),
        ...mapGetters('app', [
            'isProspectMode',
            'isUccState'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('auth', [ 'storeUrl' ]),
        headerMessage() {
            return this.isUccState
                ? this.$t('SUBSCRIBE_INTERRUPT.HEADER_MESSAGE_UCC')
                : this.$t('SUBSCRIBE_INTERRUPT.HEADER_MESSAGE');
        },
        firstButtonText() {
            return this.isUccState
                ? this.$t('SUBSCRIBE_INTERRUPT.INSTITUTIONAL_USER') : this.$t('HEADER.SUBSCRIBE');
        },
        secondButtonText() {
            return this.isUccState
                ? this.$t('SUBSCRIBE_INTERRUPT.PERSONAL_USER') : this.$t('MISC.LOG_IN');
        },
        secondButtonClass() {
            return this.isUccState ? 'wk-blue' : 'wk-green';
        },
        bannerClass() {
            return this.isUccState ? 'ucc-subscribe-message-banner' : 'subscribe-message-banner';
        },
        firstButtonAnchor() {
            return this.isUccState
                ? 'http://www.uptodate.cn/home/Institutional-Subscribers?SourceCode=TopicPreview'
                : this.storeUrl;
        },
        secondButtonAnchor() {
            return this.isUccState
                ? 'https://uptodatechina.secure.force.com/'
                + 'IndividualSubscribers?SourceCode=TopicPreview'
                : this.$t('SUBSCRIBE_INTERRUPT.LOGIN_LINK');
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.seekReplaceMessage();
        });
    },
    methods: {
        ...mapMutations('topic', [
            SET_TOOLBAR_FINDINTOPIC_VISIBLE,
            SET_TOPIC_TOOLS_SAMPLE_TOPIC
        ]),
        seekReplaceMessage() {
            if (!this.topicLoaded || !this.isProspectMode) {
                return;
            }

            const subMsg = getDocument().getElementById('subscribeMessage');
            if (!subMsg) {
                // If we cannot find the #subscribeMessage element, and this is
                // not for a topic calculator, as we know we're in prospect mode,
                // then this must be a sample topic.
                if (!this.isTopicCalculator) {
                    this[SET_TOOLBAR_FINDINTOPIC_VISIBLE](true);
                    this[SET_TOPIC_TOOLS_SAMPLE_TOPIC](true);
                }
                return;
            }
            else if (!this.isDesktopView) {
                // If we made it here, then this is not a sample topic, and we should display
                // the subscriber interrupt markup as it is returned from the server.
                return;
            }

            subMsg.parentNode.replaceChild(this.$refs.subscribeInterrupt, subMsg);
        }
    }
};
</script>

<style lang="less">
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles";

@DS1_BUTTON_HOVER_COLOR: @WKCE-ORANGE;

.subscribe-message-banner,
.ucc-subscribe-message-banner {
  text-align: center !important;
  border: 1px solid @DS1-UTD-DISABLED-TEXT-COLOR !important;
  border-radius: 0 !important;
  margin: 20px 0 !important;
  background: @DS1-LIGHT-ON-DARK-FONT-COLOR !important;
  padding: 0 !important;
  font-size: 1rem;
  filter: none !important;

  div {
    padding: 24px;
    line-height: 24px;

    &:first-child,
    &:nth-child(2) {
      font-weight: bold;
    }

    &:nth-child(2) {
      padding-top: 0;
    }

    a {
      text-decoration: none !important;
    }
  }

  .wk-navbar {
    z-index: @ZINDEX-SUBSCRIBE-MESSAGE;
    display: inline-block;
    background-color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    padding-top: 0;

    ul {
      li {
        height: 48px;

        a {
          text-decoration: none !important;
          font-weight: 400;
        }
      }
    }
  }
}

.ucc-subscribe-message-banner {
  background-color: rgba(242, 242, 242, 1) !important;
  border: none !important;

  .loginLink {
    color: #3c0;
  }

  .wk-navbar {
    background-color: rgba(242, 242, 242, 1);

    li {
      width: 180px !important;
      padding-right: 0 !important;

      a {
        border-radius: 5px;

        img {
          height: 40px;
          margin: -10px 0 -15px -10px;
          float: left;
        }
      }

      &:first-child {
        margin-right: 35px !important;
      }
    }

    .wk-orange {
      a:hover {
        background-color: @DS1_BUTTON_HOVER_COLOR !important;
      }
    }

    .wk-blue {
      a {
        background-color: #0083cb;

        &:hover {
          background-color: #0083cb !important;
        }
      }
    }
  }
}
</style>
