<template>
  <div class="utd-loading-screen">
    <div class="loading-screen__title">
      <img src="/footer/WK-Logo.svg" alt="Wolters Kluwer">
    </div>
    <div class="loading-screen__container">
      <div class="wkce-icon-spinner wk-spin" />
      <div class="loading-screen__text">
        <slot name="loadingText" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'UtdLoadingScreen'
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-SPINNER-COLOR: @WKCE-BLUE;

.utd-loading-screen {
  .ds1-ma-4();
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.loading-screen__title {
  .ds1-mb-2();
  display: flex;
  justify-content: center;
  flex-basis: 100%;
}

.loading-screen__container {
  text-align: center;
  max-width: 220px;
}

.wkce-icon-spinner {
  .ds1-mb-4();
  font-size: 24px;
  flex-basis: 100%;
  color: @DS1-SPINNER-COLOR;
}
</style>
