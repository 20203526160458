<script>
import { mapActions } from 'vuex';
import { getDocument } from '_acaSrc/utility/DOM';
import { C_LOCALIZATION } from '_acaSrc/utility/constants';

export default {
    data() {
        return {
            localGuidelines: []
        };
    },
    beforeUnmount() {
        this.localGuidelines.forEach(localGuideline => {
            const externalLinks = localGuideline.querySelectorAll('.external');
            externalLinks.forEach(link => {
                link && link.removeEventListener('click', this.handleLGExternalLinkClick);
            });
        });
    },
    methods: {
        ...mapActions('app', [ 'logEvent' ]),
        setUpLocalGuidelines({ languageCode }) {
            this.selectedLanguage = languageCode || C_LOCALIZATION.ENGLISH_CODE.toLowerCase();
            this.localGuidelines = getDocument().querySelectorAll('.local-guideline__row');
            this.localGuidelines.forEach(localGuideline => {
                const externalLinks = localGuideline.querySelectorAll('.external');
                externalLinks.forEach(link => {
                    this.attachLGLinkClickListener(link, localGuideline);
                });
            });
            return this.localGuidelines;
        },
        attachLGLinkClickListener(externalLink, localGuideline) {
            if (!externalLink) {
                return null;
            }
            externalLink.localGuidelineId = localGuideline && localGuideline.id;
            externalLink.addEventListener('click', this.handleLGExternalLinkClick);
        },
        handleLGExternalLinkClick(e) {
            this.logEvent({
                eventUrl: 'event/machineTranslationClick/json',
                eventParams: {
                    referringUrl: window.location.href,
                    uiElementName: 'localGuidelinesLink',
                    optData: e && e.target && e.target.localGuidelineId,
                    contentId: this.contentId,
                    languageCode: this.selectedLanguage
                }
            });
        }
    }
};
</script>