import RatingGuide from '_acaSrc/utility/PendoGuides/RatingGuide';
import FeedbackGuide from '_acaSrc/utility/PendoGuides/FeedbackGuide';
import ResourceCenterGuide from '_acaSrc/utility/PendoGuides/ResourceCenterGuide';
import TranslationFeedbackGuide from '_acaSrc/utility/PendoGuides/TranslationFeedbackGuide';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import Logger from '_acaSrc/utility/Logger';
import PubSub from '_acaSrc/utility/PubSub';

export const newStoreGuide = () => {
    return {
        isSetup: false,
        isReady: false,
        guide: null
    };
};

const VALID_GUIDES = {
    RatingGuide,
    FeedbackGuide,
    ResourceCenterGuide,
    TranslationFeedbackGuide
};

const state = {};

export const getters = {
    getPendoGuide: state =>
        guideName => state[guideName] && state[guideName].guide,
    isGuideSetup: state =>
        guideName => state[guideName] && state[guideName].isSetup,
    isGuideReady: state =>
        guideName => state[guideName] && state[guideName].isReady,
    lastGuideError: state =>
        guideName => state[guideName] && state[guideName].lastError,
    // Specific guide getters
    showRatingGuide: (state, getters, rootState, rootGetters) => getters.isGuideReady('RatingGuide')
            && !rootState.app.isPrintView
            && !rootGetters['app/isProspectMode'],
    showFeedbackGuide: (state, getters) => getters.isGuideReady('FeedbackGuide'),
    showResourceCenterGuide: (state, getters) => {
        return getters.isGuideReady('ResourceCenterGuide')
            && getters.getPendoGuide('ResourceCenterGuide')
            && getters.getPendoGuide('ResourceCenterGuide')._hasResourceCenterContent();
    }
};

export const SET_GUIDE_READY = 'SET_GUIDE_READY';
export const SET_GUIDE_SETUP = 'SET_GUIDE_SETUP';
export const CREATE_NEW_GUIDE = 'CREATE_NEW_GUIDE';
export const INSTANTIATE_GUIDE = 'INSTANTIATE_GUIDE';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const mutations = {
    [SET_GUIDE_READY]: (state, payload) => {
        const { guideName, guideReady } = payload;
        if (state[guideName]) {
            state[guideName].isReady = guideReady;
        }
    },
    [SET_GUIDE_SETUP]: (state, guideName) => {
        if (state[guideName]) {
            state[guideName].isSetup = true;
        }
    },
    [CREATE_NEW_GUIDE]: (state, guideName) => {
        state[guideName] = newStoreGuide();
    },
    [INSTANTIATE_GUIDE]: (state, guideName) => {
        if (!state[guideName]) {
            return;
        }

        try {
            state[guideName].guide = new VALID_GUIDES[guideName](`${guideName}-isReady`);
        }
        catch (error) {
            state[guideName].lastError
                = `pendo.store::INSTANTIATE_GUIDE(${guideName}) - ${error}`;
        }
    },
    [CLEAR_ERROR]: (state, guideName) => {
        if (state[guideName]) {
            state[guideName].lastError = '';
        }
    }
};

export const actions = {
    setupGuide({ getters, commit, dispatch }, payload) {
        const { guideName, afterSetup } = payload;

        if (!isValidGuide(guideName)) {
            Logger.error(`pendo.store::setupGuide() - Cannot setup invalid guide [${guideName}].`);
            return;
        }

        if (!getters.isGuideSetup(guideName)) {
            commit('CREATE_NEW_GUIDE', guideName);

            dispatch('app/subscribe', {
                eventName: `${guideName}-isReady`,
                handlerFn: async() => {
                    const guide = getters.getPendoGuide(guideName);
                    if (guide && guide.isShowGuide) {
                        const guideReady = await guide.isShowGuide();
                        commit('SET_GUIDE_READY', { guideName, guideReady });
                    }
                }
            }, { root: true });

            commit('INSTANTIATE_GUIDE', guideName);
            const lastError = getters.lastGuideError(guideName);
            if (lastError) {
                Logger.error(lastError);
                commit('CLEAR_ERROR', guideName);
                return;
            }

            if (afterSetup && typeof afterSetup === 'function') {
                afterSetup();
            }

            commit('SET_GUIDE_SETUP', guideName);
        }
    },
    launchGuide({ getters }, payload) {
        const { guideName, guideData } = payload;
        if (getters.isGuideReady(guideName)) {
            const guide = getters.getPendoGuide(guideName);
            if (guide && guide.launchGuide) {
                getWindow().pendoGuideData = guideData;
                guide.launchGuide();
            }
        }
    },
    setPendoScript({ rootState }, data) {
        if (typeof pendo === 'undefined'
        && !!data.pendoApiKey.trim()
        && !!data.pendoAgentScript.trim()) {
            if (rootState.profile.userProfile.pendoStateMessage === '') {
                const eleBody = getDocument().getElementsByTagName('body');
                const eleSrc = getDocument().createElement('script');
                eleSrc.id = 'pendoScriptTag';
                getWindow().onPendoGuidesLoaded = () => {
                    new PubSub().publish('pendo-guides-ready', pendo);
                };
                eleSrc.innerText = `(function(apiKey){
                    ${data.pendoAgentScript}pendo.initialize(
                        {
                            disablePersistence: true,
                            visitor: ${JSON.stringify(rootState.profile.userProfile.visitor)},
                            account: ${JSON.stringify(rootState.profile.userProfile.account)},
                            events: {
                                guidesLoaded: function(){
                                    window.onPendoGuidesLoaded();
                                }
                            }
                        });
                })('${data.pendoApiKey}');`;
                eleBody[0].appendChild(eleSrc);
            }
        }
    },
    initializePendo({ rootState, dispatch }) {
        if (rootState.profile.userProfile.pendoStateMessage === '') {
            if (typeof pendo !== 'undefined') {
                dispatch('app/delayExecuteUntilDataLoaded', function() {
                    try {
                        // Calling Pendo identify for user
                        pendo.identify({
                            usePendoAgentAPI: true,
                            visitor: rootState.profile.userProfile.visitor,
                            account: rootState.profile.userProfile.account
                        });

                        // Uncomment this block in dev environments to
                        // receive confirmation of Pendo initialization
                        // try {
                        // 	pendo.validateInstall();
                        // }
                        // catch (v){
                        // 	console.log("Error ["+v+"] :: pendo.validateInstall()");
                        // }
                    }
                    catch (e) {
                        Logger.warn(`Error pendo.identify(): ${e}`);
                    }
                }, { root: true });
            }
        }
        else {
            Logger.warn(rootState.profile.userProfile.pendoStateMessage);
        }
    }
};

const pendoStore = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default pendoStore;

export const isValidGuide = guideName => {
    return !!VALID_GUIDES[guideName];
};
