<template>
  <utd-modal-dialog ref="modalDialog"
                    :close-on-overlay-click="false"
                    :close-on-escape="false"
                    modal-classes="next-steps-learn-more-modal"
                    dialog-size="large"
                    @closed="hideOverlay()">
    <template #header>Learn more about
      {{ nextStepsModalHeader }}
    </template>

    <div class="next-steps__learn-more">
      <div class="next-steps__container">
        <div class="next-steps__info">
          <h4 class="next-steps__header">
            {{ nextStepsContentHeader }}
          </h4>
          <ul v-if="keepOneAccount" class="next-steps__info-list">
            <li>You can sign in with your organization email,
              <span class="ds1-utd-weight-bold next-steps__email">{{ email }}</span>.
              <ul class="ds1-mb-0">
                <li>You will no longer sign in with an UpToDate username and password.</li>
              </ul>
            </li>
            <li>Your "Continuing Medical Education" (CME)
              credits, history, and bookmarks will be kept in one account.</li>
          </ul>
          <ul v-else class="next-steps__info-list">
            <li>You can sign in to UpToDate with either:</li>
            <ul>
              <li>Your existing username and password</li>
              <div class="next-steps__separator">or</div>
              <li>Single Sign-On (SSO) using your organization email,
                <span class="ds1-utd-weight-bold next-steps__email">{{ email }}</span>.
              </li>
            </ul>
            <li>Your "Continuing Medical Education" (CME)
              credits, history, and bookmarks will be kept in each account separately.</li>
          </ul>
        </div>
        <div class="next-steps__info">
          <h4 class="next-steps__header">
            Your next steps for this option would be to:
          </h4>
          <ul v-if="keepOneAccount">
            <li>Sign into your new account with Single Sign-On (SSO).</li>
            <li>Enter your UpToDate password for your existing account,
              and UpToDate will do the rest.</li>
          </ul>
          <ul v-else>
            <li>Enter your UpToDate password and select a new username for your existing account.</li>
          </ul>
        </div>
      </div>
      <utd-customer-support />
    </div>

    <template #footer>
      <utd-button button-size="large" @click="close()">OK</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import PubSub from '_acaSrc/utility/PubSub';
import UtdCustomerSupport from '../shared/stdlib/UtdCustomerSupport.vue';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UtdCustomerSupport
    },
    props: {
        email: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            keepOneAccount: false
        };
    },
    computed: {
        nextStepsModalHeader() {
            return this.keepOneAccount
                ? 'keeping one UpToDate account'
                : 'keeping two UpToDate accounts';
        },
        nextStepsContentHeader() {
            return `If you keep ${this.keepOneAccount
                ? 'one UpToDate account'
                : 'two UpToDate accounts'}:`;
        }
    },
    methods: {
        open({ keepOneAccount }) {
            this.keepOneAccount = keepOneAccount;
            this.$refs.modalDialog.open();
        },
        close() {
            this.$refs.modalDialog.close();
            this.hideOverlay();
        },
        hideOverlay() {
            new PubSub().publish('wkutd.closeNextStepsInnerDialog');
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.next-steps__container {
  .ds1-mb-4();
}

.next-steps__learn-more {
  .ds1-pa-2();
}

.next-steps__info-list {
  line-height: 26px;
}

.next-steps__email {
  word-wrap: break-word;
}

.next-steps__separator {
  overflow: hidden;
  text-align: center;
  max-width: 250px;
}

.next-steps__separator::before,
.next-steps__separator::after {
  background-color: @WKCE-GRAY-TINT3;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.next-steps__separator::before {
  right: 8px;
  margin-left: -50%;
}

.next-steps__separator::after {
  left: 8px;
  margin-right: -50%;
}
</style>