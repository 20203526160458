<template>
  <div v-if="result"
       class="graphics-panel">
    <div v-if="result.title"
         class="graphics-panel-title">KEY GRAPHICS:
      <span class="graphics-panel__title-text">{{ result.title }}</span>
    </div>
    <utd-expand-collapse :max-height="341"
                         @isCollapsed="logEvent($event)">
      <div class="graphics__container">
        <utd-media-collection class="collection-type-thumbnails collection-display-row"
                              :media-collection="result.featuredGraphics"
                              v-bind="$attrs"
                              @onGvdInit="setGVDGraphics" />
      </div>
    </utd-expand-collapse>
    <utd-pendo-feedback class="graphics-panel-feedback"
                        :feedback-data="feedbackData" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { C_GRAPHICS, C_SEARCH, C_EVENTS } from '_acaSrc/utility/constants';
import { SET_GRAPHIC_VIEWER_ACCORDION_ITEMS } from '_acaSrc/store/graphic.store';
import GraphicsPanelResults from '_acaSrc/utility/data-adapters/graphics-panel-adapter';
import UtdMediaCollection from '_acaSrc/components/shared/stdlib/UtdMediaCollection.vue';
import UtdExpandCollapse from '_acaSrc/components/shared/utd/UtdExpandCollapse.vue';
import PubSub from '_acaSrc/utility/PubSub';
import UtdPendoFeedback from '_acaSrc/components/shared/utd/UtdPendoFeedback.vue';

export default {
    components: {
        UtdMediaCollection,
        UtdExpandCollapse,
        UtdPendoFeedback
    },
    props: {
        result: GraphicsPanelResults
    },
    computed: {
        feedbackData() {
            return {
                featureType: 'Graphics Panel',
                featureId: this.result.id,
                featureTitle: this.result.title
            };
        }
    },
    methods: {
        ...mapActions('graphic', [ 'setGraphicViewerGraphics' ]),
        ...mapMutations('graphic', [ SET_GRAPHIC_VIEWER_ACCORDION_ITEMS ]),
        setGVDGraphics() {
            this.setGraphicViewerGraphics({ graphics: this.result.featuredGraphics });
            this[SET_GRAPHIC_VIEWER_ACCORDION_ITEMS]([
                C_GRAPHICS.SIDEBAR_ACCORDION_AIT,
                C_GRAPHICS.SIDEBAR_ACCORDION_OG
            ]);
        },
        logEvent(isCollapsed) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: C_SEARCH.SOURCE.GRAPHICS_PANEL,
                optData: `{"action":"${isCollapsed
                    ? 'collapse'
                    : 'expand'}","id":"${this.result.id}"}`
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphics-panel {
  position: relative;

  :deep(.utd-expand-collapse__expand-arrow) {
    top: 0;
  }

  .graphics__container {
    .ds1-pl-0();
    .ds1-pr-2();
    .ds1-pv-2();
    background-color: @DS1-UTD-GRAY-BG03-COLOR;

    :deep(.wk-media-collection) {
        .collection-thumbnails .utd-thumbnail__container {
            .ds1-mr-2();
        }
    }
  }

  .graphics-panel-feedback {
    position: absolute;
    z-index: @ZINDEX-FEATURED-RESULT-FEEDBACK;
    right: 0;
    bottom: 0;
  }
}

.graphics-panel-title {
    .ds1-ph-2();
    .ds1-pv-1();
    color: @DS1-UTD-GRAY-TEXT-COLOR;
    background-color: @DS1-UTD-GRAY-BG-COLOR;
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
}

.graphics-panel__title-text {
    .ds1-utd-weight-600();
    color: @DS1-UTD-TOPIC-TEXT-COLOR;
}

@media only screen and (min-width: 768px) {
  .isDesktop .graphics-panel {
      .ds1-pt-0();
      max-width: 740px;

      .graphics__container {
        .ds1-ph-2();
        .ds1-pv-0();
        .ds1-mb-1();
        background-color: @DS1-UTD-PRIMARY-BG-COLOR;
      }

      .graphics-panel-title {
          background-color: @DS1-UTD-PRIMARY-BG-COLOR;
          border-bottom: 0;
      }
  }
}
</style>
