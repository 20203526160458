<template>
  <div class="print-header">
    <div class="print-header-section-left">
      <img class="wk-wheel"
           src="/logos/utd-wheel-icon.svg"
           alt="Wolters Kluwer Health">
      <img class="utd-print-logo"
           :src="utdLogoSrc"
           :alt="utdLogoAlt">
      <div class="print-header-text">
        <p v-html="$t('HEADER.OFFICIAL_REPRINT')" />
        <a href="https://www.uptodate.com" aria-label="UpToDate home page">www.uptodate.com</a>
        <utd-copyright-provider :full="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import { utdCcLogoAlternateText } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdCopyrightProvider
    },
    data() {
        return {
            utdCcLogoAlt: utdCcLogoAlternateText
        };
    },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        utdLogoSrc() {
            return `/app/utd-menu/utd${this.isUccState ? 'cc' : ''}-logo.svg`;
        },
        utdLogoAlt() {
            return this.isUccState ? utdCcLogoAlternateText : 'UpToDate';
        }
    }
};
</script>

<style lang="less" scoped>
@import '~_acaAssets/global/variables';
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.print-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: @NOTOSANS_FONT_STACK;
  .ds1-utd-size-1();
  .ds1-pt-1();

  > .print-header-section-left {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > img {
      .ds1-mr-2();
    }

    > .wk-wheel {
      width: 40px;
      height: auto;
      .ds1-ml-2();
    }

    > .print-header-text {
      .ds1-pb-3();

      > p {
        .ds1-ma-0();
      }

      .copyright {
        font-size: 10px;
        line-height: 14px;
        color: @DS1-UTD-TOPIC-TEXT-COLOR;
        display: inline-block;
      }
    }
  }
}

.utd-print-logo {
  width: 130px;
  height: auto;
}
</style>

<style lang="less">
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.print-header-text {
    .ds1-pb-3();
    font-size: 10px;
    line-height: 14px;
    padding-top: 6px;

    > p {
      .ds1-ma-0();
    }
  }
 
.utd-logo-cc {
  .utd-print-logo {
    width: 210px;
    height: auto;
    margin-top: 4px;
  }

  .copyright {
      font-size: 9px;
      line-height: 13px;
    }

  .print-header-text {
    font-size: 9px;
    line-height: 13px;
  }
}
</style>