<template>
  <div v-show="isActive"
       :id="panelId"
       :aria-labelledby="currentTabId"
       :aria-hidden="!isActive"
       class="wk-tab-inner-content"
       tabindex="0"
       role="tabpanel">
    <slot />
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
    props: {
        title: {
            type: String,
            default: 'Tab'
        },
        isHidden: {
            type: Boolean,
            default: false
        },
        tabId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isActive: true,
            panelId: '',
            currentTabId: ''
        };
    },
    beforeCreate() {
        const addTab = inject('addTab');
        addTab(this);
    },
    mounted() {
        const children = this.$parent.$el.children;
        const panelIndex = Array.prototype.indexOf.call(children, this.$el) - 1;
        this.panelId = `${this.$parent.uuid}-panel-${panelIndex}`;
        this.currentTabId = this.tabId || `${this.$parent.uuid}-tab-${panelIndex}`;
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-tab-inner-content {
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
}

</style>
