<template>
  <div>
    <div v-if="isShowBackButton" class="contributors-toolbar large">
      <toolbar-back-link />
    </div>
    <dl class="utd-tabControl">
      <dt v-text="`${reviewers.specialtyInfo.displayName} Peer Reviewers`" />
    </dl>

    <div id="contributors">
      <section>
        <div v-for="(contributorTypes,index) in reviewers.contributorsByType" :key="`cbt${index}`">
          <dl v-for="(contributor,index2) in contributorTypes.contributors" :key="`cnt${index2}`">
            <dt class="contributorName" v-text="contributor.contributorInfo.name" />
            <dd
              v-for="(association,index3) in contributor.contributorInfo.associations"
              :key="`asc${index3}`"
              class="contributorCredential"
              v-text="association"
            />
            <dt class="contributorCredential" v-text="contributor.contributorInfo.disclosure" />
          </dl>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { browserHasHistory } from '_acaSrc/utility/Browsers';
import { mapGetters } from 'vuex';
import ToolbarBackLink from '_acaSrc/components/shared/providers/UtdToolbarBackLinkProvider.vue';

export default {
    components: {
        ToolbarBackLink
    },
    props: {
        reviewers: Object
    },
    computed: {
        ...mapGetters('app', [ 'isAlwaysShowBackButton' ]),
        isShowBackButton() {
            return this.isAlwaysShowBackButton && browserHasHistory();
        }
    }
};
</script>
