import { millisecondsToDaysMultiplier } from './constants';
import { isNumericOnly } from '_acaSrc/utility/String';

const WkceDates = {
    getDMY(date) {
        return {
            day: `00${date.getDate()}`.slice(-2),
            month: `00${date.getMonth() + 1}`.slice(-2),
            year: `0000${date.getFullYear()}`.slice(-4)
        };
    },

    dateToYMDString(date) {
        const { year, month, day } = this.getDMY(date);
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    },

    dateToMDYString(date) {
        const { year, month, day } = this.getDMY(date);
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    },
    getDaysLeft(expirationDateISO8601) {
        const now = new Date();
        const expiration = new Date(expirationDateISO8601);
        const daysLeft = (expiration - now) * millisecondsToDaysMultiplier;
        return Math.max(1, Math.ceil(daysLeft));
    },
    dateToMDYUSLocale(date, options = { year: 'numeric', month: 'short', day: 'numeric' }) {
        // Dec 20, 2012
        date = this.toDate(date);
        if (!date) {
            return '';
        }

        if (typeof date === 'string') {
            return date;
        }

        return date.toLocaleString('en-US', options);
    },
    dateToTime(date) {
        // 3:00 AM
        date = this.toDate(date);
        if (!date) {
            return '';
        }

        if (typeof date === 'string') {
            return date;
        }

        const options = { hour: 'numeric', minute: '2-digit', hour12: true };
        return date.toLocaleString('en-US', options);
    },
    dateToMDYTime(date) {
        // Dec 20, 2012 3:00 AM
        return `${this.dateToMDYUSLocale(date)} ${this.dateToTime(date)}`;
    },
    toDate(value) {
        if (typeof value === 'string' && isNumericOnly(value)) {
            value = parseInt(value, 10);
        }
        if (typeof value === 'number') {
            value = new Date(value);
        }
        if (Object.prototype.toString.call(value) === '[object Date]') {
            return value;
        }
        if (typeof value === 'string') {
            return value;
        }
        return '';
    }
};

export default WkceDates;
