<template>
  <div class="outlineToggle"
       tabindex="0"
       :aria-expanded="outlineFlyoutShowOutline"
       aria-haspopup="true"
       role="button"
       :aria-label="`View topic outline for ${result.title}`"
       @keypress.space.prevent="handleMouseMoveOutlineToggleFlyout($event)"
       @keypress.enter.prevent="handleMouseMoveOutlineToggleFlyout($event)"
       @mousemove="handleMouseMoveOutlineToggleFlyout($event)"
       @mouseout="handleMouseLeaveOutlineToggleFlyout()"
       @blur="handleMouseLeaveOutlineToggleFlyout()">
    <span class="wkce-icon-list-bullet-square" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getWindow, getDocument } from '_acaSrc/utility/DOM';
import { C_PAGE_TYPE } from '_acaSrc/utility/constants';
import {
    SET_OUTLINE_FLYOUT_OUTLINE_LOADING,
    SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK,
    SET_OUTLINE_FLYOUT_SHOW_OUTLINE,
    SET_OUTLINE_FLYOUT_TIMEOUT_HIDE,
    SET_OUTLINE_FLYOUT_TIMEOUT_OPEN,
    SET_RESULT_HOVERED,
    CLEAR_RESULT_HOVERS
} from '_acaSrc/store/search.store';

const DELAY_MS_GET_OUTLINE = 500;
const DELAY_MS_OPEN_OUTLINE = 200;
const RENDERING_TIMEOUT = 250;
const RESULT_HOVER_CLASS = 'result-list-hovered';

export default {
    props: {
        result: {
            type: Object,
            default() {
                return {};
            }
        },
        index: {
            type: Number
        }
    },
    data() {
        return {
            element: null,
            eleOutlineContainer: null,
            eleTopicLink: null,
            eleLastNestedResult: null,
            timeoutGetOutlineFlyout: null
        };
    },
    computed: {
        ...mapGetters('search', [
            'outlineFlyoutShowOutline',
            'outlineFlyoutTopicId',
            'outlineFlyoutLoading',
            'timeoutOpenOutlineFlyout',
            'timeoutHideOutlineFlyout'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('user', [ 'isCustomer' ])
    },
    beforeUnmount() {
        this.element && this.element.removeEventListener('mouseenter', this.generateFlyoutButton);
        this.eleTopicLink
          && this.eleTopicLink.removeEventListener('focus', this.generateFlyoutButton);
        this.eleLastNestedResult
          && this.eleLastNestedResult.removeEventListener('focus', this.generateFlyoutButton);
    },
    mounted() {
        setTimeout(() => {
            this.element = this.$el && this.$el.parentNode;
            if (this.element) {
                this.eleOutlineContainer = getDocument().getElementById('outlineContainer');
                this.eleTopicLink = this.element.querySelector('.searchResultLink');
                this.eleLastNestedResult = this.element.querySelector('.section:last-child a');

                this.activate();
            }
        }, RENDERING_TIMEOUT);
    },
    methods: {
        ...mapActions('app', [ 'publish' ]),
        ...mapActions('topic', [ 'hideOrShowPathways' ]),
        ...mapActions('search', [ 'getTopicOutline' ]),
        ...mapMutations('search', [
            SET_OUTLINE_FLYOUT_SHOW_OUTLINE,
            SET_OUTLINE_FLYOUT_OUTLINE_LOADING,
            SET_OUTLINE_FLYOUT_TIMEOUT_OPEN,
            SET_OUTLINE_FLYOUT_TIMEOUT_HIDE,
            SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK,
            SET_RESULT_HOVERED,
            CLEAR_RESULT_HOVERS
        ]),
        // eslint-disable-next-line complexity
        activate() {
            // Do not generate topic outline flyouts for:
            // - Lexi drug topics ("dip")
            // - VisualDx topics ("visualdx")
            // - Calculator topics ("calc")
            // - UpToDate Pathways ("icg")
            // - Result panel ("kpBase")
            // - Quick Access Panel ("QAP")
            // - Unauthenticated/prospect users
            // - Featured Search Results - KPP & GP
            // - Graphical Answers ("GA")
            // - RX Transitions ("rx_transitions")
            // - Disable for routed drug topics for the moment...
            if (!this.element.classList.contains('dip')
                && !this.element.classList.contains('visualdx')
                && !this.element.classList.contains('calc')
                && !this.element.classList.contains('ICG')
                && !this.element.classList.contains('QAP')
                && !this.element.classList.contains('kpBase')
                && !this.element.classList.contains('featuredResult')
                && !this.element.classList.contains('GA')
                && !this.element.classList.contains('rx_transitions')
                && this.result.pageType !== C_PAGE_TYPE.DRUG_LANDING_PAGE
                && this.result.pageType !== C_PAGE_TYPE.FORMULATION_PAGE
                && this.isCustomer
                && this.isDesktopView) {

                this.setResultEntryHandler();
            }
        },
        handleMouseMoveOutlineToggleFlyout(e) {
            if (!this.isDesktopView) {
                return;
            }

            // If the user quickly moves from one result to another, and then back
            // to the original, then just cancel the lookup for the new outline
            if (this.element.id === this.outlineFlyoutTopicId
             && this.outlineFlyoutLoading === 'outlineLoading') {
                clearTimeout(this.timeoutGetOutlineFlyout);
                this[SET_OUTLINE_FLYOUT_OUTLINE_LOADING]('');
            }

            if (this.timeoutOpenOutlineFlyout === null) {
                const timeoutOpenFlyout
                    = setTimeout(this.positionShowFlyout, DELAY_MS_OPEN_OUTLINE);
                this[SET_OUTLINE_FLYOUT_TIMEOUT_OPEN](timeoutOpenFlyout);
            }

            this.cancelHideFlyout();
            e.stopPropagation();
        },
        handleMouseLeaveOutlineToggleFlyout() {
            clearTimeout(this.timeoutOpenOutlineFlyout);
            this[SET_OUTLINE_FLYOUT_TIMEOUT_OPEN](null);
        },
        positionShowFlyout() {
            this.publish({
                eventName: 'SHOW_OUTLINE_FLYOUT',
                eventData: {
                    height: this.$el.offsetHeight,
                    width: this.$el.offsetWidth,
                    top: this.$el.getBoundingClientRect().top,
                    left: this.$el.offsetLeft
                }
            });
        },
        cancelHideFlyout() {
            if (!this.timeoutHideOutlineFlyout) {
                return;
            }
            // Stops the hiding of the topic flyout
            clearTimeout(this.timeoutHideOutlineFlyout);
            this[SET_OUTLINE_FLYOUT_TIMEOUT_HIDE](null);
            this[SET_OUTLINE_FLYOUT_SHOW_OUTLINE](true);
        },
        setFocusOnFirstLink() {
            if (!this.outlineFlyoutShowOutline) {
                return;
            }
            // Focus the first element on topic outline when it becomes visible
            const eleOutlineHeading = this.eleOutlineContainer.querySelector('a');
            eleOutlineHeading && eleOutlineHeading.focus();
        },
        setResultEntryHandler() {
            this.element.addEventListener('mouseenter', this.generateFlyoutButton);
            this.eleTopicLink
                && this.eleTopicLink.addEventListener('focus', this.generateFlyoutButton);
            this.eleLastNestedResult
                && this.eleLastNestedResult.addEventListener('focus', this.generateFlyoutButton);
        },
        generateFlyoutButton() {
            if (!this.isDesktopView
                || this.element.classList.contains(RESULT_HOVER_CLASS)) {
                return;
            }
            // Remove any other hovered states first
            this[CLEAR_RESULT_HOVERS]();

            this.eleOutlineContainer.scrollTop = 0;

            // Initialize call to get outline after delay
            this[SET_OUTLINE_FLYOUT_OUTLINE_LOADING]('outlineLoading');
            // Cancel any current topic outline request first
            getWindow().clearTimeout(this.timeoutGetOutlineFlyout);
            this.timeoutGetOutlineFlyout = setTimeout(() => {
                this[SET_OUTLINE_FLYOUT_SHOW_GRAPHICS_LINK](false);
                this.getTopicOutline({
                    topicId: this.element.id,
                    language: this.result.languageCode
                }).then(() => {
                    this.setFocusOnFirstLink();
                    setTimeout(() => {
                        this.hideOrShowPathways();
                    }, RENDERING_TIMEOUT);
                });
            }, DELAY_MS_GET_OUTLINE);
            this[SET_RESULT_HOVERED](this.index);
        },
        clearResultHovers() {
            const hovered = getDocument().querySelectorAll(`.${RESULT_HOVER_CLASS}`);
            Array.from(hovered).forEach(elem => {
                elem.classList.remove(RESULT_HOVER_CLASS);
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

#searchresults .utd-listView {
  ul.DRUG_LANDING_PAGE li {
    .outlineToggle {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    ul.utd-listView li {
      .outlineToggle {
        display: none;
      }

      &:hover.result-list-hovered,
      &.outlineFlyoutIsOpen.result-list-hovered {
        .outlineToggle {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 32px;
          box-sizing: border-box;
          border-left: @DS1-UTD-GRAY-SINGLE-BORDER;
          background-color: @DS1-FLYOUT-TOGGLE-BGCLR;
          cursor: pointer;

          &:hover {
            background-color: @DS1-FLYOUT-TOGGLE-HOVER-BGCLR;
          }

          .wkce-icon-list-bullet-square {
            &::before {
              color: @DS1-UTD-JS2-ACTIVE-HOVER-BG-COLOR;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
