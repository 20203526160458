<template>
  <div v-if="tabbedContent.length"
       class="utasc__container wk-js2 tabbed-accordion">
    <div class="tabs__container">
      <utd-tabs :class="$attrs['tab-style']"
                :active-tab="activeTabIndex"
                :tab-style="tabVariant"
                :disable-tabs="isTabsDisabled"
                :enable-carousel="true"
                v-bind="$attrs">
        <utd-tab v-for="(content, contentIdx) in tabbedContent"
                 :key="contentIdx"
                 :title="content.tabLabel"
                 :is-hidden="isTabHidden"
                 :tab-id="tabId">
          <div class="accordion__container">
            <utd-accordion :accordion-style-class="accordionVariant"
                           :opened-index="accordionToOpen(content.accordionData)"
                           :accordion-items="content.accordionData"
                           :is-mutex-accordion="isMutexAccordion"
                           :disable-arrow-keys="disableArrowKeys"
                           :class="accordionClassArray[contentIdx]"
                           v-bind="$attrs">
              <template #content="slotProps">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="accordion-text__container" v-html="slotProps.item.text" />
                <slot name="accordion-media-container" :item="slotProps.item" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="accordion-practice-points__container" v-html="slotProps.item.practicePoints" />
              </template>
            </utd-accordion>
          </div>
          <div v-if="sideContentAvailable(content)"
               class="side-content__container">
            <slot name="side-content" :content="content" />
          </div>
        </utd-tab>
      </utd-tabs>
    </div>
  </div>
</template>

<script>
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';

export default {
    components: {
        UtdTab,
        UtdTabs,
        UtdAccordion
    },
    props: {
        tabbedContent: {
            type: Array,
            default: () => []
        },
        activeTabIndex: {
            type: Number,
            default: 0
        },
        tabVariant: {
            type: String,
            default: 'wk-tabs-default'
        },
        isTabsDisabled: {
            type: Boolean,
            default: false
        },
        isTabHidden: {
            type: Boolean,
            default: false
        },
        tabId: {
            type: String,
            default: ''
        },
        accordionVariant: {
            type: String,
            default: ''
        },
        openAccordionAtIdx: {
            type: Number,
            default: -1
        },
        isMutexAccordion: {
            type: Boolean,
            default: false
        },
        disableArrowKeys: {
            type: Boolean,
            default: true
        },
        accordionClassArray: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        accordionToOpen(accordionData) {
            return accordionData.length === 1 ? 0 : this.openAccordionAtIdx;
        },
        sideContentAvailable(content) {
            return !!(content.sideContent && content.sideContent.length > 0);
        }
    }
};
</script>