<template>
  <div role="menuitem" class="login-register-buttons__container">
    <ul class="wk-nav utd-right">
      <li v-if="showRegisterButton"
          role="presentation"
          class="register-btn"
          :class="{ 'wk-orange': !isHomepageRedesign2024 }"><!--
        --><a :href="registerHref" v-text="registerButtonText" /><!--
    --></li>
      <li v-if="!isLoggedIn" role="presentation" class="wk-green login-btn"><!--
        --><a href="/login" v-text="loginButtonText" /><!--
    --></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('login', [ 'loginRegisterButton', 'loginHref' ]),
        ...mapGetters('feature', [ 'isHomepageRedesign2024' ]),
        registerButtonText() {
            return this.$t('LOGIN.REGISTER');
        },
        loginButtonText() {
            return this.$t('MISC.LOG_IN');
        },
        registerHref() {
            return `${this.loginHref}?source=header`;
        },
        isLoggedIn() {
            return this.userLoggedIn;
        },
        showRegisterButton() {
            return this.loginRegisterButton
                && !this.isLoggedIn;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';
.utdWkHomePage2024 .login-register-buttons__container .wk-nav >li {
    margin-top: 11px;

    >a {
      .ds1-pa-1();
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    &.login-btn {
      margin-left: 12px;

      >a {
        background-color: @DS2-UTD-NAVBAR-LOGIN-BG-COLOR;

        &:hover {
          background-color: @DS2-UTD-NAVBAR-LOGIN-HOVER-COLOR;
        }
      }
    }

    &.register-btn>a {
      background-color: @DS2-UTD-NAVBAR-REGISTER-BG-COLOR;

      &:hover {
        background-color: @DS2-UTD-NAVBAR-REGISTER-HOVER-COLOR;
      }
    }
  }


@media (min-width: 37.5rem) {
  .utdWkHeader .wk-navbar .wk-navbar-container {
    .mobileonDesktop&,
    .isSmallScreen&,
    .isTablet & {
      .login-register-buttons__container .wk-nav li {
        margin: 7px 7px 7px 16px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .utdDeskBanner {
      .login-register-buttons__container {
        display: none;
      }

      &.navOverflowed {
        .login-register-buttons__container {
          display: block;

          .fixedToolbar& {
            visibility: hidden;
          }
        }
      }
    }

    #utdNavRoot {
      .login-register-buttons__container {
        display: block;
        position: absolute;
        right: 0;
      }

      &.navOverflowed .login-register-buttons__container {
          visibility: hidden;
      }
    }

    .wk-header > .wk-header-container {
      .utd-header-top-right > .wk-navbar > .login-register-buttons__container {
        background: none;
      }
      .login-register-buttons__container {
        .wk-nav > li > a {
          height: 13px;
          padding: 8px 16px 11px;
          font-size: 13.3px;

          &:hover {
           color: #fff;
          }
        }

        .wk-green {
          padding-left: 25px;
        }
      }
    }

    &.utdWkHomePage2024.anonymous-user .utdDeskBanner .login-register-buttons__container {
      .wk-nav {
        .ds1-mt-0();

        >li {
          .ds1-pv-1();
          margin: 0;

          >a {
            .ds1-pb-1();
            height: auto;
            display: block;
            padding-left: 14px;
            padding-right: 14px;
            font-size: 14px;
            color: @WKCE-WHITE;
          }

          &.login-btn {
            &:not(:first-child) {
              .ds1-pl-4();
            }

            &:first-child {
              .ds1-pl-0();
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 812px) {
  .isDesktop.utdWkHomePage2024.isHomeSearchEmpty.anonymous-user {
    .utdDeskBanner .login-register-buttons__container {
      display: inline-block;
    }
  }
}

@media only screen and (min-width: 1126px) {
  .isDesktop.utdWkHomePage2024.anonymous-user:not(.isHomeSearchEmpty) {
    .utdDeskBanner .login-register-buttons__container {
      display: inline-block;
    }
  }
}
</style>
