<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="utd-server-message-dialog">
    <utd-modal-dialog ref="utdMessageDialog"
                      :dialog-size="'medium'"
                      :hide-header-close="!showTitleClose"
                      :close-on-overlay-click="false"
                      modal-classes="server-modal-dialog"
                      @closed="closeModal">
      <template #header>{{ dialogTitle }}</template>
      <div v-if="dialogContent" id="utdMessageBoxContent" v-html="dialogContent" />
      <div v-else-if="dialogComponent" id="utdMessageBoxContent">
        <component :is="dialogComponent" />
      </div>
      <template #footer>
        <template v-if="hasFooterButtons">
          <div v-for="(dialogButton, index) in dialogButtons" :key="index">
            <utd-button :id="dialogButton.id"
                        :button-size="typeof dialogButton.size === 'undefined' ? 'large' : dialogButton.size"
                        :button-style="dialogButton.style"
                        @click="_handleButtonClick(index)">
              {{ dialogButton.label }}
            </utd-button>
          </div>
        </template>
      </template>
    </utd-modal-dialog>
  </div>
</template>

<script>
import PubSub from '_acaSrc/utility/PubSub';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { elAddClass, getWindow } from '_acaSrc/utility/DOM';
import { C_EVENTS } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';
import utdRestClient from '_acaSrc/utility/http/UtdRestClient';
import ChooseLanguageDialogTemplate
    from '_acaSrc/components/localization/ChooseLanguageDialogTemplate.vue';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        ChooseLanguageDialogTemplate
    },
    data() {
        return {
            dialog: {},
            dialogTitle: '',
            dialogContent: '',
            dialogComponent: '',
            dialogButtons: '',
            isProcessing: false,
            showTitleClose: false
        };
    },
    computed: {
        ...mapGetters('user', [ 'isExpired' ]),
        hasFooterButtons() {
            return this.dialogButtons && this.dialogButtons.length;
        }
    },
    mounted() {
        new PubSub().subscribe('wkutd.displayModal', this._displayModal);
        new PubSub().subscribe('wkutd.closeModal', this.closeModal);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.displayModal', this._displayModal);
        new PubSub().unsubscribe('wkutd.closeModal', this.closeModal);
    },
    methods: {
        _displayModal(dialog) {
            this.dialog = dialog;
            this.$nextTick(() => {
                this._renderDialog();
            });
            this.$nextTick(() => {
                if (epicClient) {
                    if (epicClient.isActive()) {
                        epicClient.bindExternalWindowTargets();
                    }
                }
                if (this.dialog.working.fnPostRender) {
                    this.dialog.working.fnPostRender();
                }
            });
        },
        _renderDialog() {
            if (this.dialog.visible) {
                this.dialogTitle = this.dialog.working.title;
                this.dialogContent = this.dialog.working.content;
                this.dialogComponent = this.dialog.working.component;
                this.dialogButtons = this.dialog.working.buttons;
                this.showTitleClose = !!this.dialog.working.showTitleClose;
                this.$refs.utdMessageDialog.open();

                if (this.dialog.message
                 && this.dialog.message.utdStatus === 'VIEW_INDIVIDUAL_SLA') {
                    setTimeout(() => {
                        this.rearrangeButtonForViewSla();
                    });
                }

                // If a confirmation that dialog was displayed was
                // requested, then resolve the promise here...
                if (this.dialog.working.fnDialogVisible) {
                    this.dialog.working.fnDialogVisible({
                        context: this.dialog.working.context
                    });
                }
            }
        },
        // eslint-disable-next-line complexity
        _handleButtonClick(index) {
            // Get button object by index
            const oBtn = this.dialog.working.buttons[index];

            // Handle processing of primary button operation
            if (oBtn.alert) {
                // eslint-disable-next-line no-alert
                alert(oBtn.alert);
            }
            else if (oBtn.windowLocate) {
                getWindow().location = oBtn.windowLocate;
            }
            else if (oBtn.flushApplication) {
                new PubSub().publish('wkutd.purgePersistentCache');
                getWindow().location.href
                = `${getWindow().location.protocol}//${getWindow().location.host}/contents/search`;
            }
            else if (oBtn.windowOpen) {
                epicClient.windowOpen([ oBtn.windowOpen, '_newWin' ]);
            }
            else if (oBtn.asynchRequest) {
                this._processAsynchRequest(oBtn);
            }
            else if (oBtn.confirmAsynchRequest) {
                this._processConfirmAsynchRequest(oBtn.confirmAsynchRequest);
            }
            else if (oBtn.windowReload) {
                this.isProcessing = true;

                const newUrl = `${getWindow().location.pathname}${getWindow().location.search}`;
                getWindow().location.assign(newUrl);
                getWindow().location.reload(true);
            }

            if (oBtn.logEvent) {
                new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                    uiElementName: oBtn.logEvent,
                    optData: this.isExpired
                });
            }

            // Handle processing of any "action" attribute
            if (oBtn.action) {
                if (oBtn.action === 'hideAndGetNext') {
                    this.closeModal();
                    const msgTitle = this.dialog.working.name;
                    for (let ii = 0; ii < new ServerMessaging().messageQueue.length; ii++) {
                        const msg = new ServerMessaging().messageQueue[ii];

                        if (msg.utdStatus === msgTitle) {
                            new ServerMessaging().messageQueue.splice(ii, 1);
                        }
                    }
                    new ServerMessaging().popMessage();
                }
                else if (oBtn.action === 'hide') {
                    this.closeModal();
                }
                else if (oBtn.action === 'controller') {
                    if (oBtn.controller !== '') {
                        oBtn.controller(oBtn.data);
                    }
                }
            }
        },
        _processAsynchRequest(oBtn) {
            // Note: fncData is not used yet, but could be assigned
            // to another oBtn property which should be passed in
            // as second argument to the execute<METHOD> functions.
            // This second argument is only meaningful on Get, Post or Put.
            let fncExec, fncData;

            switch (oBtn.asynchType) {
            case 'GET':
                fncExec = utdRestClient.get;
                break;

            case 'POST':
                fncExec = utdRestClient.post;
                break;

            case 'PUT':
                fncExec = utdRestClient.put;
                break;

            case 'DELETE':
                fncExec = utdRestClient.delete;
                break;
            }
            const self = this;
            fncExec({ uri: oBtn.asynchRequest,
                data: fncData,
                config: oBtn.asynchConfig })
                .then(function(data) {
                    self._processRequest(data, oBtn);
                }
                ).catch(function(data) {
                    self._processError(data, fncData, oBtn);
                });
        },
        _processConfirmAsynchRequest(aCnfDat) {
            if (aCnfDat.asynchType === 'DELETE') {
                new ServerMessaging().enqueueMessage({
                    title: aCnfDat.title,
                    message: aCnfDat.message,
                    utdStatus: 'CONFIRM_AND_EXECUTE',
                    translatedActions: [{
                        message: this.$t('YES'),
                        action: aCnfDat.action,
                        data: aCnfDat.data,
                        successHandler: aCnfDat.successHandler,
                        errorHandler: aCnfDat.errorHandler
                    }, {
                        message: this.$t('NO'),
                        action: 'CANCEL'
                    }]
                });
            }
        },
        _processRequest(data, oBtn) {
            if (oBtn.successHandler) {
                oBtn.successHandler(data, oBtn.postActionData);
            }
            new ServerMessaging().resolveAsync(data);
        },
        _processError(data, fncData, oBtn) {
            if (oBtn.errorHandler) {
                oBtn.errorHandler(data);
            }

            // Some browsers don't handle PUT,
            // but that's the proper REST call, try POST if it doesn't work
            if (oBtn.asynchType === 'PUT') {
                oBtn.asynchType = 'POST';
                const self = this;
                utdRestClient.post(oBtn.asynchRequest, fncData, oBtn.asynchConfig)
                    .then(function(data) {
                        self._processRequest(data, oBtn);
                    }
                    ).catch(function(data) {
                        self._processError(data, fncData, oBtn);
                    });
            }

        },
        closeModal() {
            // Passing "doEmit: false" to avoid infinite loop
            this.$refs.utdMessageDialog.close({ doEmit: false });

            new ServerMessaging().restoreDefault();
        },
        rearrangeButtonForViewSla() {
            const modalEl = document.querySelector('.server-modal-dialog');
            const dialogContent = modalEl.querySelector('.utd-dialog-content');
            const slaButtons = document.querySelectorAll('#view-privacy-policy, #view-license');

            // Loop through each button
            slaButtons.forEach(function(button) {
                elAddClass(button, 'view-sla-btn');
                dialogContent.appendChild(button);
            });
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/global/variables';
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-modal-overlay.server-modal-dialog {
  #utdMessageBoxContent {
    background: white;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    h3 {
      text-align: center;
      color: #9a9a9a;
    }

    dl {
      > dt {
        text-align: center;
        font-size: 0.8em;
        color: #9a9a9a;
      }

      > dd {
        margin-left: 0;
        text-align: center;
        padding-bottom: 12px;
        font-size: 0.9em;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .utd-dialog-content {
    .ds1-pa-2();
  }

  .view-sla-btn {
    .ds1-mt-1();
    .ds1-mr-1();
    .ds1-pl-0();
    display: block;
  }
}
</style>