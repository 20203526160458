let uuid = 0;

export default {
    install(Vue) {
        Vue.mixin({
            beforeCreate() {
                this.uuid = `wkutdComponentId_${uuid.toString()}`;
                uuid++;
            }
        });
    }
};
