<template>
  <utd-modal ref="relatedGraphicsModal"
             modal-classes="related_graphics ds1-utd-font"
             :autofocus="enableAutoFocus"
             :close-on-overlay-click="false"
             @utd-modal-closing="closeRelatedGraphicsModal">
    <div v-if="thumbnails.length > 0" class="related_graphic_toolbar">
      <div class="ds1-mr-6 related_graphic_title ds1-pa-1 ds1-utd-size-2" v-text="modalTitle" />
      <div class="related_graphic_overlay_close ds1-pa-1 wkce-icon-filled-close"
           tabindex="0"
           role="button"
           aria-label="close"
           @keypress="$event.which === ENTER_KEY && closeRelatedGraphicsModal()"
           @click="closeRelatedGraphicsModal" />
    </div>
    <div class="related_graphic_thumbnail__content ds1-utd-js2">
      <utd-thumbnail v-for="graphic in thumbnails"
                     :key="graphic.url"
                     class="related_graphic_thumbnail"
                     :graphic="graphicDataForThumbnail(graphic)"
                     :has-border="true"
                     @utd-thumbnail-click="onGraphicClick(graphicDataForThumbnail(graphic), $event)" />
    </div>
  </utd-modal>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UtdModal from '_acaSrc/components/shared/stdlib/UtdModal.vue';
import UtdThumbnail from '../../shared/stdlib/UtdThumbnail.vue';

import {
    SET_RELATED_GRAPHICS_DIALOG_VISIBILITY
} from '_acaSrc/store/topic/topic.store';
import { graphicDataForThumbnail } from '_acaSrc/utility/contents/graphic/graphic';

export default {
    components: {
        UtdThumbnail,
        UtdModal
    },
    data() {
        return {
            graphicDataForThumbnail
        };
    },
    computed: {
        ...mapGetters('topic', [
            'topicGraphics',
            'relatedGraphicsModal',
            'topicTitle' ]),
        ...mapGetters('app', [ 'isTabletDevice' ]),
        ...mapGetters('device', [ 'isDesktopView', 'isMobileOnDesktop' ]),
        thumbnails() {
            return this.topicGraphics;
        },
        enableAutoFocus() {
            return this.isDesktopView && !this.isTabletDevice;
        },
        modalTitle() {
            return `${this.$t('CONTENT.GRAPHICS_FOR')} ${this.topicTitle}`;
        }
    },
    watch: {
        relatedGraphicsModal(newVal) {
            if (newVal) {
                this.showRelatedGraphicsModal();
            }
        },
        isMobileOnDesktop(newVal) {
            if (newVal) {
                this.closeRelatedGraphicsModal();
            }
        }
    },
    beforeUnmount() {
        this[SET_RELATED_GRAPHICS_DIALOG_VISIBILITY](false);
    },
    methods: {
        ...mapActions('graphic', [ 'handleUseGraphicLink' ]),
        ...mapMutations('topic', [ SET_RELATED_GRAPHICS_DIALOG_VISIBILITY ]),
        onGraphicClick(graphic, event) {
            this.handleUseGraphicLink({ evt: event });
            this.closeRelatedGraphicsModal();
        },
        showRelatedGraphicsModal() {
            this.$refs.relatedGraphicsModal.open();
        },
        closeRelatedGraphicsModal() {
            this.$refs.relatedGraphicsModal.close();
            this[SET_RELATED_GRAPHICS_DIALOG_VISIBILITY](false);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.related_graphics .utd-modal-content {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    max-width: @DS1-UTD-DIALOG-WIDTH;
}

</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@TOOLBAR-BG-COLOR: @WKCE-GRAY-TINT5;

:deep(.utd-thumbnail__container) {
    .ds1-ma-2();
}

.related_graphic_toolbar {
    min-height: 40px;
    background-color: @TOOLBAR-BG-COLOR;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    align-content: center;
}

.related_graphic_thumbnail__content {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 70vh;

  .InternetExplorer & { // display:flex handles padding a bit differently in IE11 with max-height
    &:after {
      content:'';
      display: block;
      .ds1-pb-2();
    }
  }
}

.related_graphic_title {
    .ds1-pl-2();
    display: block;
    font-weight: bold;
    color: @DS1-UTD-GRAY-TEXT-COLOR;
}

.related_graphic_overlay_close {
    .ds1-ma-0();
    position: absolute;
    cursor: pointer;
    top: 3px;
    right: 8px;
}

</style>
