<template>
  <div v-if="isDesktopBrowser"
       v-show="showSectionPointer"
       class="topic-section-pointer wkce-icon-arrow-right"
       :class="[ pointerClass, { 'topic-outline-hidden': !isOutlineVisible }]"
       :style="positionTop" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    SET_SECTION_POINTER_VISIBLE,
    SET_SECTION_POINTER_CLASS
} from '_acaSrc/store/topic/topic.store';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { Listener } from '_acaSrc/utility/Events';

const SECTION_POINTER_YPOS_OFFSET_PX = 167;
const RENDERING_TIMEOUT_MS = 300;

export default {
    data() {
        return {
            verticalPosition: 0,
            topicTabsHeightPx: 0,
            topicTabsEl: null
        };
    },
    computed: {
        ...mapGetters('app', [ 'isDesktopBrowser' ]),
        ...mapGetters('topic', [
            'pointerClass',
            'pointerVisible',
            'pointerPosition',
            'isOutlineVisible',
            'topicLanguage'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isMobileOnDesktop' ]),
        positionTop() {
            return `top: ${this.verticalPosition}px`;
        },
        showSectionPointer() {
            return this.isDesktopView && this.topicLanguage !== 'ar';
        }
    },
    watch: {
        pointerPosition(newVal) {
            // This watch is triggered by TopicSmoothScroller::smoothScrollToElement()
            if (newVal > 0 && !this.pointerVisible) {
                this.verticalPosition = newVal
                    + SECTION_POINTER_YPOS_OFFSET_PX
                    + this.topicTabsHeightPx;

                this[SET_SECTION_POINTER_VISIBLE](true);
            }
            else if (newVal === 0) {
                this.verticalPosition = 0;
            }
        },
        pointerVisible(newVal, oldVal) {
            if (!newVal && oldVal) {
                this[SET_SECTION_POINTER_CLASS]('topic-section-pointer-animate-out');
            }
            else {
                this[SET_SECTION_POINTER_CLASS]('topic-section-pointer-animate-in');
            }
        },
        isMobileOnDesktop() {
            if (!this.topicTabsEl) {
                this.topicTabsEl = getDocument().querySelector('#topicContentTabs');
            }

            if (this.topicTabsEl) {
                setTimeout(() => {
                    this.topicTabsHeightPx = this.topicTabsEl.offsetHeight;
                }, RENDERING_TIMEOUT_MS);
            }
        }
    },
    mounted() {
        if (this.isDesktopBrowser) {
            this.$nextTick(() => {
                this.windowResizeListener = new Listener(getWindow(), 'resize',
                    this.onViewportChange, { passive: true });
                this.setListener(this.windowResizeListener);

                this.windowScrollListener = new Listener(getWindow(), 'scroll',
                    this.onViewportChange, { passive: true });
                this.setListener(this.windowScrollListener);

                this[SET_SECTION_POINTER_VISIBLE](false);

                this.topicTabsEl = document.querySelector('#topicContentTabs');
                if (this.topicTabsEl) {
                    this.topicTabsHeightPx = this.topicTabsEl.offsetHeight;
                }
            });
        }
    },
    beforeUnmount() {
        if (this.isDesktopBrowser) {
            this.clearListener(this.windowResizeListener);
            this.clearListener(this.windowScrollListener);
        }
    },
    methods: {
        ...mapActions('app', [ 'setListener', 'clearListener' ]),
        ...mapMutations('topic', [ SET_SECTION_POINTER_VISIBLE, SET_SECTION_POINTER_CLASS ]),
        onViewportChange() {
            this[SET_SECTION_POINTER_VISIBLE](false);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@SECTION-POINTER-COLOR: @WKCE-GREEN;

@media only screen and (min-width: 768px) {
  .topic-section-pointer {
    position: absolute;
    opacity: 0;
    top: 163px;
    left: 289px;
    width: 20px;
    height: 20px;

    &.topic-outline-hidden {
      left: 32px;
    }

    &.wkce-icon-arrow-right::before {
      color: @SECTION-POINTER-COLOR;
      font-size: 20px;
      font-weight: bold;
    }

    &.topic-section-pointer-animate-in {
      animation-name: wkce-fadein-from-left;
      animation-fill-mode: both;
      animation-duration: 0.2s;
      will-change: transform, opacity;
    }

    &.topic-section-pointer-animate-out {
      animation-name: wkce-fadeout-from-right;
      animation-fill-mode: both;
      animation-duration: 0.2s;
      will-change: transform, opacity;
    }
  }
}

@media only screen and (min-width: 992px) {
  .topic-section-pointer {
    left: 329px;
  }
}

@media only screen and (min-width: 1200px) {
  .topic-section-pointer {
    left: 369px;
  }
}

</style>
