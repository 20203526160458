/* eslint-disable complexity */

import { C_BROWSER, C_QAP, C_SEARCH, C_SEARCH_RESULTS } from '_acaSrc/utility/constants';
import { setSearchUrlParamsHelper, setQueryParamValue } from '_acaSrc/utility/http';

export function getSetSearchUrlWithParams(urlObj, rnkObj, options) {
    if (!urlObj) {
        return '';
    }
    const params = { };
    if (typeof options !== 'undefined') {
        if (typeof options.setSource !== 'undefined' && options.setSource) {
            if (typeof options.sourceObj !== 'undefined') {
                params.sourceValue = options.sourceObj.sourceValue;
            }
            else {
                params.sourceValue = rnkObj ? rnkObj.sourceValue : '';
            }
        }

        if (typeof options.kpTab !== 'undefined') {
            params.kpTab = options.kpTab;
        }
    }

    const rankIndex = rnkObj ? rnkObj.rankIndex : '';
    if (rankIndex !== '') {
        params.rankIndex = rankIndex;
    }

    urlObj.url = setSearchUrlParamsHelper(urlObj.url, params);

    if (rankIndex === '') {
        urlObj.url = setQueryParamValue(urlObj.url, 'display_rank', rankIndex);
    }

    // This will include URL param on Drug Information QAP dosing links, which
    // is used to show drug names next to any topic dosing section header labels
    if (urlObj.label && !urlObj.content) {
        urlObj.url = setQueryParamValue(urlObj.url, 'showDrugLabel', 'true');
    }

    return urlObj.url;
}

export const setSearchResultParams = (results, location, context) => {
    const { isMobileOnDesktop,
        browserType,
        diRankIndex } = context;
    let ri = 1;
    results.forEach(result => {
        result.rankIndex = ri;
        result.sourceValue = location;

        if (location === 'search_result'
            && (result.isPanelResult || result.type === 'QAP')
            && !isMobileOnDesktop
            && browserType === C_BROWSER.TYPE_DESKTOP) {
            return;
        }
        if (result.type === 'drug_info_panel') {
            ri = diRankIndex;
        }
        else if (result.type === 'QAP') {
            ri = 1 + result.dipCount;
        }
        ri++;
    });
    return results;
};

export function makeSearchCacheKey(url, params) {
    if (!url) {
        return '';
    }
    let cacheKey = `${url}?`;
    let idx = 0;

    for (const param in params) {
        if (params.hasOwnProperty(param)) {
            if (idx > 0) {
                cacheKey += '&';
            }
            cacheKey += `${param}=${params[param]}`;
            idx++;
        }
    }

    return cacheKey;
}

export function isFeaturedSearchResult(result) {
    return result.type === C_SEARCH_RESULTS.RESULT_TYPE.KEY_POINTS_PANEL
        || result.type === C_SEARCH_RESULTS.RESULT_TYPE.GRAPHICS_PANEL
        || result.type === C_SEARCH_RESULTS.RESULT_TYPE.BROAD_QUERY_PANEL;
}

export function isRegularSearchResult(result) {
    return !result.isPanelResult
        && result.type !== C_QAP.UI.QAP_TYPE
        && !isFeaturedSearchResult(result)
        && !isGraphicalAnswers(result);
}

export function isGraphicalAnswers(result) {
    return result.type === C_SEARCH_RESULTS.RESULT_TYPE.GRAPHICAL_ANSWERS;
}

export function isGraphicResult(result) {
    return result.type === C_SEARCH_RESULTS.RESULT_TYPE.GRAPHIC
        || result.type === C_SEARCH_RESULTS.RESULT_TYPE.VISUALDX;
}

export function isBertResult(result) {
    return result.type === C_SEARCH_RESULTS.RESULT_TYPE.BERT;
}

function isVueMigratedQap(result) {
    return result.panelType === C_QAP.PANEL.PCU
        || result.panelType === C_QAP.PANEL.DRUG_DXY
        || result.panelType === C_QAP.PANEL.DRUG_FORMULATION
        || result.panelType === C_QAP.PANEL.DRUG_ROUTED
        || result.panelType === C_QAP.PANEL.PATHWAYS
        || result.panelType === C_QAP.PANEL.LABI
        || result.panelType === C_QAP.PANEL.DRUG_INFO
        || result.panelType === C_QAP.PANEL.DRUG_INT;
}

export function isVueQap(result) {
    return result.type === C_QAP.UI.QAP_TYPE
        && isVueMigratedQap(result);
}

export function isNewNgQap(result) {
    return result.type === C_QAP.UI.QAP_TYPE
        && !isVueMigratedQap(result);
}

export function prependResultTitle(result) {
    if ((result.type === C_QAP.TYPES.PATHWAY
      || result.subtype === C_QAP.SUBTYPES.PCU)
        && !result.isPanelResult
        && !result.customizedBy
        && typeof C_SEARCH.TYPE_TITLES[result.type.toUpperCase()] !== 'undefined'
        && result.title.indexOf(C_SEARCH.TYPE_TITLES[result.type.toUpperCase()]) === -1) {
        result.title = `${C_SEARCH.TYPE_TITLES[result.type.toUpperCase()]}${result.title}`;
    }
}

export function recodeSearchPlusToSpace(term) {
    if (!term) {
        return term;
    }
    return term.replace(/\+/g, ' ');
}
