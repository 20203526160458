import { C_QAP, C_SEARCH_RESULTS } from '_acaSrc/utility/constants';

export const QAP_COMPONENTS = Object.freeze({
    [C_QAP.PANEL.PCU]: 'QapPcu',
    [C_QAP.PANEL.DRUG_DXY]: 'QapDrugDXY',
    [C_QAP.PANEL.DRUG_FORMULATION]: 'QapDrugFormulation',
    [C_QAP.PANEL.DRUG_ROUTED]: 'QapRoutedDrug',
    [C_QAP.PANEL.PATHWAYS]: 'QapPathways',
    [C_QAP.PANEL.LABI]: 'QapLabInterpretations',
    [C_QAP.PANEL.DRUG_INFO]: 'QapDrugInformation',
    [C_QAP.PANEL.DRUG_INT]: 'QapInternationalDrug'
});

export const SEARCH_RESULTS_COMPONENTS = Object.freeze({
    [C_SEARCH_RESULTS.RESULT_TYPE.KEY_POINTS_PANEL]: 'KeyPointsPanel',
    [C_SEARCH_RESULTS.RESULT_TYPE.GRAPHICS_PANEL]: 'GraphicsPanel',
    [C_SEARCH_RESULTS.RESULT_TYPE.GRAPHICAL_ANSWERS]: 'GraphicalAnswers',
    [C_SEARCH_RESULTS.RESULT_TYPE.BROAD_QUERY_PANEL]: 'BroadQueryPanel'
});