<template>
  <div>
    <div v-if="isShowBackButton" class="contributors-toolbar large">
      <toolbar-back-link />
    </div>
    <div class="contributor-disclosure-wrapper">
      <h2>Contributor Disclosures</h2>
      <hr>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="contributor-disclosures" v-html="lexiAllContributorsDisclosure.html" />
    </div>
  </div>
</template>

<script>
import ToolbarBackLink from '_acaSrc/components/shared/providers/UtdToolbarBackLinkProvider.vue';
import { mapGetters } from 'vuex';
import { browserHasHistory } from '_acaSrc/utility/Browsers';

export default {
    components: {
        ToolbarBackLink
    },
    props: {
        lexiAllContributorsDisclosure: Object
    },
    computed: {
        ...mapGetters('app', [ 'isAlwaysShowBackButton' ]),
        isShowBackButton() {
            return this.isAlwaysShowBackButton && browserHasHistory();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.contributor-disclosure-wrapper {
  margin: 15px;

  hr {
    height: 0;
    border: none;
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
  }

  > h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  h3 {
    margin-top: 18px;
    margin-bottom: 5px;
  }

  .contributor-disclosures {
    .contributor-info {
      font-style: italic;
      .ds1-mv-0();
    }

    .editorial-panel-description {
      .ds1-mv-2();
    }

    .contributor-disclosure {
      .ds1-mt-1();
      .ds1-mb-2();

      > span {
        text-transform: uppercase;
        letter-spacing: 1.2px;
      }
    }

    section {
      margin-bottom: 40px;
    }
  }
}
</style>
