const RESULT_TYPE = 'UTD_SECTION';
const GRAPHIC_SECTION_SOURCE = 'GRAPHIC-SECTION';
const FEATURED_GRAPHIC_SOURCE = 'FEATURED-GRAPHIC';

export default class BroadQueryResults {
    constructor(
        id,
        type,
        title,
        markupTitle,
        searchResults,
        { searchRank, rankIndex } = {},
        { initialTabIndex } = {}
    ) {
        this.id = id;
        this.type = type || '';
        this.title = title;
        this.markupTitle = markupTitle;
        this.searchRank = searchRank;
        this.rankIndex = rankIndex;
        this.tabbedContent = this.setTabbedContent(searchResults) || [];
        this.initialTabIndex = initialTabIndex;
        this.initAllGraphics();
    }

    /**
     * Returns the in-accordion graphic links object
     * @param { Object } searchResults Search Results object
     * @param { String } tabTitle The title for the tab the graphic link is under
     * @param { String } accordionTitle The title for the accordion the graphic link is under
     * @returns { Object } In-accordion graphic links object
     */
    getAccordionMediaContainer(searchResults, tabTitle, accordionTitle) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .filter(result => result.type !== RESULT_TYPE)
                .map(result => {
                    return {
                        imageKey: result.id,
                        type: result.type,
                        title: result.title,
                        topicKey: this.id,
                        searchRank: this.searchRank,
                        rankIndex: this.rankIndex,
                        source: `${this.type}-${GRAPHIC_SECTION_SOURCE}`.toLowerCase(),
                        tabTitle,
                        accordionTitle
                    };
                });
        }
    }

    /**
     * Returns the object necessary to populate the accordion
     * @param { Object } searchResults Search Results object
     * @param { String } tabTitle The title for the tab the accordion is under
     * @returns { Object } Accordion data object
     */
    getAccordionData(searchResults, tabTitle) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .filter(result => result.type === RESULT_TYPE)
                .map(result => {
                    return {
                        label: result.title, // Accordion title
                        markupLabel: result.markupTitle, // Accordion markup title
                        text: result.snippet, // Accordion content
                        graphics: this.getAccordionMediaContainer(
                            result.searchResults || [],
                            tabTitle,
                            result.title
                        ),
                        practicePoints: result.practicePointsContent
                    };
                });
        }
    }

    /**
     * Returns the graphics displayed next to the accordion(s) when under a tab
     * @param { Object } searchResults Search Results object
     * @param { String } tabTitle The title for the tab the graphic is under
     * @returns { Object } Side content object
     */
    getFeaturedGraphics(searchResults, tabTitle) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .filter(result => result.type !== RESULT_TYPE)
                .map(result => {
                    return {
                        imageKey: result.id,
                        type: result.type,
                        title: result.title,
                        topicKey: this.id,
                        searchRank: this.searchRank,
                        rankIndex: this.rankIndex,
                        source: `${this.type}-${FEATURED_GRAPHIC_SOURCE}`.toLowerCase(),
                        tabTitle
                    };
                });
        }
    }

    /**
     * Returns the tabbed content object necessary for populating each tab
     * @param { Object } searchResults Search Results object
     * @returns { Object } The tabbed content object
     */
    setTabbedContent(searchResults) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .filter(result => result.type === RESULT_TYPE)
                .map(result => {
                    return {
                        type: result.type,
                        tabLabel: result.title, // Tab's label
                        accordionData: this.getAccordionData(
                            result.searchResults || [],
                            result.title
                        ),
                        sideContent: this.getFeaturedGraphics(
                            result.searchResults || [],
                            result.title
                        )
                    };
                });
        }
    }

    initAllGraphics() {
        this.allGraphics = [];
        const map = {};
        const addIfNew = g => {
            if (map[g.imageKey]) {
                return;
            }
            map[g.imageKey] = true;
            this.allGraphics.push(g);
        };
        this.tabbedContent.forEach(tab => {
            tab.sideContent.forEach(addIfNew);
            tab.accordionData.forEach(a => {
                a.graphics.forEach(addIfNew);
            });
        });
    }
}