
(function() {
    window.paceOptions = {
        ajax: {
            trackMethods: [ 'GET', 'POST' ],
            ignoreURLs: [
                'services/app/event/',
                '/services/app/profile/',
                '/services/app/contents/search/autocomplete/'
            ]
        },
        document: false,
        target: [ '#paceHolder' ]
    };
})();
