<template>
  <div class="pharma-list">
    <div class="wkce-list">
      <utd-toolbar :is-show-back-link="true"
                   :toolbar-title-text="$t(content.toolbarTitle)" />
      <utd-spinner :is-visible="showSpinner">
        <dl class="wkce-list-content">
          <dt v-if="content.listTitle" class="wkce-list-title" v-text="content.listTitle" />
          <template v-for="(listItem, index) in data">
            <dd v-if="listItem.linkText" :key="index" class="wkce-list-item">
              <a class="wkce-list-item-link"
                 :href="getSearchUrlParam(listItem.url)"
                 v-text="listItem.linkText" />
              <span v-if="listItem.label" class="wkce-list-item-text" v-text="listItem.label" />
            </dd>
          </template>
          <dd v-if="emptyList" class="wkce-list-item">
            <span v-text="$t('SEARCH.NO_RESULTS_FOUND')" />
          </dd>
          <dd v-if="hasError" class="wkce-list-item">
            <div v-text="$t('ERRORS.ERROR_OCCURRED')" />
            <span v-text="$t('ERRORS.PLEASE_TRY_AGAIN')" />
          </dd>
        </dl>
      </utd-spinner>
    </div>
  </div>
</template>

<script>
import UtdSpinner from '_acaSrc/components/shared/utd/UtdSpinner.vue';
import UtdToolbar from '_acaSrc/components/shared/toolbar/UtdToolbar.vue';
import { getQueryParamValues, setQueryParamValue } from '_acaSrc/utility/http';

import { mapGetters, mapActions } from 'vuex';

import { C_UI } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdSpinner, UtdToolbar
    },
    props: {
        content: {
            type: Object,
            default() {
                return {};
            }
        },
        dataAsync: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            showSpinner: false,
            emptyList: false,
            hasError: false,
            searchTerm: '',
            cancelSpinnerTimeout: null,
            data: []
        };
    },
    computed: {
        ...mapGetters('search', [ 'searchParamsSearchText' ])
    },
    mounted() {
        this.onInit();
    },
    methods: {
        ...mapActions('error', [ 'resolveContent403' ]),
        getSearchUrlParam(url) {
            if (this.searchTerm && this.searchTerm !== '') {
                url = setQueryParamValue(url, 'search', this.searchTerm);
            }
            return url;
        },
        setSearchTerm() {
            this.searchTerm = this.searchParamsSearchText.trim();
            if (this.searchTerm === '') {
                this.searchTerm = getQueryParamValues(window.location.href, 'search');
            }
        },
        onInit() {
            this.setSearchTerm();

            this.hasError = false;
            this.cancelSpinnerTimeout = setTimeout(() => {
                this.showSpinner = true;
            }, C_UI.DEFAULT_SPINNER_DELAY_MS);

            this.dataAsync.promise.then(data => {
                this.data = data;
                this.emptyList = !data.length;
            }).catch(error => {
                this.hasError = true;
                if (error.status === 403) {
                    this.hasError = this.resolveContent403(error);
                }
            }).finally(() => {
                this.showSpinner = false;
                clearTimeout(this.cancelSpinnerTimeout);
            });
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@media only screen and (min-width: 768px) {
  .pharma-list .wkce-list .utd-toolbar .toolbar-title {
    .ds1-utd-size-2();
  }

  .utd-spinner.utd-spinner-visible .utd-spinner-container {
    margin-top: -7%;
  }
}
</style>
<style lang="less" scoped>
@import "~_acaAssets/wkceListView/wkceListView.less";
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD_TOOLBAR_TEXT_COLOR: @WKCE-GRAY;

.pharma-list {
  padding-top: 45px;

  .utd-toolbar {
    .ds1-utd-weight-bold();
    font-size: 14px;
    position: fixed;
    width: 100%;
    top: 56px;
    z-index: @ZINDEX-UTD-TOOLBAR;
    color: @UTD_TOOLBAR_TEXT_COLOR;
    padding: 13px 24px;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop .pharma-list .wkce-list {
    .wkce-list-content {
      width: 80%;
    }

    .utd-toolbar {
      top: 96px;
      padding: 12px 24px;
    }
  }

  .pharma-list .utd-toolbar {
    padding: 12px 24px;
  }
}
</style>
