import '../assets/app.less';
import './pace.init';
import { createApp } from 'vue';
import App from './App.vue';
import router from './utd-app.route';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import store from './store';
import mitt from 'mitt';
const emitter = mitt();
import runUtdApp from './utd-app.run';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import UtdIsSlotEmpty from './plugins/UtdIsSlotEmpty';
import UtdLockScroll from './plugins/UtdLockScroll';
import UtdEventManager from './plugins/UtdEventManager';
import UtdComponentUUID from './plugins/UtdComponentUUID';
import Localization from '../src/plugins/Localization';
import localizationEn from '_acaSrc/localizations/localization_en';
import { C_I18N } from '_acaSrc/utility/constants';

import('pace-js/pace.js').then(Pace => {
    Pace.start();

    runUtdApp();

    const app = createApp(App);

    app.config.globalProperties.emitter = emitter;
    app.provide('$router', router);
    app.config.productionTip = false;

    const { UTD_NAMESPACE } = C_I18N;

    i18next.init({
        ns: [ UTD_NAMESPACE ],
        defaultNS: UTD_NAMESPACE,
        lng: 'en',
        fallbackLng: 'en',
        resources: {
            en: {
                utd: localizationEn
            }
        }
    });

    const options = {
        color: '#fec629',
        thickness: '3px',
        transition: {
            speed: '0.2s',
            opacity: '0.6s',
            termination: 300
        }
    };

    app.use(VueProgressBar, options);
    app.use(I18NextVue, { i18next });
    app.use(router);
    app.use(UtdIsSlotEmpty);
    app.use(UtdLockScroll);
    app.use(UtdEventManager);
    app.use(UtdComponentUUID);
    app.use(Localization);
    app.use(store);

    app.mount('#appContainer');
});



