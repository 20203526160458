<template>
  <article id="graphicContainer"
           v-utd-auto-anchor-target="true"
           :class="{ 'ds1-utd-text-right': isArabicGraphicLanguage }">
    <div id="scroller">
      <utd-copyright-provider v-if="!(isDesktopBrowser && toolsIsPrintView)"
                              id="graphics-copyright"
                              :full="true" />
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="graphicModalHtml" />
    </div>
  </article>
</template>

<script>
import utdAutoAnchorTarget from '_acaSrc/directives/UtdAutoAnchorTarget.directive';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { addVideoPlayer } from '_acaSrc/utility/UtdVideoPlayer';
import { SET_TOOLS_GRAPHIC_READY } from '_acaSrc/store/graphic.store';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import { setBrowserTabTitle } from '_acaSrc/utility/Browsers';

export default {
    directives: {
        utdAutoAnchorTarget
    },
    components: {
        UtdCopyrightProvider
    },
    computed: {
        ...mapGetters('app', [ 'isDesktopBrowser' ]),
        ...mapGetters('graphic', [
            'graphicModalHtml',
            'graphicLanguage',
            'graphic',
            'tools',
            'graphicSubtype',
            'toolsIsPrintView'
        ]),
        isArabicGraphicLanguage() {
            return this.graphicLanguage === 'ar';
        }
    },
    mounted() {
        this.activate();
    },
    methods: {
        ...mapMutations('graphic', [
            SET_TOOLS_GRAPHIC_READY
        ]),
        ...mapActions('app', [
            'publish'
        ]),
        activate() {
            setBrowserTabTitle(this.graphic.displayName);
            this[SET_TOOLS_GRAPHIC_READY](true);

            if (this.graphicSubtype !== 'graphic_table') {
                setTimeout(this.graphicsNowrap, 0);
            }

            this.doCompile();
            this.initVideoPlayer();
        },
        graphicsNowrap() {
            const graphics = document.querySelectorAll('.graphic');
            Array.from(graphics).forEach(graphic => {
                graphic.insertAdjacentHTML('afterend', '<br />');
            });
        },
        doCompile() {
            this.publish({
                eventName: 'ANCHOR_CONTENT_READY'
            });
        },
        initVideoPlayer() {
            const videoElem = this.$el.querySelector('.videoplayer');
            videoElem && addVideoPlayer(videoElem);
        }
    }

};
</script>
