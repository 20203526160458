<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="qap-panel qap-international-drug ds1-qap-container wk-js2">
    <div class="qap-header">
      <div class="qap-title wkce-icon-medicine">{{ result.title }}</div>
    </div>
    <utd-tabs class="with-divider">
      <utd-tab v-for="(content, contentIdx) in result.qapContents"
               :key="contentIdx"
               :title="$t(content.translationKey || content.contentLabel)">
        <section class="qap-content qap-section">
          <div class="qap-section-container">
            <div class="qap-section-title" v-text="$t('SEARCH.VIEW_FULL_TOPIC')" />
            <a class="qap-link"
               :href="setUrlSource(content.url)"
               v-html="content.title" />
          </div>
        </section>
        <section v-for="(section, sectionIdx) in content.sections"
                 :key="sectionIdx"
                 class="qap-content qap-section"
                 :class="{isAccordionSection: isAccordionSection(section)}">
          <div class="qap-section-container">
            <utd-accordion v-if="isAccordionSection(section)"
                           :is-mutex-accordion="true"
                           :accordion-items="sectionAccordionData(section)"
                           @itemOpened="(accItem) => onAccordionClicked(accItem, contentIdx)">
              <template v-slot:content="slotProps">
                <div v-html="slotProps.item.detail" />
              </template>
            </utd-accordion>
          </div>

        </section>
      </utd-tab>
    </utd-tabs>
  </div>
</template>

<script>
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';
import { setQueryParamValue, getQueryParamValues } from '_acaSrc/utility/http';
import { C_QAP } from '_acaSrc/utility/constants';
import { mapActions } from 'vuex';

export default {
    components: {
        UtdTabs,
        UtdTab,
        UtdAccordion
    },
    props: {
        result: Object,
        source: String
    },
    data() {
        return {
            dialogTitle: '',
            dialogContent: '',
            hasLoggedDrugPanelEvent: false
        };
    },
    methods: {
        ...mapActions('search', [ 'logTopicViewDrugPanelEvent' ]),
        onAccordionClicked(accordionItem, activeTabIdx) {
            if (!this.hasLoggedDrugPanelEvent) {
                const drugUrl = this.result.qapContents[activeTabIdx].url;
                const rank = getQueryParamValues(drugUrl, 'display_rank');
                this.logTopicViewDrugPanelEvent({
                    drugAccName: accordionItem.label,
                    drugAccId: this.result.qapContents[activeTabIdx].contentId,
                    rank,
                    drugUrl
                });
                this.hasLoggedDrugPanelEvent = true;
            }
        },
        isAccordionSection(section) {
            return section.type === C_QAP.SECTION.ACCORDION;
        },
        setUrlSource(url) {
            return setQueryParamValue(url, 'source', this.source);
        },
        getSectionTitle(title) {
            return title && title.indexOf(': ') > -1
                ? title
                : this.$t(title);
        },
        sectionAccordionData(section) {
            return section.items.map(item => {
                const { label, detail } = item;
                return {
                    label,
                    detail
                };
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import "../vue-qap.less";

.qap-international-drug {
  .wkce-icon-medicine::before {
    .ds1-mr-1();
    margin-left: -24px;
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .qap-title {
    .ds1-ml-4();
    text-align: left;
  }

  :deep(.wk-tab-inner-content) {
    .ds1-pt-2();
  }

  .qap-section-container {
    .ds1-pb-2();
    .ds1-ph-2();
  }

  .isAccordionSection .qap-section-container {
    .ds1-pa-0();
    border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
  }

  :deep(.wk-accordion .wk-accordion-item) {
    .wk-accordion-item-element {
      max-height: 254px;

      > div > div {
        .ds1-pv-1();
        margin-right: 10px;

        p {
          .ds1-mt-1();
          text-indent: 0 !important;
          text-align: inherit !important;
        }
      }

      .ubnlist,
      .canbnlist,
      .cbnlist,
      .thclist,
      .htclist {
        ul {
          /* !important required to override: #searchresults .utd-listView ul -> display: none */
          display: inline !important;
          padding: 0;

          li {
            display: inline;
            font-size: inherit;
            margin: 0;
            padding: 0;
          }
        }
      }

      .fbnlist {
        ul {
          /* !important required to override: #searchresults .utd-listView ul -> display: none */
          display: block !important;
          list-style-type: none;
          padding: 0;

          li {
            display: inline;
            margin: 0;
            padding: 1px 0;
          }
        }

        a {
          /* !important required to override: ul.utd-listView li a -> display: inline-block */
          display: inline !important;
        }
      }
    }

    .wk-accordion-item-header .wk-accordion-item-header-label {
      .ds1-utd-weight-600();
    }
  }
}

</style>