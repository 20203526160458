<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="result"
       class="key-points-panel wk-js2">
    <div v-if="result.title"
         class="key-points-panel-title">KEY POINTS
      <div class="key-points-contributors__container">
        <a class="contributors-link wkce-icon-filled-check-circle"
           href="#"
           @click.prevent="showContributorsInfo()">
          <span>Contributors</span></a>
      </div>
      <span class="key-points__title-text ds1-mt-1">
        <span v-html="result.markupTitle || result.title" />
        <utd-translate-button />
      </span>
    </div>
    <div class="key-points-panel__container ds1-mt-1">
      <div v-if="hasAccordionData"
           class="key-points-panel-accordion__container utd-longform-content-styles-v1"
           :class="{'feedback-overlap': !hasFeaturedGraphics}">
        <utd-accordion :opened-index="defaultOpenAccordionIndex"
                       :accordion-items="accordionItemsData"
                       :can-close-open-accordion="true"
                       :disable-arrow-keys="true"
                       :accordion-style-class="'ds1-accordion'"
                       @itemOpened="accordionItemOpened($event)"
                       @itemClosed="accordionItemClosed($event)">
          <template v-slot:content="slotProps">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="key-points-accordion-text__container" v-html="slotProps.item.text" />
            <template v-if="hasAccordionGraphics(slotProps.item.graphics)">
              <utd-media-collection class="key-points-accordion-media__container"
                                    :title="'Graphics'"
                                    :media-collection="slotProps.item.graphics"
                                    v-bind="$attrs"
                                    @onGvdInit="setGVDGraphics" />
            </template>
            <div class="key-points-accordion-practice-points__container"
                 v-html="slotProps.item.practicePoints" />
          </template>
        </utd-accordion>
      </div>
      <div v-if="hasFeaturedGraphics"
           class="key-points-panel-graphics__container ds1-mt-1">
        <utd-media-collection class="kp-media__container collection-type-thumbnails responsive-horizontal-thumbnails"
                              :media-collection="result.featuredGraphics"
                              v-bind="$attrs"
                              @onGvdInit="setGVDGraphics" />
      </div>
    </div>
    <utd-pendo-feedback class="key-points-feedback bg-transparent"
                        :feedback-data="feedbackData" />
    <utd-modal-dialog ref="keyPointsContributorsRef"
                      :close-on-overlay-click="false"
                      :close-on-escape="false"
                      :modal-classes="'key-points-contributors-modal'"
                      :dialog-size="'medium'">
      <template #header>Editorially Curated Content</template>
      <p class="contributors-text ds1-ma-0 ds1-pa-2">{{ contributorsText }}</p>
      <template #footer>
        <utd-button @click="closeContributorsModal()">Close</utd-button>
      </template>
    </utd-modal-dialog>
  </div>
</template>

<script>
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';
import UtdPendoFeedback from '_acaSrc/components/shared/utd/UtdPendoFeedback.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import KeyPointsResults from '_acaSrc/utility/data-adapters/key-points-panel-adapter';
import UtdMediaCollection from '_acaSrc/components/shared/stdlib/UtdMediaCollection.vue';
import { C_GRAPHICS, C_EVENTS, C_KEY_POINTS, C_LOCALIZATION } from '_acaSrc/utility/constants';
import { SET_GRAPHIC_VIEWER_ACCORDION_ITEMS } from '_acaSrc/store/graphic.store';
import { SET_KPP_EVENTS_DATA } from '_acaSrc/store/search.store';
import { getEventTargetHref, setEventTargetHref } from '_acaSrc/utility/Events';
import { setQueryParamValue, makeRelativeUrl } from '_acaSrc/utility/http';
import { TopicLinkTypes } from '_acaSrc/utility/contents/topic/topic';
import PubSub from '_acaSrc/utility/PubSub';
import PracticePoint from '_acaSrc/components/localization/PracticePoint.vue';
import UtdTranslateButton from '_acaSrc/components/shared/stdlib/UtdTranslateButton.vue';

const inlineLinksSelector = '.key-points-accordion-text__container a';
const inlineGfxSelector = '.key-points-accordion-text__container a.graphic';

export default {
    components: {
        UtdAccordion,
        UtdMediaCollection,
        UtdPendoFeedback,
        UtdModalDialog,
        UtdButton,
        UtdTranslateButton
    },
    mixins: [ PracticePoint ],
    props: {
        result: KeyPointsResults
    },
    data() {
        return {
            accordionItemsData: this.processAccordionItems(this.result),
            contributorsText: C_KEY_POINTS.CONTRIBUTORS_TEXT
        };
    },
    computed: {
        ...mapGetters('search', [ 'searchParamsSearchText' ]),
        ...mapGetters('device', [ 'isNotDesktopView' ]),
        defaultOpenAccordionIndex() {
            return this.isNotDesktopView ? -1 : 0;
        },
        hasAccordionData() {
            return this.accordionItemsData.length > 0;
        },
        hasFeaturedGraphics() {
            return this.result
              && this.result.featuredGraphics
              && this.result.featuredGraphics.length > 0;
        },
        feedbackData() {
            return {
                featureType: 'Key Points Panel',
                featureId: this.result.id,
                featureTitle: this.result.title
            };
        }
    },
    mounted() {
        if (!(this.$el && this.$el.querySelectorAll)) {
            return;
        }
        Array.from(this.$el.querySelectorAll(inlineGfxSelector))
            .forEach(el => {
                el.addEventListener('click', this.onClickInlineGraphic);
            });
        this.$nextTick(this.setupIcons);
        this.setGVDGraphics();
        new PubSub().subscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, this.setGVDGraphics);
        this.setupGraphicLinkQPs();
        this.populateKppEventData();
        this.setupPracticePoints({
            contentId: this.result.id,
            source: C_LOCALIZATION.PP_SOURCE.KPP
        });
    },
    beforeUnmount() {
        if (!(this.$el && this.$el.querySelectorAll)) {
            return;
        }
        Array.from(this.$el.querySelectorAll(inlineGfxSelector))
            .forEach(el => {
                el.removeEventListener('click', this.onClickInlineGraphic);
            });
        new PubSub().unsubscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, this.setGVDGraphics);
    },
    methods: {
        ...mapActions('graphic', [ 'handleUseGraphicLink', 'setGraphicViewerGraphics' ]),
        ...mapActions('search', [ 'logKppSectionToggleEvent' ]),
        ...mapMutations('graphic', [ SET_GRAPHIC_VIEWER_ACCORDION_ITEMS ]),
        ...mapMutations('search', [ SET_KPP_EVENTS_DATA ]),
        populateKppEventData() {
            const { rankIndex, searchRank } = this.result;
            this[SET_KPP_EVENTS_DATA]({
                rankIndex, searchRank
            });
        },
        setGVDGraphics() {
            this.setGraphicViewerGraphics({ graphics: this.result.allGraphics });
            this[SET_GRAPHIC_VIEWER_ACCORDION_ITEMS]([
                C_GRAPHICS.SIDEBAR_ACCORDION_AIT,
                C_GRAPHICS.SIDEBAR_ACCORDION_OG
            ]);
        },
        applyCurrentSearchTermToEventTargetHref(evt) {
            const eventHref = getEventTargetHref(evt);
            if (eventHref) {
                const urlWithSearch = setQueryParamValue(
                    eventHref,
                    'search',
                    encodeURIComponent(this.searchParamsSearchText)
                );
                setEventTargetHref(evt, urlWithSearch);
            }
        },
        async onClickInlineGraphic(evt) {
            this.applyCurrentSearchTermToEventTargetHref(evt);
            const { rankIndex, searchRank } = this.result;
            await this.handleUseGraphicLink({ evt,
                options: {
                    isFromCollection: true,
                    skipSidebarStateReset: true,
                    rankIndex,
                    searchRank
                } });
            this.setGVDGraphics();
        },
        accordionItemOpened({ label }) {
            this.logAccordionStateEvent({ label, state: 'OPEN' });
        },
        accordionItemClosed({ label }) {
            this.logAccordionStateEvent({ label, state: 'CLOSE' });
        },
        logAccordionStateEvent({ label, state }) {
            const sectionHeader = label;
            const topicId = this.result.id;
            const { rankIndex, searchRank } = this.result;
            this.logKppSectionToggleEvent({
                topicId, sectionHeader, rankIndex, searchRank, state
            });
        },
        processAccordionItems(result) {
            if (!(result && result.searchResults && Array.isArray(result.searchResults))) {
                return [];
            }
            return result.searchResults.map(searchResult => {
                return {
                    label: searchResult.title,
                    markupLabel: searchResult.markupTitle,
                    text: searchResult.snippet,
                    graphics: searchResult.graphics || [],
                    practicePoints: searchResult.practicePoints
                };
            });
        },
        hasAccordionGraphics(graphics) {
            return graphics && graphics.length > 0;
        },
        setupIcons() {
            Array.from(this.$el.querySelectorAll(inlineLinksSelector))
                .forEach(link => {
                    if (TopicLinkTypes.isExternal(link)) {
                        link.classList.add('ds1-inline-svg-link', 'external-link');
                    }
                    else if (TopicLinkTypes.isPathway(link)) {
                        link.classList.add('ds1-inline-svg-link', 'related-pathway-link');
                    }
                });
        },
        setupGraphicLinkQPs() {
            const accordions = this.$el.querySelectorAll('.wk-accordion-item');
            accordions.forEach(acc => {
                const accordionTitle = acc.querySelector('.wk-accordion-item-header').innerText;
                const inlineLinks = acc.querySelectorAll('a.graphic');
                const graphicSectionLinks
                    = acc.querySelectorAll('a.utd-graphic-link__container');
                inlineLinks.forEach(graphicLink => {
                    let newUrl = setQueryParamValue(
                        graphicLink.href, 'accordionTitle', accordionTitle
                    );
                    newUrl = makeRelativeUrl(newUrl);
                    graphicLink.href = newUrl;
                });
                graphicSectionLinks.forEach(graphicLink => {
                    let newUrl = setQueryParamValue(
                        graphicLink.href, 'accordionTitle', accordionTitle
                    );
                    newUrl = makeRelativeUrl(newUrl);
                    graphicLink.href = newUrl;
                });
            });
        },
        showContributorsInfo() {
            this.$refs.keyPointsContributorsRef.open();
        },
        closeContributorsModal() {
            this.$refs.keyPointsContributorsRef.close();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@DS1-KEY-POINTS-CONTRIBUTORS-ICON-COLOR: @WKCE-GREEN;

.key-points-panel {
  .ds1-pb-5();
  position: relative;

  .key-points-panel-title {
    .ds1-pa-2();
    .ds1-utd-size-1();
    display: flex;
    flex-wrap: wrap;
    color: @DS1-UTD-GRAY-TEXT-COLOR;

    .key-points-contributors__container {
      margin-left: auto;

      .contributors-link {
        .ds1-mr-0();
        .ds1-pa-0();
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        &:focus {
          outline: @DS1-UTD-FOCUS-OUTLINE;
        }

        span:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        &::before {
          .ds1-mh-compact();
          color: @DS1-KEY-POINTS-CONTRIBUTORS-ICON-COLOR;
          font-size: 16px;
        }
      }
    }

    .key-points__title-text {
      .ds1-utd-weight-bold();
      .ds1-utd-size-3();
      color: @DS1-UTD-TOPIC-TEXT-COLOR;
      flex: 0 0 100%;
    }

    .isSmallScreen & {
      background-color: @DS1-UTD-GRAY-BG-COLOR;
    }
  }

  .key-points-accordion-text__container :deep(br + br) {
    // Two <br>s are present in the source data, but given our styles only one is
    // needed. Mobile uses both.
    display: none;
  }

  .key-points-panel__container {
    .ds1-ph-2();
    display: flex;
    flex-direction: column-reverse;

    .key-points-panel-accordion__container {
      flex-grow: 2;

      :deep(.wk-accordion) {
        .wk-accordion-item-header-label {
          .ds1-utd-weight-600();
        }

        .key-points-accordion-text__container {
          .ds1-ma-2();

          .external-link::before {
            .ds1-utd-external-link(14px, 14px);
            top: 1px;
            margin-right: 2px;
          }

          .related-pathway-link::before {
            .ds1-utd-tree-graph(16px, 20px);
            top: 0;
          }

          .headingAnchor .h1 {
            .ds1-pt-0();
            .ds1-ma-0();
            border-top: none;
          }
        }

        .key-points-accordion-media__container {
          .ds1-ma-2();

          .wk-media-title {
            .ds1-mb-1();
          }
        }
      }
    }

    .key-points-panel-graphics__container {
      .ds1-mt-compact();

      .kp-media__container {
        display: flex;
        margin: auto auto 16px;

        &.responsive-horizontal-thumbnails :deep(.collection-thumbnails) {
          width: 100%;
          flex-wrap: nowrap;
          margin-left: 2px;
          margin-right: 2px;
        }

        :deep(.utd-thumbnail__container) {
          .ds1-ma-0();
          .ds1-pa-0();
        }
      }
    }
  }

  .key-points-feedback {
    position: absolute;
    z-index: @ZINDEX-FEATURED-RESULT-FEEDBACK;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .key-points-panel {
    .ds1-pb-1();

    > .key-points-panel__container.ds1-mt-1 {
      flex-direction: row;

      .key-points-panel-accordion__container.feedback-overlap {
        .ds1-mb-3();
      }

      .key-points-panel-graphics__container > .kp-media__container {
        margin: auto auto 24px 16px;

        &.responsive-horizontal-thumbnails :deep(.collection-thumbnails) {
          margin: 0;
        }
      }
    }
  }

  .isDesktop {
    .key-points-panel {
      .ds1-pb-0();
      width: 100%;

      .key-points-panel-title {
        .ds1-pa-0();
        border-bottom: 0;
        font-size: 14px;

        .key-points__title-text {
          .ds1-utd-weight-600();
          font-size: 14px;
        }
      }

      .key-points-panel__container {
        .ds1-ph-0();
        flex-direction: row;

        .key-points-panel-accordion__container {
          // to support IE11
          flex-basis: 100%;
        }

        .key-points-panel-graphics__container {
          .ds1-ml-2();

          .kp-media__container {
            margin-left: 0;
          }
        }
      }

      .key-points-feedback {
        position: absolute;
        bottom: -10px;
        right: -15px;
        z-index: @ZINDEX-FEATURED-RESULT-FEEDBACK;
      }
    }
  }
}
</style>
