import DrugDXYDto from '_acaQap/transformers/qapDrugDXY.dto';
import RoutedDrugDto from '_acaQap/transformers/qapRoutedDrug.dto';
import DrugFormulationDto from '_acaQap/transformers/qapDrugFormulation.dto';
import PathwayDto from '_acaQap/transformers/qapPathway.dto';
import LabInterpretationDto from '_acaQap/transformers/qapLabInterpretations.dto';
import DrugInformationDto from '_acaQap/transformers/qapDrugInformation.dto';
import { C_QAP } from '_acaSrc/utility/constants';
import InternationalDrugDto from '_acaQap/transformers/qapInternationalDrug.dto';

const state = {
    panels: [],
    collapsed: [],
    qapActiveTab: 0,
    isUccQuickAccessPanel: false,
    isQuickAccessPanel: false,
    isTabbedPanel: false,
    isRoutedDrug: false,
    isDrugFormulation: false
};

export const getters = {
    qapPanels: state => state.panels,
    mobileCollapsed: (state, getters, rootState, rootGetters) => {
        return state.collapsed[rootGetters['search/searchParamsSearchText']];
    },
    isDesktopQapVisible: (state, getters, rootState, rootGetters) => {
        return rootGetters['device/isDesktopView']
            && state.panels.length > 0;
    },
    getPathwayQap: state => {
        return state.panels
            && state.panels.find(panel => panel.panelType === C_QAP.PANEL.PATHWAYS);
    },
    getLabInterpretationQap: state => {
        return state.panels
            && state.panels.find(panel => panel.panelType === C_QAP.PANEL.LABI);
    },
    isUccQuickAccessPanel: state => state.isUccQuickAccessPanel,
    isQuickAccessPanel: state => state.isQuickAccessPanel,
    isTabbedPanel: state => state.isTabbedPanel,
    isRoutedDrug: state => state.isRoutedDrug,
    isDrugFormulation: state => state.isDrugFormulation,
    qapActiveTab: state => state.qapActiveTab
};

export const SET_QAP_PANEL = 'SET_QAP_PANEL';
export const RESET_QAP_PANEL = 'RESET_QAP_PANEL';
export const SET_MOBILE_COLLAPSED = 'SET_MOBILE_COLLAPSED';
export const SET_QAP_ACTIVE_TAB = 'SET_QAP_ACTIVE_TAB';
export const SET_IS_UCC_QUICK_ACCESS_PANEL = 'SET_IS_UCC_QUICK_ACCESS_PANEL';
export const SET_IS_QUICK_ACCESS_PANEL = 'SET_IS_QUICK_ACCESS_PANEL';
export const SET_IS_TABBED_PANEL = 'SET_IS_TABBED_PANEL';
export const SET_IS_ROUTED_DRUG = 'SET_IS_ROUTED_DRUG';
export const SET_IS_DRUG_FORMULATION = 'SET_IS_DRUG_FORMULATION';

export const mutations = {
    [SET_QAP_PANEL]: (state, panel) => state.panels.push(panel),
    [RESET_QAP_PANEL]: state => {
        state.panels = [];
        state.isTabbedPanel = false;
        state.isRoutedDrug = false;
        state.isDrugFormulation = false;
    },
    [SET_MOBILE_COLLAPSED]: (state, payload) => {
        state.collapsed[payload.searchKey] = payload.isCollapsed;
    },
    [SET_QAP_ACTIVE_TAB]: (state, value) => state.qapActiveTab = value,
    [SET_IS_UCC_QUICK_ACCESS_PANEL]: (state, value) => state.isUccQuickAccessPanel = value,
    [SET_IS_QUICK_ACCESS_PANEL]: (state, isQAP) => state.isQuickAccessPanel = isQAP,
    [SET_IS_TABBED_PANEL]: (state, isTabbed) => state.isTabbedPanel = isTabbed,
    [SET_IS_ROUTED_DRUG]: (state, isRouted) => state.isRoutedDrug = isRouted,
    [SET_IS_DRUG_FORMULATION]: (state, isFormulation) => state.isDrugFormulation = isFormulation
};

export const actions = {
    setMobileCollapsed({ commit, rootGetters }, isCollapsed) {
        commit(SET_MOBILE_COLLAPSED, {
            searchKey: rootGetters['search/searchParamsSearchText'],
            isCollapsed
        });
    },
    // eslint-disable-next-line complexity
    processQapDtos({ commit, dispatch }, payload) {
        const { result, searchTerm, rankIndex } = payload;
        // Check to convert original QAP server data into new structure
        if (result.type === C_QAP.PANEL.DRUG_INFO_PANEL) {
            dispatch('processDrugInfoPanel', payload);
        }
        else if (result.type === C_QAP.TYPES.PATHWAY && result.isPanelResult) {
            dispatch('processPathwayQapDto', payload);
        }
        else if (result.type === C_QAP.TYPES.LABI
            && result.subtype === C_QAP.SUBTYPES.LABI
            && result.isPanelResult) {
            dispatch('processLabInterpretationQapDto', payload);
        }
        else if (result.type === C_QAP.UI.QAP_TYPE) {
            if (result.panelType === C_QAP.PANEL.DRUG_FORMULATION) {
                commit(SET_QAP_PANEL, new DrugFormulationDto({
                    result,
                    searchTerm,
                    rankIndex
                }));
                commit(SET_IS_DRUG_FORMULATION, true); // -> US22892
                commit(SET_QAP_ACTIVE_TAB, payload.result.qapActiveTab);
            }
        }
    },
    processDrugInfoPanel({ dispatch, commit, getters }, payload) {
        // drug_info_panel could be any of the following:
        // - International Drug
        // - Drug Formulary
        // - Routed Drugs
        // - DXY Drug
        // - Drug Information
        const { result } = payload;
        for (let isr = 0; isr < result.searchResults.length; isr++) {
            if (result.searchResults[isr].type === C_QAP.TYPES.DRUG) {
                if (getters.isUccQuickAccessPanel) {
                    dispatch('processDxyQapDto', payload);
                    break;
                }
                else if (result.searchResults[isr].pageType === C_QAP.TYPES.ROUTED) {
                    dispatch('processRoutedDrugQapDto', payload);
                    commit(SET_IS_ROUTED_DRUG, true); // -> US22892
                    break;
                }
                else if (result.searchResults[isr].subtype !== C_QAP.SUBTYPES.INTERNATIONAL) {
                    dispatch('processDrugInfoQapDto', payload);
                    break;
                }
                else { // subtype === C_QAP.SUBTYPES.INTERNATIONAL
                    dispatch('processIntDrugQapDto', payload);
                    break;
                }
            }
        }

        if (!getters.isRoutedDrug) {
            commit(SET_IS_TABBED_PANEL, true); // -> US22892
        }
    },
    processDxyQapDto({ commit, dispatch, rootGetters }, payload) {
        if (!rootGetters['search/isNewResults']) {
            return;
        }

        const { result, searchTerm, rankIndex } = payload;
        commit(SET_QAP_PANEL, new DrugDXYDto({
            result,
            searchTerm,
            rankIndex
        }));

        const { id, url, title } = result.searchResults[0];
        dispatch('dxy/getDxyPanelResults', {
            contentId: id,
            resultUrl: url,
            searchTerm,
            drugName: title,
            rankIndex
        }, { root: true });
    },
    processRoutedDrugQapDto({ commit, rootGetters }, payload) {
        if (!rootGetters['search/isNewResults']) {
            return;
        }

        const { result, searchTerm, rankIndex } = payload;
        commit(SET_QAP_PANEL, new RoutedDrugDto({
            result,
            searchTerm,
            rankIndex
        }));
    },
    processDrugInfoQapDto({ commit, rootGetters }, payload) {
        if (!rootGetters['search/isNewResults']) {
            return;
        }
        commit(SET_QAP_ACTIVE_TAB, payload.result.qapActiveTab);

        const { result, searchTerm, rankIndex } = payload;
        const drugInfoQap = new DrugInformationDto({
            result,
            searchTerm,
            rankIndex
        });
        commit(SET_QAP_PANEL, drugInfoQap);
    },
    processIntDrugQapDto({ commit, rootGetters }, payload) {
        if (!rootGetters['search/isNewResults']) {
            return;
        }

        const { result, searchTerm, rankIndex } = payload;
        const drugInfoQap = new InternationalDrugDto({
            result,
            searchTerm,
            rankIndex
        });
        commit(SET_QAP_PANEL, drugInfoQap);
    },
    processPathwayQapDto({ commit, getters, rootGetters }, payload) {
        if (!rootGetters['search/isNewResults']) {
            return;
        }

        const { result, searchTerm, rankIndex } = payload;

        // Attempt to retrieve the DTO currently stored (if available)
        let pathwayQap = getters.getPathwayQap;
        if (pathwayQap) {
            pathwayQap.processNextPathway({
                result,
                searchTerm,
                rankIndex
            });
        }
        else {
            pathwayQap = new PathwayDto({
                result,
                searchTerm,
                rankIndex
            });
        }

        commit(RESET_QAP_PANEL);
        commit(SET_QAP_PANEL, pathwayQap);
    },
    processLabInterpretationQapDto({ commit, getters, rootGetters }, payload) {
        if (!rootGetters['search/isNewResults']) {
            return;
        }

        const { result, searchTerm, rankIndex } = payload;

        // Attempt to retrieve the DTO currently stored (if available)
        let labIQap = getters.getLabInterpretationQap;
        if (labIQap) {
            labIQap.processNextLabInterpretation({
                result,
                searchTerm,
                rankIndex
            });
        }
        else {
            labIQap = new LabInterpretationDto({
                result,
                searchTerm,
                rankIndex
            });
        }

        commit(RESET_QAP_PANEL);
        commit(SET_QAP_PANEL, labIQap);
    }
};

const qap = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default qap;