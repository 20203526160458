import { C_UI } from '_acaSrc/utility/constants';
import { safeTimeout } from '_acaSrc/utility/timers';

const sharedStates = {};

export default {
    mounted(el, binding) {
        const sharedHoverState = binding.value.sharedHoverState;

        if (sharedHoverState && !sharedStates[sharedHoverState]) {
            sharedStates[sharedHoverState] = {};
        }
        const timers = sharedHoverState ? sharedStates[sharedHoverState] : {};

        // Starts a timeout and cancels a corresponding timeout if exists
        const toggleTimers = function(cb, delay, timeout, timeoutCancel) {
            const { cancelTimeout } = safeTimeout(cb, delay, { cancelHook: true });
            timers[timeout] = cancelTimeout;
            if (timers[timeoutCancel]) {
                timers[timeoutCancel]();
            }
        };
        
        if (!binding.value.msDelay) {
            binding.value.msDelay = C_UI.MENU_SHOW_DELAY_MS;
        }

        if (!binding.value.msUnhoverDelay) {
            binding.value.msUnhoverDelay = C_UI.MENU_HIDE_DELAY_MS;
        }

        el.hoverOver = () =>
            toggleTimers(binding.value.afterDelay,
                binding.value.msDelay,
                'hover',
                'unhover');

        el.hoverLeave = () =>
            toggleTimers(binding.value.afterUnhoverDelay,
                binding.value.msUnhoverDelay,
                'unhover',
                'hover');

        el.addEventListener('mouseenter', el.hoverOver);
        el.addEventListener('mouseleave', el.hoverLeave);
    },
    unmounted(el) {
        el.removeEventListener('mouseenter', el.hoverOver);
        el.removeEventListener('mouseleave', el.hoverLeave);

        delete el.hoverOver;
        delete el.hoverLeave;
    }
};
