<template>
  <!-- eslint-disable vue/no-v-html -->
  <span class="toolbar-title"
        :class="[`toolbar-title-${size}`]"
        v-html="title" />
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: () => null
        }
    },
    computed: {
        title() {
            return this.$attrs.title;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/global/variables';
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.toolbar-title {
  .ds1-utd-size-2();
  font-weight: bold;
  color: @WK_UTD_GRAY_100;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .toolbar-title {
    &-large {
      font-size: 25px;
      line-height: 28px;
    }

    &-small {
      .ds1-utd-h3();
      font-weight: bold;
    }
  }
}
</style>
