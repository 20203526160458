<template>
  <div v-if="hasUserProfessions" class="my-account-sidebar">
    <div class="sidebar__container">
      <div class="sidebar__header">{{ $t('MYACCOUNT.MY_PROFILE') }}</div>
      <div class="sidebar__content">
        <div class="sidebar__my-profile-label-container ds1-pb-1">
          <div v-if="myAccountContactInfo.practiceType.value"
               class="sidebar__my-profile-label ds1-pb-1">
            <span class="sidebar__practice-setting-label sidebar-bolded">Role:</span>
            <span class="sidebar__role-label-value"
                  :class="isEmptyCheck(practiceType)">
              {{ $t(practiceType) }}</span>
          </div>
          <div v-if="myAccountContactInfo.specialty.value"
               class="sidebar__my-profile-label ds1-pb-1">
            <span class="sidebar__practice-setting-label sidebar-bolded">Specialty:</span>
            <span class="sidebar__specialty-label-value"
                  :class="isEmptyCheck(specialty)">
              {{ $t(specialty) }}</span>
          </div>
          <div v-if="myAccountContactInfo.practiceSetting.value"
               class="sidebar__my-profile-label ds1-pb-1">
            <span class="sidebar__practice-setting-label sidebar-bolded">Practice Setting:</span>
            <span class="sidebar__practice-setting-label-value"
                  :class="isEmptyCheck(practiceSetting)">
              {{ $t(practiceSetting) }}</span>
          </div>
        </div>
        <span class="sidebar__link-container">
          <span class="wkce-icon-pencil" />
          <a class="sidebar__edit-my-profile-link"
             href="#"
             :aria-label="C_MY_ACCOUNT.CONTACT_INFO_LABEL"
             @click="openContactInfoDialog($event)">{{ $t('MYACCOUNT.EDIT_MY_PROFILE') }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import { C_MY_ACCOUNT, C_EVENTS, isOidcRegex } from '_acaSrc/utility/constants';

export default {
    data() {
        return {
            C_MY_ACCOUNT
        };
    },
    computed: {
        ...mapGetters('feature', [ 'featureValue' ]),
        ...mapGetters('account', [
            'myAccountContactInfo',
            'myAccountPracticeSettingOptions',
            'myAccountpracticeTypeOptions',
            'myAccountSpecialtyOptions',
            'hasUserProfessions' ]),
        ...mapGetters('footer', [ 'supportTag' ]),
        ...mapGetters('app', [ 'isUccState' ]),
        isSso() {
            return isOidcRegex.test(this.supportTag.serverTag);
        },
        practiceType() {
            return this.userProfessionValue('practiceType', this.myAccountpracticeTypeOptions);
        },
        specialty() {
            return this.userProfessionValue('specialty', this.myAccountSpecialtyOptions);
        },
        practiceSetting() {
            return this.userProfessionValue('practiceSetting',
                this.myAccountPracticeSettingOptions);
        }
    },
    methods: {
        async openContactInfoDialog(event) {
            event.stopPropagation();
            event.preventDefault();
            new PubSub().publish(C_EVENTS.OPEN_CONTACT_INFO_MODAL);
        },
        logUiClickEvent(elem) {
            logUiClickEventHelper({
                uiElementName: `myAccountProfile_${elem}`
            });
        },
        userProfessionValue(key, list) {
            let value = this.myAccountContactInfo[key].value;

            if (list && list.length > 0 && value && value !== '0') {
                const index = list.findIndex(option =>
                    option.value === value);
                value = index !== -1
                    ? list[index].label
                    : 'MYACCOUNT.UPDATE_PROFILE';
                return value;
            }
            return 'MYACCOUNT.UPDATE_PROFILE';
        },
        isEmptyCheck(label) {
            return label === 'MYACCOUNT.UPDATE_PROFILE' ? 'is-empty' : '';
        }
    }
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.is-empty {
  color: @DS1-UTD-ERROR;
}

</style>