// Mimics the behavior of angular's $timeout, based off the source at
// https://github.com/angular/angular.js/blob/master/src/ng/timeout.js#L13
//
// options:
//    cancelHook (boolean): If true (defaults to false), returns { promise, cancelTimeout }
//         instead of promise. This is the only way to cancel a timeout.
//
//    rejectOnCancel (boolean): If true (defaults to false), the promise will be
//          rejected upon cancelation.
//          note: This is one spot where we intentionally veered from Angular's
//                implementation, which essentially defaults to true for this.
//
//    onError (fn): error handler. Defaults to a function which logs warnings.
export function safeTimeout(fn, timeout, options = {}) {
    let resolve, reject;
    const promise = new Promise(function(res, rej) {
        resolve = res;
        reject = rej;
    });
    const timeoutRef = setTimeout(function() {
        try {
            const result = fn();
            resolve(result);
        }
        catch (error) {
            const onErr = options.onError || safeTimeoutDefaultErrorHandler;
            onErr(error);
            reject(error);
        }
    }, timeout);

    if (!options.cancelHook) {
        return promise;
    }

    const cancelTimeout = () => {
        window.clearTimeout(timeoutRef);
        const resolutionMethod = options.rejectOnCancel ? reject : resolve;
        resolutionMethod('canceled');
    };
    return { promise, cancelTimeout };
}

function safeTimeoutDefaultErrorHandler(error) { /* eslint-disable no-console */
    console.warn('Exception caught in safeTimeout');
    console.warn(error);
    if (console.trace) {
        console.trace();
    }
} /* eslint-enable no-console */

// Use this when you need the callback to fire immediately initially, but then
// not again no matter how many times called, until 'delay' miliseconds has
// passed. For rapidly invoked functions this creates a certain "rate of fire",
// but does not guarantee the final call will be executed.
export function throttle(callback, delay) {
    let enableCall = true;
    return function(...args) {
        if (!enableCall) {
            return;
        }
        enableCall = false;
        callback && callback.apply(this, args);
        setTimeout(() => {
            enableCall = true;
        }, delay);
    };
}

// Use this when you do not want the callback to fire until 'duration'
// milliseconds have passed since the last call. This means the last call will
// always be executed, but does not guarantee any particular rate of fire, since
// each subsequent call will cancel the previous if the previous has not
// executed yet.
export function debounce(callback, duration) {
    let timeout;
    return function(...args) {
        const effect = () => {
            timeout = null;
            return callback && callback.apply(this, args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(effect, duration);
    };
}

// This is a cross-hybrid of the throttle and debounce methods.
// It has the property of a throttle in that it immediately fires the callback
// initially, but has the debounce property of not allowing the callback
// to fire again until 'duration' milliseconds have passed since the last call,
// and that the last call will always be executed.
// Pure JS version migrated from original AngularJS throttle.service.js
export function throttleBounce(callback, identifier, duration) {
    const timeout = [];
    const lastCall = [];

    return function(...args) {
        const context = this,
            thisCall = Date.now();

        const prevCall = (!lastCall[identifier] ? thisCall : lastCall[identifier]),
            sinceLast = (thisCall - prevCall);

        lastCall[identifier] = thisCall;

        const _fnDelayed = function() {
            timeout[identifier] = null;
            callback && callback.apply(context, args);
            lastCall[identifier] = Date.now();
        };

        // If timeout is not set yet, then proceed to call the passed function
        if (!timeout[identifier]) {
            callback && callback.apply(context, args);

            // Initialize timeout to delay next execution
            timeout[identifier] = setTimeout(function() { /* Noop */ }, duration);
        }
        else {
            // Cancel the previous timeout...
            clearTimeout(timeout[identifier]);

            // If the last time we were called exceeds the duration time, then execute immediately.
            // Otherwise queue it up for a delayed execution
            if (sinceLast >= duration) {
                callback && callback.apply(context, args);
                lastCall[identifier] = 0;	// Reset time since last call
            }
            else {
                // Then setup deferred call to passed function until 'duration' time
                timeout[identifier] = setTimeout(_fnDelayed, duration);
            }
        }

        return;
    };
}