<template>
  <utd-modal-dialog ref="myUtdModal"
                    modal-classes="my-utd-modal ds1-utd-font myUptodateModal"
                    :autofocus="enableAutoFocus"
                    :close-on-overlay-click="false">
    <template #header>{{ getHeaderTitle(modalTitle) }}</template>
    <div class="my-utd-modal-content ds1-utd-js2">
      <my-utd-tabbed-content />
    </div>
  </utd-modal-dialog>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import MyUtdTabbedContent from '_acaSrc/components/myuptodate/MyUtdTabbedContent.vue';
import { C_MYUTD_TABS, C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import {
    SET_DEFAULT_CONTENT_TAB
} from '_acaSrc/store/profile.store';
import { decodeHtml } from '_acaSrc/utility/DOM';

export default {
    components: {
        UtdModalDialog,
        MyUtdTabbedContent
    },
    emits: [ 'closed' ],
    computed: {
        ...mapGetters('app', [
            'router',
            'isTabletDevice'
        ]),
        ...mapGetters('profile', [
            'permissions',
            'defaultContentTab'
        ]),
        ...mapGetters('feature', [ 'isHeaderRedesign' ]),
        ...mapGetters('device', [ 'isDesktopView', 'isMobileOnDesktop' ]),
        ...mapGetters('user', [ 'userLoggedIn' ]),
        modalTitle() {
            return this.isHeaderRedesign
                ? this.$t('MYUPTODATE.SHORTCUTS')
                : this.$t('MYUPTODATE.MY_UPTODATE');
        },
        myUtdTabs() {
            return C_MYUTD_TABS;
        },
        enableAutoFocus() {
            return this.isDesktopView && !this.isTabletDevice;
        }
    },
    watch: {
        isMobileOnDesktop(newVal) {
            if (newVal) {
                this.closeMyUtdModal();
            }
        }
    },
    mounted() {
        this.subscribe({
            eventName: 'wkutd.open-myuptodate-modal',
            handlerFn: this.showUtdModal
        });
        this.subscribe({
            eventName: 'wkutd.close-myuptodate-modal',
            handlerFn: this.closeMyUtdModal
        });
    },
    beforeUnmount() {
        this.unsubscribe({
            eventName: 'wkutd.open-myuptodate-modal',
            handlerFn: this.showUtdModal
        });
        this.unsubscribe({
            eventName: 'wkutd.close-myuptodate-modal',
            handlerFn: this.closeMyUtdModal
        });
    },
    methods: {
        ...mapActions('app', [
            'subscribe',
            'unsubscribe',
            'delayExecuteUntilDataLoaded'
        ]),
        ...mapMutations('profile', [
            SET_DEFAULT_CONTENT_TAB
        ]),
        closeMyUtdModal() {
            this.$emit('closed');
            this.$refs.myUtdModal.close();
        },
        showUtdModal(payload) {
            const { event, tab } = payload;
            if (this.isDesktopView) {
                if (event) {
                    event.preventDefault();
                }
                // Make sure app had completed loading before proceeding
                this.delayExecuteUntilDataLoaded(() => {
                    if (!this.permissions.myUpToDate) {
                        if (!event || event.currentTarget.id === null) {
                            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                                targetUrl: (event && event.target ? event.target.href : ''),
                                uiElementName: 'responsiveUnknown'
                            });
                        }
                        this.router.url('/account/index');
                        return;
                    }
                    if (this.userLoggedIn) {
                        this.logMyUtdEvent(event, tab);
                        this.$refs.myUtdModal.open();
                    }
                });
            }
            else if (event) {
                new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
            }
        },
        logMyUtdEvent(event, tab) {
            if (typeof tab !== 'undefined') {
                this[SET_DEFAULT_CONTENT_TAB](tab);
            }

            if (event && (tab >= this.myUtdTabs.HISTORY || typeof tab === 'undefined')) {
                new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
            }
        },
        getHeaderTitle(title) {
            return decodeHtml(title);
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

:deep(#myuptodate .myUtdTabContent) {
  overflow: auto;
}

.my-utd-modal-content {
  width: 700px;
  max-height: 70vh;
}
</style>
