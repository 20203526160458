import { stringToNumeric } from './String';
import {
    C_MY_ACCOUNT,
    passwordSpecialCharactersRegex,
    validUserNamePwdCharactersRegex
} from './constants';
import enLocales from '_acaSrc/localizations/localization_en';

export function validateUniqueKeys(input) {
    // Ensure any id values provided in array are unique from indexes
    const keys = [];
    const dupeList = [];

    if (!Array.isArray(input)) {
        // eslint-disable-next-line no-console
        console.warn('Invalid input, array expected.');
        return false;
    }

    Array.from(input).forEach((item, index) => {
        let key = index;
        if (item.id) {
            if (!isNaN(item.id)) {
                key = stringToNumeric(item.id);
            }
            else {
                key = item.id;
            }
        }

        if (!keys.includes(key)) {
            keys.push(key);
        }
        else {
            dupeList.push(`\nid: "${key}"`);
        }
    });

    if (dupeList.length) {
        let msg = 'Duplicate keys encountered in accordionItems:';
        dupeList.forEach(err => {
            msg += err;
        });
        // eslint-disable-next-line no-console
        console.warn(msg);
    }

    return dupeList.length === 0;
}

export function nullUndefinedOrEmpty(value) {
    return value === null || value === '' || typeof value === 'undefined';
}

export function checkErrorCodeExists(mocType, errorType, errorCode) {
    const errorString = `${errorType}_${errorCode}`;
    if (!enLocales[mocType] || !enLocales[mocType][errorString]) {
        return `${mocType}.${errorType}_DEFAULT`;
    }
    return `${mocType}.${errorString}`;
}

// eslint-disable-next-line complexity
export function validatePassword(password, username = '') {
    if (!password.match(validUserNamePwdCharactersRegex)) {
        // Password contains invalid characters
        return 'MYACCOUNT.PASSWORD_INVALID_CHARACTERS';
    }

    if ((password.length < 8) || (password.length > 24)) {
        // Password must be between 8 and 24 characters
        return 'MYACCOUNT.PASSWORD_INVALID_LENGTH';
    }

    if (!password.match(/[A-Z]/)) {
        // Password must have at least one capital letter
        return 'MYACCOUNT.PASSWORD_INVALID_NOUPPERCASE';
    }

    if (!password.match(/[0-9]/) && !password.match(passwordSpecialCharactersRegex)) {
        // Password must contain one number or special character
        return 'MYACCOUNT.PASSWORD_INVALID_NONUMERIC_OR_SPECIAL';
    }

    if (password === username) {
        // Password cannot match username
        return 'MYACCOUNT.PASSWORD_INVALID_MATCHES_USERNAME';
    }
    return '';
}

export function validateUserName(userName) {
    // Not using === here because == null checks for both null and undefined
    // eslint-disable-next-line eqeqeq
    if (userName == null) {
        return 'MYACCOUNT.NEW_USER_NAME_INVALID_CHARACTERS';
    }
    if ((userName.length < C_MY_ACCOUNT.MIN_USERNAME_LENGTH)
        || (userName.length > C_MY_ACCOUNT.MAX_USERNAME_LENGTH)) {
        return 'MYACCOUNT.NEW_USER_NAME_INVALID_LENGTH';
    }
    if (!userName.match(validUserNamePwdCharactersRegex)) {
        return 'MYACCOUNT.NEW_USER_NAME_INVALID_CHARACTERS';
    }
    return '';
}

export function validateConfirmUserName(newUserName, confirmUserName) {
    // Not using === here because == null checks for both null and undefined
    // eslint-disable-next-line eqeqeq
    if (newUserName == null || confirmUserName == null) {
        return 'MYACCOUNT.EMPTY_FIELD';
    }
    if (newUserName !== confirmUserName) {
        return 'MYACCOUNT.NEW_USER_NAME_MUST_MATCH';
    }
    if (!confirmUserName.length > 0) {
        return 'MYACCOUNT.EMPTY_FIELD';
    }
    return '';
}

export function isBqpKpp(type) {
    return type === 'KPP' || type === 'BQP';
}