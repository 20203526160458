<template>
  <div v-show="outlineFlyoutShowOutline"
       id="outlineContainer"
       @click="handleClickEvent($event)"
       @keydown="$event.which === TAB_KEY && handleKeyDownEvent($event)">
    <div id="outlineMask" :class="{'outlineLoading': outlineFlyoutLoading }" />

    <!-- eslint-disable vue/no-v-html -->
    <div id="outlineContent" v-html="outlineFlyoutHtml" />
    <div v-show="outlineFlyoutShowGraphicsLink" id="showGfxDiv">
      <a id="showGfxLink"
         tabindex="0"
         @click="showRelatedGraphicsModal">
        {{ $t('SEARCH.SHOW_GRAPHICS', {count: outlineFlyoutGraphicsCount}) }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { C_KEYCODES } from '_acaSrc/utility/constants';
import { getWindow, getDocument } from '_acaSrc/utility/DOM';
import {
    SET_OUTLINE_FLYOUT_SHOW_OUTLINE,
    SET_OUTLINE_FLYOUT_TIMEOUT_HIDE,
    SET_OUTLINE_FLYOUT_TIMEOUT_OPEN
} from '_acaSrc/store/search.store';

const CONTAINER_PADDING = 26;
const OUTLINE_PADDING = 10;

export default {
    data() {
        return {
            searchResultsElem: null,
            resultsContainer: null,
            navbar: null,
            TAB_KEY: C_KEYCODES.TAB_KEY,
            delayMsHideOutline: 750
        };
    },
    computed: {
        ...mapGetters('feature', [ 'isHeaderRedesign']),
        ...mapGetters('search', [
            'outlineFlyoutShowOutline',
            'outlineFlyoutTopicId',
            'outlineFlyoutTopicLanguage',
            'timeoutOpenOutlineFlyout',
            'timeoutHideOutlineFlyout',
            'outlineFlyoutLoading',
            'outlineFlyoutHtml',
            'outlineFlyoutShowGraphicsLink',
            'outlineFlyoutGraphicsCount'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isBrowserNameMSIE' ])
    },
    created() {
        this.subscribe({ eventName: 'SHOW_OUTLINE_FLYOUT', handlerFn: this.showOutlineFlyout });
    },
    mounted() {
        this.$nextTick(() => {
            this.setupElementListeners();
            this.setupOutlineEventHandlers();

            if (this.isHeaderRedesign) {
                this.navbar = getDocument().querySelector('.wk-navbar-container');
            }
            else {
                // We are not sure if this is being used.
                this.navbar = getDocument().querySelector('.utd-menu-tier3');
            }
        });
    },
    beforeUnmount() {
        getWindow().removeEventListener('resize', this.hideFlyout);
        this.$el.removeEventListener('mousemove', this.cancelHideFlyout);
        this.$el.removeEventListener('focus', this.cancelHideFlyout);
        this.searchResultsElem.removeEventListener('mousemove', this.setResultsPageHandler);
        this.searchResultsElem.removeEventListener('focus', this.setResultsPageHandler);
        this.unsubscribe({ eventName: 'SHOW_OUTLINE_FLYOUT', handlerFn: this.showOutlineFlyout });
    },
    methods: {
        ...mapActions('graphic', [
            'handleUseGraphicLink'
        ]),
        ...mapActions('topic', [
            'handleTopicLinkClick',
            'showRelatedGraphicsModalFromFlyout'
        ]),
        ...mapActions('app', [ 'subscribe', 'unsubscribe' ]),
        ...mapMutations('search', [
            SET_OUTLINE_FLYOUT_SHOW_OUTLINE,
            SET_OUTLINE_FLYOUT_TIMEOUT_HIDE,
            SET_OUTLINE_FLYOUT_TIMEOUT_OPEN
        ]),
        setupElementListeners() {
            this.searchResultsElem = getDocument().getElementById('searchresults');
            this.resultsContainer = getDocument().getElementById('search-results-container');

            if (!this.searchResultsElem) {
                return;
            }

            // This handler will cause the outline to hide after a brief delay,
            // when the empty area  around the search results is moused over
            this.searchResultsElem.addEventListener('mousemove', this.setResultsPageHandler);
            this.searchResultsElem.addEventListener('focus', this.setResultsPageHandler);
        },
        setupOutlineEventHandlers() {
            getWindow().addEventListener('resize', this.hideFlyout);
            this.$el.addEventListener('mousemove', this.cancelHideFlyout);
            this.$el.addEventListener('focus', this.cancelHideFlyout);
        },
        showRelatedGraphicsModal() {
            this.showRelatedGraphicsModalFromFlyout({
                topicId: this.outlineFlyoutTopicId,
                topicLanguage: this.outlineFlyoutTopicLanguage
            });
        },
        handleClickEvent(e) {
            if (e.target.matches('.openRelatedGraphics a')) {
                this.showRelatedGraphicModalFromFlyout(e);
            }

            if (e.target.matches('#outlineGraphics a.graphic')) {
                this.handleUseGraphicLink({ evt: e });
            }

            if (e.target.matches('#outlineSections li a')) {
                // Ensure topic outline links are treated as 'clicks' by scroller
                this.handleTopicLinkClick(e);
            }

            if (e.target.matches('#outlineRelatedPathwaysLinks a')) {
                this.hideFlyout();
            }
        },
        showRelatedGraphicModalFromFlyout(e) {
            e.preventDefault();
            e.stopPropagation();

            const objLink = e.target;
            const parentDiv = objLink.parentNode;
            const parentDivId = parentDiv.attributes.id.value;
            if (parentDivId.indexOf('|') >= 0) {
                const strFields = parentDivId.split('|');
                const graphicType = strFields[1];
                this.showRelatedGraphicsModalFromFlyout({
                    topicId: this.outlineFlyoutTopicId,
                    topicLanguage: this.outlineFlyoutTopicLanguage,
                    graphicType
                });
            }
        },
        handleKeyDownEvent(e) {
            if (e.target.matches('#showGfxDiv a')
                || (e.shiftKey && e.target === this.$el.querySelector('a'))) {

                this.handleOutlineTabKey(e);
            }
        },
        handleOutlineTabKey() {
            const nextLinkToFocus = document.querySelector('.outlineToggle.active');
            if (nextLinkToFocus) {
                nextLinkToFocus.focus();
                this.hideFlyout();
            }
        },
        hideFlyout() {
            if (!this.outlineFlyoutShowOutline) {
                return;
            }

            // Hides the topic flyout
            this[SET_OUTLINE_FLYOUT_SHOW_OUTLINE](false);
            this[SET_OUTLINE_FLYOUT_TIMEOUT_HIDE](null);

            const outlineToggleActiveElem = document.querySelector('.outlineToggle.active');
            outlineToggleActiveElem && outlineToggleActiveElem.classList.remove('active');

            this.$el.scrollTop = 0;

            clearTimeout(this.timeoutOpenOutlineFlyout);
            this[SET_OUTLINE_FLYOUT_TIMEOUT_OPEN](null);
        },
        cancelHideFlyout(e) {
            e && e.stopPropagation();
            if (this.timeoutHideOutlineFlyout) {
                // Stops the hiding of the topic flyout
                clearTimeout(this.timeoutHideOutlineFlyout);
                this[SET_OUTLINE_FLYOUT_TIMEOUT_HIDE](null);
                this[SET_OUTLINE_FLYOUT_SHOW_OUTLINE](true);
            }
        },
        setResultsPageHandler() {
            if (!this.isDesktopView || this.timeoutHideOutlineFlyout
                || !this.outlineFlyoutShowOutline) {
                return;
            }
            const timeoutHideFlyout = setTimeout(this.hideFlyout, this.delayMsHideOutline);
            this[SET_OUTLINE_FLYOUT_TIMEOUT_HIDE](timeoutHideFlyout);
        },
        showOutlineFlyout(outlineToggleButtonSize) {
            this.$el.style.width = '400px';
            const outLeft = this.getLeftContainerPosition(outlineToggleButtonSize);
            const outTop = this.getTopContainerPosition(outlineToggleButtonSize);

            // Sets the position of the flyout then displays it
            this.$el.style.left = `${outLeft}px`;
            this.$el.style.top = `${outTop}px`;

            this.$el.classList.add('active');

            this[SET_OUTLINE_FLYOUT_SHOW_OUTLINE](true);
            this.setFocusOnFirstLink();
        },
        setFocusOnFirstLink() {
            const eleOutlineHeading = this.$el.querySelector('a');
            eleOutlineHeading && eleOutlineHeading.focus();
        },
        getLeftContainerPosition(outlineToggleButtonSize) {
            const flyoutMaxWidth = 400; // Default max flyout width
            const flyoutBorderWidth = 4; // Size of flyout borders

            let leftEdge = outlineToggleButtonSize.left + outlineToggleButtonSize.width;
            const rightEdge = leftEdge + flyoutMaxWidth + flyoutBorderWidth;
            const screenWidth = Math.max(getWindow().document.documentElement.clientWidth, 0);

            // If our right edge is off the screen, we want to place the flyout to the left
            if (rightEdge >= screenWidth) {
                // Shift left by size of flyout (400 + borders) and size of toggle button
                leftEdge -= flyoutMaxWidth + flyoutBorderWidth + outlineToggleButtonSize.width;

                // If our left edge now goes off screen, shrink flyout to fit
                if (leftEdge < 0) {
                    const newWidth = flyoutMaxWidth + leftEdge;
                    this.$el.style.width = `${newWidth}px`;
                    leftEdge = outlineToggleButtonSize.left - newWidth - flyoutBorderWidth;
                }
            }
            return leftEdge;
        },
        getTopContainerPosition(outlineToggleButtonSize) {
            const scrollY = this.getDocumentScrolledVertically();

            // Determine bottom coordinate of header [DE10652]
            const hdrBtm = this.resultsContainer.offsetTop;
            const vpHgt = Math.max(getWindow().document.documentElement.clientHeight,
                getWindow().innerHeight || 0);

            const outlineHeight = (vpHgt - hdrBtm) < 620 ? (vpHgt - hdrBtm - 20) : 600;
            this.$el.style.maxHeight = `${outlineHeight}px`;

            const navBarBtm = this.navbar ? this.navbar.getBoundingClientRect().bottom : 0;

            // Determine vertical center of visible search results area
            const vpTop = scrollY < (hdrBtm - navBarBtm) ? hdrBtm : scrollY + navBarBtm;
            const vpBtm = vpHgt + scrollY - 1;
            const vpHlf = vpTop + ((vpBtm - vpTop) / 2);

            // Determine vertical center of hovered search result
            const selTop = outlineToggleButtonSize.top + scrollY;
            const selBtm = selTop + outlineToggleButtonSize.height;
            const selHlf = selTop + ((selBtm - selTop) / 2);

            // If selHlf < vpHlf, show outline pegged to top of results view
            // otherwise, show outline pegged to bottom of results view
            let outTop = (OUTLINE_PADDING * -1) + (scrollY > (hdrBtm - navBarBtm)
                ? scrollY - (hdrBtm - navBarBtm) : 0);

            if (selHlf > vpHlf) {
                outTop = vpHgt - CONTAINER_PADDING - OUTLINE_PADDING
                    - outlineHeight - hdrBtm + scrollY;
            }
            return outTop;
        },
        getDocumentScrolledVertically() {
            let scrollY;
            if (this.isBrowserNameMSIE) {
                scrollY = getWindow().pageYOffset || 0;
            }
            else {
                scrollY = getWindow().scrollY;
            }
            return scrollY;
        }
    }
};
</script>
