<!-- eslint-disable vue/html-closing-bracket-newline -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="copyright" :class="{center: center}"><!--
    -->&copy; {{ curYear }} UpToDate<sup v-if="!full">&reg;</sup><!--
      --><span v-if="full && isProspectView"
               :class="{copyrightFull: full}"><!--
               -->{{ $t('CONTENT.COPYRIGHT_TEXT') }}</span><!--
      --><span v-if="full && !isProspectView"
               :class="{copyrightFull: full}"><!--
               -->, Inc. and/or its affiliates. All Rights Reserved.</span><!--
      --><span v-if="isUccState"><!--
        --><br v-if="isNotDesktopView"> 京 ICP 证 110182 号 | (京)-经营性-{{ uccCopyrightYear }}</span><!--
  --></div>
</template>

<script>

export default {
    props: {
        full: Boolean,
        center: Boolean,
        isProspectView: Boolean,
        isUccState: Boolean,
        isNotDesktopView: Boolean,
        uccCopyrightYear: String
    },
    computed: {
        curYear() {
            return new Date().getFullYear();
        }
    }
};
</script>