import { cloneDeep } from 'lodash';
import { C_QAP } from '_acaSrc/utility/constants';
import { extractDrugNameFromTopicTitle } from '_acaSrc/utility/contents/topic/topic';
import { setSearchUrlParamsHelper } from '_acaSrc/utility/http';

export default class QapDto {
    constructor(payload) {
        const { result, searchTerm } = payload;

        // Any property directly assigned to 'result' object used to render inline Mobile
        // Any property directly assigned to 'this' object used to render Desktop panel

        // Store search term locally for use by decendents
        this.searchTerm = searchTerm;

        // Backup current type (will be 'drug_info_panel'), so it can
        // be restored in checkResetQapResult()
        result.originalType = result.type;

        // Turns off "classic" AngularJS QAP templating
        result.isPanelResult = false;
        result.type = C_QAP.UI.QAP_TYPE;

        // If QAP should use title found "as-is" from search result,
        // then do not call custom title processing method.
        if (!payload.retainOriginalTitle) {
            this._setQapTitle(result);
        }

        // Next set local properties for desktop panel
        this.type = result.type;
        this.title = result.title;
    }

    _setQapTitle(result) {
        let title = '';

        if (result.searchResults && result.searchResults[0]) {
            title = result.searchResults[0].title;
        }

        if (!title && result.qapContents && result.qapContents[0]) {
            title = result.qapContents[0].title;
        }

        result.title = extractDrugNameFromTopicTitle(title);
    }

    _toQapObject(payload) {
        const { results, rankIndex, kpTab, type } = payload;

        return results && results.filter(result => result.type === type)
            .map(result => {
                return {
                    title: result.title,
                    url: setSearchUrlParamsHelper(result.url, {
                        rankIndex: (rankIndex + 1),
                        searchTerm: this.searchTerm,
                        kpTab
                    })
                };
            });
    }

    _setQapPanelItem(result, rankIndex, type) {
        const qapObjectDto = this._toQapObject({
            searchTerm: this.searchTerm,
            results: result.searchResults,
            rankIndex,
            type
        });

        result.dipCount = 0;
        if (qapObjectDto && type === C_QAP.SECTION.DRUG_INTERACTIONS) {
            this._addDrugInteractionInfo(result, qapObjectDto[0]);
        }

        result.rxTransitionsCount = 0;
        if (qapObjectDto && type === C_QAP.SECTION.RX_TRANSITIONS) {
            this._addRxTransitionsInfo(result, qapObjectDto[0]);
        }
    }

    _addDrugInteractionInfo(result, qapObjectDto) {
        // Indicate there is at least 1 'Drug Interactions' panel
        // present for accurate assignment of 'display_rank' on
        // main search results URLs
        result.dipCount = 1;
        result.dip = qapObjectDto;
        this.dip = cloneDeep(qapObjectDto);
    }

    _addRxTransitionsInfo(result, qapObjectDto) {
        result.rxTransitionsCount = 1;
        result.rxTransitions = qapObjectDto;
        this.rxTransitions = cloneDeep(qapObjectDto);
    }
}