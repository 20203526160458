import { h, render } from 'vue';
import UtdInlineGraphics from '_acaSrc/components/shared/stdlib/UtdInlineGraphics.vue';
import InlineGraphicTemplate from './inlineGraphicsTemplate';

export default class InlineGraphics {
    /** @param { Element } selectorBase The base element to perform querySelector on*/
    constructor(topicViewRef, selectorBase, graphicsImageKeyMap) {
        this.topicViewRef = topicViewRef;
        this.selectorBase = selectorBase;
        this.graphicsImageKeyMap = graphicsImageKeyMap;
        this.inlineGraphicClasses = new Set();
        this.curParagraphIndex = -1;
        /** @type { Object.<string, paragraph> } */
        this.paragraphData = {};
    }

    /**
     * For every anchor found to have the necessary attribute, create a template
     * before its parent paragraph, populated with the image data needed to
     * facilitate injecting a UtdInlineGraphics component
     */
    createInlineGraphicsTemplates() {
        this.inlineGraphicClasses.clear();

        if (!this.selectorBase
         || !this.selectorBase.querySelectorAll
         || !this.graphicsImageKeyMap) {
            return this;
        }

        const inlineAnchors = this.selectorBase.querySelectorAll('a[data-inline-graphics]');

        inlineAnchors.forEach(anchor => {
            const newTemplate = new InlineGraphicTemplate(anchor, this.graphicsImageKeyMap);
            this.curParagraphIndex = newTemplate.checkIfFirstOnParagraph(this.curParagraphIndex);

            newTemplate
                .setAttributes()
                .checkIfOnHeader()
                .setLabelIdentifier()
                .setParagraphIdentifier()
                .insertTemplateBeforeParentParagraph();

            // Add label identifier as class
            const labelIdentifier = newTemplate.inlineGraphicClass;
            labelIdentifier && this.inlineGraphicClasses.add(labelIdentifier);
        });

        return this;
    }

    /** Populates the paragraphData object using attribtes set on the templates */
    populateParagraphData() {
        if (!this.selectorBase || !this.selectorBase.querySelectorAll) {
            return this;
        }

        const templates = this.selectorBase.querySelectorAll('template.inlineGraphicPlaceholder');

        templates.forEach(template => {
            const curParagraph = template.classList[0];
            const isHeaderFailsafe = template.classList.contains('onHeaderFailsafe');
            const isHeader = template.classList.contains('onHeader');
            const imageDataObject = createImageDataObject(template);

            /* If we already have a key for a given paragraph, add the additional images
               to the imageData of that paragraph instead and remove the additional templates */
            if (Object.keys(this.paragraphData).includes(curParagraph)) {
                /* Below is needed due to the difference between querySelectorAll's traversal
                   on templates in-paragraph (header graphics) vs templates above-paragraph */
                isHeader
                    ? this.paragraphData[curParagraph].imageData.unshift(imageDataObject)
                    : this.paragraphData[curParagraph].imageData.push(imageDataObject);

                template.parentNode.removeChild(template); // IE11 supported syntax
            }
            else {
                // Add a new paragraph entry
                this.paragraphData[curParagraph] = {
                    isHeaderFailsafe,
                    imageData: [
                        imageDataObject
                    ]
                };
            }
        });
        return this;
    }

    /** Injects the UtdInlineGraphics components into a topic using the provided paragraphData */
    injectInlineGraphics() {
        const templates = this.selectorBase
                       && this.selectorBase.querySelectorAll
                       && this.selectorBase.querySelectorAll('template.inlineGraphicPlaceholder');
        Object.keys(this.paragraphData).forEach((paragraph, index) => {
            const curParagraph = this.paragraphData[paragraph];
            const curTemplateEle = templates && templates[index];
            if (!curTemplateEle) {
                return;
            }

            // TODO: Check to determine whether the `parent` property is even necessary here.
            // Originally it was added to allow for Vue to handle garbage colleciton automatically.
            // Without it, the garbge collection may not work.
            
            // const inlineGraphicParams = {
            //     parent: this.topicViewRef,
            //     propsData: {
            //         graphics: curParagraph.imageData,
            //         isHeaderFailsafe: curParagraph.isHeaderFailsafe
            //     }
            // };
            const props = {
                graphics: curParagraph.imageData,
                isHeaderFailsafe: curParagraph.isHeaderFailsafe
            };

            const inlineGraphicComponent = h(UtdInlineGraphics, props);
            render(inlineGraphicComponent, curTemplateEle);
            curTemplateEle.setAttribute('style','display: block;')

            this.topicViewRef
              && this.topicViewRef.inlineGraphicsComponents
              && this.topicViewRef.inlineGraphicsComponents.push(inlineGraphicComponent);
        });
        return this;
    }

    setInlineGraphicsSyncedHover(selectorBase = this.selectorBase) {
        this.inlineGraphicClasses.forEach(className => {
            const relevantLinks = selectorBase.querySelectorAll(`.${className}`);
            relevantLinks.forEach(element => {
                element.addEventListener('mouseenter', () => addHover(relevantLinks));
                element.addEventListener('mouseleave', () => removeHover(relevantLinks));
            });
        });

        return this;
    }

    removeInlineGraphicsSyncedHover() {
        this.inlineGraphicClasses.forEach(className => {
            const relevantLinks = this.selectorBase.querySelectorAll(`.${className}`);
            relevantLinks.forEach(element => {
                element.removeEventListener('mouseenter', () => addHover(relevantLinks));
                element.removeEventListener('mouseleave', () => removeHover(relevantLinks));
            });
        });
    }
}

/** Creates an imageData object by pulling attributes off of the given template */
function createImageDataObject(template) {
    return {
        imageKey: template.getAttribute('imageKey'),
        url: template.getAttribute('url'),
        title: template.getAttribute('title'),
        label: template.getAttribute('label'),
        classLabel: template.getAttribute('label').replace(' ', '-').toLowerCase()
    };
}

const addHover = relevantLinks => {
    relevantLinks.forEach(element => {
        element.classList.add('group-hover');
    });
};
const removeHover = relevantLinks => {
    relevantLinks.forEach(element => {
        element.classList.remove('group-hover');
    });
};

/**
 * An entry in a paragraphData object
 * @typedef { Object } paragraph
 * @property { [{
 *      imageKey: String,
 *      url: String,
 *      title: String,
 *      label: String,
 *      classLabel: String
 * }] } graphics An array of graphics
 * @property { Boolean } isHeaderFailsafe
 * Whether or not the paragraph header failsafe should be used
 */