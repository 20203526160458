<template>
  <utd-content-searchbar />
</template>

<script>
import UtdContentSearchbar from './SearchBar.vue';

export default {
    components: {
        UtdContentSearchbar
    }
};
</script>