<template>
  <div class="error404__wrapper">
    <h3 class="error404__title">{{ $t('ERROR.PAGE_NOT_FOUND') }}</h3>
    <span class="error404__page-not-found" />
    <p class="error404__description">{{ $t('ERROR.PAGE_NOT_FOUND_DESCRIPTION') }}</p>
    <utd-button button-size="large" @click="redirectHomePage">
      {{ $t('ERROR.GO_TO_HOME_PAGE') }}
    </utd-button>
  </div>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdButton
    },
    computed: {
        ...mapGetters('app', [ 'router' ])
    },
    methods: {
        redirectHomePage() {
            this.router.go('search');
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles";

.error404__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: @DS1-LIGHT-ON-DARK-FONT-COLOR;
  padding-top: 40px;

  .error404__title {
    .ds1-utd-weight-normal();
    font-size: 40px;
    color: @DS1-UTD-TITLE-TEXT-COLOR;
  }

  .error404__description {
    .ds1-utd-h3();
    color: @DS1-UTD-TOPIC-TEXT-COLOR;
    padding: 20px;
    text-align: center;
  }

  .error404__page-not-found {
    background: url("~_acaAssets/error/page-not-found.svg") no-repeat;
    width: 180px;
    height: 180px;
    background-size: 180px;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .error404__wrapper {
      padding-top: 0;

      .error404__title {
        font-size: 60px;
      }

      .error404__page-not-found {
        width: 224px;
        height: 224px;
        background-size: 224px;
      }
    }
  }
}
</style>