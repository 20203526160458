<template>
  <utd-modal-dialog ref="forgotPasswordRef"
                    :modal-classes="'reset-password-modal'"
                    :dialog-classes="'ds1-default-dialog'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop"
                    @closed="handleClose()">
    <template #header>
      <span v-show="resetPwdDisplayShowEmailCtrls">{{ $t('LOGIN.RESET_PASSWORD_INTRO') }}</span>
      <span v-show="!resetPwdDisplayShowEmailCtrls">
        {{ $t('LOGIN.RESET_PASSWORD_EMAIL_SENT') }}</span>
    </template>
    <template v-slot>
      <div id="forgotPasswordView">
        <div class="dialog__content">
          <p v-show="resetPwdDisplayShowEmailCtrls"
             class="dialog__instructions-info"
             v-text="$t('LOGIN.RESET_PASSWORD_INSTRUCTIONS')" />

          <form v-show="resetPwdDisplayShowEmailForm"
                ref="forgotPasswordForm"
                name="forgotPasswordForm"
                :class="{isActive: resetPwdIsSendEmailActive}"
                @submit.prevent="sendEmailAddressRequest">
            <fieldset class="dialog__fieldset">

              <utd-notification v-if="resetPwdMessageCode"
                                :notification-mode="'error'">
                <template v-slot:title>{{ $t(resetPwdMessageCode) }}</template>
                <span class="notification-text">{{ $t('MYACCOUNT.ACCOUNT_GUID_MESSAGE') }}</span>
              </utd-notification>

              <utd-notification v-if="!resetPwdMessageCode && resetPwdMessageContent">
                <template v-slot:title>Information</template>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span class="notification-text" v-html="resetPwdMessageContent" />
              </utd-notification>

              <div v-show="resetPwdDisplayShowEmailCtrls" class="dialog__input-container wk-js2">
                <utd-text-input :label="$t('LOGIN.EMAIL_ADDRESS')">
                  <input id="emailAddress"
                         v-model.trim="emailFormModel"
                         type="text"
                         class="wk-field-input"
                         required>
                </utd-text-input>
              </div>
            </fieldset>
          </form>
          <utd-customer-support />
        </div>
      </div>
    </template>
    <template #footer>
      <utd-button id="CloseButton"
                  :button-style="closeButtonStyle"
                  class="form-button close-button"
                  @click="closeDialog()">{{ $t('CLOSE') }}
      </utd-button>
      <utd-button v-show="resetPwdDisplayShowEmailCtrls"
                  id="btnResetRequest"
                  :disabled="resetPwdIsSendEmailDisabled"
                  class="form-button login-button"
                  :class="{isActive: resetPwdIsSendEmailActive}"
                  @click.prevent="submitForm">
        {{ $t('LOGIN.SEND_REQUEST') }}
      </utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { C_UI } from '_acaSrc/utility/constants';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { getWindow } from '_acaSrc/utility/DOM';
import { dispatchEvent } from '_acaSrc/utility/Events';
import { safeTimeout } from '_acaSrc/utility/timers';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import UtdCustomerSupport from '_acaSrc/components/shared/stdlib/UtdCustomerSupport.vue';

import {
    SET_RESET_PWD_MESSAGE_CONTENT,
    SET_RESET_PWD_EMAIL_FORM,
    SET_RESET_PWD_IS_SEND_EMAIL_DISABLED,
    SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE,
    RESET_EMAIL_FORM
} from '_acaSrc/store/auth.store';

export default {
    components: {
        UtdNotification,
        UtdModalDialog,
        UtdButton,
        UtdTextInput,
        UtdCustomerSupport
    },
    emits: [ 'close-forgot-password-modal' ],
    data() {
        return {
            cancelResizeTimeout: null
        };
    },
    computed: {
        ...mapGetters('app', [ 'countryCode' ]),
        ...mapGetters('auth', [
            'resetPwdEmailForm',
            'resetPwdMessageCode',
            'resetPwdMessageContent',
            'resetPwdDisplayShowEmailForm',
            'resetPwdDisplayShowEmailCtrls',
            'resetPwdIsSendEmailDisabled',
            'resetPwdIsSendEmailActive'
        ]),
        ...mapGetters('device', [ 'isDesktopView', 'isMobileOnDesktop' ]),
        closeButtonStyle() {
            return this.resetPwdDisplayShowEmailCtrls ? 'ghost' : 'default';
        },
        emailFormModel: {
            get() {
                return this.resetPwdEmailForm.email;
            },
            set(value) {
                this[SET_RESET_PWD_EMAIL_FORM](value);
            }
        }
    },
    methods: {
        ...mapActions('auth', [
            'sendResetRequestEmailAddress'
        ]),
        ...mapMutations('auth', [
            RESET_EMAIL_FORM,
            SET_RESET_PWD_MESSAGE_CONTENT,
            SET_RESET_PWD_EMAIL_FORM,
            SET_RESET_PWD_IS_SEND_EMAIL_DISABLED,
            SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE
        ]),
        triggerDelayedResizeEvent() {
            this.cancelResizeTimeout = safeTimeout(() => {
                dispatchEvent('resize');
            }, C_UI.WINDOW_RESIZE_DELAY_MS, { cancelHook: true }).cancelTimeout;
        },
        sendEmailAddressRequest() {
            if (this.resetPwdEmailForm.email === '' || this.resetPwdEmailForm.email === undefined) {
                this[SET_RESET_PWD_MESSAGE_CONTENT](this.$t('LOGIN.RESET_PASSWORD_ERROR'));
            }
            else {
                this[SET_RESET_PWD_IS_SEND_EMAIL_DISABLED](true);
                this[SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE](true);
                // Hide keyboard
                const focus = getWindow().document.querySelector(':focus');
                if (focus) {
                    focus.blur();
                }
                this.sendResetRequestEmailAddress().finally(() => {
                    this[SET_RESET_PWD_IS_SEND_EMAIL_DISABLED](false);
                    this[SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE](false);
                    // Trigger a window resize to ensure footer repositions
                    this.triggerDelayedResizeEvent();
                });
            }
        },
        open() {
            this.$refs.forgotPasswordRef.open();
        },
        async closeDialog() {
            this.$refs.forgotPasswordRef.close();
        },
        handleClose() {
            this[RESET_EMAIL_FORM]();
            this.$emit('close-forgot-password-modal');
        },
        submitForm() {
            this.$refs
                .forgotPasswordForm
                .dispatchEvent(new Event('submit', {cancelable: true}));

        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.reset-password-modal .utd-dialog-content {
  .ds1-pa-4();
}

@media screen and (min-width: 768px) {
  .reset-password-modal {
    .utd-modal-content .utd-dialog-frame {
      width: 670px;
    }
  }
}

@media screen and (max-width: 768px) {
  .reset-password-modal {
    .utd-modal-content .utd-dialog-frame {
      width: auto;
      max-width: 360px;
    }
  }
}
</style>


<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.dialog__instructions-info {
  .ds1-reset-p();
  .ds1-mb-3();
}

.dialog__content {
  font-size: 14px;
}

.dialog__fieldset {
  .ds1-ma-0();
  .ds1-pa-0();
  border: none;
}

.utd-customer-support {
  margin-top: 12px;
}
</style>