import PendoGuide from './PendoGuide';
import { getWindow, getDocument } from '_acaSrc/utility/DOM';

const PENDO_RATING_GUIDE = 'aca-feedbackrating';

export default class RatingGuide extends PendoGuide {
    _getGuideName() {
        return this._getGuideNameIfActive(PENDO_RATING_GUIDE);
    }
}

export const lockRatingControls = () => {
    // Adds class to UtdStarRating components to lock interactions
    // while Pendo guide is active.
    Array.from(
        getDocument().querySelectorAll('.star-rating__container')
    ).forEach(el => el.classList.add('rating-locked'));
};

export const unlockRatingControls = () => {
    Array.from(getDocument().querySelectorAll('.star-rating__container.rating-locked'))
        .forEach(el => el.classList.remove('rating-locked'));
};

export const addPendoUnlockRatingControls = () => {
    // Provides a method to unlock UtdStarRating controls
    // from the Pendo guide when the rating form is submitted.
    getWindow().pendoUnlockRatingControl = unlockRatingControls;
};