<template>
  <div id="abstractContainer">
    <utd-modal-dialog ref="ovidLinksDialog"
                      modal-classes="utd-alert-dialog">
      <template #header>Full Text</template>
      <ovid-dialog-links :ovid-links="theOvidLinks" />
    </utd-modal-dialog>

    <!-- eslint-disable vue/no-v-html -->
    <h1 v-html="topicTitle" />

    <div v-for="(abstract, idx) in citations" :key="idx" class="abstract">
      <div v-if="abstract.abstractInfo" id="fullAbstract">
        <dl>
          <dt><strong v-text="abstract.citationInfo.citationNumber" /></dt>
          <dd>
            <span v-for="(link, idx2) in orderObjectBy(abstract.links, 'rel')"
                  :key="idx2"
                  class="abstractLinks">

              <input v-if="(link.rel === 'link-resolver') || (link.rel === 'linkout')"
                     v-model="link.label"
                     type="button"
                     class="abstractFullText"
                     @click="fullTextLinkClick(link.url)">

              <a v-if="link.rel === 'pubmed' && link.url"
                 :href="link.url"
                 target="_blank"
                 rel="noopener"
              >PubMed</a>

              <a v-if="link.rel === 'ovid' && link.url"
                 href="#"
                 @click="getOvidLink(link.url)"
              >{{ link.label }}</a>

              <span class="bar">|</span>
            </span>
          </dd>

          <dd v-if="!abstract.links">&nbsp;</dd>

          <dt>TI</dt>
          <dd class="abstractTitle"
          ><span v-text="abstract.abstractInfo.title" />&nbsp;</dd>

          <dt>AU</dt>
          <dd class="abstractAuthor"
          ><span v-text="abstract.abstractInfo.authors" />&nbsp;</dd>

          <dt>SO</dt>
          <dd class="abstractSource"
          ><span v-text="abstract.abstractInfo.source" />&nbsp;</dd>

          <dt>&nbsp;</dt>
          <dd class="abstractTexts">
            <p v-for="(abstractText, idx4) in abstract.abstractTexts"
               :key="idx4"
               class="abstractNames">
              <span v-if="abstractText.name" class="abstractName" v-text="abstractText.name" />
              <span v-text="abstractText.text" />
            </p>
          </dd>

          <dt class="abstractAffiliation">AD</dt>
          <dd class="abstractAffiliation"
          ><span v-text="abstract.abstractInfo.affiliation" />&nbsp;
          </dd>

          <dt v-if="abstract.abstractInfo.pmid">PMID</dt>
          <dd v-for="(link, idx3) in abstract.links" :key="idx3">
            <a v-if="link.rel === 'pubmed' && link.url"
               :href="link.url"
               target="_blank"
               rel="noopener"
               v-text="abstract.abstractInfo.pmid"
            />
          </dd>
          <dd v-if="!isHasPubMed(abstract)"
              v-text="abstract.abstractInfo.pmid"
          />
        </dl>
      </div>
      <div v-if="!abstract.abstractInfo">
        <dl class="citation">
          <dt><strong v-text="abstract.citationInfo.citationNumber" /></dt>
          <dd>&nbsp;</dd>

          <dt>&nbsp;</dt>
          <dd v-text="abstract.citationInfo.description" />

          <dt>&nbsp;</dt>
          <dd class="noAbstract">no abstract available</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>

import UtdModalDialog from '../../shared/stdlib/UtdModalDialog.vue';
import OvidDialogLinks from './OvidDialogLinks.vue';

import orderObjectBy from '../../../filters/OrderObjectBy';
import utdRestClient from '../../../utility/http/UtdRestClient';
import { decodeHtml } from '_acaSrc/utility/DOM';
import { setBrowserTabTitle } from '_acaSrc/utility/Browsers';

export default {
    components: {
        UtdModalDialog,
        OvidDialogLinks
    },
    props: {
        abstracts: Object
    },
    data() {
        return {
            theOvidLinks: []
        };
    },
    computed: {
        topicTitle() {
            let titleStr = 'Medline ® ';
            if (this.abstracts.citations.length > 1) {
                titleStr += 'Abstracts for References ';
            }
            else {
                titleStr += 'Abstract for Reference ';
            }
            titleStr += this.abstracts.citationRange;
            titleStr += ` of '${this.abstracts.topicInfo.title}'`;
            return titleStr;
        },
        topicInfo() {
            return this.abstracts.topicInfo;
        },
        citations() {
            return this.abstracts.citations;
        }
    },
    created() {
        const title = decodeHtml(this.topicTitle);
        setBrowserTabTitle(title);
    },
    methods: {
        fullTextLinkClick(url) {
            if (epicClient && epicClient.windowOpen) {
                epicClient.windowOpen(url);
            }
        },
        isHasPubMed(citation) {
            let link;

            for (link in citation.links) {
                if (citation.links[link].rel === 'pubmed') {
                    return true;
                }
            }
            return false;
        },
        getOvidLink(url) {
            utdRestClient.getCustom(url).then(utdRestResponse => {
                utdRestResponse.data = JSON.parse(utdRestResponse.data);
                if (utdRestResponse.data.length === 1) {
                    window.open(utdRestResponse.data[0].link);
                }
                else {
                    this.theOvidLinks = utdRestResponse.data;
                    this.$refs.ovidLinksDialog.open();
                }
            }).catch(err => {
                console.warn(`Error retrieving Ovid links: ${err}`);
                this.showOvidLinkError();
            });
        },
        showOvidLinkError() {
            this.theOvidLinks = null;
            this.$refs.ovidLinksDialog.open();
        },
        orderObjectBy
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-dialog-content .ovid-dialog-container {
  .ds1-pa-2();
}

#abstractContainer {
  margin: 2em;

  h1 {
    font-size: 0.95em;
  }

  .abstract {
    font-size: 0.8em;
    border-top: 2px solid #000;
    padding: 0.4em 0 1em;

    dt {
      clear: left;
      float: left;
      width: 50px;
      margin-bottom: 4px;
    }

    dd {
      margin-left: 4em;
      word-wrap: break-word;
      margin-bottom: 4px;

      &.noAbstract {
        margin-top: 1em;
      }

      &.text {
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        & span.abstractName::after {
          content: ': ';
        }
      }
    }

    .abstractAffiliation {
      padding-top: 15px;
    }
  }
}

.abstractTexts .abstractNames {
  padding: 6.4px 0;
  margin: 0 0 4px;
}

.abstractName::after {
  content: ': ';
}

.abstract dl dd .abstractLinks:last-child .bar {
  display: none;
}

</style>
