<template>
  <div id="utd-genericMenuContainer" class="utd-abs-white-carbon">
    <dl class="utd-tabControl">
      <dt>
        <span class="breadcrumb">{{ $t('MYACCOUNT.SETTINGS') }}</span>
        {{ $t('MYACCOUNT.CHANGE_TEXT_SIZE') }}
      </dt>
    </dl>
    <dl class="utd-tabControl">
      <dt><p>{{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_DESC') }}</p></dt>
    </dl>
    <ul id="utd-changeTextSize" class="utd-menu selectorMenu">
      <li :class="{'selected': isTextSize('textSize-XS')}"><!--
        --><a class="fontSizeXS"
              @click="setTextSize('textSize-XS')">
      {{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_XS') }}</a></li>
      <li :class="{'selected': isTextSize('textSize-S')}"><!--
        --><a class="fontSizeS"
              @click="setTextSize('textSize-S')">
      {{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_S') }}</a></li>
      <li :class="{'selected': isTextSize('textSize-M')}"><!--
        --><a class="fontSizeM"
              @click="setTextSize('textSize-M')">
      {{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_M') }}<span class="sampleText" /></a></li>
      <li :class="{'selected': isTextSize('textSize-L')}"><!--
        --><a class="fontSizeL"
              @click="setTextSize('textSize-L')">
      {{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_L') }}</a></li>
      <li :class="{'selected': isTextSize('textSize-XL')}"><!--
        --><a class="fontSizeXL"
                @click="setTextSize('textSize-XL')">
      {{ $t('MYACCOUNT.CHANGE_TEXT_SIZE_XL') }}</a></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { SET_STORED_SETTING } from '_acaSrc/store/app.store';

export default {
    name: 'ChangeTextSize',
    computed: {
        ...mapGetters('app', [ 'storedSetting', 'router' ])
    },
    methods: {
        ...mapMutations('app', [ SET_STORED_SETTING ]),
        isTextSize(size) {
            return this.storedSetting.textSize === size;
        },
        setTextSize(size) {
            this[SET_STORED_SETTING]({
                key: 'textSize',
                value: size
            });
        }
    }

};
</script>
