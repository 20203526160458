<template>
  <div class="utd-bread-crumb-container">
    <div v-for="(item, i) in filteredItems"
         :key="`bread-crumb-${i}`"
         class="utd-bread-crumb">
      <span v-if="item.text === '...'" class="utd-bread-crumb-text" @click="onDotsClick()">
        <span class="wkce-icon-filled-more" />
        <span v-if="isNotLastItem(i)" class="wkce-icon-chevron-right" />
      </span>
      <span v-else-if="isLabelOnly"
            class="utd-bread-crumb-text">
        {{ item.text }} <span v-if="isNotLastItem(i)" class="wkce-icon-chevron-right" />
      </span>
      <a v-else
         :href=" item.url"
         class="utd-bread-crumb-text">
        {{ item.text }} <span v-if="isNotLastItem(i)" class="wkce-icon-chevron-right" />
      </a>
    </div>
  </div>
</template>
<script>

const DOT_ITEM = {
    text: '...',
    url: '#'
};

export default {
    props: {
        breadCrumbs: {
            type: Array,
            default: () => []
        },
        hideMiddleItems: {
            type: Boolean,
            default: () => false
        },
        isLabelOnly: {
            type: Boolean,
            default: () => false
        }
    },
    emits: [ 'on-dots-clicked' ],
    computed: {
        firstItem() {
            return this.breadCrumbs[0];
        },
        lastItem() {
            return this.breadCrumbs[this.breadCrumbs.length - 1];
        },
        filteredItems() {
            return this.hideMiddleItems && this.breadCrumbs.length > 2
                ? [ this.firstItem, DOT_ITEM, this.lastItem ]
                : this.breadCrumbs;
        }
    },
    methods: {
        onDotsClick() {
            this.$emit('on-dots-clicked');
        },
        isNotLastItem(index) {
            return index < (this.filteredItems.length - 1);
        }
    }

};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-bread-crumb {
  display: inline;
  position: relative;

  &-text {
    display: inline;
    cursor: pointer;
    .ds1-utd-size-1();
    color: @DS1-BREAD-CRUMBS-TEXT-COLOR;

    &:hover,
    &:focus {
      color: @DS1-BREAD-CRUMBS-TEXT-COLOR;
      text-decoration: none;
    }

    .wkce-icon-filled-more {
      color: @DS1-BREAD-CRUMBS-DOTS-COLOR;
      transform: rotate(90deg);
      margin: 0 10px;
    }

    .wkce-icon-chevron-right {
      color: @DS1-BREAD-CRUMBS-SEPARATOR-COLOR;
    }

    .utd-rtl & {
      .ds1-utd-text-right();

      .wkce-icon-chevron-right {
        &::before {
          content: "\eaf2";
        }
      }
    }
  }

  &-container {
    .ds1-ph-2();
  }
}
</style>