<template>
  <div id="loginView" class="utd-form login-view ds1-utd-js2">
    <div v-if="isSpinnerVisible" class="loading-screen">
      <utd-loading-screen>
        <template #loadingText>
          {{ $t('LOGIN.SSO_LOADING_MESSAGE') }}
        </template>
      </utd-loading-screen>
    </div>
    <div v-else class="floating-labels-form">
      <utd-logo v-if="isNotDesktopView" />
      <form id="loginForm"
            class="form-login jumpstart-form"
            name="loginForm"
            :class="{isActive: isActive}"
            @submit.prevent="">
        <fieldset>
          <h3 v-if="isDesktopView" class="login-form-title">{{ $t('LOGIN.LOG_IN') }}</h3>
          <utd-notification-adapter :notification-mode="message.level"
                                    :notification-content="getErrorContent"
                                    :notification-title="message.title" />

          <label class="form-row form-break">
            <div class="form-label-header">
              <span class="label-above">{{ $t('LOGIN.USER_NAME') }}</span>
            </div>
            <input id="userName"
                   v-model.trim="form.userName"
                   type="text"
                   class="form-field jumpstart-input"
                   autocomplete="off"
                   autocapitalize="off"
                   autocorrect="off"
                   maxlength="50"
                   aria-required="true"
                   @input="resetForm()">
          </label>
          <span v-if="!showPasswordSection"
                id="forgot-username">
            <a href="#"
               class="login-form-link"
               @click="openForgotCredentials">
              <span class="form-link-text">{{ $t('LOGIN.FORGOT_USERNAME') }}</span>
              <i class="wk-icon wkce-icon-question-circle" />
            </a>
          </span>

          <label v-if="showPasswordSection" class="form-row">
            <div class="form-links">
              <span class="label-above">{{ $t('LOGIN.PASSWORD') }}</span>
              <span class="show-password-toggle right label-above wkce-icon-visible"
                    @click="togglePasswordVisibility">
                {{ $t(showPassword ? 'HIDE' : 'SHOW') }}
              </span>
            </div>
            <div class="form-input-icon">
              <input id="password"
                     v-model="form.password"
                     name="password"
                     class="form-field jumpstart-input"
                     maxlength="24"
                     aria-required="true"
                     :type="showPassword ? 'text' : 'password'"
                     @keyup="checkCase($event)"
                     @keydown="checkCase($event)"
                     @focus="checkCase($event)"
                     @blur="resetCaps()">
              <span v-show="capsOn" class="wkce-icon-filled-shift input-icon" />
            </div>
            <span v-if="showPasswordSection"
                  id="forgot-username-password">
              <a href="#"
                 class="login-form-link"
                 @click="openForgotCredentials">
                <span class="form-link-text">{{ $t('LOGIN.SIGN_IN_HELP') }}</span>
                <i class="wk-icon wkce-icon-question-circle" />
              </a>
            </span>
          </label>

          <div class="form-links remember-and-help-link">
            <utd-checkbox v-if="!isOidcUser"
                          :checked="isRememberUsername"
                          @checkbox-changed="checkboxChanged">{{ $t('LOGIN.REMEMBER_ME') }}
            </utd-checkbox>
            <a v-if="!isUccState"
               id="need-help-signing-in"
               href="#"
               class="login-form-link"
               @click="openSignInAdviceModal()">
              <span class="form-link-text">{{ $t('LOGIN.SIGN_IN_EXPLAINER') }}</span>
              <i class="wkce-icon-assignments-matched" />
            </a>
          </div>

          <div class="form-break form-row">
            <span class="form-control">
              <button v-if="!showPasswordSection"
                      id="btnContinueLogin"
                      class="form-button login-button"
                      :class="{isActive: isActive}"
                      :disabled="form.userName === ''"
                      @click="processUsername">
                <span v-show="form.isSubmitting" id="continueBtnPleaseWaitText">
                  {{ $t('PLEASE_WAIT') }}</span>
                <span v-show="!form.isSubmitting" id="continueBtnContinueText">
                  {{ $t('LOGIN.CONTINUE') }}</span>
              </button>
              <button v-if="showPasswordSection"
                      id="btnLoginSubmit"
                      class="form-button login-button"
                      :class="{isActive: isActive}"
                      :disabled="form.password === '' || form.userName === ''"
                      @click="login">
                <span v-show="form.isSubmitting">{{ $t('PLEASE_WAIT') }}</span>
                <span v-show="!form.isSubmitting">{{ $t('LOGIN.LOG_IN') }}</span>
              </button>
            </span>
          </div>

          <searchable-institutions-section @show-spinner="navigateToSso" />
        </fieldset>
      </form>
    </div>

    <user-name-info-tooltip ref="userNameInfoTooltip" />
    <div v-show="showCaptchaSection"
         id="captchaContainer"
         class="floating-labels-form">
      <h3 class="login-form-title">Continue without signing in</h3>
      <div class="form-control form-break">
        <button v-show="!continueAnonymous"
                id="captchaContinueBtn"
                class="form-button login-button"
                @click="displayCaptcha">
          <span>
            {{ $t('LOGIN.CONTINUE') }}
          </span>
        </button>
        <div v-show="showCaptcha"
             id="g-recaptcha"
             class="g-recaptcha"
             data-render="explicit"
             data-action="validate" />
      </div>
      <p class="login-register-section-text">
        Click the button above in order to continue using UpToDate without signing in
      </p>
    </div>

    <login-register-subscribe v-if="showRegisterSubscribeSection && !isSpinnerVisible">
      <template v-slot:registerTitle>
        <h3 v-if="!isOidcUser" class="login-register-title" v-text="getLoginRegisterTitle" />
      </template>
      <template v-if="!isOidcUser" v-slot:registerSectionText>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="login-register-section-text" v-html="getLoginRegisterText" />
      </template>
    </login-register-subscribe>

    <login-footer />
    <next-sign-in-steps-modal ref="nextStepsModal"
                              :institution-name="nextStepsInstName"
                              :sso-login-url="url"
                              :user-name="form.userName"
                              @navigate-to-sso="navigateToSso"
                              @open-learn-more-modal="openLearnMoreModal"
                              @open-two-accounts-modal="openTwoAccountsModal" />
    <sign-in-advice-modal ref="signInAdviceModal" />
    <next-steps-learn-more-modal ref="learnMoreDialog" :email="form.userName" />
    <two-accounts-modal ref="twoAccountsModal" />
    <forgot-password-modal ref="forgotPassword" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getWindow, scrollToZero } from '_acaSrc/utility/DOM';
import {
    C_BROWSER,
    C_UI,
    C_ACCOUNT,
    C_MY_ACCOUNT,
    C_EVENTS,
    C_LOGIN_WORKFLOW
} from '_acaSrc/utility/constants';
import { getDecodeCookie } from '_acaSrc/utility/Cookies';
import {
    SET_FORM_SAVE_USER_NAME,
    SET_FORM_SUBMITTING,
    SET_FORM_USER_NAME,
    SET_MESSAGE_ERROR,
    SET_MESSAGE_TITLE,
    RESET_LOGIN_ERROR_MESSAGE,
    SET_ERROR_LEVEL
} from '_acaSrc/store/login.store';
import UtdNotificationAdapter from '_acaSrc/components/shared/utd/UtdNotificationAdapter.vue';
import UtdCheckbox from '_acaSrc/components/shared/stdlib/UtdCheckbox.vue';
import SearchableInstitutionsSection from './SearchableInstitutionsSection.vue';
import LoginFooter from '_acaSrc/components/login/LoginFooter.vue';
import LoginRegisterSubscribe from '_acaSrc/components/login/LoginRegisterSubscribe.vue';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import NextSignInStepsModal from './NextSignInStepsModal.vue';
import SignInAdviceModal from './SignInAdviceModal.vue';
import PubSub from '_acaSrc/utility/PubSub';
import UserNameInfoTooltip from './UserNameInfoTooltip.vue';
import NextStepsLearnMoreModal from './NextStepsLearnMoreModal.vue';
import TwoAccountsModal from './TwoAccountsModal.vue';
import UtdLoadingScreen from '_acaSrc/components/shared/stdlib/UtdLoadingScreen.vue';
import ForgotPasswordModal from '_acaSrc/account/components/ForgotPasswordModal.vue';
import UtdLogo from '../header/utdLogo/UtdLogo.vue';
import { getQueryParamValues } from '_acaSrc/utility/http';
import Logger from '_acaSrc/utility/Logger';
import { nullUndefinedOrEmpty } from '_acaSrc/utility/Validators';

export default {
    components: {
        UtdNotificationAdapter,
        UtdCheckbox,
        SearchableInstitutionsSection,
        LoginFooter,
        LoginRegisterSubscribe,
        NextSignInStepsModal,
        SignInAdviceModal,
        UserNameInfoTooltip,
        NextStepsLearnMoreModal,
        TwoAccountsModal,
        UtdLoadingScreen,
        ForgotPasswordModal,
        UtdLogo
    },
    data() {
        return {
            isActive: false,
            showPassword: false,
            isContinueLoginForUPTUser: false,
            isRememberUsername: getDecodeCookie('userName') !== '',
            isSSOPreferred: false,
            capsOn: false,
            loginErrorTranslation: '',
            url: '',
            doNotShowConfirmDialog: false,
            nextStepsInstName: '',
            shownNextStepsModal: false,
            isSpinnerVisible: false,
            spinnerTimeout: null,
            continueAnonymous: false,
            captchaStatus: ''
        };
    },
    computed: {
        ...mapGetters('auth', [ 'isOidcUser' ]),
        ...mapGetters('login', [
            'form',
            'message',
            'institutions',
            'loginSubscriberBox',
            'loginRegisterButton',
            'loginErrorMessage',
            'loginInfoMessage'
        ]),
        ...mapGetters('device', [
            'browserType',
            'isDesktopView',
            'noNativeCapsLock',
            'isNotDesktopView'
        ]),
        ...mapGetters('profile', [ 'userProfileVisitor' ]),
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('app', [ 
            'myAccountSidebarRequestStatus', 
            'isUccState', 
            'router', 
            'isProspectView',
            'publicCaptchaKey'
        ]),
        userNameForUserInSession() {
            return this.userProfileVisitor
                  && this.userProfileVisitor.userName;
        },
        getLoginRegisterText() {
            if (this.loginSubscriberBox) {
                return this.$t('LOGIN.OFFERS_A_NUMBER_OF_SUBSCRIPTIONS');
            }
            else if (this.loginRegisterButton) {
                return this.$t('LOGIN.REGISTER_FOR_AN_ACCOUNT',
                    { value: this.$t('MYUPTODATE.CME_CE_CPD') });
            }
            return '';
        },
        getLoginRegisterTitle() {
            if (this.loginSubscriberBox) {
                return this.$t('HEADER.SUBSCRIBE');
            }
            else if (this.loginRegisterButton) {
                return this.$t('LOGIN.REGISTER_NOW');
            }
            return '';
        },
        showRegisterSubscribeSection() {
            return this.loginSubscriberBox || this.loginRegisterButton;
        },
        showPasswordSection() {
            return this.isUccState
              || this.isContinueLoginForUPTUser
              || this.shownNextStepsModal
              || this.rememberUsernameAndPasswordLogin;
        },
        getErrorContent() {
            return this.message.error === C_ACCOUNT.FORM_MESSAGING.WAYF_GENERIC_ERROR_ID
                ? this.$t('LOGIN.WAYF_GENERIC_ERROR') : this.message.error;
        },
        rememberUsernameAndPasswordLogin() {
            return this.isRememberUsername && !this.isSSOPreferred;
        },
        hasCaptchaQueryParam() {
            return this.captchaStatus === 'true';
        },
        showCaptcha() {
            return this.continueAnonymous && this.hasCaptchaQueryParam;
        },
        showCaptchaSection() {
            return this.hasCaptchaQueryParam
                && !this.isProspectView
                && !this.userLoggedIn
                && !this.isSpinnerVisible
                && !nullUndefinedOrEmpty(this.publicCaptchaKey);
        }
    },
    mounted() {
        this[SET_FORM_SAVE_USER_NAME](this.isRememberUsername);
        this.activate();
        new PubSub().subscribe('wkutd.closeNextStepsDialog', this.closeNextStepsDialog);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.closeNextStepsDialog', this.closeNextStepsDialog);
        clearTimeout(this.spinnerTimeout);
    },
    methods: {
        ...mapActions('app', [ 'delayExecuteUntilDataLoaded' ]),
        ...mapActions('login', [
            'logLoginEvent',
            'setErrorMessage',
            'setErrorTitle',
            'loginUser'
        ]),
        ...mapMutations('login', [
            SET_MESSAGE_ERROR,
            SET_MESSAGE_TITLE,
            SET_FORM_SUBMITTING,
            SET_FORM_USER_NAME,
            SET_FORM_SAVE_USER_NAME,
            RESET_LOGIN_ERROR_MESSAGE,
            SET_ERROR_LEVEL
        ]),
        validateCaptchaResult() {
            if (getWindow().grecaptcha && getWindow().grecaptcha.enterprise) {
                const token = getWindow().grecaptcha.enterprise.getResponse();
                try {
                    utdRest('captcha/validate', {
                        clientCaptchaResponse: token
                    });
                }
                catch {
                    return false;
                }
            }
        },
        displayCaptcha() {
            this.continueAnonymous = !this.continueAnonymous;
        },
        openForgotCredentials() {
            logUiClickEventHelper({
                uiElementName: 'wayfLogin_forgotCredentials'
            });
            this.$refs.forgotPassword.open();
        },
        closeTooltip() {
            this.$refs.userNameInfoTooltip.closeTooltip();
        },
        logUiClickEvent(element) {
            logUiClickEventHelper({
                uiElementName: element,
                targetUrl: '/account/reset-password'
            });
        },
        resetForm() {
            this.isContinueLoginForUPTUser = false;
            this.isRememberUsername = false;
            this.shownNextStepsModal = false;
            this.form.password = '';
        },
        resetLoginError() {
            this[SET_MESSAGE_ERROR]('');
            this[SET_MESSAGE_TITLE]('');
            this[SET_ERROR_LEVEL]('error');
            if (this.form.isSubmitting) {
                return;
            }
            // Hide keyboard
            const focus = getWindow().document.querySelector(':focus');
            if (focus) {
                focus.blur();
            }
        },
        validateUserName() {
            if (this.form.userName === '' || this.form.userName === undefined) {
                this[SET_MESSAGE_ERROR](this.$t('LOGIN.USERNAME_EMAIL_REQUIRED'));
                this[SET_MESSAGE_TITLE]('Error');
                this.$el.querySelector('#userName').focus();
                return false;
            }
            return true;
        },
        async processUsername() {
            if (this.form.isSubmitting) {
                return;
            }
            this.resetLoginError();
            let workflow = 0;
            if (this.validateUserName()) {
                this[SET_FORM_SUBMITTING](true);
                workflow = await this.getLoginWorkflow();
                this[SET_FORM_SUBMITTING](false);
                this.isContinueLoginForUPTUser
                  = workflow === C_LOGIN_WORKFLOW.PROMPT_USER_FOR_PASSWORD;
                this[SET_FORM_USER_NAME](this.form.userName.replace(/"/g, ''));
            }
            await this.$nextTick();
            if (this.isContinueLoginForUPTUser) {
                const pwdElem = this.$el.querySelector('#password');
                pwdElem && pwdElem.focus();
            }
            if (workflow === C_LOGIN_WORKFLOW.SHOW_NEXT_STEPS_FOR_SIGN_IN) {
                this.$refs.nextStepsModal.openModal();
                this.shownNextStepsModal = true;
            }
        },
        login() {
            if (this.form.isSubmitting) {
                return;
            }

            this.resetLoginError();
            this.validateUserName();

            if (this.form.password === '' || this.form.password === undefined) {
                this[SET_MESSAGE_ERROR]('Password is required');
                this[SET_MESSAGE_TITLE]('Incorrect Login');
                this.$el.querySelector('#password').focus();
            }
            else {
                this.isActive = true;
                this[SET_FORM_SUBMITTING](true);
                this.loginUser()
                    .then(() => {
                        // nothing to do
                    })
                    .finally(() => {
                        this.isActive = false;
                        this[SET_FORM_SUBMITTING](false);

                        const notificationTitle = this.$el.querySelector('.utd-notification-title');
                        notificationTitle && notificationTitle.focus();
                    });
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        checkCase(event) {
            if (this.noNativeCapsLock) {
                if (event.getModifierState && event.getModifierState('CapsLock')) {
                    this.capsOn = true;
                }
                else {
                    this.capsOn = false;
                }
            }
        },
        resetCaps() {
            this.capsOn = false;
        },
        async getLoginWorkflow() {
            const username = this.form.userName;
            if (!username.includes('@')) {
                return C_LOGIN_WORKFLOW.PROMPT_USER_FOR_PASSWORD;
            }
            try {
                const data = await utdRest('wayf/url', {
                    userName: username
                });
                const res = data.data || data;
                this.handleLoginWorkFlow(res);
                return res.workflow;
            }
            catch {
                return C_LOGIN_WORKFLOW.PROMPT_USER_FOR_PASSWORD;
            }
        },
        handleLoginWorkFlow(res) {
            if (res.workflow === C_LOGIN_WORKFLOW.REDIRECT_TO_SSO && res.url) {
                this.setSsoLoginUrl(res);
                this.navigateToSso(this.url);
            }
            if (res.workflow === C_LOGIN_WORKFLOW.SHOW_NEXT_STEPS_FOR_SIGN_IN && res.name) {
                this.setSsoLoginUrl(res);
                this.nextStepsInstName = res.name;
            }
        },
        navigateToSso(ssoUrl) {
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true
            });
            scrollToZero();
            this.showLoadingSpinner();
            setTimeout(() => {
                getWindow().location.href = ssoUrl;
            }, C_UI.DEFAULT_SPINNER_DELAY_MS);
        },
        setSsoLoginUrl(res) {
            this.url = `${res.url}?login_hint=${this.form.userName}`;
            if (this.form.saveUserName === undefined) {
                this[SET_FORM_SAVE_USER_NAME](false);
            }
            this.url += `&saveLogin=${this.form.saveUserName}`;
        },
        setupRecaptcha() {
            try {
                if (!this.publicCaptchaKey) {
                    return;
                }
                if (getWindow().grecaptcha && getWindow().grecaptcha.enterprise) {
                    setTimeout(() => {
                        getWindow().grecaptcha.enterprise.render('g-recaptcha', {
                            sitekey: this.publicCaptchaKey,
                            callback: this.validateCaptchaResult
                        });
                    }, C_UI.CAPTCHA_RENDER_DELAY_MS);
                }
            }
            catch(e) {
                Logger.warn(`Failed to initialize reCaptcha - ${e}`);
                this.captchaStatus = '';
            }
        },
        checkCaptchaStatus() {
            this.captchaStatus = getQueryParamValues(getWindow().location.href, 'captcha');
        },
        activate() {
            this.checkCaptchaStatus();
            const usernameData = getDecodeCookie('userName');
            let username = usernameData;
            try {
                const loginHint = JSON.parse(usernameData).login_hint;
                username = loginHint;
                this.isSSOPreferred = true;
            }
            catch (e) {
                this.isSSOPreferred = false;
            }

            if (username) {
                this[SET_FORM_USER_NAME](username.replace(/"/g, ''));
                this[SET_FORM_SAVE_USER_NAME](true);
            }

            // Check if we've received the initial data response
            // for the app being loaded the first time
            // eslint-disable-next-line complexity
            this.delayExecuteUntilDataLoaded(() => {
                this.logLoginEvent(this.showCaptchaSection);
                this.setupRecaptcha();
                if (this.browserType !== C_BROWSER.TYPE_SMALL_SCREEN) {
                    if ((this.rememberUsernameAndPasswordLogin)) {
                        this.$el.querySelector('#password').focus();
                    }
                    else {
                        this.$el.querySelector('#userName').focus();
                    }
                }
                if (this.loginErrorMessage) {
                    this.setErrorMessage(this.loginErrorMessage);
                    this[RESET_LOGIN_ERROR_MESSAGE]();
                }

                else if (this.loginInfoMessage) {
                    this.setErrorTitle(this.loginInfoMessage.title);
                    this.setErrorMessage(this.loginInfoMessage.error);
                    this[SET_ERROR_LEVEL](this.loginInfoMessage.level);
                }
                if (!username
                      && this.myAccountSidebarRequestStatus
                        === C_MY_ACCOUNT.SIDEBAR_REQUEST.PENDING) {
                    if (this.userNameForUserInSession) {
                        this[SET_FORM_USER_NAME](this.userNameForUserInSession);
                    }
                }
            });
        },
        checkboxChanged(value) {
            this[SET_FORM_SAVE_USER_NAME](value);
        },
        openLearnMoreModal({ keepOneAccount }) {
            this.$refs.nextStepsModal.closeModal({ showPasswordInput: false });
            this.$refs.learnMoreDialog.open({ keepOneAccount });
        },
        openTwoAccountsModal() {
            this.$refs.nextStepsModal.closeModal({ showPasswordInput: false });
            this.$refs.twoAccountsModal.openModal();
        },
        openSignInAdviceModal() {
            this.$refs.signInAdviceModal.open();
        },
        closeNextStepsDialog({ showPasswordInput }) {
            this.shownNextStepsModal = showPasswordInput;
            if (this.shownNextStepsModal) {
                this.$nextTick(() => {
                    this.$el.querySelector('#password').focus();
                });
            }
        },
        showLoadingSpinner() {
            this.isSpinnerVisible = true;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#loginView .wk-logo {
  .ds1-pb-2();
  .ds1-mt-1();
}
</style>

<style lang="less" scoped>
@import '~_acaAssets/wkce/colors/wkce-app-styles';

.remember-and-help-link {
  .ds1-flex-row-wrap();
  justify-content: space-between;
  align-items: flex-start;
}

.login-register-section-text {
  text-align: left;
  .ds1-utd-size-2();
  .ds1-mt-2();
}

.forgot-credentials-icon {
  color: #005b92;
}

.forgot-credentials-link {
  margin-right: 2px;
}

.utd-form {
  &.login-view {
    input[type="password"]::-ms-reveal {
      display: none;
    }
  }

  .jumpstart-form .show-password-toggle.right {
    color: @DS1-UTD-JS2-LINK-BASE;
    cursor: pointer;
    font-weight: normal;

    &::before {
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }

  .wk-icon-link {
    .ds1-mh-1();
    font-size: 16px;

    .wk-icon {
      height: 100%;
    }
  }

  .form-label-header {
    display: flex;
  }

  .form-links {
    .ds1-pb-0();
  }

  .form-row {
    .ds1-flex-column-wrap();
  }

  #forgot-username,
  #forgot-username-password {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-top: 6px;
  }

  .form-link-text {
    margin-right: 4px;
    .ds1-utd-size-2();
  }

  .wkce-icon-question-circle {
    font-size: 15px;
  }
}

.login-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  color: @DS1-UTD-LINK-COLOR;
}

.dialog-label {
  .ds1-mb-2();
  .ds1-utd-weight-bold();
}

.loading-screen {
  min-height: 50vh;
}

@media only screen and (min-width: 768px) {
  .isDesktop .utd-form.login-view {
    .floating-labels-form {
      .ds1-mt-6();
    }
  }
}

// Media query added for max-height to reduce the margins.
// Subscribe section need to be visible on small resolution.
@media only screen and (max-height: 860px) {
  .isDesktop .utd-form {
    &.login-view .floating-labels-form {
      .ds1-mt-3();

      &.login-register-section {
        .ds1-mt-2();
      }
    }

    .form-links.institution-section {
      .ds1-pt-0();
    }

    .login-form-title {
      .ds1-pb-0();
    }
  }
}
</style>
