/* eslint-disable max-len */
/*
 * Originally the "machine learning" directive, this function initiates a system
 * which observes user behavior (specifically on topic pages) and periodically
 * reports sends topic usage reports to the server.
 *
 * The code is very old, and has been rewritten/ported more than once. Expect
 * quirky behavior / code style in places we haven't clean up yet. Some
 * commented out console statements have been left in, as debugging and testing
 * any other way is challenging.
 */
import { collectionToQueryParams } from '_acaSrc/utility/http';
import {
    elPrev,
    elNextUntil,
    getSelection,
    getDocument,
    getWindow
} from '_acaSrc/utility/DOM';
import { safeTimeout } from '_acaSrc/utility/timers';
// import Logger from '_acaSrc/utility/Logger';

export default function initMachineLearningModule(initialTopicId, vuexStore, onReadingSection) {
    const engagement = {
        // amount of vertical height a dom element must have revealed in order to be considered visible
        visibleThresholdPx: 100,

        // time of initialization. used to track event "age" (current time - start time) during persist step
        startTime: null,

        // used by click and highlight handlers to track where mouse
        // started and finished on a given click (holds element ids)
        // updated on every click, so is used to build clickStore
        clickTrack: [ -1, -1 ],

        // used by click and highlight handlers to track where mouse
        // started and finished on a given click (holds element ids)
        // only updated when selection actually changes, used for
        // events
        clickStore: [ -1, -1 ],

        scrollLoopTimeoutMS: 500, // loop frequency
        persistDataLoopTimeoutMS: 60000, // loop frequency
        eventStore: new Array(), // buffer of events, is flushed after persistence
        secElems: [], // cached list of DOM elements corresponding to sections of the topic
        lastScrollPos: -1, // used by scroll loop to track previous scroll position
        lastWindow: [ -1, -1 ], // used by scroll loop to track previous window dimensions
        copyTime: new Date(), // tracks time of previous copy. used for debounce in handleCopy function
        copyTimeoutMS: 1000, // used to debounce handleCopy function
        scrollSection: '', // caches previous sections (used by scroll loop to detect if new data needs to be logged)
        selectionText: null, // buffer for current selection. used for copy and highlight.
        lastSelection: '', // cache previous selection, used by handleClick
        searchTerm: null, // set only during initialization, string user submitted into utd search or "" if n/a
        topicId: null, // set only during init.
        topicLanguage: null, // set only during init.
        marginOffset: 0 // initialized in setup, used to account for margin for certain height calculations
    };

    const viewEle = {
        segments: null, // cached list of DOM elements corresponding to contents topic text and references
        sectionSelectorCheck: '.headingAnchor,.drugH1Div',
        sectionSelectorsArray: [],
        viewportHeight: 0, // updated every time scroll loop repeats
        viewportWidth: 0, // updated every time scroll loop repeats
        headerVisible: 0, // updated every time scroll loop repeats, visible header height in px
        activeTopic: null // set during setup, holds topic id based on url - so could be slug or numeric id.
    };

    const $transitions = vuexStore.getters['app/transitions'];
    const routeLoading = vuexStore.getters['app/routeLoading'];
    const machineLearningPaused = () => vuexStore.getters['topic/machineLearningPaused'];

    let destroyed = false;

    let fnOnBeforeTransition;
    let clearSelectionListeners = [];
    let cancelPersistLoopTimeout = () => { /* noop */ };
    let cancelScrollLoopTimeout = () => { /* noop */ };
    let cancelInitScrollLoopTimeout = () => { /* noop */ }; // Only used once, during setup.

    // Setup $transitions.onBefore handler to flush machine learning data,
    // when user goes to a different topic, or an entirely different state.
    if (vuexStore.getters['user/isCustomer'] && routeLoading !== 'topicPrint') {
        fnOnBeforeTransition = $transitions.onBefore({}, function(transition) {
            if (transition.from().name !== transition.to().name
                || ((transition.from().name === 'topic' || transition.from().name === 'topicLanguage')
                    && (transition.to().name === 'topic' || transition.to().name === 'topicLanguage')
                    && viewEle.activeTopic !== transition.params().topic)) {
                browserLeave();
            }
        });
    }

    // Determines how much space the header is taking up
    function calculateVisibleHeader() {
        if (!vuexStore.getters['feature/isHeaderRedesign']) {
            calculateVisibleHeaderLegacy();
            return;
        }
        _calculateVisibleHeader(viewEle);
    }

    function calculateVisibleHeaderLegacy() {
        let sSelector = '#utd-menu-content',
            iPageYOff = window.pageYOffset;

        if (vuexStore.getters['app/isFixedToolbar']) {
            sSelector = '.utd-menu-tier3';
            iPageYOff = 0;
        }

        const eleTopicToolbar = getDocument().getElementById('topic-toolbar'),
            eleNavContainer = getDocument().querySelector(sSelector);

        if (eleTopicToolbar && eleNavContainer) {
            viewEle.headerVisible = eleNavContainer.offsetHeight + eleTopicToolbar.offsetHeight - iPageYOff;
        }
    }

    function setup() {
        viewEle.activeTopic = initialTopicId;
        const topicContent = getDocument().getElementById('topicContent');
        const topicText = getDocument().getElementById('topicText');
        const hasSections = getDocument().querySelectorAll(viewEle.sectionSelectorCheck);
        if (hasSections && (topicContent || topicText)) {

            engagement.secElems = getDocument().querySelectorAll('#topicContent .headingAnchor, #topicText .headingAnchor, body>ol#reference, .drugH1Div');

            engagement.marginOffset = _getMarginSizeHelper(viewEle.sectionSelectorCheck);
            engagement.startTime = new Date();
            engagement.searchTerm = vuexStore.getters['search/searchParamsSearchText'];
            engagement.topicId = vuexStore.getters['topic/topicId'];
            engagement.topicLanguage = vuexStore.getters['topic/topicLanguage'];

            persistDataLoop();
            const hooks = setEvents();
            const { cancelTimeout } = safeTimeout(function() {
                saveScrollPositionLoop();
            }, 1000, { cancelHook: true });
            cancelInitScrollLoopTimeout = cancelTimeout;
            return hooks;
        }

        return { onDestroy: () => { /* noop */ } };
    }

    function setEvents() {
        if (vuexStore.getters['device/isBrowserTypeDesktop']) {
            // Clear text store when clicking outside a section
            clearSelectionListeners = getDocument().querySelectorAll('body > * > *');
            for (let i = 0; i < clearSelectionListeners.length; i++) {
                clearSelectionListeners[i].addEventListener('mouseup', clearSelectionText);
            }

            viewEle.segments = getDocument().querySelectorAll('#topicText > *, .topic-references');
            if (viewEle.segments && viewEle.segments.length) {
                for (let i = 0; i < viewEle.segments.length; i++) {
                    viewEle.segments[i].addEventListener('mousedown', handleMouseTouchStart);
                    viewEle.segments[i].addEventListener('mouseup', handleMouseTouchFinish);
                }
            }

            getDocument().getElementsByTagName('html')[0].addEventListener('copy', handleCopy);
        }

        window.onbeforeunload = function() {
            browserLeave();
        };

        return {
            onDestroy() {
                cleanupEvents();
                destroyed = true;
            }
        };
    }

    // Cancel timeouts and unregister listeners to prevent memory leaks.
    function cleanupEvents() {
        if (vuexStore.getters['device/isBrowserTypeDesktop']) {
            for (let i = 0; i < clearSelectionListeners.length; i++) {
                clearSelectionListeners[i].removeEventListener('mouseup', clearSelectionText);
            }
            clearSelectionListeners = [];

            if (viewEle.segments && viewEle.segments.length) {
                for (let i = 0; i < viewEle.segments.length; i++) {
                    viewEle.segments[i].removeEventListener('mousedown', handleMouseTouchStart);
                    viewEle.segments[i].removeEventListener('mouseup', handleMouseTouchFinish);
                }
            }
            getDocument().getElementsByTagName('html')[0].removeEventListener('copy', handleCopy);
        }

        cancelPersistLoopTimeout();
        cancelPersistLoopTimeout = undefined;
        cancelScrollLoopTimeout();
        cancelScrollLoopTimeout = undefined;
        cancelInitScrollLoopTimeout();
        cancelInitScrollLoopTimeout = undefined;
        window.onbeforeunload = undefined;
        fnOnBeforeTransition();
    }

    function clearSelectionText() {
        if (getSelection.toString() === '') {
            engagement.selectionText = null;
        }
    }

    function handleMouseTouchStart(e) {
        const targetEl = e.target;
        if (!targetEl.classList.contains('headingAnchor') || targetEl.classList.contains('drugH1Div')) {
            let prevEle = elPrev(e.target, [ 'headingAnchor', 'drugH1Div' ]);
            if (prevEle) {
                engagement.clickTrack[0] = prevEle.id;
            }
            else {
                prevEle = elPrev(e.target.parentElement, [ 'headingAnchor', 'drugH1Div' ]);
                if (prevEle) {
                    engagement.clickTrack[0] = prevEle.id;
                }
            }
        }
        else {
            engagement.clickTrack[0] = targetEl.id;
        }
    }

    function handleMouseTouchFinish(e) {
        const targetEl = e.target;
        if (!targetEl.classList.contains('headingAnchor') || targetEl.classList.contains('drugH1Div')) {
            let prevEle = elPrev(e.target, [ 'headingAnchor', 'drugH1Div' ]);
            if (prevEle) {
                engagement.clickTrack[1] = prevEle.id;
            }
            else {
                prevEle = elPrev(e.target.parentElement, [ 'headingAnchor', 'drugH1Div' ]);
                if (prevEle) {
                    engagement.clickTrack[1] = prevEle.id;
                }
            }
        }
        else {
            engagement.clickTrack[1] = targetEl.id;
        }
        if (handleClick()) {
            engagement.clickStore[0] = engagement.clickTrack[0];
            engagement.clickStore[1] = engagement.clickTrack[1];
            handleHighlight();
        }
    }

    // Stores topic usage, then calls itself after a delay, indefinitely.
    // eslint-disable-next-line complexity
    function persistDataLoop(isClosing) {
        // console.log(`offLoad() eventStore.length=[${engagement.eventStore.length}]`);
        if (engagement.eventStore.length > 0) {
            // send it off (only if there is a valid topic ID)
            const topicId = vuexStore.getters['topic/topicId'];
            if (typeof topicId !== 'undefined' && topicId !== '') {
                let outputString = '';
                const now = new Date().getTime();
                let event;

                for (let i = 0; i < engagement.eventStore.length; i++) {
                    event = engagement.eventStore[i];

                    // Ensuring that event and event["t"] are not null to prevent exceptions
                    if (!!event && event.t !== null && engagement.startTime !== null) {
                        // US20873: Recording new "y" parameter for accurate time
                        if (event.e === 'E') {
                            event.y = event.t.getTime() - engagement.startTime.getTime();
                        }
                        else {
                            event.y = now - event.t.getTime();
                        }

                        event.t = event.t.getTime() - now;

                        outputString += `${collectionToQueryParams(event)}\n`;
                    }
                }

                if (outputString !== '') {
                    // console.log('flush:');
                    // console.log(outputString);
                    vuexStore.dispatch('topic/logTopicUsage', {
                        topicId: engagement.topicId,
                        outputString,
                        searchTerm: engagement.searchTerm,
                        topicLanguage: engagement.topicLanguage,
                        isClosing
                    });
                    // console.log(`ML: ${engagement.topicId}, ${engagement.searchTerm}, ${engagement.topicLanguage}, ${isClosing}`);
                    // console.log(`ML: ${outputString}`);
                }
            }
            engagement.eventStore = [];
        }
        if (!(destroyed || isClosing)) {
            const { cancelTimeout } = safeTimeout(function() {
                persistDataLoop();
            }, engagement.persistDataLoopTimeoutMS, { cancelHook: true });
            cancelPersistLoopTimeout = cancelTimeout;
        }
    }

    function compileEvent(eventType, params) {
        // console.log(`compileEvent [${eventType}]`);
        // console.log(`params: ${JSON.stringify(params)}`);
        // console.log(`compileEvent [${eventType}]`);
        // console.log(`compileEvent ${JSON.stringify(params)}`);
        // event types:
        // C: user copied text
        // H: user selected/highlighted some text
        // E: user navigated away from page
        // S: user viewed a section (triggered by scrolling)
        params.t = new Date();
        params.e = eventType;
        engagement.eventStore.push(params);
    }

    function browserLeave() {
        // console.log("browserLeave()");
        compileEvent('E', {});
        // Flush data one final time
        persistDataLoop(true);
        return true;
    }

    // Returns true if we think the user can currently see the
    // element (visible and in view given current scroll position),
    // accounting for fixed header.
    // eslint-disable-next-line complexity
    function isElementInView(headingEl) {
        try {
            const rect = headingEl.getBoundingClientRect();

            // Try to calculate the full section height - not just
            // headingAnchor element - to improve accuracy and still
            // return at least 1 hit in extreme cases
            let greedyBottom = rect.top + rect.height + engagement.marginOffset;
            // Content els for sections are siblings with the heading, not children.
            const sectionSiblingEls = elNextUntil(headingEl, [ 'headingAnchor', 'drugH1Div' ]);
            for (let i = 0; i < sectionSiblingEls.length; i++) {
                const el = sectionSiblingEls[i];
                if ((el.innerHTML !== '') && !_isBrowserInjectedEl(el)) {
                    greedyBottom += sectionSiblingElHeight(el);
                }
            }

            const bInView = _isElFullyVisible(rect.top, greedyBottom, rect.left, rect.right, viewEle)
                || (_getElVisiblePixels(rect.top, greedyBottom, viewEle) >= engagement.visibleThresholdPx);

            // if (bInView) {
            //     console.log(`ele=[${headingEl.id}],inView=[${bInView}],t=[${rect.top}],hV=[${viewEle.headerVisible}],gB=[${greedyBottom}],Oy=[${_getElVisiblePixels(rect.top, greedyBottom, viewEle)}],mO=[${engagement.marginOffset}]`);
            // }

            return bInView;
        }
        catch (e) {
            // Logger.warn("Couldn't determine if isInView for machine learning" + e);
        }
        return false;
    }

    function sectionSiblingElHeight(el) {
        if (el.getBoundingClientRect) {
            return el.getBoundingClientRect().height + engagement.marginOffset;
        }
        return el.offsetHeight;
    }

    // Checks the scroll position periodically and accrues that data for topic usage statistics.
    // eslint-disable-next-line complexity
    function saveScrollPositionLoop() {
        const eTopArt = getDocument().getElementById('topicArticle');
        if (!eTopArt) {
            return;
        }

        // Do not save scroll position if paused. ML is paused during automatic scrolling so it does not
        // incorrectly record sections being scrolled over.
        if (!machineLearningPaused()) {
            const isDesktop = vuexStore.getters['device/isDesktopView'];
            const topScrollPos = isDesktop ? window.pageYOffset : (eTopArt.scrollTop || window.pageYOffset);

            calculateVisibleHeader();
            viewEle.viewportHeight = (window.innerHeight || getDocument().documentElement.clientHeight);
            viewEle.viewportWidth = (window.innerWidth || getDocument().documentElement.clientWidth);

            const isNewScrollPos = engagement.lastScrollPos !== topScrollPos;
            const curWindow = [ window.innerHeight, window.innerWidth ];
            const winHeightChanged = engagement.lastWindow[0] !== curWindow[0];
            const winWidthChanged = engagement.lastWindow[1] !== curWindow[1];

            let section = '';
            if (isNewScrollPos || winHeightChanged || winWidthChanged) {
                for (let e = 0; e < engagement.secElems.length; e++) {
                    if (isElementInView(engagement.secElems[e])) {
                        section += `${engagement.secElems[e].id},`;
                    }
                }
                if (section.substring(section.length - 1) === ',') {
                    section = section.substring(0, section.length - 1);
                }
                if (section !== '' && engagement.scrollSection !== section) {
                    const params = { s: section };
                    compileEvent('S', params);
                    onReadingSection(section);
                }
                engagement.lastScrollPos = topScrollPos;
                engagement.lastWindow = curWindow;
                engagement.scrollSection = section;
            }
        }

        if (!destroyed) {
            const { cancelTimeout } = safeTimeout(function() {
                saveScrollPositionLoop();
            }, engagement.scrollLoopTimeoutMS, { cancelHook: true });
            cancelScrollLoopTimeout = cancelTimeout;
        }
    }

    // Given two element ids corresponding to potential section
    // elements, generates a serialized string of comma-separated
    // ids if either are truly ids for sections. E.g.
    // 1,2 if both ids correspond to section ids.
    function checkAndSerializeSectionIds(firstSectionId, secondSectionId) {
        // console.log(`getSections: s1=[${firstSectionId}] s2=[${secondSectionId}]`);
        // eslint-disable-next-line eqeqeq
        if (firstSectionId == secondSectionId) {
            return firstSectionId;
        }
        let sections = '';
        let foundStart = false;
        const eleSections = getDocument().querySelectorAll(viewEle.sectionSelectorCheck);
        for (let e = 0; e < eleSections.length; e++) {
            const sectionId = eleSections[e].id;
            if (sectionId === firstSectionId || sectionId === secondSectionId) {
                sections += `${sectionId},`;
                foundStart = !foundStart;
            }
            else if (foundStart) {
                sections += `${sectionId},`;
            }
        }
        if (sections.substring(sections.length - 1) === ',') {
            sections = sections.substring(0, sections.length - 1);
        }
        return sections;
    }

    function handleClick() {
        const selection = getSelection();
        engagement.selectionText = selection && selection.toString();
        if (engagement.lastSelection === engagement.selectionText) {
            return false;
        }
        engagement.lastSelection = engagement.selectionText;
        return true;

    }

    function handleHighlight() {
        // console.log(`handleHighlight ['${engagement.selectionText}',${engagement.selectionText.length}]`);
        // eslint-disable-next-line eqeqeq
        if (engagement.selectionText != null && engagement.selectionText !== '') {
            const params = {
                s: checkAndSerializeSectionIds(engagement.clickStore[0], engagement.clickStore[1]),
                x: engagement.selectionText.substring(0, 20),
                n: engagement.selectionText.length
            };
            compileEvent('H', params);
        }
    }

    function handleCopy() {
        // console.log(`handleCopy ['${engagement.selectionText}',${engagement.selectionText ? engagement.selectionText.length : 'OOPS'}]`);

        if (engagement.selectionText && engagement.selectionText.length) {
            if (new Date() - engagement.copyTime < engagement.copyTimeoutMS) {
                engagement.copyTime = new Date(); // can't hold down ctrl+c
                return;
            }
            engagement.copyTime = new Date();
            const sections = checkAndSerializeSectionIds(engagement.clickStore[0], engagement.clickStore[1]);

            const params = { s: sections,
                x: engagement.selectionText.substring(0, 20),
                n: engagement.selectionText.length };
            compileEvent('C', params);
        }
    }

    if (vuexStore.getters['user/isCustomer'] && routeLoading !== 'topicPrint') {
        return safeTimeout(function() {
            const hooks = setup();
            return hooks;
        }, 1);
    }
    return Promise.resolve('Did not start machine learning directive');
}

/* Private methods below, exported for testing. This is unconventional, but this directive is very
   hard to test cohesively.
*/

export function _calculateVisibleHeader(viewEle) {
    const toolbar = getDocument().getElementById('topic-toolbar');
    viewEle.headerVisible = (toolbar && toolbar.getBoundingClientRect().bottom) || 0;
}

// 'recog_div' and 'reader_div' are elements injected into the
// DOM by the Samsung Galaxy S3 Android browser to initialize
// it's reader. If these tests are not present then the screen
// will blank out for certain types of drug topics.
export function _isBrowserInjectedEl(el = {}) {
    return (el.id === 'recog_div') || (el.id === 'reader_div');
}

export function _isElFullyVisible(top, bottom, left, right, viewEle) {
    return top >= viewEle.headerVisible
        && left >= 0
        && bottom <= viewEle.viewportHeight
        && right <= viewEle.viewportWidth;
}

export function _getElVisiblePixels(top, bottom, viewEle) {
    return Math.min(bottom, viewEle.viewportHeight)
         - Math.max(top, viewEle.headerVisible);
}

export function _getMarginSizeHelper(selectors, theDocument = getDocument(), theWindow = getWindow()) {
    let margin = 0;
    const ele = theDocument.querySelector(selectors);
    if (ele) {
        const val = theWindow.getComputedStyle(ele)['margin-top'];
        if (typeof val !== 'undefined') {
            margin = Math.round(parseFloat(val));
        }
    }
    return margin;
}
