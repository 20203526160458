export const findLast = (arr, pred) => {
    return arr[findLastIndex(arr, pred)];
};

export const findLastIndex = (arr, pred) => {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (pred(arr[i])) {
            return i;
        }
    }
    return -1;
};

export const limitedData = (input, limit) => {
    if (!input) {
        return [];
    }
    if (!limit && limit !== 0) {
        return input;
    }
    return input.slice(0, limit);
};
