<template>
  <div class="topic-toolbar-breadcrumbs">
    <utd-bread-crumbs
      :bread-crumbs="breadCrumbs"
      :hide-middle-items="collapseBreadCrumbs"
      is-label-only
      @on-dots-clicked="collapseBreadCrumbs = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getHashFromElementParent, getUrlFromElement } from '_acaSrc/utility/contents/topic/topic';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import UtdBreadCrumbs from '_acaSrc/components/shared/stdlib/UtdBreadCrumbs.vue';
import { C_LANGUAGES_NAMES, C_TOPIC } from '_acaSrc/utility/constants';
import { dispatchEvent } from '_acaSrc/utility/Events';
import { SET_NEAREST_OUTLINE_HEADING_ID } from '_acaSrc/store/topic/topic.store';

const BREADCRUMBS_THRESHOLD = 230;

export default {
    components: {
        UtdBreadCrumbs
    },
    data() {
        return {
            collapseBreadCrumbs: true,
            articleScrollListener: null,
            windowScrollListener: null,
            topicOffsetTop: 0,
            breadCrumbsMarkers: [],
            breadCrumbs: []
        };
    },
    computed: {
        ...mapGetters('app', [ 'isProspectMode' ]),
        ...mapGetters('topic', [
            'topicIsScrolling',
            'topicScroller',
            'isTitleCollapsed',
            'isTopicShowing'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        topicArticleEl() {
            return getDocument().querySelector('#topicArticle');
        },
        outelineArticleEl() {
            return getDocument().querySelector('#outlineContent');
        },
        outelineHeight() {
            return this.outelineArticleEl.offsetHeight;
        },
        isRTL() {
            return this.topicLanguage === C_LANGUAGES_NAMES.AR.CODE;
        },
        isTopicVisible() {
            return this.isTopicShowing;
        }
    },
    watch: {
        isTitleCollapsed(newval) {
            this.collapseBreadCrumbs = newval;
        },
        breadCrumbs(newval) {
            this.collapseBreadCrumbs = this.isTitleCollapsed && newval && newval.length > 2;
        },
        isTopicVisible(newval) {
            if (newval && !this.isDesktopView && !this.isProspectMode) {
                this.setBreadCrumbsElements();
            }
        }
    },
    beforeUnmount() {
        this.clearScrollListener(this.articleScrollListener);
        this.clearScrollListener(this.windowScrollListener);
    },
    created() {
        this.collapsed = this.breadCrumbs.length > 2;
    },
    mounted() {
        this.setup();
    },
    methods: {
        ...mapActions('topic', [ 'whenTopicElementsStable' ]),
        ...mapMutations('topic', [ 'SET_NEAREST_OUTLINE_HEADING_ID' ]),
        async setup() {
            if (!this.topicArticleEl) {
                return;
            }
            this.windowScrollListener
                = this.setScrollListener(getWindow(), this.trackScrollPosition);
            this.articleScrollListener
                    = this.setScrollListener(this.topicArticleEl, this.trackScrollPosition);

            await this.whenTopicElementsStable();
            if (!this.isDesktopView && !this.isProspectMode && this.isTopicVisible) {
                this.setBreadCrumbsElements();
            }
        },
        trackScrollPosition() {
            this.topicOffsetTop
              = this.topicScroller.scrollTop || getDocument().documentElement.scrollTop;
            this.setCurrentBreadCrumbs();
        },
        setBreadCrumbsElements() {
            if (!this.topicArticleEl) {
                return;
            }

            const domElements = this.topicArticleEl.querySelectorAll('#topicContent [class*=xhash_]');
            this.breadCrumbsMarkers = Array.from(domElements).map(e => ({
                id: getHashFromElementParent(e),
                position: e.offsetTop,
                hierarchy: parseInt(e.classList[0][1]),
                text: e.textContent,
                url: getUrlFromElement(e)
            }));

            this.breadCrumbsMarkers.forEach((marker, i) => {
                marker.breadCrumbs
                  = this.createBreadCrumbs(i).map(({ text, url }) => ({ text, url }));
            });
        },
        createBreadCrumbs(index) {
            const currentBreadCrumb = this.breadCrumbsMarkers[index];
            let lastAddedBreadCrumb = currentBreadCrumb;
            const newBreadcrumbs = [ currentBreadCrumb ];

            if (index === 0 || currentBreadCrumb.hierarchy === 1) {
                return newBreadcrumbs;
            }

            for (let i = index; this.breadCrumbsMarkers[i].hierarchy >= 1; i--) {
                const breadCrumb = this.breadCrumbsMarkers[i];
                if (breadCrumb.hierarchy < currentBreadCrumb.hierarchy
                    && breadCrumb.hierarchy < lastAddedBreadCrumb.hierarchy) {
                    newBreadcrumbs.unshift(breadCrumb);
                    lastAddedBreadCrumb = breadCrumb;
                    if (breadCrumb.hierarchy === 1) {
                        break;
                    }
                }
            }
            return this.reverseIfRTL(newBreadcrumbs);
        },
        setCurrentBreadCrumbs() {
            if (this.topicIsScrolling) {
                return;
            }

            if (this.topicOffsetTop === 0) {
                this.breadCrumbs = [];
                dispatchEvent('resize');
                return;
            }
            const currentPosition = this.topicOffsetTop + BREADCRUMBS_THRESHOLD;

            const nearestbreadCrumb = this.breadCrumbsMarkers.find((x, i) => {
                const nextMaker = this.breadCrumbsMarkers[i + 1];
                return currentPosition > x.position
                      && currentPosition < ((nextMaker && nextMaker.position) || 0);
            });

            if (nearestbreadCrumb) {
                this.breadCrumbs = nearestbreadCrumb.breadCrumbs;
                const nearestHeadingId = nearestbreadCrumb.id;
                const outlineHeadingId = `${C_TOPIC.ANCHOR_HASH_PREFIX}${nearestHeadingId}`;
                this[SET_NEAREST_OUTLINE_HEADING_ID](outlineHeadingId);
            }
        },
        reverseIfRTL(breadcrumbs) {
            return this.isRTL ? breadcrumbs.reverse() : breadcrumbs;
        }
    }

};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@media only screen and (min-width: 912px) {
  .topic-toolbar-breadcrumbs {
    display: none;
  }
}

.topic-toolbar-breadcrumbs {
  .utd-rtl & {
    .utd-bread-crumb-container {
      .ds1-utd-text-right();
    }
  }
}
</style>
