<template>
  <ul class="wk-navbar-dropdown-container wk-navbar-dropdown
   wk-navbar-dropdown-classic utd-navbar-dropdown"
      role="menu"
      aria-label="contents">
    <li class="wk-navbar-dropdown-column">
      <dl class="wk-dropdown-column-list">
        <dd v-for="(item, idx) in visibleTocRootItems"
            :key="idx">
          <a :id="item.navClass"
             :class="item.navClass"
             role="menuitem"
             tabindex="0"
             :href="buildTocUrl(item)"
             :target="item.newTab ? '_blank' : undefined"
             @click="dismissMenu">
            {{ $t(item.key) }}
          </a>
        </dd>
      </dl>
    </li>
  </ul>

</template>

<script>
import { mapGetters } from 'vuex';
import { C_PATHS } from '_acaSrc/utility/constants';
import { setQueryParamValue } from '_acaSrc/utility/http';

const RENDERING_TIMEOUT = 100;

export default {
    emits: [ 'dismiss-contents-menu' ],
    data() {
        return {
            tocPrefix: C_PATHS.TOC_PREFIX
        };
    },
    computed: {
        ...mapGetters('user', [ 'language' ]),
        ...mapGetters('toc', [
            'tocRootItems'
        ]),
        visibleTocRootItems() {
            return this.tocRootItems.filter(item => {
                return item.display !== false && (!item.featureCheck || (item.featureCheck()));
            });
        }
    },
    methods: {
        buildTocUrl(item) {
            const contentsPrefix = item.hasContentsPrefix ? '/contents/' : '';

            if (item.notLocalized) {
                return contentsPrefix + item.url;
            }

            const prefixedItemUrl = `${item.hasTocPrefix ? this.tocPrefix : ''}${item.url}`;
            const localizedUrl = this.localizeAnchor(contentsPrefix, prefixedItemUrl, this.language);

            if (item.source) {
                return setQueryParamValue(localizedUrl, 'source', item.source);
            }

            return localizedUrl;
        },
        dismissMenu() {
            setTimeout(() => {
                this.$emit('dismiss-contents-menu');
            }, RENDERING_TIMEOUT);
        }
    }
};
</script>
