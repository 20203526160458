import mitt from 'mitt';

let _pubSubInstance = null;
export default class PubSub {
    constructor() {
        if (_pubSubInstance) {
            return _pubSubInstance;
        }

        this._events = mitt();
        _pubSubInstance = this;
    }

    publish(event, data) {
        this._events.emit(event, data);
    }

    subscribe(event, handlerFn) {
        this._events.on(event, handlerFn);
    }

    unsubscribe(event, handlerFn) {
        this._events.off(event, handlerFn);
    }
}
