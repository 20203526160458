<template>
  <div id="outlineToggle"
       class="collapse-sideways-arrow"
       tabindex="0"
       role="button"
       :title="tooltipText"
       :class="{'expand-sideways-arrow': !isOutlineVisible}"
       :aria-label="tooltipText"
       @click="toggleOutline()"
       @keypress="$event.which === ENTER_KEY && toggleOutline()" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_FULL_TOPIC_WIDTH, SET_TOPIC_OUTLINE_VISIBLE } from '_acaSrc/store/topic/topic.store';
import { C_KEYCODES } from '_acaSrc/utility/constants';
import { dispatchEvent } from '_acaSrc/utility/Events';

export default {
    data() {
        return {
            tooltipText: '',
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    computed: {
        ...mapGetters('topic', [ 'isOutlineVisible' ])
    },
    mounted() {
        this.tooltipText = this.setTooltipText();
    },
    methods: {
        ...mapActions('topic', [ 'scrollToActiveHash' ]),
        ...mapMutations('topic', [ SET_TOPIC_OUTLINE_VISIBLE, SET_FULL_TOPIC_WIDTH ]),
        toggleOutline() {
            this[SET_TOPIC_OUTLINE_VISIBLE](!this.isOutlineVisible);
            this[SET_FULL_TOPIC_WIDTH](!this.isOutlineVisible);
            this.tooltipText = this.setTooltipText();
            // [CORE-4743] When toggling topic outline, trigger a resize
            // event to ensure formula scaling gets applied correctly.
            dispatchEvent('resize');

            // Attempt to synch article back up with new hash position
            // if user has not scrolled since hash link click.
            setTimeout(() => this.scrollToActiveHash(), 1);
        },
        setTooltipText() {
            return this.isOutlineVisible
                ? this.$t('SEARCH.HIDE_TOPIC_OUTLINE')
                : this.$t('SEARCH.SHOW_TOPIC_OUTLINE');
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.outline-toggle-left (@width) {
  .isDesktop #topicContainer #outlineToggle {
    left: @width;
  }
}

#outlineToggle {
  display: none;
}

@media only screen and (min-width: 801px) and (orientation: landscape) {
  #topicContainer {
    #outlineToggle {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .outline-toggle-left (236px);

  .isDesktop {
    #topicContainer {
      #outlineToggle {
        top: 153px;
        display: block;
        position: absolute;
        border: 0;
        right: 70%;
        margin-right: 22px;
        z-index: 3;
        font-size: 11px;
        padding: 0;
        cursor: pointer;
        width: 22px;
        height: 26px;

        &:hover {
          opacity: 0.7;
        }

        .wkProspect & {
          position: fixed;
          top: 270px;
        }
      }

      &.outline-hidden {
        #outlineToggle {
          left: 0;
          border: 1px solid #e3e3e3;
          top: 145px;

          .wkProspect &,
          .wkProspect .fixedToolbar& {
            top: 270px;
          }
        }
      }
    }

    &.utdWkHeader.topicView {
      &.fixedToolbar {
        #topicContainer {
          #outlineToggle {
            position: fixed;
            top: 107px;
          }
        }
      }
    }

    &.fixedToolbar {
      #topicContainer {
        #outlineToggle {
          position: fixed;
          top: 76px;

          .wkProspect & {
            top: 270px;
          }
        }

        &.outline-hidden {
          #outlineToggle {
            background-position: 3px 50%;
            top: 67px;

            .utdWkHeader.utdWkTopic& {
              height: 26px;
              border: none;
            }
          }
        }
      }
    }

    .wkProspect & .bannerClosed {
      &.bannerClosing {
        #utd-main {
          #topicContainer {
            #outlineToggle {
              .css-animate(@keyName:toggleUp, @timeSec:0.5s);
            }
          }
        }
      }

      #utd-main {
        #topicContainer {
          #outlineToggle {
            top: 151px;
          }

          &.outline-hidden {
            #outlineToggle {
              top: 151px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .outline-toggle-left(276px);
}

@media only screen and (min-width: 1200px) {
  .outline-toggle-left(316px);
}
</style>
