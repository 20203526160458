<!-- eslint-disable vue/no-v-html -->
<template>
  <utd-modal-dialog ref="modalDialog"
                    :close-on-overlay-click="false"
                    :close-on-escape="false"
                    :modal-classes="'anki-download-modal'"
                    :dialog-size="'medium'">
    <template #header>{{ $t('ANKI.TITLE') }}</template>
    <utd-notification v-if="isDownloadError"
                      id="download-error-notification"
                      :notification-mode="'error'">
      <p class="notification-text">There was a problem downloading the UpToDate for Anki Add-On.</p>
      <p class="notification-text">Please try again later or contact us using
        <a v-utd-new-tab="true"
           href="https://www.wolterskluwer.com/en/solutions/uptodate/contact-us"
           class="anki-bolded">
          UpToDate Support.
        </a>
      </p>
    </utd-notification>
    <div class="anki-injected-html"
         @click="handleClickEventForInjectedHtml"
         v-html="ankiDownloadTemplate" />
    <template #footer>
      <utd-button @click="closeModal()">Close</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';
import { getWindow } from '_acaSrc/utility/DOM';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import UtdNotification from '../utd/UtdNotification.vue';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdModalDialog,
        UtdButton,
        UtdNotification
    },
    data() {
        return {
            isDownloadError: false
        };
    },
    computed: {
        ...mapGetters('app', [ 'ankiDownloadTemplate' ])
    },
    methods: {
        openModal() {
            this.isDownloadError = false;
            this.$refs.modalDialog.open();
        },
        closeModal() {
            this.$refs.modalDialog.close();
        },
        handleClickEventForInjectedHtml(e) {
            const downloadButtonClicked
                = e.target.matches('.anki__download-button-container > button');
            if (downloadButtonClicked) {
                let eventParams;
                return utdRest('anki/download')
                    .then(data => {
                        this.isDownloadError = false;
                        const url = data.data || data;
                        getWindow().location.assign(url);
                        eventParams = {
                            uiElementName: 'ankiDownloadModalDialog_downloadButton'
                        };
                    })
                    .catch(e => {
                        this.isDownloadError = true;
                        let error = `ankiDownload:${e._status}`;
                        const request = e._request;
                        if (request !== undefined) {
                            error += `-${request.method}-${request.url}`;
                        }
                        eventParams = {
                            uiElementName: 'ankiDownloadModalDialog_downloadButton_failure',
                            optData: error
                        };
                    })
                    .finally(() => {
                        new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, eventParams);
                    });
            }
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-PROFILE-ICON-COLOR: @WKCE-BLUE;

.anki-download-modal {
  .utd-modal-content {
    .utd-dialog-frame {
      min-width: 320px;
      max-width: 720px;

      .utd-dialog-content {
        .ds1-pa-2();
      }
    }
  }
}

.anki-bolded {
  .ds1-utd-weight-600();
}

.anki__subheading-container {
  .ds1-mh-0();
  .ds1-mv-2();
  .ds1-pb-2();
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
}

.anki__install-subheading,
.anki__version-info {
  .ds1-reset-p();
}

.anki__external-link:focus {
  outline: 1px solid @DS1-PDS-INPUT-HOVER-BORDER-COLOR;
}

.anki__download-button-container {
  .ds1-mt-2();
  display: flex;
  justify-content: center;

  .anki__download-exe-button,
  .anki__download-exe-icon {
    .ds1-pv-1();
    .ds1-ph-2();
    appearance: button;
    background-color: @DS1-UTD-PRIMARY-LINK-BASE-COLOR;
    color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    border-color: transparent;
    border-right: 1px solid @DS1-UTD-PRIMARY-BG-COLOR;
    border-top: @DS1-UTD-PRIMARY-BG-COLOR;
    border-bottom: @DS1-UTD-PRIMARY-BG-COLOR;

    &:hover {
      text-decoration: none;
      color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
      background-color: @DS1-UTD-JS2-LINK-HOVER;
    }
  }
}

.anki__download-exe-icon::after {
  font-family: 'wkce-icons';
  content: '\eac6';
}

.anki__instructions-list {
  .ds1-pa-0();
  .ds1-ma-3();
}

.anki__line-one,
.anki__line-two,
.anki__line-three {
  .ds1-reset-p();
}

.anki__step-one,
.anki__step-two,
.anki__step-three,
.anki__step-four {
  .ds1-mb-3();
}

#download-error-notification {
  .ds1-mv-2();

  .notification-text {
    .ds1-reset-p();
  }
}
</style>