import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { C_I18N } from '_acaSrc/utility/constants';
import i18next from 'i18next';

const { UTD_NAMESPACE } = C_I18N;

const translationLoaderUtils = {
    requestsInFlight: []
};

export default translationLoaderUtils;

export const translationsLoader = async langCode => {
    try {
        if (!langCode || translationLoaderUtils.requestsInFlight[langCode]) {
            // Either null was passed for langCode, or request for
            // translation file already in progress, ignore duplicate requests.
            return;
        }

        // Return previously retrieved request's translation file if we have it already.
        const langBundle = i18next.getResourceBundle(langCode);
        if (langBundle) {
            return langBundle;
        }

        // Set flag to prevent multiple duplicate requests for translation files
        translationLoaderUtils.requestsInFlight[langCode] = true;
        const result = await utdRest('localization/file', langCode);

        if (!result || !result.data) {
            delete translationLoaderUtils.requestsInFlight[langCode];
            throw new Error('Invalid object returned from request');
        }

        const translations = JSON.parse(result.data);
        i18next.addResourceBundle(langCode, UTD_NAMESPACE, translations);
        delete translationLoaderUtils.requestsInFlight[langCode];
        return translations;
    }
    catch (err) {
        delete translationLoaderUtils.requestsInFlight[langCode];
        throw err;
    }
};