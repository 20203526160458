<template>
  <topic-view :key="componentKey" />
</template>

<script>
import TopicView from '_acaSrc/components/contents/topic/TopicView.vue';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';

export default {
    components: {
        TopicView
    },
    data() {
        return {
            componentKey: 0
        };
    },
    mounted() {
        new PubSub().subscribe(C_EVENTS.REMOUNT_TOPIC_VIEW, this.remountTopicView);
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.REMOUNT_TOPIC_VIEW, this.remountTopicView);
    },
    methods: {
        // This wrapper provides a manual trigger to remount the TopicView component
        // which was originally not possible from from the utd-app-route. This gives flexibility
        // if we want to initiate the TopicView mounted/destroy methods without having
        // to trigger unnecessary router methods that would cause a full page reload.
        // https://stackoverflow.com/questions/47459837/how-to-re-mount-a-component-in-vuejs
        remountTopicView() {
            this.componentKey++;
        }
    }
};
</script>