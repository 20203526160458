<template>
  <utd-modal-dialog ref="whatsNewDialog"
                    :modal-classes="'topicChanged'"
                    :dialog-type="'warn'"
                    :dialog-size="'large'"
                    :close-on-overlay-click="false"
                    @closed="closed">
    <template #header>{{ title }}</template>
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="content" />
  </utd-modal-dialog>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import { moveSameArticleClickToTop } from '_acaSrc/utility/contents/topic/topic';
import { ScrollTarget } from '_acaSrc/utility/contents/topic/ScrollTarget';
import { C_UI, C_TOPIC } from '_acaSrc/utility/constants';

const RENDER_TIMEOUT = 100;

export default {
    components: {
        UtdModalDialog
    },
    computed: {
        ...mapGetters('topic', [ 'topicWhatsNewDialogData' ]),
        title() {
            return this.topicWhatsNewDialogData.title;
        },
        content() {
            return this.topicWhatsNewDialogData.content;
        }
    },
    mounted() {
        this.subscribe({ eventName: 'WHATS_NEW_DIALOG_OPEN', handlerFn: this.open });
    },
    beforeUnmount() {
        this.unsubscribe({ eventName: 'WHATS_NEW_DIALOG_OPEN', handlerFn: this.open });
        this.$el.removeEventListener('click', this.bindModalClickEvent);
    },
    methods: {
        ...mapActions('app', [
            'subscribe',
            'unsubscribe'
        ]),
        ...mapActions('topic', [
            'scrollToActiveHash',
            'processScrollRequest',
            'logWhatsNewSectionViews'
        ]),
        open() {
            this.$refs.whatsNewDialog.open();
            setTimeout(() => {
                this.$nextTick(() => {
                    const elem = document.querySelector('.topicChanged .utd-modal-content');
                    elem && elem.addEventListener('click', this.bindModalClickEvent);
                });
            }, RENDER_TIMEOUT);
        },
        bindModalClickEvent(evt) {
            if (!evt.target) {
                return;
            }

            if (evt.target.matches('.utdArticleSection a.local')) {
                this.logWhatsNewSectionViews({ evt });
            }

            if (evt.target.matches('.utdArticleSection a')) {
                this.$refs.whatsNewDialog.close();
                moveSameArticleClickToTop('.topicChanged a', this.smoothScrollToArticleTop);
            }
        },
        smoothScrollToArticleTop() {
            const target = new ScrollTarget({
                position: C_TOPIC.SCROLL_TOP_POSITION,
                fromClick: true
            });
            this.processScrollRequest(target);
        },
        closed() {
            setTimeout(this.scrollToActiveHash, C_UI.MODAL_CLOSING_DELAY_MS);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.topicChanged {
  .utd-modal-content {
    .utd-dialog-frame {
      &.no-footer {
        max-height: calc(90vh - 80px);
      }
    }

    .utdArticleSection {
      p {
        .ds1-utd-size-3();
      }

      .headingAnchor {
        .ds1-p-2();
      }

      .collapseContent {
        p {
          margin: 0;
          .ds1-mb-2();

          &:last-child {
            margin-bottom: 0;
          }
        }

        .bulletIndent1 {
          margin-left: 2em;
        }

        .bulletIndent2 {
          margin-left: 4em;
        }
      }
    }

    .utd-dialog-header {
      .ds1-pv-2();
      .ds1-pl-2();
      .ds1-pr-4();
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: auto;
      border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;

      .wkce-icon-filled-caution {
        display: none;
      }

      .wkce-icon-filled-close {
        top: 23px;
        right: 18px;
      }
    }

    .utd-dialog-content {
      .ds1-ph-2();
      padding-bottom: 0;

      .utdArticleSection {
        .ds1-mh-0();
        margin-bottom: 0;
      }
    }

    @media only screen and (min-width: 768px) {
      .utd-dialog-content,
      .utd-dialog-header {
        .ds1-ph-4();
      }
    }
  }
}
</style>
