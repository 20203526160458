<script>
import { mapGetters, mapActions } from 'vuex';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';

const MS_WAIT_FOR_FOCUS = 500;

export default {
    data() {
        return {
            scrollPos: null,
            toFocusCheck: null,
            inputEditMode: null,
            windowScrollListener: null
        };
    },
    computed: {
        ...mapGetters('device', [ 'isBrowserTypeDesktop' ]),
        ...mapGetters('feature', [ 'isHeaderRedesign' ])
    },
    beforeUnmount() {
        this.clearListeners();
    },
    mounted() {
        this.initialize();
    },
    methods: {
        ...mapActions('app', [ 'confirmConfigLoaded' ]),
        async initialize() {
            await this.confirmConfigLoaded();
            if (!this.isBrowserTypeDesktop
            && this.isHeaderRedesign) {
                this.calculatorEl = getDocument().querySelector('#topicContentCalculator');
                if (this.calculatorEl !== null) {
                    this.initTracker();
                }
            }
        },
        initTracker() {
            this.scrollPos = getWindow().pageYOffset;

            this.setupListeners();
        },
        setupListeners() {
            this.windowScrollListener = this.setScrollListener(getWindow(), this.onScroll);

            this.eInputs = this.calculatorEl.querySelectorAll(
                'select, input[type=\'text\'], input[type=\'number\']'
            );

            this.eInputs.forEach(inputEl => {
                inputEl.addEventListener('focus', this.focusInputs);
                inputEl.addEventListener('blur', this.blurInputs);
            });
        },
        clearListeners() {
            this.clearScrollListener(this.windowScrollListener);

            this.eInputs && this.eInputs.length && this.eInputs.forEach(inputEl => {
                inputEl.removeEventListener('focus', this.focusInputs);
                inputEl.removeEventListener('blur', this.blurInputs);
            });
        },
        onScroll() {
            if (!this.inputEditMode) {
                this.scrollPos = getWindow().pageYOffset;
            }
        },
        blurInputs() {
            this.toFocusCheck = setTimeout(() => {
                this.inputEditMode = false;
                getWindow().scrollTo(0, this.scrollPos);
            }, MS_WAIT_FOR_FOCUS);
        },
        focusInputs() {
            if (this.toFocusCheck) {
                clearTimeout(this.toFocusCheck);
            }
            this.inputEditMode = true;
        }
    },
    render: () => null
};

</script>