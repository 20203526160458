import { getUrlHash } from '_acaSrc/utility/http';
import { isClickEvent, eventHrefAttribute } from '_acaSrc/utility/Events';
import Queue from '_acaSrc/utility/Collections/Queue';

export class ScrollTargets extends Queue {
    purgeUntilLastTarget() {
        while (this.length > 1) {
            this.dequeue();
        }
    }
}

export class ScrollTarget {
    constructor(props) {
        props = props || {};
        const {
            event = null,
            hash = false,
            element = false,
            position = false,
            replaceUrl = false,
            fromClick,
            useSmoothScroll = true,
            fromTarget = null
        } = props;

        if (fromTarget) {
            this._initFromTarget(fromTarget);
            return;
        }

        this._hash = hash;
        this._element = element;
        this._position = position;
        this._fromClick = fromClick || isClickEvent(event);
        this._replaceUrl = replaceUrl;
        this._targetUrl = eventHrefAttribute(event);
        this._queued = false;
        this._useSmoothScroll = useSmoothScroll;

        this._initTargetHash();
    }

    _initTargetHash() {
        // If no target property specified, then attempt to
        // determine 'hash' from event target hash, or from
        // hash on the current URL.
        if (!this._hash && !this._element && !this._position) {
            if (this._targetUrl) {
                this._hash = this._targetUrl && getUrlHash(this._targetUrl);
            }
            else {
                const urlHash = getUrlHash();
                if (urlHash) {
                    this._hash = urlHash;
                }
            }
        }
    }

    _initFromTarget(target) {
        this._hash = target.hash;
        this._element = target.element;
        this._position = target.position;
        this._fromClick = target.fromClick;
        this._replaceUrl = target.replaceUrl;
        this._targetUrl = target.targetUrl;
        this._queued = target.queued;
        this._useSmoothScroll = target.useSmoothScroll;
    }

    get hash() {
        return this._hash;
    }

    get element() {
        return this._element;
    }

    get position() {
        return this._position;
    }

    get fromClick() {
        return this._fromClick;
    }

    get replaceUrl() {
        return this._replaceUrl;
    }

    get targetUrl() {
        return this._targetUrl;
    }

    get queued() {
        return this._queued;
    }

    get useSmoothScroll() {
        return this._useSmoothScroll;
    }

    set queued(_queued) {
        this._queued = _queued;
    }

    set useSmoothScroll(value) {
        this._useSmoothScroll = value;
    }

    setReplaceUrlFromTarget() {
        this._replaceUrl = this._targetUrl;
    }

    // eslint-disable-next-line complexity
    isEqual(target) {
        return target
            && this._hash === target.hash
            && this._element === target.element
            && this._position === target.position
            && this._replaceUrl === target.replaceUrl
            && this._fromClick === target.fromClick
            && this._targetUrl === target.targetUrl
            && this._queued === target.queued;
    }

    isTargetMatch(target) {
        return target
          && ((target.hash && this._hash === target.hash)
            || (target.element && this._element === target.element)
            || (target.position && this._position === target.position));
    }

    // Please do not remove, useful for debugging scroll pipeline
    // debug(logLine) {
    //   console.log(`${logLine} => scrollTarget={
    // > hash: [${this._hash}]
    // > element: [${this._element}]
    // > position: [${this._position}]
    // > fromClick: [${this._fromClick}]
    // > replaceUrl: [${this._replaceUrl}]
    // > targetUrl: [${this._targetUrl}]
    // > queued: [${this._queued}]
    // > useSmoothScroll: [${this._useSmoothScroll}]`);
    // }
}