/* eslint-disable max-len */
/* eslint-disable complexity */
import { JL } from 'jsnlog';
import Logger from '_acaSrc/utility/Logger';
import { C_HTTP, C_HTTP_STATUS, C_EVENTS } from '_acaSrc/utility/constants';
import IndexManager from '_acaSrc/utility/IndexManager';
import {
    getWindow,
    setSearchEngineUrl,
    setAnalyticsScriptWithGuid
} from '_acaSrc/utility/DOM';
import {
    isStatusNotAnError,
    isStatusRequiresLogin,
    isStatusServerError,
    urlContains
} from '_acaSrc/utility/http';
import {
    SET_UCC_STATE,
    SET_PRODUCT_NAME,
    SET_PRODUCT_VERSIONS,
    SET_COUNTRY_CODE
} from '_acaSrc/store/app.store';
import {
    SET_CME_LABEL,
    SET_CME_TICKER,
    SET_IS_TRACK_CME,
    SET_IS_INLINE_CME_ELIGIBLE,
    SET_LAST_CME_CONTENT_ID
} from '_acaSrc/store/cme.store';
import { SET_LICENSE_MESSAGES, SET_IS_MFA } from '_acaSrc/store/auth.store';
import { SET_IS_CUSTOMER } from '_acaSrc/store/user.store';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdQueuedCache from '../UtdQueuedCache';
import ServerMessaging from '../ServerMessaging';
import PubSub from '_acaSrc/utility/PubSub';
import {
    SET_HIDE_NAV_BAR,
    SET_DI_APP,
    SET_HAS_PATHWAYS_CSU_NOTES,
    SET_HAS_PATHWAYS_CONTENT,
    SET_RESPONSIVE_DESKTOP_ENABLED,
    SET_OPTIMIZELY_ROLLOUTS_INSTALLED
} from '_acaSrc/store/feature.store';
import { SET_LICENSEE, SET_SUPPORT_TAG } from '_acaSrc/store/footer.store';
import { SET_LOGIN_HREF_AND_IS_REGISTER } from '_acaSrc/store/login.store';
import { SET_CREATE_POWERPOINT } from '_acaSrc/store/graphic.store';

// This file mostly contains Internals related to UtdRestClient.js
// Contents of this file is mainly from utd-app.config.js

let isNeedReload = false;

function processAppInfo(data, vuexStore) {
    // If current content version does not match latest passed version,
    // then purge all current cache and rebuild queues.
    if (vuexStore.getters['app/contentVersion'] !== ''
     && vuexStore.getters['app/contentVersion'] !== data.appContentVersion) {
        new PubSub().publish('wkutd.purgePersistentCache');
    }
    vuexStore.commit(`app/${SET_PRODUCT_NAME}`, data.productName);
    setSearchEngineUrl(data.searchEngineUrl);
    vuexStore.dispatch('device/setBrowserInfo', data);
    vuexStore.commit(`footer/${SET_SUPPORT_TAG}`, data.supportTag);
    vuexStore.commit(`footer/${SET_LICENSEE}`, data.licensee);
    vuexStore.dispatch('app/checkLastContentUpdate', data.lastContentUpdate);
    vuexStore.commit(`app/${SET_PRODUCT_VERSIONS}`, data);
    vuexStore.commit(`app/${SET_COUNTRY_CODE}`, data.country);
    vuexStore.commit(`user/${SET_IS_CUSTOMER}`, data.isCustomer);
    vuexStore.commit(`auth/${SET_IS_MFA}`, data.isMfa);
    vuexStore.dispatch('app/updateUiHooks');
    vuexStore.commit(`login/${SET_LOGIN_HREF_AND_IS_REGISTER}`, data.loginUrl);
    vuexStore.commit(`feature/${SET_DI_APP}`, data);
    vuexStore.commit(`feature/${SET_HIDE_NAV_BAR}`, data.hideNavBar);
    vuexStore.dispatch('user/updateUser', data);

    new IndexManager().bodyCss.set('utdLogoFilename', vuexStore.getters['app/utdLogoFilename']);

    // Call method to update logo here, based on country code, product code and authentication status
    vuexStore.commit(`app/${SET_UCC_STATE}`, vuexStore.getters['user/userLoggedIn']);
}

export default {
    _checkForSlowResponse(config) {
        if (config.warningAfter) {
            const msAfterAjaxCall = new Date().getTime();
            const timeTakenInMs = msAfterAjaxCall - config.msBeforeAjaxCall;
            if (timeTakenInMs > config.warningAfter) {
                Logger.warn(
                    `timeTakenInMs ${timeTakenInMs}ms exceeds ${config.warningAfter}ms`
                );
            }
        }
    },
    _checkForTimeout(response) {
        // A status of 0 in a rejection means the request was cancelled,
        // check to see if from a timeout
        if (response.status === 0) {
            const msAfterAjaxCall = new Date().getTime();
            const timeTakenInMs = msAfterAjaxCall - response.config.msBeforeAjaxCall;
            if (timeTakenInMs > response.config.timeout) {
                getWindow().alert('The request timed out, please retry your last operation again.');

                JL('Angular.Ajax').error({
                    errorMessage: 'timeout',
                    status: response.status,
                    config: response.config
                });
            }
        }
    },
    _checkForRedirect(response) {
        if (typeof response !== 'undefined' && !!response) {
            if (typeof response.headers === 'function') {
                const redirect = response.headers('Utd-Location');

                // If we see a redirect, go there
                if (redirect) {
                    // CORE-6922: Do not clear cache if we are redirecting to TFA/MFA page.
                    // This is so we can restore the previous URL the user visited after.
                    if (redirect !== '/validate-code') {
                        // clearing cache on redirect because cache causes some
                        // issues when forcing the user to a page
                        new UtdQueuedCache().init();
                        new UtdCache().clearAll();
                    }

                    if (response && response.data !== 'undefined') {
                        try {
                            getWindow().location.href = redirect;
                            if (response.data) {
                                response.data.isRedirect = true;
                            }
                            else {
                                response.data = {
                                    isRedirect: true
                                };
                            }
                        }
                        catch (e) {
                            // ignore, seems to only happen when a String response is sent from server
                        }
                    }
                }
            }
        }
    },
    _defaultResponseError(rejection, context) {
        let errorMessage = '';
        const config = rejection && rejection._request && rejection._request.config;
        if (!config) {
            return;
        }

        this._checkForRedirect(rejection, context);

        this._checkForTimeout(rejection, context);

        if (rejection.status === C_HTTP_STATUS.FORBIDDEN && (config.ignore403
                                        || config.errorRefresh)) {
            isNeedReload = true;
            return Promise.reject(rejection);
        }

        if (rejection.status
            && rejection.status !== 0 && (config.bypassAll
                                        || config.errorRefresh
                                        || config.ignore403)) {
            return Promise.reject(rejection);
        }

        // If a REST call wants to handle the 400, 403 or 404 within the page it can set these flags
        if ((rejection.status === C_HTTP_STATUS.BAD_REQUEST && !config.bypass400)
            || (rejection.status === C_HTTP_STATUS.FORBIDDEN && !config.bypass403)
            || (rejection.status === C_HTTP_STATUS.NOT_FOUND && !config.bypass404)) {

            if (typeof rejection.data !== 'undefined' && rejection.data) {
                if (typeof rejection.data.errors !== 'undefined'
                    && rejection.data.errors.length > 0) {
                    errorMessage = rejection.data.errors[0].message;
                }
                else if (typeof rejection.data.ExceptionMessage !== 'undefined') {
                    errorMessage = rejection.data.ExceptionMessage;
                }
            }
            else if (typeof rejection.statusText !== 'undefined' && rejection.statusText) {
                errorMessage = rejection.statusText;
            }

            if (rejection.status === C_HTTP_STATUS.FORBIDDEN) {
                JL('Angular.Ajax').warn({
                    errorMessage,
                    status: rejection.status,
                    config
                });
            }
            else {
                JL('Angular.Ajax').error({
                    errorMessage,
                    status: rejection.status,
                    config
                });

                if (rejection.status === C_HTTP_STATUS.NOT_FOUND) {
                    getWindow().location.replace('/page-not-found');
                    return Promise.resolve(null);
                }
            }
            // CORE-6571 Added this logic for the MFA flow so that user is not redirected to the error page in case of a 403
            if ((rejection.status === C_HTTP_STATUS.FORBIDDEN && !context.vuexStore.getters['auth/isMfa'])
              || rejection.status !== C_HTTP_STATUS.FORBIDDEN) {
                // This syntax avoids putting it in the history api
                getWindow().location.replace('/error');
            }
            return Promise.resolve(null);
        }

        // Error thrown when the server has disabled the responsive app, force a refresh to send them to the correct page
        // If a REST call wants to handle the 409 it can set this flag
        if (rejection.status === C_HTTP_STATUS.CONFLICT && !config.bypass409) {
            context.vuexStore.getters['app/router'].reload();
            return Promise.resolve(null);
        }

        if (isStatusServerError(rejection.status) && !config.bypass500) {
            errorMessage = rejection.data.ExceptionMessage;

            JL('Angular.Ajax').error({
                errorMessage,
                status: rejection.status,
                config
            });

            // This syntax avoids putting it in the history api
            getWindow().location.replace('/unspecified-error');
            return Promise.resolve(null);
        }

        return Promise.reject(rejection);
    },
    _isUrlServiceCallMatch(rejection, urlSeek) {
        if (rejection
         && rejection.request
         && rejection.request.url
         && urlSeek) {
            return urlContains(urlSeek, rejection.request.url);
        }

        return false;
    },
    _isMyAccountServiceCall(rejection) {
        return this._isUrlServiceCallMatch(rejection, '/services/app/my-account');
    },
    _isCmeServiceCall(rejection) {
        return this._isUrlServiceCallMatch(rejection, '/services/app/cme');
    },
    _handleModuleResponseError(rejection, context, moduleUrlPath) {
        if (rejection
        && isStatusRequiresLogin(rejection.status)
        && urlContains(moduleUrlPath, getWindow().location.href)) {
            getWindow().location.href = '/login';
            return Promise.resolve(null);
        }
        else if (rejection && isStatusServerError(rejection.status)) {
            JL('Angular.Ajax').error({
                errorMessage: 'Microservice down?',
                status: rejection.status,
                config: rejection.config
            });

            getWindow().location.replace(`${moduleUrlPath}/error`);

            // Force close any open modal dialog
            new ServerMessaging().resetModal();

            return Promise.resolve(null);
        }

        return Promise.reject(rejection);
    },
    processMetaData(response, context) {
        if (response.body) {
            if (response.body.assetList) {
                // appInfo tasks need to be done first to populate data used by other tasks
                const appInfoAsset = response.body.assetList.find(asset => asset.assetKey && asset.assetKey.assetId === 'appInfo');
                if (appInfoAsset) {
                    processAppInfo(appInfoAsset.data, context.vuexStore);
                }
                for (let i = 0; i < response.body.assetList.length; i++) {
                    const asset = response.body.assetList[i];
                    if (asset.assetKey) {
                        if (asset.assetKey.assetId === 'trialInfo') {
                            context.vuexStore.dispatch('auth/setTrialData', asset.data);
                        }

                        if (asset.assetKey.assetId === 'cmeInfo') {
                            if (asset.data.cmeTicker !== undefined) {
                                context.vuexStore.commit(`cme/${SET_CME_LABEL}`, asset.data.cmeTicker.name);
                                context.vuexStore.commit(`cme/${SET_CME_TICKER}`,
                                    asset.data.cmeTicker.credits);
                            }
                            context.vuexStore.commit(`cme/${SET_IS_TRACK_CME}`, asset.data.cmeTrack);
                            if (asset.data.cmeLastTopicId !== undefined) {
                                context.vuexStore.commit(`cme/${SET_LAST_CME_CONTENT_ID}`, asset.data.cmeLastTopicId);
                            }
                            if (asset.data.inlineCmeEligible !== undefined) {
                                context.vuexStore.commit(`cme/${SET_IS_INLINE_CME_ELIGIBLE}`, asset.data.inlineCmeEligible);
                            }
                        }

                        if (asset.assetKey.assetId === 'cmeMocInfo') {
                            context.vuexStore.dispatch('cme/setMocAuthorityData', asset.data);
                        }
                        else if (asset.assetKey.assetId === 'settingsInfo') {
                            context.vuexStore.commit(`feature/${SET_RESPONSIVE_DESKTOP_ENABLED}`, asset.data);
                            context.vuexStore.commit(`graphic/${SET_CREATE_POWERPOINT}`, asset.data);
                            context.vuexStore.commit('feature/SET_SHOW_PRINT_LINK', asset.data);
                            context.vuexStore.commit(
                                'account/SET_MAX_DEVICE_ACTIVATIONS',
                                asset.data
                            );
                            context.vuexStore.commit(`feature/${SET_DI_APP}`, asset.data);
                            context.vuexStore.commit(`feature/${SET_HAS_PATHWAYS_CSU_NOTES}`, asset.data);
                            context.vuexStore.commit(`feature/${SET_HAS_PATHWAYS_CONTENT}`, asset.data);
                        }
                        else if (asset.assetKey.assetType === 'MESSAGE') {
                            if (asset.assetKey.assetId === 'VIEW_ENTERPRISE_SLA_NO_ACCEPT') {
                                context.vuexStore.commit(`auth/${SET_LICENSE_MESSAGES}`, asset.data);
                                new PubSub().publish(C_EVENTS.SLA_MESSAGE_EVENT);
                            }
                            else if (asset.assetKey.assetId === 'VIEW_INDIVIDUAL_SLA') {
                                asset.data.privacyPolicyUrl = context.vuexStore.getters['app/isUccState']
                                    ? 'https://www.uptodate.cn/home/uptodate-china-privacy-policy'
                                    : '/home/privacy-policy';
                                new ServerMessaging().enqueueMessage(asset.data);
                            }
                            else {
                                new ServerMessaging().enqueueMessage(asset.data);
                            }
                        }
                        else if (asset.assetKey.assetId === 'sessionManagerInfo') {
                            context.vuexStore.dispatch('app/setSessionManagerInfo', asset.data);
                            if ('optimizelyWebId' in asset.data) {
                                context.vuexStore.dispatch('feature/installOptimizelyWeb', asset.data);
                            }
                            if ('optimizelyRolloutsId' in asset.data) {
                                const initializeFeatures = context.vuexStore.dispatch('feature/initializeFeatures', asset.data);
                                initializeFeatures.then(() => {
                                    context.vuexStore.commit(`feature/${SET_OPTIMIZELY_ROLLOUTS_INSTALLED}`, true);
                                    return context.vuexStore.dispatch('feature/assignOptimizelyFeatures');
                                });
                            }
                            if ('guidAnalytics' in asset.data) {
                                setAnalyticsScriptWithGuid(asset.data);
                            }
                            if ('pendoApiKey' in asset.data && 'pendoAgentScript' in asset.data) {
                                context.vuexStore.dispatch('pendo/setPendoScript', asset.data);
                            }
                        }
                    }
                }

                // Attempt to display any message(s) returned in the response
                const hasMessage = new ServerMessaging().popMessage();
                if (!hasMessage && isNeedReload) {
                    context.vuexStore.getters['app/router'].reload(true);
                    isNeedReload = false;
                }

                if (response.body.data === 0) {
                    // Allow endpoints that return a number to return zero
                    response.body = 0;
                }
                else {
                    response.body = response.body.data || response.body;
                }
            }
        }

        if (isStatusNotAnError(response.status)) {
            return response;
        }

        return Promise.reject(response);
    },
    request(context, request) {
        try {
            // store the current time so we know when to log a warning, see response function
            request.config = {
                warningAfter: C_HTTP.WARNING_AFTER,
                msBeforeAjaxCall: new Date().getTime()
            };

            return request.config;
        }
        catch (err) {
            request.errorMessage = err;
            return this.requestError(context, request);
        }
    },
    requestError(context, request) {
        JL('Angular.Ajax').fatalException({
            errorMessage: request.errorMessage,
            status: request.status,
            config: request.config
        });

        getWindow().location.replace('/unspecified-error');
        return Promise.resolve(null);
    },
    response(res, context) {
        this._checkForRedirect(res, context);

        // Determine if the query is slow and log a warn message if that's the case
        this._checkForSlowResponse(res.request.config);

        return res;
    },
    responseError(rejection, context) {
        // If default error handling is suppressed via bypassAll, just reject the promise.
        // This lets code which originated the request handle errors
        // on its own, avoiding a redirect to the generic error page.
        if (rejection
         && rejection.request
         && rejection.request.config
         && rejection.request.config.bypassAll) {
            return Promise.reject(rejection);
        }

        if (this._isMyAccountServiceCall(rejection)) {
            return this._handleModuleResponseError(rejection, context, '/account');
        }
        else if (this._isCmeServiceCall(rejection)) {
            return this._handleModuleResponseError(rejection, context, '/cme');
        }

        return this._defaultResponseError(rejection, context);
    }
};
