<template>
  <div class="star-rating__container">
    <label v-if="options.showLabel"
           class="star-rating-label ds1-mr-2">{{ $t("RATE") }}</label>
    <ul class="star-rating">
      <li v-for="(rating, idx) in maxStarCount"
          :key="idx"
          class="rating-list"
          :class="starIconClass(rating)"
          tabindex="0"
          @keypress="$event.which === ENTER_KEY && setRating(rating)"
          @click="setRating(rating)"
          @mouseover="handleHover(rating)"
          @mouseleave="resetRating" />
    </ul>
  </div>
</template>

<script>
import PubSub from '_acaSrc/utility/PubSub';
import { C_KEYCODES } from '_acaSrc/utility/constants';

const MAX_STAR_COUNT = 5;

const isValidRating = (rating, max) => rating >= 0
             && typeof rating === 'number'
             && rating <= max;

export default {
    props: {
        userRating: {
            type: Number,
            default: 0
        },
        options: {
            type: Object,
            default: () => {
                return {
                    publishOnUserRating: false,
                    showLabel: true
                };
            }
        }
    },
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY,
            hoveredRating: isValidRating(this.userRating, MAX_STAR_COUNT)
                ? this.userRating
                : 0,
            selectedRating: isValidRating(this.userRating, MAX_STAR_COUNT)
                ? this.userRating
                : 0,
            maxStarCount: MAX_STAR_COUNT,
            doEventPublish: true
        };
    },
    watch: {
        userRating(newVal, oldVal) {
            if (this.selectedRating !== newVal && newVal !== oldVal) {
                if (!this.options.publishOnUserRating) {
                    this.doEventPublish = false;
                }
                this.setRating(newVal, true);
                this.resetRating();
            }
        }
    },
    methods: {
        handleHover(rating) {
            if (isValidRating(rating, MAX_STAR_COUNT)
             && !this.$el.classList.contains('rating-locked')) {
                this.hoveredRating = rating;
            }
        },
        resetRating() {
            this.hoveredRating = this.selectedRating;
        },
        setRating(rating, bypassLock = false) {
            if (isValidRating(rating, MAX_STAR_COUNT)
             && (bypassLock || !this.$el.classList.contains('rating-locked'))) {
                this.selectedRating = rating;

                if (!this.doEventPublish) {
                    this.doEventPublish = true;
                    return;
                }

                new PubSub().publish('wkutd.star-rating', {
                    rating: this.selectedRating,
                    id: this.$el.id
                });
            }
        },
        starIconClass(rating) {
            if (rating <= this.hoveredRating && this.hoveredRating > this.selectedRating) {
                return 'wkce-icon-filled-star';
            }
            return `${rating > this.selectedRating ? 'wkce-icon-star' : 'wkce-icon-filled-star'}`;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@STAR_RATING_LABEL_COLOR: @WKCE-GRAY-SHADE1;
@STAR_RATING_ICON_COLOR: @WKCE-BLUE-SHADE1;

.star-rating__container {
  .star-rating {
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  .star-rating-label {
    display: inline-block;
    vertical-align: bottom;
    font-size: 17px;
    color: @STAR_RATING_LABEL_COLOR;
  }

  .rating-list {
    .ds1-mr-1();
    .ds1-ml-0();
    max-height: 40px;
    cursor: pointer;
  }

  .wkce-icon-star, .wkce-icon-filled-star {
    color: @STAR_RATING_ICON_COLOR;

    &::before {
      display: inline-block;
      font-size: 21px;
      width: 22px;
    }
  }

  &.star-rating-wide .rating-list {
    width: 40px;

    &.wkce-icon-star, &.wkce-icon-filled-star {
      &::before {
        .ds1-ma-1();
      }
    }
  }

  @media print {
    display: none;
  }
}
</style>