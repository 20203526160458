<template>
  <div class="utd-phone-input wk-js2">
    <utd-text-input :label="$t('MYACCOUNT.CONTACT_PHONE')"
                    input-id="phoneInput"
                    :input-size="input.inputSize"
                    :is-disabled="options.isDisabled"
                    :is-success="isSuccess"
                    :is-error="isError"
                    :error-message="phoneValidator">
      <input id="phoneInput"
             v-model="phoneNumber"
             type="tel"
             class="wk-field-input"
             @blur="handleInputBlur"
             @input="handlePhoneInput">
    </utd-text-input>
    <div class="wk-field wk-field-horizontal-choices">
      <utd-radio-set v-show="!options.isDisabled"
                     v-model="phoneType"
                     :items="phoneTypeOptions"
                     name="phoneType"
                     @input="handleSelectPhoneType" />
    </div>
  </div>
</template>
<script>
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdRadioSet from '_acaSrc/components/shared/stdlib/UtdRadioSet.vue';
import { maxContactFieldLength, validPhoneNumberRegex } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdRadioSet,
        UtdTextInput
    },
    props: {
        input: {
            type: Object,
            default() {
                return {
                    inputSize: 'small',
                    phoneValue: '',
                    phoneType: 0
                };
            }
        },
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        isDirtyOverride: {
            type: Boolean,
            default: null
        }
    },
    emits: [ 'on-phone-type-change', 'on-phone-input', 'on-phone-input-blur' ],
    data() {
        return {
            phoneNumber: this.input.phoneValue,
            phoneType: this.input.phoneType,
            phoneTypeOptions: [{
                option: 'Work'
            }, {
                option: 'Home'
            }, {
                option: 'Cell'
            }],
            isDirtyInternal: false
        };
    },
    computed: {
        phoneValidator() {
            if (this.options.serverError) {
                return this.$t(this.options.serverError);
            }
            if (this.hasAttr('required') && !this.phoneNumber.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            if (this.phoneNumber.length > maxContactFieldLength) {
                return this.$t('MYACCOUNT.INVALID_FIELD_LENGTH', {
                    maxLength: maxContactFieldLength
                });
            }
            if (!this.phoneNumber.match(validPhoneNumberRegex)) {
                return this.$t('MYACCOUNT.INVALID_PHONE');
            }
            return '';
        },
        isSuccess() {
            return this.phoneValidator === ''
                && (this.isDirty || this.options.formSubmitted)
                && this.options.showSuccess;
        },
        isError() {
            return this.phoneValidator !== ''
                && (this.isDirty || this.options.formSubmitted)
                && this.options.showError;
        },
        isDirty() {
            return this.isDirtyOverride ? this.isDirtyOverride : this.isDirtyInternal;
        }
    },
    methods: {
        handleSelectPhoneType(value) {
            this.$emit('on-phone-type-change', this.phoneTypeOptions[value].option);
        },
        handlePhoneInput($event) {
            this.$emit('on-phone-input', $event.target && $event.target.value);
        },
        hasAttr(attr) {
            return this.$attrs.hasOwnProperty(attr);
        },
        handleInputBlur() {
            this.isDirtyInternal = true;
            const isValid = this.phoneValidator === '';
            this.$emit('on-phone-input-blur', isValid);
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-phone-input {
  .wk-field-choice-label {
    .ds1-utd-size-2();
  }

  .wk-field {
    .ds1-mb-1();
  }
}

</style>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-phone-input {
  .ds1-mb-2();

  .wk-field-horizontal-choices {
    .ds1-mt-0();
  }
}
</style>
