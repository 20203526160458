<template>
  <div class="user-devices-list">
    <h2 class="dialog__header sidebar-bolded"><slot /></h2>
    <div v-if="numActiveDevices(platform)" class="dialog__content">
      <ul class="dialog__devices-list">
        <li v-for="device in visibleDevices(platform)"
            :key="device.deviceId"
            class="dialog__device">
          <user-device :start-state="notificationStartState(device.deviceId)"
                       :device="device" />
        </li>
      </ul>
      <div class="dialog__number-devices-used">
        <p class="dialog__allotted-devices">Currently using
          <span>{{ numActiveDevices(platform) }} out of
            {{ maxActivationsAllowed(platform) }} allotted {{ platform }} devices</span>
        </p>
        <button v-if="showInfoButtonDesktop || showInfoButtonMobile"
                aria-label="Show allotted devices info"
                aria-controls="allotDevicesInfo"
                :aria-expanded="showAllottedDevicesInfo"
                :class="{ expandedInfo: showAllottedDevicesInfo,
                          collapsedInfo: !showAllottedDevicesInfo }"
                @click="toggleAllottedDevicesInfo" />
        <p v-if="devicesOverMaxLimit(platform)" class="dialog__show-all-devices">
          <a href="#"
             class="dialog__show-all-devices-link"
             :class="{ expanded: showAllDevices,
                       collapsed: !showAllDevices }"
             @click="clickShow()">Show {{ showAllDevices ? 'less' : 'all' }}</a>
        </p>
        <p v-if="showAllottedDevicesInfo"
           id="allotDevicesInfo"
           class="dialog__allotted-devices-info">
          UpToDate allows you {{ allowedDesktopDevicesMessage }}
          to be signed into the mobile application on {{ allowedMobileDevicesMessage }}.
          You can de-activate or sign out an installation above
          (in order to activate or sign in on another device).
        </p>
      </div>
    </div>
    <div v-if="showNoLinkedDevicesMessage">
      <p class="dialog__no-mobile-devices">
        No linked mobile devices.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { SET_SHOW_ALL_DESKTOP, SET_SHOW_ALL_MOBILE } from '_acaSrc/store/account.store';
import { C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import UserDevice from './UserDevice.vue';

export default {
    components: {
        UserDevice
    },
    props: {
        platform: {
            type: String,
            default: C_MY_ACCOUNT.DESKTOP_DEVICE
        },
        selectedDeviceId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            C_MY_ACCOUNT,
            notificationState: this.startState,
            showFullDeviceInfo: false,
            showAllottedDevicesInfo: false
        };
    },
    computed: {
        ...mapGetters('account', [
            'visibleDevices',
            'numActiveDevices',
            'devicesOverMaxLimit',
            'maxActivationsAllowed',
            'showAllDesktop',
            'showAllMobile'
        ]),
        showAllDevices() {
            if (this.platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
                return this.showAllDesktop;
            }
            return this.showAllMobile;
        },
        showInfoButtonDesktop() {
            return this.platform === C_MY_ACCOUNT.DESKTOP_DEVICE
                && this.numActiveDevices(C_MY_ACCOUNT.MOBILE_DEVICE) === 0
                && !this.devicesOverMaxLimit(this.platform);
        },
        showInfoButtonMobile() {
            return this.platform === C_MY_ACCOUNT.MOBILE_DEVICE
                && this.numActiveDevices(C_MY_ACCOUNT.MOBILE_DEVICE) !== 0
                && !this.devicesOverMaxLimit(this.platform);
        },
        showNoLinkedDevicesMessage() {
            return this.platform !== C_MY_ACCOUNT.DESKTOP_DEVICE
                && this.numActiveDevices(this.platform) === 0;
        },
        allowedDesktopDevicesMessage() {
            const pretext = 'to activate the desktop application on';
            const maxDevices = this.maxActivationsAllowed(C_MY_ACCOUNT.DESKTOP_DEVICE);
            if (maxDevices >= 2) {
                return `${pretext} ${maxDevices} computers and`;
            }
            else if (maxDevices === 1) {
                return `${pretext} ${maxDevices} computer and`;
            }
            return '';
        },
        allowedMobileDevicesMessage() {
            const maxDevices = this.maxActivationsAllowed(C_MY_ACCOUNT.MOBILE_DEVICE);
            if (maxDevices >= 2) {
                return `${maxDevices} devices`;
            }
            else if (maxDevices === 1) {
                return `${maxDevices} device`;
            }
            return 'devices';
        }
    },
    methods: {
        ...mapMutations('profile', [
            SET_SHOW_ALL_DESKTOP,
            SET_SHOW_ALL_MOBILE
        ]),
        clickShow() {
            if (this.platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
                this[SET_SHOW_ALL_DESKTOP](!this.showAllDesktop);
                return;
            }
            this[SET_SHOW_ALL_MOBILE](!this.showAllMobile);
        },
        toggleAllottedDevicesInfo() {
            this.showAllottedDevicesInfo = !this.showAllottedDevicesInfo;
        },
        notificationStartState(deviceId) {
            return this.selectedDeviceId === deviceId ? 'WARN' : 'DEVICE';
        }
    }
};
</script>


<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-UTD-DIALOG-ICON-COLOR: @WKCE-BLUE-SHADE1;
@DS1-UTD-DIALOG-ICON-COLOR-HOVER: @WKCE-BLUE-SHADE2;
@DS1-UTD-INFO-DIALOG-ICON: '\e80d';

@media screen and (max-width: 768px) {
  .dialog__allotted-devices-info {
    max-width: 300px;
  }

  .dialog__number-devices-used {
    max-width: 313px;
  }
}

.dialog__header {
  .ds1-mh-3();
  .ds1-mt-3();
  .ds1-mb-2();
  font-size: 16px;
}

.dialog__content {
  font-size: 14px;
}

.dialog__devices-list {
  .ds1-reset-ul-li();
}

.dialog__device {
  display: flex;
}

.dialog__allotted-devices {
  .ds1-ma-0();
  flex: 1;
}

.dialog__allotted-devices-info {
  .ds1-pa-1();
  line-height: 19px;
  background-color: @DS1-UTD-GRAY-BG-COLOR;
  display: inline-block;

  &::before {
    position: relative;
    font-family: "wk-icons-open";
    font-size: 14px;
    content: '\e80d';
  }
}

.dialog__number-devices-used {
  .ds1-mh-3();
  display: flex;
  flex-wrap: wrap;

  .collapsedInfo,
  .expandedInfo {
    background: transparent;
    border: none;
    margin-left: 4px;
    align-self: flex-start;

    &::after {
      color: @DS1-UTD-DIALOG-ICON-COLOR;
      font-family: "wk-icons-open";
      font-size: 16px;
    }

    &:hover {
      color: @DS1-UTD-DIALOG-ICON-COLOR-HOVER;
      cursor: pointer;
    }
  }

  .expandedInfo::after {
    content: @DS1-UTD-CHEVRON-UP;
  }

  .collapsedInfo::after {
    content: @DS1-UTD-INFO-DIALOG-ICON;
  }
}

.dialog__show-all-devices-link {
  &:hover {
    text-decoration: none;
  }
}

.dialog__show-all-devices {
  .ds1-reset-p();
  .ds1-ml-1();

  .expanded::after {
    position: relative;
    font-family: "wk-icons-open";
    font-size: 16px;
    content: @DS1-UTD-CHEVRON-UP;
    padding-left: 6px;
  }

  .collapsed::after {
    position: relative;
    font-family: "wk-icons-open";
    font-size: 16px;
    content: @DS1-UTD-CHEVRON-DOWN;
    padding-left: 6px;
  }
}

.dialog__no-mobile-devices {
  .ds1-mv-2();
  .ds1-mh-3();
  font-style: italic;
  font-size: 14px;
}
</style>