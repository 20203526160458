<template>
  <footer v-if="isFooterLoaded" id="utdFooter">
    <div v-if="!isProspectView" v-show="isAppDataLoaded">
      <div class="tier1">
        <div class="column">
          <ul id="footerTopLeft">
            <li>
              <a id="mobile-prospect-footer-language" href="/settings/change-language">Language</a>
            </li>
            <li v-if="showManageCookiesLink">
              <a id="ot-sdk-btn"
                 class="ot-sdk-show-settings">{{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}</a>
            </li>
            <li>
              <a id="sinfo_footer"
                 @click="openSupportTagMethod($event)"
                 v-html="getFooterTitle('supportTag')" />
            </li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>
              <!-- eslint-disable vue/no-v-html -->
              <a id="sla_footer"
                 :href="getFooterUrl('sla')"
                 :target="getFooterTarget('sla')"
                 :rel="getFooterRel('sla')"
                 v-text="getFooterTitle('sla')" />
            </li>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('policies')"
                 v-html="getFooterTitle('policies')" />
            </li>
          </ul>
        </div>
      </div>
      <div class="tier2">
        <div class="column">
          <ul id="fTier2">
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('contactUs')"
                 v-html="getFooterTitle('contactUs')" />
            </li>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('uptodateNews')"
                 v-html="getFooterTitle('uptodateNews')" />
            </li>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('trainingCenter')"
                 v-html="getFooterTitle('trainingCenter')" />
            </li>
          </ul>
        </div>
        <div class="column">
          <ul>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('aboutUs')"
                 v-html="getFooterTitle('aboutUs')" />
            </li>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('accessOptions')"
                 v-html="getFooterTitle('accessOptions')" />
            </li>
            <li>
              <a v-utd-new-tab="true"
                 :href="getFooterUrl('demos')"
                 v-html="getFooterTitle('demos')" />
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isTier3Visible" class="tier3">
        <ul>
          <li class="social-circle facebook">
            <a id="sfiFacebook"
               v-utd-new-tab="true"
               class="wkce-icon-filled-facebook"
               href="https://www.facebook.com/UpToDateEBM"
               aria-label="UpToDate Facebook" />
          </li>
          <li class="social-circle twitter">
            <a id="sfiTwitter"
               v-utd-new-tab="true"
               class="wkce-icon-filled-twitter"
               href="https://twitter.com/UpToDate"
               aria-label="UpToDate Twitter" />
          </li>
          <li class="social-circle linkedin">
            <a id="sfiLinkedIn"
               v-utd-new-tab="true"
               class="wkce-icon-filled-linkedin"
               href="https://www.linkedin.com/company/uptodate?trk=hb_tab_compy_id_21924"
               aria-label="UpToDate LinkedIn" />
          </li>
          <li class="social-circle youtube">
            <a id="sfiYoutube"
               v-utd-new-tab="true"
               class="wkce-icon-filled-youtube"
               href="https://www.youtube.com/uptodateebm"
               aria-label="UpToDate YouTube" />
          </li>
        </ul>
      </div>
      <div v-if="isTier4Visible" class="tiers tier4">
        <span id="wkh-logo" class="wkh-logo ir">WKH Logo</span>
        <ul id="fTier3">
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/health/">
              Wolters Kluwer Health
            </a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/why-uptodate">
              UpToDate<sup>&reg;</sup>
            </a>
          </li>
          <li>
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/medi-span">
              Medi-Span<sup>&reg;</sup>
            </a>
          </li>
        </ul>
      </div>
      <utd-copyright-provider :full="true" :center="true" />
    </div>
    <!-- WK Prospect Footer >>> -->
    <desktop-prospect-footer v-if="isProspectView" />
    <!-- <<< WK Prospect Footer -->
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';
import DesktopProspectFooter from '_acaSrc/components/footer/DesktopProspectFooter.vue';
import { Listener } from '_acaSrc/utility/Events';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import { rebindCookieManagerLink } from '_acaSrc/utility/OneTrustCookieTool';

const RENDERING_TIMEOUT = 250;
const FOOTER_SELECTOR = '#appContainer > footer';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdCopyrightProvider,
        DesktopProspectFooter
    },
    data() {
        return {
            resizeListenerInstance: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'isProspectView',
            'isAppDataLoaded'
        ]),
        ...mapGetters('footer', [
            'getFooterTitle',
            'getFooterUrl',
            'getFooterTarget',
            'getFooterRel',
            'footerHeaderTitle',
            'isTier3Visible',
            'isTier4Visible',
            'isFooterLoaded'
        ]),
        ...mapGetters('device', [ 'showManageCookiesLink' ])
    },
    mounted() {
        this.activate();
    },
    beforeUnmount() {
        this.unsubscribe({
            eventName: 'RESPONSIVE_TRANSITION',
            handlerFn: this.onResponsiveTransition
        });
    },
    methods: {
        ...mapActions('app', [
            'setListener',
            'clearListener',
            'subscribe',
            'unsubscribe'
        ]),
        ...mapActions('footer', [
            'positionFooter',
            'openSupportTag'
        ]),
        openSupportTagMethod(event) {
            this.openSupportTag({ event });
        },
        activate() {
            // Mobile safari reports scrolling as a 'resize' event,
            // as the viewport height can change.
            // Account for this by tracking the width of the viewport to determine
            // if it was a 'true' resize.
            let lastWidth = getDocument().documentElement.clientWidth;
            let lastHeight = getDocument().documentElement.clientHeight;

            // ToDo: Refactoring needed. Current function works for all footers,
            //  no need to unsubscribe on destroy
            this.resizeListenerInstance = new Listener(getWindow(), 'resize', () => {
                const resizeResult = this.isTrueResizeEvent({ lastWidth, lastHeight });
                if (resizeResult.isTrueResize) {
                    this.callPositionFooter();
                }
                else {
                    // Trigger the "repositionDialog" message here, in case the modal dialog
                    // is currently active, so that it will get correctly repositioned.
                    new PubSub().publish('wkutd.repositionDialog');
                }

                lastWidth = resizeResult.newWidth;
                lastHeight = resizeResult.newHeight;
            });
            this.setListener(this.resizeListenerInstance);
            this.subscribe({
              eventName: 'RESPONSIVE_TRANSITION',
              handlerFn: this.onResponsiveTransition
            });
            setTimeout(() => {
                this.positionFooter({
                    fromLocation: 'footer.directive: activate'
                });
            }, RENDERING_TIMEOUT);
        },
        onResponsiveTransition() {
          rebindCookieManagerLink();
        },
        callPositionFooter() {
            const eleFooter = getDocument().querySelector(FOOTER_SELECTOR);
            if (!eleFooter) {
                return;
            }
            if (eleFooter.classList.contains('displayed')) {
                eleFooter.classList.remove('displayed');
                this.positionFooter({
                    doFocusChange: false,
                    fromLocation: 'footer.directive: resize'
                });
            }
        }
    }
};
</script>

<style scoped lang="less">
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  /* Styles below include the !important rule to override styles applied to the button
   via the OneTrust script, which also use !important.*/
  #ot-sdk-btn.ot-sdk-show-settings {
    color: @WKCE-BLACK !important;

    &:hover {
      color: @WKCE-BLACK !important;
    }
  }
</style>