const RESULT_TYPE = 'UTD_SECTION';
const GRAPHIC_SECTION_SOURCE = 'GRAPHIC-SECTION';
const FEATURED_GRAPHIC_SOURCE = 'FEATURED-GRAPHIC';

export default class KeyPointsResults {
    constructor(id, type, title, markupTitle, searchResults, { searchRank, rankIndex } = {}) {
        this.id = id;
        this.type = type || '';
        this.title = title;
        this.markupTitle = markupTitle;
        this.searchRank = searchRank;
        this.rankIndex = rankIndex;
        this.featuredGraphics = this.setFeaturedGraphics(searchResults) || [];
        this.searchResults = this.setAccordionData(searchResults) || [];
        this.initAllGraphics();
    }

    setFeaturedGraphics(searchResults) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .filter(result => result.type !== RESULT_TYPE)
                .map(result => {
                    return {
                        imageKey: result.id,
                        type: result.type,
                        title: result.title,
                        topicKey: this.id,
                        searchRank: this.searchRank,
                        rankIndex: this.rankIndex,
                        source: `${this.type}-${FEATURED_GRAPHIC_SOURCE}`.toLowerCase()
                    };
                });
        }
    }

    setAccordionGraphics(searchResults, accordionTitle) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .map(result => {
                    return {
                        imageKey: result.id,
                        type: result.type,
                        title: result.title,
                        topicKey: this.id,
                        searchRank: this.searchRank,
                        rankIndex: this.rankIndex,
                        source: `${this.type}-${GRAPHIC_SECTION_SOURCE}`.toLowerCase(),
                        accordionTitle
                    };
                });
        }
    }

    setAccordionData(searchResults) {
        if (searchResults && Array.isArray(searchResults)) {
            return searchResults
                .filter(result => result.type === RESULT_TYPE)
                .map(result => {
                    return {
                        type: result.type,
                        title: result.title,
                        markupTitle: result.markupTitle,
                        snippet: result.snippet,
                        practicePoints: result.practicePointsContent,
                        searchRank: this.searchRank,
                        rankIndex: this.rankIndex,
                        graphics: this.setAccordionGraphics(result.searchResults || [], result.title)
                    };
                });
        }
    }

    initAllGraphics() {
        this.allGraphics = [];
        const map = {};
        const addIfNew = g => {
            if (map[g.imageKey]) {
                return;
            }
            map[g.imageKey] = true;
            this.allGraphics.push(g);
        };
        this.featuredGraphics.forEach(addIfNew);
        this.searchResults.forEach(sr => {
            sr.graphics.forEach(addIfNew);
        });
    }
}