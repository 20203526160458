<template>
  <div id="myuptodate"
       class="wk-js2"
       :class="`my-uptodate-wrapper${source}`"
       :data-autotest="`myUptodateWrapper${source}`">
    <utd-tabs tab-style="wk-tabs-default"
              class="with-divider"
              :disable-tabs="disableTabs"
              :active-tab="view.activeTab"
              @selectedTab="setTabAndLoadData">
      <utd-tab :is-hidden="!showHistory"
               :tab-id="`myUpToDateHistoryTab${view.source}`"
               :title="$t('MYUPTODATE.HISTORY')">
        <my-utd-history-tab :view="view" @disable-tabs="disableMyUtdTabs" />
      </utd-tab>
      <utd-tab :is-hidden="!showMostViewed"
               :tab-id="`myUpToDateMostViewedTab${view.source}`"
               :title="$t('MYUPTODATE.MOST_VIEWED')">
        <my-utd-most-viewed :source="view.source" />
      </utd-tab>
      <utd-tab :is-hidden="!showBookmarks"
               :tab-id="`myUpToDateBookmarkTab${view.source}`"
               :title="$t('MYUPTODATE.BOOKMARKS')">
        <my-utd-bookmark :view="view" />
      </utd-tab>
    </utd-tabs>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SET_DEFAULT_CONTENT_TAB } from '_acaSrc/store/profile.store';
import { C_MYUTD_TABS, C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import MyUtdHistoryTab from '_acaSrc/components/myuptodate/MyUtdHistory.vue';
import MyUtdMostViewed from '_acaSrc/components/myuptodate/MyUtdMostViewed.vue';
import MyUtdBookmark from '_acaSrc/components/myuptodate/MyUtdBookmarks.vue';
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import ServerMessaging from '../../utility/ServerMessaging';

const WIDGET_ELEMENT_SELECTOR = '#my-uptodate-widget';
const WIDGET_ELEMENT_PADDING_BOTTOM = 122;
const CONTENT_PADDING_TOP = 40;

export default {
    components: {
        MyUtdHistoryTab,
        MyUtdMostViewed,
        MyUtdBookmark,
        UtdTabs,
        UtdTab
    },
    props: {
        source: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showHistory: false,
            showBookmarks: false,
            showMostViewed: false,
            disableTabs: false,
            view: {
                activeTab: C_MYUTD_TABS.HISTORY,
                source: this.source
            },
            widgetElement: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'state',
            'router'
        ]),
        ...mapGetters('profile', [
            'permissions',
            'defaultContentTab'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        myUtdTabs() {
            return C_MYUTD_TABS;
        },
        maxUtdTab() {
            return Math.max(...Object.values(this.myUtdTabs));
        },
        isShowWidget() {
            return this.permissions.isShowWidget;
        }
    },
    watch: {
        isShowWidget(newValue) {
            if (!this.widgetElement || !newValue) {
                return;
            }
            this.widgetElement.style.height = null;
        }
    },
    mounted() {
        this.activate();
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.START_POSITION_FOOTER, this.setWidgetHeight);
    },
    methods: {
        ...mapMutations('profile', [ SET_DEFAULT_CONTENT_TAB ]),
        ...mapActions('app', [ 'delayExecuteUntilDataLoaded' ]),
        ...mapActions('profile', [
            'updateWidgetTabPreference',
            'refreshHistory',
            'refreshMostViewed',
            'refreshBookmarks' ]),
        disableMyUtdTabs(val) {
            this.disableTabs = val;
        },
        activate() {
            this.delayExecuteUntilDataLoaded(() => {
                if (this.view.source === '_widget') {
                    this.initWidgetSettings();
                }
                else {
                    this.showHistory = this.permissions.history;
                    this.showBookmarks = this.permissions.bookmarks;
                    this.showMostViewed = this.permissions.mostViewed;
                }

                if (this.view.source === '_mobile') {
                    this.checkDesktopRedirects();
                    this.setActiveMobileTab();
                }
                else if (this.view.source === '') {
                    this.setActiveModalTab();
                }

                this.setTabAndLoadData(this.view.activeTab, null);

                const messageServiceDialog = new ServerMessaging().dialog;

                if (this.view.source !== '_mobile'
                    && this.view.source !== '_widget'
                    && messageServiceDialog) {
                    messageServiceDialog.working.fnClose = this.resetTabSources;
                }
            });
        },
        // Normally we would use ui-router's ui-sref, but there appears to
        // be a bug (https://github.com/angular-ui/ui-router/issues/3529)
        // with ui-sref and lazy loaded modules where it doesn't know how to
        // create the url since the cme ui-router state is not loaded yet.
        // Only occurs when opening link in new tab and only when user has
        // not yet loaded the cme module. Will hopefully not be needed when
        // we migrate to vue-router or whatever routing solution we adopt.
        checkDesktopRedirects() {
            // Redirect to home search if user does not have permissions
            if (!this.permissions.myUpToDate) {
                this.router.url('/contents/search');
                return;
            }

            // Redirect to home search and launch modal, if on desktop and not in mobile view
            if (this.isDesktopView) {
                this.router.url('/contents/search?myUpToDate=true');
            }
        },
        resetTabSources() {
            this.initWidgetSettings();
        },
        setDefaultActiveMobileTab() {
            // Default to HISTORY or previous value, override if URL argument present
            if (this.defaultContentTab === this.myUtdTabs.MOST_VIEWED
                || this.defaultContentTab === this.myUtdTabs.BOOKMARKS) {
                this.view.activeTab = this.defaultContentTab;
            }
            else {
                this.view.activeTab = this.myUtdTabs.HISTORY;
            }
        },
        setActiveMobileTab() {
            this.setDefaultActiveMobileTab();
            if (typeof this.router.stateParams()
            && typeof this.router.stateParams().myUtdTab !== 'undefined') {
                this.view.activeTab = parseInt(this.router.stateParams().myUtdTab);
                // If tab value passed is not a numeric value,
                // then assign to min valid tab
                if (this.view.activeTab < 0 || isNaN(this.view.activeTab)) {
                    this.view.activeTab = this.myUtdTabs.HISTORY;
                }
                // Otherwise if tab is greater than max valid tab,
                // then assign it to max valid tab value
                else if (this.view.activeTab > this.maxUtdTab) {
                    this.view.activeTab = this.maxUtdTab;
                }
            }
            this[SET_DEFAULT_CONTENT_TAB](this.view.activeTab);
        },
        initWidgetSettings() {
            this.widgetElement = getDocument().querySelector(WIDGET_ELEMENT_SELECTOR);
            let activeTab = this.myUtdTabs.HISTORY;
            if (this.permissions.widgetTab) {
                activeTab = this.permissions.widgetTabPref;
            }

            this.showHistory = this.permissions.historyWidget;
            this.showMostViewed = this.permissions.mostViewedWidget;
            this.showBookmarks = this.permissions.bookmarksWidget;

            if (activeTab === this.myUtdTabs.MOST_VIEWED && !this.showMostViewed) {
                activeTab = this.myUtdTabs.HISTORY;
            }
            else if (activeTab === this.myUtdTabs.BOOKMARKS && !this.showBookmarks) {
                activeTab = this.myUtdTabs.HISTORY;
            }

            this.view.activeTab = activeTab;

            new PubSub().subscribe(C_EVENTS.START_POSITION_FOOTER, this.setWidgetHeight);
        },
        setActiveModalTab() {
            this.view.activeTab = this.defaultContentTab;
        },
        setTabAndLoadData(tab, event) {
            if (event) {
                new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, { event });

                if (this.view.source === '_mobile') {
                    this.router.go('.', { myUtdTab: tab }, { notify: false });
                }

                if (this.view.source !== '_widget') {
                    this[SET_DEFAULT_CONTENT_TAB](tab);
                }
            }
            this.view.activeTab = tab;
            // Only call it if it was from a click in the widget
            if (event && this.view.source === '_widget' && this.permissions) {
                this.updateWidgetTabPreference(tab);
            }
            this.refreshData(tab);
        },
        refreshData(tab) {
            if (tab === this.myUtdTabs.HISTORY) {
                this.refreshHistory();
            }
            else if (tab === this.myUtdTabs.MOST_VIEWED) {
                this.refreshMostViewed();
            }
            else if (tab === this.myUtdTabs.BOOKMARKS) {
                this.refreshBookmarks();
            }
        },
        setWidgetHeight() {
            if (!this.widgetElement) {
                return;
            }
            const windowHeight = getWindow().innerHeight;
            const widgetElementTop = CONTENT_PADDING_TOP + this.widgetElement.offsetTop;
            const widgetHeight = windowHeight - widgetElementTop - WIDGET_ELEMENT_PADDING_BOTTOM;
            this.widgetElement.style.height = `${widgetHeight}px`;
        }
    }
};
</script>

<style lang="less">
  @import './myuptodate.less';
</style>
