<template>
  <ul v-if="isGraphicsSearch"
      class="utd-listView graphicsResults"
      role="list">
    <li v-for="(result, idx) in searchResultsResults"
        :id="result.id"
        :key="idx"
        class="utd-listView-item"
        role="listitem">
      <utd-thumbnail :graphic="result"
                     large
                     :target="isGraphicType(result.type) ? '_self' : '_visualdx'"
                     :has-border="true"
                     @utd-thumbnail-click="onClickGraphicsLink($event, result.type)" />
    </li>
  </ul>
</template>

<script>
import UtdImageViewer from '_acaSrc/directives/UtdImageViewer.directive';
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';
import { mapActions, mapGetters } from 'vuex';
import { C_SEARCH_RESULTS } from '_acaSrc/utility/constants';

export default {
    directives: {
        UtdImageViewer
    },
    components: {
        UtdThumbnail
    },
    computed: {
        ...mapGetters('search', [
            'isGraphicsSearch',
            'searchResultsResults'
        ])
    },
    methods: {
        ...mapActions('graphic', [
            'handleUseGraphicLink'
        ]),
        onClickGraphicsLink(evt, type) {
            if (!this.isGraphicType(type)) {
                return;
            }
            this.handleUseGraphicLink({
                evt,
                options: { skipSidebarStateReset: this.isGraphicsSearch }
            });
        },
        isGraphicType(type) {
            return type === C_SEARCH_RESULTS.RESULT_TYPE.GRAPHIC;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-listView.graphicsResults {
  .ds1-ph-1();
  display: flex;
  flex-wrap: wrap;

  .utd-listView-item {
    margin: 10px 5px;
    padding: 0;
    border: none;
  }
}

:deep(.utd-thumbnail__container) {
  margin-right: 0;
}

@media only screen and (min-width: 392px) {
  .utd-listView.graphicsResults {
    .ds1-ph-0();

    .utd-listView-item {
      margin: 10px 15px;
    }
  }
}

.utd-listView-tabletAll() {
  .utd-listView.graphicsResults .utd-listView-item {
    margin: 10px 20px;
  }
}

@media only screen and (min-width: 768px) {
  .utd-listView-tabletAll();

  .isDesktop {
    #search-results-container {
      ul.utd-listView.graphicsResults {
        margin: 0 10px;
        min-width: 100%;

        > li {
          margin: 10px 20px;
        }
      }
    }
  }
}

.isTablet {
  .utd-listView-tabletAll();
}
</style>
