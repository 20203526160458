import WkceDates from '_acaSrc/utility/Dates';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import Logger from '_acaSrc/utility/Logger';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';
import UtdCache from '_acaSrc/utility/UtdCache';
import i18next from 'i18next';

export const initResetPasswordData = () => {
    return {
        emailForm: {
            email: ''
        },
        display: {
            showEmailForm: true,
            showEmailCtrls: true
        },
        message: {
            code: '',
            content: '',
            status: 'Error'
        },
        isSendEmailDisabled: false,
        isSendEmailActive: false
    };
};

export const initChangePasswordData = () => {
    return {
        passwordForm: {
            password: '',
            appguid: '',
            source: ''
        },
        message: {
            error: '',
            success: ''
        }
    };
};

const state = {
    form: {
        code: ''
    },
    otc: {
        isValid: false,
        verificationCodeMessage: '',
        validForSecondsMessage: '',
        errorMessage: '',
        isAllowOtcGeneration: true,
        isAllowOtcEntry: true,
        oneTimeCode: '',
        timeoutInterval: 180,
        showSendNewCode: false
    },
    oidcPartnerDetails: {
        bannerMessage: '',
        partnerName: '',
        storeUrl: '',
        modalConfig: {
            shown: false,
            registerOrSubscribeButton: '',
            welcomeMessage: '',
            welcomeMessageRegOrSubscribe: ''
        },
        partnerMarketingLinks: {
            medProfessionalUrl: '',
            medTraineeUrl: '',
            groupPracticeUrl: '',
            institutionUrl: ''
        }
    },
    licenseAgreements: {
        raw: '',
        html: '',
        viewed: false
    },
    trialState: {
        daysLeft: 1,
        isEnabled: false
    },
    resetPassword: {
        ...initResetPasswordData()
    },
    changePassword: {
        ...initChangePasswordData()
    },
    ssoAccountName: '',
    marketingUrls: {},
    expiredSite: {
        isExpired: false,
        expiredSiteName: ''
    },
    isMfa: false
};

export const getters = {
    formCode: state => state.form.code,
    otcIsValid: state => state.otc.isValid,
    otcVerificationCodeMessage: state => state.otc.verificationCodeMessage,
    otcValidForSecondsMessage: state => state.otc.validForSecondsMessage,
    otcErrorMessage: state => state.otc.errorMessage,
    otcIsAllowOtcGeneration: state => state.otc.isAllowOtcGeneration,
    otcIsAllowOtcEntry: state => state.otc.isAllowOtcEntry,
    otcOneTimeCode: state => state.otc.oneTimeCode,
    otcTimeoutInterval: state => state.otc.timeoutInterval,
    otcShowSendNewCode: state => state.otc.showSendNewCode,
    licenseAgreementsHtml: state => state.licenseAgreements.html,
    licenseAgreementsViewed: state => state.licenseAgreements.viewed,
    trialStateDaysLeft: state => state.trialState.daysLeft,
    trialStateIsEnabled: state => state.trialState.isEnabled,

    resetPwdEmailForm: state => state.resetPassword.emailForm,
    resetPwdDisplayShowEmailForm: state => state.resetPassword.display.showEmailForm,
    resetPwdDisplayShowEmailCtrls: state => state.resetPassword.display.showEmailCtrls,
    resetPwdMessageCode: state => state.resetPassword.message.code,
    resetPwdMessageContent: state => state.resetPassword.message.content,
    resetPwdMessageStatus: state => state.resetPassword.message.status,
    resetPwdIsSendEmailDisabled: state => state.resetPassword.isSendEmailDisabled,
    resetPwdIsSendEmailActive: state => state.resetPassword.isSendEmailActive,

    changePwdPasswordForm: state => state.changePassword.passwordForm,
    changePwdMessage: state => state.changePassword.message,
    ssoAccountName: state => state.ssoAccountName,
    getOidcBannerMessage: state => state.oidcPartnerDetails.bannerMessage,
    oidcPartnerDetails: state => state.oidcPartnerDetails || {},
    getOidcPartnerName: state => state.oidcPartnerDetails.partnerName,
    storeUrl: state => state.oidcPartnerDetails && state.oidcPartnerDetails.storeUrl
        ? state.oidcPartnerDetails.storeUrl : '/store',
    isInstitutionalLoginVisible(state, getters, rootState) {
        return rootState.feature.showAthensLink
            && rootState.app.product.countryCode !== 'CN'
            && !state.oidcPartnerDetails.partnerName;
    },
    oidcPartnerModalButton: state => {
        if (state.oidcPartnerDetails.modalConfig.registerOrSubscribeButton) {
            return state.oidcPartnerDetails.modalConfig.registerOrSubscribeButton;
        }
        return 'subscribe';
    },
    isOidcUser: state => state.oidcPartnerDetails.partnerName,
    marketingUrls: state => state.marketingUrls,
    expiredSiteName: state => state.expiredSite.expiredSiteName,
    isExpiredSite: state => state.expiredSite.isExpired,
    isMfa: state => state.isMfa
};

export const SET_FORM_CODE = 'SET_FORM_CODE';
export const SET_OTC_IS_VALID = 'SET_OTC_IS_VALID';
export const SET_OTC_VERIFICATION_CODE_MESSAGE = 'SET_OTC_VERIFICATION_CODE_MESSAGE';
export const SET_OTC_VALID_FOR_SECONDS_MESSAGE = 'SET_OTC_VALID_FOR_SECONDS_MESSAGE';
export const SET_OTC_ERROR_MESSAGE = 'SET_OTC_ERROR_MESSAGE';
export const SET_OTC_IS_ALLOW_OTC_GENERATION = 'SET_OTC_IS_ALLOW_OTC_GENERATION';
export const SET_OTC_IS_ALLOW_OTC_ENTRY = 'SET_OTC_IS_ALLOW_OTC_ENTRY';
export const SET_OTC_ONE_TIME_CODE = 'SET_OTC_ONE_TIME_CODE';
export const SET_OTC_TIMEOUT_INTERVAL = 'SET_OTC_TIMEOUT_INTERVAL';
export const SET_OTC_SHOW_SEND_NEW_CODE = 'SET_OTC_SHOW_SEND_NEW_CODE';

export const SET_LICENSE_MESSAGES = 'SET_LICENSE_MESSAGES';
export const SET_LICENSE_HTML = 'SET_LICENSE_HTML';
export const SET_LICENSE_SHOWN = 'SET_LICENSE_SHOWN';

export const SET_TRIAL_STATE_DAYS_LEFT = 'SET_TRIAL_STATE_DAYS_LEFT';
export const SET_TRIAL_STATE_IS_ENABLED = 'SET_TRIAL_STATE_IS_ENABLED';

export const SET_RESET_PWD_EMAIL_FORM = 'SET_RESET_PWD_EMAIL_FORM';
export const SET_RESET_PWD_DISPLAY_SHOW_EMAIL_FORM = 'SET_RESET_PWD_DISPLAY_SHOW_EMAIL_FORM';
export const SET_RESET_PWD_DISPLAY_SHOW_EMAIL_CTRLS = 'SET_RESET_PWD_DISPLAY_SHOW_EMAIL_CTRLS';
export const SET_RESET_PWD_MESSAGE_CODE = 'SET_RESET_PWD_MESSAGE_CODE';
export const SET_RESET_PWD_MESSAGE_CONTENT = 'SET_RESET_PWD_MESSAGE_CONTENT';
export const SET_RESET_PWD_MESSAGE_STATUS = 'SET_RESET_PWD_MESSAGE_STATUS';
export const SET_RESET_PWD_IS_SEND_EMAIL_DISABLED = 'SET_RESET_PWD_IS_SEND_EMAIL_DISABLED';
export const SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE = 'SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE';
export const RESET_EMAIL_FORM = 'RESET_EMAIL_FORM';

export const SET_CHANGE_PWD_PASSWORD_FORM = 'SET_CHANGE_PWD_PASSWORD_FORM';
export const SET_CHANGE_PWD_PASSWORD_APPGUID = 'SET_CHANGE_PWD_PASSWORD_APPGUID';
export const SET_CHANGE_PWD_PASSWORD_SOURCE = 'SET_CHANGE_PWD_PASSWORD_SOURCE';
export const SET_CHANGE_PWD_MESSAGE_SUCCESS = 'SET_CHANGE_PWD_MESSAGE_SUCCESS';
export const RESET_CHANGE_PASSWORD_FORM = 'RESET_CHANGE_PASSWORD_FORM';
export const SET_SSO_ACCOUNT_NAME = 'SET_SSO_ACCOUNT_NAME';
export const SET_ODIC_PARTNER_DETAILS = 'SET_ODIC_PARTNER_DETAILS';
export const SET_EXPIRED_SITE = 'SET_EXPIRED_SITE';
export const SET_IS_MFA = 'SET_IS_MFA';

export const mutations = {
    [SET_FORM_CODE](state, code) {
        state.form.code = code;
    },
    [SET_OTC_IS_VALID](state, isValid) {
        state.otc.isValid = isValid;
    },
    [SET_OTC_VERIFICATION_CODE_MESSAGE](state, verificationCodeMessage) {
        state.otc.verificationCodeMessage = verificationCodeMessage;
    },
    [SET_OTC_VALID_FOR_SECONDS_MESSAGE](state, validForSecondsMessage) {
        state.otc.validForSecondsMessage = validForSecondsMessage;
    },
    [SET_OTC_ERROR_MESSAGE](state, errorMessage) {
        state.otc.errorMessage = errorMessage;
    },
    [SET_OTC_IS_ALLOW_OTC_GENERATION](state, isAllowOtcGeneration) {
        state.otc.isAllowOtcGeneration = isAllowOtcGeneration;
    },
    [SET_OTC_IS_ALLOW_OTC_ENTRY](state, isAllowOtcEntry) {
        state.otc.isAllowOtcEntry = isAllowOtcEntry;
    },
    [SET_OTC_ONE_TIME_CODE](state, oneTimeCode) {
        state.otc.oneTimeCode = oneTimeCode;
    },
    [SET_OTC_TIMEOUT_INTERVAL](state, timeoutInterval) {
        state.otc.timeoutInterval = timeoutInterval;
    },
    [SET_OTC_SHOW_SEND_NEW_CODE](state, showSendNewCode) {
        state.otc.showSendNewCode = showSendNewCode;
    },
    [SET_LICENSE_MESSAGES](state, messages) {
        state.licenseAgreements.raw = messages.message;
        state.licenseAgreements.viewed = false;
    },
    [SET_LICENSE_HTML](state) {
        if (state.licenseAgreements.raw !== '') {
            state.licenseAgreements.html = state.licenseAgreements.raw;
        }
    },
    [SET_LICENSE_SHOWN](state) {
        state.licenseAgreements.viewed = true;
    },
    [SET_TRIAL_STATE_DAYS_LEFT](state, daysLeft) {
        state.trialState.daysLeft = daysLeft;
    },
    [SET_TRIAL_STATE_IS_ENABLED](state, isEnabled) {
        state.trialState.isEnabled = isEnabled;
    },
    [SET_RESET_PWD_EMAIL_FORM](state, email) {
        state.resetPassword.emailForm.email = email;
    },
    [SET_RESET_PWD_DISPLAY_SHOW_EMAIL_FORM](state, showEmailForm) {
        state.resetPassword.display.showEmailForm = showEmailForm;
    },
    [SET_RESET_PWD_DISPLAY_SHOW_EMAIL_CTRLS](state, showEmailCtrls) {
        state.resetPassword.display.showEmailCtrls = showEmailCtrls;
    },
    [SET_RESET_PWD_MESSAGE_CODE](state, code) {
        state.resetPassword.message.code = code;
    },
    [SET_RESET_PWD_MESSAGE_CONTENT](state, content) {
        state.resetPassword.message.content = content;
    },
    [SET_RESET_PWD_MESSAGE_STATUS](state, status) {
        state.resetPassword.message.status = status;
    },
    [SET_RESET_PWD_IS_SEND_EMAIL_DISABLED](state, isSendEmailDisabled) {
        state.resetPassword.isSendEmailDisabled = isSendEmailDisabled;
    },
    [SET_RESET_PWD_IS_SEND_EMAIL_ACTIVE](state, isSendEmailActive) {
        state.resetPassword.isSendEmailActive = isSendEmailActive;
    },
    [RESET_EMAIL_FORM](state) {
        const { emailForm, display, message } = initResetPasswordData();
        state.resetPassword.emailForm = emailForm;
        state.resetPassword.display = display;
        state.resetPassword.message = message;
    },
    [SET_CHANGE_PWD_PASSWORD_FORM](state, password) {
        state.changePassword.passwordForm.password = password;
    },
    [SET_CHANGE_PWD_PASSWORD_APPGUID](state, appguid) {
        state.changePassword.passwordForm.appguid = appguid;
    },
    [SET_CHANGE_PWD_PASSWORD_SOURCE](state, source) {
        state.changePassword.passwordForm.source = source;
    },
    [SET_CHANGE_PWD_MESSAGE_SUCCESS](state, success) {
        state.changePassword.message.success = success;
    },
    [RESET_CHANGE_PASSWORD_FORM](state) {
        const { passwordForm, message } = initChangePasswordData();
        state.changePassword.passwordForm = passwordForm;
        state.changePassword.message = message;
    },
    [SET_SSO_ACCOUNT_NAME](state, data) {
        if ('ssoAccountName' in data) {
            state.ssoAccountName = data.ssoAccountName;
        }
    },
    [SET_IS_MFA](state, val) {
        state.isMfa = val;
    },
    [SET_EXPIRED_SITE](state, data) {
        if ('expiredSite' in data) {
            state.expiredSite.isExpired = data.expiredSite;
            state.expiredSite.expiredSiteName = data.expiredSiteName;
        }
    },
    [SET_ODIC_PARTNER_DETAILS](state, data) {
        if (data && data.oidcPartnerDetails) {
            state.oidcPartnerDetails.bannerMessage = data.oidcPartnerDetails.bannerMessage;
            state.oidcPartnerDetails.partnerName = data.oidcPartnerDetails.partnerName;
            state.oidcPartnerDetails.storeUrl = data.oidcPartnerDetails.storeUrl;
            if (data.oidcPartnerDetails.modalConfig) {
                state.oidcPartnerDetails.modalConfig.registerOrSubscribeButton
                    = data.oidcPartnerDetails.modalConfig.registerOrSubscribeButton;
                state.oidcPartnerDetails.modalConfig.welcomeMessage
                    = data.oidcPartnerDetails.modalConfig.welcomeMessage;
                state.oidcPartnerDetails.modalConfig.welcomeMessageRegOrSubscribe
                    = data.oidcPartnerDetails.modalConfig.welcomeMessageRegOrSubscribe;
            }
            if (data.oidcPartnerDetails.partnerMarketingLinks) {
                state.marketingUrls.medProfessionalUrl
                    = data.oidcPartnerDetails.partnerMarketingLinks.medProfessionalUrl;
                state.marketingUrls.medTraineeUrl
                    = data.oidcPartnerDetails.partnerMarketingLinks.medTraineeUrl;
                state.marketingUrls.groupPracticeUrl
                    = data.oidcPartnerDetails.partnerMarketingLinks.groupPracticeUrl;
                state.marketingUrls.institutionUrl
                    = data.oidcPartnerDetails.partnerMarketingLinks.institutionsUrl;
            }
        }
    }
};

export const actions = {
    validateCode({ getters, dispatch }) {
        return utdRest('auth/validateCode', { code: getters.formCode })
            .then(data => dispatch('processValidation', data))
            .catch(error => dispatch('processFailedValidation', error));
    },
    initCode({ dispatch }) {
        return utdRest('auth/initCode', {})
            .then(data => dispatch('processCodeInfo', data))
            .catch(error => dispatch('processFailedValidation', error));
    },
    sendNewCode({ dispatch }) {
        return utdRest('auth/sendNewCode', {})
            .then(data => dispatch('processCodeInfo', data))
            .catch(error => dispatch('processFailedValidation', error));
    },
    processValidation({ commit, dispatch }, data) {
        dispatch('processCodeInfo', data);
        if (data.isValid) {
            dispatch('app/resetUI', { preventScrollTop: false }, { root: true });
            dispatch('resolveUserData', data);
            dispatch('footer/getFooter', '', { root: true });
            new ServerMessaging().popMessage();
        }
        commit(SET_FORM_CODE, '');
    },
    resolveUserData({ rootGetters }, data) {
        const utdCache = new UtdCache();
        if (data.isRedirect) {
            return;
        }

        let url = rootGetters['app/getDestinationUrl'];
        if (!url) {
            url = utdCache.getPersistent('utdDestinationUrl');
            if (url) {
                utdCache.removePersistent('utdDestinationUrl');
            }
        }

        rootGetters['app/router'].url(url || '/contents/search');
    },
    processCodeInfo({ commit }, data) {
        commit(SET_FORM_CODE, '');
        commit(SET_OTC_IS_VALID, data.isValid);
        commit(SET_OTC_VERIFICATION_CODE_MESSAGE, data.verificationCodeMessage);
        commit(SET_OTC_VALID_FOR_SECONDS_MESSAGE, data.validForSecondsMessage);
        commit(SET_OTC_ERROR_MESSAGE, data.errorMessage);
        commit(SET_OTC_IS_ALLOW_OTC_GENERATION, data.isAllowOtcGeneration);
        commit(SET_OTC_IS_ALLOW_OTC_ENTRY, data.isAllowOtcEntry);
        commit(SET_OTC_ONE_TIME_CODE, data.oneTimeCode);
        commit(SET_OTC_TIMEOUT_INTERVAL, data.timeoutInterval);
    },
    processFailedValidation({ rootGetters, dispatch }, error) {
        Logger.error(error);
        dispatch('user/clearUser', '', { root: true });
        rootGetters['app/router'].url('/contents/search');
    },
    sendResetRequestEmailAddress({ getters, commit, dispatch }) {
        return utdRest('auth/resetPassword', getters.resetPwdEmailForm)
            .then(data => {
                commit(SET_RESET_PWD_MESSAGE_CONTENT, data.value);
                commit(SET_RESET_PWD_MESSAGE_STATUS, 'Success');
                commit(SET_RESET_PWD_DISPLAY_SHOW_EMAIL_CTRLS, false);
            })
            .catch(error => dispatch('errorResetRequestEmailAddress', error));
    },
    errorResetRequestEmailAddress({ commit }, error) {
        const errorData = error.data;
        if (errorData && errorData.errors && errorData.errors.length) {
            commit(SET_RESET_PWD_MESSAGE_CONTENT, errorData.errors[0].message);
        }
        else {
            commit(SET_RESET_PWD_MESSAGE_CONTENT,
                `Error processing your request. ${i18next.t('ERRORS.CONTACT_ON_ERROR')}`
            );
        }
        if (error.status === 409) {
            commit(SET_RESET_PWD_DISPLAY_SHOW_EMAIL_FORM, false);
            commit(SET_RESET_PWD_DISPLAY_SHOW_EMAIL_CTRLS, false);
        }
    },
    setTrialData({ commit }, data) {
        commit(SET_TRIAL_STATE_DAYS_LEFT, WkceDates.getDaysLeft(data.expirationDateISO8601));
        commit(SET_TRIAL_STATE_IS_ENABLED, data.isEnabled);
    }
};

const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default auth;
