<template>
  <div class="ds1-utd-js2">
    <input ref="defaultFocus" class="visuallyHidden utd-modal-default-focus">
    <utd-notification
      class="utd-notification"
      tabindex="0"
      notification-mode="error">
      <template #title>
        <div class="notification-title">
          {{ errorTitle }}
        </div>
      </template>
      <div v-if="errorType === C_ACCOUNT.FORM_MESSAGING.INVALID_LOGIN">
        <ol id="invalidLoginMsg" class="notification-content">
          <li>Please check the spelling of your Username and Password.</li>
          <li>
            If you forgot your Username or Password:
            <a href="#" @click="$emit('login-form-forgot-credentials')">Click here</a>
          </li>
        </ol>
      </div>
      <div v-else-if="errorType === C_ACCOUNT.FORM_MESSAGING.MAXIMUM_ATTEMPTS">
        <ol id="maxAttemptsMsg" class="notification-content">
          <li>
            You have reached the maximum number of attempts for your Username and Password.
          </li>
          <li>
            Please exit or quit your browser and try again.
          </li>
        </ol>
      </div>
      <div v-else>
        <ol id="genericErrorMsg" class="notification-content">
          <li>We are sorry that there was an error in this process.</li>
          <li><utd-customer-support /></li>
          <li>To restart your sign-in process, please select:
            <a id="loginFormBackLink"
               :href="C_HTTP.DEFAULT_LOGIN"
               @click="$emit('login-form-sign-out')">Back</a>
          </li>
        </ol>
      </div>
    </utd-notification>
  </div>
</template>

<script>
import UtdCustomerSupport from '_acaSrc/components/shared/stdlib/UtdCustomerSupport.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import { C_ACCOUNT, C_HTTP } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdCustomerSupport,
        UtdNotification
    },
    data() {
        return {
            C_ACCOUNT,
            C_HTTP,
            errorTitle: '',
            errorType: ''
        };
    },
    computed: {
        ...mapGetters('login', [ 'message' ])
    },
    mounted() {
        this.setErrorNotification();
        this.$nextTick(() => {
            this.$refs.defaultFocus.focus();
        });
    },
    methods: {
        setErrorNotification() {
            const errorMessage = this.message.error;
            if (errorMessage === C_ACCOUNT.FORM_MESSAGING.MERGE_FAILURE_TYPE.INVALID_LOGIN) {
                this.errorType = C_ACCOUNT.FORM_MESSAGING.INVALID_LOGIN;
                this.errorTitle = this.$t('LOGIN.INCORRECT_LOGIN');
                return;
            }

            if (errorMessage
                === C_ACCOUNT.FORM_MESSAGING.MERGE_FAILURE_TYPE.MAXIMUM_ATTEMPTS) {
                this.errorType = C_ACCOUNT.FORM_MESSAGING.MAXIMUM_ATTEMPTS;
                this.errorTitle = this.$t('LOGIN.MAXIMUM_ATTEMPTS');
            }
            else {
                this.errorType = C_ACCOUNT.FORM_MESSAGING.GENERIC_ERROR;
                this.errorTitle = 'Error with adding Single Sign-On (SSO) to your UpToDate account';
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.notification-content {
  .ds1-pl-3();
}
</style>