<template>
  <div class="wk-field" :class="inputClasses" v-bind="$attrs">
    <div class="wk-field-header">
      <label class="wk-field-label"
             :for="$attrs['input-id']"
             v-text="$attrs.label" />
      <div v-if="hasAttr('required')" class="wk-field-required-indicator">
        <span class="wk-sr-only">Required field</span>
      </div>
      <div v-if="hasAttr('optional')" class="wk-field-tip">
        <span aria-hidden="true">Optional</span>
        <span class="wk-sr-only">Optional field</span>
      </div>
      <label class="wk-sr-only"
             v-text="$attrs.helper" />
    </div>
    <div class="wk-field-body">
      <div :class="['wk-field-select-container', open? 'open':'']">
        <select tabindex="0"
                :aria-label="`Select ${$attrs.label} option`"
                :value="chosenValue"
                :class="selectClasses"
                :disabled="isDisabled"
                :placeholder="placeholder"
                @focus="open=true"
                @blur="open=false"
                @change="handleOnSelectChange($event)">
          <option disabled value="">
            {{ placeholder }}
          </option>
          <option v-for="(option, idx) in options"
                  :key="option.key|| idx"
                  :value="useIndex ? idx : getOptionValue(option)"
                  v-text="option[labelProp]|| option.label" />
        </select>
      </div>
      <div class="wk-field-inlay">
        <span class="wk-field-icon wk-field-check-mark-icon">
          <span v-if="isSuccess"
                aria-hidden="true"
                class="success-state-icon wkce-icon" />
        </span>
      </div>
      <div v-if="isError && errorMessage"
           class="wk-field-error"
           v-text="errorMessage" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        selected: {
            type: Number,
            default: 0
        },
        options: {
            type: Array,
            default() {
                return [];
            }
        },
        inputSize: {
            type: String,
            default: 'small'
        },
        errorMessage: {
            type: String,
            default: ''
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isInline: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: [ String, Number, null ],
            default: null
        },
        valueProp: {
            type: [ String, Function, null ],
            default: 'value'
        },
        labelProp: {
            type: String,
            default: 'label'
        },
        placeholder: {
            type: String,
            default: 'Select an Option'
        },
        useIndex: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        selectClass: {
            type: String,
            default: ''
        }
    },
    emits: [ 'input', 'utd-select' ],
    data() {
        return {
            chosenValue: this.useIndex ? this.selected : this.value,
            open: false
        };
    },
    computed: {
        inputClasses() {
            return [
                this.$attrs['type-class'],
                { 'wk-field-errored': this.isError },
                { 'wk-field-success': this.isSuccess },
                { 'wk-field-is-disabled': this.isDisabled },
                { 'wk-field-is-inline': this.isInline },
                { 'wk-field-small': this.small },
                { 'wk-field-has-required-indicator': this.hasAttr('required') }
            ];
        },
        selectClasses() {
            return [
                'wk-field-select',
                this.selectClass
            ];
        }
    },
    watch: {
        value(newval) {
            this.chosenValue = newval;
        }
    },
    methods: {
        hasAttr(attr) {
            return this.$attrs.hasOwnProperty(attr);
        },
        handleOnSelectChange($event) {
            this.chosenValue = $event.currentTarget.value;
            this.$emit('input', $event.currentTarget.value);
            this.$emit('update:modelValue', $event.currentTarget.value);
            this.$emit('utd-select', parseInt(
                $event.currentTarget && $event.currentTarget.value, 10));
        },
        getOptionValue(option) {
            return typeof this.valueProp === 'function'
                ? this.valueProp(option)
                : option[this.valueProp];
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-js2 {
  .wk-field-select-container{
    box-sizing: border-box;
  }

  .wk-field-select-container::after {
    font-family: 'wkce-icons';
  }

  .wk-field-select-container.open::after {
    transform: rotate(180deg);
  }

  .wk-field-label {
    .ds1-utd-weight-600();
  }

  .wk-button {
    .ds1-pa-0();

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: @DS1-PDS-INPUT-SELECTED-BORDER;
    }
  }

  .wk-field-success {
    input {
      padding-right: 40px;
    }

    &.wk-field-small {
      input {
        padding-right: 30px;
      }
    }
  }

  .wk-field-is-inline {
    display: flex;
    align-items: center;

    .wk-field-header {
      .ds1-mr-1();
    }
  }

  .success-state-icon {
    .ds1-ph-1();

    &::after {
      content: '\E863';
      font-family: 'wk-icons-filled';
      color: @DS1-UTD-SUCCESS;
      font-size: 14px;
    }
  }
}
</style>
