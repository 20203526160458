<template>
  <utd-modal ref="utdModalInstance"
             :modal-classes="modalClasses"
             :close-on-overlay-click="closeOnOverlayClick"
             :close-on-escape="closeOnEscape"
             :autofocus="autofocus"
             :disable-portal="disablePortal"
             @utd-modal-overlay-click="$emit('closed')"
             @utd-modal-closing="close">
    <utd-dialog aria-role="alertdialog"
                :dialog-type="dialogType"
                :dialog-size="dialogSize"
                :dialog-classes="dialogClasses"
                :hide-header-close="hideHeaderClose"
                @closed="close">
      <template #header><slot name="header" /></template>
      <slot />
      <template #footer><slot name="footer" /></template>
    </utd-dialog>
  </utd-modal>
</template>

<script>

import UtdModal from './UtdModal.vue';
import UtdDialog from './UtdDialog.vue';

export default {
    components: {
        UtdModal,
        UtdDialog
    },
    props: {
        // Allows for users to override default modal styles
        modalClasses: {
            type: String,
            default: ''
        },
        // Allows for users to override default dialog styles
        dialogClasses: {
            type: String,
            default: ''
        },
        // See UtdModal.vue
        closeOnOverlayClick: {
            type: Boolean,
            default: true
        },
        // See UtdModal.vue
        closeOnEscape: {
            type: Boolean,
            default: true
        },
        // See UtdModal.vue
        autofocus: {
            type: Boolean,
            default: true
        },
        // See UtdDialog.vue
        dialogType: {
            type: String,
            default: ''
        },
        dialogSize: {
            type: String,
            default: ''
        },
        // See UtdDialog.vue
        hideHeaderClose: {
            type: Boolean,
            default: false
        },
        // See UtdModal.vue (advanced use only)
        disablePortal: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'closed' ],
    methods: {
        open() {
            this.$refs.utdModalInstance.open();
        },
        close(options = { doEmit: true }) {
            if (options.doEmit) {
                this.$emit('closed');
            }
            this.$refs.utdModalInstance.close();
        }
    }
};
</script>

<style lang="less">

.utd-modal-content .utd-dialog-frame {
  width: auto;
}

</style>