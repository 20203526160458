<template>
  <div class="search-facet wk-js2 ds1-utd-js2">
    <utd-tabs :tab-style="'wk-tabs-default'"
              tabbar-label="Search Result Category"
              class="wk-js2"
              enable-carousel
              ignore-carousel-click
              :active-tab="searchParamsPriorityInteger"
              @selectedTab="onSwitchTab">
      <utd-tab v-for="tab in searchTabs" :key="tab" :title="$t(`SEARCH.${tab}`)" />
    </utd-tabs>
    <div v-show="showExpandCollapse"
         v-if="searchParamsPriorityInteger !== C_SEARCH.PRIORITY.GRAPHICS && isDesktopView"
         class="resultControls wk-tabs ds1-pr-0 ds1-float-right"
         role="list">
      <button aria-expanded="!searchStateIsCollapse"
              class="wk-tab"
              @keypress.prevent="$event.which === ENTER_KEY && toggleExpandCollapse()"
              @click="toggleExpandCollapse">
        <a v-show="!searchStateIsCollapse"
           aria-label="collapse-search-results-details"
           aria-hidden="searchStateIsCollapse">{{ $t('SEARCH.COLLAPSE_RESULTS') }}</a>
        <a v-show="searchStateIsCollapse"
           aria-label="expand-search-results-details"
           aria-hidden="!searchStateIsCollapse">{{ $t('SEARCH.EXPAND_RESULTS') }}</a>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    C_EVENTS,
    C_KEYCODES,
    C_SEARCH
} from '_acaSrc/utility/constants';
import {
    SET_SEARCH_PARAMS_SOURCE,
    SET_SEARCH_PARAMS_SP,
    SET_SEARCH_PARAMS_TEXT,
    SET_SEARCH_PARAMS_TYPE
} from '_acaSrc/store/search.store';
import { retainElementFocus, decodeHtml } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';
import { sterilizeString } from '_acaSrc/utility/String';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';

export default {
    components: {
        UtdTabs,
        UtdTab
    },
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY,
            C_SEARCH
        };
    },
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('search', [
            'originalSearchText',
            'searchParams',
            'searchParamsSearchText',
            'searchParamsPriorityInteger',
            'isShowExpandCollapse',
            'isCollapse',
            'searchFor'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('user', [ 'isCustomer' ]),
        searchStateIsCollapse() {
            return this.searchParams && this.isCollapse;
        },
        showExpandCollapse() {
            return this.isCustomer
                && this.searchParams
                && this.isShowExpandCollapse;
        },
        facetAriaLabel() {
            return `Tabbed search results for ${this.searchFor}`;
        },
        searchTabs() {
            return C_SEARCH.PRIORITY ? Object.keys(C_SEARCH.PRIORITY) : [];
        }
    },
    methods: {
        ...mapActions('app', [ 'broadcast' ]),
        ...mapActions('search', [ 'toggleResults' ]),
        ...mapMutations('search', [
            SET_SEARCH_PARAMS_SP,
            SET_SEARCH_PARAMS_SOURCE,
            SET_SEARCH_PARAMS_TYPE,
            SET_SEARCH_PARAMS_TEXT
        ]),
        // Retrieves the search results based on the given priority aka search facet
        setPriority(priority) {
            if (priority !== this.searchParamsPriorityInteger) {
                new UtdCache().setSession('sp', priority);
                this[SET_SEARCH_PARAMS_SP](priority);
                this[SET_SEARCH_PARAMS_SOURCE](C_SEARCH.SOURCE.USER_PREF);

                if (priority === C_SEARCH.PRIORITY.GRAPHICS) {
                    this[SET_SEARCH_PARAMS_TYPE](C_SEARCH.SEARCH_TYPES.GRAPHICS);
                }
                else {
                    this[SET_SEARCH_PARAMS_TYPE](C_SEARCH.SEARCH_TYPES.PLAIN_TEXT);
                }
                this[SET_SEARCH_PARAMS_TEXT](sterilizeString(this.originalSearchText));
                if (this.router.search().sp !== priority) {
                    this.router.go('search', this.searchParams);
                }
                this[SET_SEARCH_PARAMS_TEXT](decodeHtml(
                    decodeURIComponent(encodeURIComponent(this.searchParamsSearchText))));
                retainElementFocus('#searchresults dl div button.wk-is-active');
            }
        },
        toggleExpandCollapse() {
            this.clearResultHovers();
            this.checkFooter();
            this.toggleResults();
            retainElementFocus('.resultControls button');
        },
        clearResultHovers() {
            const hovered = document.getElementsByClassName('hovered');
            for (let i = hovered.length; i > 0; i--) {
                hovered[i - 1].classList.remove('hovered');
            }
        },
        checkFooter() {
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true,
                fromLocation: 'search.controller: checkFooter'
            });
        },
        onSwitchTab(value, event) {
            if (event) {
                event.preventDefault();
            }

            if (!event) {
                return;
            }

            this.setPriority(`${value}`);
        }
    }
};

</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@DS1-FACETS-HOVER: @WKCE-BLUE-SHADE1;
@DS1-FACETS-ACTIVE: @WKCE-GRAY-SHADE1;

.search-facet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 0;

  :deep(.utd-tabs__container) {
    width: 100%;
    min-width: 0;

    .tab-bar .chevron {
      padding: 0;
      background-color: @DS1-UTD-GRAY-BG-COLOR;
    }
  }
}

.hl7-search-facets {
  visibility: hidden;
}

.wk-tabs {
  min-width: auto;
  margin: 0;
  display: block;
  text-align: center;
  position: relative;
}

.wk-js2 .wk-tabs .wk-tab {
  padding: 10px 0;
  margin: 0 12px;
  line-height: 18px;
  font-weight: 400;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border-bottom: 2px solid @DS1-FACETS-HOVER;
  }

  &:focus {
    border: 0;
  }

  &.wk-is-active {
    color: @DS1-FACETS-ACTIVE;
    border-bottom: 2px solid @DS1-FACETS-ACTIVE;
  }
}

.wk-js2 .resultControls .wk-tab {
  padding: 11px 0;

  &:hover {
    border: 0;
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .wk-js2 .wk-tabs .wk-tab {
      margin: 0 16px;

      &:first-child {
        margin-left: 8px;
      }
    }

    .wk-tabs {
      display: inline;
    }
  }
}
</style>
