<template>
  <li :class="{ 'drawer-active': isDrawerVisible }">
    <div ref="toolbarMenuContainer"
         class="toolbar-menu__container">
      <div ref="toolbarMenuLabel"
           class="toolbar-menu__item"
           @click="toggleDrawer()">
        <slot name="menuActivatorLabel" />
      </div>
      <div v-show="isDrawerVisible"
           ref="toolbarMenuDrawer"
           class="toolbar-menu__drawer">
        <slot />
      </div>
    </div>
  </li>
</template>

<script>
import { createPositionManager } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';

const RATING_POSITION_DELAY_MS = 100;

export default {
    data() {
        return {
            isDrawerVisible: false
        };
    },
    mounted() {
        this.drawerPositionManager
            = createPositionManager(this.$refs.toolbarMenuContainer, this.$refs.toolbarMenuDrawer, {
                applyViewportGutter: true
            });
        new PubSub().subscribe('wkutd.close-toolbar-menus', this.closeDrawer);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.close-toolbar-menus', this.closeDrawer);
        this.isDrawerVisible && this.utdUnlockScroll();
    },
    methods: {
        toggleDrawer() {
            this.isDrawerVisible = !this.isDrawerVisible;
            if (this.isDrawerVisible) {
                new PubSub().publish('wkutd.close-toolbar-menus', this.uuid);
                this.$nextTick(() => {
                    this.utdLockScroll();
                    // Done in timeout so utdLockScroll can hide scrollbar first
                    setTimeout(() => {
                        this.drawerPositionManager.update();
                    }, RATING_POSITION_DELAY_MS);
                });
            }
            else {
                this.utdUnlockScroll();
            }
        },
        closeDrawer(exceptMenuUUID) {
            const isNotThisMenu = !this.uuid || !exceptMenuUUID || exceptMenuUUID !== this.uuid;
            if (isNotThisMenu) {
                this.isDrawerVisible = false;
                this.utdUnlockScroll();
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.drawer-active {
    background-color: @DS1-MOBILE-TOOLBAR-ACTIVE-BG-COLOR;

    .toolbar-menu__item {
        color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    }
}

.toolbar-menu__item {
    color: @DS1-MOBILE-TOOLBAR-BASE-LINK-COLOR;

    [class*='wkce-icon-'] {
        margin-left: 0;
        margin-right: 0;

        &::before {
            margin-right: 5px;
        }
    }
}

.toolbar-menu__drawer {
    margin-top: 2px;
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border: @DS1-LEXI-TABLE-BORDER;
    border-top: 9px solid @DS1-MOBILE-TOOLBAR-ACTIVE-BG-COLOR;
    z-index: @ZINDEX-MOBILE-TOOLBAR-MENU;
}
</style>