import UtdCache from './UtdCache';

let _utdQueuedCacheInstance = null;

export const UTD_SPA_CONTENT_CACHE_DEFAULT_LIMIT = 15;

export default class UtdQueuedCache {
    constructor() {
        if (_utdQueuedCacheInstance) {
            return _utdQueuedCacheInstance;
        }

        _utdQueuedCacheInstance = this;

        // Each queue is stored as a JSON object,
        // indexed by the queue name, with the
        // following members:
        // size: max length of queue
        // data: array holding queue entries
        this.queues = [];
    }

    // Private method which attempts to retrieve value from existing queue
    getValue(queueName, key) {
        let val = null;
        const queue = this.queues[queueName];
        if (typeof queue !== 'undefined') {
            if (typeof queue.data !== 'undefined') {
                for (let i = 0; i < queue.data.length; i++) {
                    if (queue.data[i].key === key) {
                        val = queue.data[i].val;
                        break;
                    }
                }
            }
        }
        return val;
    }

    init(queueName, size) {
        // Do not modify existing queues
        const queue = this.queues[queueName];
        if (typeof queue === 'undefined') {
            this.queues[queueName] = { size, data: [] };
        }
    }

    // Method which attempts to restore any
    // persistent values stored for passed queueName
    restorePersistent(queueName) {
        const queue = this.queues[queueName];
        if (typeof queue !== 'undefined') {
            const cd = new UtdCache().getPersistent(queueName);
            if (cd) {
                this.queues[queueName].data = cd;
            }
        }
    }

    // Public method which attempts to retrieve queued cache value
    // If found will return asynchronously.
    getDeferred(queueName, key) {
        const data = this.getValue(queueName, key);
        if (data) {
            return new Promise(resolve => {
                resolve(data);
            });
        }
        return null;
    }

    // eslint-disable-next-line complexity
    setPersistent(queueName, key, val, version, expires) {
        expires = expires || 1;
        if (!queueName || !key) {
            return;
        }

        const queue = this.queues[queueName];
        if (!queue || !queue.data) {
            return;
        }

        const isKeyExist = queue.data.some(item => {
            return item.key === key;
        });
        if (isKeyExist) {
            // Key already exist in queue, then return
            return;
        }

        // If cache bucket is already at maximum, purge one before adding another
        if (queue.data.length >= queue.size) {
            queue.data.shift();
        }
        queue.data.push({ key, val });

        const cached = JSON.stringify(queue.data);
        new UtdCache().setPersistent(queueName, cached, expires, version);
    }

    delete(queueName) {
        const queue = this.queues[queueName];
        if (typeof queue !== 'undefined') {
            delete this.queues[queueName];
        }
        new UtdCache().removePersistent(queueName);
    }

    /**
     * Destroy - This function is to reset the instance of utdQueuedCacheInstance in the unit test
     */
    _destroy() {
        _utdQueuedCacheInstance = null;
    }
}