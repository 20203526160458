import Vuex from 'vuex';

import topic from './topic/topic.store';
import graphic from './graphic.store';
import qap from './qap.store';
import app from './app.store';
import inlineCme from './inlineCme.store';
import login from './login.store';
import auth from './auth.store';
import toc from './toc.store';
import profile from './profile.store';
import dxy from './dxy.store';
import share from './share.store';
import search from './search.store';
import formulary from './formulary.store';
import pendo from './pendo.store';
import footer from './footer.store';
import account from './account.store';
import cme from './cme.store';
import device from './device.store';
import feature from './feature.store';
import user from './user.store';
import error from './error.store';
import ai from './ai.store';

export default new Vuex.Store({
    modules: {
        topic,
        graphic,
        inlineCme,
        qap,
        login,
        auth,
        app,
        toc,
        profile,
        dxy,
        share,
        search,
        formulary,
        pendo,
        footer,
        account,
        cme,
        device,
        feature,
        user,
        error,
        ai
    }
});