<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="utd-autocomplete ds1-utd-font">
    <ul id="search-listbox"
        class="utd-autocomplete-ul"
        role="listbox"
        aria-labelledby="search-uptodate">
      <template v-for="item in list">
        <slot />
        <li v-if="item.type === 'header'"
            :id="item.elementId"
            :key="item.label"
            :class="item.classes"
            class="utd-autocomplete-li ac-header">
          <span v-text="item.label" />
        </li>
        <li v-if="item.type === 'default'"
            :id="item.elementId"
            :key="item.label"
            :class="itemClasses(item)"
            class="utd-autocomplete-li ac-default">
          <span v-text="item.label" />
        </li>
        <li v-if="item.type === 'link'"
            :id="item.elementId"
            :key="item.uniqueId"
            :class="itemClasses(item)"
            class="utd-autocomplete-li needsclick ac-navable"
            role="option"
            :aria-selected="isActive(item)">
          <a v-utd-new-tab="item.isNewTab"
             :href="item.href"
             :class="item.anchorClasses"
             class="ds1-utd-weight-bold"
             @mousedown="onClicked($event, item)"
             @click="onClicked($event, item)"
             v-html="getBoldfacedLabel(item.label)" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';

export default {
    directives: {
        UtdNewTab
    },
    props: {
        list: Array,
        activeIndex: Number,
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    computed: {
        navableItems() {
            return this.list.filter(i => i.type === 'link');
        },
        activeItemId() {
            if (!(Array.isArray(this.navableItems)
                && this.activeIndex > -1
                && this.activeIndex < this.navableItems.length)) {
                return -1;
            }
            return this.navableItems[this.activeIndex].uniqueId;
        }
    },
    methods: {
        onClicked(evt, item) {
            item.onClick && item.onClick(evt);
        },
        isActive(item) {
            return item.uniqueId === this.activeItemId;
        },
        itemClasses(item) {
            return [ item.classes, { active: this.isActive(item) }];
        },
        getBoldfacedLabel(label) {
            if (!label) {
                return '';
            }
            if (!this.options.autoCompleteTerm || !this.options.autoCompleteTerm.trim()) {
                return label;
            }
            const replaceBadRegex = this.options.autoCompleteTerm.trim()
                .replace(/\\/g, '')
                .replace(/\[/g, '\\[')
                .replace(/\(/g, '\\(')
                .replace(/\)/g, '\\)')
                .replace(/\+/g, '\\+')
                .replace(/\*/g, '\\*');
            const regExp = new RegExp(`(${replaceBadRegex})`, 'i');
            return label.replace(regExp,
                '<span class="ds1-utd-weight-normal">$1</span>');
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) '~_acaAssets/global/global.less';

.utd-autocomplete-ul {
  .reset-ul();
  .ds1-pb-compact();
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  box-shadow: @DS1-BOX-SHADOW-POPOUT;
}

.utd-autocomplete-li {
  .reset-li();
  position: relative;

  a {
    .ds1-ph-2();
    .ds1-utd-size-2();
    .ds1-pv-var(9.5px, 9.5px);
    cursor: pointer;

    // Click area things (we want entire row to be clickable)
    .ds1-mr-0();
    .ds1-ml-0();
    width: 100%;
    box-sizing: border-box;
    display: inline-block;

    &::before {
      font-size: 15px;
      vertical-align: -15%;
      display: inline-block;
      text-decoration: underline;
    }

    &::before,
    &:hover,
    &:hover::before {
      text-decoration: none;
    }
  }
}

.wkce-icon-search {
  text-align: left;

  &::before {
    font-weight: normal;
    position: absolute;
    line-height: 0;
    left: 16px;
    top: 22.5px;
  }
}

.ac-header {
  .ds1-ph-2();
  .ds1-utd-size-1();
  .ds1-utd-weight-600();
  padding-top: 12px;
  padding-bottom: 2px;
  color: @DS1-UTD-SUB-TEXT-COLOR;
  margin-right: 0;
  margin-left: 0;

  &:not(:first-child) {
    .ds1-mt-compact();
    border-top: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;
  }
}

.ac-default {
  .ds1-utd-size-2();
  .ds1-ph-2();
  padding-top: 10px;
  padding-bottom: 2px;
}

.ac-italic {
  font-style: italic;
}

.route-link {
  .ds1-pl-4();
}

.ac-search-suggestion > a {
  .ds1-pl-5();
  color: @WKCE-GRAY-SHADE2;
}

.ac-navable:hover,
.ac-navable.active {
  background-color: @DS1-UTD-AUTOCOMPLETE-ACTIVE-BG-COLOR;
}

@media only screen and (min-width: 768px) {
  .utd-autocomplete-li {
    a {
      .ds1-pv-var(5.5px, 5.5px);
    }
  }

  .wkce-icon-search::before {
    top: 18.5px;
  }

  .ac-search-suggestion > a {
    .ds1-pl-5();
  }
}

// Specificity shims (remove after searchbar.less is redone)
.utdWkHeader .utd-search .search-bar-ac-unit .autocomplete ul.utd-listView li.ac-header {
  padding: 12px 16px 6px;
}
</style>
