<template>
  <div class="qap-panel qap-routed-drug ds1-qap-container wk-js2">
    <div class="qap-header">
      <div class="qap-title wkce-icon-medicine">{{ result.title }}</div>
    </div>
    <div v-for="(content, contentIdx) in result.qapContents"
         :key="contentIdx"
         class="qap-content">
      <section v-for="(section, sectionIdx) in content.sections"
               :key="sectionIdx"
               class="qap-section ds1-pt-2">
        <div v-if="isLinksSection(section)"
             class="qap-section-container">
          <dl class="qap-list">
            <dt class="qap-section-title"
                v-text="section.title" />
            <dd v-for="(item, itemIdx) in section.items"
                :key="itemIdx"
                class="qap-list-item">
              <a class="qap-link"
                 :class="item.class"
                 :href="setUrlSource(item.url)"
                 v-text="item.label" />
            </dd>
          </dl>
        </div>
        <qap-drug-interactions v-if="isDrugInteractionSection(section)"
                               :result="section"
                               :source="source" />
      </section>
    </div>
  </div>
</template>

<script>
import QapDrugInteractions from './QapDrugInteractions.vue';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { C_QAP } from '_acaSrc/utility/constants';

export default {
    components: {
        QapDrugInteractions
    },
    props: {
        result: Object,
        source: String
    },
    methods: {
        isDrugInteractionSection(section) {
            return section.type === C_QAP.SECTION.DRUG_INTERACTIONS;
        },
        isLinksSection(section) {
            return section.type === C_QAP.SECTION.LINKS;
        },
        setUrlSource(url) {
            return setQueryParamValue(url, 'source', this.source);
        }
    }
};
</script>

<style lang="less" scoped>
@import "../vue-qap.less";

@ROUTED-BULLET-COLOR: @WKCE-GRAY-TINT4;

.wkce-icon-medicine::before {
  .ds1-mr-1();
  font-size: 16px;
  position: relative;
  top: 2px;
}

.qap-section-container {
  .ds1-ph-2();

  .qap-list-item {
    .ds1-mt-0();
    display: inline-block;
    line-height: 1;

    &:not(:nth-child(2)) {
      .ds1-pl-compact();

      &:before {
        .ds1-pr-compact();
        display: inline-block;
        content: @DS1-BULLET-CHAR;
        color: @ROUTED-BULLET-COLOR;
      }
    }

    .qap-link {
      width: auto;
    }
  }
}
</style>