<!-- This file will go away after parent file where it is being referred is migrated to Vue -->

<template>
  <utd-notification v-if="notificationContent" :notification-mode="notificationMode">
    <template v-slot:title>{{ notificationTitle }}</template>
    <template v-slot><span v-html="notificationContent" /></template>
  </utd-notification>
</template>

<script>
import utdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';

export default {
    components: {
        utdNotification
    },
    props: {
        notificationMode: {
            type: String,
            default: 'info'
        },
        notificationTitle: {
            type: String,
            default: ''
        },
        notificationContent: {
            type: String,
            default: ''
        }
    }
};
</script>
