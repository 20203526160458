import { Comment, Fragment, Text } from 'vue';

// Source: https://gist.github.com/marina-mosti/99b08783b161fa4ba21ebd2ec664fa14#file-useemptyslotcheck-js

function vNodeIsEmpty(vnodes) {
    return vnodes.every(node => {
        if (node.type === Comment) {
            return true;
        }
        if (node.type === Text && !node.children.trim()) {
            return true;
        }
        if (
            node.type === Fragment &&
      vNodeIsEmpty(node.children)
        ) {
            return true;
        }

        return false;
    });
}

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                isSlotEmpty(slot) {
                    if (!slot) {
                        return true;
                    }

                    // if we get a slot that is not a function, we're in vue 2 and there
                    // is content, so it's not empty
                    if (typeof slot !== 'function') {
                        return false;
                    }

                    return vNodeIsEmpty(slot());
                }
            }
        });
    }
};