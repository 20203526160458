import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';
import i18next from 'i18next';

export const getInitialState = () => {
    return {
        shareForm: {
            recipients: [],
            fromEmail: '',
            fromName: '',
            message: '',
            sendConfirmCopy: false,
            includeGuestPass: false,
            topicId: '',
            imageIds: [],
            languageCode: ''
        },
        shareMessage: {
            error: '',
            guestPass: '',
            toEmailStr: ''
        }
    };
};

const state = {
    ...getInitialState(),
    showShareForm: false,
    showConfirmationModal: false,
    formFields: {
        focused: undefined
    },
    shared: {
        contents: []
    },
    source: '',
    confirmationMessage: ''
};

export const getters = {
    shareConfirmationModal: state => state.showConfirmationModal,
    shareFormVisible: state => state.showShareForm,
    shareFormRecipients: state => state.shareForm.recipients,
    shareFormFromEmail: state => state.shareForm.fromEmail,
    shareFormFromName: state => state.shareForm.fromName,
    shareFormMessage: state => state.shareForm.message,
    shareFormSendConfirmCopy: state => state.shareForm.sendConfirmCopy,
    shareFormIncludeGuestPass: state => state.shareForm.includeGuestPass,
    shareFormTopicId: state => state.shareForm.topicId,
    shareFormImageIds: state => state.shareForm.imageIds,
    shareFormlanguageCode: state => state.shareForm.languageCode,
    shareMessageError: state => state.shareMessage.error,
    shareMessageGuestPass: state => state.shareMessage.guestPass,
    shareMessageToEmailStr: state => state.shareMessage.toEmailStr,
    shareFormFieldsFocused: state => state.formFields.focused,
    sharedContents: state => state.shared.contents,
    shareSource: state => state.source,
    shareConfirmationMessage: state => state.confirmationMessage
};

export const SET_SHARE_CONFIRMATION_MODAL_VISIBILITY = 'SET_SHARE_CONFIRMATION_MODAL_VISIBILITY';
export const SET_SHARE_FORM_VISIBILITY = 'SET_SHARE_FORM_VISIBILITY';
export const SET_SHARE_FORM_RECIPIENTS_LENGTH = 'SET_SHARE_FORM_RECIPIENTS_LENGTH';
export const SET_SHARE_FORM_RECIPIENTS = 'SET_SHARE_FORM_RECIPIENTS';
export const SET_SHARE_FORM_EMAIL = 'SET_SHARE_FORM_EMAIL';
export const SET_SHARE_FORM_SEND_CONFIRM_COPY = 'SET_SHARE_FORM_SEND_CONFIRM_COPY';
export const SET_SHARE_FORM_NAME = 'SET_SHARE_FORM_NAME';
export const SET_SHARE_FORM_MESSAGE = 'SET_SHARE_FORM_MESSAGE';
export const SET_SHARE_FORM_LANGUAGE_CODE = 'SET_SHARE_FORM_LANGUAGE_CODE';
export const SET_SHARE_FORM_IMAGE_IDS = 'SET_SHARE_FORM_IMAGE_IDS';
export const SET_SHARE_MESSAGE_TO_EMAIL_STR = 'SET_SHARE_MESSAGE_TO_EMAIL_STR';
export const SET_SHARE_FORM_TOPIC_ID = 'SET_SHARE_FORM_TOPIC_ID';
export const SET_SHARE_FORM_INCLUDE_GUEST_PASS = 'SET_SHARE_FORM_INCLUDE_GUEST_PASS';
export const SET_SHARE_MESSAGE_ERROR = 'SET_SHARE_MESSAGE_ERROR';
export const SET_SHARE_MESSAGE_GUESTPASS = 'SET_SHARE_MESSAGE_GUESTPASS';
export const SET_SHARED_CONTENTS = 'SET_SHARED_CONTENTS';
export const SET_SHARE_SOURCE = 'SET_SHARE_SOURCE';
export const RESET = 'RESET';

export const mutations = {
    [SET_SHARE_CONFIRMATION_MODAL_VISIBILITY](state, visible) {
        state.showConfirmationModal = visible;
    },
    [SET_SHARE_FORM_VISIBILITY](state, visible) {
        state.showShareForm = visible;
    },
    [SET_SHARE_FORM_RECIPIENTS_LENGTH](state, length) {
        state.shareForm.recipients.length = length;
    },
    [SET_SHARE_FORM_RECIPIENTS](state, email) {
        state.shareForm.recipients.push(email);
    },
    [SET_SHARE_FORM_EMAIL](state, email) {
        state.shareForm.fromEmail = email;
    },
    [SET_SHARE_FORM_SEND_CONFIRM_COPY](state, sendConfirmCopy) {
        state.shareForm.sendConfirmCopy = sendConfirmCopy;
    },
    [SET_SHARE_FORM_MESSAGE](state, message) {
        state.shareForm.message = message;
    },
    [SET_SHARE_FORM_NAME](state, name) {
        state.shareForm.fromName = name;
    },
    [SET_SHARE_FORM_LANGUAGE_CODE](state, languageCode) {
        state.shareForm.languageCode = languageCode;
    },
    [SET_SHARE_FORM_IMAGE_IDS](state, imageId) {
        if (imageId.length > 0) {
            state.shareForm.imageIds.push(imageId);
        }
        else {
            state.shareForm.imageIds = imageId;
        }
    },
    [SET_SHARE_FORM_TOPIC_ID](state, topicId) {
        state.shareForm.topicId = topicId;
    },
    [SET_SHARE_FORM_INCLUDE_GUEST_PASS](state, includeGuestPass) {
        state.shareForm.includeGuestPass = includeGuestPass;
    },
    [SET_SHARE_MESSAGE_ERROR](state, error) {
        state.shareMessage.error = error;
    },
    [SET_SHARE_MESSAGE_GUESTPASS](state, guestPass) {
        state.shareMessage.guestPass = guestPass;
        state.confirmationMessage = guestPass;
    },
    [SET_SHARE_MESSAGE_TO_EMAIL_STR](state, toEmailStr) {
        state.shareMessage.toEmailStr = toEmailStr;
    },
    [SET_SHARE_SOURCE](state, src) {
        state.source = src;
    },
    [SET_SHARED_CONTENTS](state, content) {
        if (content.length > 0) {
            content.forEach(item => {
                state.shared.contents.push(item);
            });
        }
        else {
            state.shared.contents = content;
        }
    },
    [RESET](state) {
        Object.assign(state, getInitialState());
    }
};

export const actions = {
    sendShareForm: ({ rootGetters, commit, getters }) => {
        const params = state.shareForm;
        return utdRest('share/content/json', params)
            .then(res => resolveShareForm(res, { rootGetters, commit, getters }))
            .catch(err => resolveError(err, { rootGetters, commit }));
    },

    showShareForm: ({ commit, dispatch }, evt) => {
        commit(SET_SHARE_FORM_VISIBILITY, true);
        dispatch('logShareEvent', evt);
    },

    logShareEvent: ({ getters }, evt) => {
        if (getters.shareFormVisible) {
            try {
                if (evt) {
                    if (evt && evt.currentTarget.id !== null) {
                        new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                            uiElementName: evt.currentTarget.id
                        });
                    }
                    else {
                        new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                            uiElementName: 'responsiveUnknownShareLink'
                        });
                    }
                }
                else {
                    new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                        uiElementName: 'shareLink'
                    });
                }
            }
            catch (e) {
                Logger.warn(`Exception trying to log event exception:${e}`);
            }
        }
    }
};

const resolveShareForm = (data, { commit, getters }) => {
    if (data.guestPassCreationState !== null && data.guestPassCreationState !== undefined) {
        commit(SET_SHARE_MESSAGE_GUESTPASS, i18next.t(`SHARE.${data.guestPassCreationState}`));
    }

    // Needed to reset the shared content, delete cached shared content
    commit(SET_SHARED_CONTENTS, []);

    // For the purpose of testing
    if (data.contentShareResults) {
        const contentArray = [];
        data.contentShareResults.forEach(function(content) {
            contentArray.push({ guid: content.guid, url: content.url });
        });
        commit(SET_SHARED_CONTENTS, contentArray);
    }


    if (getters.shareMessageError === '') {
        commit(SET_SHARE_FORM_VISIBILITY, false);
    }
};

const resolveError = (error, { commit }) => {
    commit(SET_SHARE_MESSAGE_ERROR, i18next.t('ERROR.SYSTEM_ERROR'));

    // TODO add some error handling for status 400 (validation) and 403 (permission denied).
    // If either of those don't log a message
    Logger.warn(
        `Error trying to call error: ${error.errors} status:${error.status}`);
};

const share = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default share;

export {
    resolveShareForm as _resolveShareForm,
    resolveError as _resolveError
};
