<template>
  <transition name="slide">
    <div v-if="isSidebarOpen"
         :class="modalClasses"
         class="utd-sidebar-container">
      <div class="utd-sidebar-header">
        <slot name="header" />
        <button v-if="!hideHeaderClose"
                href="#"
                class="sidebar-close-button wkce-icon-filled-close"
                tabindex="0"
                role="button"
                aria-label="close"
                @click="close()" />
      </div>
      <div class="utd-sidebar-content">
        <slot name="sidebarContent" />
      </div>
    </div>
  </transition>
</template>

<script>
import { setQueryParamValueNoReload } from '_acaSrc/utility/Browsers';
import { C_KEYCODES, C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import { getWindow } from '_acaSrc/utility/DOM';
import { mapGetters } from 'vuex';

const { ESCAPE_KEYCODE } = C_KEYCODES;

export default {
    props: {
        modalClasses: {
            type: String,
            default: ''
        },
        closeOnEscape: {
            type: Boolean,
            default: true
        },
        isDialogOpen: {
            type: Boolean,
            default: false
        },
        hideHeaderClose: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'closed' ],
    data() {
        return {
            isSidebarOpen: false,
            windowListeners: []
        };
    },
    computed: {
        ...mapGetters('app', [ 'router' ])
    },
    beforeUnmount() {
        this.removeListeners();
    },
    methods: {
        removeListeners() {
            for (const { eventType, listener } of this.windowListeners) {
                getWindow().removeEventListener(eventType, listener);
            }
            this.windowListeners = [];
        },
        hasKeyEvents() {
            return this.closeOnEscape;
        },
        onKeyUp(e) {
            const isEscapeKey = e.code === 'Escape' || e.keyCode === ESCAPE_KEYCODE;
            if (isEscapeKey && this.closeOnEscape && !this.isDialogOpen) {
                this.close();
            }
        },
        registerWindowListener(eventType, listener) {
            const boundListener = listener.bind(this);
            getWindow().addEventListener(eventType, boundListener);
            this.windowListeners.push({ eventType, listener: boundListener });
        },
        open() {
            this.isSidebarOpen = true;
            return new Promise(resolve => {
                this.$nextTick(() => {
                    if (this.hasKeyEvents()) {
                        this.registerWindowListener('keyup', this.onKeyUp);
                    }
                    resolve();
                });
            });
        },
        close(options = { doEmit: true, doRemoveQueryParam: true }) {
            if (options.doEmit) {
                this.$emit('closed');
            }
            if (options.doRemoveQueryParam) {
                setQueryParamValueNoReload(C_MY_ACCOUNT.MY_ACCOUNT_QUERY_PARAM);
            }
            this.isSidebarOpen = false;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-DIALOG-TRANSITION-DURATION: @DS1-UTD-TRANSITION-DURATION;
@UTD-DIALOG-TRANSPARENCY: @DS1-UTD-TRANSPARENCY;
@UTD-MODAL-CONTENT-BG-COLOR: @DS1-UTD-PRIMARY-BG-COLOR;
@UTD-DIALOG-XCLOSE-COLOR: @DS1-UTD-CLOSE-BUTTON-ICON;

.wkce-icon-filled-close {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;

  &::before {
    color: @UTD-DIALOG-XCLOSE-COLOR;
    font-size: 16px;
  }
}

.utd-sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: @ZINDEX-MODAL-OVERLAY;
}

.utd-sidebar-header {
  display: flex;
  justify-content: flex-end;
  background-color: @UTD-MODAL-CONTENT-BG-COLOR;
}

.sidebar-close-button {
  padding: 16px 16px 8px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .utd-sidebar-container {
    top: 56px;
  }
}

.utd-sidebar-content {
  .ds1-mvw-4();
  width: 340px;
  height: 100%;
  overflow-y: auto;
  background-color: @UTD-MODAL-CONTENT-BG-COLOR;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(300px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all @UTD-DIALOG-TRANSITION-DURATION ease-in;
}

.InternetExplorer {
  .slide-enter-active,
  .slide-leave-active {
    opacity: 1;
  }
}
</style>
