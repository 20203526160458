<template>
  <utd-copyright :full="full"
                 :center="center"
                 :is-prospect-view="isProspectView"
                 :is-ucc-state="isUccState"
                 :is-not-desktop-view="isNotDesktopView"
                 :ucc-copyright-year="uccCopyrightYear"
  />
</template>

<script>
import UtdCopyright from '../utd/UtdCopyright.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        UtdCopyright
    },
    props: {
        full: Boolean,
        center: Boolean
    },
    computed: {
        ...mapGetters('app', [
            'isProspectView',
            'isUccState',
            'uccCopyrightYear'
        ]),
        ...mapGetters('device', [ 'isNotDesktopView' ])
    }
};
</script>