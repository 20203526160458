<!-- eslint-disable max-len -->
<template>
  <svg width="15"
       height="16"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       :class="{'solid-fill': isSolid}"
  >
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.6 12.8C23 12.4 23.2 11.8 23.2 11.2C23.2 9.90005 22.1 8.80005 20.8 8.80005H13.6C14.1 7.50005 14.4 5.40005 14.4 3.20005C14.4 1.70005 13.1 0.800049 12 0.800049C10.6 0.800049 9.60005 1.30005 9.60005 3.20005C9.60005 6.10005 7.70005 9.10005 4.00005 10.4H0.800049V21.6H4.00005C5.80005 21.6 6.40005 22.1 10.4 23.2H20C21.3 23.2 22.4 22.1 22.4 20.8C22.4 20.4 22.3 20 22.1 19.6C22.8 19.2 23.2 18.4 23.2 17.6C23.2 17 23 16.4 22.6 16C23 15.6 23.2 15 23.2 14.4C23.2 13.8 23 13.2 22.6 12.8Z"
          fill="currentColor"
          stroke="black"
          stroke-width="1.6"
    />
  </svg>
</template>

<script>
export default {
    props: {
        isSolid: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  svg {
    width: 15px;
    height: 15px;
    overflow: visible;
  
    > path {
      color: @WKCE-WHITE;
      transition: color 125ms ease-in, stroke 125ms ease-in;

      &:hover {
          color: @WKCE-BLUE-SHADE1;
          stroke: @WKCE-BLUE-SHADE1;
          cursor: pointer;
      }
    }

    &.solid-fill > path {
      color: @WKCE-BLUE-SHADE1;
      stroke: @WKCE-BLUE-SHADE1;
    }
  }
</style>