import { cloneDeep } from 'lodash';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';
import UtdCache from '_acaSrc/utility/UtdCache';
import utdRest from '../utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import {
    SET_SEARCHBAR_DISABLE_AC,
    SET_SEARCH_STATE
} from '_acaSrc/store/search.store';
import { SET_KEEP_ONE_ACCOUNT_REQUEST } from '_acaSrc/store/profile.store';
import { SET_MYACCOUNT_SIDEBAR_REQUEST } from '_acaSrc/store/app.store';
import { C_MY_ACCOUNT, C_HTTP } from '_acaSrc/utility/constants';
import { getDocument } from '_acaSrc/utility/DOM';

const state = {
    form: {
        userName: '',
        password: '',
        saveUserName: false,
        isSubmitting: false
    },
    origForm: {
        userName: '',
        password: '',
        saveUserName: false,
        isSubmitting: false
    },
    message: {
        title: '',
        error: '',
        level: 'error'
    },
    origMessage: {
        error: ''
    },
    loginErrorMessage: '',
    loginErrorMessageTitle: '',
    tier2: {},
    institutions: [],
    postLoginUrl: '',
    loginSubscriberBox: false,
    loginRegisterButton: false,
    renewalSubscriptionUrl: '',
    loginHref: '/login',
    isRegister: false,
    skipLinkUrl: '',
    loginInfoMessage: {
        title: '',
        error: '',
        level: ''
    },
    isNextStepsFlow: false
};


export const getters = {
    form: state => state.form,
    origForm: state => state.origForm,
    message: state => state.message,
    origMessage: state => state.origMessage,
    tier2: state => state.tier2,
    institutions: state => state.institutions,
    postLoginUrl: state => state.postLoginUrl,
    loginSubscriberBox: state => state.loginSubscriberBox,
    loginRegisterButton: state => state.loginRegisterButton,
    renewalSubscriptionUrl: state => state.renewalSubscriptionUrl,
    loginHref: state => state.loginHref,
    isRegister: state => state.isRegister,
    skipLinkUrl: state => state.skipLinkUrl,
    loginErrorMessage: state => state.loginErrorMessage,
    loginInfoMessage: state => state.loginInfoMessage,
    isNextStepsFlow: state => state.isNextStepsFlow
};

export const SET_MESSAGE_ERROR = 'SET_MESSAGE_ERROR';
export const SET_INSTITUTIONS = 'SET_INSTITUTIONS';
export const SET_MESSAGE_TITLE = 'SET_MESSAGE_TITLE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_FORM_SUBMITTING = 'SET_FORM_SUBMITTING';
export const SET_POST_LOGIN_URL = 'SET_POST_LOGIN_URL';
export const SET_FORM_POST_LOGIN_URL = 'SET_FORM_POST_LOGIN_URL';
export const SET_FORM = 'SET_FORM';
export const SET_FORM_PASSWORD = 'SET_FORM_PASSWORD';
export const SET_FORM_USER_NAME = 'SET_FORM_USER_NAME';
export const SET_FORM_SAVE_USER_NAME = 'SET_FORM_SAVE_USER_NAME';
export const SET_LOGIN_SUBSCRIBER_BOX = 'SET_LOGIN_SUBSCRIBER_BOX';
export const SET_LOGIN_REGISTER_BUTTON = 'SET_LOGIN_REGISTER_BUTTON';
export const SET_RENEWAL_SUBSCRIPTION_URL = 'SET_RENEWAL_SUBSCRIPTION_URL';
export const SET_LOGIN_HREF_AND_IS_REGISTER = 'SET_LOGIN_HREF_AND_IS_REGISTER';
export const SET_SKIP_LINK_URL = 'SET_SKIP_LINK_URL';
export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';
export const RESET_LOGIN_ERROR_MESSAGE = 'RESET_LOGIN_ERROR_MESSAGE';
export const SET_LOGIN_INFO_MESSAGE = 'SET_LOGIN_INFO_MESSAGE';
export const SET_ERROR_LEVEL = 'SET_ERROR_LEVEL';
export const SET_NEXT_STEPS_FLOW = 'SET_NEXT_STEPS_FLOW';

export const mutations = {
    [SET_MESSAGE_ERROR]: (state, msg) => {
        state.message.error = msg;
    },
    [SET_ERROR_LEVEL]: (state, value) => {
        state.message.level = value;
    },
    [SET_MESSAGE_TITLE]: (state, msg) => state.message.title = msg,
    [SET_MESSAGE]: (state, msg) => state.message = msg,
    [SET_FORM_SUBMITTING]: (state, isSubmitting) => state.form.isSubmitting = isSubmitting,
    [SET_POST_LOGIN_URL]: (state, url) => state.postLoginUrl = url,
    [SET_FORM_POST_LOGIN_URL]: (state, url) => state.form.postLoginUrl = url,
    [SET_FORM]: (state, form) => state.form = form,
    [SET_FORM_PASSWORD]: (state, password) => state.form.password = password,
    [SET_FORM_USER_NAME]: (state, userName) => state.form.userName = userName,
    [SET_FORM_SAVE_USER_NAME]: (state, saveUserName) => state.form.saveUserName = saveUserName,
    [SET_INSTITUTIONS]: (state, institutions) => state.institutions = institutions,
    [SET_LOGIN_SUBSCRIBER_BOX]: (state, loginSubscriberBox) =>
        state.loginSubscriberBox = loginSubscriberBox,
    [SET_LOGIN_REGISTER_BUTTON]: (state, loginRegisterButton) =>
        state.loginRegisterButton = loginRegisterButton,
    [SET_RENEWAL_SUBSCRIPTION_URL]: (state, data) => {
        if ('renewalSubscriptionURL' in data) {
            state.renewalSubscriptionUrl = data.renewalSubscriptionURL;
        }
    },
    [SET_LOGIN_HREF_AND_IS_REGISTER]: (state, value) => {
        state.loginHref = value;
        // eslint-disable-next-line eqeqeq
        if (value == '/login') {
            state.isRegister = false;
        }
        else {
            state.isRegister = true;
        }
    },
    [SET_SKIP_LINK_URL]: (state, value) => {
        if (value) {
            state.skipLinkUrl = value;
        }
    },
    [SET_LOGIN_ERROR_MESSAGE]: (state, data) => {
        state.loginErrorMessage = 'loginErrorMessage' in data && data.loginErrorMessage;
    },
    [SET_LOGIN_INFO_MESSAGE]: (state, data) => {
        state.loginInfoMessage = data;
    },
    [RESET_LOGIN_ERROR_MESSAGE]: state => state.loginErrorMessage = '',
    [SET_NEXT_STEPS_FLOW]: (state, value) => state.isNextStepsFlow = value
};

export const actions = {
    reset({ commit, getters }) {
        const origForm = cloneDeep(getters.origForm);
        commit(SET_FORM, origForm);
        const origMessage = cloneDeep(getters.origMessage);
        commit(SET_MESSAGE, origMessage);
    },
    logLoginEvent({ rootGetters, dispatch, getters }, isShowCaptchaSection) {
        let src;

        if (getters.loginSubscriberBox) {
            src = 'login-subscribe-view';
        }
        else if (rootGetters['app/productName'] === 'UpToDate \u4E34\u5E8A\u987E\u95EE'
             || rootGetters['app/countryCode'] === 'CN') {
            src = '';
        }
        else {
            src = 'login-register-view';
        }
        dispatch('app/logEvent', {
            eventUrl: 'event/login/json',
            eventParams: {
                source: src,
                isCaptchaSectionVisible: isShowCaptchaSection
            }
        }, { root: true }
        );
    },
    setErrorMessage({ commit }, msg) {
        commit(SET_MESSAGE_ERROR, msg);
    },
    setErrorTitle({ commit }, msg) {
        commit(SET_MESSAGE_TITLE, msg);
    },

    loginUser: async({ getters, commit, dispatch }) => {
        try {
            // turns off auto complete while login is processing DE9872
            commit(`search/${SET_SEARCHBAR_DISABLE_AC}`, true, { root: true });
            commit('feature/SET_SHOW_PRINT_LINK', {}, { root: true });

            if (getters.postLoginUrl !== '') {
                commit(SET_FORM_POST_LOGIN_URL, getters.postLoginUrl);
                commit(SET_POST_LOGIN_URL, '');
            }

            const data = await utdRest('auth/login', getters.form);
            await dispatch('processLogin', data);
            return data;
        }
        catch (err) {
            dispatch('processFailedLogin', err);
        }
    },
    ssoMerge({ getters, commit, dispatch }) {
        return utdRest('auth/ssoMerge', getters.form)
            .then(() => {
                commit(SET_MESSAGE_ERROR, '');
                commit(`profile/${SET_KEEP_ONE_ACCOUNT_REQUEST}`, false, { root: true });
            })
            .catch(error => {
                dispatch('processFailedLogin', error);
            });
    },
    processFailedLogin({ commit }, error) {
        const errorData = error.data;

        if (errorData && errorData.errors && errorData.errors.length) {
            commit(SET_MESSAGE_ERROR, errorData.errors[0].message);
            commit(SET_MESSAGE_TITLE, errorData.errors[0].title);
        }

        commit(SET_FORM_PASSWORD, '');
        commit(`search/${SET_SEARCHBAR_DISABLE_AC}`, false, { root: true });
    },
    processLogin({ rootGetters, getters, commit, dispatch }, data) {
        dispatch('app/resetUI', { preventScrollTop: false }, { root: true });
        const url = rootGetters['app/getDestinationUrl'];
        new PubSub().publish('wkutd.purgePersistentCache');
        // If url is not empty, push it back into cache so that
        // the TwoFactor service can find it if needed.
        if (url) {
            new UtdCache().setPersistent('utdDestinationUrl', url, 1);
        }

        if (rootGetters['profile/userProfile'].pendoStateMessage === '') {
            dispatch('pendo/initializePendo', '', { root: true });
        }

        const searchState = rootGetters['search/searchResultsState'];
        commit(`search/${SET_SEARCH_STATE}`, searchState, { root: true });

        // If the user has an email as their username,
        // they will log in via the Next Steps for Sign In process to create two accounts
        // so they should not go through the normal login flow
        if (getters.isNextStepsFlow) {
            return;
        }
        const origForm = cloneDeep(getters.origForm);
        commit(SET_FORM, origForm);
        commit(SET_MESSAGE_ERROR, '');

        dispatch('footer/getFooter', '', { root: true });

        // Disable GA4 analytics script
        window.google_tag_data = 'disabled';
        window.google_tag_manager = 'disabled';
        dispatch('handleMyAccountSidebar');

        if (data && !data.isRedirect) {
            rootGetters['app/router'].url(url || '/contents/search');
        }
        else {
            rootGetters['app/router'].url(C_HTTP.DEFAULT_HOMEPAGE_PATH);
        }
    },
    handleMyAccountSidebar({ commit, rootGetters }) {
        if (rootGetters['app/myAccountSidebarRequestStatus']
        === C_MY_ACCOUNT.SIDEBAR_REQUEST.PENDING) {
            commit(`app/${SET_MYACCOUNT_SIDEBAR_REQUEST}`,
                C_MY_ACCOUNT.SIDEBAR_REQUEST.APPROVED, { root: true });
        }
    },
    setOidcPartnerModalShown({ rootGetters }) {
        rootGetters['auth/oidcPartnerDetails'].modalConfig.shown = true;
    },
    resolveLogout() {
        new PubSub().publish('wkutd.purgePersistentCache');
        new ServerMessaging().clearMessageQueue();
    }
};

const login = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default login;
