<template>
  <div class="wk-accordion" :class="{'has-collapsable': showExpandCollapse}">
    <utd-button v-if="showExpandCollapse && !isMutexAccordion"
                class="collapsable-btn"
                button-style="text"
                @click="toggleAllItems">
      {{ expandAllButtonText }}
    </utd-button>
    <slot name="title" />
    <UtdAccordionItem v-for="(i, idx) in pAccordionItems"
                      :key="i.id || idx"
                      ref="items"
                      :item-id="i.id || undefined"
                      :open="isAccordionOpen(idx)"
                      :text-label="i.label || i.name"
                      :markup-label="i.markupLabel || ''"
                      :class="accordionStyleClass"
                      @headerClicked="onClickItem(idx)"
                      @enter="onClickItem(idx)"
                      @space="onClickItem(idx)"
                      @left="evt => handleArrowKeys('left', idx, evt)"
                      @up="evt => handleArrowKeys('up', idx, evt)"
                      @right="evt => handleArrowKeys('right', idx, evt)"
                      @down="evt => handleArrowKeys('down', idx, evt)"
    >
      <slot name="content" :item="i" :index="idx">
        <div>{{ i.text }}</div>
      </slot>
    </UtdAccordionItem>
  </div>
</template>

<script>
import UtdAccordionItem from './UtdAccordionItem.vue';
import { validateUniqueKeys } from '_acaSrc/utility/Validators';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import PubSub from '_acaSrc/utility/PubSub';
import { C_EVENTS } from '_acaSrc/utility/constants';

const UTD_ACCORDION_OPENED = 'itemOpened';
const UTD_ACCORDION_CLOSED = 'itemClosed';
const RENDERING_TIMOUT_MS = 100;

export default {
    components: {
        UtdAccordionItem,
        UtdButton
    },
    props: {
        accordionStyleClass: {
            type: String,
            default: ''
        },
        openedIndex: {
            type: Number,
            default: -1
        },
        /*
            {
                label: String (required) the accordion item header text
                text: String (optional) content of accordion if simple text
                id: Key (optional) if provided, used for the Vue key attribute.
                    Only needed if accordion items change (values or order).
                    If included, should be truthy unique values.
            }
        */
        accordionItems: {
            type: Array,
            default: () => [],
            validator: validateUniqueKeys
        },
        isMutexAccordion: {
            type: Boolean,
            default: false
        },
        canCloseOpenAccordion: {
            type: Boolean,
            default: true
        },
        disableArrowKeys: {
            type: Boolean,
            default: false
        },
        showExpandCollapse: {
            type: Boolean,
            default: false
        }
    },
    emits: [ UTD_ACCORDION_OPENED, UTD_ACCORDION_CLOSED, 'next', 'previous' ],
    data() {
        return {
            pAccordionItems: [],
            openIndex: this.openedIndex > -1 ? this.openedIndex : -1
        };
    },
    computed: {
        expandAllButtonText() {
            return this.isAllOpened ? 'Collapse All' : 'Expand All';
        },
        isAllOpened() {
            return this.pAccordionItems.some(item => {
                return item.opened;
            });
        }
    },
    watch: {
        accordionItems(newVal, oldVal) {
            if (newVal !== oldVal && oldVal) {
                this.pAccordionItems = newVal;
            }
        },
        openedIndex(newVal) {
            this.openIndex = newVal;
        }
    },
    created() {
        this.pAccordionItems = this.accordionItems;
    },
    mounted() {
        if (this.openedIndex > -1) {
            if (!this.isMutexAccordion && this.pAccordionItems.length > this.openedIndex) {
                this.pAccordionItems[this.openedIndex]['opened'] = true;
                return;
            }
            this.openIndex = this.openedIndex;
        }
    },
    methods: {
        isAccordionOpen(idx) {
            if (!this.isMutexAccordion) {
                return this.pAccordionItems[idx].opened || false;
            }

            return idx === this.openIndex;
        },
        onClickItem(idx) {
            if (!this.isMutexAccordion) {
                this.pAccordionItems[idx]['opened'] = !this.pAccordionItems[idx].opened;
                if (this.pAccordionItems[idx].opened) {
                    this.$emit(UTD_ACCORDION_OPENED, this.pAccordionItems[idx]);
                    return;
                }
                this.$emit(UTD_ACCORDION_CLOSED, this.pAccordionItems[idx]);
                return;
            }

            if (idx !== this.openIndex) {
                this.openIndex = idx;
                this.$emit(UTD_ACCORDION_OPENED, this.pAccordionItems[idx]);
                return;
            }

            if (this.canCloseOpenAccordion) {
                this.openIndex = -1;
            }
        },
        indexInBounds(idx) {
            return idx <= (this.pAccordionItems.length - 1)
            && idx > -1;
        },
        nextItem(idx, evt) {
            if (!this.indexInBounds(idx) || !this.pAccordionItems.length) {
                return;
            }
            const nextIdx = (idx + 1) % this.pAccordionItems.length;
            this.onClickItem(nextIdx);
            this.$emit('next', evt);
        },
        prevItem(idx, evt) {
            if (!this.indexInBounds(idx) || !this.pAccordionItems.length) {
                return;
            }
            const prevIdx = idx === 0 ? this.pAccordionItems.length - 1 : idx - 1;
            this.onClickItem(prevIdx);
            this.$emit('previous', evt);
        },
        handleArrowKeys(key, idx, evt) {
            if (this.disableArrowKeys) {
                return;
            }
            if (key === 'left' || key === 'up') {
                this.prevItem(idx, evt);
            }
            else if (key === 'right' || key === 'down') {
                this.nextItem(idx, evt);
            }
        },
        toggleAllItems(openAll) {
            let shouldOpenAll = false;
            if (typeof openAll === 'boolean') {
                shouldOpenAll = openAll;
            }
            else {
                shouldOpenAll = !this.isAllOpened;
            }

            this.pAccordionItems.forEach((item, idx) => {
                this.pAccordionItems[idx]['opened'] = shouldOpenAll;
            });
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                delay: RENDERING_TIMOUT_MS,
                doFocusChange: false
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-accordion {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.v-stretch .wk-accordion-item-open {
    flex-grow: 1;
  }

  &.has-collapsable {
    .ds1-mt-5();
  }
}

.collapsable-btn {
  position: absolute;
  right: 0;
  top: -36px;
}
</style>
