import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import { C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import i18next from 'i18next';

export const getInitialState = () => {
    return {
        mergedData: {},
        myAccount: {
            level: '',
            deviceBundle: {},
            subscriptionInfo: {
                userHasPendingOrder: false,
                subscriptionExpirationDate: null
            },
            downloadCenterInfo: {
                canPurchaseDesktop: true,
                hasDesktopFeature: false,
                hasMobileFeature: true
            }
        },
        contactInfoFields: {
            accountNumber: {},
            salutation: {},
            fullName: {},
            suffix: {},
            address1: {},
            address2: {},
            address3: {},
            country: {},
            zip: {},
            city: {},
            county: {},
            state: {},
            email: {},
            phone1: {},
            phone1Type: {},
            phone2: {},
            phone2Type: {},
            fax: {},
            disableSendingEmail: {},
            practiceType: {},
            specialty: {},
            practiceSetting: {},
            isSuccess: false
        },
        mobileDevices: {
            listing: [],
            deactivated: []
        },
        desktopDevices: {
            listing: [],
            deactivated: []
        },
        devices: {
            listing: [],
            deactivated: [],
            maxLinked: '',
            activations: {
                maxDesktop: null,
                maxMobile: null
            }
        },
        showAllDesktop: false,
        showAllMobile: false,
        statesList: [],
        countriesList: [],
        practiceTypeList: [],
        specialtyList: [],
        practiceSettingsList: [],
        successMessage: '',
        serverErrors: {
            password: '',
            newUserName: ''
        }
    };
};

const state = getInitialState();

export const getters = {
    mergedData: state => state.mergedData,
    myAccountUserName: state => state.myAccount.userName,
    myAccountDeviceBundle: state => state.myAccount.deviceBundle,
    myAccountSubscription: state => state.myAccount.subscriptionInfo,
    myAccountAccessLevel: state => state.myAccount.level,
    myAccountDownloadCenter: state => state.myAccount.downloadCenterInfo,
    maxDesktopActivations: state => state.myAccount.deviceBundle.maxDesktopActivations,
    maxMobileActivations: state => state.myAccount.deviceBundle.maxMobileActivations,
    myAccountContactInfo: state => state.contactInfoFields,
    myAccountStates: state => state.statesList,
    myAccountCountries: state => state.countriesList,
    myAccountpracticeTypeOptions: state => state.practiceTypeList,
    myAccountSpecialtyOptions: state => state.specialtyList,
    myAccountPracticeSettingOptions: state => state.practiceSettingsList,
    hasUserProfessions: (state, getters, rootState, rootGetters) => {
        return rootGetters['feature/featureValue']('isUserProfessionsEnabled')
            && (getters.myAccountContactInfo.practiceType.value
            || getters.myAccountContactInfo.specialty.value
            || getters.myAccountContactInfo.practiceSetting.value);
    },
    isDeviceActive: state => payload => {
        const { deviceId, platform } = payload;
        if (platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
            return state.desktopDevices.deactivated.indexOf(deviceId) === -1;
        }
        return state.mobileDevices.deactivated.indexOf(deviceId) === -1;
    },
    visibleDevices: (state, getters) => platform => {
        if (platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
            if (getters.devicesOverMaxLimit(platform) && !state.showAllDesktop) {
                return state.desktopDevices.listing
                    .slice(0, getters.maxActivationsAllowed(platform));
            }
            return state.desktopDevices.listing;
        }
        if (getters.devicesOverMaxLimit && !state.showAllMobile) {
            return state.mobileDevices.listing
                .slice(0, getters.maxActivationsAllowed(platform));
        }
        return state.mobileDevices.listing;
    },
    numActiveDevices: state => platform => {
        if (platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
            return state.desktopDevices.listing.length - state.desktopDevices.deactivated.length;
        }
        return state.mobileDevices.listing.length - state.mobileDevices.deactivated.length;
    },
    maxActivationsAllowed: (state, getters) => platform => {
        if (platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
            return getters.maxDesktopActivations;
        }
        return getters.maxMobileActivations;
    },
    devices: state => state.devices,
    showAllDesktop: state => state.showAllDesktop,
    showAllMobile: state => state.showAllMobile,
    devicesOverMaxLimit: (state, getters) => platform => {
        return getters.numActiveDevices(platform).length > getters.maxActivationsAllowed(platform);
    },
    successMessage: state => state.successMessage,
    serverErrors: state => state.serverErrors
};

export const SET_MERGED_DATA = 'SET_MERGED_DATA';
export const CATEGORIZE_USER_DEVICES = 'CATEGORIZE_USER_DEVICES';
export const SET_MY_ACCOUNT = 'SET_MY_ACCOUNT';
export const SET_CONTACT_INFO_FIELDS = 'SET_CONTACT_INFO_FIELDS';
export const SET_CONTACT_FORM_AVAILABABILITY = 'SET_CONTACT_FORM_AVAILABABILITY';
export const SET_STATES_FIELD = 'SET_STATES_FIELD';
export const SET_COUNTRIES_FIELD = 'SET_COUNTRIES_FIELD';
export const SET_MAX_DEVICE_ACTIVATIONS = 'SET_MAX_DEVICE_ACTIVATIONS';
export const SET_SHOW_ALL_DESKTOP = 'SET_SHOW_ALL_DESKTOP';
export const SET_SHOW_ALL_MOBILE = 'SET_SHOW_ALL_MOBILE';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_PRACTICE_TYPES_FIELD = 'SET_PRACTICE_TYPES_FIELD';
export const SET_SPECIALTIES_FIELD = 'SET_SPECIALTIES_FIELD';
export const SET_PRACTICE_SETTINGS_FIELD = 'SET_PRACTICE_SETTINGS_FIELD';

export const mutations = {
    [SET_MERGED_DATA](state, code) {
        state.mergedData = code;
    },
    [CATEGORIZE_USER_DEVICES]: state => {
        const desktopDevices = [];
        const mobileDevices = [];
        for (const device of state.devices.listing) {
            if (device.deviceImageType === C_MY_ACCOUNT.DESKTOP_DEVICE) {
                desktopDevices.push(device);
            }
            else {
                mobileDevices.push(device);
            }
        }
        state.desktopDevices.listing = desktopDevices;
        state.mobileDevices.listing = mobileDevices;
    },
    [SET_MY_ACCOUNT]: (state, myAccount) => state.myAccount = myAccount,
    [SET_CONTACT_INFO_FIELDS]: (state, contactFields) => {
        if (!Array.isArray(contactFields)) {
            return;
        }
        for (const field of contactFields) {
            if (field.name) {
                state.contactInfoFields[field.name] = field;
            }
        }
    },
    [SET_CONTACT_FORM_AVAILABABILITY]: (state, isSuccess) => {
        state.contactInfoFields.isSuccess = isSuccess;
    },
    [SET_STATES_FIELD]: (state, states) => state.statesList = states,
    [SET_COUNTRIES_FIELD]: (state, countries) => state.countriesList = countries,
    [SET_MAX_DEVICE_ACTIVATIONS]: (state, data) => {
        state.devices.activations.maxMobile = null;
        if ('mobile-max-activations' in data) {
            state.devices.activations.maxMobile = data['mobile-max-activations'];
        }
        state.devices.activations.maxDesktop = null;
        if ('desktop-max-activations' in data) {
            state.devices.activations.maxDesktop = data['desktop-max-activations'];
        }
    },
    [SET_SHOW_ALL_DESKTOP]: (state, status) => state.showAllDesktop = status,
    [SET_SHOW_ALL_MOBILE]: (state, status) => state.showAllMobile = status,
    [SET_SERVER_ERROR]: (state, payload) => {
        if ('password' in payload) {
            state.serverErrors.password = payload.password;
        }
        if ('newUserName' in payload) {
            state.serverErrors.newUserName = payload.newUserName;
        }
    },
    [SET_SUCCESS_MESSAGE]: (state, msg) => state.successMessage = msg,
    [SET_PRACTICE_TYPES_FIELD]: (state, practiceTypes) => {
        state.practiceTypeList = practiceTypes;
    },
    [SET_SPECIALTIES_FIELD]: (state, specialties) => {
        state.specialtyList = specialties;
    },
    [SET_PRACTICE_SETTINGS_FIELD]: (state, practiceSettings) => {
        state.practiceSettingsList = practiceSettings;
    }
};

export const actions = {
    findNewAccount: async({ commit }, data) => {
        await utdRest('getConsolidateAccounts', data)
            .then(response => {
                commit(SET_MERGED_DATA, response);
            }).catch(() => {
                commit(SET_MERGED_DATA, { error: true });
            });
    },
    addDeactivatedDevice({ state }, payload) {
        const { deviceId, platform } = payload;
        if (platform === C_MY_ACCOUNT.DESKTOP_DEVICE) {
            state.desktopDevices.deactivated.push(deviceId);
        }
        state.mobileDevices.deactivated.push(deviceId);
    },
    async getMyAccountInfo({ getters, commit }) {
        try {
            const data = await utdRest('myAccount/Info');
            const deviceBundle = data.deviceBundle;
            commit(SET_MY_ACCOUNT, data);
            successGetDeviceList(deviceBundle, getters.devices);
            commit(CATEGORIZE_USER_DEVICES);

            const contactFields = state.myAccount.contactInfo.formFields;
            const contactInfoFormSuccess = data.contactInfo.isSuccess;
            commit(SET_CONTACT_FORM_AVAILABABILITY, contactInfoFormSuccess);
            if (contactInfoFormSuccess) {
                commit(SET_CONTACT_INFO_FIELDS, contactFields);
            }
        }
        catch {
            return null;
        }
    },
    async changeUserName({ commit, dispatch }, payload) {
        const { password, newUserName } = payload;
        const data = await utdRest('myAccount/changeUsername', {
            password,
            newUserName
        });
        const res = data.data || data;
        if (!res || !Array.isArray(res)) {
            return;
        }

        for (const msg of res) {
            if (msg === C_MY_ACCOUNT.UPDATE_LOGIN_SUCCESS
              || msg === C_MY_ACCOUNT.UPDATE_LOGIN_PENDING) {
                commit(SET_SUCCESS_MESSAGE, 'MYACCOUNT.PASSWORD_CHANGE_SUCCESS');
                dispatch('getMyAccountInfo');
                return;
            }
            dispatch('handleChangeUserNameError', msg);
        }
    },
    handleChangeUserNameError({ commit }, msg) {
        if (msg === C_MY_ACCOUNT.NEW_USERNAME_ERROR) {
            commit(SET_SERVER_ERROR, { newUserName: 'MYACCOUNT.NEW_USER_NAME_INVALID' });
        }
        if (msg === C_MY_ACCOUNT.USER_NAME_IN_USE_ERROR) {
            commit(SET_SERVER_ERROR, { newUserName: 'MYACCOUNT.NEW_USER_NAME_TAKEN' });
        }
        if (msg === C_MY_ACCOUNT.OLD_PASSWORD_INVALID_ERROR) {
            commit(SET_SERVER_ERROR, { password: 'MYACCOUNT.PASSWORD_INVALID' });
        }
    }
};

// eslint-disable-next-line complexity
const successGetDeviceList = (data, devices) => {
    if (!data || !devices) {
        return;
    }
    for (let idx = 0; idx < data.devices.length; idx++) {
        const device = data.devices[idx];

        switch (device.deviceType) {
        case 'LOCAL_IPHONE':
            device.deviceTypeLabel = 'iPhone';
            device.deviceImageType = 'phone';
            break;

        case 'LOCAL_IPOD':
            device.deviceTypeLabel = 'iPod';
            device.deviceImageType = 'phone';
            break;

        case 'LOCAL_ANDROID_PHONE':
            device.deviceTypeLabel = 'Android';
            device.deviceImageType = 'phone';
            break;

        case 'LOCAL_WINDOWS_PHONE':
            device.deviceTypeLabel = 'Windows Phone 8';
            device.deviceImageType = 'phone';
            break;

        case 'LOCAL_DESKTOP':
            device.deviceTypeLabel = 'Desktop';
            device.deviceImageType = 'desktop';
            break;

        case 'LOCAL_WINDOWS_PC':
        case 'LOCAL_WINDOWS_DESKTOP':
            device.deviceTypeLabel = 'Windows Desktop';
            device.deviceImageType = 'desktop';
            break;

        case 'LOCAL_MAC_PC':
        case 'LOCAL_MAC_DESKTOP':
            device.deviceTypeLabel = 'MacDesktop';
            device.deviceImageType = 'desktop';
            break;

        case 'LOCAL_IPAD':
            device.deviceTypeLabel = 'iPad';
            device.deviceImageType = 'tablet';
            break;

        case 'LOCAL_ANDROID_TABLET':
            device.deviceTypeLabel = 'Android Tablet';
            device.deviceImageType = 'tablet';
            break;

        case 'LOCAL_WINDOWS8':
            device.deviceTypeLabel = 'Windows 8 Tablet';
            device.deviceImageType = 'tablet';
            break;
        }
    }
    devices.listing = data.devices.sort((a, b) =>
        b.lastAuthorizationDateMs - a.lastAuthorizationDateMs);

    if (devices.activations.maxDesktop) {
        devices.maxLinked
            = devices.activations.maxDesktop
            + (devices.activations.maxDesktop !== 1
                ? i18next.t('MYACCOUNT.MANAGE_DEVICES_COMPUTERS')
                : i18next.t('MYACCOUNT.MANAGE_DEVICES_COMPUTER'));
    }
    if (devices.activations.maxMobile) {
        if (devices.maxLinked.length > 0) {
            devices.maxLinked += i18next.t('AND');
        }
        else {
            devices.maxLinked = '';
        }
        devices.maxLinked
      += devices.activations.maxMobile
      + (devices.activations.maxMobile !== 1
          ? i18next.t('MYACCOUNT.MANAGE_DEVICES_DEVICES')
          : i18next.t('MYACCOUNT.MANAGE_DEVICES_DEVICE'));
    }
};

const account = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default account;