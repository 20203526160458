/* eslint-disable */
/*
* Returns a GUID that is not crypto strength. Do not use for authentication or
* authorization. It is appropriate only for use when a rare collision is
* tolerable. After we drop support for IE, this can be deprecated in favor of
* something cryptographically secure.
*
* Source: https://stackoverflow.com/a/8809472/752916
*/
export function createInsecureGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}