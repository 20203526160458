<template>
  <div>
    <div class="my-account-sidebar">
      <div class="sidebar__container">
        <div class="sidebar__header">{{ $t('MYACCOUNT.DOWNLOAD_CENTER') }}</div>
        <div class="sidebar__content">
          <a v-if="myAccountDownloadCenter.canPurchaseDesktop"
             v-utd-new-tab="true"
             class="sidebar__purchase-desktop-link ds1-pb-1"
             :href="renewalSubscriptionUrl"
             @click="logEventForPurchaseDesktop()">{{ $t('MYACCOUNT.PURCHASE_UTD_DESKTOP') }}</a>
          <span v-if="myAccountDownloadCenter.hasDesktopFeature"
                class="sidebar__link-container">
            <span class="wkce-icon-download-line" />
            <a class="sidebar__desktop-download-link ds1-pb-1"
               href="#"
               @click="openDesktopCenter()">{{ $t('MYACCOUNT.DESKTOP_DOWNLOAD_CENTER') }}</a>
          </span>
          <span v-if="myAccountDownloadCenter.hasMobileFeature"
                class="sidebar__link-container">
            <span class="wkce-icon-info" />
            <a v-utd-new-tab="true"
               class="sidebar__mobile-download-link ds1-pb-1"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/uptodate/mobile"
            >{{ $t('MYACCOUNT.MOBILE_DOWNLOAD_INSTRUCTIONS') }}</a>
          </span>
        </div>
      </div>
    </div>
    <download-center-dialog ref="downloadCenterRef" />
  </div>
</template>

<script>
import DownloadCenterDialog from './DownloadCenterDialog.vue';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import { mapGetters } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        DownloadCenterDialog
    },
    emits: [ 'close-sidebar' ],
    computed: {
        ...mapGetters('account', [ 'myAccountDownloadCenter' ]),
        ...mapGetters('login', [ 'renewalSubscriptionUrl' ])
    },
    methods: {
        close() {
            this.$emit('close-sidebar');
        },
        openDesktopCenter() {
            this.$refs.downloadCenterRef.open();
            new PubSub().publish('wkutd.openMyAccountDialog');
        },
        logEventForPurchaseDesktop() {
            logUiClickEventHelper({
                uiElementName: 'myAccountDownloadCenter_purchaseDesktopLink',
                targetUrl: '/store'
            });
        }
    }
};
</script>
