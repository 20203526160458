import utdRest from '../utility/http/UtdRestHooks';
import { createInsecureGUID } from '_acaSrc/utility/guid';

export const state = {
    searchId: null,
    searchTerm: null,
    accruedCredits: 0.5,
    showSpinner: true,
    hasError: false,
    reflectionSuccess: false,
    showSuccessMessage: false,
    correlationId: null,
    questions: [ ],
    previousQuestions: []
};

export const getters = {
    goals: state => state.goals,
    practices: state => state.practices,
    questions: state => state.questions || [],
    accruedCredits: state => state.accruedCredits,
    correlationId: state => state.correlationId,
    searchTerm: state => state.searchTerm,
    reflectionSuccess: state => state.reflectionSuccess,
    showSuccessMessage: state => state.showSuccessMessage,
    widgetState: state => {
        if (state.hasError) {
            return 'error';
        }
        else if (state.showSpinner) {
            return 'loading';
        }
        else if (state.accruedCredits && state.searchTerm) {
            return 'editing';
        }
        return 'error';
    }
};

export const RESET_SELECTIONS = 'RESET_SELECTIONS';
export const SAVE_REFLECTION = 'SAVE_REFLECTION';
export const REFLECTION_SUCCESS = 'REFLECTION_SUCCESS';
export const TOGGLE_SUCCESS_MESSAGE_VISIBILITY = 'TOGGLE_SUCCESS_MESSAGE_VISIBILITY';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_CORRELATION_ID = 'SET_CORRELATION_ID';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_QUESTION_CHOSEN_OPT = 'SET_QUESTION_CHOSEN_OPT';

export const mutations = {
    [SET_SEARCH_TERM]: (state, searchTerm) => state.searchTerm = searchTerm,
    [SET_CORRELATION_ID]: (state, correlationId) => state.correlationId = correlationId,
    [SET_QUESTION_CHOSEN_OPT]: (state, { chosenOptionIdx, question }) => {
        const q = state.questions.find(q => q.id === question.id);
        if (q) {
            q['chosenOptionIdx'] = chosenOptionIdx;
        }
    },
    [SET_QUESTIONS]: (state, questions) => {
        state.questions = questions;
        state.previousQuestions = questions.map(cloneQuestion);
    },
    [RESET_SELECTIONS]: state => {
        state.questions = state.previousQuestions.map(cloneQuestion);
    },
    [SAVE_REFLECTION]: state => {
        state.previousQuestions = state.questions.map(cloneQuestion);
    },
    [REFLECTION_SUCCESS]: (state, isSuccess) => {
        state.reflectionSuccess = isSuccess;
    },
    [TOGGLE_SUCCESS_MESSAGE_VISIBILITY]: (state, isSuccess) => {
        state.showSuccessMessage = isSuccess;
    },
    [SHOW_SPINNER]: (state, show) => {
        state.showSpinner = show;
    },
    [SHOW_ERROR]: (state, show) => {
        state.hasError = show;
    }
};

export const actions = {
    saveReflection: async({ commit, getters }) => {
        const correlationId = getters.correlationId;
        const questions = getters.questions;
        commit(SHOW_ERROR, false);
        commit(SHOW_SPINNER, true);
        commit(REFLECTION_SUCCESS, false);
        commit(TOGGLE_SUCCESS_MESSAGE_VISIBILITY, false);
        try {
            await utdRest('cme/accrual/saveReflection', {
                searchCorrelationId: correlationId,
                postData: {
                    reflectionResponses: questions.map(q => {
                        return {
                            questionId: q.id,
                            answerIds: [ q.options[q.chosenOptionIdx].key ]
                        };
                    })
                }
            });
            commit(REFLECTION_SUCCESS, true);
            commit(TOGGLE_SUCCESS_MESSAGE_VISIBILITY, true);
            saveWidgetState(state);
        }
        catch {
            commit(SHOW_ERROR, true);
        }
        commit(SAVE_REFLECTION);
        commit(SHOW_SPINNER, false);
    },
    initReflectionWidget: async({ commit }) => {
        commit(SHOW_ERROR, false);
        commit(SHOW_SPINNER, true);
        commit(SET_CORRELATION_ID, localStorage.getItem('searchCorrelationId'));
        commit(SET_SEARCH_TERM, localStorage.getItem('searchCorrelationTerm'));

        try {
            const reflectionData = await utdRest('cme/reflectionData');
            const questions = reflectionData.reflectionQuestions;
            if (!questions.length) {
                throw new Error('Failed to receive questions');
            }
            commit(SET_QUESTIONS, getQuestionsFromResponse(questions));
        }
        catch {
            commit(SHOW_ERROR, true);
        }

        commit(SHOW_SPINNER, false);
    },
    handleSearch: ({ commit, getters }, searchTerm) => {
        if (searchTerm === localStorage.getItem('searchCorrelationTerm')) {
            // Sync vuex with localStorage to support correct behavior across tabs
            commit(SET_CORRELATION_ID, localStorage.getItem('searchCorrelationId'));
            commit(SET_SEARCH_TERM, searchTerm);
        }
        else {
            const newCorrelationId = createInsecureGUID();
            localStorage.setItem('searchCorrelationId', newCorrelationId);
            localStorage.setItem('searchCorrelationTerm', searchTerm);

            // Clear Widget State for new search
            resetWidgetState();
            commit(SET_CORRELATION_ID, newCorrelationId);
            commit(SET_SEARCH_TERM, searchTerm);
            commit(REFLECTION_SUCCESS, false);
            getters.questions.forEach(question => {
                commit(SET_QUESTION_CHOSEN_OPT, { question, chosenOptionIdx: null });
            });
            // Resets the widget's previousQuestions state.
            commit(SET_QUESTIONS, getters.questions);
        }
    },
    checkWidgetState: ({ commit, getters }) => {
        if (localStorage.getItem('storedReflections') && !getters.searchTerm) {
            const storedReflections = JSON.parse(localStorage.getItem('storedReflections'));
            commit(SET_QUESTIONS, storedReflections);
            commit(SET_SEARCH_TERM, localStorage.getItem('reflectedSearchTerm'));
            commit(REFLECTION_SUCCESS, true);
            commit(SHOW_SPINNER, false);
        }
    }
};

const inlineCme = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default inlineCme;

const getQuestionsFromResponse = responseQuestions => {
    return responseQuestions.map(q => {
        return {
            title: q.labels.reflection,
            chosenOptionIdx: null,
            type: q.questionType,
            id: q.id,
            options: q.reflectionAnswers.map(opt => {
                return {
                    key: opt.id,
                    option: opt.answer
                };
            })
        };
    });
};

const cloneQuestion = ({ title, chosenOptionIdx, options, id, type }) => {
    return {
        title,
        id,
        type,
        chosenOptionIdx,
        options: options.map(({ key, option }) => {
            return { key, option };
        })
    };
};

const saveWidgetState = state => {
    localStorage.setItem('storedReflections', JSON.stringify(state.questions));
    localStorage.setItem('reflectedSearchTerm', state.searchTerm);
};

const resetWidgetState = () => {
    localStorage.setItem('storedReflections', '');
    localStorage.setItem('reflectedSearchTerm', '');
};