import { decodeHtml } from '_acaSrc/utility/DOM';
import DOMPurify from 'dompurify';

export function stripPrefix(input, character, index = 1) {
    const indexPosition = input.indexOf(character);
    return indexPosition > -1 ? input.substr(index + indexPosition) : input;
}

/*  Utility method to strip all anchor tags from a string, leaving only the contents

    [WARNING]
    This method should only be used for simple html from trusted source.
    DO NOT USE AGAINST HTML RETRIEVED FROM SOURCES OUTSIDE UTD
*/
export function stripAnchorTags(source) {
    return source ? String(source).replace(/<a(\s|[^>]+)?>/gm, '').replace(/<\/a>/gm, '') : '';
}

export function stringToNumeric(source) {
    // Attempts to convert string into a numeric value
    // NOTE: Review unit tests for limitations.
    return (source && source.replace && parseFloat(source.replace(/[^\d.-]/g, ''))) || NaN;
}

/**
 * Extracts an integer/float from passed string.
 * Differs from stringToNumeric in that it only extracts the
 * first contiguous integer/float found.
 *
 * For example, inputStr of "24.5px" will return value of 24.5
 * inputStr of "24.5px 48.9px" will reutrn value of 24.5
 *
 * @param {String} inputStr - String containing numerics to be extracted
 * @returns Numeric value extracted from string, or NaN
 */
export function pxToNum(inputStr) {
    const matches = inputStr && inputStr.match(/[-]?\d*\.?\d+/);
    const result = matches && matches.map(v => parseFloat(v));
    return result && result[0] || NaN;
}

export function truncateString(input, charLimit, omissionIndicator = '...') {
    if (!input) {
        return '';
    }
    if (typeof charLimit === 'undefined' || charLimit === '' || input.length < charLimit) {
        return input;
    }
    return `${input.substring(0, charLimit)}${omissionIndicator}`;
}

export function isNumericOnly(inputStr) {
    return inputStr && inputStr.match && inputStr.match(/^[0-9]+$/);
}

export function sterilizeString(source) {
    if (!source) {
        return '';
    }
    // Decode HTML to convert strings like '&lt;' to '<' and '&gt;' to '>'...
    const srcToHtml = decodeHtml(source);

    // Run through sanitizer...
    return DOMPurify.sanitize(srcToHtml, { ALLOWED_TAGS: [], SAFE_FOR_TEMPLATES: true });
}

export function capitalizeFirstLetter(source) {
    if (!source) {
        return '';
    }
    return source.charAt(0).toUpperCase() + source.substring(1).toLowerCase();
}
