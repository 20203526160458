<template>
  <div id="utd-genericMenuContainer" class="utd-abs-white-carbon">
    <dl class="utd-tabControl">
      <dt><span class="breadcrumb"><span v-text="$t('MYACCOUNT.SETTINGS')" /></span>
        <span v-text="$t('MYACCOUNT.LANGUAGE')" /></dt>
    </dl>
    <ul id="utd-languageSelector" class="utd-menu selectorMenu">
      <li v-for="(language, index) in supportedLanguages"
          :key="index"
          :class="{'selected': localization.language === language.value}">
        <a :aria-label="language.name"
           @click="changeLang(language.value)"
           v-text="$t(language.name)" /></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { C_LANGUAGES_LIST } from '_acaSrc/utility/constants';

export default {
    computed: {
        ...mapGetters('user', [ 'localization' ]),
        supportedLanguages() {
            return C_LANGUAGES_LIST;
        }
    },
    methods: {
        ...mapActions('user', [ 'changeLanguage' ]),
        changeLang(langKey) {
            this.changeLanguage(langKey);
        }
    }
};
</script>