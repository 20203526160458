<template>
  <div>
    <utd-modal ref="utdSidebarModal"
               :modal-classes="modalClasses"
               :close-on-overlay-click="closeOnOverlayClick"
               :close-on-escape="false"
               :autofocus="autofocus"
               :disable-portal="disablePortal"
               @utd-modal-overlay-click="closeSidebar()">
      <template #siblingElements>
        <utd-sidebar ref="utdSidebar"
                     :close-on-escape="closeOnEscape"
                     :is-dialog-open="isDialogOpen"
                     :hide-header-close="hideHeaderClose"
                     @utd-sidebar-escape="closeSidebar()"
                     @closed="closeModal()">
          <template #header>
            <slot name="header" />
          </template>
          <template #sidebarContent>
            <slot name="sidebarContent" />
          </template>
        </utd-sidebar>
      </template>
    </utd-modal>
  </div>
</template>

<script>
import UtdModal from './UtdModal.vue';
import UtdSidebar from './UtdSidebar.vue';

export default {
    components: {
        UtdModal,
        UtdSidebar
    },
    props: {
        modalClasses: {
            type: String,
            default: ''
        },
        closeOnOverlayClick: {
            type: Boolean,
            default: true
        },
        closeOnEscape: {
            type: Boolean,
            default: true
        },
        autofocus: {
            type: Boolean,
            default: true
        },
        hideHeaderClose: {
            type: Boolean,
            default: false
        },
        disablePortal: {
            type: Boolean,
            default: false
        },
        isDialogOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        openSidebarModal() {
            this.$refs.utdSidebarModal.open();
            this.$refs.utdSidebar.open();
        },
        closeModal() {
            this.$refs.utdSidebarModal.close();
        },
        closeSidebar(options) {
            this.$refs.utdSidebar.close(options);
        }
    }
};
</script>