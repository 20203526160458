<script>
/* This component is unfortunately still necessary, at least until we create some
   type of TopicView.vue component.

   This is currently a straight-up migration of the AngularJS "set-article-top.directive".

   This component performs the following functions:

   1. Ensures that both the topic outline, and topic article have the correct
      amount of offset from the topic toolbar across all devices type and
      application states. This should no longer be needed with a new TopicView
      component, or it is incorporated into that component.

   2. An initial attempt to retain the approximate topic article scroll position
      for tablet devices during an orientation change, based on scroll percentage.
      This logic can be incorporated into the TopicView component.

   3. Dismissing of the "Topic Tools" overflow menu when either the #topicOutline
      or #topicArticle containers are clicked, or scrolled.
      Ideally, this functionality should be incorporated into a ToolbarToolsMenu.vue
      component.

   4. Handles removing the body scrollLock on window resize, and when "Topic Tools"
      menu is dismissed. Again, this could be handled elsewhere, preferrably inside
      of a ToolbarToolsMenu.vue component.
*/
import { debounce } from '_acaSrc/utility/timers';
import { Listener } from '_acaSrc/utility/Events';
import { stringToNumeric } from '_acaSrc/utility/String';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    getWindow,
    isOrientationLandscape
} from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';
import { C_TOPIC, C_UI } from '_acaSrc/utility/constants';
import { SET_TOOLBAR_OVERFLOW_VISIBLE } from '_acaSrc/store/topic/topic.store';

const OUTLINE_TOGGLE_TOP_OFFSET_PX = 16;

export default {
    data() {
        return {
            topicOutlineEl: null,
            topicArticleEl: null,
            topicToolbarEl: null,
            topicGalleryEl: null,
            resizeDebounce: null,
            scrollDebounce: null,
            elementsAssigned: false,
            currentScrollPct: -10,
            maxScroll: 0,
            topicHeight: 0.0,
            lastWindowSize: {
                lastHeight: null,
                lastWidth: null
            }
        };
    },
    computed: {
        ...mapGetters('app', [
            'isTabletDevice',
            'isFixedToolbar'
        ]),
        ...mapGetters('topic', [
            'toolbarOverflowVisible',
            'isFitDialogShown',
            'isTitleCollapsed'
        ]),
        ...mapGetters('feature', [ 'isHeaderRedesign' ]),
        ...mapGetters('device', [ 'isDesktopView' ])
    },
    watch: {
        async isFixedToolbar() {
            await this.setTopicTops();
        },
        async isTitleCollapsed() {
            await this.setTopicTops();
        },
        async isFitDialogShown() {
            await this.setTopicTops();
        }
    },
    async mounted() {
        await this.$nextTick().then(this.setup);
    },
    beforeUnmount() {
        if (!this.elementsAssigned) {
            return;
        }

        this.clearListener(this.windowResizeListener);
        this.clearListener(this.windowScrollListener);
        this.outlineArticleEl.forEach(el => {
            el.removeEventListener('click', this.closeToolbarMenus);
            this.clearListener(this.outlineArticleScrollListener);
        });
    },
    methods: {
        ...mapActions('app', [ 'setListener', 'clearListener', 'evalBodyScrollLock' ]),
        ...mapActions('topic', [ 'whenTopicElementsStable', 'setArticleTop' ]),
        ...mapMutations('topic', [ SET_TOOLBAR_OVERFLOW_VISIBLE ]),
        async setup() {
            this.topicContainerEl = document.getElementById('topicContainer');
            this.topicOutlineEl = document.getElementById('topicOutline');
            this.topicArticleEl = document.getElementById('topicArticle');
            this.topicGalleryEl = document.getElementById('topicGraphicCollection');
            this.outlineArticleEl = Array.from(
                document.querySelectorAll('#topicOutline,#topicArticle'));
            this.topicToolbarEl = document.getElementById('topic-toolbar');
            this.outlineToggleEl = document.querySelector('#outlineToggle');
            this.articleInlineGfxEl = document.querySelector('#articleInlineGfx');

            if (!this.topicContainerEl
             || !this.topicOutlineEl
             || !this.topicArticleEl
             || !this.topicToolbarEl) {
                return;
            }

            this.elementsAssigned = true;

            this.setupResizeHandlers();
            this.setupScrollTracking();

            this.outlineArticleEl.forEach(el => {
                el.addEventListener('click', this.closeToolbarMenus);

                // Setup outline & article scroll handlers
                this.outlineArticleScrollListener = new Listener(el, 'scroll',
                    this.scrollDebounce, { passive: true });
                this.setListener(this.outlineArticleScrollListener);
            });

            await this.setTopicTops();
            this.setTopicScrollPercent();
        },
        setupResizeHandlers() {
            // Mobile web reports some scrolling as a 'resize' event, as the
            // viewport height can change. Account for this by tracking the
            // dimensions of the viewport to determine if it was a 'true' resize.
            this.lastWindowSize.lastWidth = document.documentElement.clientWidth;
            this.lastWindowSize.lastHeight = document.documentElement.clientHeight;

            // Setup resize handler
            this.resizeDebounce = debounce(this.onResize, C_UI.RESIZE_DELAY_MS);
            this.windowResizeListener = new Listener(getWindow(),
                'resize', this.resizeDebounce, { passive: true });
            this.setListener(this.windowResizeListener);
        },
        setupScrollTracking() {
            // Setup window scroll handler
            this.scrollDebounce = debounce(this.trackScrollPosition,
                C_UI.SCROLLING_CHECK_DELAY_LONG_MS);
            this.windowScrollListener = new Listener(getWindow(),
                'scroll', this.scrollDebounce, { passive: true });
            this.setListener(this.windowScrollListener);
        },
        async onResize() {
            const resizeResult = this.isTrueResizeEvent({
                lastWidth: this.lastWindowSize.lastWidth,
                lastHeight: this.lastWindowSize.lastHeight
            });
            if (resizeResult.isTrueResize) {
                this.lastWindowSize.lastWidth = resizeResult.newWidth;
                this.lastWindowSize.lastHeight = resizeResult.newHeight;

                new PubSub().publish('wkutd.close-toolbar-menus');
                this.evalBodyScrollLock();
                this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);

                await this.setTopicTops();
                this.setTopicScrollPercent();
            }
        },
        closeToolbarMenus() {
            this.$nextTick(() => {
                new PubSub().publish('wkutd.close-toolbar-menus');
                if (this.toolbarOverflowVisible) {
                    this.evalBodyScrollLock();
                    this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
                }
            });
        },
        trackScrollPosition() {
            if (this.topicArticleEl.scrollTop && this.maxScroll) {
                this.currentScrollPct = this.topicArticleEl.scrollTop / this.maxScroll;
            }
            if (this.toolbarOverflowVisible && !this.isHeaderRedesign) {
                new PubSub().publish('wkutd.close-toolbar-menus');
                this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
            }
        },
        setTopicScrollValues() {
            const topicContentEl = document.getElementById('topicContent');
            if (!topicContentEl
                || typeof topicContentEl.offsetHeight === 'undefined') {
                return false;
            }

            const topicContentCss = topicContentEl.currentStyle
                || window.getComputedStyle(topicContentEl);

            const marginTop = stringToNumeric(topicContentCss.marginTop);
            const marginBtm = stringToNumeric(topicContentCss.marginBottom);

            if (Number.isNaN(marginTop) || Number.isNaN(marginBtm)) {
                return false;
            }

            this.topicHeight = Math.round((marginTop + marginBtm) + topicContentEl.offsetHeight);

            this.viewportHeight = this.topicArticleEl.offsetHeight;
            this.maxScroll = this.topicHeight - this.viewportHeight - 1;

            return true;
        },
        setTopicScrollPercent() {
            if (!this.setTopicScrollValues()) {
                return;
            }

            if (this.currentScrollPct > -10) {
                const scrollPos = this.maxScroll * this.currentScrollPct;
                this.topicArticleEl.scrollTop = scrollPos;
            }
        },
        async setTopicTops() {
            await this.whenTopicElementsStable();

            this.toolbarTopPx = this.topicToolbarEl.offsetTop;
            this.toolbarHeightPx = this.topicToolbarEl.offsetHeight;
            this.toolbarBottomPx = this.topicToolbarEl.offsetTop + this.topicToolbarEl.offsetHeight;
            this.topicContainerTopPx = this.topicContainerEl.getBoundingClientRect().top;

            this.setOutlineTop();
            this.setOutlineTogglePosition();
            this.setArticleTop({
                articleEl: this.topicArticleEl,
                toolbarEl: this.topicToolbarEl,
                containerEl: this.topicContainerEl,
                articleInlineGfxEl: this.articleInlineGfxEl
            });
            this.setGraphicsGalleryTop();
        },
        setOutlineTop() {
            if (this.isDesktopView) {
                // Unfortunately, the "important" here is necessary to override equivelant
                // specificty in our LESS files.
                // We should be able to remove this after the topic container refactor tech
                // investment.
                this.topicOutlineEl.style.setProperty('top', `${this.toolbarBottomPx}px`, 'important');
                this.topicOutlineEl.style.paddingTop = 0;
            }
            else if (this.isFitDialogShown) {
                this.topicOutlineEl.style.paddingTop
                    = `${this.toolbarHeightPx + C_TOPIC.FIT_HEIGHT}px`;
            }
            else {
                this.topicOutlineEl.style.removeProperty('top');
                this.topicOutlineEl.style.paddingTop
                    = `${this.toolbarHeightPx}px`;
            }
        },
        setOutlineTogglePosition() {
            if (!this.outlineToggleEl) {
                return;
            }
            this.outlineToggleEl.style.top
                = `${this.toolbarBottomPx + OUTLINE_TOGGLE_TOP_OFFSET_PX}px`;
        },
        setGraphicsGalleryTop() {
            if (this.isTabletDevice && isOrientationLandscape()) {
                this.topicGalleryEl.style.top = `${this.toolbarHeightPx}px`;
                this.topicGalleryEl.style.paddingTop = 0;
            }
            else if (this.isDesktopView) {
                this.topicGalleryEl.style.paddingTop = `${this.toolbarHeightPx}px`;
                this.topicGalleryEl.style.height = 'auto';
            }
            else {
                this.topicGalleryEl.style.paddingTop = 0;
                this.topicGalleryEl.style.top = `${this.toolbarBottomPx}px`;
                this.topicGalleryEl.style.height = `calc(100vh - ${this.toolbarBottomPx}px)`;
            }
        }
    },
    render: () => null
};
</script>
