<template>
  <div id="outerGraphicContainer">
    <utd-copyright-provider id="graphics-copyright" :full="true" />

    <!-- eslint-disable-next-line vue/no-v-html -->
    <article id="graphicContainer" v-html="grades.gradeHtml" />
  </div>
</template>

<script>
import UtdCopyrightProvider from '_acaSrc/components/shared/providers/UtdCopyrightProvider.vue';

export default {
    components: {
        UtdCopyrightProvider
    },
    props: {
        grades: {
            type: Object,
            default() {
                return {};
            }
        }

    }
};
</script>
