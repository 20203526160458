import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isIosDevice } from '_acaSrc/utility/Browsers';

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                utdLockScroll() {
                    if (!isIosDevice()) {
                        disableBodyScroll();
                    }
                    else {
                        document.body.style.overflow = 'hidden';
                    }
                },
                utdUnlockScroll() {
                    if (!isIosDevice()) {
                        enableBodyScroll();
                    }
                    else {
                        document.body.style.overflow = 'initial';
                    }
                }
            }
        });
    }
};
