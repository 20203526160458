import utdRest from '_acaSrc//utility/http/UtdRestHooks';
import Logger from '_acaSrc/utility/Logger';
import { getWindow } from '_acaSrc/utility/DOM';
import { isInsideAnkiFrame } from '_acaSrc/utility/Browsers';

export const BOOKMARK_BUFFER_PAUSE_MS = 1500;

export const makeProfileObject = () => {
    return {
        data: [],
        isLoadingResults: false,
        error: false,
        loaded: 0
    };
};

export const getInitialState = () => {
    return {
        history: makeProfileObject(),
        bookmarks: makeProfileObject(),
        mostViewed: makeProfileObject(),
        isMyUtdView: false,
        tooltip: {
            message: '',
            show: false,
            logged: false,
            hideTip: false,
            showTip: false
        },
        bookmarkBufferDelayMs: 0,
        myUpToDate: {
            permissions: {
                myUpToDateWidget: false,
                bookmarksWidget: false,
                historyWidget: false,
                mostViewedWidget: false,
                bookmarks: false,
                cmeMoc: false,
                cmeTicker: false,
                downloadCenterVisible: false,
                hasDevices: false,
                hasUsername: false,
                history: false,
                mostViewed: false,
                myAccount: false,
                myUpToDate: false,
                recurringBilling: false,
                userCanMerge: false,
                hasFilteredAutoComplete: false,
                widgetTab: false,
                widgetTabPref: 0,
                messaging: false,
                isShowWidget: false,
                bookmarkLimit: 0,
                topicsThatChanged: false
            },
            myUpToDateMessage: {},
            activeBookmarkTooltips: []
        },
        lastRefresh: {
            history: 0,
            mostViewed: 0,
            bookmarks: 0
        },
        defaultContentTab: 0,
        userProfile: {
            pendoId: false,
            pendoStateMessage: '',
            optimizelyWebInstalled: false,
            optimizelyWebInitialized: false,
            visitor: {
                id: '',
                localeLanguage: '',
                isProspect: false,
                isEMRAuthenticated: '',
                isLoggedIn: '',
                isRegistered: '',
                isGuestUser: '',
                environment: '',
                accountIdSource: '',
                activeAccountIds: '',
                deviceType: '',
                deviceSize: '',
                userBrowserWidthPx: '',
                userBrowserHeightPx: '',
                isInsideAnkiFrame: false,
                cmeCredits: 0,
                icgContent: true,
                isLegacy: false,
                isRunCampaigns: false,
                labI: false
            },
            account: {
                id: '',
                productCodes: '',
                subscriptionNumbers: ''
            }
        },
        profileRefreshInterval: 60000,
        welcomeName: '',
        info: {
            userEmail: '',
            oidcLoginHint: '',
            isKeepOneAccountRequest: false
        }
    };
};

const state = getInitialState();

export const getters = {
    userProfile: state => state.userProfile,
    userProfileVisitor: state => state.userProfile.visitor,
    userProfileAccount: state => state.userProfile.account,
    history: state => state.history,
    historyData: state => state.history.data,
    historyLoaded: state => state.history.loaded,
    bookmarks: state => state.bookmarks,
    bookmarksData: state => state.bookmarks.data,
    bookmarksLoaded: state => state.bookmarks.loaded,
    mostViewed: state => state.mostViewed,
    mostViewedData: state => state.mostViewed.data,
    isMyUtdView: state => state.isMyUtdView,
    permissions: state => state.myUpToDate.permissions,
    myUpToDateMessage: state => state.myUpToDate.myUpToDateMessage,
    tooltip: state => state.tooltip,
    bookmarkBufferDelayMs: state => state.bookmarkBufferDelayMs,
    lastRefreshByType: state => {
        return type => {
            if (type === 'history') {
                return state.lastRefresh.history;
            }
            else if (type === 'mostViewed') {
                return state.lastRefresh.mostViewed;
            }
            else if (type === 'bookmarks') {
                return state.lastRefresh.bookmarks;
            }
            return 0;
        };
    },
    isShowMyUtdWidget: state => state.myUpToDate.permissions.isShowWidget,
    hasMyUpToDate: state => state.myUpToDate.permissions.myUpToDate,
    isHasStateCme: state => state.myUpToDate.permissions.cmeMoc,
    defaultContentTab: state => state.defaultContentTab,
    isBookmarkTooltipActive: state => tooltipId => {
        // The tooltip ID at index 0 should be treated as the only "active" tooltip.
        return state.myUpToDate.activeBookmarkTooltips[0] === tooltipId;
    },
    profileRefreshInterval: state => state.profileRefreshInterval,
    welcomeName: state => state.welcomeName,
    userEmail: state => state.info.userEmail,
    oidcLoginHint: state => state.info.oidcLoginHint,
    isKeepOneAccountRequest: state => state.info.isKeepOneAccountRequest
};

export const SET_MYUPTODATE_PERMISSIONS = 'SET_MYUPTODATE_PERMISSIONS';
export const SET_MYUPTODATE_MESSAGE = 'SET_MYUPTODATE_MESSAGE';
export const PROFILE_REMOVE_BOOKMARK = 'PROFILE_REMOVE_BOOKMARK';
export const TOGGLE_TOOLTIP_HIDE = 'TOGGLE_TOOLTIP_HIDE';
export const SET_TOOLTIP_HIDETIP = 'SET_TOOLTIP_HIDETIP';
export const SET_TOOLTIP_SHOW = 'SET_TOOLTIP_SHOW';
export const SET_TOOLTIP_SHOWTIP = 'SET_TOOLTIP_SHOWTIP';
export const SET_TOOLTIP_LOGGED = 'SET_TOOLTIP_LOGGED';
export const SET_TOOLTIP_MESSAGE = 'SET_TOOLTIP_MESSAGE';
export const SET_LAST_REFRESH_HISTORY = 'SET_LAST_REFRESH_HISTORY';
export const SET_LAST_REFRESH_BOOKMARKS = 'SET_LAST_REFRESH_BOOKMARKS';
export const SET_LAST_REFRESH_MOST_VIEWED = 'SET_LAST_REFRESH_MOST_VIEWED';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const CLEAR_MOST_VIEWED = 'CLEAR_MOST_VIEWED';
export const SET_IS_MY_UTD_VIEW = 'SET_IS_MY_UTD_VIEW';
export const RESET_PROFILE = 'RESET_PROFILE';
export const RESET_TOOLTIP = 'RESET_TOOLTIP';
export const SET_LAST_REFRESH_BY_TYPE = 'SET_LAST_REFRESH_BY_TYPE';
export const SET_BOOKMARK_BUFFER_DELAY = 'SET_BOOKMARK_BUFFER_DELAY';
export const RESET_BOOKMARK_BUFFER_DELAY = 'RESET_BOOKMARK_BUFFER_DELAY';
export const SET_WIDGET_TAB_PREF = 'SET_WIDGET_TAB_PREF';
export const SET_SHOW_WIDGET = 'SET_SHOW_WIDGET';
export const SET_BOOKMARK_LIMIT = 'SET_BOOKMARK_LIMIT';
export const SET_TOPICS_THAT_CHANGED = 'SET_TOPICS_THAT_CHANGED';
export const CLEAR_WHATS_NEW_INDICATORS = 'CLEAR_WHATS_NEW_INDICATORS';
export const SET_PROFILE_ITEM = 'SET_PROFILE_ITEM';
export const SET_BOOKMARKS_IS_EDITABLE = 'SET_BOOKMARKS_IS_EDITABLE';
export const INCREMENT_HISTORY_LOADED = 'INCREMENT_HISTORY_LOADED';
export const INCREMENT_BOOKMARKS_LOADED = 'INCREMENT_BOOKMARKS_LOADED';
export const SET_DEFAULT_CONTENT_TAB = 'SET_DEFAULT_CONTENT_TAB';
export const SET_USER_PROFILE_INFO = 'SET_USER_PROFILE_INFO';
export const SET_PROFILE_REFRESH_INTERVAL = 'SET_PROFILE_REFRESH_INTERVAL';
export const SET_WELCOME_NAME = 'SET_WELCOME_NAME';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_OIDC_LOGIN_HINT = 'SET_OIDC_LOGIN_HINT';
export const SET_KEEP_ONE_ACCOUNT_REQUEST = 'SET_KEEP_ONE_ACCOUNT_REQUEST';
export const INITIALIZE_BOOKMARK_TOOLTIP = 'INITIALIZE_BOOKMARK_TOOLTIP';
export const CLEANUP_BOOKMARK_TOOLTIP = 'CLEANUP_BOOKMARK_TOOLTIP';

export const mutations = {
    [SET_MYUPTODATE_PERMISSIONS]: (state, permissions) => {
        state.myUpToDate.permissions = permissions;
    },
    [SET_MYUPTODATE_MESSAGE]: (state, message) => state.myUpToDate.myUpToDateMessage = message,
    [PROFILE_REMOVE_BOOKMARK]: (state, index) => {
        if (index > -1 && index < state.bookmarks.data.length) {
            state.bookmarks.data.splice(index, 1);
        }
    },
    [TOGGLE_TOOLTIP_HIDE]: state => state.tooltip.hideTip = !state.tooltip.hideTip,
    [SET_TOOLTIP_HIDETIP]: (state, hideTip) => state.tooltip.hideTip = hideTip,
    [SET_TOOLTIP_SHOW]: (state, show) => state.tooltip.show = show,
    [SET_TOOLTIP_SHOWTIP]: (state, show) => state.tooltip.showTip = show,
    [SET_TOOLTIP_LOGGED]: (state, logged) => state.tooltip.logged = logged,
    [SET_TOOLTIP_MESSAGE]: (state, message) => state.tooltip.message = message,
    [CLEAR_HISTORY]: state => state.history.data = [],
    [CLEAR_MOST_VIEWED]: state => state.mostViewed.data = [],
    [SET_IS_MY_UTD_VIEW]: (state, isMyUtdView) => state.isMyUtdView = isMyUtdView,
    [RESET_PROFILE]: state => {
        state.history = makeProfileObject();
        state.bookmarks = makeProfileObject();
        state.mostViewed = makeProfileObject();
        state.lastRefresh = {
            history: 0,
            mostViewed: 0,
            bookmarks: 0
        };
    },
    [RESET_TOOLTIP]: state => {
        state.tooltip.message = '';
        state.tooltip.show = false;
        state.tooltip.hideTip = false;
        state.tooltip.showTip = false;
    },
    [SET_LAST_REFRESH_BY_TYPE]: (state, payload) => {
        const { type, time } = payload;
        if (type === 'history') {
            state.lastRefresh.history = time;
        }
        else if (type === 'mostViewed') {
            state.lastRefresh.mostViewed = time;
        }
        else if (type === 'bookmarks') {
            state.lastRefresh.bookmarks = time;
        }
    },
    [SET_BOOKMARK_BUFFER_DELAY]: (state, delayMs) => state.bookmarkBufferDelayMs = delayMs,
    [RESET_BOOKMARK_BUFFER_DELAY]: state => state.bookmarkBufferDelayMs = 0,
    [SET_WIDGET_TAB_PREF]: (state, tabPref) => state.myUpToDate.permissions.widgetTabPref = tabPref,
    [SET_BOOKMARK_LIMIT]: (state, limit) => state.myUpToDate.permissions.bookmarkLimit = limit,
    [SET_TOPICS_THAT_CHANGED]:
        (state, changed) => state.myUpToDate.permissions.topicsThatChanged = changed,
    [CLEAR_WHATS_NEW_INDICATORS]: (state, topicId) => {
        // Scan History
        state.history.data.forEach(data => {
            data.historyItems.filter(item => item.contentId === topicId)
                .forEach(entry => entry.isHasNewWhatsNew = false);
        });
        // Scan Most Viewed
        state.mostViewed.data.filter(data => data.contentId === topicId)
            .forEach(entry => entry.isHasNewWhatsNew = false);
        // Scan Bookmarks
        state.bookmarks.data.filter(data => data.contentId === topicId)
            .forEach(entry => entry.isHasNewWhatsNew = false);
    },
    [SET_PROFILE_ITEM]: (state, payload) => {
        const { type, isLoadingResults, error, data } = payload;
        state[type].isLoadingResults = isLoadingResults;
        state[type].error = error;
        state[type].data = data || state[type].data;
    },
    [INCREMENT_HISTORY_LOADED]: state => {
        state.history.loaded++;
    },
    [INCREMENT_BOOKMARKS_LOADED]: state => {
        state.bookmarks.loaded++;
    },
    [SET_DEFAULT_CONTENT_TAB]: (state, tab) => {
        state.defaultContentTab = tab;
    },
    [SET_USER_PROFILE_INFO]: (state, data) => {
        state.userProfile.pendoStateMessage = '';
        try {
            if (data) {
                state.userProfile.visitor = data.visitor;
                state.userProfile.account = data.account;
                state.userProfile.visitor.userBrowserWidthPx = getWindow().innerWidth;
                state.userProfile.visitor.userBrowserHeightPx = getWindow().innerHeight;
                state.userProfile.visitor.deviceSize = data.deviceSize;
                state.userProfile.visitor.deviceType = data.deviceType;
                state.userProfile.visitor.isInsideAnkiFrame = isInsideAnkiFrame();
            }
        }
        catch (e) {
            state.userProfile.pendoStateMessage = `Error initializing pendo data: ${e}`;
        }
    },
    [SET_PROFILE_REFRESH_INTERVAL]: (state, profileRefreshInterval) => {
        state.profileRefreshInterval = profileRefreshInterval;
    },
    [SET_WELCOME_NAME]: (state, welcomeName) => state.welcomeName = welcomeName,
    [SET_USER_EMAIL]: (state, userEmail) => state.info.userEmail = userEmail,
    [SET_OIDC_LOGIN_HINT]: (state, oidcLoginHint) =>
        state.info.oidcLoginHint = oidcLoginHint,
    [SET_KEEP_ONE_ACCOUNT_REQUEST]: (state, isKeepOneAccountRequest) =>
        state.info.isKeepOneAccountRequest = isKeepOneAccountRequest,
    [INITIALIZE_BOOKMARK_TOOLTIP]: (state, key) => {
        state.myUpToDate.activeBookmarkTooltips.unshift(key);
    },
    [CLEANUP_BOOKMARK_TOOLTIP]: state => state.myUpToDate.activeBookmarkTooltips.shift()
};

export const actions = {
    isCanRefresh({ getters, commit }, type) {
        const time = new Date().getTime();
        const refreshInterval = getters.profileRefreshInterval;

        const lastRefresh = getters.lastRefreshByType(type);

        const refresh = (time - lastRefresh) > refreshInterval;
        if (refresh) {
            commit(SET_LAST_REFRESH_BY_TYPE, { type, time });
        }

        return refresh;
    },
    getProfileItem({ commit }, payload) {
        const { profileType, path, params } = payload;

        commit(SET_PROFILE_ITEM, {
            type: profileType,
            isLoadingResults: true,
            error: false
        });

        return utdRest('profile/get', { type: path, params })
            .then(data => {
                commit(SET_PROFILE_ITEM, {
                    type: profileType,
                    isLoadingResults: false,
                    error: false,
                    data
                });

                return true;
            })
            .catch(error => {
                commit(SET_PROFILE_ITEM, {
                    type: profileType,
                    isLoadingResults: false,
                    error: true
                });

                Logger.warn(
                    `Error trying to call ${path} error: ${error.errors} status:${error.status}`);
                return false;
            });
    },
    async getHistory({ dispatch, commit }, limit) {
        const curDate = new Date();
        const historyParams = {
            timezoneOffsetInMinutes: curDate.getTimezoneOffset()
        };
        if (limit) {
            historyParams.limit = limit;
        }

        const result = await dispatch('getProfileItem', {
            path: 'history',
            params: historyParams,
            profileType: 'history'
        });

        commit(INCREMENT_HISTORY_LOADED);

        return result;
    },
    async refreshHistory({ dispatch, commit }) {
        if (!await dispatch('isCanRefresh', 'history')) {
            commit(INCREMENT_HISTORY_LOADED);
            return true;
        }
        return await dispatch('getHistory', 100);
    },
    async getBookmarks({ dispatch, commit }) {
        const result = await dispatch('getProfileItem', {
            path: 'bookmark',
            params: null,
            profileType: 'bookmarks'
        });

        commit(INCREMENT_BOOKMARKS_LOADED);

        return result;
    },
    async refreshBookmarks({ dispatch }) {
        if (!await dispatch('isCanRefresh', 'bookmarks')) {
            return true;
        }
        return dispatch('getBookmarks').then(() => true);
    },
    getMostViewed({ dispatch }, limit) {
        const mostViewedParams = {};
        if (limit) {
            mostViewedParams.limit = limit;
        }
        return dispatch('getProfileItem', {
            path: 'most-viewed',
            params: mostViewedParams,
            profileType: 'mostViewed'
        });
    },
    async refreshMostViewed({ dispatch }) {
        if (!await dispatch('isCanRefresh', 'mostViewed')) {
            return true;
        }
        return dispatch('getMostViewed', 10).then(() => true);
    },
    deleteProfileItem(undefined, payload) {
        const { path, params } = payload;

        return utdRest('profile/delete', { type: path, params })
            .then(() => {
                return true;
            })
            .catch(error => {
                Logger.warn(
                    `Error trying to call ${path} error: ${error.errors} status:${error.status}`);
                return false;
            });
    },
    putProfileItem(undefined, payload) {
        const { path, params } = payload;

        return utdRest('profile/put', { type: path, params })
            .then(() => {
                return true;
            })
            .catch(error => {
                Logger.warn(
                    `Error trying to call ${path} error: ${error.errors} status:${error.status}`);
                return false;
            });
    },
    deleteBookmarks({ commit, dispatch }, payload) {
        const { bookmark, index } = payload;
        try {
            const params = {
                contentId: encodeURIComponent(bookmark.contentId),
                languageCode: bookmark.languageCode,
                contentType: bookmark.contentType
            };
            dispatch('deleteProfileItem', { path: 'bookmark/delete', params });

            commit(PROFILE_REMOVE_BOOKMARK, index);

            return true;
        }
        catch (e) {
            Logger.warn(`Error deleting bookmark: ${e}`);
            return false;
        }
    },
    deleteHistory({ dispatch }) {
        dispatch('deleteProfileItem', { path: 'history', param: {} });

        return true;
    },
    updateWidgetTabPreference({ commit }, tab) {
        // Save locally since we won't be reading it again right away
        commit(SET_WIDGET_TAB_PREF, tab);
        return utdRest('user/setting', {
            settingCode: 'my-uptodate-widget-tab-pref',
            settingValue: tab
        }).then(() => {
            return true;
        }).catch(e => {
            Logger.warn(`Error updating the user widget tab pref ${e}`);
            return false;
        });
    },
    resetBookmarkDelay({ commit }) {
        setTimeout(() => commit(SET_BOOKMARK_BUFFER_DELAY, 0), BOOKMARK_BUFFER_PAUSE_MS);
    },
    setUserProfileInfo({ rootGetters, commit }, data) {
        data.deviceSize = rootGetters['device/deviceSize'];
        data.deviceType = rootGetters['device/deviceType'];
        commit(SET_USER_PROFILE_INFO, data);
    }
};

const profile = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default profile;
