import utdRestClient from './UtdRestClient';
import {
    segmentOrEmptyString,
    makeRestUrl,
    makeTocPath
} from '_acaSrc/utility/http/UtdUrlUtilities';
import {
    setQueryParamValue,
    getQueryParamValues,
    setUrlOptionIfExists,
    setSearchUrlParamsHelper
} from '_acaSrc/utility/http';
import { getWindow } from '../DOM';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';

const restHooks = {};
const addHook = (key, callback) => restHooks[key] = callback;

export default function utdRest(type, options) {
    return restHooks[type](options);
}

addHook('app/init', id => {
    const uri = makeRestUrl(`init/json?id=${id}`);
    return utdRestClient.get({ uri });
});

addHook('topic/contributors', options => {
    const { language, topicId } = options;
    const uri = makeRestUrl(`contents/topic/${topicId}`,
        language,
        '/contributors/json');
    return utdRestClient.get({ uri });
});

addHook('pathway/contributors', options => {
    const { pathwayId } = options;
    return utdRestClient.get({ uri: `pathway/${pathwayId}/contributors/json` });
});

addHook('rxtransitions/contributors', () => {
    return utdRestClient.get({ uri: 'rxtransitions/contributors/json' });
});

addHook('topic/reviewers', options => {
    const { section, topicId } = options;
    return utdRestClient.get({
        uri: `contents/specialty/${section}/contributors/peer-reviewers/json?topicId=${topicId}`
    });
});

addHook('topic/disclosures', options => {
    const { language, topicId } = options;
    const uri = makeRestUrl(`contents/topic/${topicId}`,
        language,
        '/contributors/disclosures/json');
    return utdRestClient.get({ uri });
});

addHook('topic/lexi-all-contributors-disclosure', options => {
    const { language } = options;
    const uri = makeRestUrl('contents/topic',
        language,
        '/contributors/lexi-all-contributors-disclosure/html');
    return utdRestClient.get({ uri });
});

addHook('pathway/disclosures', options => {
    const { pathwayId } = options;
    return utdRestClient.get({ uri: `pathway/${pathwayId}/contributors/disclosures/json` });
});

addHook('topic/grades', options => {
    const { gradeParamsId } = options;
    return utdRestClient.get({ uri: `contents/grade/${gradeParamsId}/json` });
});

addHook('topic/abstracts', options => {
    const { language, topicId, abstractIdList } = options;
    const baseUrl = `contents/topic/${topicId}`;
    const appendPath = `/citation/${abstractIdList}/json`;
    const uri = makeRestUrl(baseUrl, language, appendPath);
    return utdRestClient.get({ uri });
});

addHook('profile/new-whats-new', params => {
    return utdRestClient.get({
        uri: '/profile/new-whats-new/2/json',
        params,
        config: { ignore403: true }
    });
});

addHook('contents/table-of-contents', options => {
    const { id, section, language } = options;
    const url = `contents/table-of-contents${makeTocPath(id, section)}`;
    const appendUrl = `/json?topicUrlType=webRelative&urlType=webRelative&languageCode=${language}`;
    return utdRestClient.get({ uri: `${url}${appendUrl}` });
});

addHook('contents/authors-and-editors', options => {
    const { specialty } = options;
    const baseUrl = `contents/authors-and-editors${segmentOrEmptyString(specialty)}`;
    const appendUrl = '/json?topicUrlType=webRelative&urlType=webRelative';
    return utdRestClient.get({ uri: `${baseUrl}${appendUrl}` });
});

addHook('/cme/topicViewEnd', lastCmeContentId => {
    const uri = setQueryParamValue('cme/topicViewEnd', 'content_id', lastCmeContentId);
    return utdRestClient.post({ uri });
});

addHook('cme/accrual/saveReflection', ({ searchCorrelationId, postData }) => {
    return utdRestClient.post({
        uri: `cme/accrual/saveReflection/${searchCorrelationId}/json`,
        data: postData
    });
});

addHook('cme/accrual/saveReflectionByAccrualId', ({ accrualId, postData }) => {
    return utdRestClient.post({
        uri: `cme/accrual/saveReflectionByAccrualId/${accrualId}/json`,
        data: postData
    });
});

addHook('share/content/json', postData => {
    return utdRestClient.post({
        uri: '/share/content/json',
        data: postData
    });
});

addHook('cme/reflectionData', () => {
    return utdRestClient.get({ uri: 'cme/reflectionQuestion/json' });
});

addHook('getFooter', () => {
    return utdRestClient.get({ uri: 'config/footer/json' });
});

addHook('cme/redeemInstantlyCreditCount', (config = {}) => {
    return utdRestClient.get({ uri: 'cme/accruals/unredeemed/reflected/count/json', config });
});

addHook('cme/moc/creditDetails', (options = {}) => {
    const { authCode, year } = options;
    return utdRestClient.get({ uri: `/cme/moc/${authCode}/${year}/json` });
});

addHook('showAutosuggestResults', postData => {
    return utdRestClient.put({
        uri: 'event/showAutosuggestResults/json',
        data: postData
    });
});

addHook('getConsolidateAccounts', postData => {
    const config = { bypass400: true, bypass403: true };
    return utdRestClient.post({
        uri: 'my-account/consolidate/validate',
        data: postData,
        config
    });
});

addHook('mergeAccounts', postData => {
    return utdRestClient.post({
        uri: 'my-account/consolidate/submit',
        data: postData
    });
});

addHook('user/setting', ({ settingCode, settingValue }) => {
    const uri = 'user/setting';
    const data = {
        value: {
            name: settingCode,
            value: settingValue
        }
    };
    const config = { ignore403: true };

    return utdRestClient.put({ uri, data, config })
        .catch(() => {
            // Some browsers don't handle PUT, but that's the proper REST call,
            // try POST if it doesn't work
            return utdRestClient.post({ uri, data, config });
        });
});

addHook('profile/get', ({ type, params }) => {
    return utdRestClient.get({
        uri: `/profile/${type}/json`,
        params,
        config: { bypassAll: true }
    });
});

addHook('support/feedback', options => {
    const { id } = options;
    const uri = makeRestUrl('support/feedback/json');
    return utdRestClient.post({
        uri,
        params: {
            ticketNumber: id
        },
        config: { bypass404: true }
    });
});

addHook('profile/post', ({ type, params }) => {
    return utdRestClient.post({
        uri: `/profile/${type}/json`,
        params,
        config: { bypassAll: true }
    });
});

addHook('profile/put', ({ type, params }) => {
    return utdRestClient.put({
        uri: `/profile/${type}/json`,
        data: {
            value: params
        },
        config: { ignore403: true }
    });
});

addHook('profile/delete', ({ type, params }) => {
    return utdRestClient.delete({
        uri: `/profile/${type}/json`,
        data: {
            value: params
        },
        config: { ignore403: true }
    });
});

addHook('dxy/getPharmaList', options => {
    const { topicId, dosageFormId, search } = options;
    // eslint-disable-next-line max-len
    const uri = `chinesedrugcontent/packageinserts/json?topicId=${topicId}&dosageFormId=${dosageFormId}&search=${search}`;
    return utdRestClient.get({ uri, config: { bypassAll: true } });
});

addHook('dxy/getPackageInsert', options => {
    const { topicId, search } = options;
    // eslint-disable-next-line max-len
    const uri = `chinesedrugcontent/packageinsertMonograph/json?topicId=${topicId}&search=${search}`;
    return utdRestClient.get({ uri, config: { bypassAll: true } });
});

addHook('dxy/getPanelContent', options => {
    const { topicId } = options;
    const uri = `chinesedrugcontent/drugs/json?topicId=${topicId}`;

    let promise = new UtdQueuedCache().getDeferred('utdSPAContentCache', uri);
    if (!promise) {
        promise = utdRestClient.get({ uri, config: { bypassAll: true } });
    }
    return promise;
});

addHook('share/content/json', postData => {
    return utdRestClient.post({
        uri: '/share/content/json',
        data: postData
    });
});

addHook('contents/outline/related-graphics', options => {
    const { topicId, topicLanguage, params } = options;
    const uri = makeRestUrl(`contents/outline/related-graphics/2/${topicId}/${topicLanguage}/json`);
    return utdRestClient.get({ uri, params });
});

addHook('contents/graphic/detailed', options => {
    const { language, id, search, source, params, rankIndex, searchRank, section, term } = options;
    let uri = makeRestUrl(
        `contents/graphic/detailed/${id}`,
        language, '/json');
    uri += params;
    uri = setUrlOptionIfExists(uri, options, 'id', id);
    uri = setUrlOptionIfExists(uri, options, 'search', search);
    uri = setUrlOptionIfExists(uri, options, 'source', source);
    uri = setUrlOptionIfExists(uri, options, 'section', section);
    uri = setUrlOptionIfExists(uri, options, 'term', term);
    uri = setSearchUrlParamsHelper(uri, { rankIndex, searchRank });
    return utdRestClient.get({ uri, config: { bypassAll: true } });
});

addHook('contents/graphic', options => {
    const { language, params } = options;
    const uri = makeRestUrl('contents/graphic', language, '/json');
    return utdRestClient.get({ uri,
        params,
        config: {
            bypass403: true,
            bypass404: true
        } });
});

addHook('event/graphic/json', params => {
    const uri = 'event/graphic/json';
    return utdRestClient.post({ uri, params, config: { bypassAll: true } });
});

addHook('rss/content/json', params => {
    const uri = 'rss/content/json';
    return utdRestClient.get({ uri, params });
});

addHook('topic/formulary/highlights', options => {
    const uri
        = `contents/topic/${options.topicId}/formulary/${options.formularyCode}/highlights/json`;
    return utdRestClient.post({ uri, config: { bypassAll: true }, data: options.drugList });
});

addHook('pathways/formulary/highlights', options => {
    const { pathwayId, formularyCode, drugIdArgs } = options;
    const uri
        = `contents/pathways/${pathwayId}/formulary/${formularyCode}/highlights/json?${drugIdArgs}`;
    return utdRestClient.get({ uri, config: { bypassAll: true } });
});

addHook('formularies/drugsForFormularies', options => {
    const { pathwaysId, drugIdArgs, params } = options;
    const uri = `contents/formularies/${pathwaysId}/drugsForFormularies/json?${drugIdArgs}`;

    return utdRestClient.get({ uri, params, config: { bypassAll: true } });
});

addHook('topic/formularies', options => {
    const { topicId, config = {} } = options;
    const uri = `contents/topic/${topicId}/formularies`;

    return utdRestClient.get({ uri, config });
});

addHook('topic/getTopic', options => {
    const { uri } = options;
    return utdRestClient.get({ uri,
        config: {
            bypass403: true,
            bypass404: true,
            bypass500: true
        } });
});

addHook('topic/cacheHitEvent', (options = {}) => {
    const { id, languageCode, uri } = options;
    const displayRank = getQueryParamValues(uri, 'display_rank') || undefined;
    const selectedTitle = getQueryParamValues(uri, 'selectedTitle') || undefined;
    const search = getQueryParamValues(uri, 'search') || undefined;
    let queryParams = getWindow().location.search || {};
    queryParams = setQueryParamValue(queryParams, 'id', id);
    queryParams = setQueryParamValue(queryParams, 'languageCode', languageCode);
    queryParams = setQueryParamValue(queryParams, 'display_rank', displayRank);
    queryParams = setQueryParamValue(queryParams, 'selectedTitle', selectedTitle);
    queryParams = setQueryParamValue(queryParams, 'search', search);
    return utdRestClient.post({
        uri: `event/topic/full/json${queryParams}`,
        config: { bypassAll: true }
    });
});

addHook('topic/findInTopic', params => {
    return utdRestClient.get({
        uri: 'contents/topic/find/json',
        params,
        config: { bypass403: true }
    });
});

addHook('search/cacheHitEvent', (options = {}) => {
    const { uri, params } = options;
    return utdRestClient.post({
        uri,
        params,
        config: { bypass400: true, bypass403: true }
    });
});

addHook('search/getSearch', options => {
    const { uri, params } = options;
    return utdRestClient.getWithAbort({
        uri,
        params,
        config: { bypass403: true, bypass404: true }
    });
});

addHook('search/passage-retrieval', options => {
    const { uri, params } = options;
    return utdRestClient.getWithAbort({
        uri,
        params,
        config: { bypassAll: true }
    });
});

addHook('search/getTopicOutline', options => {
    const { params, uri } = options;
    return utdRestClient.get({ uri, params, config: { ignore403: true } });
});

addHook('search/cacheHitEvent', (options = {}) => {
    return utdRestClient.post({
        uri: 'event/outline/topic/json',
        params: options.params,
        config: { bypassAll: true }
    });
});

addHook('search/autoComplete', uri => {
    let promise = new UtdQueuedCache().getDeferred('utdSPAAutoComplete', uri);
    if (!promise) {
        promise = utdRestClient.getWithAbort({ uri, config: { ignore403: true } });
    }
    return promise;
});

addHook('topic/logTopicUsageSynchronous', params => {
    utdRestClient.postSync('/services/app/event/topicUsage/bundle/json', params);
});

addHook('legal/agreement', params => {
    let uri = 'legal/agreement/';
    if (params.templateName !== '') {
        uri += `${params.templateName}/json`;
    }
    return utdRestClient.get({ uri });
});

addHook('legalSet/agreement', () => {
    const uri = 'legal/agreement/json?includeHtml=false';
    return utdRestClient.get({ uri });
});

addHook('settings/policies', () => {
    const uri = 'policies/json';
    return utdRestClient.get({ uri });
});

addHook('auth/login', form => {
    const uri = 'login/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    const config = { bypass403: true };
    const data = form;
    return utdRestClient.post({
        uri,
        data,
        headers,
        config
    });
});

addHook('auth/ssoMerge', form => {
    const uri = 'merge/sso/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    const config = { bypassAll: true };
    const data = form;
    data.source = 'merge';
    return utdRestClient.post({
        uri,
        data,
        headers,
        config
    });
});

addHook('auth/validateCode', params => {
    const uri = 'code/validate/json';
    const data = {};
    return utdRestClient.post({ uri, data, params });
});

addHook('auth/initCode', () => {
    const uri = 'code/json';
    return utdRestClient.get({ uri });
});

addHook('auth/sendNewCode', () => {
    const uri = 'code/generate/json';
    return utdRestClient.get({ uri });
});

addHook('auth/resetPassword', data => {
    const uri = 'password/reset/3/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    const config = { bypass403: true, bypass409: true };
    return utdRestClient.post({
        uri,
        data,
        headers,
        config
    });
});

addHook('auth/changePassword', data => {
    const uri = 'password/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    const config = { bypass403: true };
    return utdRestClient.post({
        uri,
        data,
        headers,
        config
    });
});

addHook('user/language', async language => {
    const uri = 'localization/user';
    const lang = {
        language
    };

    try {
        return await utdRestClient.put({
            uri,
            data: {
                value: language
            }
        });
    }
    catch {
        return await utdRestClient.post({ uri, data: lang });
    }
});

addHook('localization/file', (langCode = 'en') => {
    const uri = `localization/file?lang=${langCode}`;
    return utdRestClient.getStream({ uri });
});

addHook('myAccount/Info', () => {
    const uri = 'my-account/json';
    return utdRestClient.get({ uri });
});

addHook('myAccount/deactivateDevice', ({ deviceId }) => {
    const uri = `user/device/${deviceId}/json`;
    return utdRestClient.delete({ uri, config: { bypassAll: true } });
});

addHook('myAccount/downloadDesktop', params => {
    const uri = 'my-account/initiate-download';
    return utdRestClient.get({ uri, params, config: { bypassAll: true } });
});

addHook('myAccount/changePassword', data => {
    const uri = 'password/update-password/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    const config = { bypassAll: true };
    return utdRestClient.post({
        uri,
        data,
        headers,
        config
    });
});

addHook('myAccount/changeUsername', data => {
    const uri = 'my-account/update-username/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    return utdRestClient.post({ uri, data, headers, config: { bypassAll: true } });
});

addHook('profile/submitContact', data => {
    const uri = 'profile/contact/submit';
    const headers = { 'Content-Type': 'application/json' };
    return utdRestClient.post({ uri, data, headers, config: { bypassAll: true } });
});

addHook('profile/countriesStates', params => {
    const uri = 'profile/consts/countries-states';
    return utdRestClient.get({ uri, params, config: { bypassAll: true } });
});

addHook('profile/myAccountOptions', params => {
    const uri = 'profile/consts/myaccount-options';
    return utdRestClient.get({ uri, params, config: { bypassAll: true } });
});

addHook('myAccount/sendVerificationCode', data => {
    const uri = 'my-account/contact-information/send-verification-code/json';
    const headers = { 'Content-Type': 'application/json' };
    return utdRestClient.post({ uri, data, headers, config: { bypassAll: true } });
});

addHook('anki/download', () => {
    const uri = 'download/anki-download';
    return utdRestClient.get({
        uri,
        config: { bypassAll: true }
    });
});

addHook('anki/downloadInfo', () => {
    const uri = 'anki-info/download/json';
    return utdRestClient.get({ uri });
});

addHook('wayf/institutions/siaw', params => {
    const uri = 'wayf/institutions/siaw/json';
    return utdRestClient.get({
        uri,
        params,
        config: { bypassAll: true }
    });
});

addHook('wayf/url', data => {
    const uri = 'wayf/url/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    return utdRestClient.post({ uri, data, headers, config: { bypassAll: true } });
});

addHook('logout', params => {
    const uri = 'logout/json';
    return utdRestClient.delete({ uri, params, config: { bypassAll: true } });
});

addHook('wayf/institutions/disclaimer-email', params => {
    const uri = 'wayf/institutions/disclaimer-email/json';
    return utdRestClient.get({
        uri,
        params,
        config: { bypassAll: true }
    });
});

addHook('localization/accept-agreement', params => {
    const uri = 'agreement/machine-translation/accept';
    return utdRestClient.post({
        uri,
        params,
        config: { bypassAll: true }
    });
});

addHook('localization/agreement-status', params => {
    const uri = 'agreement/machine-translation/status';
    return utdRestClient.get({
        uri,
        params,
        config: { bypassAll: true }
    });
});

addHook('captcha/validate', data => {
    const uri = 'captcha/validate/json';
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
    return utdRestClient.post({
        uri,
        data,
        headers,
        config: { bypassAll: true }
    });
});
// Note: be sure to include bypass/error options
// in the "config" parameter if present in pre-migration code
