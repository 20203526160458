<script>
import { mapGetters } from 'vuex';
import { safeTimeout } from '_acaSrc/utility/timers';
import { parseDomForAttributeValue } from '_acaSrc/utility/DOM';
import { C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';

const TRANSITION_DELAY_SHORT_MS = 500;
const TRANSITION_DELAY_MED_MS = 1000;
const TRANSITION_DELAY_LONG_MS = 1500;

export default {
    data() {
        return {
            cancelRescanLinksTimeout: null
        };
    },
    computed: {
        ...mapGetters('app', [ 'clickEventUrls' ])
    },
    mounted() {
        // On successful $transition, and receiving custom PubSub message,
        // scan all page links to determine if we need to attach handler
        new PubSub().subscribe('wkutd.successful-transition', () => {
            this.rescanLinksForUiClickEvents(TRANSITION_DELAY_SHORT_MS);
        });

        // $transitions.onSuccess, even after 500ms, sometimes fires before every element
        // has been added to the DOM by angular during first paint. These shims are not elegant
        // but should fix this race condition in the vast majority of cases.
        this.rescanLinksForUiClickEvents(TRANSITION_DELAY_MED_MS);
        this.rescanLinksForUiClickEvents(TRANSITION_DELAY_LONG_MS);
    },
    beforeUnmount() {
        this.cancelRescanLinksTimeout && this.cancelRescanLinksTimeout();
    },
    methods: {
        recordUiClickEvent(e) {
            const eleTarget = e.currentTarget || e.target || e.srcElement;
            if (!eleTarget) {
                return;
            }
            /*
                Attempt to not log UI click event for external links
                if element has confirmBeforeNavigate class and target is not '_blank'
            */
            if (eleTarget.classList.contains('confirmBeforeNavigate')
                    && eleTarget.getAttribute('target') !== '_blank') {
                return;
            }
            // Attempt to get href of element, walking up DOM until one is found
            const attrHref = parseDomForAttributeValue('href', eleTarget);

            // Attempt to get ID of element, walking up DOM until one is found
            const attrId = parseDomForAttributeValue('id', eleTarget);

            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                targetUrl: attrHref,
                uiElementName: attrId
            });
        },
        rescanLinksForUiClickEvents(delayMS) {
            // Wait until dom content finishes rendering...
            const { cancelTimeout } = safeTimeout(() => {
                // Retrieve list of all anchor tags on the page
                const anchors = document.querySelectorAll('a');
                // If we have at least one anchor tag...
                if (!anchors.length) {
                    return;
                }
                // Begin looping through all child anchor tags found
                for (let a = 0; a < anchors.length; a++) {
                    // Isolate anchor tag as AngularJS element
                    const anchor = anchors[a];
                    const href = anchor.getAttribute('href');
                    this.matchHrefToUrls(href, anchor);
                }

            }, delayMS, { cancelHook: true }) || {};
            this.cancelRescanLinksTimeout = cancelTimeout;
        },
        matchHrefToUrls(href, anchor) {
            // Make sure href exists and that it does not have a valid track attribute
            if (!href || anchor.getAttribute('track')) {
                return;
            }
            // Determine if 'href' contains match to any of the required urls
            for (let u = 0; u < this.clickEventUrls.length; u++) {
                if (href.match(this.clickEventUrls[u])) {
                    anchor.onclick = this.recordUiClickEvent;
                    anchor.setAttribute('track', 'enabled');
                    break;
                }
            }
        }
    },
    render: () => null
};
</script>
