<template>
  <div>
    <label class="wkce-checkbox wkce-checkbox--color-primary wkce-checkbox--box-position-left"
           :class="[{'is-checked': checked},
                    {'is-disabled': disabled},
                    {'is-active': isActive}]">
      <div class="wkce-checkbox__checkmark-container">
        <input type="checkbox"
               class="wkce-checkbox__input"
               v-bind="$attrs"
               @click="onClick"
               @keypress="onKeypress"
               @focus="onFocus"
               @blur="onBlur"
               @change="$emit('checkbox-changed', $event.target.checked)">
        <div class="wkce-checkbox__checkmark">
          <div class="wkce-checkbox__checkmark-background" />
          <div class="wkce-checkbox__focus-ring" />
        </div>
      </div>
      <div class="wkce-checkbox__label-text">
        <slot />
      </div>
    </label>
  </div>
</template>

<script>
import { C_KEYCODES } from '_acaSrc/utility/constants';

export default {
    inheritAttrs: false,
    emits: [ 'checkbox-changed' ],
    data() {
        return {
            isActive: false,
            checked: this.$attrs.checked,
            disabled: this.$attrs.disabled,
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    methods: {
        onClick() {
            this.checked = !this.checked;
        },
        onKeypress(e) {
            if (e.which !== this.ENTER_KEY) {
                return;
            }
            this.checked = !this.checked;
            e.preventDefault();
            this.$emit('checkbox-changed', this.checked);
        },
        onFocus() {
            this.isActive = true;
        },
        onBlur() {
            this.isActive = false;
        }
    }
};
</script>

<style scoped lang="less">
@import "~_acaAssets/global/variables";

.wkce-checkbox {
  position: relative;
  display: block;
  margin: 0;
}

.wkce-checkbox__input {
  position: absolute;
  opacity: 0;
}

.wkce-checkbox__checkmark-container {
  display: table-cell;
}

.wkce-checkbox__checkmark {
  background-color: @WK_UTD_WHITE_100;
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 8px;
  top: 2px;

  &::after {
    height: 7px;
    width: 3px;
    position: absolute;
    display: block;
    left: 6px;
    content: '';
    bottom: 3px;
    transform: rotate(45deg);

    .is-checked & {
      border-bottom: 0.125rem solid #fff;
      border-right: 0.125rem solid #fff;
      opacity: 1;
    }
  }

  .is-disabled & {
    cursor: default;
  }
}

.wkce-checkbox__checkmark-background {
  border: 1px solid @WK_UTD_GRAY_75;
  height: 100%;
  width: 100%;

  .is-checked & {
    background-color: @WK_UTD_BLUE_HOVER;
    border-color: @WK_UTD_BLUE_HOVER;
  }

  .is-disabled & {
    background-color: @WK_UTD_GRAY_15;
    border-color: @WK_UTD_GRAY_50;
  }

  .is-active & {
    outline: 2px solid @WK_TOPIC_FONT_COLOR;
  }
}

.wkce-checkbox__label-text {
  cursor: pointer;
  display: table-cell;

  .is-disabled & {
    color: @WK_UTD_GRAY_50;
    cursor: default;
  }
}

</style>
