import {
    Listener,
    onScrollEndEvent,
    processEventOptions,
    getAttributeValueFromEvent
} from '_acaSrc/utility/Events';
import { C_BROWSER, C_UI } from '_acaSrc/utility/constants';
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { debounce } from '_acaSrc/utility/timers';
import utdRestClient from '_acaSrc/utility/http/UtdRestClient';
import Logger from '_acaSrc/utility/Logger';

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                setScrollEndEvent(element, handler) {
                    const options = processEventOptions({ passive: true },
                        this.$store.getters['device/supportsEventOptions']);
                    onScrollEndEvent(element, handler, options);
                },
                setOrientationChangeListener(handler) {
                    const listener = new Listener(
                        getWindow(),
                        'orientationchange',
                        handler
                    );
                    this.setListener(listener);
                    return listener;
                },
                clearOrientationChangeListener(handler) {
                    const listener = new Listener(
                        getWindow(),
                        'orientationchange',
                        handler
                    );
                    this.clearListener(listener);
                },
                setScrollListener(element, handler) {
                    const listener = new Listener(
                        element,
                        'scroll',
                        debounce(handler,
                            C_UI.SCROLLING_CHECK_DELAY_FAST_MS),
                        { passive: true });

                    this.setListener(listener);
                    return listener;
                },
                clearScrollListener(listener) {
                    this.clearListener(listener);
                },
                setResizeListener(element, handler) {
                    const listener = new Listener(
                        element,
                        'resize',
                        debounce(handler,
                            C_UI.RESIZE_DELAY_FAST_MS),
                        { passive: true });

                    this.setListener(listener);
                    return listener;
                },
                clearResizeListener(listener) {
                    this.clearListener(listener);
                },
                setListener(payload) {
                    if (!payload) {
                        return;
                    }

                    const options = processEventOptions(payload.options,
                        this.$store.getters['device/supportsEventOptions']);

                    const {
                        eventTarget,
                        eventType,
                        eventHandler
                    } = payload;

                    if (eventTarget && eventType && eventHandler) {
                        eventTarget.addEventListener(eventType, eventHandler, options);
                    }
                },
                clearListener(payload) {
                    if (!payload) {
                        return;
                    }

                    const options = processEventOptions(payload.options,
                        this.$store.getters['device/supportsEventOptions']);

                    const {
                        eventTarget,
                        eventType,
                        eventHandler
                    } = payload;

                    if (eventTarget && eventType && eventHandler) {
                        eventTarget.removeEventListener(eventType, eventHandler, options);
                    }
                },
                trackAndNavigate(targetUrl, elementId, timeAllowedMS = 1500) {
                    // Use this to navigate to external urls while safely
                    // tracking clicks.
                    //
                    // Explanation: Ajax requests for a closed page are cancelled
                    // after a varying delay. This is usually not noticable on fast connections,
                    // but is a serious issue on mobile. This can cause ui click tracking to fail.
                    // To avoid this, this method tries to let the tracking finish first before
                    // navigating, within a timeboxed allowance of x ms to avoid user annoyance.

                    const timerPromise = new Promise(resolve => {
                        setTimeout(resolve, timeAllowedMS);
                    });

                    const trackingPromise = this.trackUiClickEvent({
                        targetUrl,
                        uiElementName: elementId
                    });

                    const navigate = () => {
                        getWindow().location.href = targetUrl;
                    };

                    return Promise.race([ timerPromise, trackingPromise ]).finally(navigate);
                },
                trackEvent(payload) {
                    const { eventUrl, eventParams } = payload;
                    return utdRestClient.post({
                        uri: eventUrl,
                        params: eventParams,
                        config: { bypassAll: true }
                    }).catch(e => {
                        Logger.warn(`Could not record event:${eventUrl},exception:${e}`);
                    });
                },
                // Instead of calling this directly please use:
                // new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, <eventData>)
                trackClickEvent(payload) {
                    const { event } = payload;

                    let targetUrl = payload.targetUrl;
                    if (typeof targetUrl === 'undefined') {
                        targetUrl = getAttributeValueFromEvent('href', event);
                    }

                    return this.trackUiClickEvent({
                        targetUrl,
                        uiElementName: getAttributeValueFromEvent('id', event)
                    });
                },
                // Instead of calling this directly please use:
                // new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, <eventData>)
                trackUiClickEvent(payload) {
                    const { targetUrl = '', uiElementName, optData,
                        contentId, searchTerm } = payload;
                    let stringifiedData;
                    try {
                        stringifiedData
                            = typeof optData === 'object'
                                ? JSON.stringify(optData)
                                : optData;
                    }
                    catch {
                        stringifiedData = optData;
                    }

                    return this.trackEvent({
                        eventUrl: 'event/uiClick/json',
                        eventParams: {
                            referringUrl: window.location.href,
                            targetUrl,
                            uiElementName,
                            optData: stringifiedData,
                            contentId,
                            searchTerm
                        }
                    });
                },
                isTrueResizeEvent(payload) {
                    const { lastWidth, lastHeight } = payload;
                    // Some mobile devices report a 'resize' event from scrolling.
                    // As you start to scroll the page down, the address
                    // & footer bar reduce in size,
                    // causing the height of the viewport to change.
                    // This method attempts to detect this,
                    // and will return an object containing following properties:
                    //
                    // isTrueResize: true if it is determined to be
                    // a fullresize event, false otherwise
                    // newWidth: new width of viewport (after resize)
                    // newHeight: new height of viewport (after resize)
                    let fullResize = true;

                    const docEle = getDocument().documentElement;
                    if (!docEle) {
                        return {
                            isTrueResize: false,
                            newWidth: lastWidth,
                            newHeight: lastHeight
                        };
                    }

                    const devWdt = docEle.clientWidth;
                    const devHgt = docEle.clientHeight;

                    const difWdt = Math.abs(lastWidth - devWdt);
                    const difHgt = Math.abs(lastHeight - devHgt);

                    if ((this.$store.getters['device/browserType'] !== C_BROWSER.TYPE_DESKTOP)
                        && (difWdt === 0)
                        && (difHgt === 0)) {
                        fullResize = false;
                    }
                    return {
                        isTrueResize: fullResize,
                        newWidth: devWdt,
                        newHeight: devHgt
                    };
                }
            }
        });
    }
};
