import { getWindow } from '../DOM';

let _urlScrollTrackerInstance = null;

export default class UrlScrollTracker {
    constructor() {
        if (_urlScrollTrackerInstance) {
            return _urlScrollTrackerInstance;
        }

        this.resetTracker();

        _urlScrollTrackerInstance = this;
    }

    get restoreLastPosition() {
        return this._doRestorePos;
    }

    set restoreLastPosition(bSet) {
        this._doRestorePos = bSet;
    }

    resetTracker() {
        this._doRestorePos = false;
        this._positions = [];
    }

    clearPosition(url) {
        delete this._positions[url];
    }

    setPosition(pos) {
        if (!pos) {
            pos = getWindow().scrollY || getWindow().pageYOffset;
        }
        this.setPositionForUrl(getWindow().location.href, pos);
    }

    setPositionForUrl(url, pos) {
        this._positions[url] = pos;
    }

    getPosition(url) {
        if (!url) {
            url = getWindow().location && getWindow().location.href;
        }
        return this._positions[url];
    }
}