<template>
  <utd-modal-dialog ref="alertDialog" @closed="handleClosed()">
    <template v-if="title" #header>{{ title }}</template>
    <slot />
    <template #footer>
      <utd-button id="closeDialogButton"
                  @click.prevent="close()">OK
      </utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import PubSub from '_acaSrc/utility/PubSub';

export default {
    components: {
        UtdModalDialog,
        UtdButton
    },
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    emits: [ 'closed' ],
    methods: {
        open() {
            this.$refs.alertDialog.open();
        },
        handleClosed() {
            new PubSub().publish('wkutd.closeMyAccountDialog');
        },
        close() {
            this.$emit('closed');
            this.$refs.alertDialog.close();
        }
    }
};
</script>