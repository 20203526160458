import { isVisitableLink } from '_acaSrc/utility/DOM';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';
import { urlContains } from './http';

const ONE_WEEK_DAYS = 7;

export function checkIfLinkVisited(link, isAnonymousUser) {
    if (!isVisitableLink(link) || isAnonymousUser) {
        return;
    }

    const promise = new UtdQueuedCache().getDeferred(
        'utdVisitedLinksCache',
        getGraphicRefClassForGraphicLinks(link)
    );

    promise && promise.then(isVisited => {
        isVisited && link.classList.add('visited');
    });
}

export function setLinkVisitedClass(link, isAnonymousUser, contentVersion) {
    if (!isVisitableLink(link) || isAnonymousUser) {
        return;
    }

    link.classList.add('visited');

    new UtdQueuedCache().setPersistent(
        'utdVisitedLinksCache',
        getGraphicRefClassForGraphicLinks(link),
        true,
        contentVersion,
        ONE_WEEK_DAYS
    );
}

// function to get graphic ref class if image key is present in the link.
// This is to set the visited link to all graphics which contain the same data in a page
export function getGraphicRefClassForGraphicLinks(link) {
    if (!urlContains('image?imageKey', link.href)) {
        return link.href;
    }

    const classAttribute = link.getAttribute('class');
    const regex = /graphicRef\d+/;
    const match = classAttribute.match(regex);

    if (match !== null && match.length) {
        return match[0];
    }

    return link.href;
}