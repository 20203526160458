<template>
  <nav @click="clickInto">
    <ul v-hover-delay="{ afterDelay: () => null,
                         afterUnhoverDelay: hideAcctNav,
                         sharedHoverState: 'accountNav' }"
        class="wk-nav"
        role="menu"
        aria-label="account"
        @blur="hideAcctNav">

      <li v-if="permissions.myUpToDate"
          :id="`navHistory${idModifier}`"
          :class="{'separator': isNotDesktopView}">
        <a :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.HISTORY}`"
           role="menuitem"  
           @click="showPersonalizationModal($event, myUtdTabs.HISTORY)">
          {{ $t('MYUPTODATE.HISTORY') }}
        </a>
      </li>

      <li v-if="permissions.myUpToDate"
          :id="`navViewed${idModifier}`">
        <a role="menuitem"
           :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.MOST_VIEWED}`"
           @click="showPersonalizationModal($event, myUtdTabs.MOST_VIEWED)">
          {{ $t('MYUPTODATE.MOST_VIEWED') }}
        </a>
      </li>

      <li v-if="permissions.myUpToDate"
          :id="`navBookmarks${idModifier}`">
        <a role="menuitem"
           :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.BOOKMARKS}`"
           @click="showPersonalizationModal($event, myUtdTabs.BOOKMARKS)">
          {{ $t('MYUPTODATE.BOOKMARKS') }}
        </a>
      </li>

      <li v-if="showMyAccount"
          :id="`navAccount${idModifier}`"
          class="separator">
        <a role="menuitem"
           href="#"
           @click="openMyAccountFlyout($event)">
          {{ $t('MYUPTODATE.MY_ACCOUNT') }}
        </a>
      </li>

      <li v-show="isShowCmeInDropdown"
          :id="`navCme${idModifier}`"
          :class="{'separator': !showNavAccount && !showMyAccount}">
        <a :id="navCmeId"
           :href="dashboardLink"
           role="menuitem">
          <utd-cme-ticker />
        </a>
      </li>

      <li v-if="isNotDesktopView"
          :id="`navSettings${idModifier}`"
          :class="{'separator': !isShowCmeInDropdown && !showNavAccount}">
        <a href="/settings"
           role="menuitem">
          {{ $t('MYACCOUNT.SETTINGS') }}
        </a>
      </li>

      <li :id="`navLanguage${idModifier}`"
          @keypress="$event.which === ENTER_KEY && openChangeLanguageDialog($event)">
        <a class="no-redirect"
           role="menuitem"
           tabindex="0"
           @click="openChangeLanguageDialog($event)">
          Language
        </a>
      </li>

      <li v-if="showAnkiInfo" :id="`navAnkiDownload${idModifier}`">
        <a role="menuitem"
           href="#"
           tabindex="0"
           @click="showAnkiDownloadInfo()">
          {{ $t('ANKI.TITLE') }}
        </a>
      </li>

      <li v-if="showManageCookiesLink"
          :id="`navCookies${idModifier}`">
        <a id="ot-sdk-btn"
           class="no-redirect ot-sdk-show-settings"
           :class="{'one-trust-mobile': isNotDesktopView}"
           role="menuitem"
           tabindex="0">
          {{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}
        </a>
      </li>

      <li v-if="isFooterLoaded" :id="`navHelp${idModifier}`">
        <a :href="getFooterUrl('help')"
           target="_blank"
           role="menuitem">
          {{ $t('HEADER.HELP') }}
        </a>
      </li>
    </ul>
    <ul class="wk-nav" role="menu">
      <li v-if="isRegister && !isLoggedIn"
          :id="`navRegister${idModifier}`">
        <a :href="navRegisterUrl"
           role="menuitem">
          {{ $t('LOGIN.REGISTER') }}
        </a>
      </li>

      <li v-if="!isLoggedIn"
          :id="`navLogin${idModifier}`">
        <a href="/login"
           role="menuitem">
          {{ $t('MISC.LOG_IN') }}
        </a>
      </li>

      <li v-if="isLoggedIn"
          id="navLogout">
        <a href="/logout"
           role="menuitem"
           @click="resolveLogout('/logout')">
          {{ $t('LOGIN.SIGN_OUT') }}
        </a>
      </li>
    </ul>
    <my-account-sidebar v-if="isLoggedIn" ref="myAccountRef" tabindex="-1" />
    <anki-download-modal-dialog v-if="showAnkiInfo" ref="ankiDownloadRef" />
    <my-account-error-dialog ref="myAccountModalErrorRef" />
  </nav>
</template>

<script>
import UtdCmeTicker from '_acaSrc/components/header/UtdCmeTicker.vue';
import AnkiDownloadModalDialog from '../shared/stdlib/AnkiDownloadModalDialog.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { C_KEYCODES,
    C_MYUTD_TABS,
    C_CME_UI_LINKS,
    C_EVENTS,
    C_MY_ACCOUNT,
    C_ACCOUNT } from '_acaSrc/utility/constants';
import { SET_LOGIN_ERROR_MESSAGE,
    SET_LOGIN_INFO_MESSAGE } from '_acaSrc/store/login.store';
import { SET_MYACCOUNT_SIDEBAR_REQUEST } from '_acaSrc/store/app.store';
import { rebindCookieManagerLink } from '_acaSrc/utility/OneTrustCookieTool';
import MyAccountSidebar from './my-account/MyAccountSidebar.vue';
import { getDocument } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';
import MyAccountErrorDialog from '_acaSrc/components/header/my-account/MyAccountErrorDialog.vue';
import HoverDelay from '_acaSrc/directives/UtdHoverDelay.directive';

export default {
    directives: {
        HoverDelay
    },
    components: {
        UtdCmeTicker,
        MyAccountSidebar,
        AnkiDownloadModalDialog,
        MyAccountErrorDialog
    },
    emits: [ 'hide-nav', 'click-into' ],
    data() {
        return {
            myUtdTabs: C_MYUTD_TABS,
            ENTER_KEY: C_KEYCODES.ENTER_KEY,
            dashboardLink: C_CME_UI_LINKS.dashboard
        };
    },
    computed: {
        ...mapGetters('app', [
            'isUccState',
            'router',
            'myAccountSidebarRequestStatus',
            'isDisasterRecovery'
        ]),
        ...mapGetters('footer', [ 'getFooterUrl', 'isFooterLoaded' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('account', [ 'myAccountAccessLevel' ]),
        ...mapGetters('cme', [
            'showTicker',
            'cmeTickerId'
        ]),
        ...mapGetters('login', [ 'loginHref', 'isRegister' ]),
        ...mapGetters('device', [
            'isDesktopView',
            'isNotDesktopView',
            'showManageCookiesLink',
            'isAnkiAddon'
        ]),
        ...mapGetters('user', [ 'userLoggedIn' ]),
        showNavAccount() {
            return this.isLoggedIn && this.permissions.myAccount && this.isDesktopView;
        },
        showMyAccount() {
            return this.isLoggedIn
                && this.permissions.myAccount;
        },
        isLoggedIn() {
            return this.userLoggedIn;
        },
        idModifier() {
            return this.isNotDesktopView ? '_mobile' : '';
        },
        isShowCmeInDropdown() {
            return this.isLoggedIn
                && this.showTicker
                && this.isNotDesktopView;
        },
        navCmeId() {
            const qualifier = this.isDesktopView ? 'Account' : 'Hamburger';
            return this.cmeTickerId(qualifier);
        },
        navRegisterUrl() {
            return `${this.loginHref}?source=header`;
        },
        showAnkiInfo() {
            return !this.isUccState && !this.isAnkiAddon;
        }
    },
    watch: {
        myAccountSidebarRequestStatus(newValue) {
            if (newValue === C_MY_ACCOUNT.SIDEBAR_REQUEST.APPROVED) {
                this.openMyAccountFlyout();
            }
        }
    },
    mounted() {
        this.setup();
    },
    beforeUnmount() {
        this.unsubscribe({
            eventName: 'RESPONSIVE_TRANSITION',
            handlerFn: this.onResponsiveTransition
        });
    },
    methods: {
        ...mapMutations('login', [
            SET_LOGIN_ERROR_MESSAGE,
            SET_LOGIN_INFO_MESSAGE
        ]),
        ...mapMutations('app', [ SET_MYACCOUNT_SIDEBAR_REQUEST ]),
        ...mapActions('app', [
            'publish',
            'rootScopeDigest',
            'openChangeLanguageDialog',
            'subscribe',
            'unsubscribe'
        ]),
        ...mapActions('login', [ 'resolveLogout' ]),
        ...mapActions('account', [ 'getMyAccountInfo' ]),
        showPersonalizationModal(event, tab) {
            this.publish({
                eventName: 'wkutd.open-myuptodate-modal',
                eventData: { event, tab }
            });
        },
        setSidebarVisible() {
            if (this.myAccountSidebarRequestStatus === C_MY_ACCOUNT.SIDEBAR_REQUEST.APPROVED
             && !getDocument().querySelector('.my-account-sidebar')) {
                this.openMyAccountFlyout();
            }
        },
        showAnkiDownloadInfo() {
            this.logUiClickEvent('ankiDownloadInfo');
            this.$refs.ankiDownloadRef.openModal();
        },
        async openMyAccountFlyout($event) {
            $event && $event.preventDefault();
            if (this.isDisasterRecovery) {
                this.$refs.myAccountModalErrorRef.openModal();
                return;
            }
            await this.getMyAccountInfo();
            this[SET_MYACCOUNT_SIDEBAR_REQUEST](C_MY_ACCOUNT.SIDEBAR_REQUEST.SHOWN);
            if (this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.UNREGISTERED) {
                this.$refs.myAccountModalErrorRef.openModal();
            }
            else if (this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.NOT_LOGGED_IN) {
                this[SET_MYACCOUNT_SIDEBAR_REQUEST](C_MY_ACCOUNT.SIDEBAR_REQUEST.PENDING);
                this[SET_LOGIN_INFO_MESSAGE]({
                    title: C_ACCOUNT.FORM_MESSAGING.ERROR_TITLE_LOGIN_REQUIRED,
                    error: C_ACCOUNT.FORM_MESSAGING.ERROR_MESSAGE_MY_ACCOUNT_LOGIN,
                    level: 'warn'
                });
                this.router.url('/login');
            }
            else {
                this.$refs.myAccountRef.open();
                await this.$nextTick();
                this.$refs.myAccountRef.$el.focus();
            }
        },
        hideAcctNav() {
            this.$emit('hide-nav');
        },
        clickInto() {
            this.$emit('click-into');
        },
        setup() {
            this.subscribe({
                eventName: 'RESPONSIVE_TRANSITION',
                handlerFn: this.onResponsiveTransition
            });
            this.setSidebarVisible();
        },
        onResponsiveTransition() {
            rebindCookieManagerLink();
        },
        logUiClickEvent(element) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: `accountMenu_${element}`
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@media only screen and (min-width: 768px) {
  .isDesktop.utdWkHeader #wkUtdWelcomeName {
    .utdAcctNav {
      position: absolute;
      top: 34px;
      right: 0;
      width: 250px;
      background-color: @WKCE-WHITE;
      border: @DS1-UTD-GRAY-SINGLE-BORDER;
      box-shadow: 0 0 4px 0 @DS1-BOX-SHADOW-THUMB-RGBA;
      z-index: @ZINDEX-ACCOUNT-NAV;

      .wk-nav {
        .ds1-ma-0();
        .ds1-pa-0();
        display: block;
        float: none;

        > li {
          float: none;

          &.separator {
            border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
          }

          > a {
            padding: 0.5rem 1.5rem;
            cursor: pointer;
            color: @WKCE-GRAY-SHADE2;

            &:hover,
            &:focus {
              background-color: @WKCE-BLUE-TINT5;
            }

            /* Styles below include the !important rule to override styles
              applied to the button via the OneTrust script, which also use !important */
            &#ot-sdk-btn.ot-sdk-show-settings {
              color: @WKCE-GRAY-SHADE2 !important;
              border: none !important;
              font-size: 15px;
              padding: 0.5rem 1.5rem;

              &:hover {
                color: @WKCE-GRAY-SHADE2 !important;
                background-color: @WKCE-BLUE-TINT5 !important;
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }

      &.hidden-nav {
        display: none;

        &.active-nav {
          display: block;
        }
      }
    }
  }
}

.no-redirect {
  cursor: pointer;
}
</style>
