<script>
import { getDocument, getWindow } from '_acaSrc/utility/DOM';
import { mapActions } from 'vuex';

const topicTablesSelector = '.drugTopic table';
const topicTextId = 'topicText';

export default {
    data() {
        return {
            scrollListener: null,
            resizeListener: null,
            cloneHolderElem: null,
            cloneHolderTableElem: null,
            cloneHolderTableShadow: null,
            drugTopicTables: null,
            topicTextElem: null,
            activeTableElem: null,
            toolBarElem: null
        };
    },
    mounted() {
        this.activate();
    },
    beforeUnmount() {
        this.clearScrollListener(this.scrollListener);
        this.clearResizeListener(this.resizeListener);

        this.unsubscribe({
            eventName: 'wkutd.relink-outline-events',
            handlerFn: this.reassignTopicTables
        });
    },
    methods: {
        ...mapActions('app', [
            'subscribe',
            'unsubscribe'
        ]),
        ...mapActions('topic', [
            'whenTopicElementsStable'
        ]),
        async activate() {
            await this.whenTopicElementsStable();
            this.drugTopicTables = document.querySelectorAll(topicTablesSelector);

            if (!this.drugTopicTables.length) {
                return;
            }
            this.topicTextElem = document.getElementById(topicTextId);
            this.toolBarElem = document.getElementById('topic-toolbar');

            this.createCloneHolder();
            this.scrollListener = this.setScrollListener(getWindow(), this.onScroll);
            this.resizeListener = this.setResizeListener(getWindow(), this.removeCloneHolder);

            this.subscribe({
                eventName: 'wkutd.relink-outline-events',
                handlerFn: this.reassignTopicTables
            });
        },
        reassignTopicTables() {
            this.removeCloneHolder();
            this.drugTopicTables = getDocument().querySelectorAll(topicTablesSelector);
            if (this.drugTopicTables.length) {
                this.topicTextElem = getDocument().getElementById(topicTextId);
                this.onScroll();
            }
        },
        createCloneHolder() {
            this.cloneHolderElem = document.createElement('DIV');
            this.cloneHolderElem.classList.add('clone-holder');
            this.cloneHolderElem.classList.add('drugH1Div');
            this.cloneHolderElem.innerHTML
                = '<table border="1" frame="border"></table>'
                  + '<div id="table-shadow-container" class="table-shadow-container" />';
            this.cloneHolderTableElem = this.cloneHolderElem.querySelector('table');
            this.cloneHolderTableShadow
                = this.cloneHolderElem.querySelector('.table-shadow-container');
        },
        onScroll() {
            const showCloneHolder = Array.from(this.drugTopicTables)
                .some(this.checkTableHolderVisibility);

            if (this.activeTableElem && !showCloneHolder) {
                this.removeCloneHolder();
            }
        },
        checkTableHolderVisibility(tableElement) {
            const theadElement = tableElement.getElementsByTagName('thead')[0];

            if (!theadElement) {
                // If table don't contains header, don't show clone holder
                return false;
            }

            const collapsedParent = theadElement.closest('.collapsible-indication.collapsed');
            if (collapsedParent) {
                return false;
            }

            // Use toolbar offset top, to know utd header height, it is flexible (e.g. banner).
            const toolbarOffset = this.toolBarElem.clientHeight + this.toolBarElem.offsetTop;
            const theadOffsetTop = theadElement.getBoundingClientRect().top - toolbarOffset;
            const tableOffsetTop = tableElement.getBoundingClientRect().top - toolbarOffset;

            // Check if table header was scrolled under the toolbar.
            // And table body still present on view
            if (theadOffsetTop < 0
                && tableOffsetTop + tableElement.clientHeight - theadElement.clientHeight > 0) {
                return this.processCloneHolder(tableElement, theadElement);
            }
            return false;
        },
        processCloneHolder(tableElement, theadElement) {
            if (this.activeTableElem === tableElement) {
                // Clone holder already present.
                return true;
            }

            // Remove and then change clone holder if we already scroll fast on next table
            this.activeTableElem && this.removeCloneHolder();
            if (tableElement.clientWidth > this.topicTextElem.clientWidth) {
                // Do not show clone holder
                // if table width more then topic text width.
                // Can be on very small screen size or big tables
                return false;
            }

            // Copy table header
            const copiedTheadElem = theadElement.cloneNode(true);
            const copiedThNodeList = copiedTheadElem.querySelectorAll('th');

            theadElement.querySelectorAll('th').forEach((th, index) => {
                // Set cell width of cloned table header,
                // because cells can be empty and has different sizes
                copiedThNodeList[index].style.width = `${th.offsetWidth}px`;
            });

            const tableComputedStyle = getWindow().getComputedStyle(tableElement);
            let tableOffsetLeft = tableComputedStyle.getPropertyValue('margin-left');
            if (tableOffsetLeft && tableOffsetLeft !== '0px') {
                tableOffsetLeft = `${parseInt(tableOffsetLeft, 10)}px`;
                this.cloneHolderTableElem.style.marginLeft = tableOffsetLeft;
                this.cloneHolderTableShadow.style.marginLeft = tableOffsetLeft;
            }
            this.cloneHolderTableElem.insertAdjacentElement('afterBegin', copiedTheadElem);
            this.appendCloneHolder(tableElement);
            return true;
        },
        removeCloneHolder() {
            if (!this.activeTableElem) {
                return;
            }
            this.activeTableElem = null;
            const theadElem = this.cloneHolderTableElem.querySelector('thead');
            theadElem && theadElem.parentNode.removeChild(theadElem);
            this.cloneHolderTableElem.style.marginLeft = 0;
            this.cloneHolderTableShadow.style.marginLeft = 0;
            this.topicTextElem.removeChild(this.cloneHolderElem);
        },
        appendCloneHolder(elem) {
            this.activeTableElem = elem;
            this.cloneHolderElem.style.top
                = `${this.toolBarElem.clientHeight + this.toolBarElem.offsetTop}px`;
            this.topicTextElem.appendChild(this.cloneHolderElem);
        }
    },
    render: () => null
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@box-shadow-height: 12px;

#topicContainer.drugTopic #topicContent.utdArticleSection #topicText {
  .clone-holder {
    position: fixed;
    overflow-x: hidden;

    > table {
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 0;

      th {
        box-sizing: border-box;
      }
    }

    .table-shadow-container {
      position: relative;
      height: @box-shadow-height;
      overflow: hidden;

      &::after {
        position: absolute;
        width: 100%;
        height: @box-shadow-height;
        top: -$height;
        box-shadow: @DS1-BOX-SHADOW-RAISED;
        content: '';
      }

      &#table-shadow-container {
        // Protect margin-left changing in em,
        // should not differ from cloneholder table
        font-size: 16px;
      }
    }
  }
}
</style>
