import { shallowRef, ref, computed } from 'vue';
import { getWindow } from '_acaSrc/utility/DOM';
import {
    getUrlHash,
    isDifferentDomain,
    isAbsoluteUrl
} from '_acaSrc/utility/http';
import { normalizeLocation } from './util/location';
import { cleanPath } from './util/path';
import { setStateKey, genStateKey } from './util/state-key';
import { stringifyQuery } from './util/query';
import { pushStateLocationPlugin, servicesPlugin, UIRouter } from '@uirouter/core';
import UrlParser from '_acaSrc/utility/http/UrlParser';
import UIView from './components/UiView';
import UISref from './components/UiSref';

const IGNORE_URI_REGEXP = /^\s*(javascript|mailto):/i;

class UIRouterView extends UIRouter {
    constructor(options = {}) {
        super();

        this.options = options;
        this.apps = new Set();
        this._globalClickListeners = [];
        this._beforeClickHash = '';
        this.started = false;
        this.options = options;

        if (typeof options.strict !== 'undefined') {
            this.urlService.config.strictMode(options.strict);
        }

        if (options.sortFn && typeof options.sortFn === 'function') {
            this.urlService.rules.sort(options.sortFn);
        }
    }

    install(app) {
        if (this.started) {
            return;
        }

        this._setupAppUnmounted(app);

        // main app previously initialized
        // return as we don't need to set up new history listener
        if (this.app) {
            return;
        }

        this.app = app;

        this.plugin(pushStateLocationPlugin);
        this.plugin(servicesPlugin);

        if (this.options.routes) {
            this.options.routes.forEach(route => {
                this.stateRegistry.register(route);
            });
        }

        this._setupGlobalClickHandler();

        // Provide utility method to set URL hash
        this.setUrlHash = urlHash => {
            this.urlService.hash(urlHash);
            window.location.assign(`${window.location.pathname}${window.location.search}#${urlHash}`);
        };        

        // Setup reactive currentRoute and currentProps computed
        // properties to reflect @uirouter/core's current state.
        this._routeRef = shallowRef(this.globals.current);
        this.currentRoute = computed({
            get: () => this._routeRef.value,
            set: value => {
                this._routeRef.value = value;
            }
        });

        this._routeProps = ref({});
        this.currentProps = computed({
            get: () => this._routeProps.value,
            set: value => {
                this._routeProps.value = value;
            }
        });

        this._setupPrimaryTransitionHooks();

        this.urlService.listen();
        this.urlService.sync();

        this.started = true;

        app.component('UiView', UIView);
        app.component('UiSref', UISref);

        app.config.globalProperties.$router = this;
        Object.defineProperty(app.config.globalProperties, '$route', {
            enumerable: true,
            get: () => this._routeRef.value
        });
    }

    _setupPrimaryTransitionHooks() {
        let routeProps = null;
        this.transitionService.onEnter({}, transition => {
            transition.to().instances = {};
        });
        this.transitionService.onCreate({}, () => {
            routeProps = this.currentRoute.value.componentProps;
        });
        this.transitionService.onSuccess({}, transition => {
            const current = transition.router.globals.current;
            current.query = transition.router.urlService.search();
            current.hash = transition.router.urlService.hash();

            // If component props have changed, trigger a UiView update
            if (JSON.stringify(current.componentProps) !== JSON.stringify(routeProps)) {
                this.currentProps.value = current.componentProps;
            }

            // Update the current route from @uirouter/core, which triggers a UiView refresh
            this.currentRoute.value = current;

            // Supports basic 'hashchange' event in history mode
            if (this._beforeClickHash !== getUrlHash()) {
                window.dispatchEvent(new window.HashChangeEvent('hashchange'));
            }
        });
    }

    _setupAppUnmounted(app) {
        const unmountApp = app.unmount;
        this.apps.add(app);
        app.unmount = function() {
            this.apps.delete(app);
            // the router is not attached to an app anymore
            if (this.apps.size < 1) {
                this.started = false;
            }
            unmountApp();
        };
    }

    // eslint-disable-next-line complexity
    _getAnchorTagElement(elem) {
        // Traverse the DOM up to find first <a> tag
        let nodeNameLc = elem
            && elem.nodeName
            && elem.nodeName.toLowerCase
            && elem.nodeName.toLowerCase();
        if (!nodeNameLc) {
            return;
        }

        while (nodeNameLc !== 'a') {
            // Ignore if <a> tag not found (reached root element, or no further parent found)
            if (nodeNameLc === 'html' || !(elem = elem.parentNode)) {
                return;
            }
            nodeNameLc = elem && elem.nodeName && elem.nodeName.toLowerCase();
        }

        return elem;
    }

    onGlobalClick(cb) {
        // Registers a callback to be processed during _globalClickHandler.
        // If the callback has a truthy result, then the remainder of the
        // global handler processing aborts.
        this._globalClickListeners.push(cb);
    }

    // eslint-disable-next-line complexity
    _globalClickHandler(event) {
        if (!event) {
            return;
        }

        if (event.ctrlKey
            || event.metaKey
            || event.shiftKey
            || event.which === 2
            || event.button === 2) {
            return;
        }

        let elem = event.target;
        elem = this._getAnchorTagElement(elem);
        // Abort processing if no anchor element found
        if (!elem) {
            return;
        }

        // Normalize absHref and relHref
        let elemHref = this._processQueryParams(this._normalizeHref(elem.getAttribute('href')));
        // Abort processing if just an empty #
        if (elemHref === '#') {
            event.preventDefault();
            return;
        }

        this._beforeClickHash = getUrlHash();

        // Run through any registered global click listeners
        let callbackAbort = false;
        this._globalClickListeners.every(cb => {
            // Abort further processing if callback returns truthy
            if (cb(event, elemHref)) {
                callbackAbort = true;
                return false;
            }
            return true;
        });
        if (callbackAbort) {
            return;
        }

        // Abort and allow the default click behavior if...
        // - No 'href' attribute exists
        // - href contains FQDN that doesn't match current domain (external link)
        // - 'Target' attribute exists on target element
        // - Event alredy has been default prevented
        // - Href starts with 'javascript:' or 'mailto:'
        const elemTarget = elem.getAttribute('target');
        if (!elemHref
            || isDifferentDomain(elemHref)
            || (elemTarget && elemTarget !== '_self')
            || event.defaultPrevented
            || IGNORE_URI_REGEXP.test(elemHref)) {
            return;
        }

        // If we get here, prevent default action on the click event
        event.preventDefault();

        const currentHref = getWindow().location.href;
        history.pushState(setStateKey(genStateKey()), '', elemHref);

        // Take no further action if the target href matches current href.
        if (!isAbsoluteUrl(elemHref)) {
            elemHref = `${getWindow().location.origin}${elemHref}`;
        }

        if (elemHref === currentHref) {
            return;
        }

        // Allow for UIRouter to process the URL
        this.urlService.sync();
    }

    _setupGlobalClickHandler() {
        getWindow().addEventListener('click', this._globalClickHandler.bind(this));
    }

    _normalizeHref(href) {
        if (!href) {
            return '';
        }
        // Ensure that any href containing just a hash with
        // a value is, converted to a relative URL.
        let retref = href;
        if (href.substr(0, 1) === '#' && href.length > 1) {
            retref = `${getWindow().location.pathname}${getWindow().location.search}${href}`;
        }
        return retref;
    }

    _processQueryParams(href) {
        // Ensures that all query params have any '+' chars
        // replaced with '%20' to match AngularJS processing.
        if (!href) {
            return;
        }

        const oParser = new UrlParser(href);
        if (!oParser.search) {
            return href;
        }

        const searchFor = oParser.search;
        const searchFix = oParser.search.replace(/\+/g, '%20');

        return href.replace(searchFor, searchFix);
    }

    // Utility method to set URL hash
    setUrlHash(urlHash) {
        this.urlService.hash(urlHash);
        window.location = `#${urlHash}`;
    }

    // Method to resolve specific state (Used by UiSref)
    resolve(to, current, append) {
        const location = normalizeLocation(to, current, append, this);
        location.fullPath = location.fullPath || getFullPath(location);

        // Ensure any query param values are encoded before attempting match,
        // otherwise an exception can be thrown inside of the @uirouter/core
        // during a match attempt.
        Object.keys(location.query).forEach(paramName => {
            location.query[paramName] = encodeURIComponent(location.query[paramName]);
        });

        const bestMatch = this.urlService.match({
            hash: location.hash,
            path: location.path,
            search: location.query
        });
        const route = bestMatch
            && bestMatch.rule
            && bestMatch.rule.state
            && bestMatch.rule.state.self;
        const href = createHref(this.options.base, location.fullPath, this.options.mode);
        return {
            location,
            route,
            href
        };
    }

    onCreate(criteria, fn) {
        this.transitionService.onCreate(criteria, transition => fn(transition));
    }

    onBefore(criteria, fn) {
        this.transitionService.onBefore(criteria, transition => fn(transition));
    }

    onStart(criteria, fn) {
        this.transitionService.onStart(criteria, transition => fn(transition));
    }

    onExit(criteria, fn) {
        this.transitionService.onExit(criteria, (transition, stateContext) =>
            fn(transition, stateContext));
    }

    onRetain(criteria, fn) {
        this.transitionService.onRetain(criteria, (transition, stateContext) =>
            fn(transition, stateContext));
    }

    onEnter(criteria, fn) {
        this.transitionService.onEnter(criteria, (transition, stateContext) =>
            fn(transition, stateContext));
    }

    onFinish(criteria, fn) {
        this.transitionService.onFinish(criteria, transition => fn(transition));
    }

    onSuccess(criteria, fn) {
        this.transitionService.onSuccess(criteria, transition => fn(transition));
    }

    addRule(...args) {
        this.urlService.rules.rule(...args);
    }

    addWhen(...args) {
        this.urlService.rules.when(...args);
    }

    setOtherwise(...args) {
        this.urlService.rules.otherwise(...args);
    }

    // Provide public accessors to internal @ui-router/core methods
    url(...args) {
        return this.urlService.url(...args);
    }

    // Our router does not support passing args (only getter)
    path() {
        return this.urlService.path();
    }

    hash(...args) {
        return this.urlService.hash(...args);
    }

    protocol(...args) {
        return this.urlService.protocol(...args);
    }

    host(...args) {
        return this.urlService.host(...args);
    }

    search(...args) {
        return this.urlService.search(...args);
    }

    go(...args) {
        return this.stateService.go(...args);
    }

    reload(...args) {
        return this.stateService.reload(...args);
    }

    transitionTo(...args) {
        return this.stateService.transitionTo(...args);
    }    
}

function createRouter(options) {
    const router = new UIRouterView(options);

    // router.transitionService.onCreate({}, transition => {
    //     console.log(`onCreate() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onBefore({}, transition => {
    //     console.log(`onBefore() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onStart({}, transition => {
    //     console.log(`onStart() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onExit({}, transition => {
    //     console.log(`onExit() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onRetain({}, transition => {
    //     console.log(`onRetain() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onEnter({}, transition => {
    //     console.log(`onEnter() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onFinish({}, transition => {
    //     console.log(`onFinish() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    // router.transitionService.onSuccess({}, transition => {
    //     console.log(`onSuccess() FROM=[${transition.from().name}] TO=[${transition.to().name}]`);
    // });

    return router;
}

function createHref(base, fullPath, mode) {
    const path = mode === 'hash' ? `#${fullPath}` : fullPath;
    return base && path.indexOf(base) === -1
        ? cleanPath(`${base}/${path}`)
        : path;
}

function getFullPath({ path, query = {}, hash = '' }) {
    return (path || '/') + stringifyQuery(query) + hash;
}

export { createRouter };