<!-- eslint-disable vue/no-v-html -->
<template>
  <ul v-show="showResultSubhits"
      role="list"
      :aria-hidden="ariaHiddenResultSubhits"
      :class="result.pageType"
      class="search-result-subhit-container">
    <li v-for="(subhit, idx) in result.searchResults"
        :key="idx"
        :class="subhit.type"
        class="search-result-subhit-item"
        role="listitem">
      <span v-if="isRoutedDrugSubhit && idx !== 0">|&nbsp;</span>
      <a :href="getSetSearchUrlWithParams(subhit, result)"
         :aria-label="getSubhitAreaLabel(subhit)"
         :target="isSubhitBlankTarget(subhit)"
         :class="subhit.subtype"
         class="search-result-subhit-link"
         @click="onSubhitClick(subhit, $event)"
         v-html="getSubhitTitle(subhit)" />
      <span v-if="isDrugFormulationSubhit">•</span>
      <span v-if="isDrugLandingSubhit" class="subhitBullet">•</span>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { C_PAGE_TYPE, C_SEARCH_RESULTS } from '_acaSrc/utility/constants';
import { getSetSearchUrlWithParams } from '_acaSrc/utility/contents/search/search';

export default {
    props: {
        result: Object
    },
    data() {
        return {
            RESULT_TYPE: C_SEARCH_RESULTS.RESULT_TYPE,
            getSetSearchUrlWithParams
        };
    },
    computed: {
        ...mapGetters('search', [ 'isCollapse', 'isGraphicsSearch' ]),
        showResultSubhits() {
            return this.result.searchResults
                && (!this.isCollapse || this.result.nestedResults);
        },
        ariaHiddenResultSubhits() {
            return this.isCollapse && !this.result.nestedResults;
        },
        isRoutedDrugSubhit() {
            return this.result.nestedResults
            && this.result.pageType === C_PAGE_TYPE.DRUG_LANDING_PAGE;
        },
        isDrugFormulationSubhit() {
            return this.result.nestedResults
                && this.result.pageType === C_PAGE_TYPE.FORMULATION_PAGE;
        },
        isDrugLandingSubhit() {
            return this.result.pageType !== C_PAGE_TYPE.DRUG_LANDING_PAGE;
        }
    },
    methods: {
        ...mapActions('topic', [
            'handleTopicLinkClick'
        ]),
        ...mapActions('graphic', [
            'handleUseGraphicLink'
        ]),
        isSubhitBlankTarget(subhit) {
            if (this.result.nestedResults || subhit.type !== C_SEARCH_RESULTS.RESULT_SUBHIT_TYPE.ICG) {
                return '_self';
            }
            return '_blank';
        },
        onSubhitClick(subhit, event) {
            if (this.result.nestedResult || subhit.type === C_SEARCH_RESULTS.RESULT_SUBHIT_TYPE.ICG) {
                return;
            }
            if (subhit.type === C_SEARCH_RESULTS.RESULT_TYPE.GRAPHIC) {
                this.handleUseGraphicLink({
                    evt: event,
                    options: { skipSidebarStateReset: this.isGraphicsSearch }
                });
            }
            else {
                this.handleTopicLinkClick(event);
            }
        },
        getSubhitAreaLabel(subhit) {
            if (this.result.nestedResults) {
                return `Search result nested result ${subhit.title} for topic ${this.result.title}`;
            }
            if (subhit.type !== C_SEARCH_RESULTS.RESULT_TYPE.GRAPHIC) {
                return `Search result topic section ${subhit.title} for topic ${this.result.title}`;
            }
            return `Search result graphic section ${subhit.title} for topic ${this.result.title}`;
        },
        getSubhitTitle(subhit) {
            if (this.result.nestedResults) {
                return subhit.label;
            }
            return subhit.title;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#searchresults {
  .subhitBullet {
    display: none;
  }

  .altsearchResults {
    .search-result {
      ul {
        li {
          .subhitBullet {
            .ds1-ml-1();
            display: inline-block;
            color: @WKCE-GRAY-TINT2;
            font-size: 15px;
          }

          &:last-child {
            .subhitBullet {
              display: none;
            }
          }
        }
      }

      .FORMULATION_PAGE {
        li {
          .subhitBullet {
            display: none;
          }
        }
      }
    }
  }
}

.search-result-subhit-item.graphic {
  .search-result-subhit-link::before {
    .ds1-mr-compact();
    .ds1-utd-image();
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 2px;
  }

  .graphic_figure::before {
    .ds1-utd-figure();
  }

  .graphic_table::before {
    .ds1-utd-table();
  }

  .graphic_movie::before {
    .ds1-utd-movie();
  }

  .graphic_algorithm::before {
    .ds1-utd-algorithm();
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    #searchresults {
      .utd-listView {
        .search-result-subhit-container {
          margin: 0;
          flex-basis: 100%;
        }

        .search-result-subhit-item {
          padding: 8px 20px 0 0;
        }

        .search-result-subhit-link {
          .ds1-utd-size-2();
          .ds1-utd-js2-link();
        }
      }
    }
  }
}
</style>
