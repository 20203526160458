<template>
  <div v-if="renderWidgetLinks"
       id="uptodateWidgetLinks">
    <ul>
      <li>
        <a id="myUtdHistory_mobile"
           :href="myUtdHistoryHref"
           @click="logClickEvent($event)"
           v-text="$t('MYUPTODATE.HISTORY')" />
      </li>
      <li>
        <a id="myUtdMostViewed_mobile"
           :href="myUtdMostViewedHref"
           @click="logClickEvent($event)"
           v-text="$t('MYUPTODATE.MOST_VIEWED')" />
      </li>
      <li>
        <a id="myUtdBookmarks_mobile"
           :href="myUtdBookmarksHref"
           @click="logClickEvent($event)"
           v-text="$t('MYUPTODATE.BOOKMARKS')" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_MYUTD_TABS, C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';

export default {
    computed: {
        ...mapGetters('search', [ 'searchBarBody' ]),
        ...mapGetters('profile', [ 'hasMyUpToDate' ]),
        ...mapGetters('device', [ 'isNotDesktopView' ]),
        myUtdHistoryHref() {
            return `/myuptodatemobile?myUtdTab=${C_MYUTD_TABS.HISTORY}`;
        },
        myUtdMostViewedHref() {
            return `/myuptodatemobile?myUtdTab=${C_MYUTD_TABS.MOST_VIEWED}`;
        },
        myUtdBookmarksHref() {
            return `/myuptodatemobile?myUtdTab=${C_MYUTD_TABS.BOOKMARKS}`;
        },
        renderWidgetLinks() {
            return this.searchBarBody
                && this.hasMyUpToDate
                && this.isNotDesktopView;
        }
    },
    methods: {
        logClickEvent(event) {
            new PubSub().publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
        }
    }
};
</script>