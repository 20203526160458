<template>
  <div class="utd-dialog-frame ds1-utd-font ds1-utd-js2"
       :class="[dialogClassesAll, !hasFooterSlot ? 'no-footer' : '']">
    <div class="utd-dialog-header">
      <span :class="dialogIcon" />
      <slot name="header">&nbsp;</slot>
      <button v-if="!hideHeaderClose"
              role="button"
              aria-label="close"
              class="wkce-icon-filled-close wkce-icon-button"
              @click="onClose" />
    </div>
    <div class="utd-dialog-content">
      <slot />
    </div>
    <div v-if="hasFooterSlot" class="utd-dialog-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
const dialogStyles = {
    error: 'utd-dialog-style-error',
    info: 'utd-dialog-style-info',
    warn: 'utd-dialog-style-warn',
    success: 'utd-dialog-style-success'
};

const sizes = {
    small: 'utd-dialog-modal-sm',
    medium:	'utd-dialog-modal-m',
    large: 'utd-dialog-modal-lg',
    extraLarge:	'utd-dialog-modal-xl'
};

const dialogIcons = {
    error: 'wkce-icon-filled-stop-sign',
    info: 'wkce-icon-filled-info',
    warn: 'wkce-icon-filled-caution',
    success: 'wkce-icon-filled-check'
};

export default {
    props: {
        // Display related icon left of header text, and
        // includes 4px tall colored bar at top of dialog.
        // Supports: error, info, warn or success
        dialogType: {
            type: String,
            default: ''
        },
        dialogSize: {
            type: String,
            default: ''
        },
        dialogClasses: {
            type: String,
            default: ''
        },
        // When true will hide the 'X' close button in header
        hideHeaderClose: {
            type: Boolean,
            default: false
        }
    },
    emits: ['closed' ],
    data() {
        return {
            dialogIconClass: ''
        };
    },
    computed: {
        dialogClassesAll() {
            const dialogClasses = this.dialogClasses;
            const typeStyle = dialogStyles[this.dialogType] || '';
            const sizeStyle = sizes[this.dialogSize] || '';
            return `${dialogClasses} ${sizeStyle} ${typeStyle}`;
        },
        dialogIcon() {
            return dialogIcons[this.dialogType] || '';
        },
        hasFooterSlot() {
            const ss = this.$slots;
            const footerNodes = ss && ss.footer && ss.footer();
            return footerNodes && footerNodes.length;
        }
    },
    methods: {
        onClose() {
            this.$emit('closed');
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-DIALOG-BGCLR: @DS1-MODAL-TOOLBAR-BG-COLOR;
@UTD-DIALOG-HEADER-TEXT-COLOR: @DS1-LIGHT-ON-DARK-FONT-COLOR;
@UTD-DIALOG-HEADER-LINE-COLOR: @DS1-UTD-GRAY-BORDER-COLOR;
@UTD-DIALOG-FOOTER-BGCLR: @DS1-UTD-GRAY-BG-COLOR;
@UTD-DIALOG-TYPE-SUCCESS: @DS1-UTD-SUCCESS;
@UTD-DIALOG-TYPE-ERROR: @DS1-UTD-ERROR;
@UTD-DIALOG-TYPE-INFO: @DS1-UTD-INFO;
@UTD-DIALOG-TYPE-WARN: @DS1-UTD-WARN;

.utd-dialog-frame {
  width: 90vw;
  max-height: 90vh;
  display: flex; /// only used for mobile-sized devices
  flex-direction: column;

  &.utd-dialog-modal-sm {
    max-width: 300px;
  }

  &.utd-dialog-modal-m {
    max-width: 500px;
  }

  &.utd-dialog-modal-lg {
    max-width: 800px;
  }

  &.utd-dialog-modal-xl {
    max-width: 1140px;
  }

  &.no-footer {
    .ds1-mb-0();
    max-height: calc(90vh - 32px);
  }

  @media only screen and (min-width: 768px) {
    min-width: 500px;
    display: block;

    &.utd-dialog-modal-sm {
      min-width: 300px;
    }
  }
}

.utd-dialog-header {
  .ds1-utd-h4();
  position: relative;
  font-weight: 300;
  color: @UTD-DIALOG-HEADER-TEXT-COLOR;
  height: auto;
  padding: 16px 27px 16px 16px;
  background-color: @UTD-DIALOG-BGCLR;
  border-bottom: none;

  .scrolled & {
    border-color: @UTD-DIALOG-HEADER-LINE-COLOR;
  }

  [class*="utd-dialog-style-"] & {
    border-top-width: 4px;
    border-top-style: solid;

    [class^="wkce-icon-filled-"] {
      margin-left: 0;
      margin-right: 0;

      &::before {
        .ds1-mr-1();
        font-size: 16px;
        position: relative;
        top: -1px;
      }
    }
  }

  .utd-dialog-style-success & {
    border-top-color: @UTD-DIALOG-TYPE-SUCCESS;

    .wkce-icon-filled-check::before {
      color: @UTD-DIALOG-TYPE-SUCCESS;
    }
  }

  .utd-dialog-style-error & {
    border-top-color: @UTD-DIALOG-TYPE-ERROR;

    .wkce-icon-filled-stop-sign::before {
      color: @UTD-DIALOG-TYPE-ERROR;
    }
  }

  .utd-dialog-style-info & {
    border-top-color: @UTD-DIALOG-TYPE-INFO;

    .wkce-icon-filled-info::before {
      color: @UTD-DIALOG-TYPE-INFO;
    }
  }

  .utd-dialog-style-warn & {
    border-top-color: @UTD-DIALOG-TYPE-WARN;

    .wkce-icon-filled-caution::before {
      color: @UTD-DIALOG-TYPE-WARN;
    }
  }
}

.wkce-icon-button {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
}


.utd-dialog-content {
  .ds1-pa-0();
  overflow-y: auto;

  @media only screen and (min-width: 768px) {
    // Can be deleted when desktop uses display:flex
    max-height: calc(90vh - 200px);
  }
}

.utd-dialog-footer {
  .ds1-pa-2();
  .ds1-pl-0();
  background-color: @UTD-DIALOG-FOOTER-BGCLR;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  .utd-button {
    .ds1-ml-2();
    display: block;
    flex: 0 0 auto;
    font-weight: 400;
    line-height: 35px;

    &:hover {
      border-color: unset;
    }
  }

  .utd-button-size-small {
    line-height: 32px;
  }
}

</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@UTD-DIALOG-XCLOSE-COLOR: @DS1-UTD-PRIMARY-BG-COLOR;

.wkce-icon-filled-close {
  .ds1-pa-1();
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 14px;
  line-height: 14px;
  width: auto;
  height: auto;
  transform: translateY(-50%);
  margin: 0;

  &::before {
    color: @UTD-DIALOG-XCLOSE-COLOR;
    font-size: 16px;
  }
}

.utd-alert-dialog {
  .utd-dialog-header {
    position: relative;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    color: @DS1-UTD-TITLE-TEXT-COLOR;
    height: 27px;
    padding: 40px 16px 15px 32px;
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border-bottom: 1px solid @DS1-UTD-PRIMARY-BG-COLOR;
  }

  .utd-dialog-content {
    padding: 0 32px 32px;
  }

  .wkce-icon-filled-close {
    .ds1-pa-0();
    right: 14px;
    top: 14px;
    width: 14px;
    height: 14px;
    transform: none;

    &::before {
      color: @DS1-BREAD-CRUMBS-TEXT-COLOR;
      font-size: 16px;
    }
  }
}

</style>