/**
 * Selects a subset of items from `array` and returns it as a new array.
 * This is a lite version of https://docs.angularjs.org/api/ng/filter/filter
 * that is compatible with Vue
 *
 * @param {Array} array The source array.
 * @param {string|Object} expression The predicate to be used for selecting items from `array`
 *
 * @return {Array} a new array
 */
export default function(array, expression) {
    if (!expression || !array) {
        return array;
    }
    let expressionKeys;
    let filterFn;

    const expressionSearch = item => {
        return expressionKeys.some(key => {
            if (typeof expression[key] === 'boolean') {
                return item[key] === expression[key];
            }
            if (typeof item[key] !== 'object') {
                return toLowerCase(item[key]).indexOf(toLowerCase(expression[key])) !== -1;
            }
            return false;
        });
    };

    const textSearch = item => {
        return Object.keys(item).some(key => {
            if (typeof item[key] === 'object' && item[key] !== null) {
                return textSearch(item[key]);
            }
            return toLowerCase(item[key]).toLowerCase().indexOf(toLowerCase(expression)) !== -1;
        });
    };

    const toLowerCase = text => {
        text = `${text}`;
        return text.toLowerCase();
    };

    switch (typeof expression) {
    case 'object':
        expressionKeys = Object.keys(expression);
        filterFn = expressionSearch;
        break;
    case 'string':
        filterFn = textSearch;
        break;
    default:
        break;
    }

    return Array.prototype.filter.call(array, filterFn);
}
