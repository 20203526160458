/* NOTE: For most scenarios, we should not use the methods/constants
** from this file. Instead, we should rely on the browser properties
** as returned from the server which are accessed through the conifg
** service, or through the app.store getters.
**
** In rare cases however, either due to wanting to keep a component
** clean of Vuex store dependencies, or to solve a weird one-off bug,
** then these browser testing methods are available.
*/

// Constant taken from body-scroll-lock to identify an iOS device
// eslint-disable-next-line max-len
// https://github.com/willmcpo/body-scroll-lock/blob/fe6bfea3a134f337e77e62ae380cdf29eac7216b/src/bodyScrollLock.js#L28
import { getWindow, getDocument } from '_acaSrc/utility/DOM';
import { setQueryParamValue } from '_acaSrc/utility/http';

export const isIosDevice = () => {
    let platform = '';
    if (getWindow().navigator.userAgentData) {
        platform = getWindow().navigator.userAgentData.platform;
    }
    else {
        platform = getWindow().navigator.platform;
    }

    return (/iP(ad|hone|od)/.test(platform)
            || (platform === 'MacIntel' && getWindow().navigator.maxTouchPoints > 1));
};

export const isInternetExplorer = () => {
    // References:
    // https://learningjquery.com/2014/06/how-to-detect-ie-11-using-javascript-jquery
    const userAgent = getWindow().navigator.userAgent || '';

    return userAgent.indexOf('MSIE') > -1
        || userAgent.indexOf('Trident/7.0') > -1;
};

export const isInsideAnkiFrame = () => {
    return getWindow().navigator
        && getWindow().navigator.userAgent
        && getWindow().navigator.userAgent.includes('utd.anki.addon');
};

export const browserHasHistory = () => {
    return getWindow().history.length > 1;
};

// function to set the browser tab title
export const setBrowserTabTitle = title => {
    getDocument().title = `${title} - UpToDate` || 'UpToDate';
};

export const setMetaTagNoindex = () => {
    if (getDocument().querySelector('meta[content="noindex"]')) {
        return;
    }
    const meta = getDocument().createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', 'noindex');
    const headEl = getDocument().getElementsByTagName('head')[0];
    headEl && headEl.appendChild(meta);
};

export const removeMetaTagNoindex = () => {
    const metaTagNoindexEl = getDocument().querySelector('meta[content="noindex"]');
    if (!metaTagNoindexEl) {
        return;
    }
    const headEl = getDocument().getElementsByTagName('head')[0];
    headEl && headEl.removeChild(metaTagNoindexEl);
};

/**
 * Modify the query string of the current location without reloading the page
 * @param {*} param query parameter to modify
 * @param {*} value pass in value to add or set a parameter, omit to delete parameter
 */
export const setQueryParamValueNoReload = (param, value) => {
    if (isInternetExplorer()) {
        return;
    }
    const newUrl = setQueryParamValue(getWindow().location.href, param, value);
    getWindow().history.replaceState({}, '', newUrl);
};

const BrowserUtils = {
    isInternetExplorer
};

export default BrowserUtils;
