import { C_RESPONSIVE, C_UI } from '_acaSrc/utility/constants';
import { pxToNum } from './String';
import { safeTimeout } from './timers';

import { nullUndefinedOrEmpty } from './Validators';

export const inBrowser = typeof window !== 'undefined';

const DomUtils = {
    inBrowser,
    elNextUntil,
    elAddClass,
    elRemoveClass,
    elGetSetText,
    getWindow,
    getDocument,
    elPrev,
    getSelection,
    getVerticalScrollbarWidth,
    getHorizontalScrollbarHeight,
    getMaxScrollY,
    getDimensions,
    isOrientationLandscape,
    createPositionManager,
    elHasOverflowedContent,
    hideKeyboard,
    goBack,
    removeElement,
    injectCss,
    isTablet,
    isSmallScreen,
    isTouchScreenDevice,
    scrollToZero,
    updateElementAttribute,
    updateHTMLClass,
    updateBodyClass,
    updateCanonicalUrl,
    updateMetaDescription
};

export default DomUtils;

// <JQLite helpers>
const isUndefined = v => typeof v === 'undefined';
const lowercase = string => isString(string) ? string.toLowerCase() : string;
const isString = value => typeof value === 'string';
const NODE_TYPE_ELEMENT = 1;
// eslint-disable-next-line no-unused-vars
const NODE_TYPE_ATTRIBUTE = 2;
const NODE_TYPE_TEXT = 3;
// eslint-disable-next-line no-unused-vars
const NODE_TYPE_COMMENT = 8;
// eslint-disable-next-line no-unused-vars
const NODE_TYPE_DOCUMENT = 9;
// eslint-disable-next-line no-unused-vars
const NODE_TYPE_DOCUMENT_FRAGMENT = 11;
const BOOLEAN_ATTR = {};
'multiple,selected,checked,disabled,readOnly,required,open'.split(',').forEach(function(value) {
    BOOLEAN_ATTR[lowercase(value)] = value;
});
// </JQLite helpers>

// Identifies tabbable elements
// eslint-disable-next-line max-len
const TABBY_SELECTOR = 'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

// Port of jQuery's "nextUntil()" function
export function elNextUntil(srcElement, classNames) {
    const elements = [];
    if (srcElement) {
        let tmp = srcElement.nextElementSibling,
            bAdd = true;
        while (tmp) {
            for (let c = 0; c < classNames.length; c++) {
                if (tmp.classList.contains(classNames[c])) {
                    bAdd = false;
                    break;
                }
            }
            if (bAdd) {
                elements.push(tmp);
                tmp = tmp.nextElementSibling;
            }
            else {
                tmp = null;
            }
        }
    }
    return elements;
}

// Port of jQuery's "prev()" fn
// eslint-disable-next-line complexity
export function elPrev(srcElement, classNames) {
    let tgtElement = null;
    if (!srcElement) {
        return tgtElement;
    }
    let tmp = srcElement.previousElementSibling;
    // eslint-disable-next-line eqeqeq
    while ((typeof tmp !== 'undefined') && (tmp != null) && (tgtElement == null)) {
        for (let c = 0; c < classNames.length; c++) {
            if (tmp.classList.contains(classNames[c])) {
                tgtElement = tmp;
                break;
            }
        }
        // eslint-disable-next-line eqeqeq
        if (tgtElement == null) {
            tmp = tmp.previousElementSibling;
        }
    }
    return tgtElement;
}

export function getSelection(_document = document, _window = window) {
    if (_window.getSelection) {
        return _window.getSelection();
    }
    // The following should get considered legacy, since window.getSelection is
    // currently supported in all of our supported browsers.
    else if (_document.getSelection) {
        return _document.getSelection();
    }
    else if (_document.selection) {
        const selection = _document.selection && _document.selection.createRange();
        selection.toString = function() {
            return this.text;
        };
        return selection;
    }
    return '';
}

export function getVerticalScrollbarWidth(element, browser = '') {
    let scrollbarWidthPx = 0;

    scrollbarWidthPx = getWindow().innerWidth - document.documentElement.clientWidth;
    if (element) {
        scrollbarWidthPx = element.offsetWidth - element.clientWidth;
    }

    scrollbarWidthPx += browser === 'Firefox' ? -2 : -1;

    return Math.max(scrollbarWidthPx, 0);
}

export function getHorizontalScrollbarHeight(element, browser = '') {
    let scrollbarHeightPx = 0;

    scrollbarHeightPx = getWindow().innerHeight - document.documentElement.clientHeight;
    if (element) {
        scrollbarHeightPx = element.offsetHeight - element.clientHeight;
    }

    scrollbarHeightPx += browser === 'Firefox' ? -2 : -1;

    return Math.max(scrollbarHeightPx, 0);
}

export function getMaxScrollY(obj, _document = document) {
    // NOTE: "obj" could be the "Window" oject, or a DOM element
    if (!obj || !_document.documentElement) {
        return 0;
    }

    // Cross-browser/device way to get max scroll height for Window or element
    const objScrollHeight = Math.max(_document.documentElement.scrollHeight,
        _document.documentElement.offsetHeight,
        obj.scrollHeight || 0);

    // Cross-browser/device way to get visible height for Window or element
    const objVisibleHeight = Math.max(_document.documentElement.clientHeight,
        obj.innerHeight || 0,
        obj.clientHeight || 0);

    return objScrollHeight > objVisibleHeight ? objScrollHeight - objVisibleHeight : 0;
}

export function getDimensions(el, _window = window) {
    // !! DEPRECATED !!
    // :: Do not use for new work, attempt to use native browser API calls directly
    if (!el) {
        return;
    }
    const rect = el.getBoundingClientRect();

    // Make sure element is not hidden (display: none) or disconnected
    if (rect.width || rect.height || el.getClientRects().length) {
        const _documentElement = el.ownerDocument.documentElement;

        return {
            top: rect.top + _window.pageYOffset - _documentElement.clientTop,
            left: rect.left + _window.pageXOffset - _documentElement.clientLeft,
            width: el.offsetWidth,
            height: el.offsetHeight
        };
    }

    return null;
}

export function isOrientationLandscape() {
    const devWdt = Math.max(document.documentElement.clientWidth, getWindow().innerWidth || 0);
    const devHgt = Math.max(document.documentElement.clientHeight, getWindow().innerHeight || 0);
    return (devWdt > devHgt);
}

// From https://github.com/angular/angular.js/blob/master/src/jqLite.js#L457
export function elAddClass(element, cssClasses) {
    if (cssClasses && element.setAttribute) {
        const existingClasses = (` ${element.getAttribute('class') || ''} `)
            .replace(/[\n\t]/g, ' ');
        let newClasses = existingClasses;

        cssClasses.split(' ').forEach(cssClass => {
            cssClass = cssClass.trim();
            if (newClasses.indexOf(` ${cssClass} `) === -1) {
                newClasses += `${cssClass} `;
            }
        });

        if (newClasses !== existingClasses) {
            element.setAttribute('class', newClasses.trim());
        }
    }
}

// from https://github.com/angular/angular.js/blob/master/src/jqLite.js#L440
export function elRemoveClass(element, cssClasses) {
    if (cssClasses && element.setAttribute) {
        const existingClasses = (` ${element.getAttribute('class') || ''} `)
            .replace(/[\n\t]/g, ' ');
        let newClasses = existingClasses;

        cssClasses.split(' ').forEach(function(cssClass) {
            cssClass = cssClass.trim();
            newClasses = newClasses.replace(` ${cssClass} `, ' ');
        });

        if (newClasses !== existingClasses) {
            element.setAttribute('class', newClasses.trim());
        }
    }
}

export function setSearchEngineUrl(url) {
    const eleHead = document.getElementsByTagName('head');
    const plugin = document.createElement('link');
    plugin.type = 'application/opensearchdescription+xml';
    plugin.rel = 'search';
    plugin.title = 'UpToDate to Search Plugin';
    plugin.href = `${url}`;

    // First remove any current search.xml entries
    let eleLink = document.querySelector('head link[rel=\'search\']');
    while (eleLink) {
        eleHead[0].removeChild(eleLink);
        eleLink = document.querySelector('head link[rel=\'search\']');
    }
    // Add updated link to head
    eleHead[0].appendChild(plugin);
}

export function setAnalyticsScriptWithGuid(data) {
    const ORG_ID = data.guidAnalyticsOrgId;
    const eleHead = document.getElementsByTagName('body');
    // eslint-disable-next-line max-len
    const url = `${data.guidAnalyticsUrl}?org_id=${ORG_ID}&session_id=${data.guidAnalytics}&pageid=2`;
    const scriptElement = document.createElement('script');
    scriptElement.src = url;

    let eleLink = document.querySelector(`body script[src*='${data.guidAnalyticsUrl}']`);
    while (eleLink) {
        eleHead[0].removeChild(eleLink);
        eleLink = document.querySelector(`body script[src*='${data.guidAnalyticsUrl}']`);
    }
    eleHead[0].appendChild(scriptElement);
}

export function getWindow() {
    // Used to allow for easier mocking of global "window" object in unit tests
    return window;
}

export function getDocument() {
    // Used to allow for easier mocking of global "document" object in unit tests
    return document;
}

export function getHostname() {
    return getWindow().location.hostname;
}

export function goBack() {
    getWindow().history.back();
}

// Position an element using javascript, relative to another element.
//
// Parameters:
//    reference (SelectorString or Element): positioning is relative to this element
//    element (SelectorString or Element): this is the element to be positioned
//    options: Object with the following props
//      placement: top, bottom, left, right *
//      positionFixed: whether position should be fixed or absolute *
//
// Returns:
//   manager object with the following methods:
//   update(): update the position of the element. If calling repeatedly,
//             consider using a passive event listener or debouncing manually
//             for performance
//
// * Currently only supports the default options
//   When we drop IE9+10 in later 2020 we can use Popper.js.
const defaultOptions = {
    positionFixed: true,
    placement: 'bottom'
};

export function createPositionManager(reference, element, options = {}) {
    const referenceEl = typeof reference === 'string'
        ? document.querySelector(reference) : reference;
    const elToPosition = typeof element === 'string'
        ? document.querySelector(element) : element;
    return new PositionManager(referenceEl, elToPosition, {
        ...options,
        ...defaultOptions
    });
}

class PositionManager {
    constructor(referenceEl, elToPosition, options) {
        this.refEl = referenceEl;
        this.posEl = elToPosition;
        this.options = options;
        this.theDocument = options.document || document;
        this.update();
    }

    update() {
        if (!(this.refEl && this.posEl)) {
            return;
        }
        if (this.options.positionFixed) {
            this.posEl.style.position = 'fixed';
        } // ELSE... not yet implemented

        if (this.options.placement === 'bottom') {
            const refBox = this.refEl.getBoundingClientRect();
            const elBox = this.posEl.getBoundingClientRect();

            // For now, no visibility check for y-axis is needed in UTD, but
            // Popper has that feature.
            this.posEl.style.top = `${refBox.bottom + 1}px`;

            // Find centered x-axis position (relative to reference), then
            // adjust so entire el is visible (horizontally). El will have
            // left=0 if it's wider than viewport.
            const refCenterXAxis = refBox.right - (refBox.width / 2);
            const idealLeft = refCenterXAxis - (elBox.width / 2);

            let maxLeft = this.theDocument.documentElement.clientWidth - elBox.width;
            // If specified, ensure new position does not touch viewport edge
            if (this.options.applyViewportGutter) {
                maxLeft -= C_UI.VIEWPORT_GUTTER_PX;
            }
            const left = Math.max(0, Math.min(idealLeft, maxLeft));
            this.posEl.style.left = `${left}px`;
        } // ELSE... not yet implemented
    }

    reset() {
        if (!this.posEl) {
            return;
        }
        this.posEl.style.top = '';
        this.posEl.style.position = '';
        this.posEl.style.left = '';
    }
}

export function checkAndSetEventBinding(el, attrName, eventType,
    eventHandler, attrNamePrefix = 'data-'
) {
    if (el && el.getAttribute) {
        const computedAttrName = `${attrNamePrefix}${attrName}`;
        if (el.getAttribute(computedAttrName) === null) {
            el.setAttribute(computedAttrName, '');
            el.addEventListener(eventType, eventHandler);
        }
    }
    return el;
}

export function elHasOverflowedContent(el) {
    // scrollHeight does not incorporate border width but offsetWidth does
    return {
        xOverflowed: el && typeof el === 'object' && el.scrollWidth > el.offsetWidth,
        yOverflowed: el && typeof el === 'object' && el.scrollHeight > el.offsetHeight
    };
}

export function hideKeyboard() {
    const focus = getWindow().document.querySelector(':focus');
    if (focus) {
        focus.blur();
    }
}

// jqLite $.getText
//
// eslint-disable-next-line max-len
// https://github.com/angular/angular.js/blob/ae8e903edf88a83fedd116ae02c0628bf72b150c/src/jqLite.js#L763
export function elGetSetText(element, value) {
    if (!(element && element.nodeType)) {
        return '';
    }
    if (isUndefined(value)) {
        const nt = element.nodeType;
        return (nt === NODE_TYPE_ELEMENT || nt === NODE_TYPE_TEXT) ? element.textContent : '';
    }
    element.textContent = value;
}

/**
 * Method to remove element from DOM, identified by passed selector
 * @param {String} selector
 * @returns Nothing
 */
export function removeElement(selector) {
    const el = DomUtils.getDocument().querySelector(selector);
    if (!el) {
        return;
    }

    el.parentNode.removeChild(el);
}

export function getElementStyleProperty(el, prop) {
    if (el && el.style && el.style[prop]) {
        return el.style[prop];
    }
    return '';
}

/**
 * Method create style link and append to head, according passed parameters.
 * @param {Object} cssObj - Object containing parameters to create style link.
 * @param {Function} resolverFn - Function to run once CSS is injected
 */
export function injectCss(cssObj, resolverFn) {
    const elemHead = DomUtils.getDocument().querySelector('head');
    if (!elemHead) {
        return;
    }

    const link = DomUtils.getDocument().createElement('link');
    if (cssObj.id) {
        link.id = cssObj.id;
    }
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = cssObj.url;

    const onLoadPromise = new Promise(resolve => {
        link.onload = () => resolve(resolverFn && resolverFn());
    });

    elemHead.insertAdjacentElement('beforeend', link);
    return onLoadPromise;
}

export function injectCssStyle(style) {
    if (!style) {
        return;
    }
    const headElem = getDocument().querySelector('head');
    headElem && headElem.append(style);
}

export function isTablet() {
    const minScreenWidth
        = Math.min(DomUtils.getWindow().screen.width, DomUtils.getWindow().screen.height);
    if (DomUtils.getWindow().devicePixelRatio < 1.5
        || minScreenWidth > C_RESPONSIVE.MAX_TABLET_THRESHOLD_PX) {
        return false;
    }
    return DomUtils.isTouchScreenDevice()
        && minScreenWidth >= C_RESPONSIVE.MIN_DESKTOP_THRESHOLD_PX;
}

export function isSmallScreen() {
    const screenWidth = DomUtils.getWindow().screen.width;
    const minScreenWidth = Math.min(screenWidth, DomUtils.getWindow().screen.height);

    return DomUtils.isTouchScreenDevice() && minScreenWidth < C_RESPONSIVE.MIN_DESKTOP_THRESHOLD_PX
        || screenWidth < C_RESPONSIVE.MIN_DESKTOP_THRESHOLD_PX;
}

export function isTouchScreenDevice() {
    return getWindow().navigator.maxTouchPoints > 2
        || getWindow().navigator.msMaxTouchPoints > 2;
}

export function htmlToElem(html) {
    const workingEl = document.createElement('div');
    workingEl.innerHTML = html;
    return workingEl;
}

// Wraps an element in another element and updates the DOM
export function wrap(el, wrapper = getDocument().createElement('div')) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    return wrapper;
}

export const disableTabbingForContainedElements = containerEl => {
    // Disable tabbing to elements under the modal overlay
    // Modified version from SO: https://stackoverflow.com/a/64233701
    // Codepen: https://codesandbox.io/s/nervous-feather-num1s?file=/src/index.js
    if (!containerEl) {
        return;
    }
    // Store current element tabindex values if found, then set to "-1"
    return Array.from(containerEl.querySelectorAll(TABBY_SELECTOR))
        .map(el => {
            // Capture original tab index, if it exists
            const tabIndex = el.hasAttribute('tabindex')
                ? el.getAttribute('tabindex')
                : null;
            // Set tabindex of -1 to disable tabbing to this element
            el.setAttribute('tabindex', -1);
            return { el, tabIndex };
        });
};

export const restoreTabbingForContainedElements = tabData => {
    // Restore tabbing to elements previously under the modal overlay
    // Modified version from SO: https://stackoverflow.com/a/64233701
    // Codepen: https://codesandbox.io/s/nervous-feather-num1s?file=/src/index.js
    if (!tabData) {
        return;
    }
    tabData.forEach(({ el, tabIndex }) => {
        if (tabIndex === null) {
            el.removeAttribute('tabindex');
        }
        else {
            el.setAttribute('tabindex', tabIndex);
        }
    });
};

export const getElementHorizontalPadding = el => {
    // DevNote: Due to the try/catch block, this should not be run
    // inside of a tightly polled event handler
    if (!el) {
        return 0;
    }

    try {
        const leftPadPx = pxToNum(DomUtils.getWindow().getComputedStyle(el).paddingLeft);
        const rightPadPx = pxToNum(DomUtils.getWindow().getComputedStyle(el).paddingRight);

        return (
            (isNaN(leftPadPx) ? 0 : leftPadPx)
          + (isNaN(rightPadPx) ? 0 : rightPadPx)
        );
    }
    catch (err) {
        return 0;
    }
};

export const getElementFromHtmlString = html => {
    if (!html) {
        return;
    }

    const parser = new DOMParser();
    const doc = parser && parser.parseFromString(html, 'text/html');

    return doc;
};

export const decodeHtml = html => {
    if (!html) {
        return '';
    }

    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
};

export function retainElementFocus(elementToFocus) {
    safeTimeout(function() {
        const currentEleToFocus = document.querySelector(elementToFocus);
        if (currentEleToFocus) {
            currentEleToFocus.focus();
        }
    }, 2000);
}

export const parseDomForAttributeValue = (attrName, el) => {
    let attrVal = el.getAttribute(attrName);
    while (!attrVal) {
        el = el.parentNode;
        if (el && el.getAttribute) {
            attrVal = el.getAttribute(attrName);
        }
        else {
            break;
        }
    }
    return attrVal;
};

export const getComputedPaddingWidth = elem => {
    // DEPRECATED!!! DO NOT USE FOR NEW WORK - METHOD SHOULD BE REMOVED POST MIGRATION
    const css = elem.currentStyle || window.getComputedStyle(elem);
    // eslint-disable-next-line no-new-wrappers
    const paddingLeft = new Number(css.paddingLeft.replace(/[^\d.-]/g, ''));
    // eslint-disable-next-line no-new-wrappers
    const paddingRight = new Number(css.paddingRight.replace(/[^\d.-]/g, ''));
    return Math.round((paddingLeft + paddingRight) * 1) / 1;
};

export const isVisitableLink = link => {
    return link && link.href && link.href !== '#';
};

export function getNavigator() {
    return getWindow().navigator;
}

export function elementsOverlap(el1, el2) {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return {
        isElementsOverlap: !(domRect1.top > domRect2.bottom
            || domRect1.right < domRect2.left
            || domRect1.bottom < domRect2.top
            || domRect1.left > domRect2.right),
        deltaY: Math.abs(domRect2.bottom - domRect1.top)
    };
}

export function scrollToZero() {
    DomUtils.getDocument().body.scrollTop = 0;
    DomUtils.getDocument().documentElement.scrollTop = 0;
}

// function to update the Element attributes
export function updateElementAttribute(selector, attribute, data) {
    const el = getDocument().querySelector(selector);
    el && el.setAttribute(attribute, data);
}

// function to update the class of the html tag
export function updateHTMLClass(htmlClass) {
    updateElementAttribute('html', 'class', htmlClass.join(' ').trim());
}

// function to update the class of the body tag
export function updateBodyClass(bodyClass) {
    updateElementAttribute('body', 'class', bodyClass.join(' ').trim());
}

// function to update the canonical URL
export function updateCanonicalUrl(canonicalUrl) {
    updateElementAttribute('link[rel="canonical"]', 'href', canonicalUrl);
}

// function to update the meta description
export function updateMetaDescription(metaDescription) {
    updateElementAttribute('meta[name="description"]', 'content', metaDescription);
}

/**
 * Takes a DOM element and replaces its parent with a new container div
 * @param {Element} elem - the element that will receive a new parent container
 * @returns The new parent wrapper for the element
 */
export function replaceParent(elem) {
    if (!elem || !elem.parentNode) {
        return null;
    }
    const parent = elem.parentNode;
    const wrapper = getDocument().createElement('div');
    parent.replaceChild(wrapper, elem);
    wrapper.appendChild(elem);
    return wrapper;
}

/**
 * Loops through the previous siblings of {startElement} until it
 * finds the target element containing the queryString or until
 * the element no longer has any valid previous siblings.
 *
 * @param {Element} startElement - element to loop from
 * @param {String} queryString - the CSS selector of the target element that is being searched for
 * @returns the element containing queryString or a null value if element not found
 *  or does not have a valid previous sibling
 */
export function getPreviousSiblingTarget(startElement, queryString) {
    if (!startElement) {
        return null;
    }
    let prevSibling = startElement.previousSibling;
    while (prevSibling) {
        if (prevSibling.matches && prevSibling.matches(queryString)) {
            return prevSibling;
        }
        prevSibling = prevSibling.previousSibling;
    }
    return null;
}

/**
 * Loops through the next siblings of {startElement} until it finds a sibling or the
 * sibling's children containing the {stopTarget} CSS selector or there are no more next siblings.
 * Reassigns each of the siblings as a child of the {newParent} element.
 *
 * @param {Element} newParent - container element to hold the reassigned next siblings
 * @param {Element} startElement - element to start sibling search from
 * @param {String} stopTarget - CSS selector of the element that ends the sibling search
 * @returns the {newParent} container with the reassigned siblings
 */
export function reassignNextSiblings(newParent, startElement, stopTarget = '') {
    if (!newParent || !startElement) {
        return null;
    }
    let nextSiblingElement = startElement.nextSibling;
    while (nextSiblingElement) {
        const stopTargetElement = querySelectorIncludeSelf(nextSiblingElement, stopTarget);

        let queueNextSibling;
        if (!nullUndefinedOrEmpty(stopTargetElement)) {
            break;
        }
        else {
            queueNextSibling = nextSiblingElement.nextSibling;
            newParent.appendChild(nextSiblingElement);
        }
        nextSiblingElement = queueNextSibling;
    }
    return newParent;
}

/**
 * Performs the functionality of element.querySelector, but also checks the root element
 * with the queryString
 * @param {Element} element - root element to query from
 * @param {String} queryString - CSS selector to search for
 * @returns The queried element or null if not found
 */
export function querySelectorIncludeSelf(element, queryString = '') {
    if (!element || !element.matches || !element.querySelector) {
        return null;
    }
    return element.matches(queryString) && element
        || element.querySelector(queryString);
}

export function clearTextSelection() {
    if (getWindow().getSelection().empty) {
        getWindow().getSelection().empty();
    }
    else if (getWindow().getSelection().removeAllRanges) {
        getWindow().getSelection().removeAllRanges();
    }
}

export function getNearestSectionName(sectionId) {
    const sectionClassIdentifier = `.${sectionId}_scrollTarget`;
    const sectionElement = getDocument()
        .querySelector(sectionClassIdentifier);
    const section = sectionElement && sectionElement.textContent;
    return section;
}
